import { __rest } from '../../_virtual/_tslib.js';
import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../InputField/InputField.js';

const propTypes = {
  /**
   * The type of the Input that will render.
   */
  type: PropTypes.oneOf([
  // 'checkbox',
  // 'radioBox',
  // 'radioSwatch',
  // 'radioButton',
  // 'radio',
  'text', 'number', 'inlineAction', 'password', 'creditCard', 'tel', 'date', 'securityCode'])
};
const defaultProps = {
  type: 'text'
};
/**
 * @ignore
 */
const Input = function (_a) {
  var {
      type
    } = _a,
    props = __rest(_a, ["type"]);
  switch (type) {
    // case 'checkbox':
    //   return <Checkbox {...props} />;
    // case 'toggle':
    //   return <Toggle {...props} />;
    // case 'radioBox':
    //   return <RadioBox {...props} />;
    // case 'radioButton':
    // case 'radio':
    //   return <RadioButton {...props} />;
    // case 'radioSwatch':
    //   return <RadioSwatch {...props} />;
    case 'number':
    case 'text':
    case 'inlineAction':
    case 'password':
    case 'creditCard':
    case 'tel':
    case 'date':
    case 'securityCode':
      return React.createElement(InputField, Object.assign({
        type: type
      }, props));
    default:
      console.warn('Warning: Native input type ' + type + ' is not supported');
      return React.createElement(InputField, Object.assign({
        type: "text"
      }, props));
  }
};
Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export { Input as default };
