import { object, string } from "yup";

const validationSchema = object().shape({
  email: string()
    .email("You must enter a valid Email")
    .required("You must enter an Email"),
  password: string().required("You must enter a Password"),
});

export default () => validationSchema;
