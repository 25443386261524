'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deferAction = deferAction;
function deferAction(action) {
  if (typeof setImmediate !== 'undefined') {
    setImmediate(action);
  } else {
    setTimeout(action, 1);
  }
}