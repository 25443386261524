import {
  TextButton,
  FlexBox,
  Modal,
  Title,
  Body,
  Button,
  IconButton,
} from "components";
import { StyledHorizontalRule} from "./FileCourierReceivedDetailsModal.module";
import { FileCourierReceivedDetailsModalProps } from "interfaces";
import { formatDate } from "utils/dateFunctions";
import {
  downloadPackage,
  downloadFile,
} from "utils/fileCourier";

const FileCourierReceivedDetailsModal = ({
  receivedPackage,
  onClose,
  onRefresh,
}: FileCourierReceivedDetailsModalProps) => {
  const {
    id,
    sentDate,
    expirationDate,
    sentFromFirstName,
    sentFromLastName,
    files } = receivedPackage;
  
  const handleClose = () => {
    onClose();
    onRefresh();
  };

  const handleDownloadPackage = async (packageId: string) => {
    await downloadPackage({ packageId });
  }

  const handleDownloadFile = async (fileId: string) => {
    await downloadFile({ fileId });
  }

  return (
    <Modal testId="fc-received-details-modal" open={true} onClose={handleClose}>
      <FlexBox gap="1.25rem" minWidth="100%">
        <Title size="large" bold>
          Files received {formatDate(sentDate)}
        </Title>
        <Title size="medium">
          Expires {formatDate(expirationDate)}
        </Title>
        <Title size="medium">
          From: {sentFromFirstName} {sentFromLastName}
        </Title>
        <FlexBox pd="0 0 2.5rem 0">
          <FlexBox row pd="1rem 0 .1rem 0" align="flex-end" justify="space-between">
            <Body size="large">
              File name
            </Body>
            <Button
              type="submit"
              use="primary"
              ariaLabel="download package"
              size="small"
              data-testid="download-all-button"
              onClick={() => handleDownloadPackage(id)}
            >
              Download All
            </Button>
          </FlexBox>
          <StyledHorizontalRule />
          {files.map((file) => (
            <FlexBox key={file.id} row pd="0 0 .5rem 0" justify="space-between">
              <Body size="large" bold>
                {file.name}
              </Body>
              <IconButton
                key={file.id}
                name="download"
                size="large"
                ariaLabel="download file"
                onClick={() => handleDownloadFile(file.id)}
                addTooltip
                testId="download-file-button"
                toolTipText="download"
              />
            </FlexBox>
          ))}
        </FlexBox>
        <FlexBox row gap="1.5rem" align="center" justify="flex-start">
          <TextButton data-testid="cancel-button" onClick={handleClose}>Cancel</TextButton>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default FileCourierReceivedDetailsModal;