import React from 'react';
import PropTypes from 'prop-types';
import { AccordionTitle as AccordionTitle$1 } from '@vds-core/accordions';

const propTypes = {
  /**
   * @ignore
  * Option to render on dark or light surface
  */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const AccordionTitle = function (props) {
  return React.createElement(AccordionTitle$1, Object.assign({}, props));
};
AccordionTitle.propTypes = propTypes;
AccordionTitle.defaultProps = defaultProps;
AccordionTitle.displayName = 'AccordionTitle';

export { AccordionTitle as default };
