import { useRef } from "react";
import Button from "../Button/Button";
import CaretButton from "../CaretButton/CaretButton";
import { ButtonWrapper } from "./FileUploadButton.module";
import { Body } from "../../index";

const FileUploadButton = ({
  onClick,
  disabled,
  caret,
  children,
  // uploadArea,
  id,
  label,
  mg,
  maxWidth,
  error,
}: any) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null),
    handleClick = (_: any) => {
      hiddenFileInput.current?.click();
    },
    handleChange = (event: any) => {
      const fileUploaded = event.target.files[0];
      onClick(fileUploaded);
    };

  return caret ? (
    <>
      <CaretButton onClick={handleClick} disabled={disabled}>
        {children}
      </CaretButton>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  ) : (
    // : uploadArea ? (
    //   <ButtonWrapper disabled={disabled} maxWidth={maxWidth} error={error} mg={mg}>
    //     <label htmlFor={id}><Body size="small">{label ? label : "Upload file(s)"}</Body></label>
    //     <button onClick={handleClick} disabled={disabled}>
    //       {children}
    //     </button>
    //     <input
    //       id={id}
    //       type="file"
    //       ref={hiddenFileInput}
    //       onChange={handleChange}
    //       style={{ display: "none" }}
    //     />
    //   </ButtonWrapper>
    // )
    <>
      <Button onClick={handleClick} disabled={disabled} type="button">
        {children}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};

export default FileUploadButton;
