import React from 'react';
import PropTypes from 'prop-types';
import CarouselBars from './CarouselBars.js';
import CarouselScrollbar from './CarouselScrollbar.js';

const propTypes = {
  type: PropTypes.oneOf(['bars', 'scrubber'])
};
const defaultProps = {
  type: 'bars'
};
const CarouselIndicator = props => {
  switch (props.type) {
    case 'scrubber':
      return React.createElement(CarouselScrollbar, Object.assign({}, props));
    case 'bars':
    default:
      return React.createElement(CarouselBars, Object.assign({}, props));
  }
};
CarouselIndicator.propTypes = propTypes;
CarouselIndicator.defaultProps = defaultProps;

export { CarouselIndicator as default };
