import { useState } from "react";
import { DeleteFavoriteFolderModalProps } from "interfaces";
import { TextButton, Button, FlexBox, Modal, Title, Body } from "components";
import { useDeleteFavoriteFolderMutation } from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";

export enum ManagedStatus {
  notSet = "Not Set",
  active = "Active",
  inactive = "Inactive",
  deleted = "Deleted",
  requested = "Requested",
}

const DeleteFavoriteFolderModal = ({
  folderId,
  onClose,
  onComplete,
}: DeleteFavoriteFolderModalProps) => {
  const [deleteFolder, { isLoading: folderLoading }] =
    useDeleteFavoriteFolderMutation();

  const dispatch = useDispatch();

  const callDeleteFolder = () => {
    deleteFolder(folderId)
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: "Folder successfully deleted.",
          })
        );
        onComplete();
      })
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Error deleting folder.",
          })
        );
        console.error(error);
      });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal testId="delete-favorite-folder-modal" open={true} onClose={handleClose} disabled={folderLoading}>
      <FlexBox>
        <Title size="large" bold pd="0 0 2rem">
          Are you sure you want to delete this folder?
        </Title>
        <Body size="large" pd="0 0 2rem">
          Deleted folders are permanently removed from Brand Central and will no
          longer be accessible.
        </Body>
        <FlexBox row gap="1.5rem" align="center" justify="flex-start">
          <Button
            use="primary"
            data-testid="submit-button"
            onClick={callDeleteFolder}
            disabled={folderLoading}
          >
            Delete folder
          </Button>
          <TextButton data-testid="cancel-button" onClick={handleClose} disabled={folderLoading}>
            Cancel
          </TextButton>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default DeleteFavoriteFolderModal;
