import styled from "styled-components";
import FlexBox from "components/layouts/FlexBox/FlexBox";

export const StyledFlexBox = styled(FlexBox)`
  padding: 0 0 2.5rem;
  
`;

export const SavedSearchesList = styled.ul`
  overflow-y: auto;
  overflow-x: hidden;
  list-style-type: none;
  padding: 0;
  margin: 2.5rem 0;
  width: 100%;
`;

export const SavedSearchItem = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;

  min-height: 5rem;

  /* padding-block: 1rem; */

  :not(:first-of-type) {
    border-top: 1px solid #f2f2f2;
  }
`;