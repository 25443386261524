import { useState, useEffect, SyntheticEvent } from "react";
import { TextLink } from "components";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { RetailNavigationPage } from "interfaces";
import { Icon } from "@vds/icons";
import { Location } from "react-router-dom";
import {
  StyledSingleNavItem,
  StyledChildLink,
  StyledNav,
  StyledTextLink,
} from "./RetailSideNav.module";

export interface RetailSideNavProps {
  showMobile?: boolean;
  location: Location;
}

const RetailSideNav = ({ showMobile, location }: RetailSideNavProps) => {
  const RetailCenterPages: RetailNavigationPage[] = [
    {
      title: "Overview",
      url: "/retail",
    },
    {
      title: "Brand Policies",
      url: "#",
      children: [
        {
          title: "Authorized Retailer",
          url: "https://brandpolicy.verizon.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzEwNDM4NTYsImlzcyI6ImFjYiIsImp0aSI6IjNmNWU3MzdiLTVhNDYtNDEzMi04Mjc1LTU4ZWRiYzI3OWMxMiJ9.YlbE-WRk5FDg-L4uf9nMTxfD-tXmL2eYTwaiK44yf6A&showHeader=true",
          external: true,
        },
        {
          title: "Corporate Retail",
          url: "https://retailbrandpolicy.verizon.com/",
          external: true,
        },
      ],
    },
    {
      title: "Branded Environments",
      url: "/retail/branded-environments",
      children: [
        {
          title: "Branded environment design guide",
          url: "/retail/branded-environments",
        },
        {
          title: "Design advisories",
          url: "/retail/branded-environments/design-advisories",
        },
      ],
    },
    {
      title: "Governance",
      url: "/retail/retail-governance",
      children: [
        {
          title: "Process overview",
          url: "/retail/retail-governance",
        },
        {
          title: "Audits and assessments",
          url: "/retail/retail-governance/reports",
        },
      ],
    },
    {
      title: "Templates and Tools",
      url: "/retail/templates-and-tools",
      children: [
        {
          title: "Templates",
          url: "/retail/templates-and-tools",
        },
        {
          title: "Tools",
          url: "/retail/templates-and-tools/tools",
        },
      ],
    },
    {
      title: "Support",
      url: "/retail/support",
      children: [
        {
          title: "FAQs",
          url: "/retail/support",
        },
        {
          title: "Contact us",
          url: "/retail/support/contact-us",
        },
      ],
    },
  ];

  const [activePage, _activePage] = useState<string>("");

  const getActivePage = (location: Location): void => {
    let active: string = "";
    RetailCenterPages.forEach((page: RetailNavigationPage) => {
      if (page.url === location.pathname) {
        active = page.title;
      }
      if (page.children) {
        page.children.forEach((page: RetailNavigationPage) => {
          if (page.url === location.pathname) {
            active = page.title;
          }
        });
      }
    });
    _activePage(active);
  };

  useEffect(() => {
    getActivePage(location);
  }, [location]);

  const [expanded, _expanded] = useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: SyntheticEvent, newExpanded: boolean) => {
      _expanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (activePage) {
      RetailCenterPages.forEach((page, index) => {
        if (page.children) {
          if (page.children.find((page) => page.title === activePage)) {
            _expanded(index);
          }
        }
      });
    }
  }, [activePage]);

  return (
    <StyledNav className={showMobile ? "showMobile" : "hide"}>
      <nav>
        {RetailCenterPages.map((page: RetailNavigationPage, index) =>
          page.children && page.children?.length > 0 ? (
            <Accordion
              onChange={handleChange(index)}
              expanded={expanded === index ? true : false}
              className="retailNav"
              key={page.url}
            >
              <AccordionSummary
                className={`retailNav ${
                  page.title === activePage ? "active" : ""
                }`}
                expandIcon={<Icon name="down-caret" />}
              >
                <TextLink
                  newTab={page.external}
                  external={page.external}
                  size="medium"
                  textAlign="left"
                  noUnderline
                  bold
                  to={page.url}
                >
                  {page.title}
                </TextLink>
              </AccordionSummary>

              <AccordionDetails className="retailNav">
                {page.children.map((child) => (
                  <StyledChildLink
                    key={child.url}
                    className={child.title === activePage ? "active" : ""}
                  >
                    <StyledTextLink
                      className={child.title === activePage ? "active" : ""}
                    >
                      <TextLink
                        newTab={child.external}
                        external={child.external}
                        size="medium"
                        textAlign="left"
                        noUnderline
                        to={child.url}
                      >
                        {child.title}
                      </TextLink>
                    </StyledTextLink>
                  </StyledChildLink>
                ))}
              </AccordionDetails>
            </Accordion>
          ) : (
            <StyledSingleNavItem
              key={page.url}
              className={page.title === activePage ? "active" : ""}
            >
              <TextLink
                newTab={page.external}
                external={page.external}
                size="medium"
                textAlign="left"
                noUnderline
                bold
                to={page.url}
              >
                {page.title}
              </TextLink>
            </StyledSingleNavItem>
          )
        )}
      </nav>
    </StyledNav>
  );
};

export default RetailSideNav;
