import React from "react";
import { StyledFlexBox } from "./FlexBox.module";

export type FlexBoxProps = {
  className?: string;
  children: React.ReactNode;
  row?: boolean;
  bp?: string;
  flex?: string;
  align?: string;
  justify?: string;
  gap?: string;
  pd?: string;
  mg?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  wrap?: boolean;
  id?: string;
  role?: string;
  scroll?: boolean;
  bg?: string;
  radius?: string;
  mobileOrder?: number;
};

const FlexBox = ({ children, bp, className, ...rest }: FlexBoxProps) => {
  return (
    <StyledFlexBox
      className={`${className ? className : ""} ${bp ? "breakpoint" : ""}`}
      bp={bp}
      {...rest}
    >
      {children}
    </StyledFlexBox>
  );
};

export default FlexBox;
