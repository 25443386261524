export const assetSearchResultsSmall = {
  results: [
    {
      isActive: true,
      isSearchable: false,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Kat Test 5",
      description: "Test",
      source: "Test",
      altText: "Test",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "Test",
      subtitle: "",
      usageRightsCopy: "Test",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [],
      filterIds: [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "048277fa-25ce-458e-9c03-589f84b78544",
        "b1f8dcc5-7cd5-4d08-befa-b21873f0a524",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "0a30df4b-3f78-4be7-b7c0-86d19db7c35d",
      createdOn: "2022-06-13T14:14:33.665318Z",
      modifiedOn: "2023-09-26T15:30:05.49794Z",
      expiresOn: "2030-09-21T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: true,
      name: "Elaina Test Asset 6",
      description: "test 6",
      source: "internet",
      altText: "test 6",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 3,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "3307a28c-ffda-46ed-b98e-4446dea2116d",
          groupName: null,
          fileName: "png-transparent-watermark-thumbnail.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=SY8e11egyTdiDoRqm%2BFhYjFLMSA%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2BsDW1OMHxOmpNtBHnawNm%2F6%2BNtQ%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=9Pdqzz0icMqgUx3gwhAY1CLdsHM%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=nIrsTPHuEfsaQJxf5816xBkZ3Xo%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
        "c51db643-479b-46a3-b3ea-4d5c23f0db1d",
        "00b2f403-8017-4498-b591-5e7dbac2f47c",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "6fa19b24-2542-46ab-b932-0fa8b9f71ecb",
      createdOn: "2023-09-25T22:59:05.010847Z",
      modifiedOn: "2023-10-13T07:12:06.428844Z",
      expiresOn: "2026-10-26T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Test Asset 9",
      description: "Test Asset 9",
      source: "Chris B.",
      altText: "Test Asset 9",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Test Asset 9",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [],
      filterIds: [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "99f86197-9021-4581-9146-2c04cecd1b7a",
        "fa9267e8-865d-4d52-aabf-558d76c1528c",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "51288d10-2309-4fb8-9e29-38e2a3624b57",
      createdOn: "2022-07-26T17:04:25.343903Z",
      modifiedOn: "2023-06-28T14:27:18.778978Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Christine Test Asset",
      description: "testing asset request photography asset form for dashboard",
      source: "me",
      altText: "alt text",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "93a09889-754a-4725-8078-9316a34ba9e9",
          groupName: null,
          fileName: "testassetform.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=1UXVyH89T4%2BAL9qeGuortOyyonE%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=3N6JPio%2BHhrEyKfz%2BW93et11fGs%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=sag44otY7Sr65Hfw4QeVV5Yn8D0%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=YW2OXvgN59FCOUnJfXSg0rxBC5A%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "135c532b-59ff-43a2-b328-d08f01b4fa18",
      createdOn: "2023-03-08T14:37:09.234824Z",
      modifiedOn: "2023-09-27T15:10:59.421873Z",
      expiresOn: "2023-10-31T23:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "danhuri test photoshoot 7",
      description: "danhuri test photoshoot 7 assets same job",
      source: "Danhuri",
      altText: "danhuri test photoshoot",
      keywords: null,
      jobNumber: "danhuriTestJob",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 6,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "bbd5ab05-2f60-453e-bb6a-d073c85c6231",
          groupName: null,
          fileName: "global search button.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=DncCg7FO%2Bv%2BAA5rFw7dLlXFAGws%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2Fq%2BEEHLM5Y2zQ8on3vhzQjL7LDM%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=vi24ZW86KxdGPeC2TaLeVbAEJKE%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=1pU0mC4rlDy5YIxfdlPUH3pr4%2F8%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "3dbd1fd0-2c14-4002-b233-25142d5996cf",
      createdOn: "2022-08-03T13:22:07.522843Z",
      modifiedOn: "2023-08-30T17:25:59.352137Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "QA Asset Test",
      description: "test",
      source: "test",
      altText: "test",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "0099d4dd-4dab-4356-8600-0ae4b8a76d71",
          groupName: "Group 1",
          fileName: "ICONCOMPUTER (3).png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2Fxp7z69USQ0O2Pgdd19%2FDJAkOuE%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=09vf8K2j1Tgj1222z96LXtcIZ2M%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=JBl81fvxHT%2FZxe3GOxZIxuN8gRk%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=FtcbVKzcRh%2FCfjc9RNJKrRRhKoA%3D",
            isImage: true,
          },
        },
        {
          id: "0fe937c2-a37a-482d-8b75-443349db6844",
          groupName: "group 2",
          fileName: "Icon search.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ndAD09nFm2lAVP6Rx5OAJsJft7s%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=LoHNsIEE3hgrqFEWJVl1WmZWhdA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=AqfUNYOyzhzOi7NYxQ3KWo5n8%2BA%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=T%2F4ivb3l2GZG20Jn1EVrYOPyOBs%3D",
            isImage: true,
          },
        },
        {
          id: "4aeb4c25-2cdd-43a9-9ca7-04271820c846",
          groupName: "group 3",
          fileName: "SA-2054-MNYIB-V.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2BFixJJl2JywFI9w5gN0cbvOQxT8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=yvngjeSHnwzilI9BMxJV3bi9gKM%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=PQ69wQrpRQ4%2BT10rxWk9XzEo3tk%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=szwiiP6M9p6zmP8ig8GZJObQ7Ow%3D",
            isImage: true,
          },
        },
        {
          id: "cad2903c-6a7f-4b57-9773-7a23148c7ee5",
          groupName: "Group 1",
          fileName: "77-89337_E.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=aaYdTTnekL4vWw208%2FiDXNXet0o%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=gvvbHk%2B4UhjfRLg9CfIQZ6mytJU%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=63fxoLXheeDxP1vbXVv5yj8dnLw%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=IVvT3T1A%2B55ogNmwC7jQdFAyDV4%3D",
            isImage: true,
          },
        },
        {
          id: "fd13c31b-af6a-4c81-86ca-74cf0df4bec8",
          groupName: "Group 1",
          fileName: "DBP-W22V11-1757.pdf",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=NtJaZ83JKYdALICgoSm60EeddNM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=SOOx6OHdKCz368%2Bce4igReYc%2Fc4%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ooWa7q3Q%2Ft9zUlEXTgQe9dn%2Flaw%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2Blpql%2FjbVIr9SGSiWefDaPef7bM%3D",
            isImage: false,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "783eaa9e-ae1b-4e2c-8351-0a424ab226d5",
      createdOn: "2023-02-14T21:30:38.945209Z",
      modifiedOn: "2023-10-07T20:57:51.257415Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "danhuri iconography same photoshoot test 2",
      description: "danhuri iconography same photoshoot test",
      source: "Danhuri",
      altText: "danhuri iconography same photoshoot test",
      keywords: null,
      jobNumber: "DanhuriIcon",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 8,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "bf8e0067-abed-4c6a-9642-c353ea01c12f",
          groupName: null,
          fileName: "asset screen shot 3.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=J3SD5AC41GaT%2B8hyokD89uaQ6Ig%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=WlEtmHlo94%2FS%2F%2BIDJkmw3johoEM%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=QG1PeeEo02xX0PzvCeb%2BEc8hE2A%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=xPI4eSozz3a2gSjexvsm49hrSnc%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
        "e492b86d-3a1d-45a0-b518-3ace937747cf",
        "121ed840-4049-43f3-a3be-ecd0887e5f11",
        "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "f894b2ed-c964-4dfe-b10f-d7065797a0d2",
      createdOn: "2022-08-24T13:48:27.099944Z",
      modifiedOn: "2023-10-03T17:29:50.708363Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Louise likes the hose, hates the pool. IDK, weird dog.",
      description: "hot dog!",
      source: "me",
      altText: "hot dog",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "111b6137-5929-4fc4-8e0d-5a2f04115ed7",
          groupName: "group 1",
          fileName: "1 - Sign Up.psd",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Nb7q8vrTLwGYqixIdciILEQ3opo%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=9ERIhCwfuHp1eVePYnWQ4fD0KfI%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=oWgPtyX6fTfwHFZgGz3rBvuGxDM%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=h6DNHz4NBv5Fp%2FSOMrmJrdSq%2BkQ%3D",
            isImage: false,
          },
        },
        {
          id: "a521b3ee-e1e5-486c-acd8-862df489e95f",
          groupName: "group 1",
          fileName: "crazy_eyes_hose_pool_outside.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL69eff9688b554b9c9f3f73ad274e7ff5_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=6K6%2BoStS%2FWLILowu4hlNz8%2B8raQ%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL69eff9688b554b9c9f3f73ad274e7ff5_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Bf2sjiIX4e0yMocQN%2BDo2jWbWEw%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL69eff9688b554b9c9f3f73ad274e7ff5_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=4ZP3PiHoyNsB2ScNtw4HXh7vZCY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL69eff9688b554b9c9f3f73ad274e7ff5_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Qf3umQDRx3Hj0Z3TaUg9KqX5tM4%3D",
            isImage: true,
          },
        },
        {
          id: "ab751d1b-f761-45f7-b673-260b02bf6c9c",
          groupName: null,
          fileName: "focused_bite_water_in_pool.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf5d372ec3f524972bd78e199518db59b_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=3Uq%2FdeQjbI%2F%2BkBRACb8hzYD763c%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf5d372ec3f524972bd78e199518db59b_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=LPB3kxtGO2u0arxnHbRneiSd%2FZM%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf5d372ec3f524972bd78e199518db59b_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=IBgePJ3ubvvy5aGL40sjURU6bYk%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf5d372ec3f524972bd78e199518db59b_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Wlaa12RwzYXG9Jo3CpsxApsTG2Q%3D",
            isImage: true,
          },
        },
        {
          id: "afde6ac5-a7a6-4b76-8c66-03ced10dc347",
          groupName: null,
          fileName: "lick_water_from_hose_outside.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc83b964662546ef8f829d7427cb94bf_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=q%2BvuEzZnr8qvqt3LosLBMxkETgI%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc83b964662546ef8f829d7427cb94bf_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=fl4TfBJGA%2B4owWZSK9ma5SU01bU%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc83b964662546ef8f829d7427cb94bf_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=R7fTqU9Yd1ld3uA58f2FavSJg2Q%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc83b964662546ef8f829d7427cb94bf_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=OUc4B%2BGKbQCqRtS9%2F8AanEi2Cg0%3D",
            isImage: true,
          },
        },
        {
          id: "e80a9a92-2e50-41ea-8be7-61030c8e7441",
          groupName: null,
          fileName: "crazy_eyes_bite_water.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5d12b5ee34d642879ea79c3995d7396a_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=25KrnS7i0viOzlr9bJNHINkoYTw%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5d12b5ee34d642879ea79c3995d7396a_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=wIDs76H%2FZVq%2Bd35zQwQ%2FvHT7y1E%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5d12b5ee34d642879ea79c3995d7396a_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=3dAoFjzglZ8NYr2%2B3d28r4siZ%2B8%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5d12b5ee34d642879ea79c3995d7396a_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=e%2BI1e2DRq%2F%2BHVuS68%2BNO9EqA11Y%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "316c0651-c5e3-4bd2-b41c-5427484f74bd",
        "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d",
        "37791870-297d-4c3c-8230-d988fa8625b3",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "7d0db47e-dc84-4ac3-b6c9-7456f5bc9715",
      createdOn: "2023-10-04T19:18:19.579063Z",
      modifiedOn: "2023-10-07T17:57:49.864198Z",
      expiresOn: "3000-07-04T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Danhuri Test Icon Same Photoshoot 7",
      description: "Danhuri Test Icon Same Photoshoot",
      source: "Danhuri",
      altText: "Danhuri Test Icon Same Photoshoot",
      keywords: null,
      jobNumber: "DanhuriIconSamephotoshoot",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 7,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "998c0929-8b2a-4ce2-930e-bb930a150d4e",
          groupName: null,
          fileName: "global search button.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL78c28fc1c21442a392c0f906318847df_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=3zAdc%2F8tH9Bz4PDC99MAVGpMAn0%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL78c28fc1c21442a392c0f906318847df_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=SptW7RldTdyZsOg5X43d9A324Jc%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL78c28fc1c21442a392c0f906318847df_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=88h67L4jZY3Wqqpb2fO2qqROFUk%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL78c28fc1c21442a392c0f906318847df_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=YhiC6X0NcEHEr5Rp%2Fp8szoHCs9U%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "8a3295f9-4a31-4554-aa9d-eb1933134dcc",
        "45162c0d-4f5d-4b7b-a5a9-7eceeeb41e40",
        "1d06bd8f-bb8c-4cac-ae49-e51c2be788c8",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "fb4133f8-5e7e-44b3-afe2-a77bdb0c63e8",
      createdOn: "2022-09-01T13:33:09.851588Z",
      modifiedOn: "2023-10-13T07:49:14.724387Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: true,
      name: "Louise gives you a guilt trip!",
      description: "She doesn't want to get off the bed",
      source: "me",
      altText: "dog on bed",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 1,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "b5296824-2a1e-4f82-93fb-32a69f8850d1",
          groupName: null,
          fileName: "paws_over_bed.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=jvmRUPBb1y8qyrtYO99Ha19CvD8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=KzmNu6M%2FnIM%2FQdFtiymvVUUinyw%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=PJGny%2Fh5SeOXGfWh46kBecdM4jI%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=rsUsliJBWiUL%2FmSvViScjkb%2BLFE%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "18cdbe3d-567f-47eb-9c94-9acdab78547b",
        "57c0436d-bc92-4b66-b39a-68daed6e80f3",
        "1db0c5c3-55f4-4471-8d4d-6a7876c82044",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "76e9f2b0-d65c-4a04-9ebc-69c2af6777bd",
      createdOn: "2023-10-04T19:15:29.302849Z",
      modifiedOn: "2023-10-17T13:59:37.0399685Z",
      expiresOn: "2853-05-09T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      name: "Asset 36",
      description: "Asset 36",
      source: "Asset 36",
      altText: "Asset 36",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=lE3Vn8hPZxXYAJyuTqpvuhckGLg%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=QwyVUDAIeqtOVZEDdsEw0hNkbvI%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=tBj%2Ftke9JkZYIzJblf9FD3N2VHY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ZqvAs2dsQJ9OCWlo8v6XGBUs3Bo%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=qLcY2Xca8zB11NchuOJoEkWt09o%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=D6C4rPKAv7GyphJkgvHpamCQT7E%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=zuHdS0otAcA9x4thvFwIgaD7%2Fu8%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=vkEwqT%2FGGu3ldgRKsyofGTlaDNo%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "827e6605-4342-411e-9de3-848d80e0ac19",
      createdOn: "2022-10-05T19:12:25.759419Z",
      modifiedOn: "2023-10-24T19:18:30.4911899Z",
      expiresOn: "2024-01-24T05:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "test test test 10/2",
      description: "Test",
      source: "Test",
      altText: "Test",
      keywords: null,
      jobNumber: "Test",
      guidelinesUrl: "",
      copyright: "Test",
      subtitle: "Test",
      usageRightsCopy: "Test",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "107459fd-07d7-4dc4-9a04-f4f8f5b715d9",
          groupName: null,
          fileName: "Screenshot 2023-10-02 at 1.12.02 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e5dc2c027ce44d7917c70895fec408a_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=cVfqTn0bKTOFGzkTQLBYHRXn7eM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e5dc2c027ce44d7917c70895fec408a_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=O8Io2bNkkK4JPZoUle0xEafkifI%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e5dc2c027ce44d7917c70895fec408a_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=uKTum%2BfTeXvD%2BaaCIYbm%2BMPQOro%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e5dc2c027ce44d7917c70895fec408a_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Y9VCqLO6ccSXhoW%2FYIm7Ar%2BeEkc%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "316c0651-c5e3-4bd2-b41c-5427484f74bd",
        "25ee6b4e-0e36-445a-8740-7d432afa3e8a",
        "48018f84-1fd8-4165-8078-3d83908f22d4",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "20e70d79-60b6-4797-99f3-da49556d95d6",
      createdOn: "2023-10-02T20:19:52.192713Z",
      modifiedOn: "2023-10-24T19:18:30.4729122Z",
      expiresOn: "2024-01-24T05:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "Kat Test 1",
      description: "Test assets",
      source: "Kat",
      altText: "Test assets alt text",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "Copyright VZ",
      subtitle: "",
      usageRightsCopy: "Don't use these",
      downloadCount: 4,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "95971991-f599-4558-bdbf-0967d7e60de3",
          groupName: null,
          fileName: "vzcheckmark.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66d1655c51054af083935c662e951ab7_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=eLvBBhQdGOAix2srLbG1x%2Fshg1U%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66d1655c51054af083935c662e951ab7_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=WHKSp1ZNaZ9Y1wmTyJwuyqS0Cms%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66d1655c51054af083935c662e951ab7_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=MwaSJ8h7OF3rxdg2WNCI5rLsqJo%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66d1655c51054af083935c662e951ab7_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=CKR0CaQuQAzmiSz1WTLkwRyS3xo%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
        "f9e5c0cd-1a37-424c-9e8d-98a78a859dcd",
        "9ab6508c-29d8-44a1-a334-a21b5499c01c",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "37016a1d-3099-4bc5-a6cc-55b3306b6fcc",
      createdOn: "2022-06-03T19:07:22.192281Z",
      modifiedOn: "2023-09-26T00:33:22.274797Z",
      expiresOn: "2024-08-16T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "test upload fox",
      description: "test",
      source: "test",
      altText: "test",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "test",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "b56b66ab-6668-442d-ac55-4b6ce309f042",
          groupName: "group 1",
          fileName: "upload-fox.jpeg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf81d30fa666240f7aea2cc069b435fb2_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=CzfyI5haFK9gdNF8b01MBvdcl0Q%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf81d30fa666240f7aea2cc069b435fb2_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=5RRas9k%2B0E35VBfPAW3hQwiMYpM%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf81d30fa666240f7aea2cc069b435fb2_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=qQ0svMmzAZ3klPK7n8%2FbgAQnjWY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf81d30fa666240f7aea2cc069b435fb2_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=j1pR%2FfKKHABxbDH2ald9Y3JtDQs%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
        "5fee2f58-eac4-4d3d-8e01-52ad5aed2479",
        "841285bc-32fa-4273-87a0-6eb4e75b8432",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "90695732-63da-49af-bd2f-a00b8e8384c5",
      createdOn: "2023-09-25T17:03:16.267031Z",
      modifiedOn: "2023-09-26T14:14:52.193964Z",
      expiresOn: "2029-11-18T05:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "Elaina Test Asset 7",
      description: "test 7",
      source: "meredith",
      altText: "test 7",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "f46d424d-3ae8-4c35-9df8-b979f641fc19",
          groupName: null,
          fileName: "Heuristic Evaluation.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc81637367ce345d4afa208d11167fd77_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=kzRAszs0Z10Pvzwcky5HrXgNqRY%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc81637367ce345d4afa208d11167fd77_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2BoaMkiPZkLkPMZBtVI00AFUgl%2FI%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc81637367ce345d4afa208d11167fd77_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=CtftShc7QmJWl%2BPoTQtGrdk3dy4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc81637367ce345d4afa208d11167fd77_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=lcX9omcwqK%2FpD0ge7VfrJkjE40I%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "18cdbe3d-567f-47eb-9c94-9acdab78547b",
        "57c0436d-bc92-4b66-b39a-68daed6e80f3",
        "6bae776f-543c-4ed6-ab8a-309965c406e5",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "056b5b4f-73eb-41b4-8d4d-c8633e8d3706",
      createdOn: "2023-09-25T23:03:50.456199Z",
      modifiedOn: "2023-09-27T15:36:15.439186Z",
      expiresOn: "2023-10-27T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "QA Restricted 3",
      description: "I bet this makes you wanna eat cheese.",
      source: "Cass's Mental Illness",
      altText: "Wow! So cool!",
      keywords: null,
      jobNumber: "12345",
      guidelinesUrl: "https://bread.fish/",
      copyright: "All of these images belong to the child in your heart.",
      subtitle: "",
      usageRightsCopy: "You can use it but only if you tag Obama.",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "d52ed551-ea58-4f15-895a-5c0d18a31365",
          groupName: null,
          fileName: "image3234234123s.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=tsXeIJIkwX5p5ORUiMhBhNNXUkA%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=AoU1cn0OHCe3gkXSLraJqfrPyd8%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=cNLkoo7sz7MKDTY3nDqVrbatKHM%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=F%2FgciGenJ1fS%2BjGz4fzF%2BoKh2kc%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "b748d5f8-528c-428a-9ed0-8143c3918d19",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "c6b4abe1-9410-4968-8cf7-018bac13bf2b",
      createdOn: "2023-09-12T17:59:53.988665Z",
      modifiedOn: "2023-10-07T20:10:41.375546Z",
      expiresOn: "2024-04-20T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Multi file test3",
      description: "Testing to see how multiple files present in asset cards",
      source: "my desktop",
      altText: "Multi file test",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Multi file test3",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "d56cf5cb-0c67-4815-b255-98bfaa2ade72",
          groupName: null,
          fileName: "vds-carousel.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc1c8bd1610f14226b26d6388a307f080_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=HyQ9S1giLVczA9HhJEqy0dzzKoA%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc1c8bd1610f14226b26d6388a307f080_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=gsZbl6fS4ePoMvrHgrZFi0fmEtM%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc1c8bd1610f14226b26d6388a307f080_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=t%2FpIK5Ipz5XFCGo0Mqd9V4OFYXc%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc1c8bd1610f14226b26d6388a307f080_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=oh6Qct0R3%2B2Jv6BuCmtNHw%2Fgbn4%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "e492b86d-3a1d-45a0-b518-3ace937747cf",
        "121ed840-4049-43f3-a3be-ecd0887e5f11",
        "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "b8e5edca-c4ec-4358-b868-7757c1f5a4ab",
      createdOn: "2023-05-22T14:49:10.387509Z",
      modifiedOn: "2023-05-26T15:30:35.832442Z",
      expiresOn: "4545-11-22T05:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 38",
      description: "Asset 38",
      source: "Asset 38",
      altText: "Asset 38",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 38",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "7453c137-c8b9-4a3c-bb22-e3271264c78b",
          groupName: null,
          fileName: "Screen Shot 2022-10-05 at 3.24.42 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=pDSCaKT7XBlSntBp9efZALOA2l4%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=H9tywUrwbfxxwNIp3K9sxW533YA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=bZwdPAOP%2BhKTiGv3cYdSNZmWzvs%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=FLw5QYFt8umYFdzrbtogsoJGU5Y%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "e1be3260-3974-4275-b9a1-9638247ac84f",
      createdOn: "2022-10-05T19:25:36.882895Z",
      modifiedOn: "2022-10-21T14:20:36.021959Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Test asset 4",
      description: "Asset test four for access, expiration and assignment.",
      source: "Chris B.",
      altText: "Test asset 4",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "asset test 4",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "6991b423-9708-490d-8207-4d2e2ba5421f",
          groupName: null,
          fileName: "Screen Shot 2022-07-20 at 2.43.21 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=6PQ17N%2BVWhIvlyHOjgag5tCXY1s%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ywaUPKNluBaWBGeF9UwzZry2WLY%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=3211bdvvp0Im%2FYuwSeTZyGA07%2BE%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=kVHTscWeSOaBF8UH5LaH2WjmvCI%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "46e5b6fc-1b9a-4965-bafa-9d507ebb55ae",
        "e2b73d48-60a0-494e-a492-b67f352825d2",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "b8ced4d3-0a31-4b33-9611-8663f805cbf9",
      createdOn: "2022-07-21T14:11:52.564274Z",
      modifiedOn: "2022-10-21T14:20:35.951064Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 20",
      description: "Asset 20",
      source: "Asset 20",
      altText: "Asset 20",
      keywords: null,
      jobNumber: "Asset 20",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 20",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "33be5282-71aa-42db-996b-334f937c675c",
          groupName: null,
          fileName: "Screen Shot 2022-08-31 at 3.01.30 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=tZRjqVdod6e7W7BjMWydGTCoHvQ%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=CScIDAfkNQjH9erKM88BN87y7%2FA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=kjDRfnfbacCjyrfrXLZFTWV0lm4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643265&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=IlPvJTKDJeDNMr8lEBdagj9aZk4%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "df3c62ad-e25a-48d3-9d43-b119b7eed7d3",
      createdOn: "2022-10-05T18:36:36.85516Z",
      modifiedOn: "2022-10-21T14:20:36.05072Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
  ],
  filters: {
    unfilteredTotal: 89,
    categories: {
      "76007f55-b998-4883-8e53-64c5ecec4a51": 60,
      "e492b86d-3a1d-45a0-b518-3ace937747cf": 13,
      "316c0651-c5e3-4bd2-b41c-5427484f74bd": 6,
      "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f": 6,
      "18cdbe3d-567f-47eb-9c94-9acdab78547b": 4,
      "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f": 4,
      "8a3295f9-4a31-4554-aa9d-eb1933134dcc": 2,
      "1924f7c1-b223-4238-8e45-ca06a978a7ae": 1,
      "344bbcaf-766b-406d-8136-e19064297ee9": 1,
    },
    subCategories: {
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660": 52,
      "121ed840-4049-43f3-a3be-ecd0887e5f11": 8,
      "4ebecaeb-7933-4cb6-a578-4ef99e90f367": 7,
      "57c0436d-bc92-4b66-b39a-68daed6e80f3": 4,
      "36b18590-90a5-464e-85b0-a765a47e7e72": 3,
      "f19f31e1-54a7-4563-b614-ebb88631107a": 3,
      "048277fa-25ce-458e-9c03-589f84b78544": 2,
      "177c1b1b-cc61-49b0-a389-81fc1a62c320": 2,
      "25ee6b4e-0e36-445a-8740-7d432afa3e8a": 2,
      "39f6460e-7746-4767-94f7-354aa62d5253": 2,
      "45162c0d-4f5d-4b7b-a5a9-7eceeeb41e40": 2,
      "46e5b6fc-1b9a-4965-bafa-9d507ebb55ae": 2,
      "99f86197-9021-4581-9146-2c04cecd1b7a": 2,
      "c51db643-479b-46a3-b3ea-4d5c23f0db1d": 2,
      "5fee2f58-eac4-4d3d-8e01-52ad5aed2479": 1,
      "721b599a-22ec-4e45-8ac6-c63a188e073a": 1,
      "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d": 1,
      "b468837c-db94-47d2-8364-bf409a662edf": 1,
      "f9e5c0cd-1a37-424c-9e8d-98a78a859dcd": 1,
    },
    tags: {
      "9bedcce5-f0e5-4b3f-86db-2ee0b810c091": 29,
      "3d8d9269-63a0-437f-90ec-f70204d5c10f": 16,
      "9066ae36-b552-4e82-98af-f5dc2dd2fe10": 11,
      "b748d5f8-528c-428a-9ed0-8143c3918d19": 6,
      "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92": 6,
      "00b2f403-8017-4498-b591-5e7dbac2f47c": 2,
      "1d06bd8f-bb8c-4cac-ae49-e51c2be788c8": 2,
      "1db0c5c3-55f4-4471-8d4d-6a7876c82044": 2,
      "358305a0-dcd2-4002-8041-d5adfe84a734": 2,
      "6d397aaf-5e7d-40bb-983d-b670fe749003": 2,
      "990afd3e-7939-4f41-8cb4-279b1189ce29": 2,
      "b1f8dcc5-7cd5-4d08-befa-b21873f0a524": 2,
      "e2b73d48-60a0-494e-a492-b67f352825d2": 2,
      "e3e1b271-fb37-413a-9cf4-e00cede94536": 2,
      "fa9267e8-865d-4d52-aabf-558d76c1528c": 2,
      "0604a980-03fa-484d-8c3b-cbad5262c139": 1,
      "1360c2f9-4213-4aa6-81f7-3ebb94ad3972": 1,
      "1cf79ab9-d90a-4796-a4d4-e84b3b7d0c85": 1,
      "1f1042fe-789f-4997-b1ac-047743c90c57": 1,
      "37791870-297d-4c3c-8230-d988fa8625b3": 1,
      "3adead4e-7267-44fc-8845-8dc9bc216e63": 1,
      "48018f84-1fd8-4165-8078-3d83908f22d4": 1,
      "4a304e58-0e66-4130-9b92-72734694f576": 1,
      "5815e843-ab07-4efe-b8c6-0395e24f5337": 1,
      "693c6362-7a93-4b0a-a019-aa1f0437196e": 1,
      "6bae776f-543c-4ed6-ab8a-309965c406e5": 1,
      "841285bc-32fa-4273-87a0-6eb4e75b8432": 1,
      "8529fdfb-77d9-40f7-aa2e-221cc592b6ae": 1,
      "9ab6508c-29d8-44a1-a334-a21b5499c01c": 1,
      "c5c687c3-e3f1-4345-867e-51d2b86226fb": 1,
      "d9d3c77e-a000-4822-9fd8-c6a1f0885b1f": 1,
    },
  },
  form: {
    sort: "Relevance",
    andFilters: [],
    orFilters: [],
    includeRestricted: true,
    includeExpired: false,
    lastUpdated: null,
    excludeWithinExpiredDate: null,
    searchIndex: "assets",
    correlationId:
      "U0lEOmViNmE0MDcyLTIyZWItNGRhOS05ODA2LTQ4NmZhMTI0ZTk2M3xQSDowfFJJRDowMDgwY2E2Ni0yYmU4LTQ2NGQtYTYyYi1kMDEyNjRkNWJjZDI=",
    page: 1,
    significance: false,
    query: null,
    pageSize: 20,
    updateSearchHistory: false,
  },
  total: 89,
  totalPages: 5,
  elapsedMilliseconds: 37,
  correlationId:
    "U0lEOmViNmE0MDcyLTIyZWItNGRhOS05ODA2LTQ4NmZhMTI0ZTk2M3xQSDowfFJJRDpkNDMzMDRhNi1lNWY4LTRjZTAtOTZlNi1iYmNjZmMyN2ZlMjg=",
  requestFeedback: true,
};

export const assetSearchResultsLarge = {
  results: [
    {
      isActive: true,
      isSearchable: false,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Kat Test 5",
      description: "Test",
      source: "Test",
      altText: "Test",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "Test",
      subtitle: "",
      usageRightsCopy: "Test",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [],
      filterIds: [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "048277fa-25ce-458e-9c03-589f84b78544",
        "b1f8dcc5-7cd5-4d08-befa-b21873f0a524",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "0a30df4b-3f78-4be7-b7c0-86d19db7c35d",
      createdOn: "2022-06-13T14:14:33.665318Z",
      modifiedOn: "2023-09-26T15:30:05.49794Z",
      expiresOn: "2030-09-21T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: true,
      name: "Elaina Test Asset 6",
      description: "test 6",
      source: "internet",
      altText: "test 6",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 3,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "3307a28c-ffda-46ed-b98e-4446dea2116d",
          groupName: null,
          fileName: "png-transparent-watermark-thumbnail.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Wtn%2BIMh5dLxXZCzXx%2BsnIBc5Z7Q%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=x9kpQFOk2oRUEtTyhkG9BhJqE2o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=W4m6ZwAv7sUfV7aWoXYwX3DjTeo%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=KdrPfBFU8kSQeGWOBRbcHPNOVl8%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
        "c51db643-479b-46a3-b3ea-4d5c23f0db1d",
        "00b2f403-8017-4498-b591-5e7dbac2f47c",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "6fa19b24-2542-46ab-b932-0fa8b9f71ecb",
      createdOn: "2023-09-25T22:59:05.010847Z",
      modifiedOn: "2023-10-13T07:12:06.428844Z",
      expiresOn: "2026-10-26T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Test Asset 9",
      description: "Test Asset 9",
      source: "Chris B.",
      altText: "Test Asset 9",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Test Asset 9",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [],
      filterIds: [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "99f86197-9021-4581-9146-2c04cecd1b7a",
        "fa9267e8-865d-4d52-aabf-558d76c1528c",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "51288d10-2309-4fb8-9e29-38e2a3624b57",
      createdOn: "2022-07-26T17:04:25.343903Z",
      modifiedOn: "2023-06-28T14:27:18.778978Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Christine Test Asset",
      description: "testing asset request photography asset form for dashboard",
      source: "me",
      altText: "alt text",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "93a09889-754a-4725-8078-9316a34ba9e9",
          groupName: null,
          fileName: "testassetform.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=xioaT5gAuX5OEK0m3uGOpXKUJF8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=hc53AdxD1ldOXS9RIIq3dSiwJ4U%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=lkKZynytSpxRMD%2BWVRdv5hUWO50%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=LJUJl4KarDoD4v9h0ZwJhtwPEwU%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "135c532b-59ff-43a2-b328-d08f01b4fa18",
      createdOn: "2023-03-08T14:37:09.234824Z",
      modifiedOn: "2023-09-27T15:10:59.421873Z",
      expiresOn: "2023-10-31T23:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "danhuri test photoshoot 7",
      description: "danhuri test photoshoot 7 assets same job",
      source: "Danhuri",
      altText: "danhuri test photoshoot",
      keywords: null,
      jobNumber: "danhuriTestJob",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 6,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "bbd5ab05-2f60-453e-bb6a-d073c85c6231",
          groupName: null,
          fileName: "global search button.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=FiEFoET1edyN2rhaUhTiqab5uvM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ZSsJ2xSG1nKEFdMZJ9u7MGhFrto%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=9JBbT%2B5OFzYhaRcBx0rje%2FTTE4E%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=D9vMC9b92rTRScVIc9qAUBZ5GpU%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "3dbd1fd0-2c14-4002-b233-25142d5996cf",
      createdOn: "2022-08-03T13:22:07.522843Z",
      modifiedOn: "2023-08-30T17:25:59.352137Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "QA Asset Test",
      description: "test",
      source: "test",
      altText: "test",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "0099d4dd-4dab-4356-8600-0ae4b8a76d71",
          groupName: "Group 1",
          fileName: "ICONCOMPUTER (3).png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Alvau6h%2BPUT5YiNLAVroML2I6XA%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=CL7bI1gVzZzqNiUuyv4NAu%2BAtgs%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=H2oMCsrXpRekcpS%2BW5PXMNALI2Y%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Q03%2F8xq95scm0zITofMexoA2T68%3D",
            isImage: true,
          },
        },
        {
          id: "0fe937c2-a37a-482d-8b75-443349db6844",
          groupName: "group 2",
          fileName: "Icon search.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ayxFWRT%2FCVtvwX0Ndiaa91BkLpg%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=KGuEMrcTfFino1VCYStnme4K2NA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=P9Rro34ufcbzY6E2qd%2FFEiYhJNo%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=RZJ5SMvH7t7z4XGe5OxyA4pInu0%3D",
            isImage: true,
          },
        },
        {
          id: "4aeb4c25-2cdd-43a9-9ca7-04271820c846",
          groupName: "group 3",
          fileName: "SA-2054-MNYIB-V.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=6CDUVCaZ1l5VzSfB9XPSaw9YoJA%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=B%2F2YVopPTbeG9WDvEskYinsx99w%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=VVAln5d7Aw181K%2BYkh8NJWQd0mY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=x%2FDo3f2A1UFQkz6a0ACDkGdDgrQ%3D",
            isImage: true,
          },
        },
        {
          id: "cad2903c-6a7f-4b57-9773-7a23148c7ee5",
          groupName: "Group 1",
          fileName: "77-89337_E.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=UzoKlhVIYMDPQTSU4HgHyWt%2F%2BHA%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=kCT%2Bz1yefvj%2BQmGzq%2Bjgenpfk%2FU%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=aDP1C%2BX7NkrZtOSs7g7ErIMU13I%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2FG8lPAg3RUc2RITq3HWTTkJoQYc%3D",
            isImage: true,
          },
        },
        {
          id: "fd13c31b-af6a-4c81-86ca-74cf0df4bec8",
          groupName: "Group 1",
          fileName: "DBP-W22V11-1757.pdf",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=IUzVsnmxMgWNXx61DjQhSyQJR3Y%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ZRVAoa%2Fl5TXJ8HNqtinEDAYUCOc%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=coXs5JBqfMj%2BTN8k1KCJNZSO4SU%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=cKqTFHHgHkGu2ExZGsjI32vT5yU%3D",
            isImage: false,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "783eaa9e-ae1b-4e2c-8351-0a424ab226d5",
      createdOn: "2023-02-14T21:30:38.945209Z",
      modifiedOn: "2023-10-07T20:57:51.257415Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "danhuri iconography same photoshoot test 2",
      description: "danhuri iconography same photoshoot test",
      source: "Danhuri",
      altText: "danhuri iconography same photoshoot test",
      keywords: null,
      jobNumber: "DanhuriIcon",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 8,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "bf8e0067-abed-4c6a-9642-c353ea01c12f",
          groupName: null,
          fileName: "asset screen shot 3.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=wkb2q9lbTmjM%2FrQ0hbsOhqKwWY8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=xg1kbpJfj%2FlYHXGIfqtXXTZqbPo%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=sDu4c5DhpmRAY2MSSOBDnJ6pf58%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Uo7oXtW0pw11pbB93%2BCba0g5FnQ%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
        "e492b86d-3a1d-45a0-b518-3ace937747cf",
        "121ed840-4049-43f3-a3be-ecd0887e5f11",
        "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "f894b2ed-c964-4dfe-b10f-d7065797a0d2",
      createdOn: "2022-08-24T13:48:27.099944Z",
      modifiedOn: "2023-10-03T17:29:50.708363Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Louise likes the hose, hates the pool. IDK, weird dog.",
      description: "hot dog!",
      source: "me",
      altText: "hot dog",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "111b6137-5929-4fc4-8e0d-5a2f04115ed7",
          groupName: "group 1",
          fileName: "1 - Sign Up.psd",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=P7%2FFSsHdav1c9RoEHqmZY2B1194%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=mbRs4h1ENoYAI%2F8s8b1u3EGmcEs%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=V%2FF3RWSXkvz3HkEbldTs%2BIOvfqg%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=wOV9tORYbna63u8%2BBI0BbqPJ0R0%3D",
            isImage: false,
          },
        },
        {
          id: "a521b3ee-e1e5-486c-acd8-862df489e95f",
          groupName: "group 1",
          fileName: "crazy_eyes_hose_pool_outside.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL69eff9688b554b9c9f3f73ad274e7ff5_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=0S%2Bt9FY3o%2F%2B%2FtKYsvv9XT%2BvOZx8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL69eff9688b554b9c9f3f73ad274e7ff5_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=OB72n5YmF82406R5%2F9uQZ8nfbTU%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL69eff9688b554b9c9f3f73ad274e7ff5_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Hpvl9F4%2Bzn4MN4WfcCNn2NRVbx4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL69eff9688b554b9c9f3f73ad274e7ff5_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=BKAVFrZhvF%2FSH0a%2Bcfxi999t%2FsA%3D",
            isImage: true,
          },
        },
        {
          id: "ab751d1b-f761-45f7-b673-260b02bf6c9c",
          groupName: null,
          fileName: "focused_bite_water_in_pool.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf5d372ec3f524972bd78e199518db59b_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=JMRZ%2FZfbpPLqeA2IhBRhQIhk3Js%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf5d372ec3f524972bd78e199518db59b_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=t%2BnlMCr66d6tdBmValYrYFhU%2BW4%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf5d372ec3f524972bd78e199518db59b_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=YFRkLhAgZbwbu%2BfLc4Zho%2B5noqw%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf5d372ec3f524972bd78e199518db59b_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=RCyo3IwOoTIyvP5UdZzuNHiSDQc%3D",
            isImage: true,
          },
        },
        {
          id: "afde6ac5-a7a6-4b76-8c66-03ced10dc347",
          groupName: null,
          fileName: "lick_water_from_hose_outside.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc83b964662546ef8f829d7427cb94bf_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2BmQhjD7BC5cMJR99HqMGnl5TE2A%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc83b964662546ef8f829d7427cb94bf_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=41Trnj3CUsJs2RIvCX2xhA98eqk%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc83b964662546ef8f829d7427cb94bf_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=gZC9wIs92k%2FqDrOp9LLEcelsfEk%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc83b964662546ef8f829d7427cb94bf_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=hMvIwI6JFIR1q0miBhgRrJLGA%2FU%3D",
            isImage: true,
          },
        },
        {
          id: "e80a9a92-2e50-41ea-8be7-61030c8e7441",
          groupName: null,
          fileName: "crazy_eyes_bite_water.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5d12b5ee34d642879ea79c3995d7396a_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=m2K%2FUMmMPGy0NkXekujUcKiii2Q%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5d12b5ee34d642879ea79c3995d7396a_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=hz46oXMUdQgf2uH9zP6MpumQUQM%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5d12b5ee34d642879ea79c3995d7396a_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=1gyT5iJ2%2FpDfBy%2BSkU2n1GNQTRQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5d12b5ee34d642879ea79c3995d7396a_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=P%2ByUYX8naFxDRf8%2FduQzT3xFvx8%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "316c0651-c5e3-4bd2-b41c-5427484f74bd",
        "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d",
        "37791870-297d-4c3c-8230-d988fa8625b3",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "7d0db47e-dc84-4ac3-b6c9-7456f5bc9715",
      createdOn: "2023-10-04T19:18:19.579063Z",
      modifiedOn: "2023-10-07T17:57:49.864198Z",
      expiresOn: "3000-07-04T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Danhuri Test Icon Same Photoshoot 7",
      description: "Danhuri Test Icon Same Photoshoot",
      source: "Danhuri",
      altText: "Danhuri Test Icon Same Photoshoot",
      keywords: null,
      jobNumber: "DanhuriIconSamephotoshoot",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 7,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "998c0929-8b2a-4ce2-930e-bb930a150d4e",
          groupName: null,
          fileName: "global search button.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL78c28fc1c21442a392c0f906318847df_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=h6YQ48Grlw0lKiBRd05NvLzV8aQ%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL78c28fc1c21442a392c0f906318847df_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Xn3TQkiEvKuXpqxQ555lW95%2Fn1Q%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL78c28fc1c21442a392c0f906318847df_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=c%2FSjo62aB1OzvjriVjMFXxy2%2FTs%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL78c28fc1c21442a392c0f906318847df_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=np1Yh1MVtQH5orU2JiGlpYxe820%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "8a3295f9-4a31-4554-aa9d-eb1933134dcc",
        "45162c0d-4f5d-4b7b-a5a9-7eceeeb41e40",
        "1d06bd8f-bb8c-4cac-ae49-e51c2be788c8",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "fb4133f8-5e7e-44b3-afe2-a77bdb0c63e8",
      createdOn: "2022-09-01T13:33:09.851588Z",
      modifiedOn: "2023-10-13T07:49:14.724387Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: true,
      name: "Louise gives you a guilt trip!",
      description: "She doesn't want to get off the bed",
      source: "me",
      altText: "dog on bed",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 1,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "b5296824-2a1e-4f82-93fb-32a69f8850d1",
          groupName: null,
          fileName: "paws_over_bed.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=3qP%2F6Jk6NPMBZU35h0odq8l6gyc%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=qfTJ6%2BJFgi9W8S3Jl2Uy66fUPyo%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=qxDcBOO2a5IRPhO5Jql%2BCL21PSY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=sWPNLFT%2FrKggu0r%2B7W9yWVpa1dA%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "18cdbe3d-567f-47eb-9c94-9acdab78547b",
        "57c0436d-bc92-4b66-b39a-68daed6e80f3",
        "1db0c5c3-55f4-4471-8d4d-6a7876c82044",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "76e9f2b0-d65c-4a04-9ebc-69c2af6777bd",
      createdOn: "2023-10-04T19:15:29.302849Z",
      modifiedOn: "2023-10-17T13:59:37.0399685Z",
      expiresOn: "2853-05-09T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      name: "Asset 36",
      description: "Asset 36",
      source: "Asset 36",
      altText: "Asset 36",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=o5MF7Ydb8Hkhhr9OBZaVu4Xm%2BMI%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=bB374ZYqbN%2F1UzSvAdDsAYcyrfY%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=9x5LAz9XvBzDe2zqsky7%2BtQrEas%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=sQn%2BVJtnBZq%2FGNV%2Biuz6JHYSST4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=29Yy4o14UGbBBX%2BNoSGgHxTcnr8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=63F%2BLf3snZEU23OwTBB85bW%2FL5c%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=bkGLv4NEW1vEvm6BT0xj2wl1izU%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=r4rSS0sDJvdMtSHifiXnuGDZzkM%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "827e6605-4342-411e-9de3-848d80e0ac19",
      createdOn: "2022-10-05T19:12:25.759419Z",
      modifiedOn: "2023-10-24T19:18:30.4911899Z",
      expiresOn: "2024-01-24T05:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "test test test 10/2",
      description: "Test",
      source: "Test",
      altText: "Test",
      keywords: null,
      jobNumber: "Test",
      guidelinesUrl: "",
      copyright: "Test",
      subtitle: "Test",
      usageRightsCopy: "Test",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "107459fd-07d7-4dc4-9a04-f4f8f5b715d9",
          groupName: null,
          fileName: "Screenshot 2023-10-02 at 1.12.02 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e5dc2c027ce44d7917c70895fec408a_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=06%2FVNx5zEU8hEeshVZY7XK0GrKY%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e5dc2c027ce44d7917c70895fec408a_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=XKfh7OlPkLbURG4y8XtBHJCDNLw%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e5dc2c027ce44d7917c70895fec408a_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=mxYRa4ZaQUljgw2h%2B7AQeX0rn6c%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e5dc2c027ce44d7917c70895fec408a_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=oWeQW1VSteDA7j8HR4wZagSMNrA%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "316c0651-c5e3-4bd2-b41c-5427484f74bd",
        "25ee6b4e-0e36-445a-8740-7d432afa3e8a",
        "48018f84-1fd8-4165-8078-3d83908f22d4",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "20e70d79-60b6-4797-99f3-da49556d95d6",
      createdOn: "2023-10-02T20:19:52.192713Z",
      modifiedOn: "2023-10-24T19:18:30.4729122Z",
      expiresOn: "2024-01-24T05:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "Kat Test 1",
      description: "Test assets",
      source: "Kat",
      altText: "Test assets alt text",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "Copyright VZ",
      subtitle: "",
      usageRightsCopy: "Don't use these",
      downloadCount: 4,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "95971991-f599-4558-bdbf-0967d7e60de3",
          groupName: null,
          fileName: "vzcheckmark.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66d1655c51054af083935c662e951ab7_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=xCddtagsEDVzYPa3vRoYvHNrwic%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66d1655c51054af083935c662e951ab7_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=S%2BnS2b2dcMb8NMXjclcAzonC5dA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66d1655c51054af083935c662e951ab7_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=2mphHjZPmjCwi3pTAZm1H9ryV2k%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66d1655c51054af083935c662e951ab7_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=3lNlRImBTtPBTEI7Icq%2F4PuxCQc%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
        "f9e5c0cd-1a37-424c-9e8d-98a78a859dcd",
        "9ab6508c-29d8-44a1-a334-a21b5499c01c",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "37016a1d-3099-4bc5-a6cc-55b3306b6fcc",
      createdOn: "2022-06-03T19:07:22.192281Z",
      modifiedOn: "2023-09-26T00:33:22.274797Z",
      expiresOn: "2024-08-16T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "test upload fox",
      description: "test",
      source: "test",
      altText: "test",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "test",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "b56b66ab-6668-442d-ac55-4b6ce309f042",
          groupName: "group 1",
          fileName: "upload-fox.jpeg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf81d30fa666240f7aea2cc069b435fb2_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=hGspxqnqPQgg466wbG5LzGOIAy4%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf81d30fa666240f7aea2cc069b435fb2_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=y8GMFuYNquMEraiH7dRqLEJdOTg%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf81d30fa666240f7aea2cc069b435fb2_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Os64O4KbpkGePfvDawTOYscSxoE%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf81d30fa666240f7aea2cc069b435fb2_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=A%2F0cg%2BWLhEAkdmuRItwT8DkGA8U%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
        "5fee2f58-eac4-4d3d-8e01-52ad5aed2479",
        "841285bc-32fa-4273-87a0-6eb4e75b8432",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "90695732-63da-49af-bd2f-a00b8e8384c5",
      createdOn: "2023-09-25T17:03:16.267031Z",
      modifiedOn: "2023-09-26T14:14:52.193964Z",
      expiresOn: "2029-11-18T05:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "Elaina Test Asset 7",
      description: "test 7",
      source: "meredith",
      altText: "test 7",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "f46d424d-3ae8-4c35-9df8-b979f641fc19",
          groupName: null,
          fileName: "Heuristic Evaluation.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc81637367ce345d4afa208d11167fd77_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=f5c3SlzssxmceGw3DrxqdvrioZc%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc81637367ce345d4afa208d11167fd77_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=uGYmPZLAqqQANO9z8%2BqihQKyV2U%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc81637367ce345d4afa208d11167fd77_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=LLIShqfhZpAUYsKdZ4TqKLhlnn4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc81637367ce345d4afa208d11167fd77_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=QeaUQ2zlwbMdn%2FPzTda84%2B4fqGk%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "18cdbe3d-567f-47eb-9c94-9acdab78547b",
        "57c0436d-bc92-4b66-b39a-68daed6e80f3",
        "6bae776f-543c-4ed6-ab8a-309965c406e5",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "056b5b4f-73eb-41b4-8d4d-c8633e8d3706",
      createdOn: "2023-09-25T23:03:50.456199Z",
      modifiedOn: "2023-09-27T15:36:15.439186Z",
      expiresOn: "2023-10-27T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "QA Restricted 3",
      description: "I bet this makes you wanna eat cheese.",
      source: "Cass's Mental Illness",
      altText: "Wow! So cool!",
      keywords: null,
      jobNumber: "12345",
      guidelinesUrl: "https://bread.fish/",
      copyright: "All of these images belong to the child in your heart.",
      subtitle: "",
      usageRightsCopy: "You can use it but only if you tag Obama.",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "d52ed551-ea58-4f15-895a-5c0d18a31365",
          groupName: null,
          fileName: "image3234234123s.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=0CP3HDGDRXzyJP2%2FxVjKbv%2F50u0%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=kyJ%2BahX0rdAEuZHUOdoi71UxNs0%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=6Sdi9wI1bVAerNBQX5%2FI%2FJZdGlA%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=DZQFNPe9FjyNAmcnHxsVENCRxX0%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "b748d5f8-528c-428a-9ed0-8143c3918d19",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "c6b4abe1-9410-4968-8cf7-018bac13bf2b",
      createdOn: "2023-09-12T17:59:53.988665Z",
      modifiedOn: "2023-10-07T20:10:41.375546Z",
      expiresOn: "2024-04-20T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Multi file test3",
      description: "Testing to see how multiple files present in asset cards",
      source: "my desktop",
      altText: "Multi file test",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Multi file test3",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "d56cf5cb-0c67-4815-b255-98bfaa2ade72",
          groupName: null,
          fileName: "vds-carousel.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc1c8bd1610f14226b26d6388a307f080_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Ju5g0ma0GrssWkEJ7qGps7ufcUA%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc1c8bd1610f14226b26d6388a307f080_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=AmTFtSVU0EaHct%2Fc%2BIP1LGa9o6M%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc1c8bd1610f14226b26d6388a307f080_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=xLLrmFw8bf4cBVHQygSOuV9i2rk%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc1c8bd1610f14226b26d6388a307f080_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=bi0VQpLPCxJPOk2IjJdJlI%2BrW%2Fk%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "e492b86d-3a1d-45a0-b518-3ace937747cf",
        "121ed840-4049-43f3-a3be-ecd0887e5f11",
        "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "b8e5edca-c4ec-4358-b868-7757c1f5a4ab",
      createdOn: "2023-05-22T14:49:10.387509Z",
      modifiedOn: "2023-05-26T15:30:35.832442Z",
      expiresOn: "4545-11-22T05:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 38",
      description: "Asset 38",
      source: "Asset 38",
      altText: "Asset 38",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 38",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "7453c137-c8b9-4a3c-bb22-e3271264c78b",
          groupName: null,
          fileName: "Screen Shot 2022-10-05 at 3.24.42 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=HEoI2IDLmWyNG8onxtVIVh6ZnIQ%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=0KWeRjCbRY8rw6m5b0RKGBNDyWg%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=hI6lqWO6y26Fq7fpMsJb3%2Bcklzg%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=RMLqK6hWZm7IeYOTteMJQOvP9LA%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "e1be3260-3974-4275-b9a1-9638247ac84f",
      createdOn: "2022-10-05T19:25:36.882895Z",
      modifiedOn: "2022-10-21T14:20:36.021959Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Test asset 4",
      description: "Asset test four for access, expiration and assignment.",
      source: "Chris B.",
      altText: "Test asset 4",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "asset test 4",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "6991b423-9708-490d-8207-4d2e2ba5421f",
          groupName: null,
          fileName: "Screen Shot 2022-07-20 at 2.43.21 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=WYPSt6KJ9rxpQ64jYmwPDdvrYXs%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=JLIsnSCXHneio0e5KOntFCv5A74%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=v8IeqChxJ%2F7FB26mDaVNLTzd86M%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=9mcv%2FEtiTlFeTszPgwfjwiLldp8%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "46e5b6fc-1b9a-4965-bafa-9d507ebb55ae",
        "e2b73d48-60a0-494e-a492-b67f352825d2",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "b8ced4d3-0a31-4b33-9611-8663f805cbf9",
      createdOn: "2022-07-21T14:11:52.564274Z",
      modifiedOn: "2022-10-21T14:20:35.951064Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 20",
      description: "Asset 20",
      source: "Asset 20",
      altText: "Asset 20",
      keywords: null,
      jobNumber: "Asset 20",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 20",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "33be5282-71aa-42db-996b-334f937c675c",
          groupName: null,
          fileName: "Screen Shot 2022-08-31 at 3.01.30 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=OndR9mSS%2BPSYutGKb%2BZ2vK8DG6k%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Z7Iz6w1sWDa%2F81cfqLiXGaYqf4k%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ZAtc0Zzf7IwLUGOLlZQiQzsc3Ck%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=CHLYhYdM1kuJtd22w74SWwTGTck%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "df3c62ad-e25a-48d3-9d43-b119b7eed7d3",
      createdOn: "2022-10-05T18:36:36.85516Z",
      modifiedOn: "2022-10-21T14:20:36.05072Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Icon test asset QA 9-22",
      description: "Icon test asset QA 9-22",
      source: "Icon test asset QA 9-22",
      altText: "Icon test asset QA 9-22",
      keywords: null,
      jobNumber: "Icon test asset QA 9-22",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Icon test asset QA 9-22",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "99fd6393-227c-4032-96a5-04d235338077",
          groupName: null,
          fileName: "Screen_Shot_2022-09-19_at_2.17.50_PM (2).png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc4645ad91c0942dcaa877f46ce5ce7ba_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=2MfMpdp8Uzr3MtfQsCzc0g8iRB8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc4645ad91c0942dcaa877f46ce5ce7ba_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=yWOu3sBqJZOEMgDr5%2BpiHjhD5Q4%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc4645ad91c0942dcaa877f46ce5ce7ba_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=2me6NU8P80PYSmw2ppCJulIDJTw%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc4645ad91c0942dcaa877f46ce5ce7ba_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=xaJF45c9BW%2FS70Vj2iWMrPXSiuI%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "e492b86d-3a1d-45a0-b518-3ace937747cf",
        "121ed840-4049-43f3-a3be-ecd0887e5f11",
        "e3e1b271-fb37-413a-9cf4-e00cede94536",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "9241d294-e36a-4023-b32b-f0fe45bb3292",
      createdOn: "2022-09-22T13:22:37.794869Z",
      modifiedOn: "2023-02-10T14:26:32.338968Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset Chris 5",
      description: "Asset Chris 5",
      source: "Asset Chris 5",
      altText: "Asset Chris 5",
      keywords: null,
      jobNumber: "Asset Chris 5",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset Chris 5",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "35078de6-7bc4-4b02-9d6d-30a431b75e37",
          groupName: null,
          fileName: "Screen Shot 2022-09-21 at 7.29.19 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66872aef28424cfabaefac587edc262a_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Lo4I17lQehU5JDWY25A9%2BObrmUQ%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66872aef28424cfabaefac587edc262a_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=MvOoAAJGP%2F2foZQln%2ByX1Ilpvgo%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66872aef28424cfabaefac587edc262a_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=hOcDVW8Sr6Tyvid9yK7RRqwT9do%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66872aef28424cfabaefac587edc262a_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=FyECQSYXhgN%2F%2BhtqOlHzhCvJI0M%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "4e784fd4-46ce-407f-bbb0-2332df856768",
      createdOn: "2022-10-05T16:48:14.361539Z",
      modifiedOn: "2023-06-27T19:45:58.075454Z",
      expiresOn: "2424-12-31T05:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Test asset for asset request 5",
      description:
        "Needs to be approved, going to test request access emails, as well as expiring emails",
      source: "Chris B.",
      altText: "Needs to be approved",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Test asset for asset request 5",
      usageRightsCopy: "",
      downloadCount: 2,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "f3731061-1e7b-40b3-a1f1-ba486ff01903",
          groupName: null,
          fileName: "Screen Shot 2022-07-12 at 2.13.51 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL199802a6ca77441d8e6097c681c4e1f7_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=eUxuMAGzLIFDVCYBVlWAkonBZV0%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL199802a6ca77441d8e6097c681c4e1f7_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=eQqnNTIaoIpow78LhN%2BI4DtkAck%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL199802a6ca77441d8e6097c681c4e1f7_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=C1MyM%2FD%2Fq0j2GZQMybQzY%2B%2BJN2w%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL199802a6ca77441d8e6097c681c4e1f7_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=7tbwVolcfrLKanIi9ZnDMMSeIO0%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "048277fa-25ce-458e-9c03-589f84b78544",
        "b1f8dcc5-7cd5-4d08-befa-b21873f0a524",
        "316c0651-c5e3-4bd2-b41c-5427484f74bd",
        "36b18590-90a5-464e-85b0-a765a47e7e72",
        "1f1042fe-789f-4997-b1ac-047743c90c57",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "c0860908-f63a-4742-945b-21e83f352faa",
      createdOn: "2022-07-25T18:59:02.542145Z",
      modifiedOn: "2023-10-04T19:22:23.183814Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "EOA test asset-declined",
      description: "EOA test asset-declined",
      source: "my desktop",
      altText: "EOA test asset-declined",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "EOA test asset-declined",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "b748d5f8-528c-428a-9ed0-8143c3918d19",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "0b0f5916-3fda-4bd5-96df-82e32fae134e",
      createdOn: "2023-02-23T22:15:11.256538Z",
      modifiedOn: "2023-02-23T22:15:30.308389Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 22 - Test",
      description: "Asset 22",
      source: "Asset 22",
      altText: "Asset 22",
      keywords: null,
      jobNumber: "Asset 22",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 22",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "987de24f-3d0b-4dd2-beb9-10b50187f48f",
          groupName: null,
          fileName: "Screen Shot 2022-10-05 at 2.39.35 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL7b6b4cff258e4838a007de6003af7453_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=iiWKzRCqwdS6F0hqrN%2BtLjs%2B74I%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL7b6b4cff258e4838a007de6003af7453_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=F5bckdYZaZQmBR8zNaT4FyijkBw%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL7b6b4cff258e4838a007de6003af7453_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=UWAvF6kLKyPRFoJUYJZCaZSXnO8%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL7b6b4cff258e4838a007de6003af7453_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ksmlHTf37DNKVoaI4ncjHBffBwU%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "5150efd1-8c6a-44f3-958b-802f3d827eb8",
      createdOn: "2022-10-05T18:40:23.85315Z",
      modifiedOn: "2023-03-01T00:00:00Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 17",
      description: "Asset 17",
      source: "Asset 17",
      altText: "Asset 17",
      keywords: null,
      jobNumber: "Asset 17",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 17",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "dc205b27-675c-4298-8993-51a1361b92ae",
          groupName: null,
          fileName: "Screen Shot 2022-10-05 at 2.27.51 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLaef293d34a604ccf8905baf04552cc90_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=R0%2BBZbJcH5IIQKnyuGWGQY5r9wQ%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLaef293d34a604ccf8905baf04552cc90_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=4pUhUY0omtMce6kIO4oBQt5JVKk%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLaef293d34a604ccf8905baf04552cc90_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2BRBcvi%2Brybv30hzIVBcfNxzKXAY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLaef293d34a604ccf8905baf04552cc90_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643293&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=FlC4WbJfURX8ZhrVzPJnAfd07uQ%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "eecdfab7-6b54-4378-8eb1-651b314696fa",
      createdOn: "2022-10-05T18:28:38.853775Z",
      modifiedOn: "2022-11-10T20:16:36.567637Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 14",
      description: "Asset 14",
      source: "Asset 14",
      altText: "Asset 14",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 14",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "798a7afb-9bb1-4988-ba61-8c14ae716884",
          groupName: null,
          fileName: "Screen Shot 2022-07-27 at 6.11.26 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdf77adca53364e61874a58323e20dab3_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=sKhzTQ%2BngY2%2BP7xofkW1Zcdrvw8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdf77adca53364e61874a58323e20dab3_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=5wTnBYaVmZZKKpHcnFEPo8LQGsk%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdf77adca53364e61874a58323e20dab3_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=wzLs%2B86Wc%2B1IqD8bgmpzs4UpfRA%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdf77adca53364e61874a58323e20dab3_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=sfsd6s%2FeqfCD79phLmqCgAKsCHk%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "00be77d3-8f90-49a3-89e2-94b9592341c8",
      createdOn: "2022-10-05T18:24:10.372731Z",
      modifiedOn: "2023-09-27T16:40:55.330754Z",
      expiresOn: "2023-11-02T23:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Alligator in Lake",
      description: "Unlimited use of picture",
      source: "My house",
      altText: "alligator in lake",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Yuck",
      usageRightsCopy: "",
      downloadCount: 1,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "a5b3117a-3fc9-41c6-80c5-6c0aee297f43",
          groupName: null,
          fileName: "alligator.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdbfc81ab22214f56b0bed1c55c89c22d_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=1ivHlTab6uvYo94pud62Ap0tG68%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdbfc81ab22214f56b0bed1c55c89c22d_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=QwzUJ51EwZ2qQP6K1FhaThyvavA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdbfc81ab22214f56b0bed1c55c89c22d_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=o0f8CFcEs98nCKWEop7a32uqOPA%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdbfc81ab22214f56b0bed1c55c89c22d_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=GLvS6s4Eqn2l7DtENPyWWtFipOg%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "be6e1c16-ac3f-4e3f-b298-ae97164346a1",
      createdOn: "2023-04-18T01:00:29.836541Z",
      modifiedOn: "2023-08-29T19:51:18.010333Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 21",
      description: "Asset 21",
      source: "Asset 21",
      altText: "Asset 21",
      keywords: null,
      jobNumber: "Asset 21",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 21",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "6e0cf4a4-9c0b-46b6-83bf-86c0b844fbb1",
          groupName: null,
          fileName: "Screen Shot 2022-10-05 at 2.36.51 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe264354d8bc7437982b38270f7eda761_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=0ycTpHxERNGX55rYhSClgENsAWY%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe264354d8bc7437982b38270f7eda761_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=wp4VbSWKIHX4oKfTXMX20ngFnOA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe264354d8bc7437982b38270f7eda761_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=4VFEklokGZwRCXyrE0iIy8yR%2Fuo%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe264354d8bc7437982b38270f7eda761_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=1iK2L7qwtwJnkb4uRr8CLz9nLV0%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "19678c61-15a5-42c3-8e71-9d5d29a47575",
      createdOn: "2022-10-05T18:37:36.261066Z",
      modifiedOn: "2022-10-21T14:20:36.555851Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Public transportation icon",
      description: "public transportation icon",
      source: "McCann NY",
      altText: "public transportation icon",
      keywords: null,
      jobNumber: "transportation",
      guidelinesUrl:
        "https://brandcentral.verizonwireless.com/guidelines/details/masterbrand-elements/iconography/overview",
      copyright: "",
      subtitle: "Transportation",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "2ba14056-13d6-4d34-a183-2bbfdc6becc1",
          groupName: null,
          fileName: "public-transportation_suite.zip",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc596b3cd48e49528fdf4f826e152db2_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=xnb8o1S6E3wNDftkVbCshrIhJuQ%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc596b3cd48e49528fdf4f826e152db2_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=19FOQdPMuMsikfJ6gvg3XPN6A%2FY%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc596b3cd48e49528fdf4f826e152db2_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=urrqifjJdOba3Glp2lq9YrzPbTA%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfc596b3cd48e49528fdf4f826e152db2_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=SF0zSLSEHPKd5yBp3wm%2FqfhymP4%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "e492b86d-3a1d-45a0-b518-3ace937747cf",
        "f19f31e1-54a7-4563-b614-ebb88631107a",
        "8529fdfb-77d9-40f7-aa2e-221cc592b6ae",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "5c4a6c52-cb87-4f72-b02c-6fe22a01f33a",
      createdOn: "2023-09-27T16:57:22.776091Z",
      modifiedOn: "2023-09-27T18:01:08.206404Z",
      expiresOn: "2023-11-01T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "QA Restricted 4",
      description: "I bet this makes you wanna eat cheese.",
      source: "Cass's Mental Illness",
      altText: "Wow! So cool!",
      keywords: null,
      jobNumber: "12345",
      guidelinesUrl: "https://bread.fish/",
      copyright: "All of these images belong to the child in your heart.",
      subtitle: "",
      usageRightsCopy: "You can use it but only if you tag Obama.",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "c0d3dcb1-831a-44fe-8556-81b4d682ff15",
          groupName: null,
          fileName: "0z0vcyxc4sz21.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf3c8dcf74d674325afdf3bbe345d4201_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=uPn0OiWbMsCIUx%2FCuQ8JH0sxs%2Bw%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf3c8dcf74d674325afdf3bbe345d4201_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=RrDl9HqbbwtkscD5hUsDtzvhS%2FA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf3c8dcf74d674325afdf3bbe345d4201_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=hLR7DMnaTbb0zwx%2FSNgzdqCDOmw%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf3c8dcf74d674325afdf3bbe345d4201_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=dcASrijRtsLa1cJEpp5zutnaJiA%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "b748d5f8-528c-428a-9ed0-8143c3918d19",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "8ad52a87-b10b-43b5-8188-3b9da7d91614",
      createdOn: "2023-09-12T17:59:54.021936Z",
      modifiedOn: "2023-09-12T18:20:55.551968Z",
      expiresOn: "2024-04-20T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "Smug",
      description: "goat",
      source: "internet",
      altText: "goat",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "baaaaaaa",
      downloadCount: 1,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "8c680427-9689-4c2f-ac58-2eee5c04531f",
          groupName: "goat group 1",
          fileName: "smug_goat.jpeg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe236b2fcd1e94d919de62dde7e1f0d44_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=0id3%2FStPM7owzTXv5vfLVkS3T%2B4%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe236b2fcd1e94d919de62dde7e1f0d44_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=r3a8SsdSn6aDV7XsBQJO5fp5nz8%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe236b2fcd1e94d919de62dde7e1f0d44_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=fGdcsLMpQwSS5x%2FtH%2B1BnTvyEDI%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe236b2fcd1e94d919de62dde7e1f0d44_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=VsqpYEo8a8NX34AGeVO4RvEh4YE%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "18cdbe3d-567f-47eb-9c94-9acdab78547b",
        "57c0436d-bc92-4b66-b39a-68daed6e80f3",
        "1360c2f9-4213-4aa6-81f7-3ebb94ad3972",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "eade9ff2-57c1-409c-8182-dd513e56749b",
      createdOn: "2023-09-27T20:54:31.978114Z",
      modifiedOn: "2023-09-28T01:00:12.346409Z",
      expiresOn: "2041-02-28T05:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "Multiple Pending Files Test",
      description: "multiple pending files",
      source: "computer",
      altText: "multiple pending files",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "20d9614e-db3d-4493-8ae4-9a0624921f54",
          groupName: "",
          fileName: "v62do72isb531.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3eba4ac6a0464118b712a9ec87e84119_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=JOUJ%2FeoesDMiiLY0WJJvHis93DA%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3eba4ac6a0464118b712a9ec87e84119_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=1ulUC9k8zDPi5vNGTGDxMFAsErg%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3eba4ac6a0464118b712a9ec87e84119_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=pcOupJ%2F8IIAyDGhmEX3BfVZptIA%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3eba4ac6a0464118b712a9ec87e84119_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=pxyGd1XHeK%2F4GtFWA%2Fbng34r7D8%3D",
            isImage: true,
          },
        },
        {
          id: "b9ec7653-98fd-4d33-a9a2-fc7d6c60eb7d",
          groupName: "",
          fileName: "puppies2.jpeg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL9f4649361f86425792b5bce6b0f6c4f9_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=EEWdGMEqGN%2BVL1ia4iGGzSrdDuQ%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL9f4649361f86425792b5bce6b0f6c4f9_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=osKdS%2BARJrlxYpupbhqOdsoe5XQ%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL9f4649361f86425792b5bce6b0f6c4f9_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=6W7jYKjk4ILd2NokXtdqXTL5YJQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL9f4649361f86425792b5bce6b0f6c4f9_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=U8%2FsIROooiRJztRnhXDxKf2ccaM%3D",
            isImage: true,
          },
        },
        {
          id: "a5a3605a-ad49-419e-bd41-7ca02bcf6dbc",
          groupName: "",
          fileName: "puppies3.jpeg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL8451b1e5da674f46955f5ebd1d948cc1_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=NjhCZE%2B%2Fn%2F%2F3pn64fQ8OlCYlAiw%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL8451b1e5da674f46955f5ebd1d948cc1_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=h5rAnmIWW3jso7q%2FgVwNh41Rr7Y%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL8451b1e5da674f46955f5ebd1d948cc1_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=jcswY97MUDRSjfGJhc8ddmmAXrM%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL8451b1e5da674f46955f5ebd1d948cc1_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=JLiKM%2BVQOjoFEDp4Dm8wJZjIbds%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "18cdbe3d-567f-47eb-9c94-9acdab78547b",
        "57c0436d-bc92-4b66-b39a-68daed6e80f3",
        "1db0c5c3-55f4-4471-8d4d-6a7876c82044",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "bd11605e-9b3a-4074-a466-20e81b94a69f",
      createdOn: "2023-09-28T14:16:35.618167Z",
      modifiedOn: "2023-10-02T15:48:32.23817Z",
      expiresOn: "2024-08-13T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Danhuri Icon Same Photoshoot 4",
      description: "Danhuri Icon Same Photoshoot",
      source: "Danhuri",
      altText: "Danhuri Icon Same Photoshoot",
      keywords: null,
      jobNumber: "DanhuriIconSamephotoshoot",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "be914db1-f418-41ed-a992-aab0b7927b91",
          groupName: null,
          fileName: "asset screen shot new version 3.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL111a9aad0b7f4edab0d93865491d1dde_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Jn5BrGtRuVM9zdEOfzzEIMCPziY%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL111a9aad0b7f4edab0d93865491d1dde_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=TTQxPq%2FCLpm7hEsm8DyexWZGDac%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL111a9aad0b7f4edab0d93865491d1dde_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=jnaUWbapt6rUy%2FUaJ9%2BUyY%2Ff9Lg%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL111a9aad0b7f4edab0d93865491d1dde_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=QbPfUrP8s%2B6r%2BVOdpo8NU9WMd8o%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "e492b86d-3a1d-45a0-b518-3ace937747cf",
        "121ed840-4049-43f3-a3be-ecd0887e5f11",
        "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "0e82b1a1-94b8-4258-9881-9020d3cfffa1",
      createdOn: "2022-09-01T13:33:09.864169Z",
      modifiedOn: "2023-03-08T14:42:00.335214Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: false,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Restricted nonsearchable",
      description: "Restricted nonsearchable",
      source: "Restricted nonsearchable",
      altText: "Restricted nonsearchable",
      keywords: null,
      jobNumber: "12345",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Restricted nonsearchable",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "27646746-d6cd-4ee4-94e9-0bcd63fe5f12",
          groupName: null,
          fileName: "Brand_Central_attachment.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=YG1bENfQ0f1uebet%2FY9btjwoyCg%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=o9Uwl%2BHydYk2rQcIGuPSfvrzOzI%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=0jl2DRQGlA5NErk%2BcRYBNvnAtWs%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=W1lF662yveXPAK04xBmmhm3gBb0%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "dd481174-fc24-4dc4-a366-9f7bae61c0e9",
      createdOn: "2022-09-09T14:12:50.788349Z",
      modifiedOn: "2023-10-03T14:58:22.360402Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Danhuri Icon Same Photoshoot 1",
      description: "Danhuri Icon Same Photoshoot",
      source: "Danhuri",
      altText: "Danhuri Icon Same Photoshoot",
      keywords: null,
      jobNumber: "DanhuriIconSamephotoshoot",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 2,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "6d0f81b9-694a-452a-90b5-b04196421352",
          groupName: null,
          fileName: "asset screen shot 2.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc92d1ab31a3445c7ab9827308c78fc20_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=7L5f4p%2FrGbwcRrJviEMzMzcW%2Bt4%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc92d1ab31a3445c7ab9827308c78fc20_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=K40ax5Dc2o9isu9hccY3dmrBq5w%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc92d1ab31a3445c7ab9827308c78fc20_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=HtaMrIHlGWXeAsMgpvENm3%2FAVyI%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc92d1ab31a3445c7ab9827308c78fc20_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=PczDTTHVYeoWIVlKQ8h0KujvOys%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "e492b86d-3a1d-45a0-b518-3ace937747cf",
        "121ed840-4049-43f3-a3be-ecd0887e5f11",
        "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "0d7c9e83-4d86-45d0-a5ad-b260406f16c2",
      createdOn: "2022-09-01T13:33:09.820075Z",
      modifiedOn: "2023-08-10T15:04:52.25448Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "QA Restricted 6",
      description: "I bet this makes you wanna eat cheese.",
      source: "Cass's Mental Illness",
      altText: "Wow! So cool!",
      keywords: null,
      jobNumber: "12345",
      guidelinesUrl: "https://bread.fish/",
      copyright: "All of these images belong to the child in your heart.",
      subtitle: "",
      usageRightsCopy: "You can use it but only if you tag Obama.",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "f30c6330-6587-4838-99d2-9452b9461a61",
          groupName: null,
          fileName: "images.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2643da4983974e7394c6a32f65f735d0_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=VLgDm19Ie8WjqRx6hWCK5gFoGpM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2643da4983974e7394c6a32f65f735d0_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=t5n1FP9QGWAcnnBtfjNZ7w8917s%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2643da4983974e7394c6a32f65f735d0_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=j%2FsqrnTzlyFSXiO9OzwK841yolk%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2643da4983974e7394c6a32f65f735d0_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Aci3ItenYFeTimypk%2B0JadhOWQ0%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "b748d5f8-528c-428a-9ed0-8143c3918d19",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "e58c32c0-f286-4144-89a9-9b43dc79dd61",
      createdOn: "2023-09-12T17:59:54.01894Z",
      modifiedOn: "2023-09-12T18:20:01.994566Z",
      expiresOn: "2024-04-20T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 37",
      description: "Asset 37",
      source: "Asset 37",
      altText: "Asset 37",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 37",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "6f165adf-c0e3-4159-acf3-affcaf25daa4",
          groupName: null,
          fileName: "Screen Shot 2022-10-05 at 3.23.39 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLedcbcc5ed3874f0fb0382fb923e18ece_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=i4yQHkk%2Fu1ZWo6XQHENRZGBOeAg%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLedcbcc5ed3874f0fb0382fb923e18ece_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=De5IM28X2Ood7W5Pq0TGm%2BAPlg0%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLedcbcc5ed3874f0fb0382fb923e18ece_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=yZZ2UdrV6TDFhBPzyfbR5KXDJP8%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLedcbcc5ed3874f0fb0382fb923e18ece_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=QGRNEZsQ3uTxmmvNegDeQAatzGo%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "4105ca55-36cf-4e87-a64a-7edf93a3869c",
      createdOn: "2022-10-05T19:24:21.793831Z",
      modifiedOn: "2022-10-21T14:20:36.014051Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 32",
      description: "Asset 32",
      source: "Asset 32",
      altText: "Asset 32",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 32",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "2f90351b-4313-42fb-971d-1854bbbf3cfc",
          groupName: null,
          fileName: "Screen Shot 2022-10-05 at 3.04.21 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL990a9550c5a3478d96c4b1ec330aa908_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Oqh5V86qUs26LACxwaYKv5ZdQ8c%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL990a9550c5a3478d96c4b1ec330aa908_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=szlxb%2BeQfRtK%2BTbrdfocdAjPzaU%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL990a9550c5a3478d96c4b1ec330aa908_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2BkB7gJ7mlaEjBs3Erp7gNEyI5G8%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL990a9550c5a3478d96c4b1ec330aa908_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=R2SbWDpO3aOLbROXXzMwVVWm4Qc%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "fdaa4b50-6923-452b-a9b2-8772c969ad0a",
      createdOn: "2022-10-05T19:06:47.837666Z",
      modifiedOn: "2022-11-11T16:13:20.878079Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 19",
      description: "Asset 19",
      source: "Asset 19",
      altText: "Asset 19",
      keywords: null,
      jobNumber: "Asset 19",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 19",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "666c65d6-0c3f-451e-8b27-b1457eeb8e11",
          groupName: null,
          fileName: "Screen Shot 2022-10-05 at 2.30.52 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40143b7fe7e74917a0a4d7af33c62101_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=zs%2BIZmAEN4zoe5QXErjzHHvh6qk%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40143b7fe7e74917a0a4d7af33c62101_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=GO9kDXDQvqY4bCt%2BfSNhd%2BR7rUQ%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40143b7fe7e74917a0a4d7af33c62101_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Ndv%2Breksir3568MCTAoQ9t73zi4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40143b7fe7e74917a0a4d7af33c62101_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=7ZZK7T59yA7eiANl5wWzC2DULMA%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "24a1b13c-483f-4220-a1d4-cbee3ed3813a",
      createdOn: "2022-10-05T18:31:51.647943Z",
      modifiedOn: "2022-10-21T14:20:36.084805Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset Chris 7",
      description: "Asset Chris 7",
      source: "Asset Chris 7",
      altText: "Asset Chris 7",
      keywords: null,
      jobNumber: "Asset Chris 7",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset Chris 7",
      usageRightsCopy:
        "Some usage rights info right here for testing purposes.",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "7923a31c-3b21-445d-9889-95bcccae3545",
          groupName: null,
          fileName: "Screen Shot 2022-10-05 at 9.45.33 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL65599f9f6c35491d8a73a266d6badedd_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ay9BtrpBacGahOX0TqM4UBZ%2Ble8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL65599f9f6c35491d8a73a266d6badedd_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=7qNlc1pVXBScUyzFq7DWCqVq9R0%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL65599f9f6c35491d8a73a266d6badedd_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=22ESFy0XFKQfJA%2F3z9iCtg3LjXs%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL65599f9f6c35491d8a73a266d6badedd_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=AAOtjPbYBtJ03q0Q5Gn72s41RXY%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "c2d42efd-dfb1-4dbf-a0fe-855272140b62",
      createdOn: "2022-10-05T18:06:57.54192Z",
      modifiedOn: "2022-12-07T18:01:28.075343Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 11",
      description: "Asset 11",
      source: "Asset 11",
      altText: "Asset 11",
      keywords: null,
      jobNumber: "Asset 11",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 11",
      usageRightsCopy: "",
      downloadCount: 1,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "a04e6b31-f39a-4f7a-bd35-fbfcd39bf23a",
          groupName: null,
          fileName: "Screen Shot 2022-08-24 at 11.54.36 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf65564953534431ab8a4a0558226dfb4_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Vj933LJvvmlFOjpR0oMV0muz3Y8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf65564953534431ab8a4a0558226dfb4_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=hysG2uBK9C8WoKzuS3Houxp1lhg%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf65564953534431ab8a4a0558226dfb4_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=tv73Cz7Se8mkGBOjOgAPD1LBVrk%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf65564953534431ab8a4a0558226dfb4_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=PbNV4jj1zEISppvIYFyO1PXh5Bo%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "990afd3e-7939-4f41-8cb4-279b1189ce29",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "c4ab950f-622c-4df7-8c60-d2938200ca20",
      createdOn: "2022-10-05T18:19:34.341549Z",
      modifiedOn: "2023-08-29T19:51:18.028724Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "danhuri test photoshoot 6",
      description: "danhuri test photoshoot 7 assets same job",
      source: "Danhuri",
      altText: "danhuri test photoshoot",
      keywords: null,
      jobNumber: "danhuriTestJob",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "9521ebfc-b312-4082-bdb0-9f6b10e23542",
          groupName: null,
          fileName: "global search button 2.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5835e34f2bd4401799108c276dd4f041_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=mlQ%2Br2LW4ohN1e4yZyC8XAt7C5A%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5835e34f2bd4401799108c276dd4f041_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=VU2yawoLf9DREBJlmmWXAEIfYvs%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5835e34f2bd4401799108c276dd4f041_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Bt9VmcN89zSXu62RBUE%2FozcC1Wc%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5835e34f2bd4401799108c276dd4f041_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=7VI160lsJrmi3nWG%2F9RUIeXEQg8%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "f46409c7-1855-474e-851d-8ff805c7d45b",
      createdOn: "2022-08-03T13:22:07.261856Z",
      modifiedOn: "2023-03-08T14:42:00.33523Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Asset 29",
      description: "Asset 29",
      source: "Asset 29",
      altText: "Asset 29 test",
      keywords: null,
      jobNumber: "Asset 29",
      guidelinesUrl:
        "https://brandcentral.verizonwireless.com.stg.epqa.us/guidelines/details/masterbrand-elements/logo/verizon-logo",
      copyright: "",
      subtitle: "Asset 29",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "dbfe1b9d-404d-443d-a472-533cbd92cc6f",
          groupName: null,
          fileName: "Screen Shot 2022-10-05 at 2.55.16 PM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL844c12123546435791501090e17dc8c2_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=YhgSn1a3QdkI%2BAJ3wkc5uxWNs%2BU%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL844c12123546435791501090e17dc8c2_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=NSXC38cSt4SjfWWFG9RUJ2rZjw4%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL844c12123546435791501090e17dc8c2_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=f8dbQjHW84GuFWanmnQwSHgsP7s%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL844c12123546435791501090e17dc8c2_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=lKGJMABkxb0HrosTzdk8LjEqUXM%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "d7943e08-3d9b-4a54-a287-a04e60bcf811",
      createdOn: "2022-10-05T18:57:05.245611Z",
      modifiedOn: "2023-09-28T14:45:39.290041Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "QA Restricted 1",
      description: "I bet this makes you wanna eat cheese.",
      source: "Cass's Mental Illness",
      altText: "Wow! So cool!",
      keywords: null,
      jobNumber: "12345",
      guidelinesUrl: "https://bread.fish/",
      copyright: "All of these images belong to the child in your heart.",
      subtitle: "",
      usageRightsCopy: "You can use it but only if you tag Obama.",
      downloadCount: 1,
      thumbnailCountOverride: 1,
      approvalStatus: "Approved",
      files: [
        {
          id: "08c923e6-59ff-4781-99b6-d77460dca5a3",
          groupName: null,
          fileName: "d4f9915b3666d3264eb8e18058a06934.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLa2cb40873183483498144390c1dccdcd_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ANwjRJ%2FZqQirgSsHX7VXI7hTaek%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLa2cb40873183483498144390c1dccdcd_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ZBxWKN1NC%2BIk6WHmVWu9d1nKNZM%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLa2cb40873183483498144390c1dccdcd_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ZmAUj55XPwY7MJgPfU744zWdSvY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLa2cb40873183483498144390c1dccdcd_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=DplrkZolMYYrowHbh%2BMv31Xt3Ks%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "9a1b43b7-0736-46b2-a4bf-9384e7386357",
      createdOn: "2023-09-12T17:59:53.97119Z",
      modifiedOn: "2023-09-20T18:02:42.235934Z",
      expiresOn: "2024-04-20T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: false,
      canDownload: true,
      isFavorite: false,
      name: "QA multiple pending",
      description: "This is a test",
      source: "QA",
      altText: "test",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "223d85bb-77d6-4c82-9799-18e828e814c0",
          groupName: null,
          fileName: "9679392724_d7a50c4270_b.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd662212d18af4b2f8a5e084f7a74db7a_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=ing5UOtG3BRD0n06vrBdPatFMa4%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd662212d18af4b2f8a5e084f7a74db7a_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=TLddQubk79Ij2g7%2BqKO5ZJTCNQo%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd662212d18af4b2f8a5e084f7a74db7a_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=iVhXtR%2FP7O21MoTPbZIlpF5UHKI%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd662212d18af4b2f8a5e084f7a74db7a_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=A6axWANVzwUwJiTRcUFVyfx8qQg%3D",
            isImage: true,
          },
        },
        {
          id: "3c29a366-c011-44fc-ae4a-94dd701f200f",
          groupName: null,
          fileName: "Merlin.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d125cbcb7bd423fb1e35799c27ba6ac_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=S9jWr0K7jQJqIgJMA0MZKVSp04M%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d125cbcb7bd423fb1e35799c27ba6ac_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=WAxccj7mCWoJNE4LyR8jqWXT0Ys%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d125cbcb7bd423fb1e35799c27ba6ac_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=VtnD1IlIsjFsRVtABHTBCjafQEY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d125cbcb7bd423fb1e35799c27ba6ac_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=nyckGXV4YXvckXyhvlEL061Heac%3D",
            isImage: true,
          },
        },
        {
          id: "71b18f6a-4d69-4236-894c-1750edf208d1",
          groupName: null,
          fileName: "Islands.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL31ed60c22a7042ab86ac7eb1c5b26126_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=lkYrmgQcmmXwwTl7xGW2rv6WaYs%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL31ed60c22a7042ab86ac7eb1c5b26126_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=KhhjWKIzAmNe4yXHjoprhNSmlhE%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL31ed60c22a7042ab86ac7eb1c5b26126_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=xiWPH6JzHqSp%2FdQTvU8O6%2BM0vJs%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL31ed60c22a7042ab86ac7eb1c5b26126_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=GmpOKAmz7ZLNw7jgfTZeVsDA5UA%3D",
            isImage: true,
          },
        },
        {
          id: "a59a7ae1-28fb-4912-9b59-ecdb66b1897a",
          groupName: null,
          fileName: "Scala.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc3043f8b94a84ba0a4c9a85ae233e9ea_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=gwaTJnYkv9tfUeOJiCKjE0If2OE%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc3043f8b94a84ba0a4c9a85ae233e9ea_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=CeTI0f6B92hzFJNSu6dssm%2BIiuE%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc3043f8b94a84ba0a4c9a85ae233e9ea_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=fEXhLF%2FBxq5TZ2ndl5SAHfQj8Rg%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc3043f8b94a84ba0a4c9a85ae233e9ea_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2BEJU0pDiVYR8LR71SBF6AxLMiwo%3D",
            isImage: true,
          },
        },
        {
          id: "e4e5b3a5-9155-434a-a058-45017642920d",
          groupName: null,
          fileName: "TwilightTown.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3daf2ef3ebb14b85b05cb55164fcdb3c_110.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=991lOUm3lL%2Bxbj1%2Fxyua6iycJbw%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3daf2ef3ebb14b85b05cb55164fcdb3c_400.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=8M1khJhBSFE131m5mqIgNDHDBVQ%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3daf2ef3ebb14b85b05cb55164fcdb3c_500.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=xlhbOfQE0B7gSEcSYQaTmsBL0xE%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3daf2ef3ebb14b85b05cb55164fcdb3c_700.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=yAU4hk%2ButkoVevlQYBVyHFLlOoM%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "177c1b1b-cc61-49b0-a389-81fc1a62c320",
        "6d397aaf-5e7d-40bb-983d-b670fe749003",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "e344973c-2bc3-42e0-be04-083211117c99",
      createdOn: "2023-10-03T15:06:33.855401Z",
      modifiedOn: "2023-10-06T13:57:48.16423Z",
      expiresOn: "2023-10-31T04:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "SEM test asset-approved",
      description: "SEM test asset-approved",
      source: "my computer",
      altText: "SEM test asset-approved",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "SEM test asset-approved",
      subtitle: "",
      usageRightsCopy: "SEM test asset-approved",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "30a8daf4-7dfa-450d-9091-6c32594aa485",
          groupName: null,
          fileName: "1981_christmas-gun.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL70b846f7d43947999e6d75b404aad0cc_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=f7fWZIE%2FPXlp4I90h9DT12OzaJE%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL70b846f7d43947999e6d75b404aad0cc_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=oVHEmPU1gTM9cWjy9xXmZ9m51wY%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL70b846f7d43947999e6d75b404aad0cc_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=PYAJ4HniiluL%2BG0S4htx0kaiB6o%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL70b846f7d43947999e6d75b404aad0cc_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=tylf2O5WQUwFZKIGlUMgcQKe5VQ%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "177c1b1b-cc61-49b0-a389-81fc1a62c320",
        "6d397aaf-5e7d-40bb-983d-b670fe749003",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "34e8937f-443b-4ed0-979b-66d9b2601e61",
      createdOn: "2023-02-21T15:38:57.130945Z",
      modifiedOn: "2023-09-27T18:37:06.227343Z",
      expiresOn: "2030-10-10T23:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Test Asset 8 - one approve, one deny",
      description: "Test Asset 8 - one approve, one deny",
      source: "Chris B.",
      altText: "Test Asset 8 - one approve, one deny",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Test Asset 8 - one approve, one deny",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "63221ace-0a2e-4bb6-b79d-098fbca40444",
          groupName: null,
          fileName: "Screen Shot 2022-07-11 at 9.52.39 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1c6d3b4b6e894fb188dadafab9b888a1_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=AfGdXBY%2BijcttLwlcKcb1%2BLbXdw%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1c6d3b4b6e894fb188dadafab9b888a1_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=k6kDbSFK1DgyLise0Z1MYGork6s%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1c6d3b4b6e894fb188dadafab9b888a1_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=fTOXWpp7oy7KrTBhSMErFuWAQ10%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1c6d3b4b6e894fb188dadafab9b888a1_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=%2B8xMXJwnbR0royQbzSkqYS1UMsg%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "46e5b6fc-1b9a-4965-bafa-9d507ebb55ae",
        "e2b73d48-60a0-494e-a492-b67f352825d2",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "f86b7deb-d738-487d-894b-1bf89f16ad14",
      createdOn: "2022-07-26T17:01:14.22962Z",
      modifiedOn: "2022-10-21T14:20:36.587967Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Test asset for access denial, asset 6",
      description: "Test asset for access denial, asset 6",
      source: "Chris B.",
      altText: "Test asset for access denial, asset 6",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Test asset for access denial, asset 6",
      usageRightsCopy: "",
      downloadCount: 2,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "aeb391db-5284-404e-8e1a-37db824634b1",
          groupName: null,
          fileName: "Screen Shot 2022-07-20 at 9.11.27 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e7558e9a0794a20a21ef36747dec958_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=izzN%2F65vfyA0iEYsMv%2FHfv9CpSM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e7558e9a0794a20a21ef36747dec958_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Hy75cKk6Lm%2F8Maxrv0P1GPr5JZ4%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e7558e9a0794a20a21ef36747dec958_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=cJ5%2BrqcxOXlvT%2BbFMNVnbO0%2FZpA%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e7558e9a0794a20a21ef36747dec958_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=Z7B1cYdQ7v9SMxBmVuwR2WAkM9g%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "99f86197-9021-4581-9146-2c04cecd1b7a",
        "fa9267e8-865d-4d52-aabf-558d76c1528c",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "015fed73-b798-4bd3-8c05-6c46eb22f7d7",
      createdOn: "2022-07-25T19:04:05.395156Z",
      modifiedOn: "2023-07-24T16:17:27.766359Z",
      expiresOn: "2424-12-31T00:00:00Z",
      score: 0,
    },
    {
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: false,
      name: "Onboarding for new agencies",
      description:
        "For new agencies who will use the Verizon brand guidelines to create communications in Verizon's brand identity.",
      source: "EP+Co",
      altText: "Onboarding for new agencies",
      keywords: null,
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Onboarding for new agencies",
      usageRightsCopy: "",
      downloadCount: 0,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      files: [
        {
          id: "6b603ec7-204a-429e-a59b-95a11435dc36",
          groupName: null,
          fileName: "Agency_Onboarding_One-Sheeter (3).pdf",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5456efa044a04269b41ccf92a85c547a_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=B%2B40XMF5U6LDlo14bZPCCh%2BMAkc%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5456efa044a04269b41ccf92a85c547a_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=otPggR%2FoA8R0vQg3cp%2BCZutVl0o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5456efa044a04269b41ccf92a85c547a_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=4o88IZoY0JMlZrvNjprWx7jcqeQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5456efa044a04269b41ccf92a85c547a_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK7X5IWNK&Expires=1698643294&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJHMEUCICQf3YmDGbwQNvE4yOtBNHv92lL9oZ%2BXwHDC%2F0GDBDAOAiEAo3BdBatKOnPqDDFC67234JdxLHBZR9fJMIjWw8VKSjEqgwQIUhADGgwyOTA5OTEwMzcxNDIiDIeLo1wldUEEEXo8hSrgA1yOaKJFjOMsJSwMCgInia%2B7L8AQ%2FknE%2BPkZ9gzEbvdWwTqvG2zyghWIlzNZRTorI0HTI75JvvwbahL3xZaBuOkVLlOact0KLFe0HqqZDrcrC9%2F%2Bzg5kTtd8Vn5z%2BjkSKqX2MN4juAP9DEhfxQfbynj0TGM2wVrVgGrP%2BJgvoXdZqE%2BzIwpVh03ZsHYNuq3neKcntxM2bXH84FFRQlxVxeLMXaRjVvwtnkzvRReIsA79d7SX92yCZAA58F5mF0XO%2BPyWqnuRg%2BmG43RgbIjryIz3%2FtOVePhcrrWni2F5nIr24Hlu9tWY5CT1019I90S%2FnkvK0Dt2mKEhMBtSFx07OqFST6bVDKWiCMUcWftj2oEYIAaF6PTMl3pl92qb4KmKG9ueZadO%2FjcPs6Y%2BY4QKP0MAyTeMcegoqgYoFUb04VhFT5As2saXg9b07GQ%2FdbcB5flxtBR5kDk7j950sqO3xL8RCl%2FYmITsN5%2BcPzgwxOYJZQsIkCSoSACBj24%2F%2FqRF%2BvoHnJXSCRfmhCzikdyDnIK2js6q7EWvAkKcBb9DbgTEJVvm%2FolrVw%2Fc9O73OtxDZs%2F2%2Bbd%2FoEdgkr93Wiz%2FnA%2BFW%2FgUII%2B3yLEl8s%2BhAkL%2BwL6CO2pehIeAKYY8FB7skzCfz%2BGpBjqlAb7tBK8AoqevUdWiWs76o4dTe1XeIRPW9rQhsTJNPx9%2Fg9285Nc7oKedp1%2B20rVeH0q2lTpK0R3SpRdsF91C7s01O%2FvZuJHd%2BRhzZKRv9YQPM7fpugNoMbri4DYFmnfYYPhxIcMrp2BrdDY0mtAjGrfwhGtMHBaOrlDb%2FbOtbpHPHfvxr0wRfgzih52SI%2B8jtWmIlLUPg%2F2eaIBJZyUb4l8saooiJw%3D%3D&Signature=8MXB%2FnzjIIh0mwQ%2BWYVPrgUXzN4%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "1924f7c1-b223-4238-8e45-ca06a978a7ae",
        "b468837c-db94-47d2-8364-bf409a662edf",
        "3adead4e-7267-44fc-8845-8dc9bc216e63",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "dbc5836f-ed57-4237-a2d5-828c08c8ee2c",
      createdOn: "2022-10-14T13:55:49.85224Z",
      modifiedOn: "2022-10-21T14:20:36.623011Z",
      expiresOn: "2040-10-19T00:00:00Z",
      score: 0,
    },
  ],
  filters: {
    unfilteredTotal: 89,
    categories: {
      "76007f55-b998-4883-8e53-64c5ecec4a51": 60,
      "e492b86d-3a1d-45a0-b518-3ace937747cf": 13,
      "316c0651-c5e3-4bd2-b41c-5427484f74bd": 6,
      "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f": 6,
      "18cdbe3d-567f-47eb-9c94-9acdab78547b": 4,
      "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f": 4,
      "8a3295f9-4a31-4554-aa9d-eb1933134dcc": 2,
      "1924f7c1-b223-4238-8e45-ca06a978a7ae": 1,
      "344bbcaf-766b-406d-8136-e19064297ee9": 1,
    },
    subCategories: {
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660": 52,
      "121ed840-4049-43f3-a3be-ecd0887e5f11": 8,
      "4ebecaeb-7933-4cb6-a578-4ef99e90f367": 7,
      "57c0436d-bc92-4b66-b39a-68daed6e80f3": 4,
      "36b18590-90a5-464e-85b0-a765a47e7e72": 3,
      "f19f31e1-54a7-4563-b614-ebb88631107a": 3,
      "048277fa-25ce-458e-9c03-589f84b78544": 2,
      "177c1b1b-cc61-49b0-a389-81fc1a62c320": 2,
      "25ee6b4e-0e36-445a-8740-7d432afa3e8a": 2,
      "39f6460e-7746-4767-94f7-354aa62d5253": 2,
      "45162c0d-4f5d-4b7b-a5a9-7eceeeb41e40": 2,
      "46e5b6fc-1b9a-4965-bafa-9d507ebb55ae": 2,
      "99f86197-9021-4581-9146-2c04cecd1b7a": 2,
      "c51db643-479b-46a3-b3ea-4d5c23f0db1d": 2,
      "5fee2f58-eac4-4d3d-8e01-52ad5aed2479": 1,
      "721b599a-22ec-4e45-8ac6-c63a188e073a": 1,
      "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d": 1,
      "b468837c-db94-47d2-8364-bf409a662edf": 1,
      "f9e5c0cd-1a37-424c-9e8d-98a78a859dcd": 1,
    },
    tags: {
      "9bedcce5-f0e5-4b3f-86db-2ee0b810c091": 29,
      "3d8d9269-63a0-437f-90ec-f70204d5c10f": 16,
      "9066ae36-b552-4e82-98af-f5dc2dd2fe10": 11,
      "b748d5f8-528c-428a-9ed0-8143c3918d19": 6,
      "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92": 6,
      "00b2f403-8017-4498-b591-5e7dbac2f47c": 2,
      "1d06bd8f-bb8c-4cac-ae49-e51c2be788c8": 2,
      "1db0c5c3-55f4-4471-8d4d-6a7876c82044": 2,
      "358305a0-dcd2-4002-8041-d5adfe84a734": 2,
      "6d397aaf-5e7d-40bb-983d-b670fe749003": 2,
      "990afd3e-7939-4f41-8cb4-279b1189ce29": 2,
      "b1f8dcc5-7cd5-4d08-befa-b21873f0a524": 2,
      "e2b73d48-60a0-494e-a492-b67f352825d2": 2,
      "e3e1b271-fb37-413a-9cf4-e00cede94536": 2,
      "fa9267e8-865d-4d52-aabf-558d76c1528c": 2,
      "0604a980-03fa-484d-8c3b-cbad5262c139": 1,
      "1360c2f9-4213-4aa6-81f7-3ebb94ad3972": 1,
      "1cf79ab9-d90a-4796-a4d4-e84b3b7d0c85": 1,
      "1f1042fe-789f-4997-b1ac-047743c90c57": 1,
      "37791870-297d-4c3c-8230-d988fa8625b3": 1,
      "3adead4e-7267-44fc-8845-8dc9bc216e63": 1,
      "48018f84-1fd8-4165-8078-3d83908f22d4": 1,
      "4a304e58-0e66-4130-9b92-72734694f576": 1,
      "5815e843-ab07-4efe-b8c6-0395e24f5337": 1,
      "693c6362-7a93-4b0a-a019-aa1f0437196e": 1,
      "6bae776f-543c-4ed6-ab8a-309965c406e5": 1,
      "841285bc-32fa-4273-87a0-6eb4e75b8432": 1,
      "8529fdfb-77d9-40f7-aa2e-221cc592b6ae": 1,
      "9ab6508c-29d8-44a1-a334-a21b5499c01c": 1,
      "c5c687c3-e3f1-4345-867e-51d2b86226fb": 1,
      "d9d3c77e-a000-4822-9fd8-c6a1f0885b1f": 1,
    },
  },
  form: {
    sort: "Relevance",
    andFilters: [],
    orFilters: [],
    includeRestricted: true,
    includeExpired: false,
    lastUpdated: null,
    excludeWithinExpiredDate: null,
    searchIndex: "assets",
    correlationId:
      "U0lEOmViNmE0MDcyLTIyZWItNGRhOS05ODA2LTQ4NmZhMTI0ZTk2M3xQSDowfFJJRDpkNDMzMDRhNi1lNWY4LTRjZTAtOTZlNi1iYmNjZmMyN2ZlMjg=",
    page: 1,
    significance: false,
    query: null,
    pageSize: 50,
    updateSearchHistory: false,
  },
  total: 89,
  totalPages: 2,
  elapsedMilliseconds: 21,
  correlationId:
    "U0lEOmViNmE0MDcyLTIyZWItNGRhOS05ODA2LTQ4NmZhMTI0ZTk2M3xQSDowfFJJRDo5NTY0NDlhMS1kMjllLTQwYjItYjJmMS1mNDkyOThjZTg5YzM=",
  requestFeedback: true,
};

export const unifiedSearchResultsLarge = {
  "results": [
    {
      "entityId": "1nm57ZVJLwVUigKFUPNgaV",
      "complianceType": "complianceResourcesPage",
      "id": "f314dd5d-850d-4031-971a-de70182f57b0",
      "url": "/compliance/resources",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Tools & resources",
      "score": 0,
      "createdOn": "2020-12-21T21:59:54.725Z",
      "modifiedOn": "2024-05-14T15:12:32.187Z"
    },
    {
      "entityId": "16QqZ0mUBpLvldFuaC9fUW",
      "complianceType": "complianceFaqsItem",
      "id": "a193a33f-b751-4ba0-b79e-98f8a49993e9",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "What if I have an exception to the brand guidelines on my submission?",
      "score": 0,
      "createdOn": "2021-01-14T20:12:22.348Z",
      "modifiedOn": "2021-02-16T20:21:17.954Z"
    },
    {
      "entityId": "1zAjYHRJJv6BY6cSVb0Ebz",
      "complianceType": "complianceFaqsItem",
      "id": "872e4052-cd11-4130-9c61-e2b280dc4e60",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I have a promo item to submit, is this the correct process for that?",
      "score": 0,
      "createdOn": "2021-01-14T20:15:39.673Z",
      "modifiedOn": "2021-02-16T20:21:17.197Z"
    },
    {
      "entityId": "Y9tZXLqde6jNdSvw9W7SU",
      "complianceType": "complianceFaqsItem",
      "id": "7b04e7e6-1c1e-4153-bf4a-41f05bd1da99",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "What if I’m having technical issues submitting my work?",
      "score": 0,
      "createdOn": "2021-01-15T18:27:57.217Z",
      "modifiedOn": "2021-02-16T20:21:09.174Z"
    },
    {
      "entityId": "7ANck9jl0rOR63AznAFri6",
      "complianceType": "complianceFaqsItem",
      "id": "1426809b-8b47-4aa3-9a5a-85a03f9133d0",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I would like to submit multiple versions of the same asset type. How should I submit?",
      "score": 0,
      "createdOn": "2021-01-15T18:34:56.172Z",
      "modifiedOn": "2021-02-16T20:21:07.862Z"
    },
    {
      "entityId": "6UB3ROVxDgTZzDZn8jYPpR",
      "complianceType": "complianceFaqsItem",
      "id": "f00fcc09-2d11-4eb2-b941-16733594503f",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I would like to submit different assets all related to the same initiative. How should I submit?",
      "score": 0,
      "createdOn": "2021-01-15T18:35:24.188Z",
      "modifiedOn": "2021-02-16T20:21:07.623Z"
    },
    {
      "entityId": "6gLcXCyhjwNWkRLLsXbRyl",
      "complianceType": "complianceScoringElement",
      "id": "c6fe6499-6519-477a-9109-39a688a21eab",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Link CTA",
      "score": 0,
      "createdOn": "2021-01-15T17:25:20.971Z",
      "modifiedOn": "2023-09-28T20:53:26.949Z"
    },
    {
      "entityId": "2Enao0hB5dkcavHNY3cjRB",
      "complianceType": "complianceScoringElement",
      "id": "9280ca2b-c59f-462f-9e3f-1a638e1e0e4f",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "CTA with caret",
      "score": 0,
      "createdOn": "2021-01-15T17:19:41.721Z",
      "modifiedOn": "2023-09-28T20:53:06.662Z"
    },
    {
      "entityId": "6k2RzLWZRbs3TNYFNxXlbn",
      "complianceType": "complianceScoringElement",
      "id": "936e00f6-3567-4ea3-a406-5094f436dac7",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Imagery / Photography",
      "score": 0,
      "createdOn": "2021-01-15T13:43:51.331Z",
      "modifiedOn": "2023-09-28T20:45:03.933Z"
    },
    {
      "entityId": "6i9toukVd7DQ7i8E0zkP6x",
      "complianceType": "complianceScoringElement",
      "id": "101c06b1-42b9-4005-aeac-37d05bd45117",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Logo",
      "score": 0,
      "createdOn": "2020-12-21T21:59:59.343Z",
      "modifiedOn": "2023-09-28T16:42:42.97Z"
    },
    {
      "entityId": "1nm57ZVJLwVUigKFUPNgaV",
      "complianceType": "complianceResourcesPage",
      "id": "f314dd5d-850d-4031-971a-de70182f57b0",
      "url": "/compliance/resources",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Tools & resources",
      "score": 0,
      "createdOn": "2020-12-21T21:59:54.725Z",
      "modifiedOn": "2024-05-14T15:12:32.187Z"
    },
    {
      "entityId": "16QqZ0mUBpLvldFuaC9fUW",
      "complianceType": "complianceFaqsItem",
      "id": "a193a33f-b751-4ba0-b79e-98f8a49993e9",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "What if I have an exception to the brand guidelines on my submission?",
      "score": 0,
      "createdOn": "2021-01-14T20:12:22.348Z",
      "modifiedOn": "2021-02-16T20:21:17.954Z"
    },
    {
      "entityId": "1zAjYHRJJv6BY6cSVb0Ebz",
      "complianceType": "complianceFaqsItem",
      "id": "872e4052-cd11-4130-9c61-e2b280dc4e60",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I have a promo item to submit, is this the correct process for that?",
      "score": 0,
      "createdOn": "2021-01-14T20:15:39.673Z",
      "modifiedOn": "2021-02-16T20:21:17.197Z"
    },
    {
      "entityId": "Y9tZXLqde6jNdSvw9W7SU",
      "complianceType": "complianceFaqsItem",
      "id": "7b04e7e6-1c1e-4153-bf4a-41f05bd1da99",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "What if I’m having technical issues submitting my work?",
      "score": 0,
      "createdOn": "2021-01-15T18:27:57.217Z",
      "modifiedOn": "2021-02-16T20:21:09.174Z"
    },
    {
      "entityId": "7ANck9jl0rOR63AznAFri6",
      "complianceType": "complianceFaqsItem",
      "id": "1426809b-8b47-4aa3-9a5a-85a03f9133d0",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I would like to submit multiple versions of the same asset type. How should I submit?",
      "score": 0,
      "createdOn": "2021-01-15T18:34:56.172Z",
      "modifiedOn": "2021-02-16T20:21:07.862Z"
    },
    {
      "entityId": "6UB3ROVxDgTZzDZn8jYPpR",
      "complianceType": "complianceFaqsItem",
      "id": "f00fcc09-2d11-4eb2-b941-16733594503f",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I would like to submit different assets all related to the same initiative. How should I submit?",
      "score": 0,
      "createdOn": "2021-01-15T18:35:24.188Z",
      "modifiedOn": "2021-02-16T20:21:07.623Z"
    },
    {
      "entityId": "6gLcXCyhjwNWkRLLsXbRyl",
      "complianceType": "complianceScoringElement",
      "id": "c6fe6499-6519-477a-9109-39a688a21eab",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Link CTA",
      "score": 0,
      "createdOn": "2021-01-15T17:25:20.971Z",
      "modifiedOn": "2023-09-28T20:53:26.949Z"
    },
    {
      "entityId": "2Enao0hB5dkcavHNY3cjRB",
      "complianceType": "complianceScoringElement",
      "id": "9280ca2b-c59f-462f-9e3f-1a638e1e0e4f",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "CTA with caret",
      "score": 0,
      "createdOn": "2021-01-15T17:19:41.721Z",
      "modifiedOn": "2023-09-28T20:53:06.662Z"
    },
    {
      "entityId": "6k2RzLWZRbs3TNYFNxXlbn",
      "complianceType": "complianceScoringElement",
      "id": "936e00f6-3567-4ea3-a406-5094f436dac7",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Imagery / Photography",
      "score": 0,
      "createdOn": "2021-01-15T13:43:51.331Z",
      "modifiedOn": "2023-09-28T20:45:03.933Z"
    },
    {
      "entityId": "6i9toukVd7DQ7i8E0zkP6x",
      "complianceType": "complianceScoringElement",
      "id": "101c06b1-42b9-4005-aeac-37d05bd45117",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Logo",
      "score": 0,
      "createdOn": "2020-12-21T21:59:59.343Z",
      "modifiedOn": "2023-09-28T16:42:42.97Z"
    },
    {
      "entityId": "1nm57ZVJLwVUigKFUPNgaV",
      "complianceType": "complianceResourcesPage",
      "id": "f314dd5d-850d-4031-971a-de70182f57b0",
      "url": "/compliance/resources",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Tools & resources",
      "score": 0,
      "createdOn": "2020-12-21T21:59:54.725Z",
      "modifiedOn": "2024-05-14T15:12:32.187Z"
    },
    {
      "entityId": "16QqZ0mUBpLvldFuaC9fUW",
      "complianceType": "complianceFaqsItem",
      "id": "a193a33f-b751-4ba0-b79e-98f8a49993e9",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "What if I have an exception to the brand guidelines on my submission?",
      "score": 0,
      "createdOn": "2021-01-14T20:12:22.348Z",
      "modifiedOn": "2021-02-16T20:21:17.954Z"
    },
    {
      "entityId": "1zAjYHRJJv6BY6cSVb0Ebz",
      "complianceType": "complianceFaqsItem",
      "id": "872e4052-cd11-4130-9c61-e2b280dc4e60",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I have a promo item to submit, is this the correct process for that?",
      "score": 0,
      "createdOn": "2021-01-14T20:15:39.673Z",
      "modifiedOn": "2021-02-16T20:21:17.197Z"
    },
    {
      "entityId": "Y9tZXLqde6jNdSvw9W7SU",
      "complianceType": "complianceFaqsItem",
      "id": "7b04e7e6-1c1e-4153-bf4a-41f05bd1da99",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "What if I’m having technical issues submitting my work?",
      "score": 0,
      "createdOn": "2021-01-15T18:27:57.217Z",
      "modifiedOn": "2021-02-16T20:21:09.174Z"
    },
    {
      "entityId": "7ANck9jl0rOR63AznAFri6",
      "complianceType": "complianceFaqsItem",
      "id": "1426809b-8b47-4aa3-9a5a-85a03f9133d0",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I would like to submit multiple versions of the same asset type. How should I submit?",
      "score": 0,
      "createdOn": "2021-01-15T18:34:56.172Z",
      "modifiedOn": "2021-02-16T20:21:07.862Z"
    },
    {
      "entityId": "6UB3ROVxDgTZzDZn8jYPpR",
      "complianceType": "complianceFaqsItem",
      "id": "f00fcc09-2d11-4eb2-b941-16733594503f",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I would like to submit different assets all related to the same initiative. How should I submit?",
      "score": 0,
      "createdOn": "2021-01-15T18:35:24.188Z",
      "modifiedOn": "2021-02-16T20:21:07.623Z"
    },
    {
      "entityId": "6gLcXCyhjwNWkRLLsXbRyl",
      "complianceType": "complianceScoringElement",
      "id": "c6fe6499-6519-477a-9109-39a688a21eab",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Link CTA",
      "score": 0,
      "createdOn": "2021-01-15T17:25:20.971Z",
      "modifiedOn": "2023-09-28T20:53:26.949Z"
    },
    {
      "entityId": "2Enao0hB5dkcavHNY3cjRB",
      "complianceType": "complianceScoringElement",
      "id": "9280ca2b-c59f-462f-9e3f-1a638e1e0e4f",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "CTA with caret",
      "score": 0,
      "createdOn": "2021-01-15T17:19:41.721Z",
      "modifiedOn": "2023-09-28T20:53:06.662Z"
    },
    {
      "entityId": "6k2RzLWZRbs3TNYFNxXlbn",
      "complianceType": "complianceScoringElement",
      "id": "936e00f6-3567-4ea3-a406-5094f436dac7",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Imagery / Photography",
      "score": 0,
      "createdOn": "2021-01-15T13:43:51.331Z",
      "modifiedOn": "2023-09-28T20:45:03.933Z"
    },
    {
      "entityId": "6i9toukVd7DQ7i8E0zkP6x",
      "complianceType": "complianceScoringElement",
      "id": "101c06b1-42b9-4005-aeac-37d05bd45117",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Logo",
      "score": 0,
      "createdOn": "2020-12-21T21:59:59.343Z",
      "modifiedOn": "2023-09-28T16:42:42.97Z"
    },
    {
      "entityId": "1nm57ZVJLwVUigKFUPNgaV",
      "complianceType": "complianceResourcesPage",
      "id": "f314dd5d-850d-4031-971a-de70182f57b0",
      "url": "/compliance/resources",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Tools & resources",
      "score": 0,
      "createdOn": "2020-12-21T21:59:54.725Z",
      "modifiedOn": "2024-05-14T15:12:32.187Z"
    },
    {
      "entityId": "16QqZ0mUBpLvldFuaC9fUW",
      "complianceType": "complianceFaqsItem",
      "id": "a193a33f-b751-4ba0-b79e-98f8a49993e9",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "What if I have an exception to the brand guidelines on my submission?",
      "score": 0,
      "createdOn": "2021-01-14T20:12:22.348Z",
      "modifiedOn": "2021-02-16T20:21:17.954Z"
    },
    {
      "entityId": "1zAjYHRJJv6BY6cSVb0Ebz",
      "complianceType": "complianceFaqsItem",
      "id": "872e4052-cd11-4130-9c61-e2b280dc4e60",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I have a promo item to submit, is this the correct process for that?",
      "score": 0,
      "createdOn": "2021-01-14T20:15:39.673Z",
      "modifiedOn": "2021-02-16T20:21:17.197Z"
    },
    {
      "entityId": "Y9tZXLqde6jNdSvw9W7SU",
      "complianceType": "complianceFaqsItem",
      "id": "7b04e7e6-1c1e-4153-bf4a-41f05bd1da99",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "What if I’m having technical issues submitting my work?",
      "score": 0,
      "createdOn": "2021-01-15T18:27:57.217Z",
      "modifiedOn": "2021-02-16T20:21:09.174Z"
    },
    {
      "entityId": "7ANck9jl0rOR63AznAFri6",
      "complianceType": "complianceFaqsItem",
      "id": "1426809b-8b47-4aa3-9a5a-85a03f9133d0",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I would like to submit multiple versions of the same asset type. How should I submit?",
      "score": 0,
      "createdOn": "2021-01-15T18:34:56.172Z",
      "modifiedOn": "2021-02-16T20:21:07.862Z"
    },
    {
      "entityId": "6UB3ROVxDgTZzDZn8jYPpR",
      "complianceType": "complianceFaqsItem",
      "id": "f00fcc09-2d11-4eb2-b941-16733594503f",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I would like to submit different assets all related to the same initiative. How should I submit?",
      "score": 0,
      "createdOn": "2021-01-15T18:35:24.188Z",
      "modifiedOn": "2021-02-16T20:21:07.623Z"
    },
    {
      "entityId": "6gLcXCyhjwNWkRLLsXbRyl",
      "complianceType": "complianceScoringElement",
      "id": "c6fe6499-6519-477a-9109-39a688a21eab",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Link CTA",
      "score": 0,
      "createdOn": "2021-01-15T17:25:20.971Z",
      "modifiedOn": "2023-09-28T20:53:26.949Z"
    },
    {
      "entityId": "2Enao0hB5dkcavHNY3cjRB",
      "complianceType": "complianceScoringElement",
      "id": "9280ca2b-c59f-462f-9e3f-1a638e1e0e4f",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "CTA with caret",
      "score": 0,
      "createdOn": "2021-01-15T17:19:41.721Z",
      "modifiedOn": "2023-09-28T20:53:06.662Z"
    },
    {
      "entityId": "6k2RzLWZRbs3TNYFNxXlbn",
      "complianceType": "complianceScoringElement",
      "id": "936e00f6-3567-4ea3-a406-5094f436dac7",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Imagery / Photography",
      "score": 0,
      "createdOn": "2021-01-15T13:43:51.331Z",
      "modifiedOn": "2023-09-28T20:45:03.933Z"
    },
    {
      "entityId": "6i9toukVd7DQ7i8E0zkP6x",
      "complianceType": "complianceScoringElement",
      "id": "101c06b1-42b9-4005-aeac-37d05bd45117",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Logo",
      "score": 0,
      "createdOn": "2020-12-21T21:59:59.343Z",
      "modifiedOn": "2023-09-28T16:42:42.97Z"
    },
    {
      "entityId": "1nm57ZVJLwVUigKFUPNgaV",
      "complianceType": "complianceResourcesPage",
      "id": "f314dd5d-850d-4031-971a-de70182f57b0",
      "url": "/compliance/resources",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Tools & resources",
      "score": 0,
      "createdOn": "2020-12-21T21:59:54.725Z",
      "modifiedOn": "2024-05-14T15:12:32.187Z"
    },
    {
      "entityId": "16QqZ0mUBpLvldFuaC9fUW",
      "complianceType": "complianceFaqsItem",
      "id": "a193a33f-b751-4ba0-b79e-98f8a49993e9",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "What if I have an exception to the brand guidelines on my submission?",
      "score": 0,
      "createdOn": "2021-01-14T20:12:22.348Z",
      "modifiedOn": "2021-02-16T20:21:17.954Z"
    },
    {
      "entityId": "1zAjYHRJJv6BY6cSVb0Ebz",
      "complianceType": "complianceFaqsItem",
      "id": "872e4052-cd11-4130-9c61-e2b280dc4e60",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I have a promo item to submit, is this the correct process for that?",
      "score": 0,
      "createdOn": "2021-01-14T20:15:39.673Z",
      "modifiedOn": "2021-02-16T20:21:17.197Z"
    },
    {
      "entityId": "Y9tZXLqde6jNdSvw9W7SU",
      "complianceType": "complianceFaqsItem",
      "id": "7b04e7e6-1c1e-4153-bf4a-41f05bd1da99",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "What if I’m having technical issues submitting my work?",
      "score": 0,
      "createdOn": "2021-01-15T18:27:57.217Z",
      "modifiedOn": "2021-02-16T20:21:09.174Z"
    },
    {
      "entityId": "7ANck9jl0rOR63AznAFri6",
      "complianceType": "complianceFaqsItem",
      "id": "1426809b-8b47-4aa3-9a5a-85a03f9133d0",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I would like to submit multiple versions of the same asset type. How should I submit?",
      "score": 0,
      "createdOn": "2021-01-15T18:34:56.172Z",
      "modifiedOn": "2021-02-16T20:21:07.862Z"
    },
    {
      "entityId": "6UB3ROVxDgTZzDZn8jYPpR",
      "complianceType": "complianceFaqsItem",
      "id": "f00fcc09-2d11-4eb2-b941-16733594503f",
      "url": "/compliance/faqs",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "I would like to submit different assets all related to the same initiative. How should I submit?",
      "score": 0,
      "createdOn": "2021-01-15T18:35:24.188Z",
      "modifiedOn": "2021-02-16T20:21:07.623Z"
    },
    {
      "entityId": "6gLcXCyhjwNWkRLLsXbRyl",
      "complianceType": "complianceScoringElement",
      "id": "c6fe6499-6519-477a-9109-39a688a21eab",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Link CTA",
      "score": 0,
      "createdOn": "2021-01-15T17:25:20.971Z",
      "modifiedOn": "2023-09-28T20:53:26.949Z"
    },
    {
      "entityId": "2Enao0hB5dkcavHNY3cjRB",
      "complianceType": "complianceScoringElement",
      "id": "9280ca2b-c59f-462f-9e3f-1a638e1e0e4f",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "CTA with caret",
      "score": 0,
      "createdOn": "2021-01-15T17:19:41.721Z",
      "modifiedOn": "2023-09-28T20:53:06.662Z"
    },
    {
      "entityId": "6k2RzLWZRbs3TNYFNxXlbn",
      "complianceType": "complianceScoringElement",
      "id": "936e00f6-3567-4ea3-a406-5094f436dac7",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Imagery / Photography",
      "score": 0,
      "createdOn": "2021-01-15T13:43:51.331Z",
      "modifiedOn": "2023-09-28T20:45:03.933Z"
    },
    {
      "entityId": "6i9toukVd7DQ7i8E0zkP6x",
      "complianceType": "complianceScoringElement",
      "id": "101c06b1-42b9-4005-aeac-37d05bd45117",
      "url": "/compliance/scoring",
      "searchType": "compliance",
      "isFavorite": false,
      "title": "Logo",
      "score": 0,
      "createdOn": "2020-12-21T21:59:59.343Z",
      "modifiedOn": "2023-09-28T16:42:42.97Z"
    },
  ],
  "filters": null,
  "form": {
    "sort": "relevance",
    "andFilters": [],
    "includeRestricted": true,
    "includeExpired": false,
    "excludeWithinExpiredDate": null,
    "searchTypes": [
      "all"
    ],
    "correlationId": "U0lEOjMyMjQxZDdhLWFmNjAtNDg5NC1hNWEwLTU3YTk0YzQxNjliMHxQSDowfFJJRDo3MmYwMjBhYS0wYzJiLTRkOTQtOWI4ZS02OTEwNDVjNjljM2E=",
    "page": 1,
    "significance": false,
    "query": null,
    "pageSize": 50,
    "updateSearchHistory": false
  },
  "total": 459,
  "totalPages": 10,
  "correlationId": "U0lEOjMyMjQxZDdhLWFmNjAtNDg5NC1hNWEwLTU3YTk0YzQxNjliMHxQSDowfFJJRDo1NjViNTk0Zi0yMjk4LTQxYzctYTAyNS0xM2M5NjZiMzM1ZTY=",
  "requestFeedback": true
};

export const unifiedSearchAssetResultsLarge = {
  "results": [
    {
      "assetId": "135c532b-59ff-43a2-b328-d08f01b4fa18",
      "description": "testing asset request photography asset form for dashboard",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2023-10-31T23:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "alt text",
      "files": [
        {
          "id": "93a09889-754a-4725-8078-9316a34ba9e9",
          "groupName": null,
          "fileName": "testassetform.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=E7K2VKHe2SbxNk6Aa%2B1bDgtXukk%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=%2B2pJS2N2ucPu0OjHS9S60n0dQzE%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=feEiuhGsXwBXrHs2OmZmhLxBv6g%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL38273cdcea46457a88ba72472f60329a_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=9RYdGbwvxj8HC%2B7qdn3ccGcloiM%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "135c532b-59ff-43a2-b328-d08f01b4fa18",
      "url": "assets/details/135c532b-59ff-43a2-b328-d08f01b4fa18",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Christine Test Asset",
      "score": 0,
      "createdOn": "2023-03-08T14:37:09.234824Z",
      "modifiedOn": "2023-09-27T15:10:59.399001Z"
    },
    {
      "assetId": "3dbd1fd0-2c14-4002-b233-25142d5996cf",
      "description": "danhuri test photoshoot 7 assets same job",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "danhuri test photoshoot",
      "files": [
        {
          "id": "bbd5ab05-2f60-453e-bb6a-d073c85c6231",
          "groupName": null,
          "fileName": "global search button.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=6YVPhgWz9CH3n4s1xhiOXkJOsik%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=bUTghqPNrV9E9hUBecz45v4aF6w%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=lMQiojJkWtMY%2FO%2FnU5Opy7TjtLs%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL00eff3a744454a1b8b69d581431ced55_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=KGIpraie%2FEgb4EDdprx6oPvfeCU%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 7,
      "id": "3dbd1fd0-2c14-4002-b233-25142d5996cf",
      "url": "assets/details/3dbd1fd0-2c14-4002-b233-25142d5996cf",
      "searchType": "asset",
      "isFavorite": false,
      "title": "danhuri test photoshoot 7",
      "score": 0,
      "createdOn": "2022-08-03T13:22:07.522843Z",
      "modifiedOn": "2022-10-05T16:52:45.731974Z"
    },
    {
      "assetId": "51288d10-2309-4fb8-9e29-38e2a3624b57",
      "description": "Test Asset 9",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Test Asset 9",
      "files": [],
      "filterIds": [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "99f86197-9021-4581-9146-2c04cecd1b7a",
        "fa9267e8-865d-4d52-aabf-558d76c1528c",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Danhuri Category 12",
      "downloadCount": 0,
      "id": "51288d10-2309-4fb8-9e29-38e2a3624b57",
      "url": "assets/details/51288d10-2309-4fb8-9e29-38e2a3624b57",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Test Asset 9",
      "score": 0,
      "createdOn": "2022-07-26T17:04:25.343903Z",
      "modifiedOn": "2023-06-28T14:27:18.606609Z"
    },
    {
      "assetId": "4e8ffaaa-252d-4d8e-b175-1fcba8769f8c",
      "description": "Test assets",
      "userActions": {
        "hasAcceptedUsageRights": false,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "Don't use these",
      "expiresOn": "2023-09-26T00:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "Test assets alt text",
      "files": [
        {
          "id": "3b230968-92d4-4214-a247-4197731d16f0",
          "groupName": null,
          "fileName": "Guidelines_Buttons.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf651a7746d084b5999c7b67c4aebe49a_110.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=ezv%2Fz7awB53dSvpeW0PmNxQk%2Be4%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf651a7746d084b5999c7b67c4aebe49a_400.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=xKMm4iYYy95Ta6T9jR7HMhSZ%2BY4%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf651a7746d084b5999c7b67c4aebe49a_500.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=kCaQICYIF%2BMZSebeeE001aIwDVg%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf651a7746d084b5999c7b67c4aebe49a_700.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=uuw16qUt6GSQG2FufJsubstMXZw%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "0e4b62e8-133b-4c94-94e4-aaafbc6437ef",
        "722b933e-a140-4db3-8c3b-107ad9b8c9f6",
        "ddd38d47-bf8c-4b3e-931d-15d8c10b260d",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Danhuri Category 11",
      "downloadCount": 46,
      "id": "4e8ffaaa-252d-4d8e-b175-1fcba8769f8c",
      "url": "assets/details/4e8ffaaa-252d-4d8e-b175-1fcba8769f8c",
      "searchType": "asset",
      "isFavorite": true,
      "title": "Kat Test 2",
      "score": 0,
      "createdOn": "2022-06-03T19:07:22.229491Z",
      "modifiedOn": "2022-10-31T13:52:56.640535Z"
    },
    {
      "assetId": "783eaa9e-ae1b-4e2c-8351-0a424ab226d5",
      "description": "test",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "test",
      "files": [
        {
          "id": "0099d4dd-4dab-4356-8600-0ae4b8a76d71",
          "groupName": "Group 1",
          "fileName": "ICONCOMPUTER (3).png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=CjhbhfhgwxziCMQZTsclGnv1%2Bq0%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=3DrttcH0TlRV%2F4OOB11TaW0Ve%2BY%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=hJWH9d7OS4DlgrUaIHsbxc8%2FV0E%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL90a4c75f0a9a46feb83326934a6bd39e_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=71Dv3Cq75HHG%2B9hlnAw1T2uTScw%3D",
            "isImage": true
          }
        },
        {
          "id": "0fe937c2-a37a-482d-8b75-443349db6844",
          "groupName": "group 2",
          "fileName": "Icon search.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=2%2FH5qtFd8VdmMXuzaVuWbUpFEe4%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=yEMo3MSKl1egUI40ylt8jIzanUo%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=oKFF1GbVgqksEZPqP5SOQNBq5b4%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe5d5046b15554a0b95045bb0711f28c8_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=9hLmtXocyzaUQgGf25rbSe4QOD4%3D",
            "isImage": true
          }
        },
        {
          "id": "4aeb4c25-2cdd-43a9-9ca7-04271820c846",
          "groupName": "group 3",
          "fileName": "SA-2054-MNYIB-V.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=8yG1fAZyhkJANODgPtwSW7LCDrc%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=iCXLyREbCz60xaC8MbsqzVwpFRg%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=tMmoBRbH%2F7anoNhkQI8LWEcM6qA%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL98c92f3b5b394c2582a423e74870e468_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=%2Fjx9qg2SA5hw%2BHdq8HbL7fjTL3c%3D",
            "isImage": true
          }
        },
        {
          "id": "cad2903c-6a7f-4b57-9773-7a23148c7ee5",
          "groupName": "Group 1",
          "fileName": "77-89337_E.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=uItikpLS8LTvKT3CH3ZM5F4Ljk8%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=zdsO%2BLJsvK7Cjb3aPdp9Krs5gog%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=6ksV5eTZgxfFuRXLbwiYBeFMp9w%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL32dcf010889a471abf83acb967b6c506_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=7nBHroxEpQdkjuK3pWIR0uLqJtg%3D",
            "isImage": true
          }
        },
        {
          "id": "fd13c31b-af6a-4c81-86ca-74cf0df4bec8",
          "groupName": "Group 1",
          "fileName": "DBP-W22V11-1757.pdf",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=ZuPOKaSjadC5tvPu82tiB1Oaqf0%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=BDTxm0yK9fqFNk3hFrSDYHcupec%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=oCCta32LRHKWdH5x9FKaMo8K1Y0%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Sth4c0S6qL2A89Y6mSHOiC6UxzU%3D",
            "isImage": false
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10"
      ],
      "category": "Photography",
      "downloadCount": 3,
      "id": "783eaa9e-ae1b-4e2c-8351-0a424ab226d5",
      "url": "assets/details/783eaa9e-ae1b-4e2c-8351-0a424ab226d5",
      "searchType": "asset",
      "isFavorite": false,
      "title": "QA Asset Test",
      "score": 0,
      "createdOn": "2023-02-14T21:30:38.945209Z",
      "modifiedOn": "2023-10-07T20:14:44.586877Z"
    },
    {
      "assetId": "76e9f2b0-d65c-4a04-9ebc-69c2af6777bd",
      "description": "She doesn't want to get off the bed",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2853-05-09T04:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "dog on bed",
      "files": [
        {
          "id": "b5296824-2a1e-4f82-93fb-32a69f8850d1",
          "groupName": null,
          "fileName": "paws_over_bed.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_110.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=LakDZJT2FUT0SUNzldTvfIajMik%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_400.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=69%2BoSZ6QgVPScjh0Xzt%2FQPRujOw%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_500.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=JhxjbjM9feObVIfZ7%2FGEulswV8Y%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe359c7f690ac4d5cb3f657cffa4fd59e_700.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=8mWbAzphE%2FVfqZOTgsg9ml4DVqQ%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "b8acea32-28ef-43ee-9ba2-17cff3603481",
        "32e48d3d-80a3-4602-a1af-54731b48caa0"
      ],
      "category": "Photography",
      "downloadCount": 12,
      "id": "76e9f2b0-d65c-4a04-9ebc-69c2af6777bd",
      "url": "assets/details/76e9f2b0-d65c-4a04-9ebc-69c2af6777bd",
      "searchType": "asset",
      "isFavorite": true,
      "title": "Louise gives you a guilt trip!",
      "score": 0,
      "createdOn": "2023-10-04T19:15:29.302849Z",
      "modifiedOn": "2024-02-22T21:58:57.135513Z"
    },
    {
      "assetId": "827e6605-4342-411e-9de3-848d80e0ac19",
      "description": "Asset 36",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2024-01-24T05:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 36",
      "files": [
        {
          "id": "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          "groupName": "1",
          "fileName": "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          "approvalStatus": "Pending",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=CHKIqThK8Cvs95kEAcTaxhpVzbw%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=rfuZXmb9EZKNnThYTNRqx1vuezM%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=QO2VV9UXzuAyttxiCa%2F3kDH9pqw%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=L3nR85Asv6g7CC3zd1hYnD808Ns%3D",
            "isImage": true
          }
        },
        {
          "id": "dc8bea19-422d-4a75-a297-bdea822fc679",
          "groupName": "2",
          "fileName": "Screenshot 2023-09-26 at 11.41.09 AM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=i3W9hT%2BqMo6kDcsIY%2BlhefljzzM%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=N0Yk5rb7YbjM54sYcJXx8KYH0IA%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=UN9ed9%2BZs5cMB%2FECjQWwkZSGlm0%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=VghodN8ybFc3q1tsGRGM5V7jDe4%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "827e6605-4342-411e-9de3-848d80e0ac19",
      "url": "assets/details/827e6605-4342-411e-9de3-848d80e0ac19",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 36",
      "score": 0,
      "createdOn": "2022-10-05T19:12:25.759419Z",
      "modifiedOn": "2023-10-24T19:18:30.484175Z"
    },
    {
      "assetId": "ef88431c-50f2-4d40-95a8-ce42be2430ef",
      "description": "SEM 2 col asset-approved test",
      "userActions": {
        "hasAcceptedUsageRights": false,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "SEM 2 col asset-approved test",
      "expiresOn": "2023-09-30T23:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "SEM 2 col asset-approved test",
      "files": [
        {
          "id": "fd39eff5-f1aa-46ff-b751-44eee8c5e6c1",
          "groupName": null,
          "fileName": "work-values.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLa7185c162a5b41f5b448c77298ef8561_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=nX5enGqTl4041cM4nKbHMiVB9lU%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLa7185c162a5b41f5b448c77298ef8561_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=kK578VB7P%2F5sVq%2FAr32QDri6lQs%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLa7185c162a5b41f5b448c77298ef8561_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=cXlmyzeo%2FG9GrIkrD3b4UZNVCMI%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLa7185c162a5b41f5b448c77298ef8561_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=52MjuIvBziVMOvZ9WHdyQA9qCJk%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "03d7c08d-b8f6-405a-9907-567d417e71f5"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "ef88431c-50f2-4d40-95a8-ce42be2430ef",
      "url": "assets/details/ef88431c-50f2-4d40-95a8-ce42be2430ef",
      "searchType": "asset",
      "isFavorite": false,
      "title": "SEM 2 col asset-approved test",
      "score": 0,
      "createdOn": "2023-02-21T15:58:04.39858Z",
      "modifiedOn": "2023-09-27T17:13:09.70396Z"
    },
    {
      "assetId": "f894b2ed-c964-4dfe-b10f-d7065797a0d2",
      "description": "danhuri iconography same photoshoot test",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "danhuri iconography same photoshoot test",
      "files": [
        {
          "id": "bf8e0067-abed-4c6a-9642-c353ea01c12f",
          "groupName": null,
          "fileName": "asset screen shot 3.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_110.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=f6SyEWJnEzOI88tgjSOmVri3qLQ%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_400.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=biAOL1x1TBIw1nnDfQJS3f80f%2B4%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_500.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=d%2BWAYyhBFMQy2gHox%2F7wqxhoc9E%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2fc52798529a457cbd7a2c64e52d236b_700.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=aEhqxJkI3VO6XQLyKYhcnoRGI9E%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
        "e492b86d-3a1d-45a0-b518-3ace937747cf",
        "121ed840-4049-43f3-a3be-ecd0887e5f11",
        "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92"
      ],
      "category": "Photography",
      "downloadCount": 14,
      "id": "f894b2ed-c964-4dfe-b10f-d7065797a0d2",
      "url": "assets/details/f894b2ed-c964-4dfe-b10f-d7065797a0d2",
      "searchType": "asset",
      "isFavorite": false,
      "title": "danhuri iconography same photoshoot test 2",
      "score": 0,
      "createdOn": "2022-08-24T13:48:27.099944Z",
      "modifiedOn": "2022-10-05T16:52:45.734458Z"
    },
    {
      "assetId": "00be77d3-8f90-49a3-89e2-94b9592341c8",
      "description": "Asset 14",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2023-11-02T23:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 14",
      "files": [
        {
          "id": "798a7afb-9bb1-4988-ba61-8c14ae716884",
          "groupName": null,
          "fileName": "Screen Shot 2022-07-27 at 6.11.26 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdf77adca53364e61874a58323e20dab3_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=qMIzLAh6Vwt0qx%2FMd8q3%2F0n23Ug%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdf77adca53364e61874a58323e20dab3_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=KzLQEBxv2TWMUeK47zXWh8V4nXE%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdf77adca53364e61874a58323e20dab3_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=RuJBA1M7CsTjwhSiLbS%2BD5PqNR8%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdf77adca53364e61874a58323e20dab3_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=CuamVCk%2BPW%2F%2Fgr5nVmis5PvWOmo%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "00be77d3-8f90-49a3-89e2-94b9592341c8",
      "url": "assets/details/00be77d3-8f90-49a3-89e2-94b9592341c8",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 14",
      "score": 0,
      "createdOn": "2022-10-05T18:24:10.372731Z",
      "modifiedOn": "2023-09-27T16:40:55.330375Z"
    },
    {
      "assetId": "0b0f5916-3fda-4bd5-96df-82e32fae134e",
      "description": "EOA test asset-declined",
      "userActions": {
        "hasAcceptedUsageRights": false,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "EOA test asset-declined",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "EOA test asset-declined",
      "files": [],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "b748d5f8-528c-428a-9ed0-8143c3918d19"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "0b0f5916-3fda-4bd5-96df-82e32fae134e",
      "url": "assets/details/0b0f5916-3fda-4bd5-96df-82e32fae134e",
      "searchType": "asset",
      "isFavorite": false,
      "title": "EOA test asset-declined",
      "score": 0,
      "createdOn": "2023-02-23T22:15:11.256538Z",
      "modifiedOn": "2023-02-23T22:15:11.225316Z"
    },
    {
      "assetId": "4e784fd4-46ce-407f-bbb0-2332df856768",
      "description": "Asset Chris 5",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T05:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset Chris 5",
      "files": [
        {
          "id": "35078de6-7bc4-4b02-9d6d-30a431b75e37",
          "groupName": null,
          "fileName": "Screen Shot 2022-09-21 at 7.29.19 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66872aef28424cfabaefac587edc262a_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=yjn5%2BQeahTTSXQuggabpnyuEi5I%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66872aef28424cfabaefac587edc262a_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=m4eIBP9ZD2MivMk%2BNMFgZ4Iu8Os%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66872aef28424cfabaefac587edc262a_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Ke9bQc233V%2Bz0GB8E76tlBvuRhY%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL66872aef28424cfabaefac587edc262a_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=vibGvn1Y5y7h4b5FUwFBGYEPFSg%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 1,
      "id": "4e784fd4-46ce-407f-bbb0-2332df856768",
      "url": "assets/details/4e784fd4-46ce-407f-bbb0-2332df856768",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset Chris 5",
      "score": 0,
      "createdOn": "2022-10-05T16:48:14.361539Z",
      "modifiedOn": "2023-06-27T19:45:58.074763Z"
    },
    {
      "assetId": "5150efd1-8c6a-44f3-958b-802f3d827eb8",
      "description": "Asset 22",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 22",
      "files": [
        {
          "id": "987de24f-3d0b-4dd2-beb9-10b50187f48f",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 2.39.35 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL7b6b4cff258e4838a007de6003af7453_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=ELCVLBlhZ3t4sq%2F0lsTROzC7LHU%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL7b6b4cff258e4838a007de6003af7453_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Py5h9Se%2B6MVPr%2BaRAA0w2esogtQ%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL7b6b4cff258e4838a007de6003af7453_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Tk0ai2fn65Mj9%2Fbq3R13Y3jL000%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL7b6b4cff258e4838a007de6003af7453_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Gwq3VhNGnePyWQHHAYubdza0TYQ%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "5150efd1-8c6a-44f3-958b-802f3d827eb8",
      "url": "assets/details/5150efd1-8c6a-44f3-958b-802f3d827eb8",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 22 - Test",
      "score": 0,
      "createdOn": "2022-10-05T18:40:23.85315Z",
      "modifiedOn": "2022-10-21T14:20:36.050344Z"
    },
    {
      "assetId": "9241d294-e36a-4023-b32b-f0fe45bb3292",
      "description": "Icon test asset QA 9-22",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Icon test asset QA 9-22",
      "files": [
        {
          "id": "99fd6393-227c-4032-96a5-04d235338077",
          "groupName": null,
          "fileName": "Screen_Shot_2022-09-19_at_2.17.50_PM (2).png",
          "approvalStatus": "Pending",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc4645ad91c0942dcaa877f46ce5ce7ba_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=lJD3xxAHCecDm8oX9LFCa21nEKE%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc4645ad91c0942dcaa877f46ce5ce7ba_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=qhhPPXxt7cyMxyqh5jh4vc%2F5o70%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc4645ad91c0942dcaa877f46ce5ce7ba_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=NgbbwPX4uwtqjQ8%2Bk66bJdZBKIM%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc4645ad91c0942dcaa877f46ce5ce7ba_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=HCsX1%2B8lLIWdfYhQ2IOgTidbsB0%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "e492b86d-3a1d-45a0-b518-3ace937747cf",
        "121ed840-4049-43f3-a3be-ecd0887e5f11",
        "e3e1b271-fb37-413a-9cf4-e00cede94536",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Iconography",
      "downloadCount": 0,
      "id": "9241d294-e36a-4023-b32b-f0fe45bb3292",
      "url": "assets/details/9241d294-e36a-4023-b32b-f0fe45bb3292",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Icon test asset QA 9-22",
      "score": 0,
      "createdOn": "2022-09-22T13:22:37.794869Z",
      "modifiedOn": "2023-02-10T14:26:32.338524Z"
    },
    {
      "assetId": "c0860908-f63a-4742-945b-21e83f352faa",
      "description": "Needs to be approved, going to test request access emails, as well as expiring emails",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Needs to be approved",
      "files": [
        {
          "id": "f3731061-1e7b-40b3-a1f1-ba486ff01903",
          "groupName": null,
          "fileName": "Screen Shot 2022-07-12 at 2.13.51 PM.png",
          "approvalStatus": "Pending",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL199802a6ca77441d8e6097c681c4e1f7_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=XD4zIVGYqlvhLlAknBLwVzCCCoQ%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL199802a6ca77441d8e6097c681c4e1f7_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=iq6sg1tB92lfuswF3hqd6sq0UZg%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL199802a6ca77441d8e6097c681c4e1f7_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=8dRWRphElEWjQv6Y0Mwzh0MSSAg%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL199802a6ca77441d8e6097c681c4e1f7_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=zAlMbMGAz9moCrWg9%2BvLqA638ck%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "048277fa-25ce-458e-9c03-589f84b78544",
        "b1f8dcc5-7cd5-4d08-befa-b21873f0a524",
        "316c0651-c5e3-4bd2-b41c-5427484f74bd",
        "36b18590-90a5-464e-85b0-a765a47e7e72",
        "1f1042fe-789f-4997-b1ac-047743c90c57",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Danhuri Category 12",
      "downloadCount": 2,
      "id": "c0860908-f63a-4742-945b-21e83f352faa",
      "url": "assets/details/c0860908-f63a-4742-945b-21e83f352faa",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Test asset for asset request 5",
      "score": 0,
      "createdOn": "2022-07-25T18:59:02.542145Z",
      "modifiedOn": "2022-10-21T14:20:36.025094Z"
    },
    {
      "assetId": "b8ced4d3-0a31-4b33-9611-8663f805cbf9",
      "description": "Asset test four for access, expiration and assignment.",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Test asset 4",
      "files": [
        {
          "id": "6991b423-9708-490d-8207-4d2e2ba5421f",
          "groupName": null,
          "fileName": "Screen Shot 2022-07-20 at 2.43.21 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=3mpqG2pwMoYojQN%2F%2Fo64qj8SD%2Fg%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=5PaZBY7%2FnyL1OPxxdpm6RUTk8Rg%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=yAN0fg4elcfOPz7Lx%2FmWIypyNRo%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL6cc08b17b6cb42baac917090ec3c7ada_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=hfCKLKNChQKltankEdaprkMBU8w%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "46e5b6fc-1b9a-4965-bafa-9d507ebb55ae",
        "e2b73d48-60a0-494e-a492-b67f352825d2",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Danhuri Category 12",
      "downloadCount": 2,
      "id": "b8ced4d3-0a31-4b33-9611-8663f805cbf9",
      "url": "assets/details/b8ced4d3-0a31-4b33-9611-8663f805cbf9",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Test asset 4",
      "score": 0,
      "createdOn": "2022-07-21T14:11:52.564274Z",
      "modifiedOn": "2022-10-21T14:20:35.930585Z"
    },
    {
      "assetId": "be6e1c16-ac3f-4e3f-b298-ae97164346a1",
      "description": "Unlimited use of picture",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "alligator in lake",
      "files": [
        {
          "id": "a5b3117a-3fc9-41c6-80c5-6c0aee297f43",
          "groupName": null,
          "fileName": "alligator.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdbfc81ab22214f56b0bed1c55c89c22d_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=M851%2BFe5aF73cjOIpbL4S7uK3a8%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdbfc81ab22214f56b0bed1c55c89c22d_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=u%2FTUGYlj0EnEuqsuZOENg3c5EBI%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdbfc81ab22214f56b0bed1c55c89c22d_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=pyDMec0mkPbFomSSH85ANu4ubWI%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLdbfc81ab22214f56b0bed1c55c89c22d_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=lWT44OHskfVk49b78vn%2Foyho230%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f"
      ],
      "category": "Photography",
      "downloadCount": 1,
      "id": "be6e1c16-ac3f-4e3f-b298-ae97164346a1",
      "url": "assets/details/be6e1c16-ac3f-4e3f-b298-ae97164346a1",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Alligator in Lake",
      "score": 0,
      "createdOn": "2023-04-18T01:00:29.836541Z",
      "modifiedOn": "2023-06-29T22:44:52.769391Z"
    },
    {
      "assetId": "c6b4abe1-9410-4968-8cf7-018bac13bf2b",
      "description": "I bet this makes you wanna eat cheese.",
      "userActions": {
        "hasAcceptedUsageRights": false,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "You can use it but only if you tag Obama.",
      "expiresOn": "2024-04-20T04:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Wow! So cool!",
      "files": [
        {
          "id": "d52ed551-ea58-4f15-895a-5c0d18a31365",
          "groupName": null,
          "fileName": "image3234234123s.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=uBacj%2BItyBfkDJTyDt8B%2BFHL6ZA%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=xGpiLa2ofJr0LZvH15T7ZZVogRY%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=jslZv4KJIp0e3EabcvFQ3pOwpds%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5180c36f3300420a9b2b2e66264917ad_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=q4Q%2B60FZHDfWvATtfF9Du4gcoVA%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "b748d5f8-528c-428a-9ed0-8143c3918d19"
      ],
      "category": "Photography",
      "downloadCount": 1,
      "id": "c6b4abe1-9410-4968-8cf7-018bac13bf2b",
      "url": "assets/details/c6b4abe1-9410-4968-8cf7-018bac13bf2b",
      "searchType": "asset",
      "isFavorite": false,
      "title": "QA Restricted 3",
      "score": 0,
      "createdOn": "2023-09-12T17:59:53.988665Z",
      "modifiedOn": "2023-10-07T20:09:37.036168Z"
    },
    {
      "assetId": "e1be3260-3974-4275-b9a1-9638247ac84f",
      "description": "Asset 38",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 38",
      "files": [
        {
          "id": "7453c137-c8b9-4a3c-bb22-e3271264c78b",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 3.24.42 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Dr4PbwmcnfspxbAJGKFgeh35krU%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=oRBTgLUQx5PRYYHjWBZaXLJsBr0%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=S%2FqYSbRLPcUOhwQffiSG4XCuPH8%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3b1baf1fdcad4959a93935c3d0eaeeff_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=0TtpwXkmLe3JXj3Y8lQtQo3ILsw%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 2,
      "id": "e1be3260-3974-4275-b9a1-9638247ac84f",
      "url": "assets/details/e1be3260-3974-4275-b9a1-9638247ac84f",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 38",
      "score": 0,
      "createdOn": "2022-10-05T19:25:36.882895Z",
      "modifiedOn": "2022-10-21T14:20:36.021616Z"
    },
    {
      "assetId": "df3c62ad-e25a-48d3-9d43-b119b7eed7d3",
      "description": "Asset 20",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 20",
      "files": [
        {
          "id": "33be5282-71aa-42db-996b-334f937c675c",
          "groupName": null,
          "fileName": "Screen Shot 2022-08-31 at 3.01.30 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=gjw9LnWE89KlKoqnurWCO4cQ9GE%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=m6VW%2FX%2BlezNMq2MiLOAjRuQYQ9k%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=v%2FxyJdJqW4UvFZ53V6xiZp2makM%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2cd8ee8becbb450fb1cd28fc948b15bf_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=weYeM9OGKGcO4XmfyKybJkyejP0%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 1,
      "id": "df3c62ad-e25a-48d3-9d43-b119b7eed7d3",
      "url": "assets/details/df3c62ad-e25a-48d3-9d43-b119b7eed7d3",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 20",
      "score": 0,
      "createdOn": "2022-10-05T18:36:36.85516Z",
      "modifiedOn": "2022-10-21T14:20:36.050334Z"
    },
    {
      "assetId": "eecdfab7-6b54-4378-8eb1-651b314696fa",
      "description": "Asset 17",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 17",
      "files": [
        {
          "id": "dc205b27-675c-4298-8993-51a1361b92ae",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 2.27.51 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLaef293d34a604ccf8905baf04552cc90_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=%2Ft2yHOhmOQrgX3JHQrKk9%2FTa4Y8%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLaef293d34a604ccf8905baf04552cc90_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=dA4FTh6vblgcUK5geJNS9%2F1URxw%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLaef293d34a604ccf8905baf04552cc90_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=8t2y2ijslnk66t2XlgNRBfIoo0Q%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLaef293d34a604ccf8905baf04552cc90_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=NDOs%2FVZTbhmh6XZs0IyxISSU5Yg%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "eecdfab7-6b54-4378-8eb1-651b314696fa",
      "url": "assets/details/eecdfab7-6b54-4378-8eb1-651b314696fa",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 17",
      "score": 0,
      "createdOn": "2022-10-05T18:28:38.853775Z",
      "modifiedOn": "2022-11-10T20:16:36.56025Z"
    },
    {
      "assetId": "e7fae05b-08b6-45b7-8be8-3d5d5fa19366",
      "description": "test",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2024-05-31T04:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "test",
      "files": [
        {
          "id": "d5cff912-016e-4cd6-9f65-a0f0608c935b",
          "groupName": null,
          "fileName": "Quick mockuo.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d63fdc7a0714855b1f9dec118b0172c_110.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=iz%2Bhui%2FyjRz3E2oEd4gXKZRTUI8%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d63fdc7a0714855b1f9dec118b0172c_400.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=tQsHYf%2BgKK4Cb8zUdvj4K3rUP8Q%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d63fdc7a0714855b1f9dec118b0172c_500.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=jIKAZGXlySuafjpyph0vG%2Ft4UFM%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d63fdc7a0714855b1f9dec118b0172c_700.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=n8W%2BT6yeVxzTLJeUptDibASjKpU%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "e7fae05b-08b6-45b7-8be8-3d5d5fa19366",
      "url": "assets/details/e7fae05b-08b6-45b7-8be8-3d5d5fa19366",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Test filename 3",
      "score": 0,
      "createdOn": "2023-12-15T21:02:27.8527Z",
      "modifiedOn": "2024-05-31T16:18:24.848811Z"
    },
    {
      "assetId": "08e0bbe9-38aa-4514-bd49-504443d5a5bc",
      "description": "test",
      "userActions": {
        "hasAcceptedUsageRights": false,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "test",
      "expiresOn": "2023-12-28T05:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "test",
      "files": [
        {
          "id": "0dd53725-088f-4d05-b93a-cb1016441927",
          "groupName": null,
          "fileName": "Added test file name.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40d7f86618864b51818cf9bc26053b42_110.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=1tBM9Eagd%2FDWTvaQciZgDEjUf5E%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40d7f86618864b51818cf9bc26053b42_400.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=UeGLiBUuVBeqKyf%2FTHMidpmQCC0%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40d7f86618864b51818cf9bc26053b42_500.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=GBGThEiHygodONJjumDeY51s5h4%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40d7f86618864b51818cf9bc26053b42_700.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=%2BOllfP0oUoRW0TNoP1Q0J%2BMxWcs%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "08e0bbe9-38aa-4514-bd49-504443d5a5bc",
      "url": "assets/details/08e0bbe9-38aa-4514-bd49-504443d5a5bc",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Filename test",
      "score": 0,
      "createdOn": "2023-12-15T18:59:23.434986Z",
      "modifiedOn": "2023-12-15T18:59:23.355233Z"
    },
    {
      "assetId": "19678c61-15a5-42c3-8e71-9d5d29a47575",
      "description": "Asset 21",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 21",
      "files": [
        {
          "id": "6e0cf4a4-9c0b-46b6-83bf-86c0b844fbb1",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 2.36.51 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe264354d8bc7437982b38270f7eda761_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=kUqIcrmFQ%2FHkh%2BkJ%2BsX6n9jMkiE%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe264354d8bc7437982b38270f7eda761_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=YMej4kl2Qo8vEAF%2Bod8Zj1gcYWE%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe264354d8bc7437982b38270f7eda761_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=3dEPmdcstIgm9rnMCAHZlA4hKf0%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe264354d8bc7437982b38270f7eda761_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=%2Ftbp0oYo2pOwpaJI%2FoXYWNxrA%2F8%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "19678c61-15a5-42c3-8e71-9d5d29a47575",
      "url": "assets/details/19678c61-15a5-42c3-8e71-9d5d29a47575",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 21",
      "score": 0,
      "createdOn": "2022-10-05T18:37:36.261066Z",
      "modifiedOn": "2022-10-21T14:20:36.555645Z"
    },
    {
      "assetId": "24a1b13c-483f-4220-a1d4-cbee3ed3813a",
      "description": "Asset 19",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 19",
      "files": [
        {
          "id": "666c65d6-0c3f-451e-8b27-b1457eeb8e11",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 2.30.52 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40143b7fe7e74917a0a4d7af33c62101_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=nfpKMgMKeoA9PsOD8P2iWIXjeyI%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40143b7fe7e74917a0a4d7af33c62101_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=V7icyDca8TpzctR25y3%2FZ8vli0U%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40143b7fe7e74917a0a4d7af33c62101_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=%2FeEFJx7vfhWGQuvyvgFAM0bwJHM%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL40143b7fe7e74917a0a4d7af33c62101_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=eSrCdj6ZzAYiBscDF0p%2BGl6B4lw%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "24a1b13c-483f-4220-a1d4-cbee3ed3813a",
      "url": "assets/details/24a1b13c-483f-4220-a1d4-cbee3ed3813a",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 19",
      "score": 0,
      "createdOn": "2022-10-05T18:31:51.647943Z",
      "modifiedOn": "2022-10-21T14:20:36.084451Z"
    },
    {
      "assetId": "4105ca55-36cf-4e87-a64a-7edf93a3869c",
      "description": "Asset 37",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 37",
      "files": [
        {
          "id": "6f165adf-c0e3-4159-acf3-affcaf25daa4",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 3.23.39 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLedcbcc5ed3874f0fb0382fb923e18ece_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=ZJvjxk47N8TLQlJU%2FyJo1hOI5pA%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLedcbcc5ed3874f0fb0382fb923e18ece_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Z2uDT2bmeqzEt3CL3KdTAmwfvhU%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLedcbcc5ed3874f0fb0382fb923e18ece_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=b%2BTTYJag9o5Ppx3orsrYCoJ416U%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLedcbcc5ed3874f0fb0382fb923e18ece_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=xroYdpWQVDYRsM5XMKKv6ihw%2Flc%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "4105ca55-36cf-4e87-a64a-7edf93a3869c",
      "url": "assets/details/4105ca55-36cf-4e87-a64a-7edf93a3869c",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 37",
      "score": 0,
      "createdOn": "2022-10-05T19:24:21.793831Z",
      "modifiedOn": "2022-10-21T14:20:36.01367Z"
    },
    {
      "assetId": "8ad52a87-b10b-43b5-8188-3b9da7d91614",
      "description": "I bet this makes you wanna eat cheese.",
      "userActions": {
        "hasAcceptedUsageRights": false,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "You can use it but only if you tag Obama.",
      "expiresOn": "2024-04-20T04:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Wow! So cool!",
      "files": [
        {
          "id": "c0d3dcb1-831a-44fe-8556-81b4d682ff15",
          "groupName": null,
          "fileName": "0z0vcyxc4sz21.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf3c8dcf74d674325afdf3bbe345d4201_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=xT7hRwlDVrthf6z667fOG3XtL8w%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf3c8dcf74d674325afdf3bbe345d4201_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=l0Uk8VoPGDTpkS1CXgKBetirNsc%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf3c8dcf74d674325afdf3bbe345d4201_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=1R80y9c07qQ7ZtvsjQVmhBW6c9A%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf3c8dcf74d674325afdf3bbe345d4201_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=IT%2BQ0aNTn5vdq1f3ZF71g0PBvtw%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "b748d5f8-528c-428a-9ed0-8143c3918d19"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "8ad52a87-b10b-43b5-8188-3b9da7d91614",
      "url": "assets/details/8ad52a87-b10b-43b5-8188-3b9da7d91614",
      "searchType": "asset",
      "isFavorite": false,
      "title": "QA Restricted 4",
      "score": 0,
      "createdOn": "2023-09-12T17:59:54.021936Z",
      "modifiedOn": "2023-09-12T17:59:54.009873Z"
    },
    {
      "assetId": "c2d42efd-dfb1-4dbf-a0fe-855272140b62",
      "description": "Asset Chris 7",
      "userActions": {
        "hasAcceptedUsageRights": false,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "Some usage rights info right here for testing purposes.",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset Chris 7",
      "files": [
        {
          "id": "7923a31c-3b21-445d-9889-95bcccae3545",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 9.45.33 AM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL65599f9f6c35491d8a73a266d6badedd_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=vuWK%2FEhuNAdRLKwzmLyn2FSf5XU%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL65599f9f6c35491d8a73a266d6badedd_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=kHyG9sVpGOp4GPGlkleFfwHgp6E%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL65599f9f6c35491d8a73a266d6badedd_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=8sYWLzhBtkzhiviA%2BzaSpgwQtkM%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL65599f9f6c35491d8a73a266d6badedd_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=KfUGeg7i0yZPh3NVUHv9WZjdBnM%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "c2d42efd-dfb1-4dbf-a0fe-855272140b62",
      "url": "assets/details/c2d42efd-dfb1-4dbf-a0fe-855272140b62",
      "searchType": "asset",
      "isFavorite": true,
      "title": "Asset Chris 7",
      "score": 0,
      "createdOn": "2022-10-05T18:06:57.54192Z",
      "modifiedOn": "2022-12-07T18:01:28.063584Z"
    },
    {
      "assetId": "c4ab950f-622c-4df7-8c60-d2938200ca20",
      "description": "Asset 11",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 11",
      "files": [
        {
          "id": "a04e6b31-f39a-4f7a-bd35-fbfcd39bf23a",
          "groupName": null,
          "fileName": "Screen Shot 2022-08-24 at 11.54.36 AM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf65564953534431ab8a4a0558226dfb4_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Vju0oenRI71acUMxZkd0cJaM3YE%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf65564953534431ab8a4a0558226dfb4_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=b0k5k0taVwQSkYw8M9Co5s%2B8%2Bj0%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf65564953534431ab8a4a0558226dfb4_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=jBNbyozt%2Fly8nhIwJKJoQsIICes%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLf65564953534431ab8a4a0558226dfb4_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=yiCYFxquYvuddbCSutCb23grsW0%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "990afd3e-7939-4f41-8cb4-279b1189ce29"
      ],
      "category": "Photography",
      "downloadCount": 1,
      "id": "c4ab950f-622c-4df7-8c60-d2938200ca20",
      "url": "assets/details/c4ab950f-622c-4df7-8c60-d2938200ca20",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 11",
      "score": 0,
      "createdOn": "2022-10-05T18:19:34.341549Z",
      "modifiedOn": "2022-10-21T14:20:36.560139Z"
    },
    {
      "assetId": "dd481174-fc24-4dc4-a366-9f7bae61c0e9",
      "description": "Restricted nonsearchable",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": false,
      "altText": "Restricted nonsearchable",
      "files": [
        {
          "id": "27646746-d6cd-4ee4-94e9-0bcd63fe5f12",
          "groupName": null,
          "fileName": "Brand_Central_attachment.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=JcCdnoT9gRYGZYlqbSV5%2BCpGjIM%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=KNc4tk4CRTrTORtm6k%2BCHQZolyU%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=o4TgsMRxX2oZ2pCasoH38PRSx1A%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=o2wK7Sisbi%2F7CuyqDFjcCf8fLRg%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "dd481174-fc24-4dc4-a366-9f7bae61c0e9",
      "url": "assets/details/dd481174-fc24-4dc4-a366-9f7bae61c0e9",
      "searchType": "asset",
      "isFavorite": true,
      "title": "Restricted nonsearchable",
      "score": 0,
      "createdOn": "2022-09-09T14:12:50.788349Z",
      "modifiedOn": "2023-10-03T14:58:22.360059Z"
    },
    {
      "assetId": "d7943e08-3d9b-4a54-a287-a04e60bcf811",
      "description": "Asset 29",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 29 test",
      "files": [
        {
          "id": "dbfe1b9d-404d-443d-a472-533cbd92cc6f",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 2.55.16 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL844c12123546435791501090e17dc8c2_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=HBKXalR5pL%2FS2EtDKG1%2BYBGqSto%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL844c12123546435791501090e17dc8c2_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=SUdelmG4QKwRAdpDOWfci4F8elc%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL844c12123546435791501090e17dc8c2_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=yh3zDNOAGk1i7ppisYgxMtDY9w4%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL844c12123546435791501090e17dc8c2_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=0rvI4aJ6IIE5iW6bSI%2FBXrp8a%2B0%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "d7943e08-3d9b-4a54-a287-a04e60bcf811",
      "url": "assets/details/d7943e08-3d9b-4a54-a287-a04e60bcf811",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 29",
      "score": 0,
      "createdOn": "2022-10-05T18:57:05.245611Z",
      "modifiedOn": "2023-09-28T14:45:39.289794Z"
    },
    {
      "assetId": "e344973c-2bc3-42e0-be04-083211117c99",
      "description": "This is a test",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2023-10-31T04:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "test",
      "files": [
        {
          "id": "e4e5b3a5-9155-434a-a058-45017642920d",
          "groupName": null,
          "fileName": "TwilightTown.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3daf2ef3ebb14b85b05cb55164fcdb3c_110.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=iJdLjc5BryQyXxXkQb7y%2FNPAotE%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3daf2ef3ebb14b85b05cb55164fcdb3c_400.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=nL0ukSjmnI0s1V%2Bjt1xNAo3Agjk%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3daf2ef3ebb14b85b05cb55164fcdb3c_500.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=bUB2e25dkaFOs1Cdmf4z%2BZF7pdI%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3daf2ef3ebb14b85b05cb55164fcdb3c_700.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=O7XSqkDarMAPRocDucl8DqHA24o%3D",
            "isImage": true
          }
        },
        {
          "id": "a59a7ae1-28fb-4912-9b59-ecdb66b1897a",
          "groupName": null,
          "fileName": "Scala.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc3043f8b94a84ba0a4c9a85ae233e9ea_110.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=fhtT76fvfj6lpTPYcbC0N26Z1Ec%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc3043f8b94a84ba0a4c9a85ae233e9ea_400.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=k3rbijFYiU6%2BzlAMpTt%2FK0g9uyw%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc3043f8b94a84ba0a4c9a85ae233e9ea_500.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=6jMVrs6RxL0mw57HHBdGnJgiciE%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLc3043f8b94a84ba0a4c9a85ae233e9ea_700.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=U0lwaL2T%2Br4YjR9P1NmTDzFSmAU%3D",
            "isImage": true
          }
        },
        {
          "id": "71b18f6a-4d69-4236-894c-1750edf208d1",
          "groupName": null,
          "fileName": "Islands.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL31ed60c22a7042ab86ac7eb1c5b26126_110.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=UjWU5bA6gY5btOvieeCaA41rLKw%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL31ed60c22a7042ab86ac7eb1c5b26126_400.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=3o6aWjL3%2FxlQMUJF8SPoD5iLECk%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL31ed60c22a7042ab86ac7eb1c5b26126_500.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=SMSmqaqXLIgOTWvtINrKdUwF898%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL31ed60c22a7042ab86ac7eb1c5b26126_700.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=dSVGC%2B%2FgbHF3IXe7MyQupJKD1qE%3D",
            "isImage": true
          }
        },
        {
          "id": "3c29a366-c011-44fc-ae4a-94dd701f200f",
          "groupName": null,
          "fileName": "Merlin.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d125cbcb7bd423fb1e35799c27ba6ac_110.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=3YzSN%2Bo0FanAgOviouPwtvFVsSI%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d125cbcb7bd423fb1e35799c27ba6ac_400.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=6kGWYoKRLktaRCdkJbs1%2FxhMSbA%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d125cbcb7bd423fb1e35799c27ba6ac_500.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=CuCa%2BqvW5Mz%2F1EUALrmSSc7KLzA%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0d125cbcb7bd423fb1e35799c27ba6ac_700.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=e3Y4jr5z%2BcmOImxuH3Vj%2Bix47ZU%3D",
            "isImage": true
          }
        },
        {
          "id": "223d85bb-77d6-4c82-9799-18e828e814c0",
          "groupName": null,
          "fileName": "9679392724_d7a50c4270_b.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd662212d18af4b2f8a5e084f7a74db7a_110.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=1AG7Q%2Fpb6tap%2BkXvBR2ainiW69c%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd662212d18af4b2f8a5e084f7a74db7a_400.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=nGOsJ8SF5Iq%2Fat2cMWGUBJBAPrc%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd662212d18af4b2f8a5e084f7a74db7a_500.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=dwSM5Rw6yQR9plLFSPTUYmqwdJ4%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd662212d18af4b2f8a5e084f7a74db7a_700.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=DDuiQAclQCPNzVcKo2ScbINiYNM%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "177c1b1b-cc61-49b0-a389-81fc1a62c320",
        "6d397aaf-5e7d-40bb-983d-b670fe749003"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "e344973c-2bc3-42e0-be04-083211117c99",
      "url": "assets/details/e344973c-2bc3-42e0-be04-083211117c99",
      "searchType": "asset",
      "isFavorite": false,
      "title": "QA multiple pending",
      "score": 0,
      "createdOn": "2023-10-03T15:06:33.855401Z",
      "modifiedOn": "2023-10-06T13:41:58.856443Z"
    },
    {
      "assetId": "e58c32c0-f286-4144-89a9-9b43dc79dd61",
      "description": "I bet this makes you wanna eat cheese.",
      "userActions": {
        "hasAcceptedUsageRights": false,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "You can use it but only if you tag Obama.",
      "expiresOn": "2024-04-20T04:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Wow! So cool!",
      "files": [
        {
          "id": "f30c6330-6587-4838-99d2-9452b9461a61",
          "groupName": null,
          "fileName": "images.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2643da4983974e7394c6a32f65f735d0_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Y%2FVpOMFysyBEhnlrAYbP31ilwu0%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2643da4983974e7394c6a32f65f735d0_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=m6F6ok7vczyV0%2FgN%2BalFyzQbRVk%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2643da4983974e7394c6a32f65f735d0_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=MYPm8QY4v3cDUm%2FXBR9sH1egvo4%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2643da4983974e7394c6a32f65f735d0_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=rlRUMYWkaZkqoHwucMD8WvzsB3E%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "b748d5f8-528c-428a-9ed0-8143c3918d19"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "e58c32c0-f286-4144-89a9-9b43dc79dd61",
      "url": "assets/details/e58c32c0-f286-4144-89a9-9b43dc79dd61",
      "searchType": "asset",
      "isFavorite": false,
      "title": "QA Restricted 6",
      "score": 0,
      "createdOn": "2023-09-12T17:59:54.01894Z",
      "modifiedOn": "2023-09-12T17:59:54.011389Z"
    },
    {
      "assetId": "f46409c7-1855-474e-851d-8ff805c7d45b",
      "description": "danhuri test photoshoot 7 assets same job",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "danhuri test photoshoot",
      "files": [
        {
          "id": "9521ebfc-b312-4082-bdb0-9f6b10e23542",
          "groupName": null,
          "fileName": "global search button 2.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5835e34f2bd4401799108c276dd4f041_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=pHCg0gCZQCawWS0HGlemv0IDqlk%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5835e34f2bd4401799108c276dd4f041_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=YkrwfynRVut90YjoOoOHNIqdjhI%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5835e34f2bd4401799108c276dd4f041_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=3bSEn15mw87LEC5f6d%2BvdIMrU8s%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5835e34f2bd4401799108c276dd4f041_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=IpzXtx7bqqLYf7DrsoHzOBPzREA%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f"
      ],
      "category": "Photography",
      "downloadCount": 1,
      "id": "f46409c7-1855-474e-851d-8ff805c7d45b",
      "url": "assets/details/f46409c7-1855-474e-851d-8ff805c7d45b",
      "searchType": "asset",
      "isFavorite": false,
      "title": "danhuri test photoshoot 6",
      "score": 0,
      "createdOn": "2022-08-03T13:22:07.261856Z",
      "modifiedOn": "2022-08-03T13:23:05.086578Z"
    },
    {
      "assetId": "fdaa4b50-6923-452b-a9b2-8772c969ad0a",
      "description": "Asset 32",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 32",
      "files": [
        {
          "id": "2f90351b-4313-42fb-971d-1854bbbf3cfc",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 3.04.21 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL990a9550c5a3478d96c4b1ec330aa908_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=pB7nrUk7sxp%2FZoCQqNEU1IS8%2B6g%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL990a9550c5a3478d96c4b1ec330aa908_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=QR%2B4sLKurto662%2F0BbuZJOrp9sM%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL990a9550c5a3478d96c4b1ec330aa908_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=R1mnF43Z3O%2BfPeVwqxnlR3JKpy0%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL990a9550c5a3478d96c4b1ec330aa908_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=FbLVW03GeG1mrtd81b0upvprME4%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "fdaa4b50-6923-452b-a9b2-8772c969ad0a",
      "url": "assets/details/fdaa4b50-6923-452b-a9b2-8772c969ad0a",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 32",
      "score": 0,
      "createdOn": "2022-10-05T19:06:47.837666Z",
      "modifiedOn": "2022-11-11T16:13:20.87006Z"
    },
    {
      "assetId": "015fed73-b798-4bd3-8c05-6c46eb22f7d7",
      "description": "Test asset for access denial, asset 6",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Test asset for access denial, asset 6",
      "files": [
        {
          "id": "aeb391db-5284-404e-8e1a-37db824634b1",
          "groupName": null,
          "fileName": "Screen Shot 2022-07-20 at 9.11.27 AM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e7558e9a0794a20a21ef36747dec958_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=UiKeNbHSHml%2F%2FUhl9NtBi5iOHhg%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e7558e9a0794a20a21ef36747dec958_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=ZBkOGbH7H3qOZkJ0XUr9pJM6icU%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e7558e9a0794a20a21ef36747dec958_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=cVGfXSUuxRCL7w62%2F6pt6LSCDAE%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3e7558e9a0794a20a21ef36747dec958_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=NOh0hMVrZVcDnNO0WIWah6UUPfU%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "99f86197-9021-4581-9146-2c04cecd1b7a",
        "fa9267e8-865d-4d52-aabf-558d76c1528c",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Danhuri Category 12",
      "downloadCount": 2,
      "id": "015fed73-b798-4bd3-8c05-6c46eb22f7d7",
      "url": "assets/details/015fed73-b798-4bd3-8c05-6c46eb22f7d7",
      "searchType": "asset",
      "isFavorite": true,
      "title": "Test asset for access denial, asset 6",
      "score": 0,
      "createdOn": "2022-07-25T19:04:05.395156Z",
      "modifiedOn": "2023-02-27T17:13:03.638618Z"
    },
    {
      "assetId": "2919dbcb-6184-4814-b312-4fc2f0a6fa29",
      "description": "Using this asset to test asset approved/denied work",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Test asset 1",
      "files": [],
      "filterIds": [
        "344bbcaf-766b-406d-8136-e19064297ee9",
        "721b599a-22ec-4e45-8ac6-c63a188e073a",
        "c5c687c3-e3f1-4345-867e-51d2b86226fb",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Danhuri Test Category 3",
      "downloadCount": 0,
      "id": "2919dbcb-6184-4814-b312-4fc2f0a6fa29",
      "url": "assets/details/2919dbcb-6184-4814-b312-4fc2f0a6fa29",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Test asset 7-20-22",
      "score": 0,
      "createdOn": "2022-07-20T13:34:07.116755Z",
      "modifiedOn": "2022-10-21T14:20:36.572972Z"
    },
    {
      "assetId": "264f42eb-9858-4aaa-a38a-1fdcdc4e733e",
      "description": "Asset Chris 2",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset Chris 2",
      "files": [
        {
          "id": "f43b640e-179d-4d30-9b2e-daa4fe65744f",
          "groupName": null,
          "fileName": "page break icon category.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3ccd698c5e3f41ecbaa5590249bd53fd_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=C7c%2Fh289cXBmfygpG5J2A%2BAoT6Y%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3ccd698c5e3f41ecbaa5590249bd53fd_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=H9wwoR82z9gLxrU5G5G%2BbaeiamU%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3ccd698c5e3f41ecbaa5590249bd53fd_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=S%2Bbh%2Ba4H0c8s15f8fqJ6PjvrEEQ%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3ccd698c5e3f41ecbaa5590249bd53fd_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291621&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=J9mN4Pf23mUD4hddYXrLbYluZr8%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "264f42eb-9858-4aaa-a38a-1fdcdc4e733e",
      "url": "assets/details/264f42eb-9858-4aaa-a38a-1fdcdc4e733e",
      "searchType": "asset",
      "isFavorite": true,
      "title": "Asset Chris 2",
      "score": 0,
      "createdOn": "2022-10-05T16:45:04.291434Z",
      "modifiedOn": "2022-10-21T14:20:36.555058Z"
    },
    {
      "assetId": "2473c54d-6608-4209-9668-bebd117ff150",
      "description": "Asset 23",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 23",
      "files": [
        {
          "id": "3cee2f38-335c-4a37-905a-471d7228c5e7",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 2.40.53 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2eeda327e8bf4b078b8aa51e2deb7b79_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=ZrEHDgkPJU0hlnjrYXh6p%2Fr5%2Fnc%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2eeda327e8bf4b078b8aa51e2deb7b79_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=3fpbt3bFev2dmA1Sq%2BR7D56znYk%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2eeda327e8bf4b078b8aa51e2deb7b79_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Yac58GEHud7pIRKHBilcZq43a7M%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2eeda327e8bf4b078b8aa51e2deb7b79_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=sPC%2FlLvjjrh7%2BL9jLPCiLyZOfWY%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "990afd3e-7939-4f41-8cb4-279b1189ce29"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "2473c54d-6608-4209-9668-bebd117ff150",
      "url": "assets/details/2473c54d-6608-4209-9668-bebd117ff150",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 23",
      "score": 0,
      "createdOn": "2022-10-05T18:41:52.391357Z",
      "modifiedOn": "2022-10-21T14:20:36.602811Z"
    },
    {
      "assetId": "348a55ba-1003-429a-bff5-e6567642c1df",
      "description": "Asset 10",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 10",
      "files": [
        {
          "id": "ea49abe9-dc90-4329-b6fc-0d0250212899",
          "groupName": null,
          "fileName": "Asset Req form example 2.pdf",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=xkBAqoLPhVKAT2CEJdyVlpnlBn0%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=CiRgOAo4eYcfiJafiB8cpGuLPfI%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=T58m7hOa2SFNCW6UwTowBE3MPlE%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLbc241abdfad84cf6aad5ffeb2a787704_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=OfY2pTswu%2Fht1OKkD1ugvf9tTaI%3D",
            "isImage": false
          }
        },
        {
          "id": "f661dadd-9eb2-4119-a179-3518164055aa",
          "groupName": null,
          "fileName": "Screen Shot 2022-08-31 at 3.01.18 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL8c6e7a50ce134260a8b6d14539254366_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Ap76x0AReI3VxWAopp%2BWJdKjQ5A%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL8c6e7a50ce134260a8b6d14539254366_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=yD18S6CunwwVkOJF1ct1ZdSqJ%2B8%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL8c6e7a50ce134260a8b6d14539254366_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=t3wQOcPXhtdvOOXqW84CZwg7c%2Fs%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL8c6e7a50ce134260a8b6d14539254366_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=BctAs9AVt8vw5negzPZ4J47BrOo%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10"
      ],
      "category": "Photography",
      "downloadCount": 3,
      "id": "348a55ba-1003-429a-bff5-e6567642c1df",
      "url": "assets/details/348a55ba-1003-429a-bff5-e6567642c1df",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 10",
      "score": 0,
      "createdOn": "2022-10-05T18:15:18.622797Z",
      "modifiedOn": "2022-10-28T14:56:10.147888Z"
    },
    {
      "assetId": "34e8937f-443b-4ed0-979b-66d9b2601e61",
      "description": "SEM test asset-approved",
      "userActions": {
        "hasAcceptedUsageRights": false,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "SEM test asset-approved",
      "expiresOn": "2030-10-10T23:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "SEM test asset-approved",
      "files": [
        {
          "id": "30a8daf4-7dfa-450d-9091-6c32594aa485",
          "groupName": null,
          "fileName": "1981_christmas-gun.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL70b846f7d43947999e6d75b404aad0cc_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Z%2FXtpXq1j%2FfozlH6Ec4HwCoz524%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL70b846f7d43947999e6d75b404aad0cc_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=56OLekF%2BykWU9lLmbAOkkva5Z3k%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL70b846f7d43947999e6d75b404aad0cc_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=07Y%2FaFDIvzXpDvGpVh5R8EfJwKE%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL70b846f7d43947999e6d75b404aad0cc_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=YyCseRuMVRuz65wyzwdFyEZkBeM%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "177c1b1b-cc61-49b0-a389-81fc1a62c320",
        "6d397aaf-5e7d-40bb-983d-b670fe749003"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "34e8937f-443b-4ed0-979b-66d9b2601e61",
      "url": "assets/details/34e8937f-443b-4ed0-979b-66d9b2601e61",
      "searchType": "asset",
      "isFavorite": false,
      "title": "SEM test asset-approved",
      "score": 0,
      "createdOn": "2023-02-21T15:38:57.130945Z",
      "modifiedOn": "2023-09-27T18:37:06.145246Z"
    },
    {
      "assetId": "5883362e-bbef-418e-abee-c072ce08ee16",
      "description": "danhuri test photoshoot 7 assets same job",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "danhuri test photoshoot",
      "files": [
        {
          "id": "24803a59-1490-40f4-bbc5-ceaaaba2abab",
          "groupName": null,
          "fileName": "asset screen shot new version 2.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3690f88807cc4980aa6204b3374800ba_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=LTmqzFZTDKV1aYVZnYLMWeyyuzk%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3690f88807cc4980aa6204b3374800ba_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=gHcSS00b8dWeNMGp1n5tHiSJuek%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3690f88807cc4980aa6204b3374800ba_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=MYWHTOMAoeB2VIz6rDUEr%2FEtALE%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3690f88807cc4980aa6204b3374800ba_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=1lzZaO3AeWoSGYKVjkMspdm%2Ba2g%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "5883362e-bbef-418e-abee-c072ce08ee16",
      "url": "assets/details/5883362e-bbef-418e-abee-c072ce08ee16",
      "searchType": "asset",
      "isFavorite": false,
      "title": "danhuri test photoshoot 1",
      "score": 0,
      "createdOn": "2022-08-03T13:22:07.508116Z",
      "modifiedOn": "2022-11-07T18:46:26.666329Z"
    },
    {
      "assetId": "58c697f3-e34e-4018-a61b-798a72d549e6",
      "description": "Asset Chris 3",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset Chris 3",
      "files": [
        {
          "id": "19489e78-a5aa-4eef-9b2e-144261c66287",
          "groupName": null,
          "fileName": "Screen Shot 2022-09-27 at 2.22.37 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL11d129bc4a7c4dc48062b532397fe4a2_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=nqNclMi%2BQB9ydd7ZHkTMkSjID9U%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL11d129bc4a7c4dc48062b532397fe4a2_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=F6IzxbhTodqHLJ7YwAhTf5u1mWU%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL11d129bc4a7c4dc48062b532397fe4a2_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=xL5EOaqU9V931%2BeDWb5spALUCXI%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL11d129bc4a7c4dc48062b532397fe4a2_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=0jwwHkwl06FmbBh39PN507aO6lc%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "58c697f3-e34e-4018-a61b-798a72d549e6",
      "url": "assets/details/58c697f3-e34e-4018-a61b-798a72d549e6",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset Chris 3",
      "score": 0,
      "createdOn": "2022-10-05T16:46:13.433033Z",
      "modifiedOn": "2022-10-21T14:20:36.573967Z"
    },
    {
      "assetId": "7998d130-8d4c-4030-85e6-2d131e98f90e",
      "description": "Asset 13",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 13",
      "files": [
        {
          "id": "01fca0ab-b1a5-4cf0-bf78-874e4b404d4a",
          "groupName": null,
          "fileName": "Screen Shot 2022-08-17 at 11.40.00 AM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd30a76d011934fc2ab446095d54d686c_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=1mqcoeU9ZS1ecfPXHG7OM8pEpig%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd30a76d011934fc2ab446095d54d686c_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=WH5EnM5IX65FVVy2VIQdBDSl%2B6Y%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd30a76d011934fc2ab446095d54d686c_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=QZhmVcWk0Od%2BvHm3zk8k5gXgEtQ%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd30a76d011934fc2ab446095d54d686c_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=A7fPI5%2B%2FM6b96Mf1xTlKehAZ6Gg%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f"
      ],
      "category": "Photography",
      "downloadCount": 1,
      "id": "7998d130-8d4c-4030-85e6-2d131e98f90e",
      "url": "assets/details/7998d130-8d4c-4030-85e6-2d131e98f90e",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 13",
      "score": 0,
      "createdOn": "2022-10-05T18:22:38.507114Z",
      "modifiedOn": "2022-10-21T14:20:36.553191Z"
    },
    {
      "assetId": "a38429ae-e1c5-4c2d-bcbe-e4f3f9274a5f",
      "description": "Asset 12",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 12",
      "files": [
        {
          "id": "268cad4e-24e1-49d1-a3db-f911a6f7fe34",
          "groupName": null,
          "fileName": "Screen Shot 2022-09-14 at 1.06.56 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL96c753030c2847d382a8ce6186425e12_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=LCVVQUeB1GI7mAebVm27J%2BarMGA%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL96c753030c2847d382a8ce6186425e12_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=LC%2BSk7JUecVYYYZK44slLTaO4B0%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL96c753030c2847d382a8ce6186425e12_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Kx3UpfdyCtWwZyq8wV6vo2oVhmY%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL96c753030c2847d382a8ce6186425e12_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=J8CL956WHsSnujtyjE%2BCcyBfR2U%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "a38429ae-e1c5-4c2d-bcbe-e4f3f9274a5f",
      "url": "assets/details/a38429ae-e1c5-4c2d-bcbe-e4f3f9274a5f",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 12",
      "score": 0,
      "createdOn": "2022-10-05T18:21:10.590418Z",
      "modifiedOn": "2022-10-21T14:20:36.564542Z"
    },
    {
      "assetId": "9fd70508-57a5-4c65-8f53-216094472ab2",
      "description": "Asset 8",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 8",
      "files": [
        {
          "id": "42c481c7-3872-4086-9b01-63716448eaf9",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 2.09.24 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL07c990df8aff4774bcec4be5d9b36806_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=UG258Npq08GQ8ffyCojhm1QBwbI%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL07c990df8aff4774bcec4be5d9b36806_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=jK%2FNJqd6AILHG2uzjz0YCtLyYoc%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL07c990df8aff4774bcec4be5d9b36806_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=C80nF%2BzgcRAIaamC59SKWw102Nw%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL07c990df8aff4774bcec4be5d9b36806_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=p2okEY1%2B5G1alasy9fkJLN47PDc%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "9fd70508-57a5-4c65-8f53-216094472ab2",
      "url": "assets/details/9fd70508-57a5-4c65-8f53-216094472ab2",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 8",
      "score": 0,
      "createdOn": "2022-10-05T18:10:18.261289Z",
      "modifiedOn": "2022-10-21T14:20:36.558807Z"
    },
    {
      "assetId": "ba63125d-b757-4824-8398-3a9771468863",
      "description": "Test asset for asset denial Asset 7",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Test asset for asset denial Asset 7",
      "files": [],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "ba63125d-b757-4824-8398-3a9771468863",
      "url": "assets/details/ba63125d-b757-4824-8398-3a9771468863",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Test asset for asset denial Asset 7",
      "score": 0,
      "createdOn": "2022-07-25T19:05:25.26787Z",
      "modifiedOn": "2022-10-21T14:20:36.563795Z"
    },
    {
      "assetId": "bb26b945-a995-472d-bcb5-af782f46d827",
      "description": "Asset 30",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset 30",
      "files": [
        {
          "id": "de03080c-48a0-44a8-8611-153d6944f082",
          "groupName": null,
          "fileName": "Screen Shot 2022-10-05 at 2.57.22 PM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL42cb0f4a39594bf58f13ec369bfca349_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=dAL1YO8Zk%2B2bwf3rJODBZ5LUGe0%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL42cb0f4a39594bf58f13ec369bfca349_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=O5tTU9Shs6yDV9tZug9ZxSUP5O0%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL42cb0f4a39594bf58f13ec369bfca349_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=4QiFpNrsX9zOCnEzQc2qmPDL5do%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL42cb0f4a39594bf58f13ec369bfca349_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=%2BYQCN7q00QIe1DJyErLFOwx2xYM%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "bb26b945-a995-472d-bcb5-af782f46d827",
      "url": "assets/details/bb26b945-a995-472d-bcb5-af782f46d827",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset 30",
      "score": 0,
      "createdOn": "2022-10-05T18:58:07.967545Z",
      "modifiedOn": "2022-10-21T14:20:36.570262Z"
    },
    {
      "assetId": "f86b7deb-d738-487d-894b-1bf89f16ad14",
      "description": "Test Asset 8 - one approve, one deny",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Test Asset 8 - one approve, one deny",
      "files": [
        {
          "id": "63221ace-0a2e-4bb6-b79d-098fbca40444",
          "groupName": null,
          "fileName": "Screen Shot 2022-07-11 at 9.52.39 AM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1c6d3b4b6e894fb188dadafab9b888a1_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=p2g3DmyweVwxkO6h%2BbQMlVJQvzk%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1c6d3b4b6e894fb188dadafab9b888a1_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=hyGCBz4l6%2FRvkdXs8kXH%2BMJ4vV0%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1c6d3b4b6e894fb188dadafab9b888a1_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=%2FIm5aKYQjSIHImU0kd%2FzdndV4jE%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1c6d3b4b6e894fb188dadafab9b888a1_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=UKf0quB25iz4pV7fjOr1CJTSUDc%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f",
        "46e5b6fc-1b9a-4965-bafa-9d507ebb55ae",
        "e2b73d48-60a0-494e-a492-b67f352825d2",
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Danhuri Category 12",
      "downloadCount": 0,
      "id": "f86b7deb-d738-487d-894b-1bf89f16ad14",
      "url": "assets/details/f86b7deb-d738-487d-894b-1bf89f16ad14",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Test Asset 8 - one approve, one deny",
      "score": 0,
      "createdOn": "2022-07-26T17:01:14.22962Z",
      "modifiedOn": "2022-10-21T14:20:36.587417Z"
    },
    {
      "assetId": "07994f60-9bf2-43af-a92a-f8fe92bef5e9",
      "description": "Asset Chris 6",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2424-12-31T00:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "Asset Chris 6",
      "files": [
        {
          "id": "1144223f-54d1-4b30-ad11-f020c4b0f6bf",
          "groupName": null,
          "fileName": "Screen Shot 2022-09-21 at 9.10.28 AM.png",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb47d0466fddd4eb6a6a802ce2a86d656_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=iui%2Fh%2FbhOV%2Bad%2Fb%2BWad2kYP%2Fbxg%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb47d0466fddd4eb6a6a802ce2a86d656_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=PRsyz%2Ff16QGGk2U1SksmqjCYS3I%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb47d0466fddd4eb6a6a802ce2a86d656_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=f1YaKPNUq4aiBOiNfVOERSsY6Sw%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb47d0466fddd4eb6a6a802ce2a86d656_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLJLFPSAUS&Expires=1720291622&x-amz-security-token=IQoJb3JpZ2luX2VjEKn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQC%2BW7aKvSfSQPS%2BVQmWaxhkgfrhXS5%2FBc1O58dkTkpkHAIgYSk4Nbs%2FweqXqVNTfIkQdXDi30ZvgMBXaLAcqfh%2FQPoqgwQIYRAEGgwyOTA5OTEwMzcxNDIiDNn%2FcidO%2FNhQXSfirirgAwgWqgg0y6Yz2YSebonr1zHNA3TRH2L09fCoSiwOKW%2FuUdXMf4SmSZCYeAkwF%2FkWQnwTKxEmtJ9xD9JUA039BQUDj0B9%2FszmizbTGkAmqR651QkEvL4oJzhxYIvJDp4FF1R3IFNiFO%2Bf7EukFZX%2B7uIO1gzDXdX8lhCdfr3QBgAtRQ5z620C%2BM8HpzxYeiFM65ghoiP8kcmd07FH1%2B1zc3tf5kOotgLHGGFC4le37ul15%2B0DwR3rXEsKrtSHp0rklp7yiFDZGg7iTAYewRimUfwQv8RFBzK51z854%2BVcCxzQMuyD9RVAsLcPl3phSa%2BgUjPTV%2B4lB4%2F%2BRB6UE0ZGCnqYr8AbAzFNQ3dKPFRQgygYaLbzT9XfLZTSvFUmUr4XmnIiv2H6U02lIsnsevaVRYyXoKrEdblPDgYAg702mMThUuDwhb%2BGJarNOowT1c5n%2F7CwRZ5Bn71wo8THpRPYr6iqtQ282uXoxO2r4KzSCcYQAWyChqBhGcZ4ZnV9XMvr8miszhQPUOEigqCLxMMiQI%2Fy%2Fwoyr7BCObGCEvJHLOGGXTtXRcNyESG8eW4ItkN3WxU4WzLNBGaM3gxN04yNCYtPbYsp01WgsckN8zJsOmTNX1HlkK%2FSJlj%2BTha7yR8gNTDEq4u0BjqlAWl054lz647hpJ3eMorMK2gBBnE96tGAIOcbvPc8%2BsjIwbbnq0yOuW%2BIn4UQFgZ5XTt9tMl5JDalvC%2FD8WWzO4N8V2PQbKoyDhTJFqzEj7bz0tiBBcI6Aj0qqYppKUHCfolv2unFE8X%2B9bKDEu3VekHTk8N2aeT3JW4pOEFFyH853Czt9Do8ZWM%2FSjRIact6Mh2UYeAJ%2BmVPR303UHkwYbBIpuvQWA%3D%3D&Signature=Q3%2BkqbMyu%2FtsdYx2SH%2FzsxFfV98%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
        "693c6362-7a93-4b0a-a019-aa1f0437196e",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9066ae36-b552-4e82-98af-f5dc2dd2fe10",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
      ],
      "category": "Photography",
      "downloadCount": 0,
      "id": "07994f60-9bf2-43af-a92a-f8fe92bef5e9",
      "url": "assets/details/07994f60-9bf2-43af-a92a-f8fe92bef5e9",
      "searchType": "asset",
      "isFavorite": false,
      "title": "Asset Chris 6",
      "score": 0,
      "createdOn": "2022-10-05T16:49:24.337755Z",
      "modifiedOn": "2022-10-21T14:20:36.602941Z"
    }
  ],
  "filters": {
    "unfilteredTotal": 130,
    "categories": {
      "76007f55-b998-4883-8e53-64c5ecec4a51": 68,
      "6bc1aa1b-cfa6-478f-b265-357d8e4cf13f": 5,
      "e492b86d-3a1d-45a0-b518-3ace937747cf": 2,
      "0e4b62e8-133b-4c94-94e4-aaafbc6437ef": 1,
      "316c0651-c5e3-4bd2-b41c-5427484f74bd": 1,
      "344bbcaf-766b-406d-8136-e19064297ee9": 1
    },
    "subCategories": {
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660": 57,
      "4ebecaeb-7933-4cb6-a578-4ef99e90f367": 8,
      "121ed840-4049-43f3-a3be-ecd0887e5f11": 2,
      "177c1b1b-cc61-49b0-a389-81fc1a62c320": 2,
      "46e5b6fc-1b9a-4965-bafa-9d507ebb55ae": 2,
      "99f86197-9021-4581-9146-2c04cecd1b7a": 2,
      "048277fa-25ce-458e-9c03-589f84b78544": 1,
      "36b18590-90a5-464e-85b0-a765a47e7e72": 1,
      "721b599a-22ec-4e45-8ac6-c63a188e073a": 1,
      "722b933e-a140-4db3-8c3b-107ad9b8c9f6": 1,
      "a22f39d0-4876-496c-93a6-14fe4011dc8d": 1,
      "b8acea32-28ef-43ee-9ba2-17cff3603481": 1
    },
    "tags": {
      "9bedcce5-f0e5-4b3f-86db-2ee0b810c091": 33,
      "3d8d9269-63a0-437f-90ec-f70204d5c10f": 16,
      "9066ae36-b552-4e82-98af-f5dc2dd2fe10": 12,
      "b748d5f8-528c-428a-9ed0-8143c3918d19": 6,
      "6d397aaf-5e7d-40bb-983d-b670fe749003": 2,
      "990afd3e-7939-4f41-8cb4-279b1189ce29": 2,
      "e2b73d48-60a0-494e-a492-b67f352825d2": 2,
      "fa9267e8-865d-4d52-aabf-558d76c1528c": 2,
      "03d7c08d-b8f6-405a-9907-567d417e71f5": 1,
      "1f1042fe-789f-4997-b1ac-047743c90c57": 1,
      "32e48d3d-80a3-4602-a1af-54731b48caa0": 1,
      "693c6362-7a93-4b0a-a019-aa1f0437196e": 1,
      "8e04ac67-b08a-4f88-ad39-194dd6d80f02": 1,
      "b1f8dcc5-7cd5-4d08-befa-b21873f0a524": 1,
      "c5c687c3-e3f1-4345-867e-51d2b86226fb": 1,
      "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92": 1,
      "ddd38d47-bf8c-4b3e-931d-15d8c10b260d": 1,
      "e3e1b271-fb37-413a-9cf4-e00cede94536": 1
    }
  },
  "form": {
    "sort": "relevance",
    "andFilters": [
      "76007f55-b998-4883-8e53-64c5ecec4a51"
    ],
    "includeRestricted": true,
    "includeExpired": true,
    "excludeWithinExpiredDate": "2022-11-22T05:00:00Z",
    "searchTypes": [
      "asset"
    ],
    "correlationId": "U0lEOjNhMTk3MmY3LTJhNDYtNDRhOC04OWVjLTNkNjYzMmZlZDdhY3xQSDozMjUwNTIyODA3fFJJRDoxYTFmMDQ3MC1hNzc5LTQwZDQtYjk4ZC00ZjYyOTQyOWY1MTA=",
    "page": 1,
    "significance": false,
    "query": null,
    "pageSize": 50,
    "updateSearchHistory": false
  },
  "total": 68,
  "totalPages": 2,
  "correlationId": "U0lEOjNhMTk3MmY3LTJhNDYtNDRhOC04OWVjLTNkNjYzMmZlZDdhY3xQSDowfFJJRDpjNWI4MGI4Yi03NGU5LTQ5MTAtOGI3OC1jZjk0ODk1MTI0Njc=",
  "requestFeedback": true
};
