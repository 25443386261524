import { useState } from "react";
import FlexBox from "components/layouts/FlexBox/FlexBox";
import Image from "components/layouts/Image/Image";
import TileContainer from "../TileContainer/TileContainer";
import Title from "components/typography/Title/Title";
import Body from "components/typography/Body/Body";
import CaretText from "../../CaretText/CaretText";
import { TitleProps, BodyProps } from "@vds/typography";

export interface PrimaryTileInterface {
  title: string;
  titleSize?: TitleProps["size"];
  body?: string | null;
  linkText?: string | null;
  to?: string;
  external?: boolean;
  color?: TitleProps["color"];
  bg?: string;
  pd?: string;
  children?: any;
  img?: string;
  minHeight?: string;
  className?: string;
  textAlign?: string;
  noAnimation?: boolean;
  linkSize?: BodyProps["size"];
}
const PrimaryTile = ({
  title,
  body,
  linkText,
  to,
  external,
  color,
  titleSize,
  bg,
  pd,
  img,
  minHeight,
  className,
  children,
  textAlign,
  noAnimation,
  linkSize,
}: PrimaryTileInterface) => {
  const [mouseEntered, setMouseEntered] = useState<boolean>(false);

  const onMouseEnter = () => {
    setMouseEntered(true);
  };

  const onMouseLeave = () => {
    setMouseEntered(false);
  };

  return (
    <TileContainer
      className={className}
      minHeight={minHeight}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      noAnimation={noAnimation}
      justify-content="space-between"
      to={to}
      external={external}
      bg={bg}
      pd={pd}
      textAlign={textAlign}
    >
      {children && <FlexBox pd="0 0 1rem">{children}</FlexBox>}
      <FlexBox>
        <Title
          size={titleSize ? titleSize : "large"}
          color={color ? color : "#000000"}
          pd="0 0 0.75rem"
          bold
        >
          {title}
        </Title>
        {body && <Body size="large">{body}</Body>}
      </FlexBox>
      {linkText && (
        <FlexBox row align="center">
          <CaretText
            caretPosition="right"
            hover={mouseEntered}
            size="large"
            fontSize="1"
            pd="2rem 0 0 0"
          >
            {linkText}
          </CaretText>
        </FlexBox>
      )}
      {img && <Image className="templates-img" src={img} />}
    </TileContainer>
  );
};

export default PrimaryTile;
