import React from 'react';
import PropTypes from 'prop-types';
import { calculateRem } from '@vds/utilities';
import { Checkbox as Checkbox$1 } from '@vds-core/checkboxes';
import { ColorTokens } from '@vds-tokens/color';
import { TypographyTokens } from '@vds-tokens/typography';
import { FormControlsTokens } from '@vds-tokens/form-controls';

const BORDER_RADIUS = calculateRem(2);
const FOCUS_BORDER_RADIUS = calculateRem(4);
const LETTER_SPACING = TypographyTokens.letterspacing.wide.value;
const ERROR_LEFT_MARGIN = calculateRem(-1);
const propTypes = {
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const _calculateBorderColor = function ({
  disabled,
  error,
  surface,
  hovered,
  selected
}) {
  if (disabled) {
    return surface === 'dark' ? ColorTokens.interactive.disabled.ondark.value : ColorTokens.interactive.disabled.onlight.value;
  }
  if (error) {
    return surface === 'dark' ? ColorTokens.feedback.error.ondark.value : ColorTokens.feedback.error.onlight.value;
  }
  if (hovered && !selected) {
    return surface === 'dark' ? ColorTokens.elements.secondary.ondark.value : ColorTokens.elements.secondary.onlight.value;
  }
  return surface === 'dark' ? FormControlsTokens.color.border.ondark.value : FormControlsTokens.color.border.onlight.value;
};
const _calculateCheckboxBackgroundColor = function ({
  disabled,
  selected,
  surface,
  error
}) {
  if (disabled && selected) {
    return surface === 'dark' ? ColorTokens.interactive.disabled.ondark.value : ColorTokens.interactive.disabled.onlight.value;
  }
  if (selected) {
    return surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value;
  }
  if (error && !disabled) {
    return surface === 'dark' ? ColorTokens.feedback.error.background.ondark.value : ColorTokens.feedback.error.background.onlight.value;
  }
  return 'transparent';
};
const _calculateAdditionalBorderColor = function (surface, error, selected) {
  if (error) {
    return surface === 'dark' ? ColorTokens.feedback.error.ondark.value : ColorTokens.feedback.error.onlight.value;
  }
  if (selected) {
    return surface === 'dark' ? FormControlsTokens.color.border.hover.ondark.value : FormControlsTokens.color.border.hover.onlight.value;
  }
  return surface === 'dark' ? ColorTokens.elements.secondary.ondark.value : ColorTokens.elements.secondary.onlight.value;
};
const _calculateBorderWidth = function ({
  selected
}) {
  return selected ? 0 : calculateRem(FormControlsTokens.border.width.value);
};
const _calculateBoxShadow = function ({
  hovered,
  disabled,
  surface,
  error,
  selected
}) {
  return hovered && !disabled ? ` 0 0 0 ${calculateRem(1)} ${_calculateAdditionalBorderColor(surface, error, selected)}` : `none`;
};
const Checkbox = function (props) {
  return React.createElement(Checkbox$1, Object.assign({}, props, {
    letterSpacing: LETTER_SPACING,
    borderRadius: BORDER_RADIUS,
    focusBorderRadius: FOCUS_BORDER_RADIUS,
    errorMarginLeft: ERROR_LEFT_MARGIN,
    calculateBorderWidth: _calculateBorderWidth,
    calculateBoxShadow: _calculateBoxShadow,
    calculateBorderColor: _calculateBorderColor,
    calculateCheckboxBackgroundColor: _calculateCheckboxBackgroundColor
  }));
};
Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export { Checkbox as default };
