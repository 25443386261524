import React, { forwardRef } from "react";
import { StyledLayoutContainer, StyledSection } from "./Section.module";

export type SectionProps = {
  ref?: any;
  className?: string;
  children: React.ReactNode;
  pd?: string;
  mg?: string;
  minHeight?: string;
  maxHeight?: string;
  color?: string;
  background?: string;
  overflow?: string;
  id?: string;
  testId?: string;
};

const Section = forwardRef(({ children, testId, mg, ...rest }: SectionProps, ref) => {
  return (
    <StyledSection mg={mg} data-testid={testId} ref={ref} {...rest}>
      <StyledLayoutContainer>{children}</StyledLayoutContainer>
    </StyledSection>
  );
});

export default Section;
