import React, { Fragment } from 'react';
import styled from 'styled-components';
import { calculateRem } from '@vds-core/utilities';
import { LayoutTokens } from '@vds-tokens/layout';
import '../Controls/PaginationStepControl.js';
import PaginationListItem from '../Controls/PaginationListItem.js';
import PaginationLink from '../Controls/PaginationLink.js';
import sharedDefaultProps from '../utilities/sharedDefaultProps.js';
import sharedPropTypes from '../utilities/sharedPropTypes.js';

const heightPaginationNumber = '16px';
const minWidthPaginationNumber = '20px';
const PaginationNumbersContainer = styled(PaginationListItem).withConfig({
  displayName: "PaginationNumbersContainer",
  componentId: "VDS__sc-edp5oz-0"
})`
  display: flex;
  height: ${calculateRem(heightPaginationNumber)};
  justify-content: center;
  min-width: ${calculateRem(minWidthPaginationNumber)};

  ${({
  index,
  startRange
}) => index !== startRange && `margin-left: ${calculateRem(LayoutTokens.space['1X'].value)};`}
`;
const StyledSpan = styled.span.withConfig({
  displayName: "StyledSpan",
  componentId: "VDS__sc-edp5oz-1"
})`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  svg {
    margin-top: -1px;
  }
`;
/**
 * @ignore
 */
const PaginationNumbers = props => {
  const {
    total,
    selectedPage,
    baseUrl,
    selectPage,
    fixedWidth,
    surface,
    calculateRange,
    focusBorderRadius
  } = props;
  const {
    startRange,
    endRange
  } = calculateRange(selectedPage, total);
  function _selected(page) {
    selectPage(page);
  }
  return React.createElement(Fragment, null, Array.from({
    length: total + 1
  }, (v, i) => i).map((item, index) => {
    const isInRange = index >= startRange && index <= endRange && index !== 0;
    return isInRange && React.createElement(PaginationNumbersContainer, {
      fixedWidth: fixedWidth,
      key: index,
      startRange: startRange,
      index: index
    }, React.createElement(PaginationLink, {
      pageNumber: index,
      selectedPage: selectedPage,
      baseUrl: baseUrl,
      onClick: _selected,
      surface: surface,
      focusBorderRadius: focusBorderRadius,
      total: total
    }, React.createElement(StyledSpan, null, index)));
  }));
};
PaginationNumbers.propTypes = sharedPropTypes;
PaginationNumbers.defaultProps = sharedDefaultProps;

export { PaginationNumbers as default };
