import React from 'react';
import PropTypes from 'prop-types';
import { Notification as Notification$1 } from '@vds-core/notifications';
import { ButtonGroup } from '@vds/buttons';

const propTypes = {
  /**
   * Renders the provided title, not to be used with children.
   */
  title: PropTypes.string,
  /**
   * Renders the provided subtitle, not to be used with children.
   */
  subtitle: PropTypes.string,
  /**
   * Accepts an array of objects that allows buttons to be added to the Notification.
   */
  buttonData: PropTypes.arrayOf(PropTypes.shape({
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    onClick: PropTypes.func,
    href: PropTypes.string
  })),
  /**
   * If true, will add 20px margins to the left and right sides of the Notification.
   * This is meant to mimick the margins used on the grid.
   */
  inline: PropTypes.bool,
  /**
   * Determines the orientation of buttons and text in the Notification.
   * @note This prop is only available for desktop viewports.
   */
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
  * Option to render on dark or light surface
  */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  fullBleed: false,
  inline: false,
  surface: 'light'
};
const Notification = function (props) {
  const {
    fullBleed,
    title,
    subtitle,
    buttonData
  } = props;
  return React.createElement(Notification$1, Object.assign({}, props, {
    fullBleed: fullBleed,
    title: title,
    subtitle: subtitle,
    buttonData: buttonData,
    ButtonGroup: ButtonGroup
  }));
};
Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export { Notification as default };
