import { Body, FlexBox, IconButton } from "components";
import { Icon } from "@vds/icons";
import { ProgressBar } from "./DragAndDrop.module";
import { AxiosProgressEvent } from "axios";
import { CircularProgress } from "@mui/material";

export interface UploadedFileProps {
  file: File;
  progressEvent: AxiosProgressEvent;
  onDelete?: Function;
  uploading: boolean;
  deleting: boolean;
}

const UploadedFile = ({
  file,
  progressEvent,
  uploading,
  deleting,
  onDelete,
}: UploadedFileProps) => {
  const loaded =
    progressEvent?.loaded < file.size ? progressEvent.loaded : file.size;

  const handleDelete = () => {
    onDelete && onDelete(file);
  };
  return (
    <FlexBox key={`${file.name}`} row gap="0.5rem">
      <Icon name="single-document" size="medium" />
      <FlexBox flex="1" justify={"space-between"}>
        <Body testId={`file-name-${file.size}`} bold>{file.name}</Body>
        {progressEvent && progressEvent.progress !== 1 ? (
          <FlexBox gap="0.25rem">
            <ProgressBar percentage={loaded / file.size} />
            <Body>
              {`${(loaded / 1024 ** 2).toFixed(2)}MB / ${(
                file.size /
                1024 ** 2
              ).toFixed(2)}
              MB (${Math.ceil((loaded / file.size) * 100)}% complete)`}
            </Body>
          </FlexBox>
        ) : (
          <Body pd="0.25rem 0 0">{`${(file.size / 1024 ** 2).toFixed(
            2
          )}MB`}</Body>
        )}
      </FlexBox>
      {!progressEvent || progressEvent?.progress === 1 ? (
        uploading ? (
          <CircularProgress size={23} />
        ) : !!onDelete ? (
          <IconButton
            name="trash"
            size="medium"
            ariaLabel="delete file"
            onClick={handleDelete}
            disabled={deleting}
            testId={`delete-file-${file.size}`}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </FlexBox>
  );
};

export default UploadedFile;
