import styled from "styled-components";
import {mobile, minTablet, mobileLarge, zIndex, minDesktop} from "theme/variables";

interface AssetCardProps {
  searchType?: string;
}

export const StyledAssetCard = styled("div")<AssetCardProps>`
  position: relative;
  /* min-height: 275px; */
  width: 100%;
  /* height: 100%; */
  height: calc(16rem + 4em);
  &.is-mobile {
    height: fit-content;
  }
  .full-wrap {
    height: fit-content;
    position: absolute;
    &.is-mobile {
      position: relative;
    }
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: block;
    border: 0.0625rem solid transparent;
    background-color: #ffffff;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition: border-color ease-in 50ms, box-shadow ease-in 50ms,
      padding ease-in 50ms;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 8px;

    @media screen and (min-width: ${minTablet}px) {
      &.focused {
        height: fit-content;
        border: 0.0625rem solid #747676;
        box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.1);
        z-index: 100;
        transition: border-color ease-in 550ms, box-shadow ease-out 250ms;
      }
    }

  }

  .asset-card-utilities {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: calc(100% - (1rem * 2));
    z-index: 5;
    padding: 0.5rem;
    box-sizing: border-box;
    transition: border-color 250ms;
  }

  .assetcard__wrap {
    position: relative;
    height: fit-content;
    padding: 1rem;
    &.is-mobile {
      padding: 1rem 0;
    }
    box-sizing: border-box;
    border-radius: 8px;
    > a {
      padding: 0;
    }
  }

  .asset-card-expand {
    @media (min-width: ${minTablet}px) {
      opacity: 0;
    }
  }

  .asset-card-expand {
    &.focused {
      @media (min-width: ${minTablet}px) {
        opacity: 1;
      }
    }
  }

  .asset-card-select {
    opacity: 0;
    &.is-mobile {
      opacity: 1;
      top: 1.5rem;
      left: 0.5rem;
    }
    transition: opacity 250ms;
    width: 20px;
    background-color: #ffffff;
    border-radius: 4px;
    &.focused {
      opacity: 1;
    }
    &.selected {
      background-color: transparent;
    }

    .checkbox {
      margin-right: 0;
    }

    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    z-index: ${zIndex.notBottom};

    label span p {
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
      overflow: hidden;
      position: absolute;

      &.focusable:active,
      &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
      }
    }
  }

  .asset-card-indicators {
    display: flex;

    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    z-index: ${zIndex.notBottom};
  }

  .asset-card-indicator {
    height: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 4px;

    & + .asset-card-indicator {
      margin-left: 0.25rem;
    }

    span {
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
      overflow: hidden;
      position: absolute;

      &.focusable:active,
      &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
      }
    }
    &.asset-card-indicator-text {
      padding: 0.125rem 0.25rem;
      min-width: unset;
      font-size: 1rem;
      line-height: 1;
      //background-color: transparent;
    }
  }

  .media {
    overflow: hidden;
    outline: #f6f6f6 0.0625rem solid;
    background-color: ${(props) => (props.searchType === "guideline" ? "#000000" : "#d8dada")};
    height: 13.75rem;
    position: relative;
    border-radius: 8px;

    .media__image {
      width: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      vertical-align: middle;
    }

    .media__link {
      text-decoration: none;
      color: #000000;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      .compliance__title {
        @media screen and (max-width: ${minTablet - 1}px) {
          padding: 2rem 0.5rem 1rem;
        }
        @media screen and (min-width: ${minTablet}px) {
          padding: 1.5rem 0.5rem 1rem;
        }
        h5 {
          @media screen and (min-width: ${minDesktop}px) {
            font-size: 1rem;
            line-height: 1.25rem;
          }
          @media screen and (min-width: 1272px) {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }
      }
      
      .guideline__title {
        
        @media screen and (max-width: ${minTablet - 1}px) {
          padding: 2rem 0.5rem 1rem;
        }
        @media screen and (min-width: ${minTablet}px) {
          padding: 1.5rem 0.5rem 1rem;
        }
        h5 {
          color: #ffffff;
          @media screen and (min-width: ${minDesktop}px) {
            font-size: 1rem;
            line-height: 1.25rem;
          }
          @media screen and (min-width: 1272px) {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }
      }

      span {
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        overflow: hidden;
        position: absolute;

        &.focusable:active,
        &.focusable:focus {
          clip: auto;
          height: auto;
          margin: 0;
          overflow: visible;
          position: static;
          width: auto;
        }
      }
    }
  }
  
  .assetcard__body {
    padding-top: 0.75rem;
  }

  .assetcard__title {
    word-break: break-word;
    p {
      line-height: 1.25rem;
    }

    a {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }
    &:not(.focused) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .assetcard__footer {
    opacity: 0;
    &.is-mobile {
      opacity: 1;
    }

    ul {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;

      @media ${mobile} {
        li.assetcard__listitem {
          button {
            padding: 0.25rem;
            svg {
              width: 0.825rem;
              height: 0.825rem;
              min-width: 0.825rem;
              min-height: 0.825rem;
            }
          }
        }
      }

      @media ${mobileLarge} {
        li.assetcard__listitem {
          button {
            padding: 0.25rem;
            svg {
              width: 1rem;
              height: 1rem;
              min-width: 1rem;
              min-height: 1rem;
            }
          }
        }
      }

      @media screen and (min-width: ${minTablet}px) {
        li.assetcard__listitem {
          button {
            padding: 0.25rem;
            svg {
              width: 1.25rem;
              height: 1.25rem;
              min-width: 1.25rem;
              min-height: 1.25rem;
            }
          }
        }
      }
      li {
        &.mobile-select {
          display: none;
          @media ${mobile} {
            display: block;
          }
          width: 20px;

          label {
            padding: 0;
          }

          label span p {
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            margin: -1px;
            padding: 0;
            border: 0;
            overflow: hidden;
            position: absolute;

            &.focusable:active,
            &.focusable:focus {
              clip: auto;
              height: auto;
              margin: 0;
              overflow: visible;
              position: static;
              width: auto;
            }
          }
        }

        @media (min-width: ${minTablet}px) {
          + li {
            margin-left: 0.25rem;
          }

          &.mobile-select + li {
            margin-left: 0;
          }
        }
      }
    }

    .expiration-date p {
      @media ${mobile} {
        margin-top: 8px;      }
      margin-top: 16px;
      line-height: 1.2;
      color: #747676;
      @media ${mobileLarge} {
        margin-top: 8px;
      }
      @media screen and (min-width: ${minTablet}px) {
        line-height: 2;
        margin-top: 0;
      }
    }

    @media screen and (min-width: ${minTablet}px) {
      /* visibility: hidden; */
      margin-top: 1rem;
      transform: translateY(0);
      &.focused {
        height: auto;
        opacity: 1;
        display: flex;
        /* visibility: visible; */
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
      }

      &.small {
        ul {
          li.assetcard__listitem {
            button {
              padding: 0 0.25rem;
              svg {
                width: 0.825rem;
                height: 0.825rem;
                min-width: 0.825rem;
                min-height: 0.825rem;
              }
            }
            & + li {
              margin-left: 0rem;
            }
          }
        }
      }
    }
  }

  /* fullscreen */
  .uimodal-title {
    font-size: 0;
    line-height: 0;
    color: transparent;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
  }

  /* fullscreen utility buttons */
  [class|="thumbnail-utility-button"] {
    position: absolute;
    top: 1rem;
    padding: 0.313rem 0.453rem;
    background-color: #ffffff;
    z-index: 20;
  }

  .thumbnail-utility-button-open {
    padding: 0.313rem 0.453rem;
    background-color: #ffffff;
    z-index: 20;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    top: auto;
    border-radius: 2px;
  }

  .thumbnail-utility-button-close {
    padding: 0.313rem 0.453rem;
    background-color: #ffffff;
    z-index: 20;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    box-shadow: 0 0 3px rgb(0 0 0 / 11%);
  }

  .thumbnail-expired-indicator {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 20;
    padding: 0 0.75rem;
    background-color: #ffffff;
  }

  .thumbnail-list-wrap.fullscreen & {
    .thumbnail-expired-indicator {
      display: none;
    }
  }
`;

export const ImageWrapper = styled.div`
  max-height: 3rem;
  overflow: hidden;
`;
