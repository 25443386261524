import { AssetFile } from "interfaces";
import { AssetFileVersion } from "interfaces/Asset";

export function getFileCurrentVersion(
  file: AssetFile
): AssetFileVersion | null {
  if (!file.currentVersionId) {
    return null;
  }

  return (
    file.versions?.find((version) => version.id === file.currentVersionId) ||
    null
  );
}

export function getFileName(file: AssetFile): string {
  const currentVersion = getFileCurrentVersion(file);

  if (!currentVersion) {
    return "";
  }

  return currentVersion.fileName || "";
}

export function formatFileSize(filesize: number | undefined): string {
  if (!filesize || filesize === undefined) {
    return "";
  }

  if (filesize >= 1024000) {
    return `${(filesize / 1024000).toFixed(0)} MB`;
  }

  if (filesize >= 1024 && filesize < 1024000) {
    return `${(filesize / 1024).toFixed(0)} KB`;
  }

  if (filesize < 1024) {
    return `${filesize} B`;
  }

  return `${filesize} B`;
}

export function formatAllFileSize(files: AssetFile[]): string {
  let totalSize = 0;
  for (const file of files) {
    if (getFileCurrentVersion(file)) {
      totalSize += getFileCurrentVersion(file)?.sizeInBytes || 0;
    }
  }
  return formatFileSize(totalSize);
}

export async function openFileDownload(fileUrl: string): Promise<void> {
  const a = document.createElement("a");
  a.href = fileUrl;
  a.download = "download";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
