import {
  CheckboxGroupProps as VdsCheckboxGroupProps,
  CheckboxGroup as VdsCheckboxGroup,
} from "@vds/checkboxes";
import { StyledCheckboxGrid } from "./CheckboxGroup.module";

export type CheckboxGroupProps = {
  grid?: boolean;
  className?: string;
  maxWidth?: string;
  minWidth?: string;
  width?: string;
  cols?: { xs?: number; sm?: number; md?: number; lg?: number; xl?: number };
  colGutter?: string;
  rowGutter?: string;
  rowHeight?: string;
  align?: "flex-start" | "center" | "flex-end";
  pd?: string;
};

const CheckboxGroup = ({
  grid,
  className,
  maxWidth,
  minWidth,
  width,
  cols,
  colGutter,
  rowGutter,
  rowHeight,
  align,
  pd,
  data,
  ...rest
}: VdsCheckboxGroupProps & CheckboxGroupProps) => {
  return data ? (
    grid ? (
      <StyledCheckboxGrid
        grid={grid}
        className={className}
        maxWidth={maxWidth}
        minWidth={minWidth}
        width={width}
        cols={cols}
        colGutter={colGutter}
        rowGutter={rowGutter}
        rowHeight={rowHeight}
        align={align}
        pd={pd}
      >
        <VdsCheckboxGroup data={data} {...rest} />
      </StyledCheckboxGrid>
    ) : (
      <VdsCheckboxGroup data={data} {...rest} />
    )
  ) : (
    <></>
  );
};

export default CheckboxGroup;
