import { Modal } from "components";
import { StyledTabsContainer } from "./MultipleSelectedModal.module";
import {useEffect, useState} from "react";
import {UnifiedSearchResultProps} from "../../interfaces";

import MultipleSelectedTab from "../multipleSelectedTab/MultipleSelectedTab";
import Tabs from "../../components/layouts/Tabs/Tabs";

interface TabProps {
  label: string,
  content: any
}

export interface MultipleSelectedModalProps {
  selectedItems: Array<UnifiedSearchResultProps>;
  onClose: Function;
  clearSelected: Function;
  removeAsset: Function;
  updateUsageRights: Function;
  clearBySearchType: Function;
}

const MultipleSelectedModal = ({
  selectedItems,
  onClose,
  clearSelected,
  removeAsset,
  updateUsageRights,
  clearBySearchType
}: MultipleSelectedModalProps) => {
  
  const [selectedTab, _selectedTab] = useState<number>(0);
  
  const [isLoading, _isLoading] = useState<boolean>(false);
  
  const [tabs, _tabs] = useState<Array<TabProps>>([]);
  
  const handleSelectTab = (index: number) => {
    _selectedTab(index);
  };
  
  const handleRemoveAsset = (id: string) => {
    const itemToRemove = selectedItems.find((item) => item.id === id);
    if (itemToRemove) {
      const tempTabs = getTabs();
      
      if (selectedItems.length <= 1) {
        clearSelected();
        onClose();
      } else if (selectedItems.length > 1 && tempTabs.length <= 1) {
        removeAsset(id);
      } else if (selectedItems.length > 1 && tempTabs.length > 1) {
        const lastTab = tempTabs.find((tab, index) => (tab.label.toLowerCase().includes(itemToRemove.searchType)) && (index === tempTabs.length - 1));
        if (lastTab && selectedItems.filter((item) => item.searchType === itemToRemove.searchType).length <= 1) {
          _tabs([]);
          removeAsset(id);
        } else {
          removeAsset(id);
        }
      } else {
        removeAsset(id);
      }
    }
    
    
    
  };
  
  const handleClearBySearchType = (searchType: string) => {
    // if there is only one tab, close modal and clear all selections. if there is more than one tab, but the selected tab is the final one, set the tabs to an empty array before clearing the selections. This prevents errors when VDS components tries to read changes to a tab that no longer exists, and will automatically set the selected tab to the first tab, once it has new selected items and can set a new tabs state based on that. Otherwise, just clear the selections in that tab, which will jump the user to the next available tab.
    const tempTabs = getTabs();
    if (tempTabs.length === 1) {
      clearSelected() && onClose();
    } else if (tempTabs.length > 1) {
      const lastTab = tempTabs.find((tab, index) => (tab.label.toLowerCase().includes(searchType)) && (index === tempTabs.length - 1));
      if (lastTab) {
        _tabs([]);
        clearBySearchType(searchType);
      } else {
        clearBySearchType(searchType);
      }
    }
    
  }
  
  const handleUpdateUsageRights = (id: string) => {
    updateUsageRights(id);
  };
  
  const getTabs = (): Array<TabProps> => {
    const selectedAssets = selectedItems.filter((selectedItem) => selectedItem.searchType === "asset");
    const selectedGuidelines = selectedItems.filter((selectedItem) => selectedItem.searchType === "guideline");
    const selectedCompliance = selectedItems.filter((selectedItem) => selectedItem.searchType === "compliance");
    const selectedTraining = selectedItems.filter((selectedItem) => selectedItem.searchType === "training");
    const selectedRetail = selectedItems.filter((selectedItem) => selectedItem.searchType === "retail");
    const selectedTemplates = selectedItems.filter((selectedItem) => selectedItem.searchType === "template");
    let tempTabs: Array<{label: string, content: any}> = [];
    if (selectedItems.some((selectedItem) => selectedItem.searchType === "asset")) {
      tempTabs.push({label: `Assets${selectedAssets.length > 0 ? ` (${selectedAssets.length})` : ""}`,
        content: (
          <MultipleSelectedTab
            key="asset"
            searchType="asset"
            selectedItems={selectedAssets}
            removeAsset={handleRemoveAsset}
            clearBySearchType={(searchType: string) => handleClearBySearchType(searchType)}
            updateUsageRights={handleUpdateUsageRights}
            setIsLoading={(loading: boolean) => _isLoading(loading)}
          />
        )})
    } if (selectedItems.some((selectedItem) => selectedItem.searchType === "guideline")) {
      tempTabs.push({label: `Guidelines${selectedGuidelines.length > 0 ? ` (${selectedGuidelines.length})` : ""}`,
        content: (
          <MultipleSelectedTab
            key="guideline"
            searchType="guideline"
            selectedItems={selectedGuidelines}
            removeAsset={handleRemoveAsset}
            clearBySearchType={(searchType: string) => handleClearBySearchType(searchType)}
            updateUsageRights={handleUpdateUsageRights}
            setIsLoading={(loading: boolean) => _isLoading(loading)}
          />
        )})
    } if (selectedItems.some((selectedItem) => selectedItem.searchType === "template")) {
      tempTabs.push({label: `Templates${selectedTemplates.length > 0 ? ` (${selectedTemplates.length})` : ""}`,
        content: (
          <MultipleSelectedTab
            key="template"
            searchType="template"
            selectedItems={selectedTemplates}
            removeAsset={handleRemoveAsset}
            clearBySearchType={(searchType: string) => handleClearBySearchType(searchType)}
            updateUsageRights={handleUpdateUsageRights}
            setIsLoading={(loading: boolean) => _isLoading(loading)}
          />
        )})
    } if (selectedItems.some((selectedItem) => selectedItem.searchType === "compliance")) {
      tempTabs.push({label: `Compliance${selectedCompliance.length > 0 ? ` (${selectedCompliance.length})` : ""}`,
        content: (
          <MultipleSelectedTab
            key="compliance"
            searchType="compliance"
            selectedItems={selectedCompliance}
            removeAsset={handleRemoveAsset}
            clearBySearchType={(searchType: string) => handleClearBySearchType(searchType)}
            updateUsageRights={handleUpdateUsageRights}
            setIsLoading={(loading: boolean) => _isLoading(loading)}
          />
        )})
    } if (selectedItems.some((selectedItem) => selectedItem.searchType === "retail")) {
      tempTabs.push({label: `Retail${selectedRetail.length > 0 ? ` (${selectedRetail.length})` : ""}`,
        content: (
          <MultipleSelectedTab
            key="retail"
            searchType="retail"
            selectedItems={selectedRetail}
            clearBySearchType={(searchType: string) => handleClearBySearchType(searchType)}
            removeAsset={handleRemoveAsset}
            updateUsageRights={handleUpdateUsageRights}
            setIsLoading={(loading: boolean) => _isLoading(loading)}
          />
        )})
    } if (selectedItems.some((selectedItem) => selectedItem.searchType === "training")) {
      tempTabs.push({label: `Training${selectedTraining.length > 0 ? ` (${selectedTraining.length})` : ""}`,
        content: (
          <MultipleSelectedTab
            key="training"
            searchType="training"
            selectedItems={selectedTraining}
            clearBySearchType={(searchType: string) => handleClearBySearchType(searchType)}
            removeAsset={handleRemoveAsset}
            updateUsageRights={handleUpdateUsageRights}
            setIsLoading={(loading: boolean) => _isLoading(loading)}
          />
        )})
    }
    return tempTabs;
  };
  const setTabs = (newTabs: Array<TabProps>) => {
    _tabs(newTabs.map((tab) => tab));
  };
  
  useEffect(() => {
    if (selectedItems.length > 0) {
      const tempTabs = getTabs();
      setTabs(tempTabs);
    }
  }, [selectedItems]);
  

  return (
    <>
      <Modal
        open
        onClose={onClose}
        disabled={isLoading}
        testId="multiple-selected-modal"
        height="80vh"
      >
        {tabs.length > 0 && (
          <StyledTabsContainer>
            <Tabs
              noBorder
              onSelectTab={(index) => handleSelectTab(index)}
              orientation="horizontal"
              tabs={tabs}
              // disabled={disabled}
            />
          </StyledTabsContainer>
        )}
        
      </Modal>
      
    </>
  );
};

export default MultipleSelectedModal;
