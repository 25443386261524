export const AccessibilityTokens = {
  "focusring": {
    "borderstyle": {
      "value": "dashed",
      "filePath": "packages/Accessibility/src/index.js",
      "isSource": true,
      "original": {
        "value": "dashed"
      },
      "name": "FocusringBorderstyle",
      "attributes": {
        "category": "focusring",
        "type": "borderstyle"
      },
      "path": [
        "focusring",
        "borderstyle"
      ]
    },
    "borderwidth": {
      "value": "1px",
      "attributes": {
        "category": "size",
        "type": "borderwidth"
      },
      "filePath": "packages/Accessibility/src/index.js",
      "isSource": true,
      "original": {
        "value": "1px",
        "attributes": {
          "category": "size"
        }
      },
      "name": "FocusringBorderwidth",
      "path": [
        "focusring",
        "borderwidth"
      ]
    },
    "space": {
      "offset": {
        "value": "2px",
        "attributes": {
          "category": "size",
          "type": "space",
          "item": "offset"
        },
        "filePath": "packages/Accessibility/src/index.js",
        "isSource": true,
        "original": {
          "value": "2px",
          "attributes": {
            "category": "size"
          }
        },
        "name": "FocusringSpaceOffset",
        "path": [
          "focusring",
          "space",
          "offset"
        ]
      }
    }
  },
  "color": {
    "focusring": {
      "onlight": {
        "value": "#000000",
        "attributes": {
          "category": "color",
          "type": "focusring",
          "item": "onlight"
        },
        "filePath": "packages/Accessibility/src/index.js",
        "isSource": true,
        "original": {
          "value": "#000000",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ColorFocusringOnlight",
        "path": [
          "color",
          "focusring",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#ffffff",
        "attributes": {
          "category": "color",
          "type": "focusring",
          "item": "ondark"
        },
        "filePath": "packages/Accessibility/src/index.js",
        "isSource": true,
        "original": {
          "value": "#ffffff",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ColorFocusringOndark",
        "path": [
          "color",
          "focusring",
          "ondark"
        ]
      }
    }
  }
}