import {FlexBox, Title, MdToHtml} from "components";
import { ComplianceOverviewPageProps} from "../../../interfaces";
import { StyledContainer } from "./DoDontBlock.module";

export type DoDontBlockProps = {
  doContent: string;
  dontContent: string;
  doTitle: string;
  dontTitle: string;
  pd?: string;
  mg?: string;
  mobilePd?: string;
  mobileMg?: string;
  maxWidth?: string;
}

const DoDontBlock = ({ doContent, dontContent, doTitle, dontTitle, ...rest }: DoDontBlockProps ) => {
  return (
    <>
      <StyledContainer dontContent={dontContent} doContent={doContent} doTitle={doTitle} dontTitle={dontTitle} {...rest}>
        <FlexBox minWidth="50%">
          <Title size="small" bold color="#008330">{doTitle}</Title>
          <MdToHtml content={doContent} pMargin="0 0 0 0" pSize="2rem"/>
        </FlexBox>
        <FlexBox minWidth="50%">
          <Title size="small" bold color="#ee0000">{dontTitle}</Title>
          <MdToHtml content={dontContent} />
        </FlexBox>
      </StyledContainer>
    </>
  )
};

export default DoDontBlock;