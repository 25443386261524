import React from 'react';
import 'prop-types';
import ViewportManager from './ViewportManager.js';
import isStyledComponent from '../../utils/isStyledComponent.js';
import ErrorBoundary from './ErrorBoundary.js';

// import -rc from './injectStyles';
const CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const wrapWithManager = (Component, expanded = false) => {
  const componentName = Component ? Component.displayName || Component.name : 'Component';
  const isStatelessFunctionalComponent = typeof Component === 'function' && !(Component.prototype && 'isReactComponent' in Component.prototype);
  // NOTE: We can't pass a ref to a stateless functional component
  const shouldSetInnerRef = isStyledComponent(Component) || isStatelessFunctionalComponent;
  class WithManager extends React.Component {
    constructor(props) {
      super(props);
      this.viewportManager = ViewportManager; // will return viewport: { normalViewport, expandedViewport } format
      this.state = {
        viewport: undefined
      };
      this.id = this._makeid(10);
    }
    _makeid(length) {
      var result = '';
      for (var i = 0; i < length; i++) {
        let randomNum = Math.random();
        let flooredNum = Math.floor(randomNum * 10);
        result += CHARACTERS.charAt(flooredNum);
      }
      return result;
    }
    componentDidMount() {
      // For intial viewport
      this.setState({
        viewport: expanded ? this.viewportManager.viewport.expandedViewport : this.viewportManager.viewport.normalViewport
      });
      this.viewportManager.subscribe(this.id, expanded, vp => {
        this.setState({
          viewport: vp
        });
      });
    }
    componentWillUnmount() {
      this.viewportManager.unsubscribe(this.id);
    }
    render() {
      const props = Object.assign({
        viewport: this.state.viewport
      }, this.props);
      if (!shouldSetInnerRef) {
        props.ref = props.innerRef;
        delete props.innerRef;
      }
      return React.createElement(ErrorBoundary, null, React.createElement(Component, Object.assign({}, props)));
    }
  }
  WithManager.displayName = `WithManager(${componentName})`;
  return WithManager;
};

export { wrapWithManager as default };
