import React from 'react';
import PropTypes from 'prop-types';
import { Tab as Tab$1 } from '@vds-core/tabs';
import { Title, Body } from '@vds/typography';
import { ColorTokens } from '@vds-tokens/color';

const propTypes = {
  /**
   * @ignore
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const _renderLabel = function ({
  label,
  surface,
  selected,
  hovered,
  size
}) {
  const backgroundMode = surface === 'dark' ? 'ondark' : 'onlight';
  const color = selected ? ColorTokens.elements.primary[backgroundMode].value : !selected && hovered ? ColorTokens.elements.primary[backgroundMode].value : ColorTokens.elements.secondary[backgroundMode].value;
  if (size === 'large') {
    return React.createElement(Title, {
      viewport: "desktop",
      size: "small",
      primitive: "span",
      bold: true,
      color: color
    }, label);
  } else {
    return React.createElement(Body, {
      viewport: "desktop",
      size: "large",
      primitive: "span",
      bold: true,
      color: color
    }, label);
  }
};
const _calculateIndicatorColor = function (surface) {
  return {
    selectedColor: surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.palette.red.value,
    hoverColor: 'transparent'
  };
};
const Tab = function (props) {
  return React.createElement(Tab$1, Object.assign({}, props, {
    calculateIndicatorColor: _calculateIndicatorColor,
    renderLabel: _renderLabel,
    focusRingRadius: "2px"
  }));
};
Tab.propTypes = propTypes;
Tab.defaultProps = defaultProps;

export { Tab as default };
