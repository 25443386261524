import { compose } from "@reduxjs/toolkit";
import { AuthProps } from "HOCs";
import { withAuth } from "HOCs/withAuth";
import { NavBar } from "components";
import { LoaderNavBar } from "components/layouts/NavBar/NavBar.module";
import { useDispatch } from "hooks/redux";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { currentUserJwt } from "services/auth/auth.slice";
import PageNotFound from "views/PageNotFound/PageNotFound";

const Protected404 = () => {
  const dispatch = useDispatch();
  const handleUserDetails = () => {
    dispatch(currentUserJwt());
  };

  useEffect(() => {
    handleUserDetails();
  }, []);
  return (
    <>
      <NavBar />
      <PageNotFound />
    </>
  );
};

const Error404 = ({ auth }: AuthProps) => {
  document.title = "404 | Brand Central";
  const error = useRouteError();
  console.error(error);

  return auth ? (
    <Protected404 />
  ) : (
    <>
      <LoaderNavBar />
      <PageNotFound />
    </>
  );
};

export default compose(withAuth())(Error404);
