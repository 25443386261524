import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TabButton from "./TabButton.js";
import { Title, Body } from "@vds-core/typography";
import UNSAFE_SetEnvRef from "@uie/set-env-ref";
import ReactDOM from "react-dom";
import {
  calculateRem,
  withVDSManager,
  checkIfMobileDevice,
} from "@vds-core/utilities";
import { ColorTokens } from "@vds-tokens/color";
import { LayoutTokens } from "@vds-tokens/layout";
import { AccessibilityTokens } from "@vds-tokens/accessibility";

const SPACE2 = LayoutTokens.space["2X"].value; // 8px
const SPACE4 = LayoutTokens.space["4X"].value; // 16px
const _renderLabel = ({ label, surface, selected, hovered, size }) => {
  const backgroundMode = surface === "dark" ? "ondark" : "onlight";
  const color = selected
    ? ColorTokens.elements.primary[backgroundMode].value
    : !selected && hovered
    ? ColorTokens.elements.primary[backgroundMode].value
    : ColorTokens.elements.secondary[backgroundMode].value;
  if (size === "large") {
    return React.createElement(
      Title,
      {
        viewport: "desktop",
        size: "small",
        primitive: "span",
        bold: true,
        color: color,
      },
      label
    );
  } else {
    return React.createElement(
      Body,
      {
        viewport: "desktop",
        size: "large",
        primitive: "span",
        bold: true,
        color: color,
      },
      label
    );
  }
};
const _calculateIndicatorColor = (surface) => {
  return {
    selectedColor:
      surface === "dark"
        ? ColorTokens.elements.primary.ondark.value
        : ColorTokens.palette.red.value,
    hoverColor: "transparent",
  };
};
const propTypes = {
  /**
   * The text label of the tab.
   */
  label: PropTypes.string.isRequired,
  /**
   * An optional callback that is called when this Tab is clicked. Passes parameters (event, tabIndex).
   */
  onClick: PropTypes.func,
  /**
   * If provided, it will set this Tab to the Active Tab on render.
   * @note This will override whatever was set on the Tab selectedIndex prop. Also, it is "Last One Wins", ie: if this is set on multiple Tab elements, the last element with it set will be the Active Tab.
   */
  selected: PropTypes.bool,
  /**
   * If true, the Tab element will not be visible and removed from the DOM
   */
  hide: PropTypes.oneOf([true, false, 0, 1, 2]),
  /**
   * Minimum Width of this Tab when orientation is horizontal.
   */
  minWidth: PropTypes.string,
  /**
   * If provided, it will set fixed width for this Tab when orientation is horizontal.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * @ignore
   */
  indicatorFillTab: PropTypes.bool,
  /**
   * @ignore
   */
  indicatorPosition: PropTypes.oneOf(["bottom", "top"]),
  /**
   * If provided, this will be used instead of the random uniqueId generated by the Tabs Component that is used to associate the Tab li and button with the TabPanel div components.
   */
  uniqueId: PropTypes.string,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * @ignore
   */
  handleVisibilityChange: PropTypes.func,
  /**
   * @ignore
   */
  onVisibilityChange: PropTypes.func,
  /**
   * @ignore
   */
  size: PropTypes.oneOf(["medium", "large"]),
  /**
   * @ignore
   */
  renderLabel: PropTypes.func,
  /**
   * @ignore
   */
  calculateIndicatorColor: PropTypes.func,
  /**
   * @ignore
   */
  focusRingRadius: PropTypes.string,
  /**
   * @ignore
   */
  Title: PropTypes.func,
  /**
   * @ignore
   */
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  /**
   * @ignore
   */
  surface: PropTypes.oneOf(["light", "dark"]),
  /**
   * @ignore
   */
  overflow: PropTypes.oneOf(["scroll", "none"]),
  /**
   * @ignore
   */
  fillContainer: PropTypes.bool,
  /**
   * Allows a string to be provided for analytics.
   */
  "data-analyticstrack": PropTypes.string,
  /**
   * Allows a string to be provided for analytics.
   */
  "data-track": PropTypes.string,
  /**
   * Allows a string to be provided for ignoring analytics tagging.
   */
  "data-track-ignore": PropTypes.string,
  /**
   * Allows a string to be provided for click stream.
   */
  "data-clickstream": PropTypes.string,
  /**
   * Allows for a description to be passed to the tab panel.
   */
  tabPanelAriaLabel: PropTypes.string,
  // EP_MOD
  disabled: PropTypes.bool,
};
const defaultProps = {
  hide: false,
  minWidth: "44px",
  width: "auto",
  className: null,
  calculateIndicatorColor: _calculateIndicatorColor,
  renderLabel: _renderLabel,
  focusRingRadius: "2px",
  surface: "light",
};
const StyledTab = styled.li.withConfig({
  displayName: "StyledTab",
  componentId: "VDS__sc-eyuyma-0",
})`
  position: relative;
  display: flex;
  outline: none;
  ${({ fillContainer }) => fillContainer && `flex: 1`};
  margin: ${({ size }) =>
    size === "large"
      ? calculateRem(0, LayoutTokens.space["8X"].value, 0, 0)
      : calculateRem(0, LayoutTokens.space["6X"].value, 0, 0)};
  :last-child {
    margin: 0;
  }
`;
const ItemWrapper = styled.span.withConfig({
  displayName: "ItemWrapper",
  componentId: "VDS__sc-eyuyma-1",
})`
  margin-bottom: ${({ size }) =>
    size === "large"
      ? calculateRem(LayoutTokens.space["6X"].value)
      : calculateRem(LayoutTokens.space["4X"].value)};
  text-decoration: none;
  &:last-child {
    margin-bottom: 0px;
  }
  &:focus {
    outline: none;
  }
`;
const HitArea = styled.span.withConfig({
  displayName: "HitArea",
  componentId: "VDS__sc-eyuyma-2",
})`
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 44px;
  cursor: pointer;
`;
const ContentWrapper = styled.a.withConfig({
  displayName: "ContentWrapper",
  componentId: "VDS__sc-eyuyma-3",
})`
  outline: none;
  padding: ${SPACE2} 0px;
  box-sizing: border-box;
  padding-left: ${SPACE4};
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  overflow: visible;
  &:focus:not(:hover) .tabContent {
    overflow: visible;
    &::after {
      display: inline-flex;
      position: absolute;
      top: -3px;
      left: -3px;
      width: calc(
        100% +
          ${calculateRem(
            parseInt(AccessibilityTokens.focusring.space.offset.value) * 2
          )}
      );
      height: calc(
        100% +
          ${calculateRem(
            parseInt(AccessibilityTokens.focusring.space.offset.value) * 2
          )}
      );
      content: "";
      border: ${AccessibilityTokens.focusring.borderwidth.value}
        ${AccessibilityTokens.focusring.borderstyle.value}
        ${({ surface }) =>
          surface === "dark"
            ? AccessibilityTokens.color.focusring.ondark.value
            : AccessibilityTokens.color.focusring.onlight.value};
      border-radius: ${({ focusRingRadius }) => calculateRem(focusRingRadius)};
    }
  }

  ${({ selected, indicatorColor }) => `
    &:hover {
      &::before {
        background:  ${
          selected ? indicatorColor.selectedColor : indicatorColor.hoverColor
        };
        box-shadow: none;
        content: '';
        height: 100%;
        left: -1px;
        position: absolute;
        top: 0;
        width: 4px;
        z-index: 1;
      }
    }
  `}
  ${({ selected, indicatorColor }) => `
    &::before {
      background:  ${selected ? indicatorColor.selectedColor : "none"};
      box-shadow: none;
      content: '';
      height: 100%;
      left: -1px;
      position: absolute;
      top: 0;
      width: 4px;
      z-index: 1;
    }
  `};
`;
const TabContent = styled.span.withConfig({
  displayName: "TabContent",
  componentId: "VDS__sc-eyuyma-4",
})`
  display: flex;
  position: relative;
  justify-content: middle;
  width: auto;
`;
class Tab extends Component {
  constructor(props) {
    super(props);
    this.refPath = UNSAFE_SetEnvRef(); // use it to set either ref or innerRef prop
    this._onClick = (evt) => {
      const { onClick, uniqueId, disabled } = this.props;
      if (!disabled) {
        evt.target = document.getElementById(uniqueId);
        if (evt.detail === 0) evt.preventDefault();
        onClick && onClick(evt);
      }
    };
    this._onKeyDown = (e) => {
      if (!this.props.disabled && (e.keyCode === 13 || e.keyCode === 32)) {
        this._onClick(e);
      }
    };
    this._addHover = (e) => {
      if (!this.props.disabled && !this.state.hovered)
        this.setState({
          hovered: true,
        });
    };
    this._removeHover = (e) =>
      this.setState({
        hovered: false,
      });
    this._setRef = (ref) => {
      const { setRef, viewport, orientation } = this.props;
      const domNode = ReactDOM.findDOMNode(ref);
      if (setRef) {
        if (
          (viewport === "desktop" ||
            viewport === "desktopSmall" ||
            viewport === "tablet") &&
          orientation === "vertical"
        ) {
          setRef(ref);
        } else {
          setRef(domNode && domNode.firstChild);
        }
      }
    };
    this.isMobileDevice = checkIfMobileDevice();
    this.tabRef = null;
    this.handleVisibilityChange =
      props.handleVisibilityChange || props.onVisibilityChange;
    this.state = {
      selected: props.selected,
      hovered: false,
    };
  }
  componentDidUpdate(oldProps) {
    if (!this.props.handleVisibilityChange) {
      return;
    }
    if (!this.props.disabled && this.props.selected !== oldProps.selected) {
      let selected = this.props.selected;
      this.setState({
        selected: selected,
      });
      this.handleVisibilityChange("isSelected", selected);
    }
    if (this.props.hide !== oldProps.hide) {
      this.handleVisibilityChange("hide", this.props.hide);
    }
  }
  render() {
    const {
      indicatorFillTab,
      indicatorPosition,
      hide,
      label,
      uniqueId,
      minWidth,
      width,
      fillContainer,
      onClick,
      className,
      size,
      calculateIndicatorColor,
      renderLabel,
      focusRingRadius,
      analyticsTrack,
      track,
      ignoreTrack,
      clickStream,
      orientation,
      viewport,
      children,
      dataTrack,
      position,
      cta,
      level,
      selected,
      numberOfTabs,
      surface,
      overflow,
      disabled,
    } = this.props;
    const { hovered } = this.state;
    if (hide) {
      return null;
    }
    const isDesktop =
      viewport === "desktop" ||
      viewport === "desktopSmall" ||
      viewport === "tablet";
    const styledTabProps = {};
    if (this.isMobileDevice) {
      styledTabProps["ariaLabel"] = `${label} tab ${
        selected ? "selected" : "unselected"
      } ${this.props.index + 1} of ${numberOfTabs}`;
    }
    let buttonWidth = typeof width === "number" ? calculateRem(width) : width;
    if (fillContainer) {
      buttonWidth = "100%";
    } else if (!isDesktop) {
      buttonWidth = overflow === "scroll" ? "max-content" : buttonWidth;
    }
    if (!isDesktop || orientation === "horizontal") {
      return React.createElement(
        StyledTab,
        Object.assign(
          {
            size: size,
            id: `tab_${uniqueId}`,
            minWidth: minWidth,
            onClick: this._onClick,
            onKeyDown: this._onKeyDown,
            role: this.isMobileDevice ? "text" : "presentation",
            className: className,
          },
          {
            [this.refPath]: this._setRef,
          },
          {
            index: this.props.index,
            viewport: viewport,
            onBlur: this.props.onBlur,
            onMouseEnter: this._addHover,
            onMouseLeave: this._removeHover,
            isDesktop: isDesktop,
            fillContainer: fillContainer,
          },
          styledTabProps
        ),
        React.createElement(
          TabButton,
          Object.assign(
            {
              indicatorFillTab: indicatorFillTab,
              indicatorPosition: indicatorPosition,
              selected: selected,
              label: label,
              uniqueId: uniqueId,
              minWidth: minWidth,
              width: buttonWidth,
              fillContainer: fillContainer,
              size: size,
              calculateIndicatorColor: calculateIndicatorColor,
              focusRingRadius: focusRingRadius,
              clickStream: clickStream,
              analyticsTrack: analyticsTrack,
              track: track,
              ignoreTrack: ignoreTrack,
              position: position,
              level: level,
              cta: cta,
              surface: surface,
              dataTrack: dataTrack,
              disabled: disabled,
            },
            styledTabProps
          ),
          renderLabel(
            Object.assign(
              {
                hovered,
              },
              this.props
            )
          )
        )
      );
    }
    return React.createElement(
      ItemWrapper,
      {
        isDesktop: isDesktop,
        viewport: viewport,
        id: `tab_${uniqueId}`,
        size: size,
        onClick: this._onClick,
        onKeyDown: this._onKeyDown,
        onBlur: this.props.onBlur,
        onMouseEnter: this._addHover,
        onMouseLeave: this._removeHover,
      },
      React.createElement(
        ContentWrapper,
        Object.assign(
          {
            tabIndex: selected ? 0 : -1,
            selected: selected,
            id: uniqueId,
            viewport: viewport,
            isDesktop: isDesktop,
            "aria-selected": selected,
            "aria-controls": `tabpanel-${uniqueId}`,
            role: "tab",
            surface: surface,
            "data-analyticstrack": analyticsTrack,
            "data-track": track,
            "data-track-ignore": ignoreTrack,
            "data-clickstream": clickStream,
          },
          {
            [this.refPath]: this._setRef,
          },
          {
            indicatorColor: calculateIndicatorColor(surface),
            focusRingRadius: focusRingRadius,
          }
        ),
        React.createElement(HitArea, {
          tabIndex: -1,
        }),
        React.createElement(
          TabContent,
          {
            className: "tabContent",
            tabIndex: -1,
          },
          renderLabel(
            Object.assign(
              {
                hovered,
              },
              this.props
            )
          )
        )
      )
    );
  }
}
Tab.propTypes = propTypes;
Tab.defaultProps = defaultProps;
var Tab$1 = withVDSManager(Tab);

export { Tab, Tab$1 as default };
