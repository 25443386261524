import React, { useEffect, useRef } from "react";
import { StyledParallax, StyledStepsWrapper } from "./Parallax.module";
import { Title, FlexBox, Body } from "components";
import { ComplianceOverviewPageProps } from "../../../interfaces";
import {ComplianceProcessStepProps} from "../../../interfaces/Compliance";
import MdToHtml from "../../MdToHtml/MdToHtml";

export type ParallaxProps = {
  className?: string;
  children: React.ReactNode;
  pd?: string;
  mg?: string;
  maxHeight?: string;
  color?: string;
  background?: string;
  bgOffsetY?: number;
  noParallax?: boolean;
  content?: ComplianceOverviewPageProps;
};

const Parallax = ({ children, background, bgOffsetY, noParallax, content, ...rest }: ParallaxProps) => {
  
  // const [offsetY, setOffsetY] = useState(0);
  const parallaxContainer = useRef<HTMLDivElement>(null);
  const backgroundContainer = useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    if (window.innerWidth < 768) {
      return;
    }
    if (parallaxContainer.current !== null && backgroundContainer.current !== null) {
      const top = window.innerHeight - parallaxContainer.current.getBoundingClientRect().y;
      const position = Math.min(Math.max((1 - (top / window.innerHeight)) * 100, -100), 100);
      backgroundContainer.current.style.backgroundPositionY = `${position}%`;
    }
    
    // setOffsetY(window.pageYOffset);
  }
  
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  
  return (
    <>
      { !noParallax ? (
        <StyledParallax background={background}{...rest}>
          <div ref={parallaxContainer} className="parallax-overlay-container">
            <div className="parallax-overlay-content">
              {children}
            </div>
            <div ref={backgroundContainer} className="parallax-overlay-background"
                 style={{backgroundImage: `url("${background}")`}}></div>
          </div>
        </StyledParallax>) : (
        <StyledParallax background={background}{...rest}>
          <div className="parallax-overlay-container non-parallax">
            <div className="parallax-overlay-content non-parallax">
              {children}
            </div>
            <div className="parallax-overlay-background content" style={{backgroundColor: "#f6f6f6"}}>
              <StyledStepsWrapper>
                {content?.howComplianceWorksSteps.map((
                  steps: ComplianceProcessStepProps, index: number
                ) => (
                  <FlexBox key={index}>
                    <Title size="large" bold>{index + 1 + ". "}{steps.title}</Title>
                    <MdToHtml pd="0" pMargin="0" pSize="large" linkTextDecoration linkSize="large" color="#000000" content={steps.shortDescription} />
                  </FlexBox>
                ))}
              </StyledStepsWrapper>
            </div>
          </div>
        </StyledParallax>
      )
      }
    </>
  );
};

export default Parallax;
