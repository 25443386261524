import { ModalProps } from "interfaces";
import { Modal as MuiModal } from "@mui/material";
import { StyledWrapper, StyledClose } from "./Modal.module";

const Modal = ({
  open,
  onClose,
  disabled,
  hideBackdrop,
  children,
  allowOverflow,
  maxWidth,
  height,
  testId,
}: ModalProps) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      data-testid={testId ? testId : "mui-modal"}
    >
      <StyledWrapper
        className={allowOverflow ? "allow-overflow" : ""}
        maxWidth={maxWidth}
        height={height}
      >
        <StyledClose
          name="close"
          size="medium"
          onClick={onClose}
          disabled={disabled}
          testId="modal-close"
        />

        {children}
      </StyledWrapper>
    </MuiModal>
  );
};

export default Modal;
