import styled from "styled-components";
import { ButtonGroupProps } from "interfaces";

export const StyledGroup = styled.div<ButtonGroupProps>`
  width: ${(props: ButtonGroupProps) =>
    props.fullWidth ? "100%" : "fit-content"};
  display: grid;
  grid-template: auto / repeat(2, max-content);
  gap: 0.75rem;
  align-items: center;
  justify-content: ${(props: ButtonGroupProps) => props.justify || "center"};
  padding: ${(props: ButtonGroupProps) => props.pd || 0};
  button {
    width: 100%;
  }

  align-self: ${(props: ButtonGroupProps) => props.align || ""};

  &.breakpoint {
    @media only screen and (max-width: ${(props: ButtonGroupProps) =>
        props.bp}) {
      grid-template: unset;
    }
  }
`;
