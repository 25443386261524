import { Loader } from "components";
import { useParams } from "react-router-dom";
import ErrorBoundary from "views/ErrorBoundary/ErrorBoundary";

interface stringIndex {
  [key: string]: string;
}

const withReduxData =
  (name: string, query: Function) => (WrappedComponent: any) => {
    const Component = (props: any) => {
      const params = useParams();
      const { data, isLoading, isFetching, isError } = query(params);
      let dynamicProps: stringIndex = {};
      dynamicProps[name] = data;
      return isError ? (
        <ErrorBoundary />
      ) : !dynamicProps[name] || isFetching ? (
        <Loader active />
      ) : (
        <WrappedComponent {...dynamicProps} {...props} />
      );
    };

    return Component;
  };

export default withReduxData;
