import { __awaiter } from '../../_virtual/_tslib.js';

let breakpoints = {
  xs: '320px',
  sm: '480px',
  md: '767px',
  lg: '991px',
  xl: '1024px',
  xxl: '1272px'
};
let desktop = {
  min: _parsePxStringToInt(breakpoints.xl),
  max: null
};
let tabletLarge = {
  min: _parsePxStringToInt('992px'),
  max: _parsePxStringToInt('1023px')
};
let tablet = {
  max: _parsePxStringToInt(breakpoints.lg),
  min: _parsePxStringToInt('768px')
};
let mobileLarge = {
  max: _parsePxStringToInt(breakpoints.md),
  min: _parsePxStringToInt(breakpoints.sm)
};
class ViewportManager {
  constructor(config) {
    this.viewport = this._calculateInitialViewport();
    if (typeof global !== 'undefined' && !global.callbacks) global.callbacks = {};
  }
  register({
    maxMobile,
    maxMobileLarge,
    maxTablet,
    maxTabletLarge,
    minDesktop
  }) {
    const self = this;
    let desktopQuery = `screen and (min-width:${minDesktop})`; // 1024 - any
    let tabletLargeQuery = `screen and (min-width: 992px) and (max-width:${maxTabletLarge})`; // 992 - 1023
    let tabletQuery = `screen and (min-width: 768px) and (max-width:${maxTablet})`; // 768 - 991
    let mobileLargeQuery = `screen and (min-width: 480px) and (max-width:${maxMobileLarge})`; // 480 - 767
    let mobileQuery = `screen and (max-width:${maxMobile})`; // any - 479
    if (this.registered) return;
    if (typeof window !== 'undefined') {
      (() => __awaiter(this, void 0, void 0, function* () {
        let enquire = yield Promise.resolve().then(() => require('enquire.js'));
        if (enquire.register) {
          //If it goes from TabletLarge to Desktop
          enquire.register(desktopQuery, {
            match: function () {
              self._changeViewport('desktop', 'desktop');
            }
          });
          // If it goes from tablet to tabletLarge
          enquire.register(tabletLargeQuery, {
            match: function () {
              self._changeViewport('desktop', 'tabletLarge');
            }
          });
          //If it goes from Desktop to Tablet
          enquire.register(tabletQuery, {
            match: function () {
              self._changeViewport('tablet', 'tablet');
            }
          });
          //If it goes from mobile to mobileLarge
          enquire.register(mobileLargeQuery, {
            match: function () {
              self._changeViewport('mobile', 'mobileLarge');
            }
          });
          //If it goes from Tablet to Mobile
          enquire.register(mobileQuery, {
            match: function () {
              self._changeViewport('mobile', 'mobile');
            }
          });
          this.registered = true;
        }
      }))();
    }
  }
  unregister({
    maxMobile,
    maxMobileLarge,
    maxTablet,
    maxTabletLarge,
    minDesktop
  }) {
    let desktopQuery = `screen and (min-width:${minDesktop})`; // 1024 - any
    let tabletLargeQuery = `screen and (min-width: 992px) and (max-width:${maxTabletLarge})`; // 992 - 1023
    let tabletQuery = `screen and (min-width: 768px) and (max-width:${maxTablet})`; // 768 - 991
    let mobileLargeQuery = `screen and (min-width: 480px) and (max-width:${maxMobileLarge})`; // 480 - 767
    let mobileQuery = `screen and (max-width:${maxMobile})`; // any - 479
    if (!this.registered) return;
    (() => __awaiter(this, void 0, void 0, function* () {
      let enquire = yield Promise.resolve().then(() => require('enquire.js'));
      enquire.unregister(desktopQuery);
      enquire.unregister(tabletLargeQuery);
      enquire.unregister(tabletQuery);
      enquire.unregister(mobileLargeQuery);
      enquire.unregister(mobileQuery);
    }))();
  }
  // Grab expanded variable from HOC component
  subscribe(id, expanded, cb) {
    if (typeof global !== 'undefined') global.callbacks[id] = {
      callbackFn: cb,
      expanded
    };
  }
  unsubscribe(id) {
    if (typeof global !== 'undefined') delete global.callbacks[id];
  }
  _calculateInitialViewport() {
    if (typeof window === 'undefined') return;
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    let viewport;
    let expandedViewport;
    // >= 1024
    if (width >= desktop.min) {
      viewport = 'desktop';
      expandedViewport = 'desktop';
    } else if (width >= tabletLarge.min && width < desktop.min) {
      // 992 <= x < 1024
      viewport = 'tablet';
      expandedViewport = 'tabletLarge';
    } else if (width >= tablet.min && width < tabletLarge.min) {
      // 768 <= x < 992
      viewport = 'tablet';
      expandedViewport = 'tablet';
    } else if (width >= mobileLarge.min && width < tablet.min) {
      // 480 <= x < 768
      viewport = 'mobile';
      expandedViewport = 'mobileLarge';
    } else if (width >= 0 && width < mobileLarge.min) {
      // 0 <= x < 480
      viewport = 'mobile';
      expandedViewport = 'mobile';
    }
    return {
      normalViewport: viewport,
      expandedViewport
    };
  }
  _changeViewport(normalViewport, expandedViewport) {
    this.viewport = {
      normalViewport,
      expandedViewport
    }; // Will fire when component first mounted
    let callbackKeys = typeof global !== 'undefined' && Object.keys(global.callbacks);
    if (callbackKeys.length > 0) {
      callbackKeys.forEach(key => {
        if (typeof global !== 'undefined' && global.callbacks && global.callbacks[key] && global.callbacks[key].callbackFn) {
          // If the callback object has expanded variable true,
          // send expandedViewport
          global.callbacks[key].expanded ? global.callbacks[key].callbackFn(expandedViewport) : global.callbacks[key].callbackFn(normalViewport);
        }
      });
    }
  }
}
var ViewportManager$1 = new ViewportManager();
//Helpers
function _parsePxStringToInt(string) {
  if (typeof string == 'number') return string;
  if (!string.indexOf('px')) return parseInt(string);
  return parseInt(string.split('px')[0]);
}

export { ViewportManager$1 as default };
