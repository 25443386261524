import { TableCell, TableRow } from "@mui/material";
import styled from "styled-components";
export const TableWrapper = styled.div`
  margin-bottom: 4rem;
  border: 1px solid #d8dada;

  label:first-of-type {
    align-items: center;
    > span > span {
      padding-bottom: 0;
      > p {
        line-height: 1rem;
      }
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgb(246, 246, 246);
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    padding: 8px;
  }
  &.MuiTableCell-body {
    &:not(.dense) {
      padding: 20px 8px;
    }
    &.dense {
      padding: 0 8px;
    }
    &:not(:first-of-type) {
      border-left: 1px solid #d8dada;
    }
  }
`;
