import { useState, useEffect } from "react";
import { RetailSideNav, FlexBox, Body, IconButton } from "components";
import { Drawer } from "@mui/material";
import { StyledMobileNav, StyledLogoContainer } from "./RetailMobileNav.module";
import { Location } from "react-router-dom";
import { RetailNavigationPage } from "interfaces";
import paths from "../../../routing/paths";
import Image from "../Image/Image";
import { verizonCheckmark } from "../../../assets";

type Anchor = "top" | "left" | "bottom" | "right";
const RetailMobileNav = ({ location }: { location: Location }) => {
  const RetailCenterPages: RetailNavigationPage[] = [
    {
      title: "Overview",
      url: "/retail",
    },
    {
      title: "Brand Policies",
      url: "#",
      children: [
        // DISCUSS: full token hard coded here??
        {
          title: "Authorized Retailer",
          url: "https://brandpolicy.verizon.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzEwNDM4NTYsImlzcyI6ImFjYiIsImp0aSI6IjNmNWU3MzdiLTVhNDYtNDEzMi04Mjc1LTU4ZWRiYzI3OWMxMiJ9.YlbE-WRk5FDg-L4uf9nMTxfD-tXmL2eYTwaiK44yf6A&showHeader=true",
          external: true,
        },
        {
          title: "Corporate Retail",
          url: "https://retailbrandpolicy.verizon.com/",
          external: true,
        },
      ],
    },
    {
      title: "Branded Environments",
      url: "/retail/branded-environments",
      children: [
        {
          title: "Branded environment design guide",
          url: "/retail/branded-environments",
        },
        {
          title: "Design advisories",
          url: "/retail/branded-environments/design-advisories",
        },
      ],
    },
    {
      title: "Governance",
      url: "/retail/retail-governance",
      children: [
        {
          title: "Process overview",
          url: "/retail/retail-governance",
        },
        {
          title: "Audits and assessments",
          url: "/retail/retail-governance/reports",
        },
      ],
    },
    {
      title: "Templates and Tools",
      url: "/retail/templates-and-tools",
      children: [
        {
          title: "Templates",
          url: "/retail/templates-and-tools",
        },
        {
          title: "Tools",
          url: "/retail/templates-and-tools/tools",
        },
      ],
    },
    {
      title: "Support",
      url: "/retail/support",
      children: [
        {
          title: "FAQs",
          url: "/retail/support",
        },
        {
          title: "Contact us",
          url: "/retail/support/contact-us",
        },
      ],
    },
  ];

  const [currentLink, _currentLink] = useState<string>("");
  const [showNav, _showNav] = useState<boolean>(false),
    toggleNav = () => {
      if (showNav) {
        _showNav(false);
      } else {
        _showNav(true);
      }
    };

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    toggleNav();
  };

  const getCurrentLink = () => {
    const match = RetailCenterPages.find((page) =>
      page.url === location.pathname ? page.title : "Overview"
    );
    if (match) {
      _currentLink(match.title);
    }
  };

  useEffect(() => {
    getCurrentLink();
  }, [location]);

  return (
    <StyledMobileNav>
      <FlexBox row align="center" justify="flex-start">
        <IconButton
          size="medium"
          noHover
          onClick={toggleNav}
          onKeyDown={toggleDrawer}
          name="plus"
        />
        <Body>{currentLink}</Body>
      </FlexBox>
      <Drawer anchor="left" open={showNav} onClose={toggleDrawer}>
        <StyledLogoContainer>
          <a href={paths.SIGNIN} className="home-icon">
            <Image
              src={verizonCheckmark}
              className="logo"
              width={20}
              height={24}
            />
          </a>
        </StyledLogoContainer>
        <RetailSideNav location={location} showMobile />
      </Drawer>
    </StyledMobileNav>
  );
};

export default RetailMobileNav;
