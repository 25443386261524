import React from 'react';
import PropTypes from 'prop-types';
import { Icon as Icon$1 } from '@vds-core/icons';

const propTypes = {
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const Icon = function (props) {
  return React.createElement(Icon$1, Object.assign({}, props));
};
Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;
Icon.displayName = 'VDS_Icon';

export { Icon as default };
