import styled from "styled-components";
import { FlexBox } from "../../index";
import { mobile } from "../../../theme/variables";

export const StyledDiv = styled("div")`
  
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  width: 37px;
  border: 2px solid #00AC3E;
  border-radius: 100%;
  margin-bottom: 1rem;
`;

export const StyledFlexbox = styled(FlexBox)`
  background-color: #f6f6f6;
  padding: 2.5rem;
  @media ${mobile}{
    background-color: unset;
    padding: unset;
  }
`;
