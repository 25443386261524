import {
  TextButton,
  Button,
  FlexBox,
  Modal,
  Title,
  ButtonGroup,
  Form,
  Input,
} from "components";
import { useSaveSearchMutation } from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import { CategoryResponse, FormikProps } from "interfaces";
import { useSelector } from "hooks/redux";
import { ParsedUserJWT } from "interfaces/Cognito";
import { selectCurrentUserDetails } from "services/auth/auth.slice";

const SaveSearchModal = ({
  searchResponse,
  onClose,
  values,
  handleChange,
  touched,
  errors,
  handleBlur,
  isValid,
  dirty,
}: {
  searchResponse: any;
  onClose: Function;
} & FormikProps) => {
  const dispatch = useDispatch();
  
  const user: ParsedUserJWT | null | undefined = useSelector(
      selectCurrentUserDetails
    ),
    handleOnSubmit = () => {
      let searchObject: any = {
        Query: searchResponse.search,
        Filters: [],
        IsRestricted: searchResponse.includeRestricted,
        IsExpired: searchResponse.includeExpired,
        ExcludeWithinExpiredDate: searchResponse.expiresOn,
      };
      (["templates"].includes(searchResponse.searchIndex) || ["design-advisories"].includes(searchResponse.searchIndex))
        ? searchObject.Filters.push({
            Type: "category",
            Value: searchResponse.categoryId,
          })
        : searchResponse.categoryId &&
          searchObject.Filters.push({
            Type: "subcategory",
            Value: searchResponse.categoryId,
          });
      searchResponse.selectedTags.map((tag: any) =>
        searchObject.Filters.push({
          Type: "tag",
          Value: tag.id,
        })
      );
      const userId =
        typeof user?.uid === "string" ? user?.uid : user?.uid[0] || "";
      callSaveSearch({
        Name: values.name,
        SearchObject: JSON.stringify(searchObject),
        UserId: userId,
        SearchType: searchResponse.searchTypes[0]
      })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: "Search successfully saved.",
          })
        );
        onClose();
      })
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Error saving search.",
          })
        );
      });
    };

  const [callSaveSearch, { isLoading }] = useSaveSearchMutation(),
    handleClose = () => {
      onClose();
    };

  return (
    <Modal testId="save-search-modal" open onClose={handleClose} disabled={isLoading} maxWidth="660px">
      <FlexBox minWidth="100%">
        <Title size="large" bold pd="0 0 2rem">
          Save Search
        </Title>
        <Form onSubmit={handleOnSubmit} gap="1rem">
          <Input
            value={values.name}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            name="name"
            label="Name your search"
            error={touched.name && !!errors.name}
            errorText={errors.name}
            disabled={isLoading}
            data-testid="search-name"
          />
          <ButtonGroup>
            <Button
              use="primary"
              type="submit"
              disabled={isLoading || !(dirty && isValid)}
              data-testid="submit-button"
            >
              Save
            </Button>
            <TextButton data-testid="cancel-button" disabled={isLoading} onClick={handleClose}>
              Cancel
            </TextButton>
          </ButtonGroup>
        </Form>
      </FlexBox>
    </Modal>
  );
};

export default SaveSearchModal;
