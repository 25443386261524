import styled from "styled-components";
import FlexBox from "components/layouts/FlexBox/FlexBox";

export const StyledFlexBox = styled(FlexBox)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;

  > * {
    opacity: 0.8;
  }
`;

export const StyledCarouselWrapper = styled(FlexBox)`

`;

export const ImageWrapper = styled(FlexBox)`
  /* border: 1px solid black; */
  border-radius: 0.5rem;
  padding: 3rem;
  background-color: #ffffff;
  
  &.image-container {
    width: 100%;
  }
`;
