import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import {TextLinkProps} from "../TextLink/TextLink";


const baseLinkStyle = css`
  text-decoration: none;
  color: inherit;
  width: fit-content;
  height: ${(props:TextLinkProps) => (props.display === "flex" ? "3rem" : "auto")};
  align-items: center;
  align-self: ${(props) => props.align || ``};
  display: ${(props) => props.display || "block"};

  div > p {
    border-bottom: 1px solid black;
    margin-bottom: 1px;
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
    font-size: ${(props) =>
  props.size === "large"
    ? "1rem"
    : props.size === "XLarge"
      ? "1.25rem"
      : props.size === "medium"
        ? "0.875rem"
        : "0.75rem"};
    font-family: ${(props) =>
  props.size === "large" || props.size === "XLarge" || props.size === "medium"
    ? "Verizon-NHG-eDS"
    : "Verizon-NHG-eTX"};
  }

  &:active {
    color: grey;
  }

  &:hover:not(.disabled) {
    div > p {
      border-bottom-width: 2px;
      margin-bottom: 0px;
    }
  }

  &.disabled {
    pointer-events: none;
    div > p {
      color: grey;
      border-bottom-color: lightgrey;
    }
  }

  a > div {
    height: 1.5rem;
    display: flex;
    align-items: center;
  }

  &.footer-link p {
    border-bottom: none;
  }

  &.no-underline,
  &.underline-on-hover,
  &:hover:not(.disabled).no-underline {
    div p {
      border-bottom: none;
      border-bottom-width: 0;
      margin-bottom: 0;
    }
  }

  &:hover:not(.disabled).underline-on-hover {
    div p {
      border-bottom: 2px solid black;
      margin-bottom: -2px;
    }
  }
`;

export const StyledLink = styled(Link)<TextLinkProps>`
  ${baseLinkStyle}
`;

export const StyledExternalLink = styled.a<TextLinkProps>`
  ${baseLinkStyle}
`;
