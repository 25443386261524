import React from "react";
import { StyledContainer } from "./VideoContainer.module";

export type VideoContainerProps = {
  className?: string;
  children: React.ReactNode;
};

const VideoContainer = ({ className, children,...rest }: VideoContainerProps) => {
  return (
    <StyledContainer className={className} {...rest}>
      {children}
    </StyledContainer>
  );
};

export default VideoContainer;
