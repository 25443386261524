import {
  Body,
  Button,
  FlexBox,
  Form,
  Input,
  TextArea,
  TextButton,
  Title,
} from "components";
import { FormikProps } from "interfaces";
import { useRequestAssetMutation } from "services/api/api.slice";
import { useDispatch } from "react-redux";
import { setNotification } from "../../services";

interface NoResultsProps {
  search: string;
  suggestedSearch: Array<string>;
  updateSearch: Function;
}
const NoResults = ({
  suggestedSearch,
  updateSearch,
  values: { assetName, purpose, fileTypes },
  handleChange,
  handleBlur,
  errors,
  touched,
  isValid,
  dirty,
}: FormikProps & NoResultsProps) => {
  const dispatch = useDispatch();

  const [requestAsset, { isLoading, isSuccess }] = useRequestAssetMutation();
  const handleSubmit = () => {
    requestAsset({
      payload: {
        assetRequested: assetName,
        fileTypesNeeded: fileTypes,
        purposeOfUse: purpose,
      },
    })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: "Asset has been requested.",
          })
        );
      })
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Could not send asset request.",
          })
        );
        console.error(error);
      });
  };

  return (
    <FlexBox
      maxWidth="35rem"
      mg="auto"
      gap="1.5rem"
      data-testid="no-results-container"
    >
      <Title size="large" bold pd="1.5rem 0 0.5rem">
        Your search did not return any results.
      </Title>
      {suggestedSearch.length ? (
        <FlexBox gap="0.5rem" wrap row align="center">
          <Title bold> Did you mean:</Title>
          {suggestedSearch.map((suggestion: string) => (
            <TextButton
              data-testid="suggestion-search-button"
              key={suggestion}
              onClick={updateSearch(suggestion)}
            >
              <Title bold>{suggestion}</Title>
            </TextButton>
          ))}
        </FlexBox>
      ) : (
        <></>
      )}
      {isSuccess ? (
        <Body size="large">Thank you for submitting a request.</Body>
      ) : (
        <>
          <Body size="large">
            To request an asset to be available on Brand Central, please use the
            form below. The brand team will reach out to you if any additional
            information is needed.
          </Body>
          <Form onSubmit={handleSubmit} gap="1rem">
            <Input
              label="Asset being requested"
              name="assetName"
              value={assetName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.assetName && !!errors.assetName}
              errorText={errors.assetName}
              data-testid="asset-being-requested"
            />
            <TextArea
              value={purpose}
              onChange={handleChange}
              onBlur={handleBlur}
              name="purpose"
              label="Purpose of the file use"
              error={touched.purpose && !!errors.purpose}
              errorText={errors.purpose}
              required
              maxLength={1000}
              data-testid="purpose"
            />
            <TextArea
              value={fileTypes}
              onChange={handleChange}
              onBlur={handleBlur}
              name="fileTypes"
              label="File type(s) needed"
              error={touched.fileTypes && !!errors.fileTypes}
              errorText={errors.fileTypes}
              required
              data-testid="file-types"
            />
            <Button
              type="submit"
              use="primary"
              disabled={!(dirty && isValid) || isLoading}
              data-testid="submit-button"
            >
              Submit
            </Button>
          </Form>
        </>
      )}
    </FlexBox>
  );
};

export default NoResults;
