export const LayoutTokens = {
  "space": {
    "1X": {
      "value": "4px",
      "attributes": {
        "category": "size",
        "type": "1X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "4",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space1X",
      "path": [
        "space",
        "1X"
      ]
    },
    "2X": {
      "value": "8px",
      "attributes": {
        "category": "size",
        "type": "2X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "8",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space2X",
      "path": [
        "space",
        "2X"
      ]
    },
    "3X": {
      "value": "12px",
      "attributes": {
        "category": "size",
        "type": "3X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "12",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space3X",
      "path": [
        "space",
        "3X"
      ]
    },
    "4X": {
      "value": "16px",
      "attributes": {
        "category": "size",
        "type": "4X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "16",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space4X",
      "path": [
        "space",
        "4X"
      ]
    },
    "5X": {
      "value": "20px",
      "attributes": {
        "category": "size",
        "type": "5X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "20",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space5X",
      "path": [
        "space",
        "5X"
      ]
    },
    "6X": {
      "value": "24px",
      "attributes": {
        "category": "size",
        "type": "6X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "24",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space6X",
      "path": [
        "space",
        "6X"
      ]
    },
    "8X": {
      "value": "32px",
      "attributes": {
        "category": "size",
        "type": "8X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "32",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space8X",
      "path": [
        "space",
        "8X"
      ]
    },
    "12X": {
      "value": "48px",
      "attributes": {
        "category": "size",
        "type": "12X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "48",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space12X",
      "path": [
        "space",
        "12X"
      ]
    },
    "16X": {
      "value": "64px",
      "attributes": {
        "category": "size",
        "type": "16X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "64",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space16X",
      "path": [
        "space",
        "16X"
      ]
    },
    "24X": {
      "value": "96px",
      "attributes": {
        "category": "size",
        "type": "24X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "96",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space24X",
      "path": [
        "space",
        "24X"
      ]
    },
    "32X": {
      "value": "128px",
      "attributes": {
        "category": "size",
        "type": "32X"
      },
      "filePath": "packages/Layout/src/index.js",
      "isSource": true,
      "original": {
        "value": "128",
        "attributes": {
          "category": "size"
        }
      },
      "name": "Space32X",
      "path": [
        "space",
        "32X"
      ]
    }
  }
}