import {useEffect, useRef, useState} from "react";
import Modal from "../../components/modals/Modal/Modal";
import {Category, SavedSearchResponse, SubCategory, Tag} from "../../interfaces";
import {Body, Button, Checkbox, DatePicker, FlexBox, IconButton, Loader, RadioGroup, TextButton, Title} from "../../components";
import {SelectedTag} from "./Search.module";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {Icon} from "@vds/icons";
import {setNotification, useLazySearchAssetsQuery} from "../../services";
import {useDispatch} from "../../hooks/redux";


export interface MobileSearchSettings {
  subcategories: Array<SubCategory>;
  selectedSearchTypes: Array<string>;
  selectedCategories: Array<string>;
  selectedCategory: number;
  selectedSearchType: number;
  selectedCategoryId: string | null;
  categoryOptions: Array<CategoryOption>;
  categories: Array<Category>;
  correlationId: string;
  savedSearch: SavedSearchResponse | null;
  savedSearchApplied: boolean;
  filters: FiltersProps;
}

interface MobileSearchFilterProps {
  categories?: Array<Category>;
  setResults: Function;
  setLoading: Function;
  search: string | null;
  pageNumber: number;
  setPageNumber: Function;
  pageSize: number;
  sortBy: number;
  updateSearchHistory: boolean;
  setCategory: Function;
  setSelectedSearchType: Function;
  onClose: Function;
  setCurrentSearch: Function;
  currentSearch: MobileSearchSettings;
}

interface FiltersProps {
  restricted: boolean;
  expired: boolean;
  expiresAfter: boolean;
  expiresOn: Date | null;
  selectedTags: Array<{ id: string; value: string }>;
  submit: boolean;
}

interface AvailabilityProps {
  restricted: boolean;
  expired: boolean;
  expiresAfter: boolean;
}

export type CategoryOption = {
  label: string;
  value: number;
}

export type SelectedTagProps = {
  id: string;
  value: string;
}

const MobileSearchFilter = ({
  setResults,
  setLoading,
  search,
  pageNumber,
  setPageNumber,
  pageSize,
  sortBy,
  updateSearchHistory,
  setCategory,
  categories,
  onClose,
  currentSearch,
  setCurrentSearch,
  setSelectedSearchType
}: MobileSearchFilterProps) => {
  
  const [searchAssets] = useLazySearchAssetsQuery();
  const dispatch = useDispatch();
  
  const handleClose = () => {
    onClose();
  };
  
  const searchTypeOptions = [
    { value: 0, label: "All" },
    { value: 1, label: "Assets" },
    { value: 2, label: "Guidelines" },
    { value: 3, label: "Templates" },
    { value: 4, label: "Compliance" },
    { value: 5, label: "Retail" },
    { value: 6, label: "Training" },
  ];
  
  const emptyFilter = {
      restricted: true,
      expired: false,
      expiresAfter: false,
      expiresOn: null,
      selectedTags: [],
      submit: false,
    };
  
  const initialAvailabilityFilter = {expiresAfter: currentSearch.filters.expiresAfter, expired: currentSearch.filters.expired, restricted: currentSearch.filters.restricted};
  const [isLoading, _isLoading] = useState<boolean>(false);
  const [filters, _filters] = useState<FiltersProps>(currentSearch.filters);
  const [subCategories, _setSubCategories] = useState<Array<SubCategory>>(currentSearch.subcategories);
  const [searchTypeExpanded, _searchTypeExpanded] = useState<boolean>(false);
  const [categoryExpanded, _categoryExpanded] = useState<boolean>(false);
  const [availabilityExpanded, _availabilityExpanded] = useState<boolean>(false);
  const [selectedSearchTypes, _selectedSearchTypes] = useState<Array<string>>( currentSearch.selectedSearchTypes);
  const [selectedCategories, _selectedCategories] = useState<Array<string>>(currentSearch.selectedCategories);
  const [selectedSearchType, _selectedSearchType] = useState<number>(currentSearch.selectedSearchType);
  const [selectedCategory, _selectedCategory] = useState<number>( currentSearch.selectedCategory);
  const [selectedCategoryId, _selectedCategoryId] = useState<string | null>( currentSearch.selectedCategoryId);
  const [categoryOptions, _categoryOptions] = useState<Array<CategoryOption>>(currentSearch.categoryOptions);
  const [correlationId, _setCorrelationId] = useState<string>(currentSearch.correlationId);
  const [availabilitySelected, _availabilitySelected] = useState<AvailabilityProps>(initialAvailabilityFilter);
  const [initialLoadSearch, _initialLoadSearch] = useState<boolean>(true);
  
  
  const handleRestricted = () => {
    if (initialLoadSearch === false) {
      _availabilitySelected((prevState) => {
        return {...prevState, restricted: !prevState.restricted};
      });
      _filters((existing: FiltersProps) => {
        return { ...existing, submit: true, restricted: !existing.restricted };
      });
    }
  };
  const handleExpired = () => {
    if (initialLoadSearch === false) {
      _availabilitySelected((prevState) => {
        return {...prevState, expired: !prevState.expired};
      });
      _filters((existing: FiltersProps) => {
        return {...existing, submit: true, expired: !existing.expired};
      });
    }
  };
  const handleExpiresAfter = () => {
    if (initialLoadSearch === false) {
      _availabilitySelected((prevState) => {
        return {...prevState, expiresAfter: !prevState.expiresAfter};
      });
      _filters((existing: FiltersProps) => {
        return {
          ...existing,
          submit: filters.expiresAfter && !!filters.expiresOn,
          expiresAfter: !filters.expiresAfter,
          expiresOn: null,
        };
      });
    }
  };
  const selectExpiration = (value: Date, submit: boolean) => {
    if (initialLoadSearch === false) {
      _filters((existing: FiltersProps) => {
        return {...existing, expiresOn: value, submit: submit};
      });
    }
  };
  const handleClearExpiration = () => {
    if (initialLoadSearch === false) {
      _filters((existing: FiltersProps) => {
        return {...existing, submit: true, expiresOn: null};
      });
    }
  };
  
  const handleExpandSearchType = () => {
    _searchTypeExpanded((prevState) => !prevState);
  };
  const handleExpandCategories = () => {
    _categoryExpanded((prevState) => !prevState);
  };
  const handleExpandAvailability = () => {
    _availabilityExpanded((prevState) => !prevState);
  };
  
  const handleManualSearchTypeChange = (selected: number) => {
    if (initialLoadSearch === false) {
      // when changing search types, clear category options, selected category, subcategories, and clear selected tags on filters but not the availability settings
      _categoryOptions([]);
      _selectedCategoryId(null);
      _setSubCategories([]);
      _filters((existing: FiltersProps) => {
        return { ...existing, selectedTags: [] };
      });
      if (selected === 0) {
        setSelectedSearchType(0);
        _selectedSearchTypes(["all"]);
      } else if (selected === 1) {
        setSelectedSearchType(1);
        _selectedSearchTypes(["asset"]);
      } else if (selected === 2) {
        setSelectedSearchType(2);
        _selectedSearchTypes(["guideline"]);
      } else if (selected === 3) {
        setSelectedSearchType(3);
        _selectedSearchTypes(["template"]);
      } else if (selected === 4) {
        setSelectedSearchType(4);
        _selectedSearchTypes(["compliance"]);
      } else if (selected === 5) {
        setSelectedSearchType(5);
        _selectedSearchTypes(["retail"]);
      } else if (selected === 6) {
        setSelectedSearchType(6);
        _selectedSearchTypes(["training"]);
      }
      _selectedSearchType(selected);
    }
  };
  
  const handleSearchTypeChange = (selected: number) => () => {
    handleManualSearchTypeChange(selected);
  };
  
  const handleManualCategoryChange = (selected: number) => {
    if (categories && categories.length > 0 && initialLoadSearch === false) {
      // if asset search type, must account for the all as the first option, so move the index back one, but only if the selected options is not all, whose index is 0
      if (selectedSearchTypes.includes("asset") && selected > 0) {
        setCategory(categories[selected - 1].id);
        _selectedCategories([categories[selected - 1].id])
      } else if (selectedSearchTypes.includes("asset") && selected === 0) {
        // if asset search type and user selects all, set selected category id to null and do not call the setCategory function that is passed in
        _selectedCategories(["All"]);
        _selectedCategoryId(null);
      } else {
        // for everything else, set the selected option as normal
        setCategory(categories[selected].id);
        _selectedCategories([categories[selected].id])
      }
      // this updates the value of the field
      _selectedCategory(selected);
      
      // if asset search type and all categories is not selected, set the category id and then set the subcategories based on that category
      if (categoryOptions[selected].label !== "All" && selectedSearchTypes.includes("asset")) {
        _selectedCategoryId(categories[selected - 1].id);
        // if the category has subcategories, set the state for subcategories, otherwise reset subcategories to an empty array
        if (categories[selected - 1].subCategories.length > 0) {
          const newSubCategories = categories[selected - 1].subCategories.map((subcat) => subcat);
          _setSubCategories(newSubCategories);
        } else _setSubCategories([]);
        
      } else if (!selectedSearchTypes.includes("asset")) {
        // if search type is anything other than asset, set the category id and subcategories state based on that category's subcategories. if it does not have any, reset it to an empty array
        _selectedCategoryId(categories[selected].id);
        if (categories[selected].subCategories.length > 0) {
          const newSubCategories = categories[selected].subCategories.map((subcat) => subcat);
          _setSubCategories(newSubCategories);
        } else _setSubCategories([]);
        
      } else {
        // this is for if the search type is asset, category is all, so there is no selected category id, subcategories, or tags. when resetting filters, leave the availability filters the same, just remove the tags
        _selectedCategoryId(null);
        _setSubCategories([]);
        if (initialLoadSearch === false) {
          _filters((existing: FiltersProps) => {
            return {...existing, submit: true, selectedTags: []};
          });
        }
      }
    }
  };
  
  // need this for radio buttons so it doesn't loop on load
  const handleCategoryChange = (selected: number) => () => {
    handleManualCategoryChange(selected);
  };
  
  const getCategoryOptions = () => {
    if (categories && categories.length > 0) {
      const tempCategories: CategoryOption[] = categories.map((category, index) => {
        let option = {
          label: category.value,
          value: index
        };
        // add 1 to the value because for assets, first choice has to be all
        if (selectedSearchTypes.includes("asset")) {
          option = {
            label: category.value,
            value: index + 1
          };
        }
        return option;
      });
      // add All as the first option
      if (selectedSearchTypes.includes("asset")) {
        // unshift adds the all option at the beginning of the array
        tempCategories.unshift({label: "All", value: 0});
      }
      
      _categoryOptions(tempCategories);
    }
  };
  
  useEffect(() => {
    if (categories && categories.length > 0 && initialLoadSearch === false) {
      getCategoryOptions();
    }
  }, [categories]);
  
  const handleSelectFilter = ({ id, value }: { id: string; value: string }) => {
    if (initialLoadSearch === false) {
      
      // if selected tags includes the tag id, filter out all the tags that don't match in order to remove that selected tag
      if (filters.selectedTags.some((tag) => tag.id === id)) {
        _filters((existing: FiltersProps) => {
          return {
            ...existing,
            selectedTags: filters.selectedTags.filter((tag) => tag.id !== id),
          };
        });
      } else {
        // add the tag to the list of selected tags
        !!filters.selectedTags.length
          ? _filters((existing: FiltersProps) => {
            return {
              ...existing,
              selectedTags: [...filters.selectedTags, {id, value}],
            };
          })
          : _filters((existing: FiltersProps) => {
            return {
              ...existing,
              selectedTags: [{id, value}],
            };
          });
      }
    }
  };
  
  const handleOnError = () => {
    dispatch(
      setNotification({
        type: "error",
        message: "An error has occurred. Please try again.",
      })
    );
    _isLoading(false);
  };
  
  const handleSubmit = () => {
    if (initialLoadSearch === false) {
      _isLoading(true);
      searchAssets({
        search,
        searchTypes: selectedSearchType === 0 ? ["all"] : selectedSearchType === 1 ? ["asset"] : selectedSearchType === 2 ? ["guideline"] : selectedSearchType === 3 ? ["template"] : selectedSearchType === 4 ? ["compliance"] : selectedSearchType === 5 ? ["retail"] : ["training"],
        pageNumber,
        pageSize,
        sortBy: sortBy === 1 ? "popularity" : sortBy === 2 ? "last-modified" : sortBy === 3 ? "old-to-new" : sortBy === 4 ? "new-to-old" : sortBy === 5 ? "a-to-z" : sortBy === 6 ? "z-to-a" : "relevance",
        updateSearchHistory,
        correlationId,
        includeRestricted: filters.restricted,
        includeExpired: filters.expired,
        expiresOn: filters.expiresOn
          ? filters.expiresOn.toISOString()
          : undefined,
        categoryId: selectedCategoryId === "All" || selectedCategoryId === null ? undefined : selectedCategoryId,
        selectedTags: filters.selectedTags,
      })
      .unwrap()
      .then((response) => {
        _setCorrelationId(response.correlationId);
        setResults({
          search,
          searchTypes: selectedSearchType === 0 ? ["all"] : selectedSearchType === 1 ? ["asset"] : selectedSearchType === 2 ? ["guideline"] : selectedSearchType === 3 ? ["template"] : selectedSearchType === 4 ? ["compliance"] : selectedSearchType === 5 ? ["retail"] : ["training"],
          pageNumber,
          pageSize,
          sortBy: sortBy === 1 ? "popularity" : sortBy === 2 ? "last-modified" : sortBy === 3 ? "old-to-new" : sortBy === 4 ? "new-to-old" : sortBy === 5 ? "a-to-z" : sortBy === 6 ? "z-to-a" : "relevance",
          updateSearchHistory,
          correlationId: response.correlationId,
          includeRestricted: filters.restricted,
          includeExpired: filters.expired,
          expiresOn: filters.expiresOn
            ? filters.expiresOn.toISOString()
            : undefined,
          categoryId: selectedCategoryId,
          selectedTags: filters.selectedTags,
          results: response.results,
          total: response.total,
          totalPages: response.totalPages,
        });
        
        if (categories && categories.length > 0) {
          // start with all subcategories under the selected category
          // do the minus 1 if it is asset, just use selected category if retail or template
          if (selectedSearchTypes.includes("asset")) {
            if (categories[selectedCategory - 1]) {
              const newSubCategories = categories[selectedCategory - 1].subCategories.map((subcat) => subcat);
              
              // filter out any subcategories that don't contain the category ids from the response
              let filteredCategories = JSON.parse(JSON.stringify(newSubCategories));
              filteredCategories = filteredCategories.filter(
                (subCategory: SubCategory) => {
                  return Object.keys(response.filters.categories).includes(
                    subCategory.categoryId
                  );
                }
              );
              
              // filter out any subcategories whose id doesn't match the response list of subcategories
              filteredCategories = filteredCategories.filter(
                (subCategory: SubCategory) =>
                  Object.keys(response.filters.subCategories).includes(
                    subCategory.id
                  )
              );
              
              // filter out any tags that are not part of the response
              filteredCategories = filteredCategories.map(
                (subCategory: SubCategory) => {
                  return {
                    ...subCategory,
                    tags: subCategory.tags.filter((tag: Tag) =>
                      Object.keys(response.filters.tags).includes(tag.id)
                    ),
                  };
                }
              );
              
              // set filtered subcategories to get rid of any that do not have results; usually narrows it down to one subcategory but not always
              _setSubCategories(filteredCategories);
            }
          } else if (categories[selectedCategory] && (selectedSearchTypes.includes("template") || selectedSearchTypes.includes("retail"))) {
            const newSubCategories = categories[selectedCategory].subCategories.map((subcat) => subcat);
            
            if (response.filters.categories) {
              // filter out any subcategories that don't contain the category ids from the response
              let filteredCategories = JSON.parse(JSON.stringify(newSubCategories));
              filteredCategories = filteredCategories.filter(
                (subCategory: SubCategory) => {
                  return Object.keys(response.filters.categories).includes(
                    subCategory.categoryId
                  );
                }
              );
              
              if (response.filters.subCategories) {
                // filter out any subcategories whose id doesn't match the response list of subcategories
                filteredCategories = filteredCategories.filter(
                  (subCategory: SubCategory) =>
                    Object.keys(response.filters.subCategories).includes(
                      subCategory.id
                    )
                );
                
                // filter out any tags that are not part of the response
                filteredCategories = filteredCategories.map(
                  (subCategory: SubCategory) => {
                    return {
                      ...subCategory,
                      tags: subCategory.tags.filter((tag: Tag) =>
                        Object.keys(response.filters.tags).includes(tag.id)
                      ),
                    };
                  }
                );
                
                // set filtered subcategories to get rid of any that do not have results; usually narrows it down to one subcategory but not always
                _setSubCategories(filteredCategories);
              }
            }
            
          } else {
            _setSubCategories([]);
          }
        }
        _isLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        handleOnError();
      });
    }
    
  };
  
  const updateCurrentSearch = () => {
    setCurrentSearch({
      subcategories: subCategories,
      selectedSearchTypes: selectedSearchTypes,
      selectedCategories: selectedCategories,
      selectedCategory: selectedCategory,
      selectedSearchType: selectedSearchType,
      selectedCategoryId: selectedCategoryId,
      categoryOptions: categoryOptions,
      categories: categories,
      correlationId: correlationId,
      filters: filters,
    });
    onClose();
  };
  
  // if selected tag is deselected and no other tags are selected, reset the subcategories back to the full list
  const resetSubCategories = () => {
    if (categories && categories.length > 0) {
      // if the searching within assets, and the selected category is not all get the selected category's full list of subcategories, and reset the displayed subcategories
      if (selectedSearchTypes.includes("asset") && selectedCategory !== 0) {
        if (categories[selectedCategory - 1] && categories[selectedCategory - 1].subCategories && categories[selectedCategory - 1].subCategories.length > 0) {
          const newSubCategories = categories[selectedCategory - 1].subCategories.map((subcat) => subcat);
          _setSubCategories(newSubCategories);
        }
      } else {
        // if the searching within retail or templates or if all is the selected category within assets, get the selected category's full list of subcategories, and reset the displayed subcategories
        if (categories[selectedCategory] && categories[selectedCategory].subCategories && categories[selectedCategory].subCategories.length > 0) {
          const newSubCategories = categories[selectedCategory].subCategories.map((subcat) => subcat);
          _setSubCategories(newSubCategories);
        }
      }
      
    }
  };
  
  // if selected tag is deselected and no other tags are selected, reset the subcategories back to the full list
  useEffect(() => {
    // only proceed if the preloaded search has been applied
    if (initialLoadSearch === false) {
      if (filters.selectedTags.length === 0) {
        resetSubCategories();
      }
    }
  }, [filters]);
  
  
  // prevent looping on initial load once all states have been set
  const initialSearch = useRef(true);
  useEffect(() => {
    if (initialSearch.current) {
      initialSearch.current = false;
      return;
    }
    // only proceed if preloaded search has been applied
    if (initialLoadSearch === false) {
      handleSubmit();
    }
  }, [pageNumber, pageSize, sortBy]);
  
  // for submitting search
  const firstUpdateSearch = useRef(true);
  
  useEffect(() => {
    if (firstUpdateSearch.current) {
      firstUpdateSearch.current = false;
      return;
    }
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      // only proceed if there is not a saved search or if there is a saved search and it has been applied and the search term has changed
      if (initialLoadSearch === false) {
        handleSubmit();
      }
    }
  }, [search]);
  function arrayEquals(a: any, b: any): boolean {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }
  
  useEffect(() => {
    if (initialLoadSearch === true) {
      const initialSearchSettings = {
        subcategories: subCategories,
        selectedSearchTypes: selectedSearchTypes,
        selectedCategories: selectedCategories,
        selectedCategory: selectedCategory,
        selectedSearchType: selectedSearchType,
        selectedCategoryId: selectedCategoryId,
        categoryOptions: categoryOptions,
        categories: categories,
        correlationId: correlationId,
        filters: filters,
      };
      if (arrayEquals(currentSearch.subcategories, subCategories)  && arrayEquals(currentSearch.selectedSearchTypes, selectedSearchTypes) && arrayEquals(currentSearch.selectedCategories, selectedCategories) && currentSearch.selectedCategory === selectedCategory && currentSearch.selectedSearchType === selectedSearchType && currentSearch.selectedCategoryId === selectedCategoryId && arrayEquals(currentSearch.categoryOptions, categoryOptions) && arrayEquals(currentSearch.categories, categories) && currentSearch.correlationId === correlationId && (currentSearch.filters.expired === filters.expired && currentSearch.filters.expiresAfter === filters.expiresAfter && currentSearch.filters.restricted === filters.restricted && currentSearch.filters.expiresOn === filters.expiresOn && arrayEquals(currentSearch.filters.selectedTags, filters.selectedTags) && currentSearch.filters.submit === filters.submit) && initialLoadSearch) {
        _initialLoadSearch(false);
      }
    }
    
  }, [subCategories, selectedSearchTypes, selectedCategories, selectedCategory, selectedSearchType, selectedCategoryId, categoryOptions, categories, correlationId, filters]);
  
  
  useEffect(() => {
      // if there's not a saved search or if the initial saved search was applied and the filters have changed, submit the search. this is where the saved search will ultimately get called once savedSearchApplied has been set to true if the saved search does not have a selected category
      if (initialLoadSearch === false) {
        if (pageNumber !== 1) {
          setPageNumber(1);
        } else {
        if (filters.submit) {
          handleSubmit();
        }
      }
    }
  }, [filters]);
  
  useEffect(() => {
    if (initialLoadSearch === false && selectedCategoryId !== null) {
      // if there is a saved search but it hasn't been applied yet, there is a selected category, and the filters have not been cleared or reset, submit search
      if (filters.submit) {
        handleSubmit();
      }
    }
  }, [filters, selectedCategoryId]);

  
  useEffect(() => {
    
      // only proceed if there is not a saved search or if there is a saved search and it has been applied
      if (initialLoadSearch === false) {
        if (pageNumber !== 1) {
          setPageNumber(1);
        } else {
        
        if (selectedSearchTypes.includes("asset") || selectedSearchTypes.includes("template") || selectedSearchTypes.includes("retail")) {
          if (categoryOptions && categoryOptions.length > 0) {
            // only change the category for asset, template, and retail once there are category options. the default selection is the first option, which is all for assets and possibly the only category for template or retail
            handleManualCategoryChange(0);
          }
        } else {
          // if guidelines, compliance, training, or all, go ahead and submit because they don't have categories
          handleSubmit();
        }
      }
    }
  }, [selectedSearchTypes, categoryOptions]);
  
  return (
    <Modal testId="mobile-search-filter-modal" open onClose={updateCurrentSearch}>
      <FlexBox justify="space-between" align="center" minWidth="100%" row pd="4.5rem 0 2.5rem">
        <Title size="large">
          Filters
        </Title>
        {filters.selectedTags.length > 0 && (
          <TextButton
            pd="0.25rem 0 0"
            onClick={() =>
              _filters((existing: FiltersProps) => {
                return { ...existing, selectedTags: [] };
              })
            }
            testId="clear-all-button"
          >
            <Body>Clear all</Body>
          </TextButton>
        )}
      </FlexBox>
      {filters.selectedTags.length > 0 && (
        <FlexBox row gap="0.5rem" pd="0 0 1rem" wrap>
          {filters.selectedTags.map(
            (tag: { id: string; value: string }) => (
              <SelectedTag key={tag.id}>
                <Body bold>
                  {tag.value}
                </Body>
                <IconButton
                  name="close"
                  color="#000000"
                  noHover
                  onClick={() => handleSelectFilter(tag)}
                  testId="remove-selected-button"
                />
              </SelectedTag>
            )
          )}
        </FlexBox>
      )}
      
      {isLoading ? (
          <Loader containerHeight="20rem" active />
        ) :
            
          <FlexBox minWidth="100%">
              {/*Search type accordion*/}
              <Accordion
                onChange={handleExpandSearchType}
                expanded={searchTypeExpanded}
                disableGutters
                square
                data-testid="search-types"
                className="first"
              >
                <AccordionSummary
                  expandIcon={<Icon name="down-caret" />}
                >
                  <Body size="large">
                    {`Search Within ${selectedSearchTypes.length > 0 ? `(${selectedSearchTypes.length})` : ''}`}
                  </Body>
                </AccordionSummary>
                <AccordionDetails>
                  <FlexBox>
                    <FlexBox row gap="0.5rem" wrap>
                      <RadioGroup
                        name="searchType"
                        options={searchTypeOptions}
                        value={selectedSearchType}
                        onChange={(value: number) => handleSearchTypeChange(value)}
                      />
                    </FlexBox>
                  </FlexBox>
                </AccordionDetails>
              </Accordion>
              
              {/*Category accordion*/}
              {categories && categories.length > 0 && (
                <Accordion
                  onChange={handleExpandCategories}
                  expanded={categoryExpanded}
                  disableGutters
                  square
                  data-testid="categories"
                >
                  <AccordionSummary
                    expandIcon={<Icon name="down-caret" />}
                  >
                    <Body size="large">
                      {`Category ${selectedCategories.length > 0 ? `(${selectedCategories.length})` : ''}`}
                    </Body>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FlexBox>
                      <FlexBox row gap="0.5rem" wrap>
                        <RadioGroup
                          name="searchType"
                          options={categoryOptions}
                          value={selectedCategory}
                          onChange={(value: number) => handleCategoryChange(value)}
                        />
                      </FlexBox>
                    </FlexBox>
                  </AccordionDetails>
                </Accordion>
              )}
              {/*Availability accordion*/}
              {(selectedSearchType === 0 || selectedSearchType === 1 || selectedSearchType === 3 || selectedSearchType === 5) && (
                <Accordion
                  onChange={handleExpandAvailability}
                  expanded={availabilityExpanded}
                  disableGutters
                  square
                  data-testid="availability"
                >
                  <AccordionSummary
                    expandIcon={<Icon name="down-caret" />}
                  >
                    <Body size="large">
                      {`Asset Availability ${Object.values(availabilitySelected).filter((value) => value === true).length > 0 ? `(${Object.values(availabilitySelected).filter((value) => value === true).length})` : ''}`}
                    </Body>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FlexBox gap="1rem">
                      <Checkbox
                        selected={filters.restricted}
                        onChange={handleRestricted}
                        required={false}
                      >
                        <Body bold>Include restricted assets</Body>
                      </Checkbox>
                      <Checkbox
                        selected={filters.expired}
                        onChange={handleExpired}
                        required={false}
                      >
                        <Body bold>Include expired assets</Body>
                      </Checkbox>
                      <Checkbox
                        selected={filters.expiresAfter}
                        onChange={handleExpiresAfter}
                        required={false}
                      >
                        <Body bold>Expires after</Body>
                      </Checkbox>
                      {filters.expiresAfter && (
                        <DatePicker
                          value={filters.expiresOn}
                          label="Date"
                          onChange={selectExpiration}
                          data-testid="expires-after-date"
                          onClear={handleClearExpiration}
                        />
                      )}
                    </FlexBox>
                  </AccordionDetails>
                </Accordion>
              )}
              {subCategories.map((subCategory: SubCategory) => (
                subCategory.tags.length > 0 ? (
                  <Accordion
                    key={subCategory.id}
                    disableGutters
                    square
                    data-testid="filters-accordion"
                  >
                    <AccordionSummary
                      expandIcon={<Icon name="down-caret" />}
                    >
                      <Body size="large">
                        {`${subCategory.value} ${filters.selectedTags.filter((selectedTag) => {
                          if (subCategory.tags.find((tag) => tag.id === selectedTag.id)) {
                            return selectedTag;
                          }
                          
                        }).length > 0 ? `(${filters.selectedTags.filter((selectedTag) => {
                          if (subCategory.tags.find((tag) => tag.id === selectedTag.id)) {
                            return selectedTag;
                          }
                        }).length})` : ''}`}
                      </Body>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FlexBox gap="1rem">
                        {subCategory.tags.map((tag: Tag) => (
                          <Checkbox
                            key={tag.id}
                            onChange={() =>
                              handleSelectFilter({ id: tag.id, value: tag.value })
                            }
                            selected={
                              !!filters.selectedTags.some(
                                (selected) => selected.id === tag.id
                              )
                            }
                            required={false}
                          >
                            <Body bold>{tag.value}</Body>
                          </Checkbox>
                        ))}
                      </FlexBox>
                    </AccordionDetails>
                  </Accordion>
                ) : <></>
              ))}
            </FlexBox>
      }
            
      <div style={{width: "100%", paddingTop: "4rem"}}>
        <Button width="100%" use="primary" disabled={isLoading} onClick={updateCurrentSearch}>Done</Button>
      </div>
      
    </Modal>
  );

};

export default MobileSearchFilter;