import { CognitoUserInterface } from "interfaces/Cognito";
import ASYNC_STATUS from "../../constants/asyncStatus";
import AUTH_STATE from "../../constants/authState";
import MFA_CHOICE from "../../constants/mfaChoice";
import { Auth } from "aws-amplify";

export const mockAuthFlow = () => {
  jest.mock("aws-amplify");
  jest.spyOn(Auth, "currentSession").mockReturnValue({
    // @ts-ignore
    getIdToken: () => ({
      getJwtToken: () => "Secret-Token",
    }),
  });
};

export const mockAuthContext = {
  email: "email@email.com",
  setEmail: () => {},
  phone: "1234567",
  setPhone: () => {},
  password: "password",
  setPassword: () => {},
};

export const mockCountries = [
  {
    id: "0",
    code: "US+1",
    phoneCode: 1,
    name: "United States",
    plusPhoneCode: "+1",
  },
  {
    id: "1",
    code: "AU+61",
    phoneCode: 61,
    name: "Australia",
    plusPhoneCode: "+61",
  },
  {
    id: "2",
    code: "CA+1",
    phoneCode: 1,
    name: "Canada",
    plusPhoneCode: "+1",
  },
  {
    id: "3",
    code: "MX+64",
    phoneCode: 64,
    name: "Mexico",
    plusPhoneCode: "+64",
  },
  {
    id: "4",
    code: "NZ+61",
    phoneCode: 61,
    name: "New Zealand",
    plusPhoneCode: "+61",
  },
];

export const mockEmploymentTitles = [
  {
    id: "0",
    name: "Agency creator",
  },
  {
    id: "1",
    name: "Agency CX/tech",
  },
  {
    id: "2",
    name: "Device, Accessory & OEM partners",
  },
  {
    id: "3",
    name: "Verizon partner/sponsorship",
  },
  {
    id: "4",
    name: "Verizon partner/media",
  },
  {
    id: "5",
    name: "Vendor/supplier",
  },
  {
    id: "6",
    name: "Other",
  },
];

export const mockUser = {
  username: "mock.email@gmail.com",
  attributes: { "custom:sms_config_skips": 0 },
  challengeName: "CUSTOM_CHALLENGE",
  challengeParam: {
    authStep: "MFA_CHOICE",
    userEmail: "*****ail.com",
    userPhone: "*****1234",
  },
} as CognitoUserInterface;

export const mockUserNoPhone = {
  username: "mock.email@gmail.com",
  attributes: {},
  challengeName: "CUSTOM_CHALLENGE",
  challengeParam: {
    authStep: "MFA_CHOICE",
    userEmail: "*****ail.com",
  },
} as CognitoUserInterface;

export const mockAuthState = {
  status: ASYNC_STATUS.IDLE,
  authState: AUTH_STATE.MFA_CHOICE,
  user: mockUser,
  mfaChoice: MFA_CHOICE.EMAIL,
  notification: { type: undefined, message: "" },
};
