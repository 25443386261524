import { ButtonWrapper } from "./Button.module";
import {
  Button as VdsButton,
  ButtonProps as VdsButtonProps,
} from "@vds/buttons";
import { ButtonProps } from "interfaces";

const Button = ({
  children,
  use = "primary",
  pd,
  ariaLabel,
  searchButton,
  testId,
  ...rest
}: VdsButtonProps & ButtonProps) => {
  return (
    <ButtonWrapper searchButton={searchButton} pd={pd}>
      <VdsButton data-testid={testId} use={use} {...rest} ariaLabel={ariaLabel} >
        {children}
      </VdsButton>
    </ButtonWrapper>
  );
};

export default Button;
