import { Await, useLoaderData } from "react-router-dom";
import ErrorBoundary from "views/ErrorBoundary/ErrorBoundary";

interface stringIndex {
  [key: string]: string;
}

const withLoaderData = (name: string) => (WrappedComponent: any) => {
  const Component = (props: any) => {
    const { [name]: data } = useLoaderData() as any;
    return (
      <Await resolve={data} errorElement={<ErrorBoundary />}>
        {(data) => {
          let dynamicProp: stringIndex = {};
          dynamicProp[name] = data;

          return <WrappedComponent {...dynamicProp} {...props} />;
        }}
      </Await>
    );
  };

  return Component;
};

export default withLoaderData;
