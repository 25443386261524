import React from 'react';
import PropTypes from 'prop-types';
import { Feature as Feature$1 } from '@vds-core/typography';

const propTypes = {
  /**
   * If enabled, Feature will be rendered in bold
   */
  bold: PropTypes.bool
};
const Feature = function (props) {
  return React.createElement(Feature$1, Object.assign({}, props, {
    regularWeight: "light",
    letterSpacing: null
  }));
};
Feature.propTypes = propTypes;

export { Feature as default };
