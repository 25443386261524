import styled from "styled-components";
import FlexBox from "components/layouts/FlexBox/FlexBox";

interface props {
  white?: boolean;
  margin?: boolean;
}

export const StyledSelected = styled(({ white, margin, ...props }) => (
  <FlexBox {...props} />
))<props>`
  width: 100%;

  background-color: ${(props: props) =>
    props.white ? "#FFFFFF" : "rgb(246, 246, 246)"};

  border-radius: 0.25rem;

  margin-top: ${(props: props) => (props.margin ? "0.25rem" : 0)};
  padding: 0.5rem;
`;

export const StyledSelectText = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
`;
