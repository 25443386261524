import styled from "styled-components";
import { PlaylistContainerProps } from "./PlaylistContainer";

export const StyledContainer = styled(({ ...props }) => (
  <div {...props} />
))<PlaylistContainerProps>`
  position: relative;

  display: flex;
  flex-direction: column;

  height: 29.5rem;

  overflow-y: scroll;

  gap: 1rem;
  padding-top: 1rem;
  border-top: ${(props: PlaylistContainerProps) => props.videosPg ? "1px solid #d8dada" : "unset"};
  border-bottom: ${(props: PlaylistContainerProps) => props.videosPg ? "1px solid #d8dada" : "unset"};

  @media only screen and (max-width: 1440px) {
    height: 25rem;
  }

  @media only screen and (max-width: 1180px) {
    height: ${(props: PlaylistContainerProps) => props.videosPg ? "13rem" : "unset"};
    overflow-y: ${(props: PlaylistContainerProps) => props.videosPg ? "scroll" : "unset"};
    padding-bottom: 1.5rem;
    
  }
`;
