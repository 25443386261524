import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TypographyTokens } from '@vds-tokens/typography';
import { ColorTokens } from '@vds-tokens/color';
import { withVDSManager } from '@vds-core/utilities';
import { Fonts } from '../../fonts/index.js';
import Typography from '../Typography/Typography.js';

const TITLE_SIZES = ['small', 'medium', 'large', 'XLarge', '2XLarge'];
const propTypes = {
  /**
   * Primitive used for the component.
   */
  primitive: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p']),
  /**
   * Viewport the font styling is based on. Desktop and tablet are the same size.
   */
  viewport: PropTypes.oneOf(['desktop', 'mobile', 'tablet']),
  /**
   * Size of the typescale that will be used.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large', 'XLarge', '2XLarge']),
  /**
   * Content that will be passed to the component.
   */
  children: PropTypes.node,
  /**
   * Color of the text.
   */
  color: PropTypes.oneOf(['#000000', '#FFFFFF', '#ffffff', '#EE0000', '#ee0000', '#F6F6F6', '#f6f6f6', '#D8DADA', '#d8dada', '#A7A7A7', '#a7a7a7', '#6F7171', '#6f7171', '#333333', '#1B1D1F', '#1b1d1f', '#ffece0', '#FFECE0', '#ffcaaa', '#FFCAAA', '#ffa46d', '#FFA46D', '#ff8027', '#FF8027', '#b95319', '#B95319', '#732706', '#561701', '#fff9de', '#FFF9DE', '#fff4bc', '#FFF4BC', '#ffe97a', '#FFE97A', '#fed60e', '#FED60E', '#bc9f0a', '#BC9f0A', '#635305', '#4b3f04', '#4B3F04', '#e3f2fd', '#E3F2FD', '#aad8f9', '#AAD8F9', '#4aabf2', '#4AABF2', '#0089ec', '#0089EC', '#006fc1', '#006FC1', '#003e6c', '#003E6C', '#002c4d', '#002C4D', '#dcf5e6', '#DCF5E6', '#a4e6bd', '#A4E6BD', '#63d48e', '#63D48E', '#00b845', '#00B845', '#008331', '#004b1c', '#004B1C', '#003514', '#febfe8', '#FEBFE8', '#fc89d5', '#FC89D5', '#fb42bc', '#FB42BC', '#b9318b', '#B9318B', '#671b4e', '#671B4E', '#edb9fb', '#EDB9FB', '#e084f9', '#E084F9', '#ce3df5', '#CE3DF5', '#84279e', '#84279E', '#461553', '#FBE4D7', '#fbe4d7', '#ED7000', '#ed7000', '#C44904', '#c44904', '#4A1C02', '#4a1c02', '#FFF4E0', '#fff4e0', '#FFBC3D', '#ffbc3d', '#523C14', '#523c14', '#D6EEFB', '#d6eefb', '#0096E4', '#0096e4', '#0077B4', '#0077b4', '#002B42', '#002b42', '#D6F2E0', '#d6f2e0', '#00AC3E', '#00ac3e', '#008330', '#003614', '#747676' // EOL BRand1.0 Coolgray6
  ]),

  /**
   * @ignore
   * If enabled, Title will be rendered in bold
   */
  bold: PropTypes.bool,
  /**
   * The tabIndex of the text.
   */
  tabIndex: PropTypes.number,
  /**
   * @ignore
   */
  regularWeight: PropTypes.oneOf(['regular', 'light']),
  /**
   * Assigns an Id to the component
   */
  id: PropTypes.string,
  /**
   * Assigns an testid to the component
   */
  'data-testid': PropTypes.string,
  /**
   * Adds an aria-labelledby attribute to the component
   */
  ariaLabelledBy: PropTypes.string,
  /**
   * @ignore
   */
  letterSpacing: PropTypes.string
};
const defaultProps = {
  viewport: 'desktop',
  size: 'small',
  children: null,
  color: ColorTokens.palette.black.value,
  bold: false,
  letterSpacing: null,
  lineHeight: null,
  fontSize: null
};
const primitiveMap = {
  small: 'h5',
  medium: 'h4',
  large: 'h3',
  XLarge: 'h2',
  '2XLarge': 'h1'
};
const StyledTitle = styled(Typography).withConfig({
  displayName: "StyledTitle",
  componentId: "VDS__sc-155xzr0-0"
})`
  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
`;
const Title = props => {
  const {
    children,
    primitive: primitiveProp,
    viewport,
    size,
    color,
    bold,
    tabIndex,
    id,
    ariaLabelledBy,
    fontSize,
    lineHeight,
    letterSpacing,
    regularWeight,
    'data-testid': testId
  } = props;
  const viewportToUse = viewport === 'mobile' ? 'mobile' : 'desktop';
  const fontSizeToUse = TITLE_SIZES.indexOf(size) > -1 ? size : 'small';
  function _setWeight() {
    if (bold) {
      return TypographyTokens.fontweight.bold.value;
    } else if (regularWeight) {
      return TypographyTokens.fontweight[regularWeight].value;
    } else {
      if (size === 'small' || size === 'medium' || size === 'large' && viewportToUse === 'mobile') {
        return TypographyTokens.fontweight.regular.value;
      } else {
        return TypographyTokens.fontweight.light.value;
      }
    }
  }
  const ls = letterSpacing ? letterSpacing : bold || size === 'medium' || size === 'small' || size === 'large' && viewport === 'mobile' ? null : TypographyTokens.letterspacing.semiwide.value;
  function _setPrimitive() {
    if (fontSizeToUse) {
      return primitiveMap[fontSizeToUse];
    } else {
      return 'h5';
    }
  }
  let primitive = primitiveProp ? primitiveProp : _setPrimitive();
  const getFontSize = () => {
    switch (fontSizeToUse) {
      case '2XLarge':
        return viewportToUse === 'mobile' ? 40 : 64;
      case 'XLarge':
        return viewportToUse === 'mobile' ? 32 : 48;
      case 'large':
        return viewportToUse === 'mobile' ? 24 : 32;
      case 'medium':
        return viewportToUse === 'mobile' ? 20 : 24;
      case 'small':
      default:
        return viewportToUse === 'mobile' ? 16 : 20;
    }
  };
  const getLineHeight = () => {
    switch (fontSizeToUse) {
      case '2XLarge':
        return viewportToUse === 'mobile' ? 40 : 64;
      case 'XLarge':
        return viewportToUse === 'mobile' ? 36 : 48;
      case 'large':
        return viewportToUse === 'mobile' ? 28 : 36;
      case 'medium':
        return viewportToUse === 'mobile' ? 24 : 28;
      case 'small':
      default:
        return viewportToUse === 'mobile' ? 20 : 24;
    }
  };
  return React.createElement(StyledTitle, {
    fontSize: fontSize ? fontSize : TypographyTokens.fontsize.title[getFontSize()].value,
    fontWeight: _setWeight(),
    fontFamily: Fonts.VerizonNHGeDS,
    lineheight: lineHeight ? lineHeight : TypographyTokens.lineheight.title[getLineHeight()].value,
    letterSpacing: ls,
    primitive: primitive,
    color: color,
    tabIndex: tabIndex,
    "aria-hidden": tabIndex < 0,
    id: id,
    "aria-labelledby": ariaLabelledBy,
    "data-testid": testId
  }, children);
};
Title.propTypes = propTypes;
Title.defaultProps = defaultProps;
var Title$1 = withVDSManager(Title);

export { Title$1 as default };
