import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useAccessRequestsMutation } from "services/api/api.slice";
import {
  Body,
  Button,
  ButtonGroup,
  DatePicker,
  FlexBox,
  Form,
  Image,
  Input,
  Micro,
  Modal,
  RadioGroup,
  Select,
  TextButton,
  Title,
} from "components";
import { FormikProps } from "interfaces";
import { AccessModalProps } from "interfaces/Modal";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import { ParsedUserJWT } from "../../interfaces/Cognito";
import { useSelector } from "../../hooks/redux";
import { selectCurrentUserDetails } from "../../services/auth/auth.slice";

const AccessModal = ({
  asset,
  data,
  loading,
  onSuccess,
  onClose,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  isValid,
  dirty,
  resetForm,
  touched,
  errors,
  setTouched,
  setErrors,
  expiresOn,
  expiresWithin90,
}: AccessModalProps & FormikProps) => {
  const dispatch = useDispatch();

  const userDetails: ParsedUserJWT | null | undefined = useSelector(
    selectCurrentUserDetails
  );

  const [requestAccess, { isLoading: requestLoading, isSuccess, error }] =
    useAccessRequestsMutation();

  const isLoading = loading || requestLoading;

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const radioOptions = [
      { value: 1, label: "Yes" },
      { value: 0, label: "No" },
    ],
    handleOnChange = (selected: number) => () => {
      setFieldValue("isPhotographyAsset", radioOptions[selected].value);
      if (selected === 1) {
        setTouched({});
        setErrors({});
      }
    };

  const [channelId, _channelId] = useState<string>(""),
    handleChannelId = () => {
      const selected = data.channels.find(
        (channel: any) => channel.id === values.channelId
      );
      if (selected !== "Other") {
        setFieldValue("otherChannel", "");
      }
      _channelId(selected.value);
    };

  const [requestedUseId, _requestedUseId] = useState<string>(""),
    handleRequestedUseId = () => {
      const selected = data.requestedUses.find(
        (use: any) => use.id === values.requestedUseId
      );
      if (selected !== "Other") {
        setFieldValue("otherRequestedUse", "");
      }
      _requestedUseId(selected.value);
    };

  const handleStartDate = (e: any) => {
      setFieldValue("startDate", e);
    },
    handleEndDate = (e: any) => {
      setFieldValue("endDate", e);
    };
  const [searchIndex, _searchIndex] = useState<string>("assets");
  const getSearchIndex = () => {
    const currentUrl: string = window.location.toString();
    if (
      currentUrl.includes(
        "/retail/branded-environments/design-advisories/details/"
      )
    ) {
      _searchIndex("design-advisories");
    } else if (currentUrl.includes("/templates/details/")) {
      _searchIndex("templates");
    } else if (currentUrl.includes("/assets/details/")) {
      _searchIndex("assets");
    }
  };

  useEffect(() => {
    if (!loading) {
      getSearchIndex();
    }
  }, [loading]);

  const handleOnSubmit = () => {
      requestAccess({
        assetId: asset.id ? asset.id : "",
        payload: {
          audience: values.audience,
          channelId: values.channelId,
          inMarketEndDate: !!values.endDate
            ? new Date(values.endDate)
            : undefined,
          inMarketStartDate: !!values.startDate
            ? new Date(values.startDate)
            : undefined,
          isPhotographyAsset: Boolean(values.isPhotographyAsset),
          otherChannel: values.otherChannel,
          otherRequestedUse: values.otherRequestedUse,
          projectName: values.projectName,
          requestedUseId: values.requestedUseId,
          assetType: searchIndex,
        },
      })
        .unwrap()
        .then(handleOnSuccess)
        .catch((err: any) => handleOnError(err));
    },
    handleOnSuccess = () => {
      dispatch(
        setNotification({
          type: "success",
          message: "Asset access has been requested.",
        })
      );
      onSuccess();
    },
    handleOnError = (err: any) => {
      dispatch(
        setNotification({
          type: "error",
          message: err?.message || "An error has occurred. Please try again.",
        })
      );
    };

  useEffect(() => {
    if (values.channelId) {
      handleChannelId();
    }
  }, [values.channelId]);

  useEffect(() => {
    if (values.requestedUseId) {
      handleRequestedUseId();
    }
  }, [values.requestedUseId]);

  const handleClearStartDate = () => {
    setFieldValue("startDate", null);
  };
  const handleClearEndDate = () => {
    setFieldValue("endDate", null);
  };

  const isPhotoSelected = values.isPhotographyAsset !== null;

  return (
    <Modal testId="access-modal" open onClose={handleClose} disabled={isLoading}>
      <Grid container spacing={1}>
        {asset.files && (
          <Grid item xs={3}>
            <Image src={asset.files[0].thumbnails?.small} />
          </Grid>
        )}
        <Grid item xs={9}>
          <FlexBox gap="1.5rem">
            <Title size="large" bold>
              Request Access Form
            </Title>
            <div>
              <Body size="large" pd="0 0 0.5rem">
                Please describe why you need this asset and how you will use it.
              </Body>
              <Body size="large">
                All fields required unless otherwise indicated.
              </Body>
            </div>
            <Form onSubmit={handleOnSubmit} gap="1rem">
              <RadioGroup
                title={
                  <Body pd="0 0 0.5rem 0">
                    Is this request for a photography asset?
                  </Body>
                }
                name="isPhotographyAsset"
                options={radioOptions}
                value={values.isPhotographyAsset}
                onChange={(selected: number) => handleOnChange(selected)}
              />
              <Input
                label="Who is the audience for this asset?"
                name="audience"
                value={values.audience}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.audience && !!errors.audience}
                errorText={errors.audience}
                helperText="VCG, VBG, CMG, customers, social followers, etc."
                disabled={isLoading || !isPhotoSelected}
                data-testid="asset-audience"
              />
              <Select
                items={data.channels}
                label="In what channel will this asset be used?"
                name="channelId"
                value={values.channelId}
                onChange={handleChange}
                optionLabels={["value"]}
                // error={touched.channelId && !!errors.channelId}
                errorText={errors.channelId}
                disabled={isLoading || !isPhotoSelected}
                data-testid="asset-channel-id"
                includeEmpty
              />
              {channelId === "Other" && (
                <Input
                  label="Other - please specify"
                  name="otherChannel"
                  value={values.otherChannel} //channelId === "Other" ? values.otherChannel : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.otherChannel && !!errors.otherChannel
                    // && channelId === "Other"
                  }
                  errorText={errors.otherChannel}
                  disabled={isLoading || !isPhotoSelected}
                  data-testid="asset-other-channel"
                />
              )}
              <Select
                items={data.requestedUses}
                label="In which medium will this asset be used?"
                name="requestedUseId"
                value={values.requestedUseId}
                onChange={handleChange}
                optionLabels={["value"]}
                errorText="You must enter this field"
                disabled={isLoading || !isPhotoSelected}
                data-testid="asset-requested-use-id"
                includeEmpty
              />
              {requestedUseId === "Other" && (
                <Input
                  label="Other - please specify"
                  name="otherRequestedUse"
                  value={values.otherRequestedUse}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.otherRequestedUse && !!errors.otherRequestedUse
                    // && requestedUseId === "Other"
                  }
                  errorText={errors.otherRequestedUse}
                  disabled={isLoading || !isPhotoSelected}
                  data-testid="asset-other-requested-use"
                />
              )}
              <Input
                label="Please list the project name and describe where this asset will be used."
                name="projectName"
                value={values.projectName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(touched.projectName && !!errors.projectName) || false}
                errorText={errors.projectName}
                helperText="Campaign name, article, website link, specific banner, etc."
                disabled={isLoading || !isPhotoSelected}
                data-testid="asset-project-name"
                required={values.isPhotographyAsset !== 0}
              />
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item md={12} lg={6} width="100%">
                  <DatePicker
                    value={values.startDate}
                    name="startDate"
                    label="In-market start date"
                    onChange={handleStartDate}
                    disabled={isLoading || !isPhotoSelected}
                    testId="asset-start-date"
                    minDate={new Date()}
                    onClear={handleClearStartDate}
                    optional={values.isPhotographyAsset === 0}
                  />
                  {expiresWithin90 && (
                    <Micro color="#747676">
                      Please note this asset expires on {expiresOn}.
                    </Micro>
                  )}
                </Grid>
                <Grid item md={12} lg={6} width="100%">
                  <DatePicker
                    value={values.endDate}
                    name="endDate"
                    label="In-market end date"
                    onChange={handleEndDate}
                    disabled={
                      isLoading || !isPhotoSelected || !values.startDate
                    }
                    testId="asset-end-date"
                    minDate={values.startDate ? values.startDate : new Date()}
                    onClear={handleClearEndDate}
                    optional={values.isPhotographyAsset === 0}
                  />
                </Grid>
              </Grid>
              <FlexBox gap="0.5rem" pd="1.5rem 0 0">
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} md={3}>
                    <Body bold size="large">
                      Asset ID:
                    </Body>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Body size="large">{asset.id}</Body>
                  </Grid>
                </Grid>
                {asset.isRestricted && (
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={3}>
                      <Body bold size="large">
                        Asset approver:
                      </Body>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Body size="large">{`${asset.restrictedApprover?.firstName} ${asset.restrictedApprover?.lastName} (${asset.restrictedApprover?.emailAddress})`}</Body>
                    </Grid>
                  </Grid>
                )}
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} md={3}>
                    <Body bold size="large">
                      Requested for:
                    </Body>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Body size="large">{`${userDetails?.firstName} ${userDetails?.lastName} (${userDetails?.email})`}</Body>
                  </Grid>
                </Grid>
              </FlexBox>
              <ButtonGroup pd="1.5rem 0 0 0">
                <Button
                  type="submit"
                  use="primary"
                  disabled={!(dirty && isValid) || isLoading}
                  data-testid="submit-button"
                >
                  Request
                </Button>
                <TextButton
                  onClick={handleClose}
                  disabled={isLoading}
                  data-testid="cancel-button"
                >
                  Cancel
                </TextButton>
              </ButtonGroup>
            </Form>
          </FlexBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AccessModal;
