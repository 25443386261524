import React from "react";
import { StyledTag } from "./Tag.module";

export type TagProps = {
  children: React.ReactNode;
};

const Tag = ({ children }: TagProps) => {
  return <StyledTag>{children}</StyledTag>;
};

export default Tag;
