export interface FormValues {
  groupName: string;
  companyName: string;
}
export default function mapPropsToValues() {
  return {
    groupName: "",
    companyName: "",
  };
}
