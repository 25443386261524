import { __rest } from '../../_virtual/_tslib.js';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { calculateRem } from '@vds-core/utilities';
import Scrollbars from './Scrollbars.js';
import { ColorTokens } from '@vds-tokens/color';

const _calculateTrackColor = surface => {
  let trackColor;
  if (surface === 'dark') {
    trackColor = ColorTokens.interactive.scrolltrack.ondark.value;
  } else {
    trackColor = ColorTokens.interactive.scrolltrack.onlight.value;
  }
  return trackColor;
};
const _calculateThumbColor = (surface, hover) => {
  let thumbColor;
  if (surface === 'dark' && !hover) {
    thumbColor = ColorTokens.interactive.scrollthumb.ondark.value;
  } else if (hover && surface !== 'dark') {
    thumbColor = ColorTokens.interactive.scrollthumb.hover.onlight.value;
  } else if (hover && surface === 'dark') {
    thumbColor = ColorTokens.interactive.scrollthumb.hover.ondark.value;
  } else {
    thumbColor = ColorTokens.interactive.scrollthumb.onlight.value;
  }
  return thumbColor;
};
const Wrapper = styled.span.withConfig({
  displayName: "Wrapper",
  componentId: "VDS__sc-81vy1k-0"
})`
  height: ${({
  height
}) => height};
  width: ${({
  width
}) => width};
  &&,
  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const HitArea = styled.span.withConfig({
  displayName: "HitArea",
  componentId: "VDS__sc-81vy1k-1"
})`
  position: absolute;
  top: 0;
  right: -22px;
  width: 48px;
  cursor: grab;
  height: 100%;
`;
const propTypes = {
  wrapperStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  surface: PropTypes.oneOf(['light', 'dark']),
  height: PropTypes.string,
  width: PropTypes.string,
  scrollbarId: PropTypes.string,
  borderRadius: PropTypes.string,
  calculateTrackColor: PropTypes.func,
  calculateThumbColor: PropTypes.func
};
const defaultProps = {
  calculateTrackColor: _calculateTrackColor,
  calculateThumbColor: _calculateThumbColor,
  borderRadius: calculateRem(2)
};
class ScrollWrapper extends React.Component {
  constructor(props) {
    super(props);
    this._handleUpdate = values => {
      const {
        top,
        scrollHeight,
        clientHeight
      } = values;
      if (this.state.top !== top || this.state.scrollHeight !== scrollHeight || this.state.clientHeight !== clientHeight) {
        this.setState({
          top,
          scrollHeight,
          clientHeight
        });
      }
    };
    this._thumbHeight = () => {
      const {
        clientHeight,
        scrollHeight
      } = this.state;
      return scrollHeight ? parseInt(clientHeight * this.state.clientHeight / scrollHeight) : 0;
    };
    this._changeBackground = () => {
      this.setState({
        hover: !this.state.hover
      });
    };
    // MARK - Render Function Components
    this.View = _a => {
      var {
          style,
          calculateThumbColor,
          calculateTrackColor,
          borderRadius,
          surface
        } = _a,
        rest = __rest(_a, ["style", "calculateThumbColor", "calculateTrackColor", "borderRadius", "surface"]);
      const viewStyle = {
        display: 'flex',
        boxSizing: 'border-box',
        overflowX: 'hidden',
        margin: 0,
        width: '100%',
        height: '100%'
      };
      return React.createElement("div", Object.assign({
        id: `scrollbar-view-${this.props.scrollbarId}`,
        style: Object.assign(Object.assign(Object.assign({}, style), viewStyle), this.props.contentStyle)
      }, rest));
    };
    this.Track = _a => {
      var {
          style,
          calculateThumbColor,
          calculateTrackColor,
          borderRadius,
          surface
        } = _a,
        rest = __rest(_a, ["style", "calculateThumbColor", "calculateTrackColor", "borderRadius", "surface"]);
      const hideScroll = this._thumbHeight() >= this.state.clientHeight;
      const trackStyle = {
        backgroundColor: this.props.calculateTrackColor(this.props.surface),
        display: hideScroll ? 'none' : 'block',
        top: 0,
        right: 0,
        bottom: 0,
        width: 4,
        cursor: 'pointer',
        borderRadius: this.props.borderRadius,
        overflow: 'hidden'
      };
      return React.createElement("span", Object.assign({
        id: `scrollbar-track-${this.props.scrollbarId}`,
        style: Object.assign(Object.assign({}, style), trackStyle),
        onMouseOver: this._changeBackground,
        onMouseOut: this._changeBackground
      }, rest, {
        "aria-hidden": true
      }));
    };
    this.Thumb = _a => {
      var {
          style,
          calculateThumbColor,
          calculateTrackColor,
          borderRadius,
          surface
        } = _a,
        rest = __rest(_a, ["style", "calculateThumbColor", "calculateTrackColor", "borderRadius", "surface"]);
      const {
        hover
      } = this.state;
      const thumbStyle = {
        backgroundColor: this.props.calculateThumbColor(this.props.surface, hover),
        height: '100%',
        width: 4,
        left: '22px',
        borderRadius: this.props.borderRadius
      };
      const grabZoneStyle = {
        cursor: 'pointer',
        width: 48,
        left: '-22px',
        zIndex: 1,
        textAlign: 'center'
      };
      return React.createElement("span", Object.assign({
        style: Object.assign(Object.assign({}, style), grabZoneStyle),
        onMouseOver: this._changeBackground,
        onMouseOut: this._changeBackground
      }, rest), React.createElement("span", Object.assign({
        style: Object.assign(Object.assign({}, style), thumbStyle)
      }, rest)));
    };
    this.state = {
      clientHeight: 0,
      scrollHeight: 0,
      hover: false,
      top: 0,
      scroll: false
    };
    this.scrollRef = null;
  }
  render() {
    const _a = this.props,
      rest = __rest(_a, ["contentStyle", "calculateThumbColor", "calculateTrackColor", "borderRadius", "surface"]);
    return React.createElement(Wrapper, {
      height: this.props.height,
      width: this.props.width
    }, React.createElement(Scrollbars, Object.assign({
      style: Object.assign(Object.assign({}, this.props.wrapperStyle), {
        height: this.props.height,
        width: this.props.width
      }),
      id: `scrollview-${this.props.scrollbarId}`,
      ref: el => this.scrollRef = el,
      renderView: this.View,
      renderTrackVertical: this.Track,
      renderThumbVertical: this.Thumb,
      renderTrackHorizontal: () => React.createElement("span", {
        "aria-hidden": true
      }),
      renderThumbHorizontal: () => React.createElement("span", null),
      renderHitArea: () => React.createElement(HitArea, {
        onMouseOver: this._changeBackground,
        onMouseOut: this._changeBackground
      }),
      onUpdate: this._handleUpdate
    }, rest), this.props.children));
  }
}
ScrollWrapper.defaultProps = defaultProps;
ScrollWrapper.propTypes = propTypes;

export { ScrollWrapper as default };
