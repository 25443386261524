import styled from "styled-components";
import FlexBox from "components/layouts/FlexBox/FlexBox";
import { Form } from "components";

export const StyledFlexBox = styled(FlexBox)`
  border-left: #d8dada 0.063rem solid;
`;

export const DragWrapper = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
`;

export const StyledForm = styled(Form)`
  background: #f6f6f6;
  border-radius: 0.5rem;
`;

export const StyledSpan = styled.span`
  font-family: Verizon-NHG-eDS, Helvetica, Arial, Sans-serif;
`;
