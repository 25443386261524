import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import mapPropsToValues, { FormProps } from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import GlobalSearch from "./GlobalSearch";
import { GlobalSearchProps } from "interfaces";

export const GlobalSearchContainer = compose(
  withFormik<GlobalSearchProps, FormProps>({
    mapPropsToValues,
    validationSchema,
    handleSubmit: () => null,
    validateOnMount: true,
  })
)(GlobalSearch);

export default GlobalSearchContainer;
