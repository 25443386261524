import { BodyProps } from "@vds/typography";
import { StyledLink, StyledExternalLink } from "./TextLink.module";
// import { Body } from "components";
import Body from "components/typography/Body/Body";
import { externalRoute } from "utils";

export interface TextLinkProps {
  to: string;
  align?: "flex-start" | "center" | "flex-end";
  disabled?: boolean;
  children: any;
  display?: string;
  bold?: boolean;
  size?: "small" | "medium" | "large" | "XLarge";
  className?: string;
  color?: BodyProps["color"];
  rel?: string;
  newTab?: boolean;
  noUnderline?: boolean;
  underlineOnHover?: boolean;
  state?: any;
  onClick?: any;
  textAlign?: "left" | "center" | "right";
  inline?: boolean;
  external?: boolean;
  testId?: string;
}

const TextLink = ({
  display,
  disabled,
  children,
  align,
  bold,
  size,
  className,
  color,
  rel,
  newTab,
  noUnderline,
  onClick,
  textAlign,
  inline,
  external,
  to,
  testId,
  underlineOnHover,
  ...rest
}: TextLinkProps) => {
  return external ? (
    <StyledExternalLink
      href={externalRoute(to)}
      to="#"
      className={`${disabled ? "disabled" : ""} ${className ? className : ""} ${
        noUnderline ? "no-underline" : ""
      } ${underlineOnHover ? "underline-on-hover" : ""}`}
      color={color}
      rel={"external noopener noreferrer"}
      target={newTab ? "_blank" : ""}
      display={display}
      bold={bold}
      disabled={disabled}
      align={align}
      onClick={onClick}
      data-testid={testId}
      {...rest}
    >
      <Body inline={inline} textAlign={textAlign} color={color}>
        {children}
      </Body>
    </StyledExternalLink>
  ) : (
    <StyledLink
      className={`${disabled ? "disabled" : ""} ${className ? className : ""} ${
        noUnderline ? "no-underline" : ""} ${underlineOnHover ? "underline-on-hover" : ""}
      `}
      color={color}
      rel={rel}
      to={to}
      target={newTab ? "_blank" : ""}
      display={display}
      bold={bold}
      size={size}
      disabled={disabled}
      align={align}
      onClick={onClick}
      data-testid={testId}
      {...rest}
    >
      <Body inline={inline} textAlign={textAlign} color={color}>
        {children}
      </Body>
    </StyledLink>
  );
};

export default TextLink;
