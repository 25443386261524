import {AssetFile, AssetUserActions, DownloadOptionsModalProps, withVDSManagerProps} from "../../interfaces";
import {Body, Button, FlexBox, IconButton, TextButton} from "../../components";
import {formatAllFileSize, formatFileSize, getFileCurrentVersion, getFileName, openFileDownload} from "../../utils/assetFiles";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setNotification, useGetFeedbackOptionsQuery, useLazyGetAssetDownloadUrlQuery, useLazyGetFileDownloadUrlQuery, useTrackingMutation} from "../../services";
import {useLocation} from "react-router-dom";
import {buildFinalTrackingObject} from "../../utils";
import UsageRightsModal from "../usageRightsModal/UsageRightsModal";
import Modal from "../../components/modals/Modal/Modal";
import {StyledListboxContainer} from "./MobileDownloadOptionsModal.module";
import SatisfiedFeedback from "../satisfiedFeedback/SatisfiedFeedback";

export interface MobileDownloadOptionsModalProps {
  files: AssetFile[];
  onClose: Function;
  userActions: AssetUserActions;
  isExpired: boolean;
  assetId: string;
  correlationId?: string;
  trackingData?: Record<string, unknown>;
  usageRightsCopy?: string;
}

const MobileDownloadOptionsModal = ({
  files,
  onClose,
  isExpired,
  userActions,
  assetId,
  correlationId,
  trackingData,
  usageRightsCopy,
}: MobileDownloadOptionsModalProps) => {
  
  const handleClose = () => {
    onClose();
  };
  
  const [fileVersionId, setFileVersionId] = useState<string>("");
  
  const dispatch = useDispatch();
  
  const [
    getAssetUrl,
    {
      data: assetResult,
      isFetching: assetFetching,
      isError: assetError,
      error: assetErrorMsg,
      isSuccess: assetSuccess,
    },
  ] = useLazyGetAssetDownloadUrlQuery();
  const [
    getFileUrl,
    {
      data: fileResult,
      isFetching: fileFetching,
      isError: fileError,
      error: fileErrorMsg,
      isSuccess: fileSuccess,
    },
  ] = useLazyGetFileDownloadUrlQuery();
  const {
    data: feedbackOptions = [],
    isError: feedbackOptionsError,
    error: feedbackOptionsErrorMsg,
  } = useGetFeedbackOptionsQuery();
  
  const isFetching = assetFetching || fileFetching;
  
  const [trackAction, isLoading] = useTrackingMutation();
  
  const location = useLocation();
  
  const trackEvent = (action: string, entityType: string, id: string): void => {
    const payloadData = {
      version: "1.0.0",
      context: trackingData,
      type: "download-listbox",
      action,
      entityType,
      id,
    };
    const trackingObject = buildFinalTrackingObject(
      payloadData,
      location.pathname,
      correlationId
    );
    trackAction(trackingObject)
    .unwrap()
    .catch((error: any) => {
      console.error(error);
    });
  };
  
  const callGetFileUrl = (fileVersionId: string) => {
    trackEvent("download-single-file", "asset-file", fileVersionId);
    getFileUrl({ assetId, fileVersionId })
    .unwrap()
    .catch((error: unknown) => {
      dispatch(
        setNotification({
          type: "error",
          message: "Could not download file.",
        })
      );
      console.error(error);
    });
  };
  
  const callGetAssetUrl = (assetId: string) => {
    trackEvent("download-asset", "asset", assetId);
    getAssetUrl(assetId)
    .unwrap()
    .catch((error: unknown) => {
      dispatch(
        setNotification({
          type: "error",
          message: "Could not download asset.",
        })
      );
      console.error(error);
    });
  };
  
  const [showSatisfiedFeedback, setShowSatisfiedFeedback] =
      useState<boolean>(false),
    openSatisfiedFeedback = () => {
      if (!satisfiedFeedbackSeen) {
        setShowSatisfiedFeedback(true);
      }
    },
    closeSatisfiedFeedback = () => {
      setShowSatisfiedFeedback(false);
      _satisfiedFeedbackSeen(true);
    };
  
  const [satisfiedFeedbackSeen, _satisfiedFeedbackSeen] =
    useState<boolean>(false);
  
  const [usageRightsModalOpen, setUsageRightsModalOpen] =
      useState<boolean>(false),
    openUsageRightsModal = () => {
      setUsageRightsModalOpen(true);
    },
    completeUsageRightsModal = () => {
      updateUsageRights(true);
      setUsageRightsModalOpen(false);
      callGetFileUrl(fileVersionId);
    },
    closeUsageRightsModal = () => {
      setUsageRightsModalOpen(false);
    };
  
  const [rightsAccepted, setRightsAccepted] = useState<boolean>(
      usageRightsCopy &&
      userActions &&
      userActions.hasAcceptedUsageRights !== null
        ? userActions.hasAcceptedUsageRights
        : true
    ),
    updateUsageRights = (value: boolean) => {
      setRightsAccepted(value);
    };
  
  const onDownloadFileClick = (file: AssetFile) => () => {
    if (!file.currentVersionId) {
      return;
    }
    if (usageRightsCopy && !rightsAccepted) {
      setFileVersionId(file.currentVersionId);
      openUsageRightsModal();
    } else {
      callGetFileUrl(file.currentVersionId);
    }
  };
  
  const onDownloadAssetClick = () => {
    if (usageRightsCopy && usageRightsCopy.length > 0 && !rightsAccepted) {
      openUsageRightsModal();
    } else {
      callGetAssetUrl(assetId);
    }
  };
  
  useEffect(() => {
    if (feedbackOptionsError && feedbackOptionsErrorMsg) {
      dispatch(
        setNotification({
          type: "error",
          message: "Error retrieving feedback options.",
        })
      );
      console.error(feedbackOptionsErrorMsg);
    }
  }, [feedbackOptionsError, feedbackOptionsErrorMsg]);
  
  // DOWNLOADING A SINGLE FILE
  useEffect(() => {
    fileResult && openFileDownload(fileResult);
    if (fileResult && !userActions.isAdmin) {
      openSatisfiedFeedback();
    }
  }, [fileResult]);
  
  // DOWNLOADING AN ENTIRE ASSET
  useEffect(() => {
    assetResult && openFileDownload(assetResult);
    if (assetResult && !userActions.isAdmin) {
      openSatisfiedFeedback();
    }
  }, [assetResult]);
  
  
  return (
    <Modal maxWidth="100%" height="100%" testId="mobile-download-options-modal" open onClose={handleClose} disabled={isFetching}>
      
      <StyledListboxContainer
        className="listbox-group"
        style={{padding: "2rem 1rem 1rem"}}
      >
        <FlexBox className="listbox">
          {userActions?.canDownload ? (
            <Body className="header">
              {files?.length > 1
                ? "Click individual files to download or download all below"
                : "Click the file or download button below to download"}
            </Body>
          ) : (
            <Body className="header">
              {files?.length > 1
                ? "Files included in asset"
                : "File included in asset"}
            </Body>
          )}
          
          <ul className="scrollbar">
            {files.map((file: AssetFile) => {
              return (
                !!file.currentVersionId && (
                  <li key={file.id}>
                    <FlexBox className="layout" row justify="space-between">
                      <div
                        style={{ maxWidth: "70%", overflow: "hidden" }}
                        className="download-link"
                      >
                        {(userActions?.canDownload && !isExpired) ? (
                          <TextButton
                            size="small"
                            onClick={onDownloadFileClick(file)}
                            disabled={isFetching}
                            data-testid="download-file-text-button"
                          >
                            {getFileName(file)}
                          </TextButton>
                        ) : (
                          <Body>{getFileName(file)}</Body>
                        )}
                      </div>
                      
                      <FlexBox
                        row
                        maxWidth="30%"
                        justify="flex-end"
                        align="center"
                      >
                        <Body className="info">
                          {formatFileSize(
                            getFileCurrentVersion(file)?.sizeInBytes
                          )}
                        </Body>
                        {userActions?.canDownload && !isExpired && (
                          <IconButton
                            mg="0 0 0 0.5rem"
                            id="download-file-button"
                            name="download"
                            size="medium"
                            onClick={onDownloadFileClick(file)}
                            disabled={isFetching}
                            noHover
                            testId="download-file-icon-button"
                          />
                        )}
                      </FlexBox>
                    </FlexBox>
                  </li>
                )
              );
            })}
          </ul>
        </FlexBox>
        
        {userActions?.canDownload && !isExpired && (
          <FlexBox
            row
            pd="0 1rem 2.5rem 0"
            justify="space-between"
            className="controls"
          >
            <Button
              use="secondary"
              disabled={!userActions?.canDownload || isExpired || isFetching}
              onClick={onDownloadAssetClick}
              data-testid="download-asset-button"
            >
              Download {files.length > 1 ? "all" : ""}
            </Button>
            <Body className="info">Total {formatAllFileSize(files)}</Body>
          </FlexBox>
        )}
        {usageRightsModalOpen && (
          <UsageRightsModal
            assetId={assetId}
            usageRightsCopy={usageRightsCopy}
            onComplete={completeUsageRightsModal}
            onClose={closeUsageRightsModal}
          />
        )}
      </StyledListboxContainer>
      <SatisfiedFeedback
        feedbackOptionList={feedbackOptions}
        isActive={showSatisfiedFeedback}
        assetId={assetId}
        correlationId={correlationId}
        onClose={closeSatisfiedFeedback}
      />
    </Modal>
  );
  
};

export default MobileDownloadOptionsModal;