import USER_ROLES from "constants/userRoles";
import { QueryUserGroup } from "interfaces";

export function createQueryString(obj: QueryUserGroup): string {
  // build query string from object property and value
  let query = "";
  for (const property in obj) {
    if (obj[property] !== undefined) {
      query += `${property}=${obj[property]}&`;
    }
  }
  return query;
}

export function privilegedUser(userRole: string | undefined): boolean {
  if (userRole && [USER_ROLES.STANDARD, USER_ROLES.ADMIN].includes(userRole)) {
    return true;
  } else {
    return false;
  }
}
