import { useState, useEffect, FormEvent, useRef } from "react";
import { v4 as uuid } from "uuid";
import { FormikProps, SuggestedUser, FileCourierFormProps } from "interfaces";
import {
  MultiSelectSearch,
  Input,
  Button,
  ButtonGroup,
  TextArea,
  DatePicker,
  Checkbox,
  FlexBox,
  Body,
  Tooltip,
  TwoUp,
  DragAndDrop,
  Title,
  MdToHtml,
} from "components";
import {
  useLazyGetSuggestedUsersQuery,
  useSendFileCourierPackageMutation,
  useUploadFileCourierFileMutation,
  useDeleteFileCourierFileMutation,
} from "services";
import { addDaysToCurrentDate, formatDate } from "utils/dateFunctions";
import {
  DragWrapper,
  StyledFlexBox,
  StyledForm,
  StyledSpan,
} from "./FileCourierForm.module";
import { SubmitFileCourierPackageModal } from "features";
import { AxiosProgressEvent } from "axios";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";

const FileCourierForm = ({
  values: fileCourierFormProps,
  touched,
  errors,
  handleChange,
  handleBlur,
  resetForm,
  setFieldValue,
  dirty,
  isValid,
}: FormikProps) => {
  const {
    sendToUsers,
    subject,
    message,
    expirationDate,
    sendCopy,
    files,
  }: FileCourierFormProps = fileCourierFormProps;

  const dispatch = useDispatch();

  const [sendFileCourierPackage, { isLoading: packageLoading }] =
    useSendFileCourierPackageMutation();
  const [uploadFileCourierFile, { isLoading: fileLoading }] =
    useUploadFileCourierFileMutation();
  const [deleteFileCourierFile] = useDeleteFileCourierFileMutation();

  const [getUsers] = useLazyGetSuggestedUsersQuery();

  const [hasAgreed, setHasAgreed] = useState<boolean>(false);
  const [packageId, setPackageId] = useState<string>("");
  const [showSentFiles, setShowSentFiles] = useState<boolean>(false);

  useEffect(() => {
    // Create and set uuid for package. The same id is used for all files which are part of the package.
    setPackageId(uuid());
  }, []);

  const formatSendPackageRequestBody = (): URLSearchParams => {
    let data = new URLSearchParams({
      id: packageId,
      subject,
      message,
      sendCopy: sendCopy ? "true" : "false",
      expirationDate: expirationDate?.toISOString() || "",
    });

    sendToUsers.forEach((user, index) => {
      data.append(`sentToEmails[${index}][Id]`, user.id);
      data.append(`sentToEmails[${index}][FirstName]`, user.firstName);
      data.append(`sentToEmails[${index}][LastName]`, user.lastName);
      data.append(
        `sentToEmails[${index}][FullName]`,
        `${user.firstName} ${user.lastName}}`
      );
      data.append(`sentToEmails[${index}][Email]`, user.email);
    });

    return data;
  };

  const handleOnSubmit = (e: FormEvent) => {
    sendFileCourierPackage(formatSendPackageRequestBody())
      .unwrap()
      .then(() => {
        setShowSentFiles(true);
      })
      .catch(() =>
        dispatch(
          setNotification({
            type: "error",
            message: `Could not submit File Courier Package request.`,
          })
        )
      );
  };

  const handleCancel = () => {
    setHasAgreed(false);
    resetForm();
  };

  const handleCloseSubmit = () => {
    window.location.reload();
  };

  const fileStateRef = useRef<File[]>();
  fileStateRef.current = files;

  const [uploadingFiles, _setUploadingFiles] = useState<string[]>([]),
    uploadingStateRef = useRef<string[]>();
  uploadingStateRef.current = uploadingFiles;

  const handleFilesChange = (newFiles: File[], progressCallback: Function) => {
    setFieldValue(
      "files",
      fileStateRef.current ? [...fileStateRef.current, ...newFiles] : newFiles
    );

    newFiles.forEach((file: File) => {
      _setUploadingFiles((oldArr: string[]) => [...oldArr, file.name]);

      let formData = new FormData();
      formData.append("file", file);
      formData.append("packageId", packageId);

      uploadFileCourierFile({
        payload: formData,
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          progressCallback(file.name, progressEvent);
        },
      })
        .unwrap()
        .then(() => {
          _setUploadingFiles((oldArr: string[]) =>
            oldArr.filter((item: string) => item !== file.name)
          );
        })
        .catch(() => {
          deleteFileFromForm(file);
          _setUploadingFiles((oldArr: string[]) =>
            oldArr.filter((item: string) => item !== file.name)
          );
          dispatch(
            setNotification({
              type: "error",
              message: `Could not upload file.`,
            })
          );
        });
    });
  };

  const deleteFileFromForm = (file: File) => {
    setFieldValue(
      "files",
      fileStateRef?.current?.filter((item: File) => item.name !== file.name) ||
        files
    );
  };

  const [deletingFiles, _setDeletingFiles] = useState<string[]>([]),
    deletingStateRef = useRef<string[]>();
  deletingStateRef.current = deletingFiles;

  const handleRemoveFile = (file: File) => {
    _setDeletingFiles(
      deletingStateRef.current
        ? [...deletingStateRef.current, file.name]
        : [file.name]
    );
    deleteFileCourierFile({ packageId, fileName: file.name })
      .unwrap()
      .then(() => {
        deleteFileFromForm(file);
        _setDeletingFiles(
          deletingStateRef?.current?.filter(
            (uploading: string) => uploading !== file.name
          ) || deletingFiles
        );
      })
      .catch(() => {
        _setDeletingFiles(
          deletingStateRef?.current?.filter(
            (uploading: string) => uploading !== file.name
          ) || deletingFiles
        );
        dispatch(
          setNotification({
            type: "error",
            message: `Could not delete file.`,
          })
        );
      });
  };

  const toggleHasAgreed = () => {
    setHasAgreed(!hasAgreed);
  };

  const toggleSendCopy = () => {
    setFieldValue("sendCopy", !sendCopy);
  };

  const removeUser = (selected: SuggestedUser) => () => {
    setFieldValue(
      "sendToUsers",
      sendToUsers.filter((item: SuggestedUser) => item.id !== selected.id)
    );
  };

  const handleSelectExpiration = (value: Date) => {
    setFieldValue("expirationDate", value);
  };

  const handleClearExpiration = () => {
    setFieldValue("expirationDate", undefined);
  };

  return (
    <>
      <Title size="large" bold>
        Send files
      </Title>
      <StyledForm data-testid="file-courier-form" onSubmit={handleOnSubmit}>
        <TwoUp pd="1rem">
          <FlexBox gap="1rem" maxWidth={"32rem"}>
            <MultiSelectSearch
              label="Send to (Brand Central users only)"
              name="sendToUsers"
              value={sendToUsers}
              setFieldValue={setFieldValue}
              callSearch={getUsers}
              removeSelected={(selected: SuggestedUser) => removeUser(selected)}
              orientation="vertical"
              includeEmail
              white
            />
            <Input
              label="Subject"
              name="subject"
              value={subject}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(touched.subject && !!errors.subject) || false}
              errorText={errors.subject}
              required
              data-testid="subject"
            />
            <TextArea
              label="Message"
              name="message"
              value={message}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(touched.message && !!errors.message) || false}
              errorText={errors.message}
              required
              data-testid="message"
              maxLength={1000}
            />
            <DatePicker
              value={(expirationDate as Date) || null}
              name="expirationDate"
              label="Valid Until"
              onChange={handleSelectExpiration}
              onClear={handleClearExpiration}
              testId="expiration-date"
              minDate={addDaysToCurrentDate(1)}
              maxDate={addDaysToCurrentDate(90)}
            />
            <FlexBox row gap="0.25rem">
              <Checkbox
                width="1.5rem"
                required={false}
                selected={sendCopy}
                onChange={toggleSendCopy}
              />
              <Body size="large">Send me a copy</Body>
            </FlexBox>
            <FlexBox row gap="0.6rem">
              <Checkbox
                width="1.5rem"
                selected={hasAgreed}
                onChange={toggleHasAgreed}
              />
              <span>
                <StyledSpan>
                  <strong>I agree</strong> File Courier is to be used solely by
                  Verizon employees and/or approved parties engaged in the
                  development of Verizon marketing materials to transfer files
                  for this purpose. No other use is permitted. Do not send
                  Highly Confidential information, PII
                </StyledSpan>
                <Tooltip
                  size="small"
                  data-tooltip="pii"
                  iconFillColor="primary"
                >
                  <FlexBox>
                    <MdToHtml
                      pMargin="0 0 .75rem 0"
                      pSize="small"
                      content="<strong>Personally identifiable Information</strong>"
                    />
                    <Body size="small">
                      Data that could be used to identify a specific person.
                    </Body>
                  </FlexBox>
                </Tooltip>
                <StyledSpan>, or CPNI</StyledSpan>
                <Tooltip
                  size="small"
                  data-tooltip="cpni"
                  iconFillColor="primary"
                >
                  <FlexBox>
                    <MdToHtml
                      pMargin="0 0 .75rem 0"
                      pSize="small"
                      content="<strong>Customer Proprietary Network Information</strong>"
                    />
                    <Body size="small">
                      Information about a customer's subscription to/use of
                      telecommunication or interconnected VOIP services.
                    </Body>
                  </FlexBox>
                </Tooltip>
                <StyledSpan>
                  . Do not use this tool to transfer files to a personal email
                  account or unauthorized parties. Usage is logged and
                  monitored.Verizon employees: Verizon policy CPI-810
                  requirements and other Verizon policy requirements apply.
                </StyledSpan>
              </span>
            </FlexBox>

            <ButtonGroup align="flex-start" pd="1.5rem 0">
              <Button
                type="submit"
                use="primary"
                disabled={
                  packageLoading ||
                  fileLoading ||
                  !(dirty && isValid) ||
                  !hasAgreed
                }
                data-testid="submit"
              >
                Submit
              </Button>
              <Button
                use="primary"
                type="button"
                data-testid="cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </FlexBox>
          <StyledFlexBox height={"100%"} pd={"0 0 0 1rem"} minWidth={"100%"}>
            <DragWrapper>
              <DragAndDrop
                files={files}
                fileSizeLimitInMb={500}
                onChange={(files: File[], progressCallback: Function) =>
                  handleFilesChange(files, progressCallback)
                }
                onDelete={(file: File) => handleRemoveFile(file)}
                uploadingFiles={uploadingFiles}
                deletingFiles={deletingFiles}
              />
            </DragWrapper>
          </StyledFlexBox>
        </TwoUp>
      </StyledForm>
      {showSentFiles && (
        <SubmitFileCourierPackageModal
          data={fileCourierFormProps}
          onClose={handleCloseSubmit}
        />
      )}
    </>
  );
};

export default FileCourierForm;
