import styled from "styled-components";
import Section from "../Section/Section";
import {minTablet} from "../../../theme/variables";
import FlexBox from "../FlexBox/FlexBox";
import TwoUp from "../TwoUp/TwoUp";

export const StyledContactSubFooter = styled(Section)`
  p {
    margin-top: 0;
  }
`;

export const StyledContactsContainer = styled(FlexBox)`
  @media screen and (max-width: ${minTablet - 1}px) {
    justify: flex-start;
  }
  @media screen and (min-width: ${minTablet}px) {
    justify: flex-end;
  }
`;

export const StyledContactsTwoUp = styled(TwoUp)`
  @media screen and (max-width: ${minTablet - 1}px) {
    flex-direction: column;
  }
  @media screen and (min-width: ${minTablet}px) {
    flex-direction: row;
    align-self: flex-end;
  }
`;
