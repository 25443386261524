import styled from "styled-components";

interface StyledDatePickerProps {
  pd?: string;
}
export const StyledDatePicker = styled("div")<StyledDatePickerProps>`
  padding: ${(props) => props.pd || "0"};
  width: 100%;

  .optional {
    color: rgb(111, 113, 113);
  }
`;
