import { StyledImage, StyledPopLinkImage } from "./Image.module";
export interface ImageProps {
  className?: string;
  src: string;
  width?: string | number;
  height?: string | number;
  popularLink?: boolean;
  border?: boolean;
  maxWidth?: string | number;
  maxHeight?: string | number;
  alt?: string;
  fit?: string;
  $bgColor?: string;
  radius?: string;
  testId?: string;
}

const Image = ({ popularLink, testId, ...rest }: ImageProps) => {
  return popularLink ? (
    <StyledPopLinkImage
      {...rest}
      data-testid={testId ? testId : "popular-link-image"}
    />
  ) : (
    <StyledImage {...rest} data-testid={testId ? testId : "styled-image"} />
  );
};

export default Image;
