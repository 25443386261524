import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import { withAction } from "HOCs/withAction";
import { signUp } from "services";
import mapPropsToValues from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import handleSubmit from "./handleSubmit";
import SignUpConfirm from "./SignUpConfirm";

export const SignUpConfirmContainer = compose(
  withAction((props: any) => signUp(props)),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  })
)(SignUpConfirm);

export default SignUpConfirmContainer;
