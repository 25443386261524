import {Navigate, Outlet, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import paths from "routing/paths";
import {
  Loader,
  Notification,
  UnprotectedFooter,
  UnprotectedHeader,
} from "components";
import { UnprotectedWrapper } from "./UnprotectedPage.module";
import { fallback } from "../ProtectedPage/ProtectedPage";
import { AuthProps } from "HOCs";
import { compose } from "@reduxjs/toolkit";
import { withAuth } from "HOCs/withAuth";
import { BASE_URL } from "utils/environmentVariables";
import { supportsStorage } from "utils";
import {useEffect, useRef} from "react";

const renderPath = (searchParams: any) => {
  if (searchParams.get("ru")) {

    const searchLink = searchParams.get("ru"),
      anchorIndex = searchLink?.indexOf("anchor=");
    const externalLink =
      anchorIndex !== -1
        ? searchLink?.slice(0, anchorIndex) +
          "?" +
          searchLink?.slice(anchorIndex)
        : searchLink;

    window.location.href = BASE_URL + externalLink;
    return <Loader active />;
  } else {
    return <Navigate to={paths.HOME} replace />;
  }
};



const UnprotectedPage = ({ auth }: AuthProps) => {
  const [searchParams] = useSearchParams();

  return auth === null ? (
    fallback()
  ) : auth ? (
    renderPath(searchParams)
  ) : (
    <UnprotectedWrapper>
      <UnprotectedHeader />
      <Outlet />
      <Notification />
      <UnprotectedFooter />
    </UnprotectedWrapper>
  );
};

export default compose(withAuth())(UnprotectedPage);
