import { useState } from "react";
import CaretText from "components/CaretText/CaretText";
import { StyledButton } from "./CaretButton.module";
import { MouseEventHandler, ReactNode } from "react";

export interface CaretButtonProps {
  children: ReactNode;
  onClick: MouseEventHandler;
  disabled?: boolean;
  pd?: string;
  size?: "medium" | "small" | "large";
  caretPosition?: "left" | "right" | "";
  testId?: string;
}
const CaretButton = ({
  disabled,
  children,
  size = "medium",
  caretPosition,
  onClick,
  testId,
  ...rest
}: CaretButtonProps) => {
  
  const [isHovering, _isHovering] = useState<boolean>(false);
  
  const handleMouseEnter = () => {
    _isHovering(true);
  };
  
  const handleMouseLeave = () => {
    _isHovering(false);
  };
  
  return (
    <StyledButton data-testid={testId} onMouseEnter={handleMouseEnter} onClick={onClick} onFocus={handleMouseEnter} onBlur={handleMouseLeave} onMouseLeave={handleMouseLeave} type="button" disabled={disabled} {...rest}>
      <CaretText size={size} fontSize={size === "small" ? "0.75" : size === "large" ? "1" : "0.875"} align="center" hover={isHovering} caretPosition={caretPosition} disabled={disabled}>
        {children}
      </CaretText>
    </StyledButton>
  );
};

export default CaretButton;
