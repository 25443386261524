import { useState } from "react";
import { UsageRightsModalProps } from "interfaces";
import {
  TextButton,
  Button,
  FlexBox,
  Modal,
  Title,
  Body,
  MdToHtml,
  Checkbox,
} from "components";
import { useAcceptUsageRightsMutation, setNotification } from "services";
import { useDispatch } from "hooks/redux";

const UsageRightsModal = ({
  onClose,
  assetId,
  onComplete,
  usageRightsCopy,
}: UsageRightsModalProps) => {
  const [acceptRights, { isLoading }] = useAcceptUsageRightsMutation();
  const dispatch = useDispatch();

  const [rightsAccepted, setRightsAccepted] = useState<boolean>(false),
    toggleRightsAccepted = () => {
      setRightsAccepted(!rightsAccepted);
    },
    callUsageRights = () => {
        acceptRights(assetId)
          .unwrap()
          .then(() => {
            setRightsAccepted(true);
            dispatch(
              setNotification({
                type: "success",
                message: "Usage rights accepted.",
              })
            );
            onComplete();
          })
        .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Rights not accepted.",
          })
        );
        console.error(error);
      });
    };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal testId="usage-rights-modal" open onClose={handleClose} disabled={isLoading}>
      <FlexBox>
        <Title size="large" bold pd="0 0 2rem">
          Please accept the usage rights before downloading.
        </Title>
        <Body size="large" pd="0 0 1rem">
          Permission to download an image has been given on the condition that
          the image is only used in accordance with the usage rights listed,
          including any term period limitations. Any other use, which has not
          been explicitly authorized, is prohibited. Any breach of the usage
          rights may result in copyright infringement or other liability for
          Verizon, for which you will bear full legal responsibility.
        </Body>
        {usageRightsCopy && (
          <MdToHtml
            pSize="large"
            pMargin="0 0 1rem 0"
            content={`**Usage rights:** ${usageRightsCopy}`}
          />
        )}

        <Checkbox
          label="I agree to comply with the usage rights listed"
          selected={rightsAccepted}
          onChange={toggleRightsAccepted}
          disabled={isLoading}
          data-testid="rights-checkbox"
        />
        <FlexBox
          pd="3rem 0 0"
          row
          gap="1.5rem"
          align="center"
          justify="flex-start"
        >
          <Button
            use="primary"
            data-testid="submit-button"
            disabled={!rightsAccepted || isLoading}
            onClick={callUsageRights}
          >
            Start my download
          </Button>
          <TextButton data-testid="cancel-button" onClick={handleClose} disabled={isLoading}>
            Cancel
          </TextButton>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default UsageRightsModal;
