import { Tooltip } from "@vds/tooltips";
import styled from "styled-components";
import Tabs from "../../components/layouts/Tabs/Tabs";
import {minMobileLarge, minTablet} from "../../theme/variables";
import Button from "../../components/buttons/Button/Button";
import FlexBox from "../../components/layouts/FlexBox/FlexBox";

export const StyledFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;

`;

export const StyledTabsContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  @media (max-width: ${minTablet-1}px) {
    padding-top: 1.5rem;
  }
  @media (min-width: ${minTablet}px) {
    padding-top: 0;
  }
`;
export const AssetList = styled.ul`

  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const AssetItem = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;

  min-height: 7rem;
  
`;

export const AssetItemContainer = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;

  min-height: 7rem;
  justify-content: space-between;

  /* padding-block: 1rem; */

  :not(:first-of-type) {
    border-top: 1px solid #d8dada;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 4.5rem;
  height: 4.5rem;

  background-color: #f6f6f6;

  border-radius: 8px;
  outline: rgb(246, 246, 246) solid 0.0625rem;

  overflow: hidden;
`;

// export const StyledTooltip = styled(Tooltip)`
//   position: relative;
//   padding-left: 0.25rem;
//   > span > div {
//     left: 0;
//   }
//   > span > span > span {
//     /* position: fixed;
//     transform: none;
//     top: 0; */
//     /* width: 25rem;
//     max-width: 25rem;
//     height: fit-content;
//     max-height: none;

//     bottom: auto;
//     &:before {
//       content: unset;
//     } */
//   }

//   * {
//     height: fit-content;
//   }
// `;
