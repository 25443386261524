import React from 'react';
import PropTypes from 'prop-types';
import { calculateRem } from '@vds/utilities';
import { Button as Button$1 } from '@vds-core/buttons';

const propTypes = {
  /**
   * Accepts primary or secondary.
   */
  use: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const Button = function (props) {
  function _calculateMinWidth(size) {
    if (size === 'large') {
      return calculateRem(76);
    }
    return undefined;
  }
  function _calculateBorderRadius(size) {
    let borderRadius;
    if (size === 'small') {
      borderRadius = 32;
    } else {
      borderRadius = 44;
    }
    return calculateRem(borderRadius);
  }
  function _calculateMinWidth(size) {
    if (size === 'large') {
      return calculateRem(76);
    }
    return;
  }
  function _calculateButtonHeight() {
    return 'auto';
  }
  return React.createElement(Button$1, Object.assign({}, props, {
    calculateButtonHeight: _calculateButtonHeight,
    calculateBorderRadius: _calculateBorderRadius,
    _calculateMinWidth: _calculateMinWidth
  }));
};
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
Button.displayName = 'Button'; // MUST set so Modal Footer can recognize

export { Button as default };
