import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  height: 12rem;
  width: 100%;

  @media only screen and (max-width: 960px) {
    height: unset;
    flex-direction: column;
  }
`;

export const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  border: 1px solid #000;
  height: 100%;
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;
  padding: 1.5rem;
  text-decoration: unset;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
  transition: all 0.1s;
  background-color: #ffffff;
  z-index: unset;

  &:hover {
    transform: scale(1.025);
    z-index: 1;
  }

  &.active {
    background-color: black;
  }
  
`;
