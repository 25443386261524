import {
  BASE_URL,
  COGNITO_AUTH_FLOW_TYPE,
  COGNITO_OAUTH_DOMAIN,
  COGNITO_OAUTH_REDIRECT_SIGN_IN,
  COGNITO_OAUTH_REDIRECT_SIGN_OUT,
  COGNITO_OAUTH_RESPONSE_TYPE,
  COGNITO_OAUTH_SCOPE,
  COGNITO_REGION,
  COGNITO_USER_POOL_CLIENT_ID,
  COGNITO_USER_POOL_ID,
} from "utils/environmentVariables";

const config = {
  Auth: {
    region: COGNITO_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_USER_POOL_CLIENT_ID,
    authenticationFlowType: COGNITO_AUTH_FLOW_TYPE,
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // - Cookie domain (only required if cookieStorage is provided)
      domain: new URL("/", BASE_URL).hostname,
      // (optional) - Cookie path
      path: "/",
      // (optional) - Cookie expiration in days
      expires: 1,
      // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: "lax",
      // (optional) - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
    },
  },
  oauth: {
    domain: COGNITO_OAUTH_DOMAIN,
    scope: COGNITO_OAUTH_SCOPE,
    redirectSignIn: COGNITO_OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut: COGNITO_OAUTH_REDIRECT_SIGN_OUT,
    responseType: COGNITO_OAUTH_RESPONSE_TYPE,
  },

};

export default config;
