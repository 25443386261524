import styled from "styled-components";

interface LoaderProps {
  active?: boolean;
  containerHeight?: string;
  pd?: string;
}

export const StyledLoaderContainer = styled("div")<LoaderProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
  height: ${(props) => props.containerHeight};
  padding: ${(props) => props.pd || 0};
  flex-grow: 1;
  & > div {
    height: ${(props) => props.containerHeight};
    position: relative;
    flex-grow: 1;
  }
`;
