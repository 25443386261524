import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const withScrollTop = () => (WrappedComponent: any) => {
  const Component = (props: any) => {
    const location = useLocation();
    useEffect(() => {
      if (!location.hash) {
        window.scrollTo(0, 0);
      }
    }, [location]);

    return <WrappedComponent {...props} />;
  };

  return Component;
};

export default withScrollTop;
