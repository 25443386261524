export default function handleError(error: any) {
  switch (error.code) {
    case "NotAuthorizedException":
      return new UnauthorizedError(error.message);
    case "UserNotFoundException":
      if (error.message === "Username/client id combination not found.") {
        return new Error("Username is incorrect.");
      }
      return new Error("Username or password is incorrect.");
    default:
      return new Error(
        error.message || "An unknown error has occurred. Please contact admin."
      );
  }
}

class UnauthorizedError extends Error {
  constructor(message: string) {
    super();
    this.name = "UnauthorizedError";
    this.message =
      message || "An unauthorized error has occurred. Please log in.";
  }
}
