import styled from "styled-components";
import FlexBox from "../../components/layouts/FlexBox/FlexBox";
import {minDesktop, minTablet, zIndex} from "../../theme/variables";
import {AvatarGroup} from "@mui/material";

export const StyledBar = styled(FlexBox)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  border-top: 1px solid #d8dada;
  background-color: #f6f6f6;
  overflow: hidden;
  padding-inline: 1rem;
  z-index: ${zIndex.FAB};
  @media (max-width: ${minTablet-1}px) {
    flex-direction: column;
    gap: 0.5rem;
    height: unset;
    padding: 1rem 1rem 1.5rem;
  }
  @media (min-width: ${minTablet}px) {
    flex-direction: row;
    height: 5rem;
    padding: 1.25rem;
  }
`;

export const StyledAvatarGroup = styled(AvatarGroup)`
  gap: 0.75rem;
`;

export const StyledSelectedTitleAndClearContainer = styled(FlexBox)`
  @media (max-width: ${minTablet-1}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding-bottom: 2rem;
   
  }
  @media (min-width: ${minTablet}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }
`;

export const StyledLinkContainer = styled(FlexBox)`
  
  // @media (max-width: ${minTablet-1}px) {
  //   align-self: center;
  // }
  // @media (min-width: ${minTablet}px) {
  //   align-self: flex-end;
  //   margin-right: 0.5rem;
  // }
`;

