import { Component } from "react";
import { SavedSearchProvider } from "contexts/savedSearchContext";

const withSavedSearchContext = (WrappedComponent: any) => {
  return class extends Component {
    render() {
      return (
        <SavedSearchProvider>
          <WrappedComponent {...this.props} />
        </SavedSearchProvider>
      );
    }
  };
};

export default withSavedSearchContext;
