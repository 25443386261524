import styled from "styled-components";

export const StyledNav = styled("div")`
  &> nav {
    border: 1px solid #d8dada;
    overflow: hidden;
  }
  &.hide {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
    }
    > nav {
      border-radius: 0.5rem;
    }
  }
  &.showMobile {
    display: block;
    @media screen and (min-width: 768px) {
      display: none;
    }
    > nav {
      border-radius: 0;
    }
  }
  
`;

export const StyledSingleNavItem = styled("div")`
  padding: 1rem 1.5rem;
  &.active {
    border-left: 4px solid #ee0000;
  }
`;

export const StyledChildLink = styled("div")`
  padding: 0.5rem 1.25rem 0.5rem 2rem;
  &.active {
    border-left: 4px solid #ee0000;
  }
`;

export const StyledTextLink = styled("div")`
  margin-left: 0;
  &.active {
    margin-left: -4px;
  }
`;