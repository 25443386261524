import styled from "styled-components";
import {
  mobile,
  desktop,
  minTablet,
  minTabletLarge,
  tablet,
  mobileLarge,
  minMobileLarge,
} from "theme/variables";

interface AssetCardListProps {
  scrollOnMobile?: string;
  width?: string;
  maxWidth?: string;
  colGutter?: string;
}

export const StyledAssetCardList = styled("div")<AssetCardListProps>`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${minTablet - 1}px) {
    padding: 4rem 0 1rem;
  }
  @media screen and (min-width: ${minTablet}px) {
    padding: 4rem 0 2rem;
  }

  .overflow-container {
    width: 100%;
    @media screen and (max-width: ${minTablet - 1}px) {
      overflow-x: scroll;
    }
    @media screen and (min-width: ${minTablet}px) {
      overflow-x: unset;
    }
    .scroll-grid {
      //overflow: unset;
      width: 100%;
      max-width: 100%;
      @media ${mobile} {
        //overflow: auto;
        width: 200%;
        max-width: 200%;
      }
      @media ${mobileLarge} {
        //overflow: auto;
        width: 125%;
        max-width: 125%;
      }
    }
  }
`;
