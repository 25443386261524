export const TypographyTokens = {
  "fontsize": {
    "body": {
      "12": {
        "value": "12px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "12"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "12px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeBody12",
        "path": [
          "fontsize",
          "body",
          "12"
        ]
      },
      "14": {
        "value": "14px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "14"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "14px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeBody14",
        "path": [
          "fontsize",
          "body",
          "14"
        ]
      },
      "16": {
        "value": "16px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "16"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "16px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeBody16",
        "path": [
          "fontsize",
          "body",
          "16"
        ]
      }
    },
    "feature": {
      "40": {
        "value": "40px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "40"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "40px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeFeature40",
        "path": [
          "fontsize",
          "feature",
          "40"
        ]
      },
      "48": {
        "value": "48px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "48"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "48px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeFeature48",
        "path": [
          "fontsize",
          "feature",
          "48"
        ]
      },
      "64": {
        "value": "64px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "64"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "64px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeFeature64",
        "path": [
          "fontsize",
          "feature",
          "64"
        ]
      },
      "80": {
        "value": "80px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "80"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "80px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeFeature80",
        "path": [
          "fontsize",
          "feature",
          "80"
        ]
      },
      "96": {
        "value": "96px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "96"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "96px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeFeature96",
        "path": [
          "fontsize",
          "feature",
          "96"
        ]
      },
      "128": {
        "value": "128px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "128"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "128px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeFeature128",
        "path": [
          "fontsize",
          "feature",
          "128"
        ]
      },
      "144": {
        "value": "144px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "144"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "144px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeFeature144",
        "path": [
          "fontsize",
          "feature",
          "144"
        ]
      },
      "superscript": {
        "value": "50%",
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "50%"
        },
        "name": "FontsizeFeatureSuperscript",
        "attributes": {
          "category": "fontsize",
          "type": "feature",
          "item": "superscript"
        },
        "path": [
          "fontsize",
          "feature",
          "superscript"
        ]
      }
    },
    "micro": {
      "11": {
        "value": "11px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "11"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "11px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeMicro11",
        "path": [
          "fontsize",
          "micro",
          "11"
        ]
      }
    },
    "title": {
      "16": {
        "value": "16px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "16"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "16px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeTitle16",
        "path": [
          "fontsize",
          "title",
          "16"
        ]
      },
      "20": {
        "value": "20px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "20"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "20px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeTitle20",
        "path": [
          "fontsize",
          "title",
          "20"
        ]
      },
      "24": {
        "value": "24px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "24"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "24px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeTitle24",
        "path": [
          "fontsize",
          "title",
          "24"
        ]
      },
      "32": {
        "value": "32px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "32"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "32px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeTitle32",
        "path": [
          "fontsize",
          "title",
          "32"
        ]
      },
      "40": {
        "value": "40px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "40"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "40px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeTitle40",
        "path": [
          "fontsize",
          "title",
          "40"
        ]
      },
      "48": {
        "value": "48px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "48"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "48px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeTitle48",
        "path": [
          "fontsize",
          "title",
          "48"
        ]
      },
      "64": {
        "value": "64px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "64"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "64px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "FontsizeTitle64",
        "path": [
          "fontsize",
          "title",
          "64"
        ]
      },
      "superscript": {
        "value": "50%",
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "50%"
        },
        "name": "FontsizeTitleSuperscript",
        "attributes": {
          "category": "fontsize",
          "type": "title",
          "item": "superscript"
        },
        "path": [
          "fontsize",
          "title",
          "superscript"
        ]
      }
    }
  },
  "fontweight": {
    "light": {
      "value": 300,
      "attributes": {
        "category": "string",
        "type": "font"
      },
      "filePath": "packages/Typography/src/index.js",
      "isSource": true,
      "original": {
        "value": 300,
        "attributes": {
          "category": "string",
          "type": "font"
        }
      },
      "name": "FontweightLight",
      "path": [
        "fontweight",
        "light"
      ]
    },
    "regular": {
      "value": 400,
      "attributes": {
        "category": "string",
        "type": "font"
      },
      "filePath": "packages/Typography/src/index.js",
      "isSource": true,
      "original": {
        "value": 400,
        "attributes": {
          "category": "string",
          "type": "font"
        }
      },
      "name": "FontweightRegular",
      "path": [
        "fontweight",
        "regular"
      ]
    },
    "bold": {
      "value": 700,
      "attributes": {
        "category": "string",
        "type": "font"
      },
      "filePath": "packages/Typography/src/index.js",
      "isSource": true,
      "original": {
        "value": 700,
        "attributes": {
          "category": "string",
          "type": "font"
        }
      },
      "name": "FontweightBold",
      "path": [
        "fontweight",
        "bold"
      ]
    }
  },
  "letterspacing": {
    "wide": {
      "value": "0.5px",
      "attributes": {
        "category": "size",
        "type": "font"
      },
      "filePath": "packages/Typography/src/index.js",
      "isSource": true,
      "original": {
        "value": "0.5px",
        "attributes": {
          "category": "size",
          "type": "font"
        }
      },
      "name": "LetterspacingWide",
      "path": [
        "letterspacing",
        "wide"
      ]
    },
    "semiwide": {
      "value": "0.25px",
      "attributes": {
        "category": "size",
        "type": "font"
      },
      "filePath": "packages/Typography/src/index.js",
      "isSource": true,
      "original": {
        "value": "0.25px",
        "attributes": {
          "category": "size",
          "type": "font"
        }
      },
      "name": "LetterspacingSemiwide",
      "path": [
        "letterspacing",
        "semiwide"
      ]
    }
  },
  "lineheight": {
    "body": {
      "16": {
        "value": "16px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "16"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "16px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightBody16",
        "path": [
          "lineheight",
          "body",
          "16"
        ]
      },
      "18": {
        "value": "18px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "18"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "18px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightBody18",
        "path": [
          "lineheight",
          "body",
          "18"
        ]
      },
      "20": {
        "value": "20px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "20"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "20px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightBody20",
        "path": [
          "lineheight",
          "body",
          "20"
        ]
      }
    },
    "feature": {
      "40": {
        "value": "40px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "40"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "40px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightFeature40",
        "path": [
          "lineheight",
          "feature",
          "40"
        ]
      },
      "48": {
        "value": "48px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "48"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "48px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightFeature48",
        "path": [
          "lineheight",
          "feature",
          "48"
        ]
      },
      "64": {
        "value": "64px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "64"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "64px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightFeature64",
        "path": [
          "lineheight",
          "feature",
          "64"
        ]
      },
      "76": {
        "value": "76px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "76"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "76px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightFeature76",
        "path": [
          "lineheight",
          "feature",
          "76"
        ]
      },
      "88": {
        "value": "88px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "88"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "88px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightFeature88",
        "path": [
          "lineheight",
          "feature",
          "88"
        ]
      },
      "120": {
        "value": "120px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "120"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "120px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightFeature120",
        "path": [
          "lineheight",
          "feature",
          "120"
        ]
      },
      "136": {
        "value": "136px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "136"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "136px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightFeature136",
        "path": [
          "lineheight",
          "feature",
          "136"
        ]
      }
    },
    "micro": {
      "16": {
        "value": "16px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "16"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "16px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightMicro16",
        "path": [
          "lineheight",
          "micro",
          "16"
        ]
      }
    },
    "title": {
      "20": {
        "value": "20px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "20"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "20px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightTitle20",
        "path": [
          "lineheight",
          "title",
          "20"
        ]
      },
      "24": {
        "value": "24px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "24"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "24px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightTitle24",
        "path": [
          "lineheight",
          "title",
          "24"
        ]
      },
      "28": {
        "value": "28px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "28"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "28px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightTitle28",
        "path": [
          "lineheight",
          "title",
          "28"
        ]
      },
      "36": {
        "value": "36px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "36"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "36px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightTitle36",
        "path": [
          "lineheight",
          "title",
          "36"
        ]
      },
      "40": {
        "value": "40px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "40"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "40px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightTitle40",
        "path": [
          "lineheight",
          "title",
          "40"
        ]
      },
      "48": {
        "value": "48px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "48"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "48px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightTitle48",
        "path": [
          "lineheight",
          "title",
          "48"
        ]
      },
      "64": {
        "value": "64px",
        "attributes": {
          "category": "size",
          "type": "font",
          "item": "64"
        },
        "filePath": "packages/Typography/src/index.js",
        "isSource": true,
        "original": {
          "value": "64px",
          "attributes": {
            "category": "size",
            "type": "font"
          }
        },
        "name": "LineheightTitle64",
        "path": [
          "lineheight",
          "title",
          "64"
        ]
      }
    }
  }
}