import FlexBox from "components/layouts/FlexBox/FlexBox";
import Title from "components/typography/Title/Title";
import Body from "components/typography/Body/Body";
import { TitleProps } from "@vds/typography";
import MdToHtml from "../../MdToHtml/MdToHtml";

export interface ActionTileInterface {
  title: string;
  titleSize?: TitleProps["size"];
  body: string;
  action: any;
  color?: TitleProps["color"];
}
const ActionTile = ({
  title,
  titleSize,
  body,
  action,
  color,
}: ActionTileInterface) => {
  return (
    <FlexBox justify="space-between" height="100%">
      <Title
        size={titleSize ? titleSize : "large"}
        color={color ? color : "#000000"}
        bold
      >
        {title}
      </Title>
      <MdToHtml pMargin="0" pd="0 0 1rem" pSize="large" linkTextDecoration linkSize="large" color={color ? color : "#000000"} content={body} />
      {action}
    </FlexBox>
  );
};

export default ActionTile;
