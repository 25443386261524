import { Autocomplete, TextField } from "@mui/material";
import { Icon } from "@vds/icons";
import { MultiSelectSearchProps } from "interfaces";
import { Body, FlexBox, IconButton, Tag, Title, TwoUp } from "components";
import { StyledSelectText, StyledSelected } from "./MultiSelectSearch.module";
import { useEffect, useRef, useState } from "react";
import {setNotification} from "../../../services";

const MultiSelectSearch = ({
  label,
  title,
  name,
  value,
  optionLabels = ["name"],
  valueKey = "id",
  disabled,
  setFieldValue,
  callSearch,
  selectedLabel,
  removeSelected,
  orientation = "vertical",
  includeEmail,
  white,
  callback,
}: MultiSelectSearchProps) => {
  const [options, _setOptions] = useState<any[]>([]),
    handleOnBlur = () => {
      _setOptions([]);
      _setShowNoOptions(false);
    };

  const generateOptionLabel = (option: any) => {
    return option
      ? optionLabels.reduce((result: string, item: string, index: number) => {
          if (index === optionLabels.length - 1) {
            return `${result}${option[item]}`;
          }
          return `${result}${option[item]} `;
        }, "")
      : "";
  };

  const [showNoOptions, _setShowNoOptions] = useState(false),
    [allowOptionsUpdate, _setAllowOptionsUpdate] = useState(false);

  const allowOptionsRef = useRef<boolean>();
  allowOptionsRef.current = allowOptionsUpdate;

  useEffect(() => {
    !allowOptionsUpdate && options.length && _setOptions([]);
  }, [options]);
  const InputComponent = (
      <div>
        {title ? (
          <Title size="small" bold pd="0 0 0.75rem 0">
            {label}
          </Title>
        ) : (
          <Body pd="0 0 0.25rem 0">{label}</Body>
        )}
        <Autocomplete
          value={value}
          options={allowOptionsUpdate ? options : []}
          autoComplete
          // autoHighlight
          multiple
          fullWidth
          freeSolo={showNoOptions ? false : true}
          disabled={disabled}
          onChange={(_, value: readonly any[], reason) => {
            const tempValue: any[] = JSON.parse(JSON.stringify(value));
            if (reason === "clear") {
            } else if (reason !== "removeOption") {
              setFieldValue(name, tempValue);
              callback && callback(tempValue[tempValue.length - 1]);
            }
          }}
          onBlur={handleOnBlur}
          clearOnBlur
          onInputChange={(_: any, value: any, reason: any) => {
            if (reason === "input") {
              _setShowNoOptions(false);
              if (value.length >= 3) {
                _setAllowOptionsUpdate(true);
                callSearch(value)
                  .unwrap()
                  .then((response: Array<any>) => {
                    _setOptions(response);

                    if (allowOptionsRef.current && response.length === 0) {
                      _setShowNoOptions(true);
                    }
                  })
                .catch((error: any) => {
                  console.error(error);
                });
              } else if (value.length === 0) {
                _setOptions([]);
                _setShowNoOptions(false);
                _setAllowOptionsUpdate(false);
              }
            } else if (reason === "reset") {
              _setOptions([]);
              _setShowNoOptions(false);
              _setAllowOptionsUpdate(false);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              color="secondary"
              inputProps={{
                ...params.inputProps,
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation();
                  }
                },
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                <StyledSelectText>
                  <Body className="ellipsis" bold>
                    {generateOptionLabel(option)}
                  </Body>
                  {includeEmail && (
                    <Body className="ellipsis" color="#a7a7a7">{`${
                      option.email || option.emailAddress
                    }`}</Body>
                  )}
                </StyledSelectText>
              </li>
            );
          }}
          getOptionLabel={(option: any) => generateOptionLabel(option)}
          filterOptions={(x) => x}
          filterSelectedOptions
          ListboxProps={{
            style: { maxHeight: "10rem" },
          }}
          forcePopupIcon
          popupIcon={<Icon name="search" />}
          sx={{
            "& .MuiAutocomplete-popupIndicator": {
              transform: "none",
              cursor: "text",
              ".MuiTouchRipple-root": {
                "*": {
                  background: "none",
                },
              },
            },
          }}
          isOptionEqualToValue={(option: any, value: any) => {
            return option[valueKey] === value[valueKey];
          }}
          renderTags={() => null}
          disableClearable
          data-testid="multi-select-search"
        />
      </div>
    ),
    ResultComponent = value.length ? (
      <FlexBox
        pd={
          title
            ? "2rem 0 0.25rem 0"
            : orientation === "horizontal"
            ? "1rem 0 0.25rem 0"
            : "0"
        }
        data-testid="multi-select-search-selected"
      >
        {selectedLabel && <Body testId="selected-label" size="large">{selectedLabel}</Body>}
        <StyledSelected white={white} margin={orientation === "horizontal"}>
          {value.map((item: any) => (
            <Tag key={item.id || generateOptionLabel(item)}>
              <IconButton
                name="close"
                size="small"
                noHover
                onClick={removeSelected && removeSelected(item)}
                disabled={disabled}
                testId="multi-select-search-remove"
              />
              <StyledSelectText>
                <Body className="ellipsis" bold>
                  {generateOptionLabel(item)}
                </Body>
                {includeEmail && (
                  <Body className="ellipsis" color="#a7a7a7">{`${
                    item.email || item.emailAddress
                  }`}</Body>
                )}
              </StyledSelectText>
            </Tag>
          ))}
        </StyledSelected>
      </FlexBox>
    ) : null;

  return orientation === "vertical" ? (
    <>
      {InputComponent}
      {ResultComponent}
    </>
  ) : (
    <TwoUp>
      {InputComponent}
      {ResultComponent}
    </TwoUp>
  );
};

export default MultiSelectSearch;
