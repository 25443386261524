import { Loader } from "components";
import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import paths from "routing/paths";

const RerouteUnifiedSearch = () => {
  const params = useParams(),
    { pathname } = useLocation(),
    [searchParams] = useSearchParams();

  const navigate = useNavigate();
  useEffect(() => {
    switch (pathname) {
      case paths.TEMPLATE_SAVED_SEARCH_REDIRECT:
        return navigate(
          `${paths.ASSET_SEARCH}?saved=${searchParams.get("saved") || ""}`
        );
      default:
      case paths.ASSET_SEARCH_REDIRECT:
        return navigate(paths.ASSET_SEARCH, {
          state: { saved: { subCategoryId: params?.categoryId } },
        });
    }
  }, []);

  return <Loader active />;
};

export default RerouteUnifiedSearch;
