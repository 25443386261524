import { createContext, useMemo, useState } from "react";

interface savedSearchContext {
  savedSearch:
    | {
        search: string;
        category?: string;
        subCategoryId?: string;
        tags: Array<string>;
      }
    | boolean
    | null;
  setSavedSearch: Function;
}

export const SavedSearchContext = createContext<savedSearchContext>({
  savedSearch: null,
  setSavedSearch: () => {},
});

export function SavedSearchProvider({ children }: any) {
  const [savedSearch, setSavedSearch] = useState(null);

  return (
    <SavedSearchContext.Provider
      value={useMemo(
        () => ({ savedSearch, setSavedSearch }),
        [savedSearch, setSavedSearch]
      )}
    >
      {children}
    </SavedSearchContext.Provider>
  );
}
