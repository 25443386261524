import { useState, useEffect, useCallback } from "react";
import { StyledFeedbackContainer } from "./LookingForFeedback.module";
import IconButton from "../../components/buttons/IconButton/IconButton";
import Title from "../../components/typography/Title/Title";
import Body from "../../components/typography/Body/Body";
import FlexBox from "../../components/layouts/FlexBox/FlexBox";
import { useTrackFeedbackMutation } from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";

export interface LookingForFeedbackProps {
  assetId: string;
  correlationId?: string;
}

const LookingForFeedback = ({
  assetId,
  correlationId,
}: LookingForFeedbackProps) => {
  const [trackFeedback, { isLoading }] = useTrackFeedbackMutation();

  const dispatch = useDispatch();

  const [open, _setOpen] = useState<boolean>(false),
    [done, _setDone] = useState<boolean>(false),
    handleScroll = useCallback(() => {
      _setOpen(true);
    }, []),
    close = () => {
      _setOpen(false);
      window.removeEventListener("scroll", handleScroll, true);
    };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  const callTrackFeedback = (
    source: string,
    response: string,
    details: string[],
    entityId?: string
  ) => {
    trackFeedback({
      source: source,
      response: response,
      details: details,
      entityId: entityId,
      correlationId: correlationId,
    })
      .unwrap()
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Error sending feedback.",
          })
        );
      });
  };

  const leaveScrollFeedback = (feedbackString: string): void => {
    if (feedbackString === "thumbs down") {
      callTrackFeedback("asset-view", "negative", [], assetId);
    } else {
      callTrackFeedback("asset-view", "positive", [], assetId);
    }
    _setDone(true);
    setTimeout(() => {
      close();
    }, 5000);
  };

  return open ? (
    <>
      <StyledFeedbackContainer
        data-testid="feedback-scroll-container"
        className={`feedback-scroll-container ${open ? "active" : ""}`}
      >
        <IconButton
          className="close-button"
          name="close"
          onClick={close}
          size="medium"
          noHover
          disabled={isLoading}
          testId="close-button"
        />
        {!done ? (
          <div className="initial-state">
            <Title size="small" bold>
              Is this what you were looking for?
            </Title>
            <FlexBox className="button-wrap" row align="center" gap="0.25rem">
              <IconButton
                className="feedback-button thumbs-up"
                kind="lowContrast"
                name="thumbs-up"
                size="medium"
                onClick={() => leaveScrollFeedback("thumbs up")}
                fitToIcon={false}
                disabled={isLoading}
                testId="thumbs-up"
              >
                <span className="visuallyhidden">
                  This is what I was looking for
                </span>
              </IconButton>
              <IconButton
                className="feedback-button thumbs-down"
                kind="lowContrast"
                name="thumbs-down"
                size="medium"
                onClick={() => leaveScrollFeedback("thumbs down")}
                fitToIcon={false}
                disabled={isLoading}
                testId="thumbs-down"
              >
                <span className="visuallyhidden">
                  This is NOT what I was looking for
                </span>
              </IconButton>
            </FlexBox>
          </div>
        ) : (
          <Body pd="1rem 2rem" bold size="large">
            Thank you for your feedback.
          </Body>
        )}
      </StyledFeedbackContainer>
    </>
  ) : (
    <></>
  );
};

export default LookingForFeedback;
