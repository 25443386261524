import styled from "styled-components";
import {minTablet} from "../../theme/variables";

export const StyledListboxContainer = styled("div")`
  .listbox {
    padding: 1rem 1rem 0;
    margin-bottom: 1.5rem;
    min-height: 176px;
    //height: 90vh;
    font-size: inherit;
    line-height: 1.333;
    height: auto;
    border: #d8dada 1px solid;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    & > .header > p {
      position: relative;
      margin: 0;
      padding-bottom: 1rem;
      padding-right: 10%;
      font-size: 1rem;
      font-weight: 700;
      font-family: Verizon-NHG-eDS, "Helvetica Neue", helvetica, arial,
      sans-serif;

      /* horizontal rule styling */
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100vw;
        border-bottom: 1px solid #d8dada;
        left: calc(-1 * calc(100vw - 100%) / 2);
      }

      @media screen and (min-width: ${minTablet}px) {
        padding-bottom: 0.5rem;
        font-size: 0.75rem;
        font-family: Verizon-NHG-eTX, "Helvetica Neue", helvetica, arial,
        sans-serif;
        font-weight: normal;
        &:after {
          width: 100%;
          left: 0;
          border-bottom-color: #000000;
        }
      }
    }

    .scrollbar {
      overflow-y: auto;
      @media screen and (max-width: ${minTablet - 1}px) {
        height: calc(70vh - 153px);
      }
    }

    ul {
      padding: 0.5rem 0.5rem 1rem 0;
      margin: 0;
      height: 8.25rem;
      list-style: none;
    }

    li {
      margin-top: 0.5rem;
      & + & {
        margin-top: 1rem;
      }

      span {
        word-break: break-all;
      }
    }

    .info + .link-dark {
      margin-left: 1rem;
    }
  }
`;

