import {
  Button,
  Form,
  Input,
  Select,
  Title,
  Body,
  FlexBox,
  ButtonGroup,
  Notification,
} from "components";
import { FormikProps } from "interfaces";
import { useDispatch, useSelector } from "hooks/redux";
import {
  resetAuthNotification,
  selectAuthNotification,
  selectStatus,
} from "services";
import { skipSms } from "services";
import { useContext } from "react";
import { AuthContext } from "contexts/authContext";

export type props = {
  countries: Array<any>;
};

const MfaSms = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  errors,
  isValid,
  countries,
  dirty,
}: props & FormikProps) => {
  const dispatch = useDispatch(),
    isLoading = useSelector(selectStatus) === "LOADING";

  const { setPhone } = useContext<any>(AuthContext),
    handleOnSubmit = (e: any) => {
      handleSubmit(e);
      const plusCode =
          countries.find((val: any) => val.id === values.country)
            ?.plusPhoneCode || null,
        phoneNumber = plusCode + values.phone;
      setPhone(phoneNumber);
    },
    handleSkipSms = () => {
      dispatch(skipSms());
    };

  return (
    <Form onSubmit={handleOnSubmit} maxWidth="360px">
      <Title bold size="large" pd="0 0 0.5rem 0">
        Set Up SMS Two-Factor Authentication
      </Title>
      <Body size="large" pd="0 0 .5rem 0">
        Enter your mobile number in the field below to help secure your account.
      </Body>
      <Body size="large" pd="0 0 2rem 0">
        Please select your country code, then enter your full phone number, with
        no spaces or dashes.
      </Body>

      <FlexBox row gap="0.75rem" pd="0 0 1.5rem 0">
        <Select
          items={countries}
          label="Country code"
          name="country"
          value={values.country}
          optionLabels={["code", "plusPhoneCode"]}
          onChange={handleChange}
          disabled={isLoading}
          errorText={errors.country}
          data-testid="country"
          maxWidth="125px"
        />
        <Input
          name="phone"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isLoading}
          error={touched.phone && !!errors.phone}
          errorText={errors.phone}
          data-testid="phone"
        />
      </FlexBox>
      <Notification
        selector={selectAuthNotification}
        reset={resetAuthNotification}
        inline
      />
      <ButtonGroup pd="1.5rem 0 0 0">
        <Button
          type="submit"
          disabled={isLoading || !(dirty && isValid)}
          data-testid="submit"
        >
          Submit
        </Button>
        <Button
          type="button"
          disabled={isLoading}
          onClick={handleSkipSms}
          data-testid="skip"
          use="secondary"
        >
          Skip for now
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default MfaSms;
