import styled from "styled-components";
import { CaretButtonProps } from "./CaretButton";

export const StyledButton = styled.button<CaretButtonProps>`
  border: none;
  background: transparent;

  display: flex;
  flex-direction: row;
  text-decoration: none;
  width: fit-content;
  padding: ${(props) => props.pd || 0};

  align-items: center;
  text-align: left;

  .caret {
    position: relative;
    top: 0;
    left: 0;
    transition: left 500ms ease-in-out;
  }

  &:hover:not([disabled]) {
    cursor: pointer;
    //.caret {
    //  left: 0.2rem;
    //}
  }

  p {
    align-items: center !important;
  }
`;
