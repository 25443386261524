import { Button, FlexBox } from "components";
import { useNavigate, useRouteError } from "react-router-dom";

const ErrorBoundary = () => {
  document.title = "Error | Brand Central";
  const navigate = useNavigate(),
    error = useRouteError() as any;
  console.log("ErrorBoundary", error);

  async function handleNav() {
    navigate(-1);
  }

  return (
    <FlexBox justify="center" align="center">
      <h3>An error has occurred. Please return to previous page.</h3>

      <Button onClick={handleNav}>return</Button>
    </FlexBox>
  );
};

export default ErrorBoundary;
