import {
  StyledBanner,
  StyledTextLinkWrapper,
  StyledCountWrapper,
} from "./FileCourierBanner.module";
import { Body, FlexBox, TextLink } from "components";

type FileCourierBannerProps = {
  fileCount: number;
  viewLink: string;
  sortSentBy: string;
  sortReceivedBy: string;
};

const FileCourierBanner = ({
  fileCount,
  viewLink,
  sortSentBy,
  sortReceivedBy,
}: FileCourierBannerProps) => {
  return (
    <StyledBanner data-testid="file-courier-banner">
      <FlexBox row gap=".5rem" align="center">
        <StyledCountWrapper>
          <Body bold size="small" color="#ffffff">
            {fileCount}
          </Body>
        </StyledCountWrapper>
        <Body bold size="small" color="#ffffff">
          {`new file${fileCount > 1 ? "s " : ""} received.`}
        </Body>
        <StyledTextLinkWrapper>
          <TextLink
            to={viewLink}
            state={{ sortSentBy, sortReceivedBy }}
            bold
            size="large"
            data-testid="view-link"
          >
            View
          </TextLink>
        </StyledTextLinkWrapper>
      </FlexBox>
    </StyledBanner>
  );
};

export default FileCourierBanner;
