import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  height: 100%;

  padding: 1.5rem;
  text-decoration: none;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: black;
  background: #f6f6f6;
  border: 1px solid black;
  border-radius: 0.5rem;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
  transition: all 0.1s ease-in-out;

  path {
    fill: black;
  }

  &:hover {
    transform: scale(1.025);
    * p,
    * h3 {
      color: white;
    }
    background: black;

    path {
      fill: white;
    }

    .step-description {
      visibility: visible;
    }
    .caret {
      transition: left 500ms ease-in-out;
      &.right {
        left: 0.25rem;
      }
    }
  }

  .step-description {
    visibility: hidden;
  }

  &.disabled {
    /* pointer-events: none; */
    cursor: default;
  }
`;

export const StyledExternalLink = styled.a`
  height: 100%;

  padding: 1.5rem;
  text-decoration: none;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: black;
  background: #f6f6f6;
  border: 1px solid black;
  border-radius: 0.5rem;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
  transition: all 0.1s ease-in-out;

  path {
    fill: black;
  }

  &:hover {
    transform: scale(1.025);
    * p,
    * h3 {
      color: white;
    }
    background: black;

    path {
      fill: white;
    }

    .step-description {
      visibility: visible;
    }
    .caret {
      transition: left 500ms ease-in-out;
      &.right {
        left: 0.25rem;
      }
    }
  }

  .step-description {
    visibility: hidden;
  }
`;

export const StyledStep = styled.div`
  height: 100%;

  padding: 1.5rem;
  text-decoration: none;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: black;
  background: #f6f6f6;
  border: 1px solid black;
  border-radius: 0.5rem;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
  transition: all 0.1s ease-in-out;

  path {
    fill: black;
  }

  &:hover {
    transform: scale(1.025);
    * p,
    * h3 {
      color: white;
    }
    background: black;

    path {
      fill: white;
    }

    .step-description {
      visibility: visible;
    }
    .caret {
      transition: left 500ms ease-in-out;
      &.right {
        left: 0.25rem;
      }
    }
  }

  .step-description {
    visibility: hidden;
  }
`;
