import styled from "styled-components";

export const StyledTag = styled.div`
  display: flex;
  align-items: center;

  overflow: hidden;

  margin: 0.25rem;
  border-radius: 0.25rem;
  padding: 0.5rem;
  background-color: #ffffff;
`;
