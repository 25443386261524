import styled from "styled-components";
import { minTablet } from "theme/variables";


interface TabsProps {
  small?: boolean;
  noBorder?: boolean;
}
interface TabProps {
  className?: string;
}

interface TabListProps {
  category?: boolean;
}

export const TabsWrapper = styled.div<TabsProps>`
  width: 100%;
`;

export const StyledTabs = styled.ul<TabListProps>`
  display: flex;
  margin: 0px;
  list-style-type: none;
  box-shadow: rgb(216, 218, 218) 0px -1px 0px 0px inset;
  padding: 0 0 0.0625rem;
  margin-bottom: 1rem;
  @media screen and (max-width: ${minTablet - 1}px) {
    overflow-x: auto;
    margin-bottom: ${(props) => (props.category ? "1rem" : "5rem")};
  }
  @media screen and (min-width: ${minTablet}px) {
    overflow: visible hidden;
    overflow-x: auto;
    margin-bottom: 1rem;
  }
`;

export const StyledTab = styled.li`
  position: relative;
  display: flex;
  outline: none;
  margin: 0px 1.5rem 0px 0px;
  /* background: transparent;
  border: none;

  &:hover {
    font-weight: 700;
  }
  button[disabled] {
    pointer-events: none;
  }
  :not(.active) button[disabled] {
    > span {
      color: #d8dada;
    }
  } */
`;

export const StyledTabButton = styled.button`
  position: relative;
  padding: 0px;
  border-width: 0px 0px 4px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  border-image: initial;
  display: inline-block;
  margin: 0px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  appearance: none;
  border-radius: 0px;
  min-height: 44px;
  min-width: 44px;
  width: auto;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  &:before {
    display: block;
    background: none;
    box-shadow: none;
    content: "";
    width: 100%;
    position: absolute;
    bottom: -5px;
    height: 4px;
    z-index: 1;
  }

  > span {
    font-weight: 700;
    font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
    letter-spacing: 0.03125rem;
    color: rgb(111, 113, 113);
    margin: 0px;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1rem;

    &:hover {
      color: #000000;
    }
  }

  &.active {
    &:before {
      background: rgb(238, 0, 0);
    }
    > span {
      color: #000000;
    }
  }
`;
