import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { useDispatch } from "hooks/redux";
import { logout } from "services";

export interface AuthProps {
  auth: boolean | null;
}

export const withAuth = () => (WrappedComponent: any) => {
  const Component = (props: any) => {
    const dispatch = useDispatch();

    async function checkAuthenticated() {
      try {
        await Auth.currentSession();
        await Auth.currentAuthenticatedUser();
        _auth(true);
      } catch (err) {
        dispatch(logout());
        _auth(false);
      }
    }

    const [auth, _auth] = useState<boolean | null>(null),
      location = useLocation();

    useEffect(() => {
      _auth(null);
      checkAuthenticated();
    }, [location]);

    return <WrappedComponent auth={auth} {...props} />;
  };

  return Component;
};
