import React from 'react';
import PropTypes from 'prop-types';
import { InputField as InputField$1 } from '@vds-core/inputs';
import { FormControlsTokens } from '@vds-tokens/form-controls';
import { ColorTokens } from '@vds-tokens/color';

const _getBorderColor = function (disabled, surface, error, success, focused, hovered, isOpen) {
  return disabled && surface !== 'dark' ? ColorTokens.interactive.disabled.onlight.value : disabled && surface === 'dark' ? ColorTokens.interactive.disabled.ondark.value : (isOpen || focused || hovered) && surface !== 'dark' && !disabled ? ColorTokens.elements.primary.onlight.value : (isOpen || focused || hovered) && surface === 'dark' & !disabled ? ColorTokens.elements.primary.ondark.value : error && !disabled && surface !== 'dark' ? ColorTokens.feedback.error.onlight.value : error && !disabled && surface === 'dark' ? ColorTokens.feedback.error.ondark.value : success && !disabled && surface !== 'dark' ? ColorTokens.feedback.success.onlight.value : success && !disabled && surface === 'dark' ? ColorTokens.feedback.success.ondark.value : surface === 'dark' ? FormControlsTokens.color.border.ondark.value : FormControlsTokens.color.border.onlight.value;
};
const _getBorderBottomColor = function (disabled, surface, error, success, focused, hovered, isOpen) {
  return disabled && surface !== 'dark' ? ColorTokens.interactive.disabled.onlight.value : disabled && surface === 'dark' ? ColorTokens.interactive.disabled.ondark.value : error && surface !== 'dark' && !focused && !hovered && !isOpen ? ColorTokens.feedback.error.onlight.value : error && surface === 'dark' && !focused && !hovered && !isOpen ? ColorTokens.feedback.error.ondark.value : success && surface !== 'dark' && !focused && !hovered ? ColorTokens.feedback.success.onlight.value : success && surface === 'dark' && !focused && !hovered ? ColorTokens.feedback.success.ondark.value : !isOpen && !focused && !hovered && surface === 'dark' && !disabled ? ColorTokens.elements.secondary.ondark.value : !isOpen && !focused && !hovered && surface !== 'dark' && !disabled ? ColorTokens.elements.secondary.onlight.value : surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value;
};
const propTypes = {
  /**
   * If provided, the Input will be rendered with transparent background.
   */
  transparentBackground: PropTypes.bool,
  /**
   * Render the component in surface background dark or light
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * @deprecated String, array or node containing the title of the Tooltip for Input label
   */
  tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  /**
   * @deprecated String, array or node containing the body of the Tooltip for Input label
   */
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  /**
   * @deprecated If provided, will render the custom text for Close button for Tooltip when on mobile/touch devices
   */
  tooltipCloseButtonText: PropTypes.string,
  /**
   * Config object for tooltip option
   */
  tooltip: PropTypes.shape({
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    closeButtonText: PropTypes.string,
    'data-track': PropTypes.string,
    'data-track-ignore': PropTypes.string,
    'data-analyticstrack': PropTypes.string,
    'data-clickstream': PropTypes.string
  })
};
const defaultProps = {
  transparentBackground: false,
  surface: 'light',
  tooltipCloseButtonText: 'Close'
};
const InputField = function (props) {
  return React.createElement(InputField$1, Object.assign({}, props, {
    renderSecurityCodeTooltipDialog: true,
    maxWidth: null,
    getBorderColor: _getBorderColor,
    getBorderBottomColor: _getBorderBottomColor
  }));
};
InputField.propTypes = propTypes;
InputField.defaultProps = defaultProps;

export { InputField as default };
