import { useNavigate } from "react-router-dom";
import paths from "routing/paths";
import { useEffect } from "react";
import Loader from "components/Loader/Loader";
import { Auth } from "aws-amplify";
import { useDispatch } from "hooks/redux";
import { logout, setNotification } from "services";

const SignOutRedirect = () => {
  const dispatch = useDispatch(),
    navigate = useNavigate();

  async function handleSignOut() {
    try {
      await Auth.signOut();
      dispatch(logout());
      navigate(paths.SIGNIN);
    } catch (err) {
      dispatch(
        setNotification({
          type: "error",
          message: "An error has occurred. Please try again.",
        })
      );
      console.log("Error on logout:", err);
    }
  }

  useEffect(() => {
    handleSignOut();
  }, []);

  return <Loader active />;
};

export default SignOutRedirect;
