import { Toggle as VdsToggle } from "@vds/toggles";
import { FlexBox, Body } from "components";

export interface ToggleProps {
  disabled?: boolean;
  name?: string;
  onChange?: Function;
  showText?: boolean;
  on?: boolean;
  textPosition?: "left" | "right";
  textWeight?: "regular" | "bold";
  textSize?: "small" | "large";
  surface?: "light" | "dark";
  value: string | number;
  statusText?: string;
}

const Toggle = ({
  statusText,
  showText,
  on,
  value,
  textPosition,
  ...rest
}: ToggleProps) => {
  return (
    <FlexBox row gap="1rem" align="center">
      {showText && textPosition === "left" && <Body bold>{statusText}</Body>}
      <VdsToggle value={value} on={on} {...rest} />
      {showText && textPosition === "right" && <Body bold>{statusText}</Body>}
    </FlexBox>
  );
};

export default Toggle;
