const AUTH_STATE = {
  NA: "N/A",
  MFA_CHOICE: "MFA_CHOICE",
  MFA_CODE: "MFA_CODE",
  MFA_SMS: "MFA_SMS",
  MFA_SMS_CODE: "MFA_SMS_CODE",
  RESET_CODE: "RESET_CODE",
  SIGNUP_CODE: "SIGNUP_CODE",
  SIGNUP_RESEND: "SIGNUP_RESEND",
  SIGNUP_PENDING: "SIGNUP_PENDING",
  AUTHENTICATED: "AUTHENTICATED",
  MFA_SMS_SKIPPED: "MFA_SMS_SKIPPED",
};

export default AUTH_STATE;
