import { HandleSubmitProps } from "interfaces";

interface props {
  code: string;
}

export default function (
  values: props,
  { props: { action } }: HandleSubmitProps
) {
  return action({ ...values });
}
