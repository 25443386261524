import { useEffect, useState, useRef } from "react";
import {
  SearchSection,
  SearchBar,
  SearchForm,
  SearchDropdown,
  StyledRecentSearchesGrid,
  SearchBackdrop,
  SectionWrapper,
  SearchLogo,
  CloseContainer,
  SearchBarContainer,
  StyledRecentSearchesList,
  StyledList
} from "./GlobalSearch.module";
import { Icon } from "@vds/icons";
import {
  GlobalSearchProps,
  SavedSearchResponse,
} from "interfaces";
import { FormikProps } from "interfaces";
import { Body, FlexBox, IconButton, Title } from "components";
import {
  useDeleteRecentSearchMutation,
  useLazyGetRecentSearchesQuery,
  useLazyGetRelatedSearchesQuery,
} from "services/api/api.slice";
import { debounce } from "utils";
import paths from "routing/paths";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import Image from "../../components/layouts/Image/Image";
import {verizonCheckmark} from "../../assets";
import {Grid} from "@mui/material";

const GlobalSearch = ({
  open,
  onClose,
  values: { search },
  setFieldValue,
  resetForm,
}: GlobalSearchProps & FormikProps) => {
  const navigate = useNavigate(),
    dispatch = useDispatch();

  const searchRef = useRef<HTMLInputElement>(null),
    searchSectionRef = useRef<HTMLInputElement>(null);
  
  const [suggestedSearch, _setSuggestedSearch] = useState<Array<string>>([]),
    [recentSearches, _setRecentSearches] = useState([]),
    [callSuggestedSearch, { data: suggestedSearchResponse, isLoading: suggestedSearchLoading, isFetching: suggestedSearchFetching }] = useLazyGetRelatedSearchesQuery(),
    [callRecentSearches, { data: recentSearchesResponse, isLoading: recentSearchLoading, isFetching: recentSearchFetching }] =
      useLazyGetRecentSearchesQuery(),
    [callDeleteRecentSearch] = useDeleteRecentSearchMutation();
  
  useEffect(() => {
    if (!recentSearchLoading && !recentSearchFetching && recentSearchesResponse) {
      _setRecentSearches(recentSearchesResponse);
    }
  }, [recentSearchesResponse, recentSearchLoading, recentSearchFetching]);
  
  
  useEffect(() => {
    if (!suggestedSearchLoading && !suggestedSearchFetching && suggestedSearchResponse && suggestedSearchResponse.results) {
      _setSuggestedSearch(suggestedSearchResponse.results);
    }
  }, [suggestedSearchResponse, suggestedSearchLoading, suggestedSearchFetching]);
  
  const handleRecentSearch =
    (recent: { searchId: string; searchTerm: string }) => () => {
      if (location.pathname.startsWith("/guidelines/")) {
        localStorage.setItem("searchPathFromGuidelines", `${recent.searchTerm}`);
      }
      setFieldValue("search", recent.searchTerm);
      navigate(paths.ASSET_SEARCH, {
        state: {
          saved: {
            search: recent.searchTerm,
          },
        },
      });
      handleClose();
    };
  const handleClose = () => {
      onClose();
      _setSuggestedSearch([]);
      const closeTimeout = setTimeout(() => {
        resetForm();
        clearTimeout(closeTimeout);
      }, 200);
    };
  
  const location = useLocation();
  const handleSearch = (e: any) => {
      if (e.key === "Enter") {
        if (location.pathname.startsWith("/guidelines/")) {
          localStorage.setItem("searchPathFromGuidelines", `${search}`);
        }
        navigate(paths.ASSET_SEARCH, {
          state: {
            saved: {
              search: search,
            },
          },
        });
        handleClose();
      }
    };
  
  const getRecentSearches = () => {
    callRecentSearches()
    .unwrap()
    .then()
    .catch((error: any) => {
      console.error("error getting recent searches", error);
    });
  };
  
  const callSuggested = (search: string) => {
    callSuggestedSearch({
      search: search,
      correlationId: undefined
    })
    .unwrap()
    .then()
    .catch((error: any) => {
      console.error("error getting suggested searches", error);
    });
  };
  const handleChange = async (e: any) => {
      setFieldValue("search", e.target.value);
      
    const callSuggestedDebounced = debounce((): void => {
          callSuggested(e.target.value);
        }, 1000);
      callSuggestedDebounced();
    };
  
  const handleClearSearch = (e: any) => {
    if (e.key && e.key === "Enter") {
      setFieldValue("search", "");
    } else if (!e.key) {
      setFieldValue("search", "");
    }
    _setSuggestedSearch([]);
  };
  
  const [deletingSearch, _setDeletingSearch] = useState<Array<string>>([]);
  const handleDeleteRecentSearch =
    (recent: { searchId: string; searchTerm: string }) => () => {
      _setDeletingSearch((oldArray) => [...oldArray, recent.searchId]);
      callDeleteRecentSearch({ searchId: recent.searchId })
      .unwrap()
      .then(() => {
      })
      .finally(() => {
        getRecentSearches();
      });
    };
  
  const handleSuggestSearch = (suggested: string) => () => {
    if (location.pathname.startsWith("/guidelines/")) {
      localStorage.setItem("searchPathFromGuidelines", `${suggested}`);
    }
    setFieldValue("search", suggested);
    navigate(paths.ASSET_SEARCH, {
      state: {
        saved: {
          search: suggested,
        },
      },
    });
    handleClose();
  };


  useEffect(() => {
    if (open) {
      getRecentSearches();
    }
    const focusTimeout = setTimeout(() => {
      open && searchRef.current?.focus();
    }, 200);
    return () => {
      clearTimeout(focusTimeout);
    };
  }, [open]);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
        !searchSectionRef.current?.contains(e.target) && handleClose();
      },
      handleScroll = () => {
        searchRef.current?.blur();
        handleClose();
      };
    document.addEventListener("click", handleClickOutside, true);
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [searchSectionRef]);

  return (
    <>
      <SearchSection
        ref={searchSectionRef}
        className={open ? "open" : ""}
        data-testid="search-section"
      >
        <SearchLogo row justify="stretch" align="center">
          <Link to={paths.HOME} className="home-icon">
            <Image
              src={verizonCheckmark}
              className="logo"
              width={33}
              height={40}
            />
          </Link>
          <CloseContainer flex="1" row>
            <IconButton
              name="close"
              size="large"
              onClick={handleClose}
              noHover
              color="#000000"
              testId="search-close"
            />
          </CloseContainer>
          
        </SearchLogo>
        <SearchBarContainer container>
          <Grid xs={0} lg={3} item></Grid>
          <Grid xs={0} lg={3} item></Grid>
          <SearchBar xs={12} lg={6} item data-testid="search-bar">
            <SearchForm>
              <Icon name="search" color="#000000" size="XLarge" />
              <input
                ref={searchRef}
                name="search"
                value={search}
                onChange={handleChange}
                onKeyDown={handleSearch}
                autoComplete="off"
                placeholder="Search Brand Central"
                data-testid="search-input"
              />
              {search.length > 0 && (
                <IconButton
                  name="close-alt"
                  surface="dark"
                  color="#000000"
                  onClick={handleClearSearch}
                  onKeyDown={(e: any) => handleClearSearch(e)}
                  id="clear-search-field"
                  ariaLabel={"clear search term"}
                  size="XLarge"
                  testId="clear-search"
                />
              )}
            
            </SearchForm>
          </SearchBar>
        </SearchBarContainer>
        
        <SearchDropdown
          className={open ? "open" : ""}
          data-testid="search-dropdown"
        >
          
          <SectionWrapper data-testid="with-search">
            {recentSearches.length > 0 ? (
              <StyledRecentSearchesGrid container data-testid="recent-and-suggested-searches">
                <Grid item xs={0} lg={6}>
                </Grid>
                <Grid container rowSpacing="2rem" item xs={12} lg={6}>
                  <Grid item xs={12} sm={5} lg={6} xl={5}>
                    <StyledRecentSearchesList data-testid="recent-searches">
                      <Title color="#000000" size="small" bold pd="0 0 0.5rem">
                        Recent Searches
                      </Title>
                      {recentSearches.map(
                        (recent: { searchId: string; searchTerm: string }) => (
                          <li key={recent.searchId}>
                            <button
                              className="recent"
                              onClick={handleRecentSearch(recent)}
                              disabled={deletingSearch.includes(recent.searchId)}
                              data-testid="search-recent-button"
                            >
                              <Body size="large" color="#000000">
                                {recent.searchTerm}
                              </Body>
                            </button>
                            <IconButton
                              className="delete-button"
                              name="close"
                              noHover
                              color="#000000"
                              onClick={handleDeleteRecentSearch(recent)}
                              disabled={deletingSearch.includes(recent.searchId)}
                              testId="delete-recent-button"
                            />
                          </li>
                        )
                      )}
                    </StyledRecentSearchesList>
                  </Grid>
                  <Grid item xs={12} sm={7} lg={6} xl={7}>
                    {suggestedSearch.length > 0 ? (
                      <>
                        <Title color="#000000" size="small" bold pd="0 0 0.5rem">
                          Suggested Searches
                        </Title>
                        <StyledList data-testid="suggested-searches">
                          {suggestedSearch.map((suggested: string, index: number) => (
                            <li key={suggested + index}>
                              <button
                                className="suggested"
                                onClick={handleSuggestSearch(suggested)}
                                data-testid="search-suggested-button"
                                color="#000000"
                              >
                                <Body color="#000000" size="large">{suggested}</Body>
                              </button>
                            </li>
                          ))}
                        </StyledList>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  
                </Grid>
              </StyledRecentSearchesGrid>
            ) : (
              <StyledRecentSearchesGrid container data-testid="suggested-searches-only">
                <Grid item xs={0} lg={6}>
                </Grid>
                <Grid container rowSpacing="2rem" item xs={12} lg={6}>
                  <Grid item xs={12} sm={7} lg={6} xl={7}>
                    {suggestedSearch.length > 0 ? (
                      <>
                        <Title color="#ffffff" size="small" bold pd="0 0 0.5rem">
                          Suggested Searches
                        </Title>
                        <StyledList>
                          {suggestedSearch.map((suggested: string, index: number) => (
                            <li key={suggested + index}>
                              <button
                                className="suggested"
                                onClick={handleSuggestSearch(suggested)}
                                data-testid="search-suggested-button"
                                color="#ffffff"
                              >
                                <Body bold color="#a7a7a7" size="large">{suggested}</Body>
                              </button>
                            </li>
                          ))}
                        </StyledList>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </StyledRecentSearchesGrid>
            )}
          </SectionWrapper>
        </SearchDropdown>
      </SearchSection>
      {open && (
        <SearchBackdrop className="open" data-testid="search-backdrop" />
      )}
    </>
  );
};

export default GlobalSearch;
