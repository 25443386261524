import { Autocomplete, TextField } from "@mui/material";
import { Icon } from "@vds/icons";
import { MultiSelectProps } from "interfaces";
import { Body, FlexBox, IconButton, Tag, Title, TwoUp } from "components";
import { StyledSelectText, StyledSelected } from "./MultiSelect.module";

const MultiSelect = ({
  label,
  title,
  value,
  freeSolo = false,
  options,
  optionLabels = ["name"],
  valueKey = "id",
  onChange,
  selectedLabel,
  removeSelected,
  addCustom = false,
  orientation = "vertical",
  disabled,
  callback,
}: MultiSelectProps) => {
  const generateOptionLabel = (option: any) => {
      return optionLabels.reduce(
        (result: string, item: string, index: number) => {
          if (index === optionLabels.length - 1) {
            return `${result}${option[item]}`;
          }
          return `${result}${option[item]} `;
        },
        ""
      );
    },
    InputComponent = (
      <>
        {/* {title ? (
          <Title size="small" bold pd="0 0 0.75rem 0">
            {label}
          </Title>
        ) : (
          <Body pd="0 0 0.25rem 0">{label}</Body>
        )} */}
        <Autocomplete
          value={value}
          options={options}
          autoComplete
          multiple
          fullWidth
          freeSolo={freeSolo}
          onChange={(_, value: readonly any[], reason) => {
            const tempValue: any[] = JSON.parse(JSON.stringify(value));
            if (reason !== "removeOption") {
              onChange(_, tempValue, reason);
              callback && callback(tempValue[tempValue.length - 1]);
            }
          }}
          renderInput={(params) => <TextField {...params} color="secondary" />}
          renderOption={(props, option) => {
            const optionLabel = generateOptionLabel(option);
            return (
              <li {...props} key={option.id || optionLabel}>
                <StyledSelectText>
                  <Body className="ellipsis" size="large">
                    {optionLabel}
                  </Body>
                </StyledSelectText>
              </li>
            );
          }}
          getOptionLabel={(option: any) => generateOptionLabel(option)}
          ListboxProps={{
            style: { maxHeight: "10rem" },
          }}
          filterSelectedOptions
          filterOptions={(options, params) => {
            const { inputValue } = params,
              contains = options.filter((option) =>
                optionLabels
                  .map((label) =>
                    option[label]
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  )
                  .includes(true)
              );

            if (addCustom) {
              const match = contains.some((option) =>
                optionLabels.map((label) => option[label]).includes(inputValue)
              );

              if (inputValue !== "" && !match) {
                contains.unshift({
                  [optionLabels[0]]: `Add "${inputValue}"`,
                  value: inputValue,
                });
              }
            }
            return contains;
          }}
          renderTags={() => null}
          isOptionEqualToValue={(option: any, value: any) => {
            return option[valueKey] === value[valueKey];
          }}
          forcePopupIcon
          popupIcon={<Icon name="down-caret" />}
          disableClearable
          disabled={disabled}
          data-testid="multi-select"
        />
      </>
    ),
    ResultComponent = value.length ? (
      <FlexBox
        pd={orientation === "vertical" ? "1rem 0 0.25rem 0" : ""}
        data-testid="multi-select-selected"
      >
        {selectedLabel && (
          <Body size="large" pd="0 0 0.25rem">
            {selectedLabel}
          </Body>
        )}
        <StyledSelected>
          {value.map((item: any) => (
            <Tag
              key={
                item.id ||
                optionLabels.reduce(
                  (result: string, label: string, index: number) => {
                    if (index === optionLabels.length - 1) {
                      return `${result}${item[label]}`;
                    }
                    return `${result}${item[label]} `;
                  },
                  ""
                )
              }
            >
              <IconButton
                name="close"
                size="small"
                noHover
                onClick={removeSelected && removeSelected(item)}
                disabled={disabled}
              />
              <StyledSelectText>
                <Body className="ellipsis">{generateOptionLabel(item)}</Body>
              </StyledSelectText>
            </Tag>
          ))}
        </StyledSelected>
      </FlexBox>
    ) : null;

  return orientation === "vertical" ? (
    <>
      {title ? (
        <Title size="small" bold pd="0 0 0.75rem 0">
          {label}
        </Title>
      ) : (
        <Body pd="0 0 0.25rem 0">{label}</Body>
      )}
      {InputComponent}
      {ResultComponent}
    </>
  ) : (
    <>
      {title ? (
        <Title size="small" bold pd="0 0 0.75rem 0">
          {label}
        </Title>
      ) : (
        <Body pd="0 0 0.25rem 0">{label}</Body>
      )}
      <TwoUp>
        {InputComponent}
        {ResultComponent}
      </TwoUp>
    </>
  );
};

export default MultiSelect;
