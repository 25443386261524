import React from 'react';
import PropTypes from 'prop-types';
import { RadioBox as RadioBox$1 } from '@vds-core/radio-boxes';
import { ColorTokens } from '@vds-tokens/color';
import { FormControlsTokens } from '@vds-tokens/form-controls';
import { TypographyTokens } from '@vds-tokens/typography';

const propTypes = {
  /**
   * If provided, the RadioBox will be rendered with transparent background.
   */
  transparentBackground: PropTypes.bool,
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  transparentBackground: false,
  surface: 'light'
};
const RadioBox = function (props) {
  return React.createElement(RadioBox$1, Object.assign({}, props, {
    borderActiveIndicator: "outline",
    borderColor: FormControlsTokens.color.border.onlight.value,
    borderRadius: FormControlsTokens.border.radius.value,
    borderColorActive: ColorTokens.elements.primary.onlight.value,
    borderColorSelected: ColorTokens.elements.primary.onlight.value,
    fontSize: TypographyTokens.fontsize.body[16].value,
    letterSpacing: TypographyTokens.letterspacing.wide.value,
    lineHeight: TypographyTokens.lineheight.body[20].value
  }));
};
RadioBox.propTypes = propTypes;
RadioBox.defaultProps = defaultProps;

export { RadioBox as default };
