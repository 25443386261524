import { __rest } from '../_virtual/_tslib.js';
import React from 'react';
import PropTypes from 'prop-types';
import { TableRow as TableRow$1 } from '@vds-core/tables';

const propTypes = {
  /**
   * If provided, will override the border configuration and apply to this specific row
   * @note If bottomLine is 'none' in Table and bottomLine is provided here, this will render the border bottom for this specific row
   */
  bottomLine: PropTypes.oneOf(['primary', 'secondary', 'none'])
};
const defaultProps = {
  bottomLine: null // Controlled in Table by default via bottomLine prop
};

const TableRow = function (props) {
  const {
      bottomLine
    } = props,
    rest = __rest(props, ["bottomLine"]);
  return React.createElement(TableRow$1, Object.assign({
    bottomLine: bottomLine
  }, rest));
};
TableRow.propTypes = propTypes;
TableRow.defaultProps = defaultProps;

export { TableRow as default };
