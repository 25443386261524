import styled from "styled-components";
import {minTablet} from "../../../theme/variables";

interface ProtectedWrapperProps {
  guidelinesPage?: boolean;
}

export const ProtectedWrapper = styled.main<ProtectedWrapperProps>`
  line-height: 1.25;
  min-height: ${(props: ProtectedWrapperProps) =>
    `${props.guidelinesPage ? "3.5rem" : "100vh"}`};
  @media (min-width: ${minTablet}px) {
    min-height: ${(props: ProtectedWrapperProps) =>
      `${props.guidelinesPage ? "5.5rem" : "100vh"}`};
  }

  &.intro-visible {
    max-height: 100vh;
    overflow: hidden;
  }
`;
