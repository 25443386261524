import { object, string, array, boolean } from "yup";

const validationSchema = object().shape({
  sendToUsers: array().min(1).required("You must enter at least one Email"),
  subject: string().required("You must enter a Subject"),
  message: string().required("You must enter a Message"),
  expirationDate: string().required("You must enter a Valid Until Date"),
  sendCopy: boolean(),
  files: array().min(1).required("You must upload at least one File"),
});

export default () => validationSchema;