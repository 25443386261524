import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AccordionDetail as AccordionDetail$1 } from '@vds-core/accordions';

const propTypes = {
  /**
   * @ignore
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
class AccordionDetail extends Component {
  render() {
    return React.createElement(AccordionDetail$1, Object.assign({}, this.props));
  }
}
AccordionDetail.componentType = 'AccordionDetail';
AccordionDetail.propTypes = propTypes;
AccordionDetail.defaultProps = defaultProps;

export { AccordionDetail as default };
