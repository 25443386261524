import styled from "styled-components";
import { UnprotectedFooterProps } from "./UnprotectedFooter";

export const StyledUnprotectedFooter = styled(
  ({ position, ...props }) => <div {...props} />
)<UnprotectedFooterProps>`
  position: relative;
  top: 4rem;
  padding-bottom: 4rem;
`;
