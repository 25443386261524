import React, { Component } from 'react';
import styled, { keyframes, css } from 'styled-components';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import UNSAFE_SetEnvRef from '@uie/set-env-ref';
import animationConfig from './animationConfig.js';
import { withVDSManager } from '@vds-core/utilities';

// This polyfill prevents Intersection Observer from breaking IE
require('intersection-observer');
const propTypes = {
  /**
   * @ignore
   * ref for component
   */
  // setAnimationRef: PropTypes.func,
  /**
  /**
   * Animation name
   */
  name: PropTypes.oneOf(['slideUp', 'fadeInUp']),
  /**
   * Pass a boolean value to start the animation, if set to true, animation starts when component mounts.
   */
  startAnimation: PropTypes.bool,
  /**
   *
   */
  animated: PropTypes.bool
};
const defaultProps = {
  // setAnimationRef: () => {},
  name: 'slideUp',
  startAnimation: false,
  animated: true
};
const buildAnimation = animation => {
  if (animation === {} || !animation) {
    return null;
  }
  const {
    duration,
    delay,
    timingFunction,
    keyFrame
  } = animation;
  const animationKeyframe = keyframes`${keyFrame}`;
  // use css helper when using keyframes see https://styled-components.com/docs/basics#animations
  return css`
    animation: ${duration} ${timingFunction} ${delay} ${animationKeyframe};
  `;
};
const AnimatorWrapper = styled.div.withConfig({
  displayName: "AnimatorWrapper",
  componentId: "VDS__sc-12xw7k2-0"
})`
  position: relative;
  ${({
  animation,
  animated
}) => animated && buildAnimation(animation)};

  animation-play-state: ${({
  startAnimation
}) => startAnimation ? 'running' : 'paused'};
`;
class Animator extends Component {
  constructor(props) {
    super(props);
    this.componentDidMount = () => {
      // this.props.setAnimationRef(this.animationElem);
      if (this.props.animated) this._setupObserver();
    };
    this.componentDidUpdate = prevProps => {
      if (prevProps.animated !== this.props.animated) {
        if (this.props.animated) this._setupObserver();
      }
    };
    this._setupObserver = () => {
      const options = {
        // root: rootRef,
        rootMargin: '150px 0px 0px 0px',
        threshold: 0
      };
      this.observer = new IntersectionObserver(this._observationHandler, options);
      let elem = ReactDOM.findDOMNode(this.animationElem);
      if (!this.observer || !elem) return;
      this.observer.observe(elem);
    };
    this._observationHandler = (entries, observer) => {
      entries.forEach(entry => {
        this.shouldAnimate = entry.isIntersecting;
        if (this.shouldAnimate) {
          this.setState({
            startAnimation: true
          });
          this.observer.disconnect();
        }
      });
    };
    this.setAnimationRef = element => {
      this.animationElem = element;
    };
    this.refKey = UNSAFE_SetEnvRef();
    this.state = {
      startAnimation: false
    };
  }
  render() {
    const {
      name,
      animated /*, keyFrames*/
    } = this.props;
    const {
      startAnimation
    } = this.state;
    const animation = animationConfig[name];
    // uncomment bellow if custom keyframes are passed
    // if (keyFrames) {
    //   animation.keyFrame = `${keyFrames}`;
    // }
    return React.createElement(AnimatorWrapper, Object.assign({}, {
      [this.refKey]: ref => this.setAnimationRef(ref)
    }, {
      animated: animated,
      animation: animation,
      startAnimation: startAnimation
    }), this.props.children);
  }
}
Animator.propTypes = propTypes;
Animator.defaultProps = defaultProps;
var Animator$1 = withVDSManager(Animator);

export { Animator$1 as default };
