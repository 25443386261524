import styled from "styled-components";
import IconButton from "components/buttons/IconButton/IconButton";
import {minTablet, zIndex} from "../../../theme/variables";
import FlexBox from "components/layouts/FlexBox/FlexBox";

interface WrapperProps {
  maxWidth?: string;
  height?: string;
}

export const StyledWrapper = styled.div<WrapperProps>`
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  

  @media screen and (max-width: ${minTablet - 1}px) {
    max-width: 100vw;
    width: 100vw;
    border-radius: unset;
    padding: 1.5rem;
    max-height: 100dvh;
    height: 100dvh;
  }

  @media screen and (min-width: ${minTablet}px) {
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "70vw")};
    height: ${(props) => (props.height ? props.height : "auto")};
    max-height: 98vh;
    width: 100%;
    padding: 4rem;
    border-radius: 0.5rem;
  }

  &.allow-overflow {
    overflow-y: unset;
    /* max-height: fit-content;
    top: 1rem;
    transform: translate(-50%); */
  }
`;

export const StyledFlexBox = styled(FlexBox)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;

  > * {
    opacity: 0.8;
  }
`;

export const StyledClose = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  z-index: ${zIndex.notBottom};
`;
