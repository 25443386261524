import styled from "styled-components";
import { mobile, minTablet, mobileLarge } from "theme/variables";

export const StyledTitle = styled("div")`
`;

export const StyledStepsWrapper = styled("div")`
`;

export const StyledStep = styled("div")`
`;