import { FormikProps } from "interfaces";
import { resetAuthNotification, selectAuthNotification } from "services";
import Button from "components/buttons/Button/Button";
import ButtonGroup from "components/buttons/ButtonGroup/ButtonGroup";
import Input from "components/forms/Input/Input";
import { Form, Notification, TextArea } from "components";

import { useSendFeedbackMutation } from "services/api/api.slice";
import { useNavigate } from "react-router-dom";
import paths from "routing/paths";
import { setNotification } from "../../services";
import { useDispatch } from "hooks/redux";

interface FeedbackProps {
  user: any;
}

const FeedbackForm = ({
  values: { comments },
  handleChange,
  handleBlur,
  resetForm,
  touched,
  errors,
  dirty,
  isValid,
  user,
  setFieldValue,
}: FormikProps & FeedbackProps) => {
  const [sendFeedback, { isLoading }] = useSendFeedbackMutation(),
    navigate = useNavigate();

  const dispatch = useDispatch();

  const handleOnSubmit = () => {
    sendFeedback({ comments: comments })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: `Successfully submitted feedback form.`,
          })
        );
        navigate(paths.HOME);
      })
      .catch(() => {
        dispatch(
          setNotification({
            type: "error",
            message: `Could not submit feedback form.`,
          })
        );
      });
  };
  const handleCancel = () => {
    setFieldValue("comments", "");
    navigate(paths.HOME);
  };

  return (
    <Form data-testid="feedback-form" onSubmit={handleOnSubmit} gap="1rem">
      <Input
        value={user.name}
        required
        onChange={handleChange}
        name="name"
        disabled
        data-testid="name"
      />
      <Input
        value={user.email}
        required
        onChange={handleChange}
        name="email"
        disabled
        data-testid="email"
      />
      <TextArea
        value={comments}
        onChange={handleChange}
        onBlur={handleBlur}
        name="comments"
        error={touched.comments && !!errors.comments}
        errorText={errors.comments}
        disabled={isLoading}
        required
        data-testid="comments"
        maxLength={1000}
      />
      <Notification
        selector={selectAuthNotification}
        reset={resetAuthNotification}
        inline
      />
      <ButtonGroup align="flex-start" pd="1.5rem 0">
        <Button
          type="submit"
          use="primary"
          disabled={isLoading || !(dirty && isValid)}
          data-testid="submit"
        >
          Submit
        </Button>
        <Button
          type="button"
          use="secondary"
          data-testid="cancel"
          onClick={handleCancel}
          disabled={isLoading}
          ariaLabel="test"
        >
          Cancel
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default FeedbackForm;
