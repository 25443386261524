import { useState, ReactNode } from "react";
import {
  FlexBox,
  Table,
  Select,
  CaretLink,
  IconButton,
  Button,
  Body,
  Badge,
  CustomTooltip,
} from "components";
import { FileCourierReceivedPackageProps } from "interfaces";
import { formatDate } from "utils/dateFunctions";
import { downloadPackage } from "utils/fileCourier";
import { Grid } from "@mui/material";
import paths from "routing/paths";
import { FileCourierReceivedDetailsModal } from "features";
import SORT_BY_OPTIONS from "constants/fileCourierOptions";
import {
  StyledBodyWrapper,
  StyledHorizontalRule,
  StyledList,
} from "./FileCourierFilesReceivedTable.module";

interface FilesReceivedTableData {
  Filename: ReactNode;
  From: ReactNode;
  Received: ReactNode;
  Expires: ReactNode;
  Actions: ReactNode;
}

interface FileCourierFilesReceivedTableProps {
  data: FileCourierReceivedPackageProps[];
  isPage?: boolean;
  sortSentBy?: string;
  sortReceivedBy: string;
  onSort: (value: string) => void;
  onRefresh: () => void;
}

const FileCourierFilesReceivedTable = ({
  data,
  isPage = true,
  sortSentBy = SORT_BY_OPTIONS[0].value,
  sortReceivedBy = SORT_BY_OPTIONS[0].value,
  onSort,
  onRefresh,
}: FileCourierFilesReceivedTableProps) => {
  const [selectedPackage, setSelectedPackage] =
    useState<FileCourierReceivedPackageProps | null>(null);
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  
  const openDetails = (item: FileCourierReceivedPackageProps) => () => {
    if (item.id) {
      setSelectedPackage(item);
      setDetailsOpen(true);
    }
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
    onRefresh();
  };

  const handleUpdateSortBy = (e: any) => {
    onSort(e.target.value);
  };

  const handleDownloadPackage = async (packageId: string) => {
    await downloadPackage({ packageId });
    onRefresh();
  };

  const formatTableData = (
    source: FileCourierReceivedPackageProps[]
  ): FilesReceivedTableData[] => {
    let tableData: FilesReceivedTableData[] = [];

    if (source.length === 0) {
      return tableData;
    }

    source.forEach((item) => {
      const row: FilesReceivedTableData = {
        Filename: (
          <FlexBox data-testid="table-item">
            <FlexBox row>
              <StyledBodyWrapper>
                <Body
                  size="large"
                  testId="main-file-name"
                  className={
                    item.isExpiringSoon
                      ? "expiring-text"
                      : item.isNew
                      ? "new-only-text"
                      : ""
                  }
                >
                  {item.files[0].name}
                </Body>
              </StyledBodyWrapper>
              {item.files.length > 1 && (
                <>
                  <CustomTooltip
                    icon={
                      <Body testId="tooltip" size="large" bold>{`${
                        item.files.length - 1
                      } more`}</Body>
                    }
                    ariaLabel="show more"
                  >
                    <FlexBox minWidth="100%">
                      <Body testId="files" size="large">
                        <strong>Files</strong>
                      </Body>
                      <StyledHorizontalRule />
                      <StyledList>
                        {item.files.slice(1).map((file) => (
                          <li key={file.id}>
                            <Body>{file.name}</Body>
                          </li>
                        ))}
                      </StyledList>
                    </FlexBox>
                  </CustomTooltip>
                </>
              )}
            </FlexBox>
            <FlexBox row gap="1rem">
              {item.isNew && (
                <Badge data-testid="new-badge" key={`${item.files[0].name}-new`} fillColor="blue">
                  NEW
                </Badge>
              )}
              {item.isExpiringSoon && (
                <Badge
                  data-testid="expiring-badge"
                  key={`${item.files[0].name}-expiring`}
                  fillColor="orange"
                >
                  EXPIRING
                </Badge>
              )}
            </FlexBox>
          </FlexBox>
        ),
        From: (
          <StyledBodyWrapper>
            <Body
              size="large"
              className={
                item.isExpiringSoon
                  ? "expiring-text"
                  : item.isNew
                  ? "new-only-text"
                  : ""
              }
            >
              {`${item.sentFromFirstName} ${item.sentFromLastName}`}
            </Body>
          </StyledBodyWrapper>
        ),
        Received: (
          <StyledBodyWrapper>
            <Body
              size="large"
              className={
                item.isExpiringSoon
                  ? "expiring-text"
                  : item.isNew
                  ? "new-only-text"
                  : ""
              }
            >
              {formatDate(item.sentDate)}
            </Body>
          </StyledBodyWrapper>
        ),
        Expires: (
          <StyledBodyWrapper>
            <Body
              size="large"
              className={
                item.isExpiringSoon
                  ? "expiring-text"
                  : item.isNew
                  ? "new-only-text"
                  : ""
              }
            >
              {formatDate(item.expirationDate)}
            </Body>
          </StyledBodyWrapper>
        ),
        Actions: (
          <FlexBox row gap="1rem">
            <IconButton
              key={item.id}
              name="download"
              size="large"
              ariaLabel="download package"
              onClick={() => handleDownloadPackage(item.id)}
              addTooltip
              toolTipText="download"
              testId="download-icon-button"
            />
            <Button
              type="submit"
              use="primary"
              data-testid="details-button"
              ariaLabel="details"
              size="small"
              onClick={openDetails(item)}
            >
              Details
            </Button>
          </FlexBox>
        ),
      };

      tableData.push(row);
    });

    return tableData;
  };

  return (
    <FlexBox gap="1rem" data-testid="fc-received-table">
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={0} md={5}></Grid>
        <Grid item xs={12} md={3}>
          <Select
            items={SORT_BY_OPTIONS}
            onChange={(e: Event) => handleUpdateSortBy(e)}
            optionLabels={["value"]}
            valueKey="value"
            name="displayResults"
            label="Sort by"
            value={sortReceivedBy}
            data-testid="sort-select"
          />
        </Grid>
      </Grid>
      <Table
        striped
        padding="compact"
        headers={["File name", "From", "Received", "Expires", "Actions"]}
        items={formatTableData(
          isPage ? data : data.length > 0 ? data.slice(0, 4) : []
        )}
      />
      {!isPage && (
        <CaretLink
          caretPosition="right"
          to={paths.FILE_COURIER_RECEIVED}
          state={{ sortSentBy: sortSentBy, sortReceivedBy: sortReceivedBy }}
          pd="0 0 1rem 0"
        >
          See all
        </CaretLink>
      )}
      {selectedPackage && detailsOpen && (
        <FileCourierReceivedDetailsModal
          receivedPackage={selectedPackage}
          onClose={handleCloseDetails}
          onRefresh={onRefresh}
        />
      )}
    </FlexBox>
  );
};

export default FileCourierFilesReceivedTable;
