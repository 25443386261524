import styled from "styled-components";
import { Tab, TabProps as VdsTabProps } from "@vds/tabs";

interface TabsProps {
  small?: boolean;
  noBorder?: boolean;
}
interface TabProps {
  className?: string;
}

export const TabsWrapper = styled.div<TabsProps>`
  width: 100%;
  span {
    ${(props) => props.small && "font-size: 0.875rem; line-height: 1rem;"}
  }
  ul {
    ${(props) => props.noBorder && "box-shadow: none;"}
  }
`;

export const StyledTab = styled(Tab)<TabProps & VdsTabProps>`
  button[disabled] {
    pointer-events: none;
  }
  :not(.active) button[disabled] {
    > span {
      color: #d8dada;
    }
  }
  button {
    > span {
      white-space: nowrap;
    }
  }
`;
