import { object, string } from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = object().shape({
  country: string().min(1).required("You must enter a Country"),
  phone: string()
    .matches(phoneRegExp, "You must enter a valid Phone Number")
    .min(5)
    .required("You must enter a Phone Number"),
});

export default () => validationSchema;
