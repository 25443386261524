import { useState, ReactNode } from "react";
import {
  FlexBox,
  Table,
  Select,
  CaretLink,
  IconButton,
  Button,
  Body,
  Badge,
  DatePickerToggle,
  CustomTooltip,
} from "components";
import {
  DeleteFileCourierPackageModal,
  FileCourierSentDetailsModal,
} from "features";
import { formatDate } from "utils/dateFunctions";
import { Grid } from "@mui/material";
import { FileCourierSentPackageProps } from "interfaces";
import paths from "routing/paths";
import SORT_BY_OPTIONS from "constants/fileCourierOptions";
import {
  StyledBodyWrapper,
  StyledHorizontalRule,
  StyledList,
} from "./FileCourierFilesSentTable.module";
import {
  setNotification,
  useLazyExtendFileCourierPackageQuery,
} from "services";
import {useDispatch} from "react-redux";

interface FilesSentTableData {
  Filename: ReactNode;
  Recipient: ReactNode;
  Sent: ReactNode;
  Expires: ReactNode;
  Actions: ReactNode;
}

interface FileCourierFilesSentTableProps {
  data: FileCourierSentPackageProps[];
  isPage?: boolean;
  sortSentBy: string;
  sortReceivedBy?: string;
  onSort: (value: string) => void;
  onRefresh: () => void;
}

const FileCourierFilesSentTable = ({
  data,
  isPage = true,
  sortSentBy = SORT_BY_OPTIONS[0].value,
  sortReceivedBy = SORT_BY_OPTIONS[0].value,
  onSort,
  onRefresh,
}: FileCourierFilesSentTableProps) => {
  const [extendFileCourierPackage, { isFetching }] =
    useLazyExtendFileCourierPackageQuery();

  const [selectedPackage, setSelectedPackage] =
    useState<FileCourierSentPackageProps | null>(null);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  const handleOpenDelete = (item: FileCourierSentPackageProps) => () => {
    if (item.id) {
      setSelectedPackage(item);
      setDeleteOpen(true);
    }
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);
  };
  
  const dispatch = useDispatch();

  const handleExtendExpiration = (value: Date): void => {
    if (selectedPackage) {
      extendFileCourierPackage({
        packageId: selectedPackage.id,
        newDateTime: value.toISOString(),
      })
        .unwrap()
        .then(() => {
          setSelectedPackage(null);
          onRefresh();
        })
        .catch(() => {
          dispatch(
            setNotification({
              type: "error",
              message: `Could not extend file.`,
            })
          );
        });
    }
  };

  const handleOpenDetails = (item: FileCourierSentPackageProps) => () => {
    if (item.id) {
      setSelectedPackage(item);
      setDetailsOpen(true);
    }
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
    onRefresh();
  };

  const handleUpdateSortBy = (e: any) => {
    onSort(e.target.value);
  };

  const formatTableData = (
    source: FileCourierSentPackageProps[]
  ): FilesSentTableData[] => {
    let tableData: FilesSentTableData[] = [];

    if (source.length === 0) {
      return tableData;
    }

    source.forEach((item) => {
      const row: FilesSentTableData = {
        Filename: (
          <FlexBox data-testid="table-item">
            <FlexBox row>
              <StyledBodyWrapper>
                <Body
                  size="large"
                  testId="main-file-name"
                  className={
                    item.isExpiringSoon
                      ? "expiring-text"
                      : item.isExpired
                      ? "expired-text"
                      : ""
                  }
                >
                  {item.files[0].name}
                </Body>
              </StyledBodyWrapper>
              {item.files.length > 1 && (
                <CustomTooltip
                  icon={
                    <Body testId="tooltip" size="large" bold>{`${
                      item.files.length - 1
                    } more`}</Body>
                  }
                  ariaLabel="show more"
                >
                  <FlexBox minWidth="100%">
                    <Body testId="files" size="large">
                      <strong>Files</strong>
                    </Body>
                    <StyledHorizontalRule />
                    <StyledList>
                      {item.files.slice(1).map((file) => (
                        <li key={file.id}>
                          <Body>{file.name}</Body>
                        </li>
                      ))}
                    </StyledList>
                  </FlexBox>
                </CustomTooltip>
              )}
            </FlexBox>
            <FlexBox row gap="1rem">
              {item.isExpiringSoon && (
                <Badge
                  key={`${item.files[0].name}-expiring`}
                  fillColor="orange"
                  data-testid="expiring-badge"
                >
                  EXPIRING
                </Badge>
              )}
            </FlexBox>
          </FlexBox>
        ),
        Recipient: (
          <FlexBox>
            <FlexBox row>
              <StyledBodyWrapper>
                <Body
                  testId="recipient-text"
                  size="large"
                  className={
                    item.isExpiringSoon
                      ? "expiring-text"
                      : item.isExpired
                      ? "expired-text"
                      : ""
                  }
                >
                  {item.recipients.length > 0 ? `${item.recipients[0].userFirstName} ${item.recipients[0].userLastName}` : 'None'}
                </Body>
              </StyledBodyWrapper>
              {item.recipients.length > 1 && (
                <CustomTooltip
                  icon={
                    <Body size="large" bold>{`${
                      item.recipients.length - 1
                    } more`}</Body>
                  }
                  ariaLabel="show more"
                >
                  <FlexBox minWidth="100%">
                    <Body size="large">
                      <strong>Files</strong>
                    </Body>
                    <StyledHorizontalRule />
                    <StyledList>
                      {item.recipients.slice(1).map((recipient) => (
                        <li key={recipient.userEmail}>
                          <Body>{`${recipient.userFirstName} ${recipient.userLastName}`}</Body>
                        </li>
                      ))}
                    </StyledList>
                  </FlexBox>
                </CustomTooltip>
              )}
            </FlexBox>
          </FlexBox>
        ),
        Sent: (
          <StyledBodyWrapper>
            <Body
              size="large"
              className={
                item.isExpiringSoon
                  ? "expiring-text"
                  : item.isExpired
                  ? "expired-text"
                  : ""
              }
            >
              {formatDate(item.sentDate)}
            </Body>
          </StyledBodyWrapper>
        ),
        Expires: (
          <StyledBodyWrapper>
            <Body
              size="large"
              className={
                item.isExpiringSoon
                  ? "expiring-text"
                  : item.isExpired
                  ? "expired-text"
                  : ""
              }
            >
              {formatDate(item.expirationDate)}
            </Body>
          </StyledBodyWrapper>
        ),
        Actions: (
          <>
            {item.isExpired ? (
              <StyledBodyWrapper>
                <Body testId="expired-text" size="large" className="expired-text">
                  Expired
                </Body>
              </StyledBodyWrapper>
            ) : (
              <FlexBox row gap="1rem" align="center">
                <DatePickerToggle
                  item={item}
                  selectedPackageId={selectedPackage?.id}
                  setSelectedPackage={setSelectedPackage}
                  onUpdate={handleExtendExpiration}
                  disabled={isFetching}
                />
                <IconButton
                  key={`trash-${item.id}`}
                  name="trash"
                  size="large"
                  ariaLabel="delete package"
                  onClick={handleOpenDelete(item)}
                  addTooltip
                  toolTipText="delete"
                  testId="delete-button"
                />
                <Button
                  type="submit"
                  use="primary"
                  ariaLabel="details"
                  size="small"
                  onClick={handleOpenDetails(item)}
                  data-testid="details-button"
                >
                  Details
                </Button>
              </FlexBox>
            )}
          </>
        ),
      };
      tableData.push(row);
    });

    return tableData;
  };

  return (
    <FlexBox gap="1rem" data-testid="fc-sent-table">
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={0} md={5}></Grid>
        <Grid item xs={12} md={3}>
          <Select
            items={SORT_BY_OPTIONS}
            onChange={(e: Event) => handleUpdateSortBy(e)}
            optionLabels={["value"]}
            valueKey="value"
            name="displayResults"
            label="Sort by"
            value={sortSentBy}
            data-testid="sort-select"
          />
        </Grid>
      </Grid>
      <Table
        striped
        padding="compact"
        headers={["File name", "Recipient", "Sent", "Expires", "Actions"]}
        items={formatTableData(
          isPage ? data : data.length > 0 ? data.slice(0, 4) : []
        )}
      />
      {!isPage && (
        <CaretLink
          caretPosition="right"
          to={paths.FILE_COURIER_SENT}
          state={{ sortSentBy: sortSentBy, sortReceivedBy: sortReceivedBy }}
          pd="0 0 1rem 0"
        >
          See all
        </CaretLink>
      )}
      {selectedPackage && deleteOpen && (
        <DeleteFileCourierPackageModal
          packageId={selectedPackage.id}
          onClose={handleCloseDelete}
          onRefresh={onRefresh}
        />
      )}
      {selectedPackage && detailsOpen && (
        <FileCourierSentDetailsModal
          packageId={selectedPackage.id}
          onClose={handleCloseDetails}
          onRefresh={onRefresh}
        />
      )}
    </FlexBox>
  );
};

export default FileCourierFilesSentTable;
