import { HandleSubmitProps } from "interfaces";

interface formValues {
  code: string;
}

interface props {
  props: { verify: boolean };
}

export default function (
  values: formValues,
  { props: { action, verify } }: HandleSubmitProps & props
) {
  return action({ ...values, verify });
}

// export default function (
//   values: any,
//   { props: { action, navigate, location, verify } }: any
// ) {
//   const handleSuccess = ({ payload }: any) => {
//     if (payload.authState !== AUTH_STATE.MFA_SMS) {
//       const origin = location.state?.from?.pathname || paths.HOME;
//       return navigate(origin);
//     }
//     return null;
//   };
//   return action({ ...values, verify }).unwrap().then((res: any) => handleSuccess(res));
// }
