import { Button } from "components";
import { useNavigate } from "react-router-dom";
import paths from "routing/paths";
import Title from "../../components/typography/Title/Title";
import Body from "../../components/typography/Body/Body";
import FlexBox from "../../components/layouts/FlexBox/FlexBox";

const SignUpPending = () => {
  const navigate = useNavigate(),
    handleNavigate = () => {
      navigate(paths.SIGNIN);
    };
  return (
    <FlexBox maxWidth="50ch">
      <Title size="large" bold pd="0 0 0.5rem 0">
        Your account is pending activation.
      </Title>
      <Body size="large" pd="0 0 2rem 0">
        You will receive an email when your account is activated. Once your
        account is activated, you may sign in.
      </Body>
      <Button data-testid="ok-button" onClick={handleNavigate}>Ok</Button>
    </FlexBox>
  );
};

export default SignUpPending;
