import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup as RadioButtonGroup$1 } from '@vds-core/radio-buttons';
import RadioButton from './RadioButton.js';

const propTypes = {
  /**
  * Option to render on dark or light surface
  */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const RadioButtonGroup = function (props) {
  return React.createElement(RadioButtonGroup$1, Object.assign({}, props, {
    RadioButton: RadioButton
  }));
};
RadioButtonGroup.propTypes = propTypes;
RadioButtonGroup.defaultProps = defaultProps;

export { RadioButtonGroup as default };
