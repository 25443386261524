import { withFormik } from "formik";
import mapPropsToValues, { FormValues } from "./mapPropsToValues";
import ShareModal from "./ShareModal";
import { ShareModalProps } from "interfaces/Modal";

export const ShareModalContainer = withFormik<ShareModalProps, FormValues>({
  mapPropsToValues,
  handleSubmit: () => null,
  enableReinitialize: true,
  validateOnMount: true,
})(ShareModal);

export default ShareModalContainer;
