import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import { withAction } from "HOCs/withAction";
import { signin } from "services";
import mapPropsToValues from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import handleSubmit from "./handleSubmit";
import { SignInProps } from "interfaces";
import { CognitoSignInProps } from "interfaces/Cognito";
import SignInForm from "./SignInForm";

export const SignInFormContainer = compose(
  withAction((props: any) => signin(props)),
  withFormik<SignInProps, CognitoSignInProps>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  })
)(SignInForm);

export default SignInFormContainer;
