import styled from 'styled-components';
import { Body } from '@vds-core/typography';
import { ColorTokens } from '@vds-tokens/color';
import React from 'react';

const ErrorTextWrapper = styled.div.withConfig({
  displayName: "ErrorTextWrapper",
  componentId: "VDS__sc-13aeuon-0"
})`
  margin-top: ${({
  errorLabelSpacing
}) => errorLabelSpacing};
`;
const ErrorText = props => {
  const {
    errorLabelSpacing,
    errorText,
    error,
    success,
    successText,
    surface
  } = props;
  return React.createElement("div", {
    role: "alert"
  }, React.createElement(ErrorTextWrapper, {
    errorLabelSpacing: errorLabelSpacing
  }, React.createElement(Body, {
    size: "small",
    color: surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value
  }, error ? errorText : success && successText)));
};

export { ErrorText as default };
