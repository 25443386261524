import { StyledContainer, StyledLink } from "./ComplianceNav.module";
import { FlexBox, Title } from "components";
import { Icon } from "@vds/icons";
import paths from "routing/paths";

export type ComplianceNavProps = {
  navNum: number;
};

const ComplianceNav = ({ navNum }: ComplianceNavProps) => {

  const navList = [
    {
      title: "Overview",
      url: paths.COMPLIANCE_OVERVIEW,
    },
    {
      title: "Process",
      url: paths.COMPLIANCE_PROCESS,
    },
    {
      title: "Scoring",
      url: paths.COMPLIANCE_SCORING,
    },
    {
      title: "Tools & resources",
      url: paths.COMPLIANCE_RESOURCES,
    },
    {
      title: "FAQs",
      url: paths.COMPLIANCE_FAQS,
    },
  ];

  return (
    <StyledContainer>
      {navList.map((item, index: number) => (
        <StyledLink key={index} to={item.url} className={index === navNum ? "active" : ""}>
          <FlexBox justify="space-between" bp="960px" minWidth="100%">
            <Title size="large" bold color={index === navNum ? "#ffffff" : "#000000"}>
              {item.title}
            </Title>
            <Icon
              name="right-arrow"
              size="XLarge"
              color={index === navNum ? "#ffffff" : "#000000"}
            />
          </FlexBox>
        </StyledLink>
      ))}
    </StyledContainer>
  );
};

export default ComplianceNav;
