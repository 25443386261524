import React from 'react';
import PropTypes from 'prop-types';
import { AccordionSubTitle as AccordionSubTitle$1 } from '@vds-core/accordions';

const propTypes = {
  /**
   * @ignore
  * Option to render on dark or light surface
  */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const AccordionSubTitle = function (props) {
  return React.createElement(AccordionSubTitle$1, Object.assign({}, props));
};
AccordionSubTitle.propTypes = propTypes;
AccordionSubTitle.defaultProps = defaultProps;
AccordionSubTitle.displayName = 'AccordionSubTitle';

export { AccordionSubTitle as default };
