import { Autocomplete, TextField } from "@mui/material";
import { Icon } from "@vds/icons";
import { SearchProps } from "interfaces";
import { Title, Body } from "components";
import { useState } from "react";
import { StyledSelectText } from "./Search.module";

const Search = ({
  label,
  name,
  value,
  options,
  optionLabels = ["name"],
  setFieldValue,
  callSearch,
  required = false,
  adminSearch,
  includeEmail,
  callback,
  testId,
}: SearchProps) => {
  const [search, _setSearch] = useState(""),
    handleOnBlur = (e: any) => {
      _setSearch("");
      !required && !e.target.value && setFieldValue(name, null);
    };

  const generateOptionLabel = (option: any) => {
    return option
      ? optionLabels.reduce((result: string, item: string, index: number) => {
          if (index === optionLabels.length - 1) {
            return `${result}${option[item]}`;
          }
          return `${result}${option[item]} `;
        }, "")
      : "";
  };

  return (
    <>
      {label && !adminSearch && (
        <Title size="small" bold pd="1rem 0 0.75rem 0">
          {label}
        </Title>
      )}

      {label && adminSearch && (
        <Body size="small" pd="0 0 0.25rem 0">
          {label}
        </Body>
      )}

      <Autocomplete
        value={value}
        options={search.length >= 3 ? options : []}
        autoComplete
        fullWidth
        freeSolo
        onChange={(_, value: any) => {
          setFieldValue(name, value);
          callback && callback(value);
        }}
        onBlur={(e) => handleOnBlur(e)}
        onInputChange={(_: any, value: any, reason: any) => {
          if (reason === "input") {
            _setSearch(value);
            if (value.length >= 3) {
              callSearch(value);
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            data-testid={`${testId}-input`}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <StyledSelectText>
                <Body className="ellipsis" bold>
                  {generateOptionLabel(option)}
                </Body>
                {includeEmail && (
                  <Body
                    className="ellipsis"
                    color="#a7a7a7"
                  >{`${option.email}`}</Body>
                )}
              </StyledSelectText>
            </li>
          );
        }}
        getOptionLabel={(option: any) => generateOptionLabel(option)}
        ListboxProps={{
          style: { maxHeight: "10rem" },
        }}
        filterSelectedOptions
        filterOptions={(x) => x}
        forcePopupIcon
        popupIcon={<Icon name="search" />}
        sx={{
          "& .MuiAutocomplete-popupIndicator": {
            transform: "none",
            cursor: "text",
            ".MuiTouchRipple-root": {
              "*": {
                background: "none",
              },
            },
          },
        }}
        isOptionEqualToValue={(option: any, value: any) => {
          return option.id === value.id;
        }}
        disableClearable
        data-testid={testId || "search"}
      />
    </>
  );
};

export default Search;
