import { useContext } from "react";
import { AuthContext } from "contexts/authContext";
import {
  Button,
  Form,
  Input,
  Title,
  Body,
  TextButton,
  FlexBox,
  Notification,
  ButtonGroup,
} from "components";
import { FormikProps } from "interfaces";
import { useDispatch, useSelector } from "hooks/redux";
import {
  mfa,
  mfaSms,
  resetAuthNotification,
  resetAuthState,
  selectAuthNotification,
  selectMfaChoice,
  selectStatus,
} from "services";
import MFA_CHOICE from "constants/mfaChoice";
import { useLocation, useNavigate } from "react-router-dom";
import paths from "routing/paths";

export interface MfaConfirmProps {
  verify?: boolean;
  myAccountCancel?: Function;
}
const MfaConfirm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  errors,
  dirty,
  isValid,
  verify,
  myAccountCancel = () => null,
}: FormikProps & MfaConfirmProps) => {
  const { pathname } = useLocation(),
    myAccount = pathname === paths.MY_ACCOUNT;

  const mfaChoice = useSelector(selectMfaChoice),
    { email, phone, password } = useContext<any>(AuthContext);

  const dispatch = useDispatch(),
    navigate = useNavigate(),
    isLoading = useSelector(selectStatus) === "LOADING";

  const handleCancel = () => {
      verify ? navigate(paths.HOME) : dispatch(resetAuthState());
    },
    handleResendCode = () => {
      verify
        ? dispatch(mfaSms({ phoneNumber: phone }))
        : dispatch(mfa({ resend: { email, password }, verify }));
    };

  return (
    <Form onSubmit={handleSubmit} maxWidth="360px">
      <Title size="medium" bold pd="0 0 0.5rem 0">
        Enter Code
      </Title>
      <Body size="large" pd="0 0 0.25rem 0">
        A verification code has been sent to
      </Body>
      <Body size="large" bold={true} pd="0 0 0.25rem 0">
        {mfaChoice === MFA_CHOICE.EMAIL ? email : phone}
      </Body>
      <Body size="large" pd="0 0 1.5rem 0">
        Please enter it below.
      </Body>
      <Input
        label="Code"
        name="code"
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isLoading}
        error={touched["code"] && !!errors["code"]}
        errorText={errors["code"]}
        data-testid="code"
      />
      <Notification
        selector={selectAuthNotification}
        reset={resetAuthNotification}
        inline
      />
      <ButtonGroup pd="1.5rem 0 0 0">
        <Button
          type="submit"
          disabled={isLoading || !(dirty && isValid)}
          data-testid="submit"
        >
          Submit
        </Button>
        {myAccount ? (
          <TextButton data-testid="my-account-cancel" onClick={myAccountCancel}>
            Cancel
          </TextButton>
        ) : (
          <Button
            type="button"
            onClick={handleCancel}
            disabled={isLoading}
            data-testid="cancel"
            use="secondary"
          >
            {verify ? "Skip for now" : "Cancel"}
          </Button>
        )}
      </ButtonGroup>
      <FlexBox pd="1.5rem 0 0">
        <TextButton
          onClick={handleResendCode}
          disabled={isLoading}
          data-testid="resend-code"
          size="small"
        >
          Send a new code
        </TextButton>
      </FlexBox>
    </Form>
  );
};

export default MfaConfirm;
