import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AccordionItem as AccordionItem$1 } from '@vds-core/accordions';

const propTypes = {
  /**
   * @ignore
  * Option to render on dark or light surface
  */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
class AccordionItem extends Component {
  render() {
    return React.createElement(AccordionItem$1, Object.assign({}, this.props));
  }
}
AccordionItem.componentType = 'AccordionItem';
AccordionItem.propTypes = propTypes;
AccordionItem.defaultProps = defaultProps;

export { AccordionItem as default };
