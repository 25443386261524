import styled from "styled-components";
import {  minTablet} from "../../theme/variables";


export const StyledTable = styled("div")`
  @media screen and (max-width: ${ minTablet - 1}px) {
    overflow: hidden;
    &> div {
      overflow-x: auto;
    }
  }
`;

export const StyledCellContent = styled("span")`
  &.break {
    @media screen and (min-width: ${ minTablet}px) {
      word-break: break-word;
    }
  }
`;