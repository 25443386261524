import { object, string } from "yup";

const validationSchema = () => {
  return object().shape({
    groupName: string().required("You must enter a Group Name"),
    companyName: string().required("You must enter a Company Name"),
  });
};

export default () => validationSchema();
