import styled from "styled-components";
import { FormProps } from "./Form";

export const StyledForm = styled.form<FormProps>`
  display: flex;
  flex-direction: column;

  max-width: ${(props) => props.maxWidth || "100%"};
  width: ${(props) => props.width || "auto"};

  > :not(:last-child) {
    ${(props) => props.gap && `padding-bottom: ${props.gap}`};
  }
`;
