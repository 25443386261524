import CaretLink from "../../buttons/CaretLink/CaretLink";
import Title from "../../typography/Title/Title";
import Body from "../../typography/Body/Body";
import Feature from "../../typography/Feature/Feature";
import FlexBox from "../../layouts/FlexBox/FlexBox";
import Line from "../../Line/Line";
import {StyledContactsContainer, StyledContactsTwoUp, StyledContactSubFooter} from "./ContactSubFooter.module";
import MdToHtml from "../../MdToHtml/MdToHtml";
import { Grid } from "@mui/material";
import { useGetGlobalQuery } from "services";
import { ContactSubFooterItemProps } from "interfaces";
import TwoUp from "../TwoUp/TwoUp";

const ContactSubFooter = () => {
  const { data: global } = useGetGlobalQuery();

  return (
    <StyledContactSubFooter>
      <Line type="secondary" />
      <FlexBox row justify="space-between" wrap gap="1.25rem" pd="2.5rem 0 0">
        <FlexBox gap="1rem">
          <Feature size="XSmall" bold>
            Questions?
          </Feature>
          <Body size="large" maxWidth="45ch">
            We’ll help you find the right answer to your brand identity
            questions.
          </Body>
        </FlexBox>
      
      
        {(global?.contactSubFooter?.contacts && global?.contactSubFooter?.contacts.length > 0) && (
            <StyledContactsContainer row gap="2.5rem" wrap>
              <FlexBox
                minWidth="18rem"
                justify="flex-start"
                maxWidth="45ch"
              >
                <Title size="medium" bold pd="0 0 0.75rem 0">
                  {global?.contactSubFooter?.contacts[0].title}
                </Title>
                <MdToHtml content={global?.contactSubFooter?.contacts[0].body} />
                {global?.contactSubFooter?.contacts[0].linkUrl && (
                  <CaretLink
                    caretPosition="right"
                    to={global?.contactSubFooter?.contacts[0].linkUrl}
                    pd="0.75rem 0 0 0"
                  >
                    {global?.contactSubFooter?.contacts[0].linkText}
                  </CaretLink>
                )}
              </FlexBox>
              <FlexBox gap="1.5rem" minWidth="18rem" wrap>
                <FlexBox
                  key={global?.contactSubFooter?.contacts[1].title}
                  justify="flex-start"
                >
                  <Title size="medium" bold pd="0 0 0.75rem 0">
                    {global?.contactSubFooter?.contacts[1].title}
                  </Title>
                  <MdToHtml content={global?.contactSubFooter?.contacts[1].body} />
                  {global?.contactSubFooter?.contacts[1].linkUrl && (
                    <CaretLink
                      caretPosition="right"
                      to={global?.contactSubFooter?.contacts[1].linkUrl}
                      pd="0.75rem 0 0 0"
                    >
                      {global?.contactSubFooter?.contacts[1].linkText}
                    </CaretLink>
                  )}
                </FlexBox>
                <FlexBox
                  key={global?.contactSubFooter?.contacts[2].title}
                  justify="flex-start"
                >
                  <Title size="medium" bold pd="0 0 0.75rem 0">
                    {global?.contactSubFooter?.contacts[2].title}
                  </Title>
                  <MdToHtml content={global?.contactSubFooter?.contacts[2].body} />
                  {global?.contactSubFooter?.contacts[2].linkUrl && (
                    <CaretLink
                      caretPosition="right"
                      to={global?.contactSubFooter?.contacts[2].linkUrl}
                      pd="0.75rem 0 0 0"
                    >
                      {global?.contactSubFooter?.contacts[2].linkText}
                    </CaretLink>
                  )}
                </FlexBox>
              </FlexBox>
              
            </StyledContactsContainer>
        )}
      </FlexBox>
    </StyledContactSubFooter>
  );
};

export default ContactSubFooter;
