import styled from "styled-components";

export interface StyledContainerProps {
  isHidden?: boolean;
}
export const StyledContainer = styled.div`
  
  position: relative;
  visibility: visible;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 760px) {
    content-visibility: ${(props: StyledContainerProps) => (props.isHidden? "hidden" : "visible")};
  }
  
`;

export const StyledButton = styled.button`
  
  position: relative;
  border: none;
  cursor: pointer;
  background: none;
  visibility: hidden;

  @media only screen and (max-width: 760px) {
    visibility: visible;
  }
  
`;