import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import sharedDefaultProps from '../utilities/sharedDefaultProps.js';
import sharedPropTypes from '../utilities/sharedPropTypes.js';
import PaginationStepControl from '../Controls/PaginationStepControl.js';
import PaginationListItem from '../Controls/PaginationListItem.js';
import '../Controls/PaginationLink.js';

const propTypes = Object.assign(Object.assign({}, sharedPropTypes), {
  children: PropTypes.node
});
const defaultProps = Object.assign(Object.assign({}, sharedDefaultProps), {
  children: null
});
/**
 * @ignore
 */
const PaginationControls = props => {
  const {
    baseUrl,
    children,
    selectedPage,
    selectPage,
    total,
    dataTrack,
    level,
    position,
    cta,
    surface,
    focusBorderRadius
  } = props;
  function _selected(page) {
    selectPage(page);
  }
  return React.createElement(Fragment, null, React.createElement(PaginationListItem, {
    marginSide: 'right'
  }, React.createElement(PaginationStepControl, {
    direction: "previous",
    baseUrl: baseUrl,
    selectedPage: selectedPage,
    total: total,
    onClick: _selected,
    tabIndex: 0,
    cta: cta,
    position: position,
    level: level,
    dataTrack: dataTrack,
    surface: surface,
    focusBorderRadius: focusBorderRadius
  })), children, React.createElement(PaginationListItem, {
    marginSide: 'left'
  }, React.createElement(PaginationStepControl, {
    direction: "next",
    baseUrl: baseUrl,
    selectedPage: selectedPage,
    total: total,
    onClick: _selected,
    tabIndex: 0,
    cta: cta,
    position: position,
    level: level,
    dataTrack: dataTrack,
    surface: surface,
    focusBorderRadius: focusBorderRadius
  })));
};
PaginationControls.propTypes = propTypes;
PaginationControls.defaultProps = defaultProps;

export { PaginationControls as default };
