import { Body, FlexBox } from "components";

export type MetaDataTextProps = {
  label: string;
  value?: string;
  children?: any;
  pd?: string;
  testId?: string;
};

const MetaDataText = ({ label, value, children, pd, testId }: MetaDataTextProps) => {
  return (
    <FlexBox pd={pd ? pd : "0"} row gap="1rem" data-testid={testId}>
      <FlexBox minWidth="11ch">
        <Body bold>{label}</Body>
      </FlexBox>
      {children && children}
      {value && <Body>{value}</Body>}
    </FlexBox>
  );
};

export default MetaDataText;
