import styled from "styled-components";
import { DropdownSelect, DropdownSelectProps } from "@vds/selects";
import { SelectProps } from "interfaces";

export const StyledSelect = styled(DropdownSelect)<
  DropdownSelectProps & SelectProps
>`
  max-width: ${(props) => props.maxWidth || ""};
  width: ${(props) => props.width || ""};
`;

export const StyledSelectText = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
`;
