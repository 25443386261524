import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import { withAction } from "HOCs/withAction";
import { mfaSms } from "services";
import mapPropsToValues from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import handleSubmit from "./handleSubmit";
import MfaSms from "./MfaSms";

export const MfaSmsContainer = compose(
  withAction((props: any) => mfaSms(props)),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    validateOnMount: true,
  })
)(MfaSms);

export default MfaSmsContainer;
