import styled from "styled-components";
import { CaretTextProps } from "./CaretText";

export const StyledCaretTextContainer = styled("div")<CaretTextProps>`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: ${(props) => props.color || `inherit`};
  width: fit-content;
  padding: ${(props) => props.pd || ``};

  cursor: pointer;
  align-items: ${(props) => props.align || `baseline`};
  text-align: left;
  &.right {
    flex-direction: row;
  }
  &.left {
    flex-direction: row-reverse;
  }
  .caret {
    position: relative;
    transition: all 500ms ease-in-out;
    width: ${(props) => props.fontSize || 1}rem;
    height: ${(props) => props.fontSize || 1}rem;
    top: calc(${(props) => props.fontSize || 1}rem * 0.2);
    &.right {
      left: 0;
      margin-left: calc(${(props) => props.fontSize || 1}rem * 0.2);
    }
    &.left {
      right: 0;
      margin-right: calc(${(props) => props.fontSize || 1}rem * 0.2);
      transform: scaleX(-1);
    }
  }
  div > div > p,
  div > .caret {
    font-family: Verizon-NHG-eDS, Helvetica, Arial, Sans-serif;
    font-size: ${(props) => props.fontSize || 1}rem;
    line-height: ${(props) => props.fontSize || 1}rem !important;
    color: ${(props) => props.color || `inherit`};
  }

  p,
  .caret {
    font-family: Verizon-NHG-eDS, Helvetica, Arial, Sans-serif;
    font-size: ${(props) => props.fontSize || 1}rem;
    line-height: ${(props) => props.fontSize || 1}rem !important;
    color: ${(props) => props.color || `inherit`};
    text-align: left;
  }

  &.hover:not(.disabled) {
    border-bottom-width: 2px;
    margin-bottom: 0px;
    .caret {
      position: relative;
      transition: all 500ms ease-in-out;
      &.right {
        left: calc(${(props) => props.fontSize || 1}rem * 0.6);
      }
      &.left {
        left: calc(${(props) => props.fontSize || 1}rem * -0.6);
      }
    }
  }

  &:active {
    p,
    .caret {
      color: grey;
    }
  }

  &.disabled {
    pointer-events: none;

    p,
    .caret {
      color: grey;
    }
  }
`;
