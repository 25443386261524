import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { ColorTokens } from '@vds-tokens/color';

const propTypes = {
  /**
   * TableBody requires TableRow components as children.
   */
  children: PropTypes.node.isRequired,
  /**
   * @ignore
   * BottomLine used to control tableRow
   */
  bottomLine: PropTypes.bool,
  /**
   * @ignore
   * If provided, rows inside the Table component will have alternating stripes.
   */
  striped: PropTypes.bool,
  /**
   * @ignore
   */
  stripePattern: PropTypes.oneOf(['even', 'odd']),
  /**
   * @ignore
   */
  className: PropTypes.string
};
const defaultProps = {
  bottomLine: true,
  striped: false,
  stripePattern: 'odd',
  className: undefined
};
const StyledTableBody = styled.tbody.withConfig({
  displayName: "StyledTableBody",
  componentId: "VDS__sc-1n0i1z5-0"
})`
  ${({
  bottomLine
}) => !bottomLine && `
    tr {
      border: none !important;
    }
  `};
  ${({
  striped,
  stripePattern
}) => striped && `
    tr:nth-child(${stripePattern}) {
      background-color: ${ColorTokens.background.secondary.light.value} !important;
    }
  `}
`;
const TableBody = props => {
  const {
    bottomLine,
    striped,
    children,
    stripePattern,
    className
  } = props;
  return React.createElement(StyledTableBody, {
    className: striped ? `tbody-striped-override` : null,
    bottomLine: bottomLine,
    striped: striped,
    stripePattern: stripePattern
  }, children);
};
TableBody.defaultProps = defaultProps;
TableBody.propTypes = propTypes;
TableBody.displayName = TableBody;

export { TableBody as default };
