import {
  Body,
  Button,
  Radio,
  Title,
  Form,
  ButtonGroup,
  Notification,
} from "components";
import { FormikProps } from "interfaces";
import { useSelector, useDispatch } from "hooks/redux";
import {
  resetAuthNotification,
  resetAuthState,
  selectAuthNotification,
  selectStatus,
  selectUser,
} from "services";
import { useContext } from "react";
import { AuthContext } from "contexts/authContext";
import MFA_CHOICE from "constants/mfaChoice";
import FlexBox from "../../components/layouts/FlexBox/FlexBox";

const MfaChoice = ({ values, setFieldValue, handleSubmit }: FormikProps) => {
  const { email, setPhone } = useContext<any>(AuthContext),
    user = useSelector(selectUser),
    smsEnabled = user?.challengeParam.userPhone || false;
  const isLoading = useSelector(selectStatus) === "LOADING";

  const handleOnChange = (choice: string) => () => {
      setFieldValue("choice", choice);

      if (choice === MFA_CHOICE.SMS) {
        setPhone(smsEnabled);
      }
    },
    dispatch = useDispatch(),
    handleCancel = () => {
      dispatch(resetAuthState());
    };

  return (
    <Form onSubmit={handleSubmit} maxWidth="360px">
      <Title size="medium" bold pd="0 0 0.5rem 0">
        Two-Factor Authentication
      </Title>
      <Body
        size="large"
        pd="0 0 1.5rem 0"
        data-testid={smsEnabled ? "sms-enabled-text" : "sms-not-enabled-text"}
      >
        {smsEnabled
          ? "Select device to receive verification code."
          : "To log into Brand Central, please verify your account by sending a code to your email address below."}
      </Body>

      {smsEnabled ? (
        <>
          <FlexBox pd="0 0 1.5rem 0">
            <Body bold size="small" pd="0 0 0.5rem 0">
              Email
            </Body>
            <Radio
              label={email}
              name="Email"
              value="Email"
              selected={values.choice === MFA_CHOICE.EMAIL}
              onChange={handleOnChange(MFA_CHOICE.EMAIL)}
              disabled={isLoading}
              data-testid="email-radio-button"
            />
          </FlexBox>

          <FlexBox pd="0">
            <Body bold pd="0 0 0.5rem 0" size="small" data-testid="sms-enabled">
              SMS
            </Body>
            <Radio
              label={smsEnabled}
              name="SMS"
              value="SMS"
              selected={values.choice === MFA_CHOICE.SMS}
              onChange={handleOnChange(MFA_CHOICE.SMS)}
              disabled={isLoading}
              data-testid="sms-radio-button"
            />
          </FlexBox>
          <Notification
            selector={selectAuthNotification}
            reset={resetAuthNotification}
            inline
          />
        </>
      ) : (
        <>
          <Body
            bold
            size="small"
            pd="0 0 0.25rem 0"
            data-testid="sms-not-enabled"
          >
            Email
          </Body>
          <Body size="small">{email}</Body>
          <Notification
            selector={selectAuthNotification}
            reset={resetAuthNotification}
            inline
          />
        </>
      )}

      <ButtonGroup pd="1.5rem 0 0 0">
        <Button
          type="submit"
          use="primary"
          disabled={isLoading}
          data-testid="submit"
        >
          Send code
        </Button>
        <Button
          type="button"
          onClick={handleCancel}
          disabled={isLoading}
          data-testid="cancel"
          use="secondary"
        >
          Cancel
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default MfaChoice;
