import {
  Body,
  Button,
  ButtonGroup,
  Form,
  Input,
  Notification,
  Title,
} from "components";
import { FormikProps } from "interfaces";
import { useSelector } from "hooks/redux";
import {
  resetAuthNotification,
  selectAuthNotification,
  selectStatus,
} from "services";
import paths from "routing/paths";
import { useNavigate } from "react-router-dom";

export interface props {
  setUsername: Function;
}

const ForgotPasswordForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  errors,
  dirty,
  isValid,
  setUsername,
}: props & FormikProps) => {
  const isLoading = useSelector(selectStatus) === "LOADING";

  const handleOnSubmit = () => {
      setUsername(values.username);
    },
    navigate = useNavigate(),
    handleCancel = () => {
      navigate(paths.SIGNIN);
    };
  
   const checkIsEmail = (email: string): boolean => {
    let emailCheck = true;
    
    // this check whether the email contains at least 1 character
    // followed by an @ sign, followed by at least 1 more character
    // followed by a period, followed by at least 1 last character
    if (email.indexOf('@') === -1) {
      emailCheck = false;
    } else {
      const emailParts = email.split('@');
      if (!emailParts[0].length) {
        emailCheck = false;
      } else {
        if (emailParts[1].indexOf('.') === -1) {
          emailCheck = false;
        } else {
          const afterAt = emailParts[1].split('.');
          if (!afterAt[0].length || !afterAt[1].length) {
            emailCheck = false;
          }
        }
      }
    }
    
    return emailCheck;
  }

  return (
    <Form data-testid="forgot-pw-form" onSubmit={handleSubmit} maxWidth="360px">
      <Title size="large" bold={true} pd="0 0 0.5rem">
        Reset Password
      </Title>

      <Body pd="0 0 1.5rem">Please enter your username.</Body>
      <Input
        label="Username (email address)"
        name="username"
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isLoading}
        error={touched["username"] && !!errors["username"]}
        errorText={errors["username"]}
        data-testid="username"
      />
      <Notification
        selector={selectAuthNotification}
        reset={resetAuthNotification}
        inline
      />
      <ButtonGroup pd="1.5rem 0 0 0">
        <Button
          type="submit"
          use="primary"
          size="large"
          disabled={isLoading || !(dirty && (isValid || !touched["username"]) && checkIsEmail(values.username))}
          onClick={handleOnSubmit}
          data-testid="submit"
        >
          Next
        </Button>
        <Button
          type="button"
          onClick={handleCancel}
          disabled={isLoading}
          data-testid="cancel"
          use="secondary"
        >
          Cancel
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default ForgotPasswordForm;
