import { HandleSubmitProps } from "interfaces";
import paths from "routing/paths";

interface formValues {
  code: string;
  password: string;
}
interface props {
  props: { username: string; navigate: any };
}

export default async function (
  values: formValues,
  {
    props: { action, setNotification, username, navigate },
  }: HandleSubmitProps & props
) {
  const handleSuccess = () => {
    navigate(paths.SIGNIN);
    setNotification &&
      setNotification({
        type: "success",
        message: "Password successfully updated.",
      });
  };

  return action({ ...values, username })
    .unwrap()
    .then(handleSuccess)
    .catch((_: any) => {});
}
