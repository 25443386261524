import { useState, useEffect } from "react";
import {
  Body,
  Button,
  CheckboxGroup,
  FlexBox,
  IconButton,
  Title,
} from "components";
import { useTrackFeedbackMutation } from "services/api/api.slice";
import { minTablet } from "theme/variables";
import { StyledFeedbackContainer } from "./SatisfiedFeedback.module";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";

export interface SatisfiedFeedbackProps {
  isActive: boolean;
  assetId: string;
  onClose: () => void;
  correlationId?: string;
  feedbackOptionList: string[];
}

const SatisfiedFeedback = ({
  isActive,
  onClose,
  feedbackOptionList,
  assetId,
  correlationId,
}: SatisfiedFeedbackProps) => {
  const [feedbackActive, setFeedbackActive] = useState<boolean>(isActive);
  const [feedbackStep, setFeedbackStep] = useState<number>(0);

  const dispatch = useDispatch();

  const [feedbackOptionsSelected, setFeedbackOptionsSelected] = useState<
    string[]
  >([]);

  const [trackFeedback, { isLoading }] = useTrackFeedbackMutation();

  const [feedbackOptions, setFeedbackOptions] = useState<string[]>([]);

  const callTrackFeedback = (
    source: string,
    response: string,
    details: string[],
    entityId?: string
  ) => {
    trackFeedback({
      source: source,
      response: response,
      details: details,
      entityId: entityId,
      correlationId,
    })
    .unwrap()
    .catch((error: any) => {
      dispatch(
        setNotification({
          type: "error",
          message: "Error sending feedback.",
        })
      );
    });
  };

  const closeFeedback = (): void => {
    onClose();
  };

  const feedbackOptionSelected = (option: string) => () => {
    if (feedbackOptionsSelected.includes(option)) {
      setFeedbackOptionsSelected(
        feedbackOptionsSelected.filter((selected) => selected !== option)
      );
    } else if (!checkFeedbackOption(option)) {
      setFeedbackOptionsSelected(feedbackOptionsSelected.concat(option));
    }
  };

  const checkFeedbackOption = (option: string): boolean => {
    return feedbackOptionsSelected.includes(option);
  };

  const leaveFeedback = (feedbackString: string): void => {
    if (feedbackString === "checkboxes") {
      callTrackFeedback(
        "asset-download",
        "negative",
        feedbackOptionsSelected,
        assetId
      );
      setFeedbackStep(2);
    }
    if (feedbackString === "thumbs down") {
      setFeedbackStep(1);
    }
    if (feedbackString === "thumbs up") {
      callTrackFeedback("asset-download", "positive", [], assetId);
      setFeedbackStep(2);
    }
    if (feedbackStep === 2 && feedbackActive) {
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  };

  useEffect(() => {
    setFeedbackActive(isActive);
  }, [isActive]);

  useEffect(() => {
    if (!feedbackOptionList || feedbackOptionList?.length < 1) {
      return;
    }
    setFeedbackOptions(feedbackOptionList);
  }, [feedbackOptionList]);

  return feedbackActive ? (
    <>
      <StyledFeedbackContainer data-testid="feedback-container"
        className={`feedback-container ${
          feedbackActive ? "active" : ""
        } on-step-${feedbackStep}`}
      >
        <div className="bg-gradient"></div>
        <FlexBox className="content">
          <IconButton
            name="close"
            size="large"
            className="x-button"
            onClick={closeFeedback}
            disabled={isLoading}
            testId="close-button"
          />
          {feedbackStep === 0 && feedbackActive && (
            <FlexBox
              bp={`${minTablet - 1}px`}
              justify="center"
              row
              align="center"
              className="feedback-step step-0"
            >
              <Title size="large" bold>
                How satisfied are you with this asset?
              </Title>
              <FlexBox
                className="button-wrap"
                row
                justify="space-between"
                align="center"
              >
                <IconButton
                  className="feedback-button thumbs-up"
                  // color="#000000"
                  name="thumbs-up"
                  size="large"
                  onClick={() => leaveFeedback("thumbs up")}
                  fitToIcon={false}
                  disabled={isLoading}
                  testId="thumbs-up"
                >
                  <span className="visuallyhidden">
                    This is what I was looking for
                  </span>
                </IconButton>
                <IconButton
                  className="feedback-button thumbs-down"
                  name="thumbs-down"
                  size="large"
                  onClick={() => leaveFeedback("thumbs down")}
                  fitToIcon={false}
                  disabled={isLoading}
                  testId="thumbs-down"
                >
                  <span className="visuallyhidden">
                    This is NOT what I was looking for
                  </span>
                </IconButton>
              </FlexBox>
            </FlexBox>
          )}
          {feedbackStep === 1 && feedbackActive && (
            <FlexBox justify="flex-start" className="feedback-step step-1">
              <FlexBox justify="flex-start" className="copy">
                <Title pd="0 0 0.3rem" size="large" bold>
                  What went wrong?
                </Title>
                <Body className="feedback-instruction">
                  (Check all that apply)
                </Body>
              </FlexBox>
              {feedbackOptions && feedbackOptions.length > 0 && (
                <CheckboxGroup
                  grid
                  cols={{ xs: 1, md: 2, lg: 3 }}
                  colGutter="1.25rem"
                  rowGutter="1.5rem"
                  pd="2rem 1rem"
                  data={feedbackOptions?.map((option) => {
                    return {
                      label: option,
                      selected: feedbackOptionsSelected.includes(option),
                      onChange: feedbackOptionSelected(option),
                      disabled: isLoading,
                    };
                  })}
                />
              )}
              <Button
                use="primary"
                size="large"
                onClick={() => leaveFeedback("checkboxes")}
                disabled={isLoading || feedbackOptionsSelected.length < 1}
                data-testid="submit"
              >
                Submit
              </Button>
            </FlexBox>
          )}
          {feedbackStep === 2 && feedbackActive && (
            <div className="feedback-step step-2" role="status">
              <Title size="large" bold>
                Thank you for your feedback.
              </Title>
            </div>
          )}
        </FlexBox>
      </StyledFeedbackContainer>
    </>
  ) : (
    <></>
  );
};

export default SatisfiedFeedback;
