import { useEffect, useState } from "react";
import {FavoriteModalProps, UnifiedSearchResultProps} from "interfaces";
import {
  useAddFavoriteAssetMutation,
  useLazyGetFavoriteFoldersQuery,
  useRemoveFavoriteAssetMutation,
} from "services/api/api.slice";
import { StyledSelected } from "components/forms/MultiSelect/MultiSelect.module";
import { Grid } from "@mui/material";
import {
  ButtonGroup,
  Modal,
  Title,
  TextButton,
  Button,
  FlexBox,
  Body,
  Tag,
  Form,
  MultiSelect, Loader,
} from "components";
import { ModalThumbnailGrid } from "features";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";

interface FolderItem {
  id: string;
  name: string;
  entityId: string;
  searchType: string;
  searchId: string;
}
interface FolderProps {
  createdOn: string;
  id: string;
  items: Array<FolderItem>;
  name: string;
  active?: boolean;
}
interface AddProps {
  value: string;
  name: string;
}

const FavoriteModal = ({
  items,
  onClose,
  onComplete,
  hideBackdrop,
}: FavoriteModalProps) => {
  const [callGetFolders, { data: initialFolders, isFetching: fetchLoading, isLoading: foldersLoading }] =
      useLazyGetFavoriteFoldersQuery(),
    [addFavoriteAssets, { isLoading: addLoading }] =
      useAddFavoriteAssetMutation(),
    [removeFavoriteAssets, { isLoading: removeLoading }] =
      useRemoveFavoriteAssetMutation(),
    dispatch = useDispatch(),
    isLoading = fetchLoading || addLoading || removeLoading;

  const [folders, _setFolders] = useState<Array<FolderProps>>([]),
    [initialActive, _setInitialActive] = useState<Array<FolderProps>>([]);
  
  const getFolders = () => {
    callGetFolders()
    .unwrap()
    .catch((error: unknown) => {
      dispatch(
        setNotification({
          type: "error",
          message: "Could not fetch folders.",
        })
      );
      console.error(error);
    });
  };
  
  useEffect(() => {
    getFolders();
  }, []);
  
  useEffect(() => {
    let tempFolders: Array<FolderProps> = [];
    let activeFolders: Array<FolderProps> = [];

    if (initialFolders) {
      tempFolders = JSON.parse(JSON.stringify(initialFolders));
      // TODO: need to check each item against each folder, and rule out all folders that contain ANY of the items
      items.forEach((item, index) => {
        tempFolders.map((folder: FolderProps) => {
          if (!(activeFolders.find((activeFolder) => activeFolder.id === folder.id))) {
            if (folder.items.find((folderItem: FolderItem) => folderItem.entityId === item.id || ("entityId" in item && (folderItem.entityId ===  item.entityId)))) {
              folder.active = true;
              activeFolders.push(folder);
              _setInitialActive((oldArray) => [...oldArray, folder]);
            } else {
              folder.active = false;
            }
          }
        });
      });
    }
    _setFolders(tempFolders);
  }, [initialFolders]);

  const [addFavorites, _setAddFavorites] = useState<Array<any>>([]),
    [removeFavorites, _setRemoveFavorites] = useState<
      Array<{ folderId: string; id: string }>
    >([]),
    updateFavorites = async () => {
      try {
        let addFavoritesCalls = addFavorites.map((folder: FolderProps) =>
            addFavoriteAssets({
              folderId: folder.id,
              folderName: folder.name,
              folderItems: items.map((item) => {
                return {
                  entityId: "entityId" in item && item.entityId ? item.entityId : item.assetId ? item.assetId : item.id ? item.id : "",
                  searchType: item.searchType ? item.searchType : "asset"
                };
              }),
            }).unwrap()
          ),
          removeFavoritesCalls = removeFavorites.map((folder: any) =>
            removeFavoriteAssets(folder.id).unwrap()
          );
        await Promise.all(addFavoritesCalls);
        await Promise.all(removeFavoritesCalls);

        const isFavorite =
          addFavorites.length || removeFavorites.length < initialActive.length;
        if (onComplete) {
          onComplete(isFavorite);
        } else {
          onClose(isFavorite);
        }
        dispatch(
          setNotification({
            type: "success",
            message: "Successfully updated favorites.",
          })
        );
      } catch (error: any) {
        dispatch(
          setNotification({
            type: "error",
            message: "Error updating favorites.",
          })
        );
        // onClose(initialActive.length > 0);
      }
    },
    removeFromFolder = (item: UnifiedSearchResultProps, folder: FolderProps) => () => {
      const itemId = (folder.items.find(
        (folderItem: FolderItem) => folderItem.entityId === item.id || (folderItem.entityId === item.entityId)
      ) !== undefined) ? folder.items.find(
        (folderItem: FolderItem) => folderItem.entityId === item.id || (folderItem.entityId === item.entityId)
      )!.id : "";
      _setRemoveFavorites((oldArray) => [
        ...oldArray,
        { folderId: folder.id, id: itemId },
      ]);

      let tempFolders = JSON.parse(JSON.stringify(folders));
      tempFolders.find((item: FolderProps) => item.id === folder.id).active =
        false;
      _setFolders(tempFolders);
    },
    removeSelected = (selected: any) => () => {
      _setAddFavorites(
        addFavorites.filter((favorite) => favorite.name !== selected.name)
      );
    },
    handleClose = () => {
      onClose(
        addFavorites.length || removeFavorites.length < initialActive.length
      );
      _setAddFavorites([]);
    };
  
  return (
    <Modal
      open
      onClose={handleClose}
      disabled={isLoading}
      hideBackdrop={hideBackdrop}
      testId="favorites-modal"
    >
      <Title size="large" bold>
        Favorite Assets
      </Title>
      <Body size="large" pd="0.25rem 0 0">
        Add or remove this item from new or existing favorite folders.
      </Body>

      <ModalThumbnailGrid
        items={items}
        isFavorites
        // onRemoveClick={onRemoveClick ? onRemoveClick : () => {}}
      />
      {isLoading ? (
          <Loader containerHeight="30vh" active />
        ) :
      <Form onSubmit={updateFavorites} width="100%" gap="1rem">
        <Grid container>
          <Grid item xs={12} sm={12} md={8} lg={6} width="100%">
            <MultiSelect
              label="Select or create a folder"
              value={addFavorites}
              freeSolo
              options={folders.filter((folder: FolderProps) => !folder.active)}
              addCustom
              optionLabels={["name"]}
              onChange={(_, value: Array<FolderProps & AddProps>, reason) => {
                const newestOption = value[value.length - 1];
                if (reason === "createOption") {
                  const alreadyExists = addFavorites
                    .map((item) => item.name.includes(newestOption))
                    .includes(true);
                  !alreadyExists &&
                    _setAddFavorites([...addFavorites, { name: newestOption }]);
                } else if (newestOption.id === undefined) {
                  const alreadyExists = addFavorites
                    .map((item) => item.name.includes(newestOption.value))
                    .includes(true);
                  !alreadyExists &&
                    _setAddFavorites([
                      ...addFavorites,
                      { name: newestOption.value },
                    ]);
                } else {
                  const newValue = initialActive.find(
                    (initial) => initial.id === value[value.length - 1].id
                  );

                  if (newValue) {
                    let tempFolders = JSON.parse(JSON.stringify(folders));
                    tempFolders.find(
                      (item: FolderProps) => item.id === newValue.id
                    ).active = true;
                    _setFolders(tempFolders);
                    _setRemoveFavorites(
                      removeFavorites.filter(
                        (item) => item.folderId !== newValue.id
                      )
                    );
                  } else {
                    _setAddFavorites(value);
                  }
                }
              }}
              selectedLabel="Selected folders"
              removeSelected={(selected: any) => removeSelected(selected)}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
        {items.length === 1 && folders.find((folder) => folder.active) ? (
          <Grid container data-testid="current-folder-locations">
            <Grid item xs={12} sm={12} md={8} lg={6} width="100%">
              <Body size="large">Current folder locations</Body>
              <StyledSelected>
                {folders
                  .filter((folder: FolderProps) => folder.active)
                  .map((folder: FolderProps) => (
                    <FlexBox
                      key={folder.id}
                      row
                      align="center"
                      justify="space-between"
                      gap="0.5rem"
                    >
                      <Tag>
                        <Body size="small" bold>
                          {folder.name}
                        </Body>
                      </Tag>
                      <TextButton
                        onClick={removeFromFolder(items[0], folder)}
                        disabled={isLoading}
                      >
                        Remove from this folder
                      </TextButton>
                    </FlexBox>
                  ))}
              </StyledSelected>
            </Grid>
          </Grid>
        ) : null}

        <ButtonGroup align="flex-start">
          <Button
            type="submit"
            use="secondary"
            disabled={
              (!addFavorites.length && !removeFavorites.length) || isLoading
            }
            data-testid="submit-button"
          >
            Update favorites
          </Button>
          <TextButton
            onClick={handleClose}
            disabled={isLoading}
            data-testid="cancel-button"
          >
            Cancel
          </TextButton>
        </ButtonGroup>
      </Form>
      }
    </Modal>
  );
};

export default FavoriteModal;
