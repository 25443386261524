import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion as Accordion$1 } from '@vds-core/accordions';

const propTypes = {
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * If enabled, will render the bottom line of the last accordion item.
   */
  bottomLine: PropTypes.bool
};
const defaultProps = {
  surface: 'light',
  bottomLine: true
};
class Accordion extends Component {
  render() {
    return React.createElement(Accordion$1, Object.assign({}, this.props));
  }
}
Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

export { Accordion as default };
