import styled from "styled-components";
import Flexbox from "../../layouts/FlexBox/FlexBox";

export const StyledWrapper = styled.div`
  overflow: hidden;
  max-width: 100%;
  text-align: center;
  position: relative;

  border: 1px solid #d8dada;
  border-radius: 0.5rem;
`;

export const StyledInput = styled.input`
  display: none;
  visibility: hidden;
`;

export const StyledBackground = styled.div`
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.drag-active {
    background-color: #ffffff;
  }
`;

export const StyledButton = styled.button`
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;

  text-decoration-line: underline;

  &:hover {
    text-decoration-thickness: 2px;
  }
`;

export const StyledDrag = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #ffffff80;
`;

export const StyledFlexBox = styled(Flexbox)`
  align-items: center;
`;

export const StyledError = styled("p")`
  color: rgb(211, 47, 47);
  font-size: 0.75rem;
  margin: 0;
`;

export const ProgressBar = styled.div`
  margin-top: 0.25rem;
  width: 100%;
  height: 0.5rem;
  background-color: rgb(246, 246, 246);
  border-radius: 0.25rem;

  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${(props: { percentage: number }) =>
      props.percentage ? props.percentage * 100 + "%" : 0};
    background-color: #0088ce; //#00ac3e
    border-radius: 0.25rem;
  }
`;
