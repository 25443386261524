import { __rest } from '../../_virtual/_tslib.js';

/* eslint-disable react/prop-types */
function renderViewDefault(props) {
  return React.createElement("div", Object.assign({}, props));
}
function renderTrackHorizontalDefault(_a) {
  var {
      style
    } = _a,
    props = __rest(_a, ["style"]);
  const finalStyle = Object.assign(Object.assign({}, style), {
    right: 2,
    bottom: 2,
    left: 2,
    borderRadius: 3
  });
  return React.createElement("div", Object.assign({
    style: finalStyle
  }, props));
}
function renderTrackVerticalDefault(_a) {
  var {
      style
    } = _a,
    props = __rest(_a, ["style"]);
  const finalStyle = Object.assign(Object.assign({}, style), {
    right: 2,
    bottom: 2,
    top: 2,
    borderRadius: 3
  });
  return React.createElement("div", Object.assign({
    style: finalStyle
  }, props));
}
function renderThumbHorizontalDefault(_a) {
  var {
      style
    } = _a,
    props = __rest(_a, ["style"]);
  const finalStyle = Object.assign(Object.assign({}, style), {
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: 'rgba(0,0,0,.2)'
  });
  return React.createElement("div", Object.assign({
    style: finalStyle
  }, props));
}
function renderThumbVerticalDefault(_a) {
  var {
      style
    } = _a,
    props = __rest(_a, ["style"]);
  const finalStyle = Object.assign(Object.assign({}, style), {
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: 'rgba(0,0,0,.2)'
  });
  return React.createElement("div", Object.assign({
    style: finalStyle
  }, props));
}

export { renderThumbHorizontalDefault, renderThumbVerticalDefault, renderTrackHorizontalDefault, renderTrackVerticalDefault, renderViewDefault };
