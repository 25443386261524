import styled from "styled-components";
import {
  mobile,
  mobileLarge,
  tablet,
  tabletLarge,
  desktop,
} from "theme/variables";
import { CheckboxGroupProps } from "./CheckboxGroup";

export const StyledCheckboxGrid = styled("div")<CheckboxGroupProps>`
  padding: ${(props) => props.pd || 0};
  
  &> div {
    max-width: ${(props) => props.maxWidth || "100%"};
    min-width: ${(props) => props.minWidth || ""};
    width: ${(props) => props.width || "100%"};
    display: grid;
    grid-row-gap: ${(props) => props.rowGutter || "1.25rem"};
    grid-column-gap: ${(props) => props.colGutter || "1.25rem"};
    align-self: ${(props) => props.align || ""};
    > * {
      height: ${(props) => props.rowHeight || ""};
    }
    &> div {
      label {
        span {
          display: flex;
          flex-direction: row;
          &:first-child {
            margin-right: 0.25rem;
          }
        }
      }
    }
  
    @media ${mobile} {
      grid-template: auto / repeat(${(props) => props.cols?.xs || 1}, 1fr);
    }
  
    @media ${mobileLarge} {
      grid-template: auto / repeat(
          ${(props) => props.cols?.sm || props.cols?.xs || 1},
          1fr
        );
    }
  
    @media ${tablet} {
      grid-template: auto / repeat(
          ${(props) => props.cols?.md || props.cols?.sm || props.cols?.xs || 1},
          1fr
        );
    }
  
    @media ${tabletLarge} {
      grid-template: auto / repeat(
          ${(props) =>
    props.cols?.lg ||
    props.cols?.md ||
    props.cols?.sm ||
    props.cols?.xs ||
    1},
          1fr
        );
    }
  
    @media ${desktop} {
      grid-template: auto / repeat(
          ${(props) =>
    props.cols?.xl ||
    props.cols?.lg ||
    props.cols?.md ||
    props.cols?.sm ||
    props.cols?.xs ||
    1},
          1fr
        );
    }
    &> div {
      padding: 0;
    }
  }
  
`;
