import { EmploymentTitleProps } from "interfaces";
import { object, string } from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = (props: any) =>
  object().shape({
    firstName: string().required("You must enter your First Name"),
    lastName: string().required("You must enter your Last Name"),
    email: string()
      .email("You must enter a valid Email")
      .required("You must enter an Email"),
    verizonContact: string()
      .email("You must enter a valid Email")
      .required("You must enter a Verizon Contact Email"),
    phone: string()
      .matches(phoneRegExp, "You must enter a valid Phone Number")
      .required("You must enter a Phone Number"),
    country: string().required("You must enter a Country"),
    company: string().required("You must enter a Company"),
    employmentTitle: string().required("You must enter a User Role"),
    customEmploymentTitle: string()
      .nullable()
      .when("employmentTitle", {
        is: (employmentTitle: string) =>
          props.employmentTitles.find(
            (item: EmploymentTitleProps) => item.name === "Other"
          )?.id === employmentTitle,
        then: () =>
          string().required("You must enter a Custom Employment Title"),
      }),
    password: string().required("You must enter a Password"),
    passwordConfirm: string()
      .required("You must Retype Your Password")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
    recaptchaToken: string().required(),
  });

export default (props: any) => validationSchema(props);
