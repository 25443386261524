function getInnerHeight(el) {
  const {
    clientHeight
  } = el;
  const {
    paddingTop,
    paddingBottom
  } = getComputedStyle(el);
  return clientHeight - parseFloat(paddingTop) - parseFloat(paddingBottom);
}

export { getInnerHeight as default };
