import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { StyledContainerProps } from "./TileContainer";

const basicTile = css`
  height: 100%;

  text-decoration: none;

  overflow: hidden;

  display: flex;
  flex-direction: column;

  border-radius: 0.5rem;

  img {
    object-fit: cover;
  }
`;

export const StyledContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["minHeight", "maxWidth", "justify", "radius"].includes(prop),
})<StyledContainerProps>`
  min-height: ${(props) => props.minHeight || ""};
  max-width: ${(props) => props.maxWidth || ""};
  padding: ${(props) => props.pd || "1.5rem"};

  text-align: ${(props) => props.textAlign || "left"};

  color: ${(props) => props.color || "inherit"};
  background: ${(props) => props.bg || "white"};
  justify-content: ${(props) => props.justify || "space-between"};

  ${basicTile};
  
  border-radius: ${(props) => props.radius || "0.5rem"};
  border: ${(props) => props.border ? "0.5px solid #A7A7A7" : "none"};
  
`;

const baseLinkStyle = css`
  max-width: ${(props: StyledContainerProps) => props.maxWidth || ""};
  min-height: ${(props: StyledContainerProps) => props.minHeight || ""};
  padding: ${(props: StyledContainerProps) => props.pd || "1.5rem"};

  color: ${(props: StyledContainerProps) => props.color || "inherit"};
  background: ${(props: StyledContainerProps) => props.bg || "white"};

  text-align: ${(props: StyledContainerProps) => props.textAlign || "left"};

  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
  transition: all 0.1s;

  &:hover {
    transform: ${(props: StyledContainerProps) =>
      props.noAnimation ? "none" : "scale(1.025)"};
  }
  .templates-img {
    max-width: 80%;
    display: block;
    position: absolute;
    bottom: 0;
    right: 1.5rem;
  }

  justify-content: ${(props: StyledContainerProps) => props.justify || "space-between"};

  ${basicTile};

  border-radius: ${(props: StyledContainerProps) => props.radius || "0.5rem"};
  border: ${(props) => props.border ? "0.5px solid #A7A7A7" : "none"};

  &.quicklink-0,
  &.quicklink-3,
  &.quicklink-4,
  &.quicklink-7,
  &.quicklink-8,
  &.quicklink-11 {
    background-color: #f6f6f6;
  }
  &.quicklink-1,
  &.quicklink-2,
  &.quicklink-5,
  &.quicklink-6,
  &.quicklink-9,
  &.quicklink-10 {
    border: 1px solid #d8dada;
  }
  &.quicklink {
    border-radius: 0.5rem;
    position: relative;
  }
`;

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) =>
    !["minHeight", "textAlign", "noAnimation", "radius"].includes(prop),
})<StyledContainerProps>`
  ${baseLinkStyle};
`;

export const StyledExternalLink = styled.a.withConfig({
  shouldForwardProp: (prop) =>
    !["minHeight", "textAlign", "noAnimation", "radius"].includes(prop),
})<StyledContainerProps>`
  ${baseLinkStyle};
`;
