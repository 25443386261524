import { useEffect, useState } from "react";
import {
  ShareSavedSearchesModalProps,
  SuggestedUser,
  SavedSearchResponse,
} from "interfaces";
import {
  useShareSavedSearchMutation,
  useLazyGetSuggestedUsersQuery,
} from "services/api/api.slice";
import { setNotification } from "services";
import {
  Modal,
  Title,
  TextButton,
  Button,
  TextArea,
  Form,
  ButtonGroup,
  MultiSelectSearch,
  Body,
  IconButton,
} from "components";
import { FormikProps } from "interfaces";
import { useDispatch } from "hooks/redux";
import { Grid } from "@mui/material";
import {
  SavedSearchesList,
  SavedSearchItem,
} from "./ShareSavedSearchesModal.module";

const ShareSavedSearchesModal = ({
  savedSearches,
  removeSavedSearch,
  onClose,
  values: { shareRecipients, shareMessage },
  handleChange,
  handleBlur,
  setFieldValue,
  resetForm,
  onComplete,
  hideBackdrop,
}: ShareSavedSearchesModalProps & FormikProps) => {
  const [callGetUsers, { data: searchResults = [], isFetching: fetchLoading }] =
      useLazyGetSuggestedUsersQuery(),
    [shareSavedSearches, { isLoading: shareLoading }] =
      useShareSavedSearchMutation();

  const dispatch = useDispatch();
  const handleOnSubmit = () => {
      shareSavedSearches({
        recipientIds: shareRecipients.map(
          (recipient: SuggestedUser) => recipient.id
        ),
        searchIds: savedSearches.map((search) => (search.id)),
        message: shareMessage,
      })
        .unwrap()
        .then(() => {
          onComplete();
          dispatch(
            setNotification({
              type: "success",
              message: `Search${
                savedSearches.length > 1 ? "es" : ""
              } shared successfully.`,
            })
          );
        })
        .catch((error: any) => {
          dispatch(
            setNotification({
              type: "error",
              message: `Could not share search${
                savedSearches.length > 1 ? "es" : ""
              }.`,
            })
          );
          console.error(error);
        });
    },
    handleClose = () => {
      resetForm();
      onClose();
    };

  const handleRemoveAsset = (id: string) => {
    removeSavedSearch(id);
    savedSearches.length <= 1 && onClose();
  };

  const removeSelected = (selected: any) => () => {
    setFieldValue(
      "shareRecipients",
      shareRecipients.filter((item: any) => item.id !== selected.id)
    );
  };

  return (
    <Modal
      open
      onClose={handleClose}
      hideBackdrop={hideBackdrop}
      disabled={shareLoading}
      testId="share-saved-searches-modal"
    >
      <Title size="large" bold>
        {savedSearches.length > 1
          ? `Share ${savedSearches.length} saved searches`
          : "Share saved search"}
      </Title>

      {savedSearches.length > 0 && (
        <SavedSearchesList>
          {savedSearches.map((savedSearch: SavedSearchResponse) => (
            <SavedSearchItem key={savedSearch.id}>
              <IconButton
                name="close"
                onClick={() => handleRemoveAsset(savedSearch.id)}
                noHover
                disabled={shareLoading}
                testId="remove-search"
              />
              <Body size="large" bold>
                {savedSearch.name}
              </Body>
            </SavedSearchItem>
          ))}
        </SavedSearchesList>
      )}

      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={6} width="100%">
          <Form onSubmit={handleOnSubmit} width="100%" gap="1rem">
            <MultiSelectSearch
              label="Recipients"
              name="shareRecipients"
              value={shareRecipients}
              callSearch={callGetUsers}
              optionLabels={["firstName", "lastName"]}
              setFieldValue={setFieldValue}
              selectedLabel="Selected recipients"
              removeSelected={(selected: any) => removeSelected(selected)}
              disabled={shareLoading}
              // TODO: add error handling
            />

            <TextArea
              value={shareMessage}
              required={false}
              onChange={handleChange}
              name="shareMessage"
              label="Message"
              disabled={shareLoading}
              data-testid="message"
              maxLength={1000}
            />
            <ButtonGroup>
              <Button
                use="primary"
                type="submit"
                disabled={shareLoading || shareRecipients.length === 0}
                data-testid="submit-button"
              >
                Send
              </Button>
              <TextButton
                data-testid="cancel-button"
                onClick={handleClose}
                disabled={shareLoading}
              >
                Cancel
              </TextButton>
            </ButtonGroup>
          </Form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ShareSavedSearchesModal;
