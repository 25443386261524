import { StyledForm } from "./SignInForm.module";
import { FormikProps, SignInProps } from "interfaces";
import { useSelector } from "hooks/redux";
import {
  resetAuthNotification,
  selectAuthNotification,
  selectStatus,
} from "services";
import { CognitoSignInProps } from "interfaces/Cognito";
import { useContext } from "react";
import paths from "routing/paths";
import { AuthContext } from "contexts/authContext";
import Button from "components/buttons/Button/Button";
import ButtonGroup from "components/buttons/ButtonGroup/ButtonGroup";
import Input from "components/forms/Input/Input";
import Body from "components/typography/Body/Body";
import TextLink from "components/buttons/TextLink/TextLink";
import ASYNC_STATUS from "constants/asyncStatus";
import IconButton from "../../components/buttons/IconButton/IconButton";
import Title from "../../components/typography/Title/Title";
import { Notification } from "components";
export interface FormProps {
  values: CognitoSignInProps;
  touched: CognitoSignInProps;
  errors: CognitoSignInProps;
}

const SignInForm = ({
  expanded,
  setExpanded,
  values: { email, password },
  handleChange,
  handleBlur,
  handleSubmit,
  resetForm,
  touched,
  errors,
  dirty,
  isValid,
}: SignInProps & FormProps & FormikProps) => {
  const { setEmail, setPassword } = useContext<any>(AuthContext),
    isLoading = useSelector(selectStatus) === ASYNC_STATUS.LOADING;

  const handleOnSubmit = () => {
      setEmail(email);
      setPassword(password);
    },
    handleCancel = () => {
      setExpanded(false);
      resetForm();
    };

  return (
    <StyledForm
      className={expanded ? `expanded` : ``}
      onSubmit={handleSubmit}
      data-testid="sign-in-form"
    >
      {expanded && (
        <>
          <Title size="small" bold pd="1rem 0 1.25rem 0">
            Sign in
          </Title>
          <IconButton
            onClick={handleCancel}
            className="close-button"
            name="close"
            size="small"
            color="#000000"
            disabled={isLoading}
            noHover
          />
        </>
      )}
      {expanded && (
        <>
          <Input
            name="email"
            value={email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched["email"] && !!errors["email"]}
            errorText={errors["email"]}
            disabled={isLoading}
            data-testid="email"
          />
          <Input
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched["password"] && !!errors["password"]}
            errorText={errors["password"]}
            disabled={isLoading}
            data-testid="password"
          />
        </>
      )}
      {expanded && (
        <>
          <Notification
            selector={selectAuthNotification}
            reset={resetAuthNotification}
            inline
          />
          <TextLink
            to={paths.FORGOT_PASSWORD}
            align="flex-end"
            disabled={isLoading}
            display="flex"
            data-testid="forgot-password"
          >
            Forgot password?
          </TextLink>

          <ButtonGroup align="flex-start" pd="1.5rem 0">
            <Button
              type="submit"
              use="primary"
              onClick={handleOnSubmit}
              disabled={isLoading || !(dirty && isValid)}
              data-testid="submit"
            >
              Sign in
            </Button>
            <Button
              type="button"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel"
              use="secondary"
            >
              Cancel
            </Button>
          </ButtonGroup>
          <Body bold>New to Brand Central?</Body>
          <TextLink
            to={paths.SIGNUP}
            bold
            disabled={isLoading}
            data-testid="sign-up"
          >
            Create an account now.
          </TextLink>
        </>
      )}
    </StyledForm>
  );
};

export default SignInForm;
