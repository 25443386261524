import styled from "styled-components";
import { FormProps } from "components/forms/Form/Form";
import {zIndex, minTablet, minMobileLarge} from "theme/variables";
import { Form } from "components";
import { Grid } from "@mui/material";
import FlexBox from "../../components/layouts/FlexBox/FlexBox";
export const SearchSection = styled.section`
  z-index: ${zIndex.searchBar};
  position: relative;
`;

export const SearchBar = styled.div`
  position: relative;
  padding: 2.25rem 0 0.5rem;
  z-index: ${zIndex.notBottom};
  border-bottom: 1px solid #000000;
  background-color: #ffffff;
`;

export const SearchButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  @media screen and (max-width: ${minMobileLarge - 1}px) {
    div:first-of-type {
      display: none;
    }
  }
`;

export const SearchForm = styled(Form)<FormProps>`
  flex-direction: row;
  align-items: center;
  margin: auto;

  @media screen and (max-width: ${minTablet - 1}px) {
    padding: 0 1rem;
    gap: 0.5rem;
  }
  @media screen and (min-width: ${minTablet}px) {
    padding: 0 2.5rem;
    gap: 1rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0 1rem;
    justify-content: flex-start;
  }
  
  max-width: 1440px;
  > input {
    font-family: Verizon-NHG-eDS, "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: bold;
    
    
    @media screen and (max-width: ${minTablet - 1}px) {
      font-size: 1.25rem;
      height: 2.5rem;
    }
    @media screen and (min-width: ${minTablet}px) {
      font-size: 3rem;
      height: 3.5rem;
    }

    background-color: transparent;
    border: none;

    width: 100%;

    &:focus {
      outline: none;
    }
  }
`;

export const SearchDropdown = styled.div`
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);

  background-color: #ffffff;
  height: fit-content;

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  

  &.open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  transition: visibility 200ms ease-in-out, transform 200ms ease-in-out,
    opacity 200ms ease-in-out;
`;

export const SearchBackdrop = styled.div`
  z-index: ${zIndex.backdrop};
  visibility: hidden;
  opacity: 0;

  background-color: rgba(116, 118, 118, 0.7);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.open {
    visibility: visible;
    opacity: 1;
  }

  transition: visibility 200ms ease-in-out, opacity 200ms ease-in-out;
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  position: relative;
`;

export const TotalWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  
  p {
    font-size: 0.875rem;
  }

`;

export const DropdownContentWrapper = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 1440px;
  @media screen and (min-width: 1440px) {
    //align-items: flex-start;
    
  }
`;

export const StyledGrid = styled(Grid)`
  position: relative;
  
  @media screen and (max-width: ${minTablet - 1}px) {
    padding: 1.25rem 1rem;
  }
  @media screen and (min-width: ${minTablet}px) {
    padding: 1.25rem 0 1.25rem 4.25rem;
  }
  
`;

export const RecentAndSuggestedSearchesGrid = styled(Grid)`
  max-width: 1440px;
  @media screen and (max-width: ${minTablet - 1}px) {
    padding: 2.625rem 1rem 3.625rem;
  }
  @media screen and (min-width: ${minTablet}px) {
    padding: 2.625rem 1.25rem 3.625rem 2.5rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 2.625rem 1rem 3.625rem;
  }
`;

export const RecentSearchesGrid = styled(Grid)`
  @media screen and (max-width: ${minMobileLarge - 1}px) {
    padding-right: 0;
    padding-bottom: 2rem;
  }
  @media screen and (min-width: ${minMobileLarge}px) {
    padding-right: 1rem;
    padding-bottom: 0;
  }

`;

export const StyledRecentSearchesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  width: fit-content;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.25rem;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
  }

  .recent {
    p {
      color: #6f7171;
    }
    &:not([disabled]):hover {
      cursor: pointer;
      p {
        color: #000000;
      }
    }
  }

  .delete-button {
    margin-left: 1.5rem;
    &:not([disabled]):hover {
      cursor: pointer;
      path {
        stroke: black;
      }
    }
  }
`;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  width: fit-content;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
  }

  .suggested {
    cursor: pointer;
    &:hover {
      p {
        color: #000000;
      }
    }
  }

  .recent {
    &:not([disabled]):hover {
      cursor: pointer;
      p {
        color: #000000;
      }
    }
  }

  .delete-button {
    margin-left: 1.5rem;
    &:not([disabled]):hover {
      cursor: pointer;
      path {
        stroke: black;
      }
    }
  }
`;
