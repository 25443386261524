import styled from "styled-components";
import { minTablet } from "../../../theme/variables";

interface BasicCarouselProps {
  length: number;
  slidesPerView: number;
  $bgColor?: string;
  pd?: string;
  gap?: string;
  navSize?: string;
  assetCardThumbnails?: boolean;
  carouselId: string;
  onNavClick?: () => void;
}

export const StyledCarousel = styled("div")<BasicCarouselProps>`
  position: relative;

  /* max-width: 80%;
  margin: auto;
  overflow: hidden;
  width: fit-content; */

  .fullscreen-button {
    left: 1rem;
  }

  &.fullscreen-carousel {
    width: 100%;
  }

  &.thumbnails-carousel {
    display: none;
    width: 100%;
    @media screen and (min-width: ${minTablet}px) {
      display: block;
      height: 0;
      transform: scale(0);
      transition: opacity ease-in 100ms, height ease-in 250ms;
      opacity: 0;
      position: relative;
      width: 100%;
      margin: auto;
    }
    &.fullscreen-carousel {
      display: block;
      width: 100%;
    }
    &.focused {
      @media (min-width: ${minTablet}px) {
        transform: scale(1);
        height: auto;
        opacity: 1;
        transition: opacity ease-out 400ms;
      }
    }
    #carousel-slides-${(props) => `${props.carouselId}`} {
      position: relative;
      z-index: 2;
      width: 80%;
      margin: auto;
      //&> div {
      //  padding: 0 0.5rem;
      //}
      .asset-detail-nav .tile-button {
        padding: 0.5rem 0;
      }

      .highlighted {
        .tile-button {
          &:hover,
          &:focus,
          &:focus-within,
          &:active,
          &:visited,
          &:focus:not(:hover),
          &:focus:not(:hover):not(:disabled) {
            &::before {
              width: 100%;
            }
          }
          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  > #carousel-slides-${(props) => `${props.carouselId}`} {
    .asset-detail-nav {
      .tile-button {
        padding: 0 0.5rem 1rem;
      }
    }
    /* hide scroll bars and allow overflow-x */
    overflow-x: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding: 0;

    overflow-y: visible;

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: ${(props) =>
      `repeat(${props.length}, ${
        props.slidesPerView > 1
          ? `calc(100% / ${props.slidesPerView})`
          : `calc(100% / ${props.slidesPerView})`
      } )`};
    padding: ${(props) => `${props.pd ? props.pd : "0rem"}`};
    gap: ${(props) => `${props.gap ? props.gap : "0rem"}`};
    & > div {
      background-color: ${(props) => `${props.$bgColor ? props.$bgColor : ""}`};
      border-radius: ${(props) => `${props.$bgColor ? "8px" : "unset"}`};
    }

    .tile-button {
      &:hover {
        cursor: pointer;
      }
      position: relative;
      background-color: transparent;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      border: none;
      &:hover,
      &:focus,
      &:focus-within,
      &:active,
      &:visited,
      &:focus:not(:hover) {
        border: none;
        border-radius: unset;
        box-shadow: unset;
        span {
          &::before {
            border: none;
            border-radius: unset;
            box-shadow: unset;
            width: 0;
            height: 0;
          }
        }
      }
      &:focus:not(:hover):not(:disabled) {
        &::before {
          border: none;
          border-radius: unset;
          box-shadow: unset;
          width: 0;
          height: 0;
        }
      }
      span {
        padding: 0;
      }
    }

    .highlighted {
      .tile-button {
        &:hover,
        &:focus,
        &:focus-within,
        &:active,
        &:visited,
        &:focus:not(:hover),
        &:focus:not(:hover):not(:disabled) {
          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 2%);
            height: 1px;
            background-color: #000;
            top: unset;
          }
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 2%);
          height: 1px;
          background-color: #000;
          top: unset;
        }
      }
    }
  }
`;

export const StyledNavigation = styled("div")<BasicCarouselProps>`
  position: absolute;
  top: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  > button {
    z-index: 3;
    position: relative;
    padding: 0;
    background-color: transparent;
    :not([disabled]) {
      cursor: pointer;
    }
    /* &:first-child {
      margin-left: ${(props: BasicCarouselProps) =>
      `${props.assetCardThumbnails ? "-1.5rem" : "0"}`};
    }
    &:last-child {
      margin-right: ${(props: BasicCarouselProps) =>
      `${props.assetCardThumbnails ? "-1.5rem" : "0"}`};
    } */

    > svg {
      width: ${(props: BasicCarouselProps) =>
        `${props.navSize ? props.navSize : "2.5rem"}`};
      height: ${(props) => `${props.navSize ? props.navSize : "2.5rem"}`};
      min-width: ${(props) => `${props.navSize ? props.navSize : "2.5rem"}`};
      min-height: ${(props) => `${props.navSize ? props.navSize : "2.5rem"}`};
    }
  }

  p {
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    position: absolute;
  }
`;
