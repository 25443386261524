import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import mapPropsToValues from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import NoResults from "./NoResults";

export const NoResultsContainer = compose(
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit: () => null,
  })
)(NoResults);

export default NoResultsContainer;
