import styled from "styled-components";
import { TextArea } from "@vds/inputs";

export interface TextAreaProps {
  value: string;
  onFocus?: Function;
  onBlur?: Function;
  pd?: string;
  maxWidth?: string;
}
export const StyledTextArea = styled(TextArea)<TextAreaProps>`
  span[type="password"] {
    div:nth-of-type(2) {
      padding: 0px 0.6875rem 0px 0px;
    }
  }
  padding: ${(props) => props.pd || 0};
  max-width: ${(props) => props.maxWidth || "none"};
`;
