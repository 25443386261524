import styled from "styled-components";

export const StyledHorizontalRule = styled.hr`
  border-top: #000000 .063rem solid;
  min-width: 100%;
`;

export const StyledEmailList = styled.ul`
  margin-top: 0;
  padding-inline-start: 0;
  list-style-type: none;
  
  li {
    margin-bottom: .75rem;
  }
`;

export const StyledFileList = styled.ul`
  margin-top: 0;
  padding-inline-start: 1rem;
  list-style-type: disc;

  li {
    margin-bottom: .75rem;
  }
`;
