import styled from "styled-components";

export const StyledBodyWrapper = styled.div`
  .expiring-text > p {
    font-weight: bold;
    color: #b95319;
  }

  .new-only-text > p {
    font-weight: bold;
  }
`;

export const StyledHorizontalRule = styled.hr`
  border-top: #000000 0.063rem solid;
  min-width: 100%;
`;

export const StyledList = styled.ul`
  padding-inline-start: 1rem;
  margin-top: 0;
  list-style-type: disc;

  li {
    margin-bottom: 0.25rem;
  }
`;
