import {
  Button,
  Form,
  Input,
  Title,
  Body,
  TextButton,
  ButtonGroup,
} from "components";
import { FormikProps } from "interfaces";
import { useDispatch, useSelector } from "hooks/redux";
import { resetAuthState, selectStatus, selectUser, signUp } from "services";
import { CognitoUserInterface } from "interfaces/Cognito";
import FlexBox from "components/layouts/FlexBox/FlexBox";
import { useNavigate } from "react-router-dom";
import paths from "routing/paths";

const SignupConfirm = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  dirty,
  isValid,
  handleSubmit,
}: FormikProps) => {
  const user = useSelector(selectUser) as CognitoUserInterface,
    dispatch = useDispatch(),
    isLoading = useSelector(selectStatus) === "LOADING";

  const navigate = useNavigate(),
    handleCancel = () => {
      dispatch(resetAuthState());
      navigate(paths.SIGNIN);
    },
    handleResendCode = () => {
      dispatch(signUp({ resend: true }));
    };

  return (
    <Form onSubmit={handleSubmit} maxWidth="360px">
      <Title size="large" bold={true} pd="0 0 0.5rem 0">
        Enter Code
      </Title>
      <Body pd="0 0 1.5rem 0">
        A verification code has been sent to "{user?.username}". Please enter it
        below.
      </Body>
      <Input
        type="text"
        name="code"
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isLoading}
        error={touched.code && errors.code}
        errorText={errors.code}
        data-testid="code"
      />
      <FlexBox pd="1.5rem 0 0">
        <TextButton
          onClick={handleResendCode}
          disabled={isLoading}
          testId="resend-code"
          size="small"
        >
          Send a new code
        </TextButton>
      </FlexBox>
      <ButtonGroup pd="1.5rem 0 0 0">
        <Button
          type="submit"
          disabled={isLoading || !(dirty && isValid)}
          data-testid="submit"
        >
          Submit
        </Button>
        <Button
          type="button"
          onClick={handleCancel}
          disabled={isLoading}
          data-testid="cancel"
          use="secondary"
        >
          Cancel
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default SignupConfirm;
