import { Component, Suspense } from "react";
import Loader from "../components/Loader/Loader";

const withSuspense = (WrappedComponent: any) => {
  const Fallback = <Loader active />;

  return class extends Component {
    render() {
      return (
        <Suspense fallback={Fallback}>
          <WrappedComponent {...this.props} />
        </Suspense>
      );
    }
  };
};

export default withSuspense;
