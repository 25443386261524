import { __rest } from '../_virtual/_tslib.js';
import React from 'react';
import PropTypes from 'prop-types';
import { TableHead as TableHead$1 } from '@vds-core/tables';

const propTypes = {
  /**
   * Options to render the bottom line of the the Table Header
   */
  bottomLine: PropTypes.oneOf(['primary', 'secondary', 'none'])
};
const defaultProps = {
  bottomLine: 'primary'
};
const TableHead = function (props) {
  const {
      bottomLine
    } = props,
    rest = __rest(props, ["bottomLine"]);
  return React.createElement(TableHead$1, Object.assign({
    bottomLine: bottomLine
  }, rest));
};
TableHead.propTypes = propTypes;
TableHead.defaultProps = defaultProps;

export { TableHead as default };
