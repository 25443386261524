import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as Pagination$1 } from '@vds-core/pagination';

const propTypes = {
  /**
  * Option to render on dark or light surface
  */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const calculateRange = function (selectedPage, total) {
  let additionalPagesToShow = selectedPage < 98 ? 3 : 2;
  let startRange = Math.min(selectedPage - 1, total - additionalPagesToShow);
  if (selectedPage === 1) {
    startRange = 1;
  }
  let endRange = startRange + additionalPagesToShow;
  return {
    startRange,
    endRange
  };
};
const Pagination = function (props) {
  return React.createElement(Pagination$1, Object.assign({}, props, {
    calculateRange: calculateRange,
    focusBorderRadius: "2px"
  }));
};
Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export { Pagination as default };
