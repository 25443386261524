import { TextAreaProps } from "interfaces";
import { TextAreaProps as VdsTextAreaProps } from "@vds/inputs";
import { StyledTextArea } from "./TextArea.module";

const TextArea = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  disabled,
  maxWidth,
  defaultValue,
  required = false,
  noError = false,
  error,
  errorText = " ",
  testId,
  maxLength = 1000,
  ...rest
}: VdsTextAreaProps & TextAreaProps) => {
  return (
    <StyledTextArea
      required={required}
      errorEvent="blur"
      name={name}
      label={label ? label : name.charAt(0).toUpperCase() + name.slice(1)}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      error={noError ? false : error}
      errorText={errorText}
      maxWidth={maxWidth}
      data-testid={testId || "text-area"}
      maxLength={maxLength}
      {...rest}
    />
  );
};

export default TextArea;
