import { __rest } from '../../_virtual/_tslib.js';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { calculateRem } from '@vds-core/utilities';
import { Fonts } from '../../fonts/index.js';

const propTypes = {
  primitive: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span']),
  children: PropTypes.node
};
const defaultProps = {
  children: null,
  primitive: 'p'
};
/**
 * @ignore
 */
const StyledTypography = styled(_a => {
  var {
      fontSize,
      fontWeight,
      lineheight,
      fontFamily,
      letterSpacing,
      colorConfig,
      color,
      bold,
      strikethrough,
      fontsconfig,
      primitive,
      children
    } = _a,
    rest = __rest(_a, ["fontSize", "fontWeight", "lineheight", "fontFamily", "letterSpacing", "colorConfig", "color", "bold", "strikethrough", "fontsconfig", "primitive", "children"]);
  return React.createElement(primitive, rest, children);
}).withConfig({
  displayName: "StyledTypography",
  componentId: "VDS__sc-5k55co-0"
})`
  font-size: ${({
  fontSize
}) => calculateRem(fontSize)};
  font-weight: ${({
  fontWeight
}) => fontWeight};
  line-height: ${({
  lineheight
}) => calculateRem(lineheight)};
  font-family: ${({
  fontFamily
}) => fontFamily + ', Helvetica, Arial, Sans-serif'};
  letter-spacing: ${({
  letterSpacing
}) => letterSpacing && calculateRem(letterSpacing)};
  color: ${({
  color
}) => color};
  margin: 0;
  text-decoration: none;
`;
/**
 * @ignore
 */
const Typography = props => {
  const {
    primitive,
    children
  } = props;
  if (Fonts.checkFontLoaded && !Fonts.checkFontLoaded()) {
    Fonts.loadFonts && Fonts.loadFonts();
  }
  return React.createElement(StyledTypography, Object.assign({}, props, {
    primitive: primitive
  }), children);
};
Typography.propTypes = propTypes;
Typography.defaultProps = defaultProps;

export { Typography as default };
