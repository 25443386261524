import { SelectProps } from "interfaces";
import { DropdownSelectProps } from "@vds/selects";
import { StyledSelect, StyledSelectText } from "./Select.module";
import { Autocomplete, TextField } from "@mui/material";
import { Icon } from "@vds/icons";
import { Body } from "components";

const Select = ({
  autoComplete,
  items = [],
  label,
  name,
  value,
  optionLabels = ["name"],
  valueKey = "id",
  onChange,
  disabled,
  noError,
  error,
  errorText,
  includeEmpty = false,
  maxWidth,
  width,
  disableClearable = true,
  filterSelected = false,
  testId,
  ...rest
}: SelectProps & DropdownSelectProps) => {
  const generateOptionLabel = (option: any) => {
    return option
      ? optionLabels.reduce((result: string, item: string, index: number) => {
          if (index === optionLabels.length - 1) {
            return `${result}${option[item]}`;
          }
          return `${result}${option[item]} `;
        }, "")
      : "";
  };

  if (autoComplete) {
    return (
      <Autocomplete
        value={value}
        options={items}
        disabled={disabled}
        autoComplete
        fullWidth
        freeSolo
        onChange={(_, value: readonly any[], reason) => {
          const tempValue: any[] = JSON.parse(JSON.stringify(value));
          if (reason !== "removeOption") {
            onChange && onChange(tempValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                // @ts-ignore
                e.target.blur();
              }
            }}
            onBlur={(e) => onChange && onChange(e.target.value)}
          />
        )}
        renderOption={(props, option) => {
          const optionLabel =
            typeof option === "string" ? option : generateOptionLabel(option);
          return (
            <li {...props} key={option.id || optionLabel}>
              <StyledSelectText>
                <Body className="ellipsis" size="large">
                  {optionLabel}
                </Body>
              </StyledSelectText>
            </li>
          );
        }}
        getOptionLabel={(option: any) =>
          typeof option === "string" ? option : generateOptionLabel(option)
        }
        getOptionDisabled={(option) => option === "Type new group to add"}
        ListboxProps={{
          style: { maxHeight: "10rem" },
        }}
        isOptionEqualToValue={(option: any, value: any) => {
          return typeof option === "string"
            ? option === value
            : option[valueKey] === value[valueKey];
        }}
        renderTags={() => null}
        forcePopupIcon
        popupIcon={<Icon name="down-caret" />}
        sx={{
          "&.MuiAutocomplete-root": {
            backgroundColor: "#FFFFFF",
          },
        }}
        filterSelectedOptions={filterSelected}
        disableClearable={disableClearable}
        data-testid={testId || "mui-select"}
      />
    );
  } else {
    return (
      <StyledSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={noError ? false : error}
        errorText={errorText}
        maxWidth={maxWidth}
        width={width}
        data-testid={testId || "select"}
        {...rest}
      >
        {includeEmpty ? (
          <option
            data-testid="empty-option"
            value={undefined}
            disabled
          ></option>
        ) : (
          <></>
        )}
        {items.map((item: any) => {
          return (
            <option
              data-testid="select-option"
              key={item[valueKey] || item}
              value={item[valueKey]}
            >
              {typeof item === "string" ? item : generateOptionLabel(item)}
            </option>
          );
        })}
      </StyledSelect>
    );
  }
};

export default Select;
