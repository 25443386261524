import MdToHtml from "../MdToHtml/MdToHtml";
import { StyledSiteAlertBanner } from "./SiteAlertBanner.module";
import Title from "../typography/Title/Title";

type SiteAlertBannerProps = {
  content: any;
  title?: any;
};

const SiteAlertBanner = ({ content, title }: SiteAlertBannerProps) => {
  return (
    <StyledSiteAlertBanner className="brand-central-site-alert-banner">
      <Title bold size="small" color="#ffffff">
        {title}
      </Title>
      <MdToHtml
        content={content}
        linkSize="large"
        pSize="large"
        color="#ffffff"
        pMargin="0"
        linkTextDecoration
      ></MdToHtml>
    </StyledSiteAlertBanner>
  );
};

export default SiteAlertBanner;
