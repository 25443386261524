import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import mapPropsToValues from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import { FileCourierFormProps } from "interfaces/FileCourier";
import FileCourierForm from "./FileCourierForm";
import { withAction } from "HOCs/withAction";
import { setNotification } from "../../services";

export const FileCourierFormContainer = compose(
  withAction((props: any) => setNotification(props), "setNotification"),
  withFormik<{}, FileCourierFormProps>({
    mapPropsToValues,
    validationSchema,
    handleSubmit: () => null,
    enableReinitialize: true,
    validateOnMount: true,
  })
)(FileCourierForm);

export default FileCourierFormContainer;
