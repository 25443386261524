import { useEffect, useState } from "react";
import {
  TextButton,
  FlexBox,
  Title,
  Body,
  IconButton,
  TwoUp,
  DatePickerToggle,
  Button,
  Modal,
  Loader,
} from "components";
import { DeleteFileCourierPackageModal } from "features";
import { Modal as MuiModal } from "@mui/material";
import {
  StyledHorizontalRule,
  StyledTable,
  StyledFlexBox,
  StyledWrapper,
} from "./FileCourierSentDetailsModal.module";
import { downloadFile } from "utils/fileCourier";
import {
  FileCourierSentDetailsModalProps,
  FileCourierSentPackageProps,
} from "interfaces";
import { formatDate } from "utils/dateFunctions";
import {
  setNotification,
  useLazyExtendFileCourierPackageQuery,
  useLazyGetSentFileCourierPackageDetailsQuery,
} from "services";
import { downloadPackage } from "../../utils/fileCourier";
import { useDispatch } from "react-redux";

const FileCourierSentDetailsModal = ({
  packageId,
  onClose,
  onRefresh,
}: FileCourierSentDetailsModalProps) => {
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] =
    useState<FileCourierSentPackageProps | null>(null);

  const dispatch = useDispatch();

  const [
    getSentFileCourierPackageDetails,
    { data: sentPackage, isFetching: isFetchingPackage },
  ] = useLazyGetSentFileCourierPackageDetailsQuery();

  const [extendFileCourierPackage, { isFetching: isExtendingPackage }] =
    useLazyExtendFileCourierPackageQuery();

  const isLoading = isFetchingPackage || isExtendingPackage;

  useEffect(() => {
    refreshDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sentPackage) {
      setSelectedPackage(sentPackage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentPackage]);

  const refreshDetailsData = () => {
    getSentFileCourierPackageDetails({ packageId })
      .unwrap()
      .catch(() => {
        dispatch(
          setNotification({
            type: "error",
            message: `Could not get file details.`,
          })
        );
      });
  };

  const handleClose = () => {
    onClose();
    onRefresh();
  };

  const handleOpenDelete = () => {
    setDeleteOpen(true);
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);
    handleClose();
  };

  const handleExtendExpiration = (value: Date): void => {
    if (selectedPackage) {
      extendFileCourierPackage({
        packageId: selectedPackage.id,
        newDateTime: value.toISOString(),
      })
        .unwrap()
        .then(() => {
          setSelectedPackage((oldPackage: any) => {
            return { ...oldPackage, expirationDate: value };
          });
        })
        .catch(() => {
          dispatch(
            setNotification({
              type: "error",
              message: `Could not extend file.`,
            })
          );
        });
    }
  };

  const handleDownloadPackage = async (packageId: string) => {
    await downloadPackage({ packageId });
  };

  const handleDownloadFile = async (fileId: string) => {
    await downloadFile({ fileId });
  };

  return (
    <>
      {sentPackage && selectedPackage ? (
        <Modal testId="fc-sent-details-modal" open={true} onClose={handleClose}>
          <>
            <FlexBox gap="1.25rem" minWidth="100%">
              <Title size="large" bold>
                Files sent {formatDate(sentPackage.sentDate)}
              </Title>

              <Title size="medium">
                Expires {formatDate(selectedPackage.expirationDate)}
              </Title>
              <FlexBox row align="center" gap="1.5rem">
                <DatePickerToggle
                  item={selectedPackage}
                  selectedPackageId={selectedPackage?.id}
                  setSelectedPackage={setSelectedPackage}
                  onUpdate={handleExtendExpiration}
                />
                <IconButton
                  name="trash"
                  size="medium"
                  ariaLabel="delete package"
                  onClick={handleOpenDelete}
                  addTooltip
                  toolTipText="delete"
                  testId="delete-button"
                />
              </FlexBox>
              <FlexBox gap="2rem">
                <TwoUp className="baseline">
                  <FlexBox>
                    <Body size="large" bold>
                      Recipients
                    </Body>
                    <StyledHorizontalRule />
                    <Body size="large">
                      {sentPackage.recipients
                        .map(
                          ({ userFirstName, userLastName }) =>
                            `${userFirstName} ${userLastName}`
                        )
                        .join(", ")}
                    </Body>
                  </FlexBox>
                  <FlexBox>
                    <FlexBox row align="flex-end" justify="space-between">
                      <Body size="large" bold>
                        Files Sent
                      </Body>
                      <Button
                        type="submit"
                        use="primary"
                        data-testid="download-all-button"
                        ariaLabel="download package"
                        size="small"
                        onClick={() => handleDownloadPackage(sentPackage.id)}
                      >
                        Download All
                      </Button>
                    </FlexBox>
                    <StyledHorizontalRule />
                    {sentPackage.files.map((file) => (
                      <FlexBox
                        key={file.id}
                        row
                        pd="0 0 .5rem 0"
                        justify="space-between"
                      >
                        <Body breakFileName size="large" bold>
                          {file.name}
                        </Body>
                        <IconButton
                          key={file.id}
                          name="download"
                          size="large"
                          ariaLabel="download file"
                          onClick={() => handleDownloadFile(file.id)}
                          addTooltip
                          toolTipText="download"
                          testId="download-file-button"
                        />
                      </FlexBox>
                    ))}
                  </FlexBox>
                </TwoUp>
                <FlexBox>
                  <Body size="large" bold>
                    Downloads
                  </Body>
                  <StyledHorizontalRule />
                  {sentPackage.downloadHistory.length > 0 ? (
                    <>
                      {sentPackage.downloadHistory.map(
                        ({ fileNames, userEmail, date }, index) => (
                          <StyledTable
                            className="table-row"
                            key={`${fileNames}-${index}`}
                            row
                            gap=".5rem"
                            pd="0 0 .5rem 0"
                          >
                            <Body width="30%" size="large">
                              {userEmail}
                            </Body>
                            <Body width="60%" size="large">
                              {fileNames}
                            </Body>
                            <Body textAlign="right" width="10%" size="large">
                              {formatDate(date)}
                            </Body>
                          </StyledTable>
                        )
                      )}
                    </>
                  ) : (
                    <Body size="large">No files have been downloaded</Body>
                  )}
                </FlexBox>
              </FlexBox>
              <FlexBox row gap="1.5rem" align="center" justify="flex-start">
                <TextButton data-testid="cancel-button" onClick={handleClose}>
                  Cancel
                </TextButton>
              </FlexBox>
            </FlexBox>
            {deleteOpen && (
              <DeleteFileCourierPackageModal
                packageId={sentPackage.id}
                onClose={handleCloseDelete}
                onRefresh={onRefresh}
              />
            )}
          </>
        </Modal>
      ) : // eslint-disable-next-line react/jsx-no-undef
      isLoading ? (
        <Loader active={(!sentPackage || !selectedPackage) && isLoading} />
      ) : (
        <></>
      )}
    </>
  );
};

export default FileCourierSentDetailsModal;
