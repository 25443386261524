import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CoreNotification from './Notification.js';

const propTypes = {
  /**
   * @ignore - Prop that passes new Notification component
   */
  renderNotification: PropTypes.func
};
class NotificationItem extends Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = (nextProps, nextState) => this.props !== nextProps;
    this.state = {};
  }
  render() {
    const {
      index,
      renderNotification
    } = this.props;
    const NotificationComponent = renderNotification ? renderNotification : CoreNotification;
    return React.createElement(NotificationComponent, Object.assign({}, this.props, {
      disableAnimation: index !== 0
    }));
  }
}
NotificationItem.propTypes = propTypes;

export { NotificationItem as default };
