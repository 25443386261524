import { ButtonGroupProps } from "interfaces";
import { StyledGroup } from "./ButtonGroup.module";

const ButtonGroup = ({
  pd,
  align,
  justify,
  children,
  fullWidth,
  bp,
}: ButtonGroupProps) => {
  return (
    <StyledGroup
      pd={pd}
      align={align}
      justify={justify}
      fullWidth={fullWidth}
      className={bp ? "breakpoint" : ""}
      bp={bp}
    >
      {children}
    </StyledGroup>
  );
};

export default ButtonGroup;
