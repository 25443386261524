import React from 'react';
import PropTypes from 'prop-types';
import { Body, Title, Micro, Feature } from '@vds/typography';
import { TrailingTooltip as TrailingTooltip$1 } from '@vds-core/tooltips';
import { ColorTokens } from '@vds-tokens/color';

const _calculateTrackColor = function (surface) {
  let trackColor;
  if (surface === 'dark') {
    trackColor = ColorTokens.interactive.scrolltrack.ondark.value;
  } else {
    trackColor = ColorTokens.interactive.scrolltrack.onlight.value;
  }
  return trackColor;
};
const _calculateThumbColor = function (surface, hover) {
  let thumbColor;
  if (surface === 'dark' && !hover) {
    thumbColor = ColorTokens.interactive.scrollthumb.ondark.value;
  } else if (hover && surface !== 'dark') {
    thumbColor = ColorTokens.interactive.scrollthumb.hover.onlight.value;
  } else if (hover && surface === 'dark') {
    thumbColor = ColorTokens.interactive.scrollthumb.hover.ondark.value;
  } else {
    thumbColor = ColorTokens.interactive.scrollthumb.onlight.value;
  }
  return thumbColor;
};
const propTypes = {
  /**
   * Option to render icon element in brand colors
   * @note Providing a custom HEX value is also possible. brandHightlight is recommended to use on light surface only
   */
  iconFillColor: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'brandHighlight']), PropTypes.string]),
  /**
   * Render the component in surface background dark or light
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * If provided, will render the custom text for Close button for Tooltip when on mobile/touch devices
   */
  tooltipCloseButtonText: PropTypes.string
};
const defaultProps = {
  iconFillColor: 'primary',
  surface: 'light'
};
const TrailingTooltip = function (props) {
  return React.createElement(TrailingTooltip$1, Object.assign({}, props, {
    renderTooltipDialog: true,
    Body: Body,
    Title: Title,
    Micro: Micro,
    Feature: Feature,
    calculateTrackColor: _calculateTrackColor,
    calculateThumbColor: _calculateThumbColor,
    scrollbarBorderRadius: "2"
  }));
};
TrailingTooltip.propTypes = propTypes;
TrailingTooltip.defaultProps = defaultProps;

export { TrailingTooltip as default };
