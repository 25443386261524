import { BodyProps as VdsBodyProps } from "@vds/typography";
import { StyledWrapper } from "./Body.module";
import { Body as VdsBody } from "@vds/typography";

export interface BodyProps {
  pd?: string;
  size?: string;
  width?: string;
  maxWidth?: string;
  color?: string;
  className?: string;
  textAlign?: "left" | "center" | "right";
  onFocus?: Function;
  onBlur?: Function;
  breakFileName?: boolean;
  inline?: boolean;
  testId?: string;
}

const Body = ({
  pd,
  width,
  maxWidth,
  size,
  children,
  className,
  textAlign,
  breakFileName,
  inline,
  testId,
  ...rest
}: VdsBodyProps & BodyProps) => {
  return (
    <StyledWrapper
      className={className}
      pd={pd}
      width={width}
      maxWidth={maxWidth}
      size={size}
      textAlign={textAlign}
      breakFileName={breakFileName}
      inline={inline}
    >
      <VdsBody data-testid={testId} {...rest}>{children}</VdsBody>
    </StyledWrapper>
  );
};

export default Body;
