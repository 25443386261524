import styled from "styled-components";
import { DoDontBlockProps } from "./DoDontBlock";
import { minTablet } from "theme/variables";


export const StyledContainer = styled("div")<DoDontBlockProps>`

  max-width: ${(props) => props.maxWidth || ""};
  margin: ${(props) => props.mg || ""};
  padding: ${(props) => props.pd || ""};
  
  display: flex;
  flex-direction: row;
  background: none;
  border-radius: unset;
  -webkit-box-pack: justify;
  justify-content: space-between;
  overflow-y: unset;


  @media only screen and (max-width: ${minTablet - 1}px) {
    flex-direction: column;
    padding: ${(props) => props.mobilePd || ""};
    margin: ${(props) => props.mobileMg || ""};
  }
  
`;