import styled from "styled-components";
import { Section, FlexBox } from "components";

export const StyledMobileNav = styled(Section)`
  display: block;
  padding: 0.5rem 0;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const StyledLogoContainer = styled(FlexBox)`
  height: 3.5rem;
  padding-inline: 1rem;
  justify-content: center;
  .home-icon {
    width: fit-content;
    display: flex;
    justify-content: center;
  }
`;

