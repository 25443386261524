import FlexBox from "components/layouts/FlexBox/FlexBox";
import Title from "components/typography/Title/Title";
import Body from "components/typography/Body/Body";
import { StyledExternalLink, StyledLink, StyledStep } from "./StepTile.module";
import CaretText from "components/CaretText/CaretText";
import { externalRoute } from "utils";

export interface StepTileInterface {
  title: string;
  body: string | null;
  to: string;
  external?: boolean;
  linkText?: string;
  cardNumber: number;
}

const StepTile = ({
  title,
  body,
  to,
  external,
  linkText,
  cardNumber,
}: StepTileInterface) => {
  const CardDetails = () => {
    return (
      <>
        <div>
          <Body bold pd="0 0 0.5rem" size="large">
            0{cardNumber}
          </Body>
          <FlexBox>
            <Title size="large" pd="0 0 0.75rem" bold>
              {title}
            </Title>
            <Body color="#ffffff" className="step-description" size="large">
              {body}
            </Body>
          </FlexBox>
        </div>
        {linkText && (
          <CaretText
            size="large"
            color="unset"
            caretPosition="right"
            fontSize="1"
            pd="2rem 0 0 0"
          >
            {linkText}
          </CaretText>
        )}
      </>
    );
  };
  return external && to ? (
    <StyledExternalLink href={externalRoute(to)}>
      <CardDetails />
    </StyledExternalLink>
  ) : to ? (
    <StyledLink to={to}>
      <CardDetails />
    </StyledLink>
  ) : (
    <StyledStep>
      <CardDetails />
    </StyledStep>
  );
};

export default StepTile;
