import { AssetArray } from "interfaces";

type QueryParamKeyValuePair = { key: string; value: string | number | unknown };

function formatParams(ob: Record<string, unknown>): QueryParamKeyValuePair[] {
  const params: QueryParamKeyValuePair[] = [];

  for (const key in ob) {
    if (!ob.hasOwnProperty(key)) {
      continue;
    }
    const value = ob[key];
    if (value instanceof Array) {
      for (const part of value) {
        params.push({
          key: key,
          value: part,
        });
      }
    } else if (value instanceof Object) {
      for (const subKey in value) {
        if (!value.hasOwnProperty(subKey)) {
          continue;
        }
        const newKey = `${key}[${subKey}]`;
        params.push({
          key: newKey,
          value: (value as Record<string, unknown>)[subKey],
        });
      }
    } else {
      params.push({
        key: key,
        value: value,
      });
    }
  }

  return params;
}

export function buildQueryString(params?: Record<string, unknown>): string {
  if (!params) {
    return "";
  }
  const formattedParams = formatParams(params);
  const allParams: string[] = [];
  for (const pair of formattedParams) {
    if (
      !pair.hasOwnProperty("value") ||
      pair.value === undefined ||
      pair.value === null
    ) {
      continue;
    }
    allParams.push(`${pair.key}=${pair.value}`);
  }
  if (allParams.length === 0) {
    return "";
  }
  return `?${allParams.join("&")}`;
}

export function getTotalPages(results: number, perPage: number): number {
  const totalPages = Math.ceil(results / perPage);
  return totalPages;
}

export function setPreviousPage(pageUrl: string): void {
  localStorage.setItem("previousPage", pageUrl);
}

export function getPreviousPage(): string | null {
  const previousPage = localStorage.getItem("previousPage");
  return previousPage;
}

export function clearPreviousPageUrl(): void {
  window.localStorage.removeItem("previousPage");
}

export const supportsStorage = (
  storageType: "localStorage" | "sessionStorage"
): boolean => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const storage = window[storageType] as Storage;
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};
