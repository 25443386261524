import styled from "styled-components";
import { minTablet } from "../../theme/variables";
import { zIndex } from "theme/variables";

export const StyledFeedbackContainer = styled("div")`
  &.feedback-scroll-container {
    background: #ffffff;
    position: fixed;
    bottom: -20rem;
    right: 1rem;
    padding: 1rem 2rem;
    z-index: ${zIndex.modal};
    max-width: calc(100% - 2rem);
    border: 1px solid #000000;
    box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    pointer-events: none;
    border-radius: 0.5rem;

    &.active {
      bottom: 1rem;
      pointer-events: auto;
    }

    .close-button {
      background: transparent;
      border: none;
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
    .feedback-button {
      &.thumbs-up {
        margin-left: 1rem;
      }

      @media screen and (max-width: ${minTablet - 1}px) {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    .visuallyhidden {
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
      overflow: hidden;
      position: absolute;

      &.focusable:active,
      &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
      }
    }

    .initial-state {
      display: flex;
      align-items: center;
      flex-direction: column;
      @media screen and (min-width: ${minTablet}px) {
        flex-direction: row;
        padding: 0.5rem 2rem;
      }
    }

    @media screen and (max-width: ${minTablet - 1}px) {
      width: 100%;
    }
  }
`;
