import { createContext, useMemo, useState } from "react";

interface authContext {
  email: string | null;
  setEmail: Function;
  phone: string | null;
  setPhone: Function;
  password: string | null;
  setPassword: Function;
}

export const AuthContext = createContext<authContext>({
  email: null,
  setEmail: () => {},
  phone: null,
  setPhone: () => {},
  password: null,
  setPassword: () => {},
});

export function AuthProvider({ children }: any) {
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);

  return (
    <AuthContext.Provider
      value={useMemo(
        () => ({ email, setEmail, phone, setPhone, password, setPassword }),
        [email, setEmail, phone, setPhone, password, setPassword]
      )}
    >
      {children}
    </AuthContext.Provider>
  );
}
