import { __rest } from '../_virtual/_tslib.js';
import React from 'react';
import PropTypes from 'prop-types';
import { calculateRem } from '@vds/utilities';
import { DropdownSelect as DropdownSelect$1 } from '@vds-core/selects';
import { Body, Micro } from '@vds/typography';
import { Tooltip } from '@vds/tooltips';
import { ColorTokens } from '@vds-tokens/color';

function _calculateOnlightBorders(readOnly, error, disabled, keyboardFocused) {
  let borderColor;
  if (disabled || readOnly) {
    borderColor = ColorTokens.interactive.disabled.onlight.value;
  } else if (error && !keyboardFocused) {
    borderColor = ColorTokens.feedback.error.onlight.value;
  } else if (!error && keyboardFocused) {
    borderColor = ColorTokens.elements.primary.onlight.value;
  } else if (error && keyboardFocused) {
    borderColor = ColorTokens.elements.primary.onlight.value;
  } else {
    borderColor = ColorTokens.elements.secondary.onlight.value;
  }
  return borderColor;
}
function _calculateOnDarkBorders(readOnly, error, disabled, keyboardFocused) {
  let borderColor;
  if (disabled || readOnly) {
    borderColor = ColorTokens.interactive.disabled.ondark.value;
  } else if (error && !keyboardFocused) {
    borderColor = ColorTokens.feedback.error.ondark.value;
  } else if (!error && keyboardFocused) {
    borderColor = ColorTokens.elements.primary.ondark.value;
  } else if (error && keyboardFocused) {
    borderColor = ColorTokens.elements.primary.ondark.value;
  } else {
    borderColor = ColorTokens.elements.secondary.ondark.value;
  }
  return borderColor;
}
function _calculateBorderColor(surface, readOnly, error, disabled, keyboardFocused) {
  switch (surface) {
    case 'dark':
      return _calculateOnDarkBorders(readOnly, error, disabled, keyboardFocused);
    case 'light':
    default:
      return _calculateOnlightBorders(readOnly, error, disabled, keyboardFocused);
  }
}
function _calculateElementColor(disabled, surface) {
  let elementColor;
  if (surface === 'dark' && disabled) {
    elementColor = ColorTokens.interactive.disabled.ondark.value;
  } else if (surface !== 'dark' && disabled) {
    elementColor = ColorTokens.interactive.disabled.onlight.value;
  } else if (surface === 'dark' && !disabled) {
    elementColor = ColorTokens.elements.primary.ondark.value;
  } else {
    elementColor = ColorTokens.elements.primary.onlight.value;
  }
  return elementColor;
}
function _calculateBackgroundColor(error, surface) {
  let backgroundColor;
  if (surface === 'dark' && !error) {
    backgroundColor = ColorTokens.background.primary.dark.value;
  } else if (error && surface !== 'dark') {
    backgroundColor = ColorTokens.feedback.error.background.onlight.value;
  } else if (error && surface === 'dark') {
    backgroundColor = ColorTokens.feedback.error.background.ondark.value;
  } else {
    backgroundColor = ColorTokens.background.primary.light.value;
  }
  return backgroundColor;
}
const propTypes = {
  required: PropTypes.bool,
  /**
   * If true, the label will be displayed inside the dropdown container. Otherwise, the label will be above the dropdown container like a normal text input.
   */
  inlineLabel: PropTypes.bool,
  /**
   * @deprecated String, array or node containing the title of the Tooltip for Input label
   */
  tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  /**
   * @deprecated String, array or node containing the body of the Tooltip for Input label
   */
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  /**
   * @deprecated If provided, will render the custom text for Close button for Tooltip when on mobile/touch devices
   */
  tooltipCloseButtonText: PropTypes.string,
  /**
   * Config object for tooltip option
   */
  tooltip: PropTypes.shape({
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    closeButtonText: PropTypes.string,
    'data-track': PropTypes.string,
    'data-track-ignore': PropTypes.string,
    'data-analyticstrack': PropTypes.string,
    'data-clickstream': PropTypes.string
  }),
  /**
   * If provided, will contain the string for the helper text
   */
  helperText: PropTypes.string,
  /**
   * Determines the placement of the helper text (right or bottom)
   */
  helperTextPlacement: PropTypes.oneOf(['right', 'bottom']),
  /**
   * If provided, will render with transparent background.
   */
  transparentBackground: PropTypes.bool,
  /**
   * Render the component in surface background dark or light
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  required: true,
  helperTextPlacement: 'bottom',
  inlineLabel: false,
  transparentBackground: false,
  surface: 'light',
  tooltipCloseButtonText: undefined
};
const DropdownSelect = function (props) {
  const {
      required,
      inlineLabel,
      helperText,
      helperTextPlacement,
      tooltipTitle,
      tooltipContent,
      tooltipCloseButtonText
    } = props,
    rest = __rest(props, ["required", "inlineLabel", "helperText", "helperTextPlacement", "tooltipTitle", "tooltipContent", "tooltipCloseButtonText"]);
  return React.createElement(DropdownSelect$1, Object.assign({}, rest, {
    inlineLabel: inlineLabel,
    readOnlyBorders: true,
    hoverState: true,
    focusState: true,
    inputFontSize: true,
    overflowEllipsis: true,
    minWidth: inlineLabel ? '102px' : '66px',
    height: calculateRem(44),
    selectPadding: calculateRem(12, 44, 12, 12),
    required: required,
    helperText: helperText,
    helperTextPlacement: helperTextPlacement,
    Tooltip: Tooltip,
    tooltipTitle: tooltipTitle,
    tooltipContent: tooltipContent,
    tooltipCloseButtonText: tooltipCloseButtonText,
    Body: Body,
    Micro: Micro,
    focusRing: true,
    heavyErrorBorder: false,
    calculateElementColor: _calculateElementColor,
    calculateBackgroundColor: _calculateBackgroundColor,
    calculateBorderColor: _calculateBorderColor,
    borderRadius: "4px",
    errorLabelSpacing: calculateRem(8)
  }));
};
DropdownSelect.propTypes = propTypes;
DropdownSelect.defaultProps = defaultProps;

export { DropdownSelect as default };
