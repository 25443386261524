import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import { withAction } from "HOCs/withAction";
import { setNotification } from "services";
import mapPropsToValues, { FormValues } from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import Feedback from "./Feedback";

export const FeedbackContainer = compose(
  withFormik<any, FormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit: () => null,
    enableReinitialize: true,
    validateOnMount: true,
  })
)(Feedback);

export default FeedbackContainer;
