import styled from "styled-components";
import { FlexBoxProps } from "./FlexBox";

export const StyledFlexBox = styled(
  ({ row, wrap, minWidth, maxWidth, ...props }) => <div {...props} />
)<FlexBoxProps>`
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  background: ${(props) => (props.bg ? props.bg : "none")};
  border-radius: ${(props) =>
    props.bg && props.radius ? props.radius : "unset"};

  &.breakpoint {
    @media only screen and (max-width: ${(props) => props.bp}) {
      flex-direction: ${(props) => (props.row ? "column" : "row")};
    }
  }

  @media only screen and (max-width: ${(props) => props.bp}) {
    order: ${(props) => props.mobileOrder || "unset"};
  }

  flex: ${(props) => props.flex || ""};
  align-items: ${(props) => props.align || ""};
  justify-content: ${(props) => props.justify || ""};

  height: ${(props) => props.height || ""};
  min-width: ${(props) => props.minWidth || ""};
  max-width: ${(props) => props.maxWidth || ""};

  overflow-y: ${(props) => (props.scroll ? "scroll" : "unset")};

  gap: ${(props) => props.gap || ""};

  margin: ${(props) => props.mg || ""};
  padding: ${(props) => props.pd || ""};

  flex-wrap: ${(props) => (props.wrap ? "wrap" : "no-wrap")};
`;
