import axios from "axios";
import makeUrlFromPath from "./makeUrlFromPath";
import { routeList } from "routing/routeList";
import { Auth } from "aws-amplify";
import { handleError } from "utils";

const getToken = async () => {
  return await Auth.currentSession()
    .then((response) => {
      return response.getIdToken().getJwtToken();
    })
    .catch((error: any) => handleError(error));
};
export async function request(
  method: string,
  path: string,
  { data, params }: any = {}
) {
  const url = makeUrlFromPath({ path }),
    unauthenticatedPaths = routeList.unprotected.map((route) => route.path);

  let headers = params;
  if (!unauthenticatedPaths.includes(path)) {
    const accessToken = await getToken();
    // Add a request interceptor
    headers = { ...params, Authorization: "Bearer " + accessToken };
  }

  return axios({
    method,
    url,
    data,
    headers,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error: any) => handleError(error));
}

export default {
  get: request.bind(this, "get"),
  post: request.bind(this, "post"),
  put: request.bind(this, "put"),
  patch: request.bind(this, "patch"),
  delete: request.bind(this, "delete"),
};
