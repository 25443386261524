import { useState } from "react";
import { ActivateModalProps } from "interfaces";
import { TextButton, Button, FlexBox, Modal, Title, Body } from "components";
import { useActivateAssetMutation } from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";

const ActivateModal = ({
  asset,
  onClose,
  buttonText,
  title,
  itemName,
  onComplete,
}: ActivateModalProps) => {
  const [activateAsset, { isLoading }] = useActivateAssetMutation();

  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState<boolean>(
      asset.isActive ? asset.isActive : false
    ),
    callActivateAsset = () => {
      activateAsset({
        assetId: asset.id,
        isActive: !isActive,
        payload: JSON.stringify(""),
      })
        .unwrap()
        .then(() => {
          dispatch(
            setNotification({
              type: "success",
              message: `Asset ${isActive ? "deactivated" : "activated"}.`,
            })
          );
          setIsActive(!isActive);
          onComplete(isActive);
        })
        .catch((error: any) => {
          dispatch(
            setNotification({
              type: "error",
              message: `Could not ${
                isActive ? "deactivate" : "activate"
              } asset.`,
            })
          );
          console.error(error);
        });
    };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal testId="activate-modal" open onClose={handleClose} disabled={isLoading}>
      <FlexBox>
        <Title size="large" bold pd="0 0 2rem">
          {title}
        </Title>
        <Body size="large" pd="0 0 2rem">
          {`Are you sure you want to ${!isActive ? "activate" : "deactivate"} `}
          <span style={{ fontWeight: "700" }}>{itemName}?</span>
        </Body>
        <FlexBox row gap="1.5rem" align="center" justify="flex-start">
          <Button
            use="primary"
            data-testid="submit-button"
            onClick={callActivateAsset}
            disabled={isLoading}
          >
            {buttonText}
          </Button>
          <TextButton data-testid="cancel-button" onClick={handleClose} disabled={isLoading}>
            Cancel
          </TextButton>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default ActivateModal;
