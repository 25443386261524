import { externalRoute } from "utils";
import {
  StyledContainer,
  StyledExternalLink,
  StyledLink,
} from "./TileContainer.module";

export interface TileContainerProps {
  children: any;
}

export interface StyledContainerProps {
  width?: string;
  color?: string;
  bg?: string;
  to?: string;
  external?: boolean;
  newTab?: boolean;
  state?: any;
  pd?: string;
  maxWidth?: string;
  minHeight?: string;
  className?: string;
  textAlign?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  noAnimation?: boolean;
  justify?: string;
  radius?: string;
  border?: boolean;
}
const TileContainer = ({
  to,
  external,
  newTab,
  state,
  children,
  onMouseEnter,
  onMouseLeave,
  onClick,
  noAnimation,
  justify,
  radius,
  border,
  ...rest
}: TileContainerProps & StyledContainerProps) => {
  return to ? (
    external ? (
      <StyledExternalLink
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        radius={radius}
        border={border}
        href={externalRoute(to)}
        rel="external noopener noreferrer"
        target={newTab ? "_blank" : ""}
        state={state}
        noAnimation={noAnimation}
        {...rest}
      >
        {children}
      </StyledExternalLink>
    ) : (
      <StyledLink
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        to={to}
        border={border}
        radius={radius}
        state={state}
        noAnimation={noAnimation}
        {...rest}
      >
        {children}
      </StyledLink>
    )
  ) : (
    <StyledContainer
      justify={justify}
      onClick={onClick}
      border={border}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      radius={radius}
      {...rest}
    >
      {children}
    </StyledContainer>
  );
};

export default TileContainer;
