import styled from "styled-components";

export const StyledContainer = styled.button`
  border: none;
  cursor: pointer;
  background: none;

  .selected {
    position: relative;
  }

  .selected::after {
    content: "";
    width: 100%;
    height: 0.25rem;
    position: relative;
    left: 0;
    bottom: -0.25rem;
    background-color: red;
  }
`;