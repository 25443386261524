import { TrackingRequest } from "../interfaces";

const trackingVersion = {
  versionId: "1.0.0",
};

export function buildTrackingPayload<T>(
  trackingData: T,
  location: string
): Record<string, unknown> {
  // useLocation to get location in page file
  // import {useLocation} from "react-router-dom"
  // const location = useLocation();

  return {
    info: {
      version: trackingVersion.versionId,
      route: location,
    },
    data: {
      ...trackingData,
    },
  };
}

export function buildFinalTrackingObject(
  payloadData: any,
  location: string,
  correlationId?: string
): TrackingRequest {
  const payload = buildTrackingPayload(payloadData, location);
  const trackingObject = {
    correlationId,
    payload: JSON.stringify({ ...payload }),
  };
  return trackingObject;
}
