var sharedDefaultProps = {
  baseUrl: null,
  total: 20,
  selectPage: null,
  selectedPage: 10,
  surface: 'light',
  focusBorderRadius: '2px'
};

export { sharedDefaultProps as default };
