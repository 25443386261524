import styled from "styled-components";
import {
  minMobileLarge,
  mobile,
  mobileLarge,
  tablet,
  tabletLarge,
  desktop, minDesktop, minTablet,
} from "../../theme/variables";

interface ImageWrapperProps {
  searchType?: string;
}
export const StyledThumbnailGridWrapper = styled("div")`
  display: block;
  box-sizing: border-box;
  width: 100%;
  max-width: 70vw;

  @media screen and (max-width: ${minMobileLarge - 1}px) {
    display: none;
  }

  ul {
    display: grid;
    overflow-y: auto;
    padding: 0 1rem 0 0;
    max-height: 22.75rem;
    position: relative;
    list-style: none;
    width: 100%;
    margin: 1.25rem auto;
    box-sizing: border-box;
    grid-gap: 1.25rem;

    @media ${mobileLarge} {
      grid-template: auto / repeat(2, 1fr);
    }

    @media ${tablet} {
      grid-template: auto / repeat(3, 1fr);
    }

    @media ${tabletLarge} {
      grid-template: auto / repeat(4, 1fr);
    }

    @media ${desktop} {
      grid-template: auto / repeat(4, 1fr);
    }
  }

  .list-asset {
    position: relative;
    box-sizing: border-box;
    height: 100%;
  }
  .list-asset-remove {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .asset-name-container {
    word-break: break-word;
    h5 {
      text-align: left;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    a {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }
    &:not(.focused) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export const ImageWrapper = styled("div")<ImageWrapperProps>`
  //background-color: #f6f6f6;
  background-color: ${(props) => (props.searchType === "guideline" ? "#000000" : props.searchType === "compliance" ? "#d8dada" : "#f6f6f6")};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12rem;
  flex: 1;
  /* padding: 8%; */
  border-radius: 6px;
  > img {
    max-height: calc(100%);
    margin: auto;
    /* border-radius: 4px; */
  }
  .media__link {
    text-decoration: none;
    color: #000000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    .compliance__title {
      padding: 0.5rem;
      h5 {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }

    .guideline__title {
      padding: 0.5rem;
      h5 {
        color: #ffffff;
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }

    span {
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
      overflow: hidden;
      position: absolute;

      &.focusable:active,
      &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
      }
    }
  }
`;
