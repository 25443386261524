import {Body, Button, FlexBox, IconButton, Image, MdToHtml, TextLink, Title} from "../../components";
import {AssetItem, AssetItemContainer, AssetList, ImageWrapper, StyledFlexBox} from "../multipleSelectedModal/MultipleSelectedModal.module";
import {getThumbnails} from "../../utils";
import {AssetFile, UnifiedSearchResultProps} from "../../interfaces";
import {setNotification, useAcceptUsageRightsMutation, useLazyGetAssetDownloadUrlQuery} from "../../services";
import {openFileDownload} from "../../utils/assetFiles";
import {useEffect, useState} from "react";
import {useDispatch} from "../../hooks/redux";
import FavoriteModal from "../favoriteModal/FavoriteModal";
import {ShareModal} from "../index";
import {Link} from "react-router-dom";
import paths from "../../routing/paths";
import TextButton from "../../components/buttons/TextButton/TextButton";
import {downloadFile} from "../../utils/fileCourier";

export interface MultipleSelectedTabProps {
  selectedItems: Array<UnifiedSearchResultProps>;
  removeAsset: Function;
  updateUsageRights: Function;
  searchType: string;
  setIsLoading: Function;
  clearBySearchType: Function;
}
const MultipleSelectedTab = ({
  selectedItems,
  removeAsset,
  updateUsageRights,
  searchType,
  setIsLoading,
  clearBySearchType,
}: MultipleSelectedTabProps) => {
  
  const dispatch = useDispatch();
  const handleRemoveAsset = (id: string) => {
    removeAsset(id);
    // selectedItems.length <= 1 && onClose();
  };
  
  const handleClearBySearchType = () => {
    clearBySearchType(searchType);
  }
  
  const [getAssetUrl, { isFetching: assetFetching, data: downloadUrl }] =
    useLazyGetAssetDownloadUrlQuery();
  
  const [filesForDownload, _filesForDownload] = useState<string[]>([]);
  
  const handleDownload = async () => {
    let downloadUrls: string[] = [];
    selectedItems.forEach((selectedItem) => {
      if (selectedItem.assetId) {
        getAssetUrl(selectedItem.assetId)
        .unwrap()
        .then((response: any) => {
          downloadUrls.push(response);
          if (downloadUrls.length === selectedItems.length) {
            _filesForDownload(downloadUrls.map((url) => url));
          }
        })
        .catch((error: any) => {
          console.error("Error downloading asset: ", error, " Asset Id: ", selectedItem.assetId);
        });
      }
    });
  };
  
  const handleDownloadFile = async (url: string, fileIndex: number, time?: number) => {
    if (fileIndex === 0) {
      await openFileDownload(url);
    } else {
      setTimeout(() => {
        openFileDownload(url);
      }, time);
    }
    
  }
  
  const downloadAllFiles = async () => {
    if (filesForDownload.length > 0 && filesForDownload.length === selectedItems.length) {
      let time = 0;
      for (const url of filesForDownload) {
        const fileIndex = filesForDownload.findIndex((file) => file === url);
        if (fileIndex === 0) {
          // if it is the first file, open the download link right away without a delay
          await handleDownloadFile(url, fileIndex);
        } else {
          // stagger opening the download link so it can open all of them without race conditions
          time = time + 500;
          await handleDownloadFile(url, fileIndex, time);
        }
        
      }
    }
  };
  
  useEffect(() => {
    if (filesForDownload.length > 0 && filesForDownload.length === selectedItems.length) {
      // once all the file urls have been fetched for each of the selected items, start the process of opening the link in a new tab to actually download it
      downloadAllFiles()
    }
  }, [filesForDownload]);
  
  
  
  const [favoritesOpen, _setFavoritesOpen] = useState(false),
    handleFavorites = () => {
      _setFavoritesOpen(true);
    },
    [shareOpen, _setShareOpen] = useState(false),
    handleShare = () => {
      _setShareOpen(true);
    };
  
  const [acceptRights, { isLoading }] = useAcceptUsageRightsMutation();
  const callUsageRights = (id: string) => {
      acceptRights(id)
      .unwrap()
      .then(() => {
        updateUsageRights(id);
        
        dispatch(
          setNotification({
            type: "success",
            message: "Usage rights accepted.",
          })
        );
      })
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Rights not accepted",
          })
        );
        console.error("error accepting usage rights: ", error);
      });
    };
  
  useEffect(() => {
    if (isLoading || assetFetching) {
      setIsLoading(true);
    } else if (!isLoading && !assetFetching) {
      setIsLoading(false);
    }
  }, [isLoading, assetFetching]);
  
  const getLinkUrl = (item: UnifiedSearchResultProps): string => {
    let itemUrl = item.url;
    if (item.searchType === 'template') {
      itemUrl = `/templates/details/${item.assetId}`;
    } else if (item.searchType === 'retail') {
      itemUrl = `/retail/branded-environments/design-advisories/details/${item.assetId}`;
    } else if (item.searchType === 'asset') {
      itemUrl = `/assets/details/${item.assetId}`;
    } else if (item.searchType === 'training') {
      itemUrl = `${paths.TRAINING_VIDEOS}/${item.playlistSlug}/${item.videoSlug}`;
    }
    return itemUrl;
  };
  
  const getSearchType = (): string => {
    let tabSearchType = "Asset";
    if (searchType === "guideline") {
      tabSearchType = "Guideline";
    } else if (searchType === "compliance") {
      tabSearchType = "Compliance";
    } else if (searchType === "training") {
      tabSearchType = "Training";
    } else if (searchType === "template") {
      tabSearchType = "Template";
    } else if (searchType === "retail") {
      tabSearchType = "Retail";
    }
    return tabSearchType;
  };
  
  return (
    <>
      <StyledFlexBox data-testid="multiple-selected-tab">
        <FlexBox row align="center" justify="space-between" pd="0 0 2rem">
          <Title size="large" bold pd="1rem 0">
            {`${getSearchType()} selection${selectedItems.length > 1 ? "s" : ""}`}
          </Title>
          {selectedItems.length > 1 && (
            <Button data-testid="clear-by-search-type" size="small" use="secondary" onClick={handleClearBySearchType}>
              Remove all {getSearchType()}{(searchType === "template" || searchType === "asset" || searchType === "guideline") ? "s" : ""}
            </Button>
          )}
        </FlexBox>
        <AssetList>
          {selectedItems.map((selectedItem: UnifiedSearchResultProps) => (
            <AssetItemContainer>
            <AssetItem key={selectedItem.id}>
              {selectedItem.files && selectedItem.files.length > 0 && (
                <ImageWrapper>
                  <Link
                    to={getLinkUrl(selectedItem)}
                    // onClick={onAssetLinkClick}
                    className="media__link"
                    title={`Open new tab to view image: ${selectedItem.title}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="asset-details-link"
                  >
                    <Image src={getThumbnails(selectedItem.files, "medium")[0]} />
                  </Link>
                </ImageWrapper>
              )}
              <FlexBox pd="1rem 0">
                <Body size="large" bold>{selectedItem.category}</Body>
                <TextLink
                  inline
                  newTab
                  key={selectedItem.id}
                  size="large"
                  to={getLinkUrl(selectedItem)}
                >
                  {selectedItem.title}
                </TextLink>
                {selectedItem.files && selectedItem.files.length > 0 && (
                  <Body pd="0.5rem 0 0">{`${selectedItem.files.length} file${
                    selectedItem.files.length !== 1 ? "s" : ""
                  }`}</Body>
                )}
                {((searchType === "asset" || searchType === "template" || searchType === "retail") && selectedItem.userActions && !selectedItem.userActions.hasAcceptedUsageRights) && (
                  <FlexBox data-testid="accept-rights">
                    <FlexBox pd="0 0 1rem">
                      <Body pd="0.25rem 0" color="#0077b4">
                        You must accept the usage rights before downloading
                        this asset.
                      </Body>
                      <Body maxWidth="80ch">
                        Permission to download an image has been given on the
                        condition that the image is only used in accordance
                        with the usage rights listed, including any term
                        period limitations. Any other use, which has not been
                        explicitly authorized, is prohibited. Any breach of
                        the usage rights may result in copyright infringement
                        or other liability for Verizon, for which you will
                        bear full legal responsibility.
                      </Body>
                      {/* <StyledTooltip size="small" data-tooltip="test">
                                    Permission to download an image has been given on the
                                    condition that the image is only used in accordance
                                    with the usage rights listed, including any term
                                    period limitations. Any other use, which has not been
                                    explicitly authorized, is prohibited. Any breach of
                                    the usage rights may result in copyright infringement
                                    or other liability for Verizon, for which you will
                                    bear full legal responsibility.
                                  </StyledTooltip> */}
                    </FlexBox>
                    {selectedItem.usageRightsCopy && (
                      <MdToHtml
                        pSize="large"
                        pMargin="0 0 0.5rem 0"
                        content={`**Usage rights:** ${selectedItem.usageRightsCopy}`}
                      />
                    )}
                    <Button
                      use="primary"
                      onClick={() => callUsageRights(selectedItem.assetId ? selectedItem.assetId : "")}
                      disabled={assetFetching || isLoading}
                      data-testid="update-rights-button"
                    >
                      Accept
                    </Button>
                  </FlexBox>
                )}
              </FlexBox>
            </AssetItem>
              <TextButton
                onClick={() => handleRemoveAsset(selectedItem.id)}
                disabled={assetFetching}
                testId="remove-asset"
              >Remove
              </TextButton>
            </AssetItemContainer>
          ))}
        </AssetList>
        <FlexBox row pd="2rem 0 0" align="center" gap="1rem">
          {(searchType === "asset" || searchType === "template" || searchType === "retail") && (
            <Button
              use="primary"
              onClick={handleDownload}
              disabled={assetFetching || isLoading || selectedItems.some((item) => item.userActions && !item.userActions.hasAcceptedUsageRights && item.usageRightsCopy)}
              data-testid="download-button"
            >
              Download
            </Button>
          )}
          
          <IconButton
            size="large"
            name="add-to-favorite"
            onClick={handleFavorites}
            noHover
            disabled={assetFetching || isLoading}
            testId="open-favorites"
          />
          <IconButton
            size="large"
            name="share"
            onClick={handleShare}
            noHover
            disabled={assetFetching || isLoading}
            testId="open-share"
          />
        </FlexBox>
      </StyledFlexBox>
      {favoritesOpen && (
        <FavoriteModal
          items={selectedItems}
          onClose={() => _setFavoritesOpen(false)}
          // onRemoveClick={(id: string) => removeAsset(id)}
          hideBackdrop
        />
      )}
      {shareOpen && (
        <ShareModal
          items={selectedItems}
          onClose={() => _setShareOpen(false)}
          // onRemoveClick={(id: string) => removeAsset(id)}
          hideBackdrop
        />
      )}
    </>
  );
};

export default MultipleSelectedTab;