import { SuggestedUser } from "../../interfaces";

export interface FormValues {
  shareRecipients: SuggestedUser[];
  shareMessage: string;
}
export default function mapPropsToValues() {
  return {
    shareRecipients: [],
    shareMessage: "",
  };
}
