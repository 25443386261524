import styled from "styled-components";
import { VideoContainerProps } from "./VideoContainer";
export const StyledContainer = styled(({ ...props }) => (
  <div {...props} />
))<VideoContainerProps>`
  display: flex;
  width: 65%;

  @media only screen and (max-width: 1180px) {
    width: 100%;
  }
`;
