import { withFormik } from "formik";
import mapPropsToValues, { FormValues } from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import { CreateGroupModalProps } from "interfaces/Modal";
import CreateGroupModal from "./CreateGroupModal";

export const CreateGroupModalContainer = withFormik<
  CreateGroupModalProps,
  FormValues
>({
  mapPropsToValues,
  validationSchema,
  handleSubmit: () => null,
  enableReinitialize: true,
  validateOnMount: true,
})(CreateGroupModal);

export default CreateGroupModalContainer;
