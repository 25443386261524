import {
  AssetUploadProps,
  ThumbnailsStateProps,
} from "views/AssetUpload/mapPropsToValues";
import { ParsedUserJWT } from "./Cognito";
import { GroupProps } from "./Search";

export interface Asset {
  createdOn?: string;
  category?: string;
  lastDownload?: string;
  modifiedOn?: string;
  lastModifiedDate?: string;
  assetId?: string;
  id: string;
  expiresOn?: string;
  isActive?: boolean;
  isFavorite?: boolean;
  isSearchable?: boolean;
  isRestricted?: boolean;
  name?: string;
  description?: string;
  source?: string;
  altText?: string;
  keywords?: string;
  jobNumber?: string | null;
  guidelinesUrl?: string | null;
  copyright?: string | null;
  subtitle?: string | null;
  packageHash?: string | null;
  usageRightsCopy?: string | null;
  downloadCount?: number;
  thumbnailCountOverride?: number;
  restrictedApproverId?: string;
  approvalStatus?: ApprovalStatus | string | undefined;
  fileStatus?: ApprovalStatus | undefined;
  restrictedApprover?: AssetUser | null;
  creativeApprovers?: AssetUser[] | null;
  groups?: Groups[] | null;
  users?: AssetUser[] | null;
  assignedGroups?: GroupProps[];
  assignedUsers?: AssetUser[] | null;
  uploadedByUser?: AssetUser | null;
  ownedByUser?: AssetUser | null;
  defaultThumbnailFileId?: string | null;
  defaultThumbnailFile?: AssetFile | null;
  contacts?: AssetUser[] | null;
  editors?: AssetUser[] | null;
  relatedAssets?: Asset[] | null;
  files?: AssetFile[] | null;
  tags?: AssetTag[] | null;
  thumbnails?: AssetThumbnails;
  thumbnail?: string;
  canDownload?: boolean;
  samePhotoShoot?: boolean;
  similarAssets?: Asset[] | null;
  userActions?: AssetUserActions;
  searchIndex?: string;
  assetType?: string;
  searchType?: string;
}

export interface AssetUserActions {
  canDeactivate: boolean;
  canDownload?: boolean;
  canEdit: boolean;
  canExpire: boolean;
  hasAcceptedUsageRights: boolean;
  hasPendingAccessRequest: boolean;
  isAdmin: boolean;
  canUpdateAssetAccessRequest?: boolean;
}

export interface AssetUser {
  createdOn?: string;
  modifiedOn?: string;
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  emailAddress?: string;
  contacts?: AssetUserMeta[] | null;
  editors?: AssetUserMeta[] | null;
}

export interface AssetUserMeta {
  createdOn?: string;
  modifiedOn?: string;
  assetId?: string;
  asset?: Asset | null;
  userId?: string;
  userDetails?: AssetUser;
}

export interface RelatedAsset {
  asset?: Asset;
  id?: string;
  name?: string;
}

export enum ApprovalStatus {
  Pending,
  Approved,
  Declined,
  Created,
  None,
}

export interface AssetEditDto {
  id?: string;
  createdOn?: string;
  expiresOn?: string;
  isActive?: boolean;
  isSearchable?: boolean;
  isRestricted?: boolean;
  name?: string;
  description?: string;
  source?: string;
  altText?: string;
  keywords?: string;
  jobNumber?: string | null;
  guidelinesUrl?: string | null;
  copyright?: string | null;
  subtitle?: string | null;
  usageRightsCopy?: string | null;
  thumbnailCountOverride?: number;
  defaultThumbnailFile?: AssetFile | null;
  restrictedApproverId?: string;
  ownedByUserId?: string;
  contacts?: (string | undefined)[];
  editors?: (string | undefined)[];
  assignedUsers?: (string | undefined)[];
  assignedGroups?: string[];
  relatedAssets?: (string | undefined)[];
  files?: AssetFile[] | null;
  tags?: string[];
}

export interface AssetTagEditDto {
  tagId: string;
}

export interface AssetFileVersionEditDto {
  approvalStatus?: ApprovalStatus | string;
  id?: string;
  fileName?: string;
  sizeInBytes?: number;
  extension?: string;
  thumbnails?: AssetThumbnails;
}

export interface AssetFileEditDto {
  id?: string;
  sendNewVersionNotification?: boolean;
  groupName?: string | null;
  thumbnailOverrideFile?: AssetFileEditDto | null;
  currentVersionId?: string | null;
  versions?: AssetFileVersionEditDto[] | null;
  thumbnails?: AssetThumbnails;
}

// Details
export interface AssetFileVersionDetailDto {
  id?: string;
  createdOn?: string;
  modifiedOn?: string;
  approvalStatus?: ApprovalStatus;
  extension?: string | null;
  fileName?: string | null;
  mimeType?: string | null;
  sizeInBytes?: number;
  versionNumber?: number;
  isCurrentVersion?: boolean;
  thumbnails?: AssetThumbnails;
}

export interface AssetArray {
  page?: number;
  pageSize?: number;
  results: Asset[];
  totalResults: number;
  resultSet?: string;
}

export interface AssetFileCreateDto {
  id?: string;
  sendNewVersionNotification?: boolean;
  groupName?: string | null;
  thumbnailOverrideFile?: AssetFileCreateDto | null;
  /**
   * Name of the file
   */
  fileName?: string | null;
  /**
   * Size of the file
   */
  sizeInBytes?: number;
  /**
   * The extension of the file
   */
  extension?: string | null;
  /**
   * The versions data of file
   */
  versions?: AssetFileVersionDetailDto[] | null;
  thumbnails?: AssetThumbnails;
}

export interface AssetCreateDto {
  /**
   * The date this asset expires
   */
  expiresOn: Date | null; //string;
  /**
   * Determines if asset will show in search results
   */
  isSearchable?: boolean;
  /**
   * Is this a restricted asset
   */
  isRestricted?: boolean;
  /**
   * The name of this asset
   */
  name: string;
  /**
   * The description of this asset
   */
  description: string;
  /**
   * Asset source
   */
  source: string;
  /**
   * Alt text for thumbnail
   */
  altText: string;
  /**
   * Asset keywords for tagging/searching
   */
  keywords: string;
  /**
   * Asset job number
   */
  jobNumber?: string | null;
  /**
   * A link to an associated guidelines doc
   */
  guidelinesUrl?: string | null;
  /**
   * Copyright information
   */
  copyright?: string | null;
  /**
   * Asset subtitle
   */
  subtitle?: string | null;
  /**
   * Asset usage rights information
   */
  usageRightsCopy?: string | null;
  /**
   * Override for thumbnail count
   */
  thumbnailCountOverride?: number;
  /**
   * Override for thumbnail count
   */
  thumbnails?: AssetThumbnails;
  /**
   * The default file to use as thumbnail
   */
  defaultThumbnailFile?: AssetFileCreateDto;
  /**
   * Id for the default file to use as thumbnail
   */
  defaultThumbnailFileId?: string;
  /**
   * Id for the owner user
   */
  ownedByUserId?: string;
  /**
   * Id for the approver user
   */
  restrictedApproverId?: string;
  /**
   * List of id's for assigned users
   */
  assignedGroups?: GroupProps[] | null;
  /**
   * List of id's for assigned users
   */
  assignedUsers?: AssetUser[] | null;
  /**
   * List of id's for contact users
   */
  contacts: string[];
  /**
   * List of id's for editor users
   */
  editors?: string[] | null;
  /**
   * List of id's for related assets
   */
  relatedAssets?: string[] | null;
  /**
   * Files associated with this asset
   */
  files?: AssetFileCreateDto[] | null;
}

export interface AssetRequestCreateDto {
  isPhotographyAsset: boolean;
  audience: string;
  projectName?: string | null;
  inMarketStartDate?: Date | null;
  inMarketEndDate?: Date | null;
  channelId: string;
  requestedUseId: string;
  otherChannel?: string | null;
  otherRequestedUse?: string | null;
  searchIndex: string;
}

export interface AssetRequest {
  assetRequested?: string;
  purposeOfUse?: string;
  fileTypesNeeded?: string;
}

export interface AssetFile {
  id?: string;
  sendNewVersionNotification?: boolean;
  groupName?: string | null;
  thumbnailOverrideFile?: AssetFile | null;
  currentVersionId?: string | null;
  versions?: AssetFileVersion[] | null;
  thumbnails: AssetThumbnails;
  currentVersion?: AssetFileVersion;
  approvalStatus?: string;
}

export interface AssetFileVersion {
  id?: string;
  createdOn?: string;
  modifiedOn?: string;
  approvalStatus?: ApprovalStatus | string;
  extension?: string | null;
  fileName?: string | null;
  mimeType?: string;
  sizeInBytes?: number;
  thumbnails?: AssetThumbnails | null;
  uploadedBy?: AssetUser;
}

export interface AssetPendingFile {
  id: string;
  fileName: string;
  extension: string;
}

export interface AssetFileVersionApproval {
  fileVersionId?: string;
  approvalStatus?: ApprovalStatus | string;
}

export interface AssetPendingVersions {
  thumbnails?: AssetThumbnails;
  versions: AssetFileVersion[];
}

export interface AssetThumbnails {
  small: string;
  medium: string;
  large: string;
  xLarge: string;
  isImage: boolean;
}

export interface AssetUpload {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  id: string;
  thumbnails: AssetThumbnails;
  versionId: string;
}

export interface AssetUploadedFile {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
}

export interface AssetTag {
  category?: string;
  subcategory?: string;
  tag: string;
  tagId: string;
}

export interface AWSPolicy {
  accessKey: string;
  signature: string;
  bucket: string;
  policy: string;
  uploadDirectory: string;
}

export interface AssetDashboardContent {
  whatsNewPinnedAssets: string[];
  featuredCategories: AssetDashboardFeaturedItemContentful[];
}

export type AssetDashboard = {
  title: string;
  categoryTileList: AssetDashboardFeaturedItemContentful[];
};

export type AssetDashboardEntry = Entry<{
  whatsNewSpotlight: AssetSpotlightItem[];
  categoryTileList: AssetDashboardFeaturedItemContentful[];
}>;

export type AssetSpotlightItem = Entry<{
  id: string;
}>;

export type ContentfulFile = Entry<{
  url: string;
}>;

export type ContentfulImage = Entry<{
  file: ContentfulFile;
}>;

export type AssetDashboardFeaturedItemContentful = Entry<{
  assetType: string;
  image: ContentfulImage;
  title: string;
  url: string;
}>;

export interface AssetDashboardFeaturedItem {
  id: number;
  title: string;
  imageUrl: string;
  url: string;
  imageFileName: string;
  assetType: string;
  imageContentType: string;
  fillImage: boolean;
  noBorder: boolean;
  twoWide: boolean;
  backgroundColor: "BLACK" | "WHITE";
}

export interface AssetSearchQuery {
  q?: string;
  filters?: string[];
  sort?: SearchSortMethod;
  page?: number;
  pageSize?: number;
  significance?: boolean;
  includerestricted?: boolean;
  includeexpired?: boolean;
  lightweight?: boolean;
}

export enum UploadedAssetsQuerySortMethod {
  None,
  TitleAscending,
  TitleDescending,
  UploadedAscending,
  UploadedDescending,
  ModifiedAscending,
  ModifiedDescending,
  StatusAscending,
  StatusDescending,
}

export interface UploadedAssetsQuery {
  searchTerm?: string;
  // per-page
  pageSize?: number;
  // which page
  pageNum?: number;
  sortColumn?: number;
  sortDescending: boolean;
}

export interface UploadedAssetsQueryResponse {
  total: number;
  pageCount: number;
  records: Asset[];
}

export interface DownloadedAssetsQuery {
  searchTerm?: string;
  // per-page
  pageSize?: number;
  // which page
  pageNum?: number;
  sortColumn?: number;
  sortDescending: boolean;
  categoryFilters: string[];
}

export interface DownloadedAssetsQueryResponse {
  totalResults: number;
  totalPages: number;
  pageSize: number;
  pageNumber: number;
  results: Asset[];
}

export interface ApproveAccessResponse {
  firstName: string;
  lastName: string;
  emailAddress: string;
  id: string;
}

export interface GeneratedKeywordsResult {
  createdOn: string;
  errorMessage: string;
  fileVersionId: string;
  id: string;
  modifiedOn: string;
  status: string;
  tags: string[];
}

export interface AssetSearchInfo {
  // which page
  page?: number;
  // result # on page
  resultIndex?: number;
}

export interface AssetRequestFormOption {
  id: string;
  value: string;
}

export interface AssetRequestFormOptions {
  channels: AssetRequestFormOption[];
  requestedUses: AssetRequestFormOption[];
}

export enum SearchSortMethod {
  MostRelevant,
  MostPopular,
  RecentlyUpdated,
  OldestToNewest,
  AtoZ,
  ZtoA,
  NewestToOldest,
}

interface Sys {
  type: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  contentType?: { sys: { id: string } };
}

export interface Entry<T> {
  sys: Sys;
  fields: T;
}

export interface EntriesResponse {
  items: unknown[];
  limit: number;
  skip: number;
  sys: Sys;
  total: number;
}

export interface Groups {
  $id: string;
  Company: string;
  CreatedBy: string;
  CreatedOn: string;
  GroupMembers: string[];
  GroupStatus: string | null;
  Id: string;
  Name: string;
  Status: string;
  company?: string;
  id?: string;
  name?: string;
}

export interface AssetFileGroup {
  name: string;
  default: AssetFile;
  files: AssetFile[];
}

export interface AssetDetailsPage {
  assetDetails: Asset;
}

export interface EditAssetPage {
  assetDetails: Asset;
  setNotification: Function;
  userDetails: ParsedUserJWT | null;
}

export interface EditMultipleAssetPage {
  multipleAssets: Asset[];
  setNotification: Function;
  userDetails: ParsedUserJWT | null;
}

export interface EditAssetTab {
  emptyAsset?: AssetUploadProps;
  onNav: Function;
  disabled?: boolean;
  setDisabled: Function;
  assetId?: string;
  handleCancel?: Function;
  usageRightsAccepted?: boolean | null;
  usageRightsCopy?: string | null;
}

export interface UploadAssetTab {
  emptyAsset?: AssetUploadProps;
  onNav: Function;
  disabled?: boolean;
  setDisabled: Function;
  assetId?: string;
  handleReset?: Function;
}

export interface ThumbnailProps {
  extension?: string;
  fileName: string;
  id?: string;
  sizeInBytes?: number;
  src: string;
  versionNumber?: number;
}

export interface FetchedThumbnailProps {
  isImage: boolean;
  small: string;
  medium: string;
  large: string;
}
export interface ThumbnailTileProps {
  fileId: string;
  index?: number;
  thumbnail: ThumbnailProps;
  thumbnails: ThumbnailsStateProps;
  files?: any[];
  uploadS3: Function;
  loading?: boolean;
  callGetThumbnailUrls: Function;
  setFieldValue: Function;
}

export interface ActivateAssetRequest {
  assetId: string;
  isActive: boolean;
  payload: string;
}

export interface ExpireAssetRequest {
  assetId: string;
  expirationDate: boolean;
  payload: string;
}

export interface FileDownloadRequest {
  assetId: string;
  fileVersionId: string;
}

export interface ShareAssetRequest {
  recipientIds: string[];
  shareItems: Array<ShareItem>;
  message: string;
}

export interface ShareItem {
  entityId: string;
  searchType: string;
}

export type AssetThumbnailSize = "small" | "medium" | "large" | "xLarge";

export type Tag = {
  description: string;
  id: string;
  subCategoryId: string;
  value: string;
};
export type SubCategory = {
  categoryId: string;
  description: string;
  id: string;
  tags: Array<Tag>;
  value: string;
};

export type Category = {
  description: string;
  id: string;
  subCategories: Array<SubCategory>;
  value: string;
};

export type ActiveCategory = {
  active: boolean;
  id: string;
  value: string;
};

export type ActiveSubCategory = {
  active: boolean;
  categoryId: string;
  id: string;
  value: string;
};

export type ActiveTag = {
  active: boolean;
  subcategoryId: string;
  id: string;
  value: string;
};

export type CategoryArray = Array<Category>;

export type CategoryResponse = {
  assets: CategoryArray;
  templates: CategoryArray;
  designAdvisories: CategoryArray;
};
