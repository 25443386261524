export default function mapPropsToValues() {
  return {
    firstName: "",
    lastName: "",
    email: "",
    verizonContact: "",
    phone: "",
    country: "",
    company: "",
    employmentTitle: "",
    customEmploymentTitle: null,
    password: "",
    passwordConfirm: "",
    recaptchaToken: null,
  };
}
