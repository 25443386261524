import {Avatar, AvatarGroup, Grid} from "@mui/material";
import {IconButton} from "components";
import FourUp from "../../components/layouts/FourUp/FourUp";
import styled from "styled-components";
import { zIndex, minTablet } from "theme/variables";
import Section from "../../components/layouts/Section/Section";
import FlexBox from "../../components/layouts/FlexBox/FlexBox";

interface PaginationProps {
  showFeedback?: boolean;
}

export const StyledTitle = styled.div`
  background-color: #f6f6f6;
`;

export const FiltersWrapper = styled.div`
  border: 1px solid #f6f6f6;
  border-bottom: none;
`;

export const StyledMicro = styled.div`
  border-block: 1px solid #f6f6f6;
  padding: 1rem 1.5rem;
`;

export const SelectedTag = styled.div`
  display: flex;

  background-color: #ffffff;
  padding-block: 0.25rem;
  padding-inline: 0.5rem;
  border-radius: 5px;
  border: 1px solid #6F7171;
  width: fit-content;
`;

export const StyledFeedback = styled.div`
  position: relative;
  background-color: #f6f6f6;
  padding: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  
  h3 {
    text-align: center;
  }
`;

export const StyledRelatedSearches = styled(FlexBox)`
  @media screen and (max-width: ${minTablet - 1}px) {
    display: none;
  }
  @media screen and (min-width: ${minTablet}px) {
    display: flex;
  }
  button {
    white-space: break-spaces;
  }
`;

export const MobileStyledRelatedSearches = styled(FlexBox)`
  flex-direction: column;
  gap: 1.5rem;
  @media screen and (max-width: ${minTablet - 1}px) {
    display: flex;
    button {
      display: inline-block;
      text-align: left;
      white-space: break-spaces;
      span:nth-of-type(2) {
        text-align: left;
        display: inline;
      }
    }
  }
  @media screen and (min-width: ${minTablet}px) {
    display: none;
  }
`;

export const StyledClose = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
`;

export const SelectedGroup = styled.button`
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: ${zIndex.FAB};

  padding: 0;
  border: none;
  background: transparent;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledAvatar = styled(Avatar)`
  background-color: rgb(216, 218, 218);
`;

export const StyledFeedbackButtons = styled("div")`
  display: flex;
  gap: 1rem;
  @media screen and (max-width: ${minTablet - 1}px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (min-width: ${minTablet}px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledFilterDropdowns = styled("div")`
  
  
  @media screen and (max-width: ${minTablet - 1}px) {
    justify-content: flex-start;
    padding: 0 1rem 1rem;
    display: none;
  }
  @media screen and (min-width: ${minTablet}px) {
    justify-content: flex-end;
    padding: 0 1rem 1rem 0;
    display: flex;
    gap: 0.5rem;
  }
`;

export const MobileSortDropdowns = styled("div")`
  
  @media screen and (max-width: ${minTablet - 1}px) {
    justify-content: flex-start;
    padding: 0 1rem 1rem;
    display: none;
    &.visible {
      display: flex;
    }
    gap: 0.5rem;
  }
  @media screen and (min-width: ${minTablet}px) {
    justify-content: flex-end;
    padding: 0 1rem 1rem 0;
    display: none;
  }
`;

export const MobileFilterSortContainer = styled(Grid)`
  @media screen and (max-width: ${minTablet - 1}px) {
    display: block;
    padding: 0 0 1rem;
  }
  @media screen and (min-width: ${minTablet}px) {
    display: none;
  }
`;

export const MobileFilterButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  padding: 0 1rem 1rem;
`;

export const StyledFiltersContainer = styled("div")`
  padding-top: 5rem;
`;

export const StyledFiltersGrid = styled(Grid)`
  @media screen and (max-width: ${minTablet - 1}px) {
    display: none;
  }
  @media screen and (min-width: ${minTablet}px) {
    display: block;
  }
`;

export const StyledSearchResultsSection = styled(Section)`
  @media screen and (max-width: ${minTablet - 1}px) {
    padding: 1rem 0 0;
  }
  @media screen and (min-width: ${minTablet}px) {
    padding: 1rem 1.25rem 0;
  }
`;
export const StyledResultsTitle = styled("div")`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  @media screen and (max-width: ${minTablet - 1}px) {
    padding: 1rem;
  }
  @media screen and (min-width: ${minTablet}px) {
    padding: 0 0 1rem 1rem;
  }
`;

export const StyledResultsContainer = styled("div")`
  padding-bottom: 4rem;
  @media screen and (max-width: ${minTablet - 1}px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media screen and (min-width: ${minTablet}px) {
    padding-left: 1.25rem;
    padding-right: 0;
  }
`;

interface StyledAssetResultsProps {
  bottomMargin: boolean;
}

export const StyledAssetResultsContainer = styled("div")<StyledAssetResultsProps>`
  margin-bottom: ${(props) => (props.bottomMargin ? "4rem" : "0")};
  @media screen and (max-width: ${minTablet - 1}px) {
  padding: 0 1rem;
}
  @media screen and (min-width: ${minTablet}px) {
    padding: 0;
  }
`;

export const StyledAssetResults = styled(FourUp)`
  @media screen and (max-width: ${minTablet - 1}px) {
    padding: 0 1rem;
  }
  @media screen and (min-width: ${minTablet}px) {
    padding: 0 2rem 0 0;
  }
`;

export const StyledPagination = styled("div")<PaginationProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  @media screen and (max-width: ${minTablet - 1}px) {
    padding: ${(props) => (props.showFeedback ? "4rem 0 2rem" : "2rem 0 2rem")};
  }
  @media screen and (min-width: ${minTablet}px) {
    padding: ${(props) => (props.showFeedback ? "0 0 2rem" : "2rem 0 2rem")};
  }
`;
