import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NotificationItem from './NotificationItem.js';

const propTypes = {
  childArray: PropTypes.array,
  /**
   * @ignore - Prop that passes new Notification component
   */
  renderNotification: PropTypes.func
};
const NotificationContainer = props => {
  const {
    childArray,
    renderNotification
  } = props;
  return React.createElement(Fragment, null, childArray.map((child, index) => React.createElement(NotificationItem, Object.assign({}, child, {
    renderNotification: renderNotification,
    key: index,
    index: index
  }))));
};
NotificationContainer.propTypes = propTypes;

export { NotificationContainer as default };
