import { Body, Button, FlexBox, Section, Title, TwoUp } from "components";
import { StyledLink } from "components/buttons/TextLink/TextLink.module";
import { useNavigate } from "react-router-dom";

const GenericError = ({ inline }: { inline?: boolean }) => {
  // const error = useRouteError();
  // console.error(error);

  const navigate = useNavigate();

  async function handleNav() {
    navigate(0);
  }

  return inline ? (
    <TwoUp pd="2rem 0 0">
      <Body size="large">
        An error has occurred while loading data. Please refresh to try again.
      </Body>
    </TwoUp>
  ) : (
    <Section data-testid="generic-error">
      <TwoUp pd="2rem 0 0">
        <FlexBox gap="2rem">
          <Title size="XLarge" bold>
            Page Load Error
          </Title>
          <Body size="large">
            An error has occurred while loading your page. Please check your
            connection and try again. If this error persists, please feel free
            to{" "}
            <StyledLink
              to={`mailto:support@brandcentral@verizon.com`}
              target="_blank"
              display="inline-block"
            >
              contact us
            </StyledLink>{" "}
            and we'll be sure to look into the issue.
          </Body>

          <Button onClick={handleNav}>Refresh page</Button>
        </FlexBox>
      </TwoUp>
    </Section>
  );
};

export default GenericError;
