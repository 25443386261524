import { Button } from "@vds/buttons";
import styled from "styled-components";
import { ButtonProps } from "interfaces";

export const StyledButton = styled(({ bold, ...props }) => (
  <Button {...props} />
))<ButtonProps>`
  width: fit-content;
  height: fit-content;

  border: none;
  border-radius: unset;
  background-color: transparent;
  width: fit-content;
  text-decoration: none;
  padding: 0;
  color: black;
  text-transform: none;
  min-width: unset;

  padding: ${(props) => (props.pd ? props.pd : "0")};

  span:last-child {
    border-bottom: 1px solid black;
    margin-bottom: 1px;
    color: ${(props) => (props.color ? props.color : "#000000")};
  }

  span {
    height: fit-content;
    font-weight: ${(props) => (props.bold ? "700" : "400")};
    font-size: ${(props) => (props.size === "large" ? "1rem" : "0.75rem")};
    font-family: ${(props) =>
      props.size === "large" ? "Verizon-NHG-eDS" : "Verizon-NHG-eTX"};
  }

  &:hover,
  &:hover:not(:active),
  &:active,
  &:hover:not(.disabled) {
    background: none;
    box-shadow: none;
    span:last-child {
      border-bottom: 2px solid black;
      margin-bottom: 0px;
    }
  }

  &.disabled {
    pointer-events: none;
    border-bottom-color: lightgrey;

    p {
      color: grey;
    }
  }
  span {
    padding: 0;
    min-width: unset;
  }
`;
