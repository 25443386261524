import { SuggestedUserResponse } from "interfaces";

export default function mapPropsToValues() {
  return {
    sendToUsers: [] as unknown as SuggestedUserResponse,
    subject: "",
    message: "",
    expirationDate: undefined,
    sendCopy: false,
    files: [],
  };
}
