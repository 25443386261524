'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _focusLock = require('focus-lock');

var _Trap = require('./Trap');

var _Trap2 = _interopRequireDefault(_Trap);

var _util = require('./util');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RenderChildren = function RenderChildren(_ref) {
  var children = _ref.children;
  return _react2.default.createElement(
    'div',
    null,
    children
  );
};
RenderChildren.propTypes = {
  children: _propTypes2.default.node.isRequired
};
var Fragment = _react2.default.Fragment ? _react2.default.Fragment : RenderChildren;

var hidden = {
  width: '1px',
  height: '0px',
  padding: 0,
  overflow: 'hidden',
  position: 'fixed',
  top: 0,
  left: 0
};

var FocusLock = function (_Component) {
  _inherits(FocusLock, _Component);

  function FocusLock() {
    var _ref2;

    var _temp, _this, _ret;

    _classCallCheck(this, FocusLock);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref2 = FocusLock.__proto__ || Object.getPrototypeOf(FocusLock)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      observed: undefined
    }, _this.onActivation = function () {
      _this.originalFocusedElement = _this.originalFocusedElement || document.activeElement;
    }, _this.setObserveNode = function (observed) {
      return _this.setState({
        observed: observed
      });
    }, _this.update = function () {
      return _this.setState(function (prevState) {
        return {
          escapeAttempts: prevState.escapeAttempts + 1
        };
      });
    }, _this.originalFocusedElement = null, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(FocusLock, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (prevProps.disabled && !this.props.disabled) {
        this.originalFocusedElement = null;
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      var _this2 = this;

      if (this.props.returnFocus && this.originalFocusedElement && this.originalFocusedElement.focus) {
        (0, _util.deferAction)(function () {
          return _this2.originalFocusedElement.focus();
        }, 0);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _lockProps;

      var _props = this.props,
          children = _props.children,
          disabled = _props.disabled,
          noFocusGuards = _props.noFocusGuards,
          persistentFocus = _props.persistentFocus,
          autoFocus = _props.autoFocus,
          allowTextSelection = _props.allowTextSelection,
          group = _props.group;
      var observed = this.state.observed;


      if (typeof allowTextSelection !== 'undefined') {
        // eslint-disable-next-line no-console
        console.warn('React-Focus-Lock: allowTextSelection is deprecated and enabled by default');
      }

      var lockProps = (_lockProps = {}, _defineProperty(_lockProps, _focusLock.constants.FOCUS_DISABLED, disabled && 'disabled'), _defineProperty(_lockProps, _focusLock.constants.FOCUS_GROUP, group), _lockProps);

      return _react2.default.createElement(
        Fragment,
        null,
        !noFocusGuards && [_react2.default.createElement('div', { key: 'guard-first', tabIndex: disabled ? -1 : 0, style: hidden }), // nearest focus guard
        _react2.default.createElement('div', { key: 'guard-nearest', tabIndex: disabled ? -1 : 1, style: hidden })],
        _react2.default.createElement(
          'div',
          _extends({
            ref: this.setObserveNode
          }, lockProps),
          _react2.default.createElement(
            _Trap2.default,
            {
              observed: observed,
              disabled: disabled,
              persistentFocus: persistentFocus,
              autoFocus: autoFocus,
              onActivation: this.onActivation
            },
            children
          )
        ),
        !noFocusGuards && _react2.default.createElement('div', { tabIndex: disabled ? -1 : 0, style: hidden })
      );
    }
  }]);

  return FocusLock;
}(_react.Component);

FocusLock.propTypes = {
  children: _propTypes2.default.node.isRequired,
  disabled: _propTypes2.default.bool,
  returnFocus: _propTypes2.default.bool,
  noFocusGuards: _propTypes2.default.bool,

  allowTextSelection: _propTypes2.default.bool,
  autoFocus: _propTypes2.default.bool,
  persistentFocus: _propTypes2.default.bool,

  group: _propTypes2.default.string
};

FocusLock.defaultProps = {
  disabled: false,
  returnFocus: false,
  noFocusGuards: false,
  autoFocus: true,
  persistentFocus: false,
  allowTextSelection: undefined,
  group: undefined
};

exports.default = FocusLock;