import styled from "styled-components";
import { zIndex } from "theme/variables";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  position: relative;
  top: 0;

  max-width: 25rem;
  height: 0;

  opacity: 0;
  /* z-index: -1; */

  &.expanded {
    height: auto;
    z-index: ${zIndex.notBottom};
    opacity: 1;
    transition: opacity 0.5s;
  }

  > div:nth-child(4) {
    margin-top: 1.5rem;
  }

  button[type="submit"] {
    width: 6.5rem;
  }

  .close-button {
    position: absolute;
    right: 0.25rem;
    top: 0.75rem;
  }
`;
