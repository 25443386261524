import styled from "styled-components";
import FlexBox from "components/layouts/FlexBox/FlexBox";
import { minTablet } from "theme/variables";

export interface StyledFormProps {
  maxWidth?: string;
}

export const StyledForm = styled(FlexBox)<StyledFormProps>`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  @media screen and (max-width: ${minTablet - 1}px) {
    max-width: 100%;
  }
`;

