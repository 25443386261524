import { AssetFile, AssetThumbnailSize } from "interfaces";

export function getPrimaryFile(files: AssetFile[]): AssetFile {
  const sortedFiles = [...files].sort((file) =>
    file.thumbnails?.isImage ? -1 : 1
  );

  return sortedFiles[0];
}

export function getThumbnails(
  files: AssetFile[],
  size: AssetThumbnailSize = "small"
): string[] {
  let thumbnails: string[] = [];
  thumbnails = files.map((file) => {
    let thumbnail = "";
    if (!file.thumbnails || !file.thumbnails?.[size]) {
      return thumbnail;
    }
    thumbnail = file.thumbnails?.[size];
    return thumbnail;
  });
  return thumbnails;
}
