import { useRef, useState } from "react";
import { Body, FlexBox, Title } from "components";
import {
  StyledButton,
  StyledDrag,
  StyledWrapper,
  StyledInput,
  StyledBackground,
  StyledFlexBox,
} from "./DragAndDrop.module";
import { AxiosProgressEvent } from "axios";
import UploadedFile from "./UploadedFile";
import { setNotification } from "services";
import { useDispatch } from "hooks/redux";

export interface DragAndDropProps {
  files: File[];
  label?: string;
  fileSizeLimitInMb?: number;
  onChange: (files: File[], progressCallback: Function) => void;
  uploadingFiles: string[];
  onDelete?: (file: File) => void;
  deletingFiles?: string[];
}

const DragAndDrop = ({
  files,
  label,
  fileSizeLimitInMb,
  onChange,
  onDelete,
  uploadingFiles,
  deletingFiles = [],
}: DragAndDropProps) => {
  const dispatch = useDispatch();

  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef<HTMLInputElement>(null);

  const exceedsFileSizeLimit = (sizeInBytes: number): boolean => {
    if (fileSizeLimitInMb && sizeInBytes / 1024 ** 2 > fileSizeLimitInMb) {
      dispatch(
        setNotification({
          type: "error",
          message: `File(s) failed upload as exceed file size limit.`,
        })
      );
      return true;
    }

    return false;
  };

  const fileNameExists = (file: File): boolean => {
    const matchedFiles = files.find(({ name }) => name === file.name);
    if (matchedFiles) {
      dispatch(
        setNotification({
          type: "error",
          message: `File failed upload as file name exists.`,
        })
      );
      return true;
    }
    return false;
  };

  const [progress, _setProgress] = useState<any>({});

  const progressCallback = (
    fileName: string,
    progressEvent: AxiosProgressEvent
  ) => {
    _setProgress((oldArr: any) => {
      return {
        ...oldArr,
        [fileName]: progressEvent,
      };
    });
  };
  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault();
    // e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files.length) {
      let uploadFiles: File[] = [];
      Object.values(e.dataTransfer.files).map((file: any) => {
        if (!exceedsFileSizeLimit(file.size) && !fileNameExists(file)) {
          uploadFiles.push(file);
        }
      });
      onChange(uploadFiles, progressCallback);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files.length) {
      let uploadFiles: File[] = [];
      Object.values(e.target.files).map((file: any) => {
        if (!exceedsFileSizeLimit(file.size) && !fileNameExists(file)) {
          uploadFiles.push(file);
        }
      });
      onChange(uploadFiles, progressCallback);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (e: any) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  return (
    <FlexBox>
      {label && <Body size="small">{label}</Body>}
      <StyledWrapper onDragEnter={handleDrag}>
        <StyledInput
          ref={inputRef}
          type="file"
          multiple
          onChange={handleChange}
          data-testid="drag-and-drop"
          // onClick={(e: any) => {
          //   e.target.files = null;
          // }}
        />
        <StyledBackground className={dragActive ? "drag-active" : ""}>
          <StyledFlexBox>
            {/* <Icon name="single-document" size="XLarge" /> */}
            <Title size="large" bold>
              Drag and drop
            </Title>
            <FlexBox row align="center">
              <Body size="large">your files or</Body>
              <StyledButton
                className="upload-button"
                onClick={onButtonClick}
                aria-label="Browse files"
              >
                <Body size="large" bold>
                  browse
                </Body>
              </StyledButton>
            </FlexBox>
            {fileSizeLimitInMb ? (
              <Body size="small" pd={"1.5rem 0 0 0"}>
                <em>File size limit: {fileSizeLimitInMb}MB</em>
              </Body>
            ) : (
              <></>
            )}
          </StyledFlexBox>
        </StyledBackground>
        {dragActive && (
          <StyledDrag
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </StyledWrapper>
      {/* {hasFileSizeError && (
        <StyledError>
          The file you uploaded exceeds the file size limit of{" "}
          {fileSizeLimitInMb}MB
        </StyledError>
      )} */}
      {files.length ? (
        <FlexBox pd={"1rem"} gap="1rem">
          {files.map((file: File) => (
            <UploadedFile
              key={file.name}
              file={file}
              progressEvent={progress[file.name]}
              uploading={uploadingFiles?.includes(file.name) || false}
              deleting={deletingFiles?.includes(file.name) || false}
              onDelete={onDelete}
            />
          ))}
        </FlexBox>
      ) : (
        <></>
      )}
    </FlexBox>
  );
};

export default DragAndDrop;
