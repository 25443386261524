import React, { FormEventHandler } from "react";
import { StyledForm } from "./Form.module";

export type FormProps = {
  children: React.ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  maxWidth?: string;
  width?: string;
  gap?: string;
};

const Form = ({ children, onSubmit, ...rest }: FormProps) => {
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onSubmit && onSubmit(e);
  };

  return (
    <StyledForm onSubmit={handleOnSubmit} {...rest}>
      {children}
    </StyledForm>
  );
};

export default Form;
