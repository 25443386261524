import { object, string, date } from "yup";

const validationSchema = ({ data }: any) => {
  return object().shape({
    isPhotographyAsset: string().required(
      "You must indicate if this request is for a photography asset"
    ),
    audience: string().required("You must enter an audience for this asset"),
    channelId: string().required("You must select a Channel"),
    otherChannel: string().when("channelId", {
      is: (channelId: string) =>
        data.channels.find(
          (channel: any) =>
            channel.value === "Other" && channel.id === channelId
        ),
      then: () => string().required("You must enter a channel"),
    }),
    requestedUseId: string().required("You must select a medium"),
    otherRequestedUse: string().when("requestedUseId", {
      is: (requestedUseId: string) =>
        data.requestedUses.find(
          (use: any) => use.value === "Other" && use.id === requestedUseId
        ),
      then: () => string().required("You must enter a requested use"),
    }),
    projectName: string().when("isPhotographyAsset", {
      is: (isPhotographyAsset: string) => isPhotographyAsset === "1",
      then: () => string().required("You must enter a project name"),
    }),
    startDate: date()
      .nullable()
      .when("isPhotographyAsset", {
        is: (isPhotographyAsset: string) => isPhotographyAsset === "1",
        then: () => date().required("You must enter a start date"),
      }),
    endDate: date()
      .nullable()
      .when("isPhotographyAsset", {
        is: (isPhotographyAsset: string) => isPhotographyAsset === "1",
        then: () => date().required("You must enter an end date"),
      }),
  });
};

export default (props: any) => validationSchema(props);
