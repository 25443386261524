import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootStateProps } from "interfaces";
import { Location } from "react-router-dom";

export interface NotificationProps {
  type: "error" | "info" | "success" | "warning" | undefined;
  message: string;
}

const initialState: {
  notification: NotificationProps;
  location?: Location;
} = {
  notification: { type: undefined, message: "" },
  location: undefined,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setNotification: (state, { payload }: PayloadAction<NotificationProps>) => {
      state.notification.type = payload.type;
      state.notification.message = payload.message;
    },
    resetNotification: (state) => {
      state.notification.type = undefined;
      state.notification.message = "";
    },
    setLocation: (state, { payload }: PayloadAction<Location>) => {
      state.location = payload;
    },
    resetLocation: (state) => {
      state.location = undefined;
    },
  },
});

export const {
  setNotification,
  resetNotification,
  setLocation,
  resetLocation,
} = uiSlice.actions;

export const selectNotification = (state: RootStateProps) =>
  state.ui.notification;
export const selectLocation = (state: RootStateProps) => state.ui.location;

export const uiReducer = uiSlice.reducer;
