import React from "react";
import { StyledComplianceOverlayBox, StyledStepsWrapper } from "./ComplianceOverlayBox.module";
import { Title, FlexBox, Body, MdToHtml } from "components";
import {ComplianceOverviewPageProps, ComplianceScoringPageProps} from "../../../interfaces";
import {ComplianceProcessStepProps, ComplianceScoringElementProps} from "../../../interfaces/Compliance";

export type ComplianceOverlayBoxProps = {
  className?: string;
  children: React.ReactNode;
  pd?: string;
  mg?: string;
  maxHeight?: string;
  color?: string;
  background?: string;
  overviewPage?: boolean;
  overviewContent?: ComplianceOverviewPageProps;
};

const ComplianceOverlayBox = ({ children, background, overviewPage, overviewContent, ...rest }: ComplianceOverlayBoxProps) => {

  const feedback = [
    {
      title: '“Off-brand” means:',
      step1: 'Applying edits is expected',
      step2: 'Clear direction and link to guidelines',
      step3: 'Impacts your score',
    },
    {
      title: '“Please ensure” means:',
      step1: 'Applying edits is recommended, but are not required',
      step2: 'We provide suggestions that will help the piece feel more like the Verizon brand',
      step3: 'Does not impact your score',
    },
  ];

  return (
    <>
      { overviewPage ? (
        <StyledComplianceOverlayBox background={background}{...rest}>
          <div className="ComplianceOverlayBox-overlay-container">
            <div className="ComplianceOverlayBox-overlay-content">
              {children}
            </div>
            <div className="ComplianceOverlayBox-overlay-background content" style={{backgroundColor: "#f6f6f6"}}>
              <FlexBox gap="4rem">
                {overviewContent?.howComplianceWorksSteps.map((
                  steps: ComplianceProcessStepProps, index: number
                ) => (
                  <FlexBox key={index}>
                    <Title size="large" bold pd="0 0 1.25rem">{index + 1 + ". "}{steps.title}</Title>
                    <Body>{steps.shortDescription}</Body>
                  </FlexBox>
                ))}
              </FlexBox>
            </div>
          </div>
        </StyledComplianceOverlayBox>) : (
        <StyledComplianceOverlayBox background={background}{...rest}>
          <div className="ComplianceOverlayBox-overlay-container">
            <div className="ComplianceOverlayBox-overlay-content">
              {children}
            </div>
            <div className="ComplianceOverlayBox-overlay-background content" style={{backgroundColor: "#f6f6f6"}}>
              <StyledStepsWrapper gap="4rem">
                {feedback.map((
                  item
                ) => (
                  <FlexBox key={item.title} maxWidth="24rem">
                    <Title size="large" bold pd="0 0 1.25rem">{item.title}</Title>
                    <ul>
                      <li><Body size="large">{item.step1}</Body></li>
                      <li><Body size="large">{item.step2}</Body></li>
                      <li><Body size="large">{item.step3}</Body></li>
                    </ul>
                  </FlexBox>
                ))}
              </StyledStepsWrapper>
            </div>
          </div>
        </StyledComplianceOverlayBox>
      )
      }
    </>
  );
};

export default ComplianceOverlayBox;
