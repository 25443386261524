import { __awaiter } from '../_virtual/_tslib.js';

//****** To-do, test loading in the families first asynchronously from check whether to load the url if necessary ******//
let FontConfig = {
  loaded: false
};
//Turn on or off the font loader
function setFontLoaded(loaded) {
  FontConfig.loaded = loaded ? true : false;
}
function checkFontLoaded() {
  return FontConfig.loaded;
}
// Define and Load the fonts
let customConfig = {
  families: ['Verizon-NHG-eTX', 'Verizon-NHG-eDS']
};
let fontUri1 = 'https://respframework.verizon.com/verizon-nhg.css';
function finishLoading() {
  return __awaiter(this, void 0, void 0, function* () {
    try {
      const WebFont = yield Promise.resolve().then(() => require('webfontloader'));
      const WebFontConfig = {
        custom: customConfig,
        active: () => setFontLoaded(true),
        inactive: () => console.warn('fonts could not be loaded.')
      };
      WebFont.load && WebFont.load(WebFontConfig);
    } catch (error) {
      typeof window !== 'undefined' && console.error(error);
    }
  });
}
function checkIfLoaded() {
  if (typeof document === 'undefined') return false;
  const links = Array.from(document.head.getElementsByTagName('link'));
  if (!links || !links.length) return false;
  return links.find(link => {
    return link.getAttribute('href') === fontUri1;
  });
}
function loadFonts() {
  let loaded = checkIfLoaded();
  if (!loaded) {
    customConfig.urls = [fontUri1];
    finishLoading();
  } else setFontLoaded(true);
}
if (checkIfLoaded()) setFontLoaded(true);
if (!checkFontLoaded()) loadFonts();
const Fonts = {
  VerizonNHGeDS: 'Verizon-NHG-eDS',
  VerizonNHGeTX: 'Verizon-NHG-eTX',
  checkFontLoaded: checkFontLoaded,
  setFontLoaded: setFontLoaded,
  loadFonts: loadFonts
};

export { Fonts };
