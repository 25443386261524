import { StyledExternalLink, StyledLink } from "./CaretLink.module";
import Body from "components/typography/Body/Body";
import { MouseEventHandler } from "react";
import { externalRoute } from "utils";

export interface CaretLinkProps {
  to: string;
  external?: boolean;
  onClick?: MouseEventHandler;
  align?: "flex-start" | "center" | "flex-end";
  disabled?: boolean;
  children: any;
  caretPosition?: "left" | "right";
  fontSize?: string;
  pd?: string;
  color?: string;
  rel?: string;
  newTab?: boolean;
  state?: any;
}
const CaretLink = ({
  to,
  external,
  disabled,
  caretPosition,
  color,
  children,
  rel,
  newTab,
  ...rest
}: CaretLinkProps) => {
  return external ? (
    <StyledExternalLink
      href={externalRoute(to)}
      to="#"
      rel="external noopener noreferrer"
      className={`${caretPosition} ${disabled ? "disabled" : ""}`}
      color={color}
      target={newTab ? "_blank" : ""}
      data-testid="external-caret-link"
      {...rest}
    >
      <Body bold>{children}</Body>
      <span className={`caret ${caretPosition}`}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 21.6 21.6"
        >
          <path
            fill={`${disabled ? "gray" : color ? color : "black"}`}
            d="M7.6,20.7L5,18.1l7.3-7.3L5,3.5l2.5-2.5l9.9,9.9L7.6,20.7z"
          ></path>
        </svg>
      </span>
    </StyledExternalLink>
  ) : (
    <StyledLink
      to={to}
      className={`${caretPosition} ${disabled ? "disabled" : ""}`}
      color={color}
      target={newTab ? "_blank" : ""}
      data-testid="internal-caret-link"
      {...rest}
    >
      <Body bold>{children}</Body>
      <span className={`caret ${caretPosition}`}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 21.6 21.6"
        >
          <path
            fill={`${disabled ? "gray" : color ? color : "black"}`}
            d="M7.6,20.7L5,18.1l7.3-7.3L5,3.5l2.5-2.5l9.9,9.9L7.6,20.7z"
          ></path>
        </svg>
      </span>
    </StyledLink>
  );
};

export default CaretLink;
