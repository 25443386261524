import React from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon as ButtonIcon$1 } from '@vds-core/button-icons';

const propTypes = {
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const ButtonIcon = function (props) {
  return React.createElement(ButtonIcon$1, Object.assign({}, props));
};
ButtonIcon.propTypes = propTypes;
ButtonIcon.defaultProps = defaultProps;

export { ButtonIcon as default };
