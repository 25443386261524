import { __rest } from '../../_virtual/_tslib.js';
import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../InputField/InputField.js';
import '../InputField/InputText.js';

/* istanbul ignore file */
const propTypes = {
  type: PropTypes.oneOf(['text', 'number', 'calendar', 'inlineAction', 'password', 'creditCard', 'tel', 'date', 'securityCode'])
};
const defaultProps = {
  type: 'text'
};
/**
 * @ignore
 */
const Input = _a => {
  var {
      type
    } = _a,
    props = __rest(_a, ["type"]);
  switch (type) {
    case 'number':
    case 'text':
    case 'inlineAction':
    case 'password':
    case 'creditCard':
    case 'tel':
    case 'date':
    case 'securityCode':
      return React.createElement(InputField, Object.assign({
        type: type
      }, props));
    default:
      console.warn('Warning: Native input type ' + type + ' is not supported');
      return React.createElement(InputField, Object.assign({
        type: "text"
      }, props));
  }
};
Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export { Input as default };
