import { Fragment, ReactNode, useEffect, useState, useRef } from "react";
import { StyledCarousel, StyledNavigation } from "./BasicCarousel.module";
import IconButton from "../../buttons/IconButton/IconButton";

export interface BasicCarouselProps {
  slides: Array<ReactNode>;
  slidesPerView?: number;
  noControls?: boolean;
  noScroll?: boolean;
  minHeight?: string;
  maxHeight?: string;
  bgColor?: string;
  pd?: string;
  scrollToSlide?: number;
  gap?: string;
  navSize?: string;
  className?: string;
  assetCardThumbnails?: boolean;
  carouselId: string;
  onNavClick?: (index: number) => void;
  fullScreen?: boolean;
  openFullscreen?: Function;
  noHover?: boolean;
  navButtonTestId?: string;
}

function BasicCarousel({
  slides,
  onNavClick,
  carouselId,
  fullScreen,
  openFullscreen,
  assetCardThumbnails,
  className,
  navSize,
  bgColor,
  pd,
  gap,
  scrollToSlide,
  noControls = false,
  noScroll = false,
  minHeight,
  maxHeight,
  slidesPerView = 1,
  noHover = false,
  navButtonTestId,
}: BasicCarouselProps) {
  const totalViews = Math.ceil(slides.length / slidesPerView),
    [currentView, _setCurrentView] = useState<number>(0);
  const setView = (val: number) => {
    _setCurrentView(val);
  };

  const carouselSlides = useRef<HTMLDivElement | null>(null);
  const [navClicked, setNavClicked] = useState<boolean>(false);

  const handleScroll = (): void => {
    const slide =
      carouselSlides.current?.children[
        slidesPerView * (currentView + 1) - 1
      ]?.getBoundingClientRect();
    const slideWidth = slide?.width || 0;
    const overflow =
      carouselSlides.current!.getBoundingClientRect().width * 0.15;
    const breakPoint = slidesPerView * slideWidth;
  };

  useEffect(() => {
    carouselSlides.current?.addEventListener("scroll", handleScroll);

    return () => {
      carouselSlides.current?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slidesPerView]);

  const onNavClickHandler = (dir: string) => (_: any) => {
    var tempValue = currentView;
    if (dir === "right") {
      tempValue = tempValue + 1;
    } else {
      tempValue = tempValue - 1;
    }

    if (tempValue >= totalViews) {
      tempValue = 0;
    } else if (tempValue < 0) {
      tempValue = totalViews;
    }
    if (onNavClick) {
      onNavClick(tempValue);
    }
    setNavClicked(true);
    setView(tempValue);
  };

  const scrollToView = () => {
    const carouselSlidesEl = document.querySelector(
      `#carousel-slides-${carouselId}`
    ) as HTMLDivElement;

    if ((!assetCardThumbnails && !navClicked) || (fullScreen && !navClicked)) {
      const singleSlideScroll =
        carouselSlidesEl.getBoundingClientRect().width *
        (scrollToSlide !== undefined ? scrollToSlide : 0);
      carouselSlidesEl.scrollTo({
        behavior: "smooth",
        left: singleSlideScroll,
      });
    } else {
      const slideEl = carouselSlidesEl.firstChild as HTMLDivElement,
        newScroll =
          currentView * slidesPerView * slideEl.getBoundingClientRect().width;
      carouselSlidesEl.scrollTo({ behavior: "smooth", left: newScroll });
    }
  };

  const createObserver = () => {
    const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
      entries.map((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          entry.target.firstElementChild?.setAttribute("aria-hidden", "false");
          entry.target.firstElementChild?.removeAttribute("tab-index");
        } else {
          entry.target.firstElementChild?.setAttribute("aria-hidden", "true");
          entry.target.firstElementChild?.setAttribute("tab-index", "-1");
        }
      });
    };
    const carousel = document.querySelector(
      `#carousel-slides-${carouselId}`
    ) as HTMLDivElement;
    if (carousel !== null && carousel !== undefined) {
      const cards = Array.prototype.slice.call(
        carousel?.getElementsByClassName("carousel-slide")
      );
      const singleCard = carousel?.firstElementChild;
      const cardWidth = singleCard?.getBoundingClientRect().width;
      if (cardWidth) {
        const options = {
          root: carousel,
          threshold: [0.51],
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        if (cards !== undefined && cards !== null) {
          cards.forEach((card: Element) => {
            observer.observe(card);
          });
        }
      }
    }
  };

  useEffect(() => {
    scrollToView();
  }, [currentView]);

  useEffect(() => {
    if (scrollToSlide !== currentView && scrollToSlide !== undefined) {
      setView(scrollToSlide);
    }
  }, [scrollToSlide]);

  createObserver();

  return (
    <>
      <StyledCarousel
        className={className}
        carouselId={carouselId}
        pd={pd}
        gap={gap}
        $bgColor={bgColor}
        length={slides.length}
        slidesPerView={slidesPerView}
        data-testid="basic-carousel"
      >
        <div
          id={`carousel-slides-${carouselId}`}
          ref={carouselSlides}
          style={{
            overflow: `${noScroll ? "hidden" : "auto"}`,
            minHeight: `${minHeight ? minHeight : "auto"}`,
            maxHeight: `${maxHeight ? maxHeight : "auto"}`,
          }}
        >
          {slides.map((slide, i) => (
            <Fragment key={i}>{slide}</Fragment>
          ))}
        </div>
        {!noControls && (
          <StyledNavigation
            carouselId={carouselId}
            navSize={navSize}
            assetCardThumbnails={assetCardThumbnails}
            length={slides.length}
            slidesPerView={slidesPerView}
          >
            <IconButton
              name="left-caret"
              size="large"
              surfaceType="media"
              kind="lowContrast"
              onClick={onNavClickHandler("left")}
              disabled={currentView === 0}
              fitToIcon={false}
              noHover={noHover}
              testId={navButtonTestId}
            />
            {/* <Body size="large">
              {currentView + 1} / {totalViews}
            </Body> */}
            <IconButton
              name="right-caret"
              size="large"
              surfaceType="media"
              kind="lowContrast"
              onClick={onNavClickHandler("right")}
              disabled={currentView === totalViews - 1}
              fitToIcon={false}
              noHover={noHover}
              testId={navButtonTestId}
            />
          </StyledNavigation>
        )}
        {openFullscreen && (
          <IconButton
            name="fullscreen"
            onClick={openFullscreen}
            className="fullscreen-button"
            size="large"
            ariaLabel="open full screen modal to view image"
            fitToIcon={false}
            testId="open-fullscreen-button"
          />
        )}
      </StyledCarousel>
    </>
  );
}

export default BasicCarousel;
