import { DispatchProps, RootStateProps } from "interfaces";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => useReduxDispatch<DispatchProps>();
export const useSelector: TypedUseSelectorHook<RootStateProps> =
  useReduxSelector;
