import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TypographyTokens } from '@vds-tokens/typography';
import { ColorTokens } from '@vds-tokens/color';
import { withVDSManager } from '@vds-core/utilities';
import { Fonts } from '../../fonts/index.js';
import Typography from '../Typography/Typography.js';

const propTypes = {
  /**
   * Primitive used for the component.
   */
  primitive: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p']),
  /**
   * Viewport the font styling is based on. Desktop and tablet are the same size
   */
  viewport: PropTypes.oneOf(['desktop', 'mobile', 'tablet']),
  /**
   * Content that will be passed to the component.
   */
  children: PropTypes.node,
  /**
   * Color of the text.
   */
  color: PropTypes.oneOf(['#000000', '#FFFFFF', '#ffffff', '#EE0000', '#ee0000', '#F6F6F6', '#f6f6f6', '#D8DADA', '#d8dada', '#A7A7A7', '#a7a7a7', '#6F7171', '#6f7171', '#333333', '#1B1D1F', '#1b1d1f', '#ffece0', '#FFECE0', '#ffcaaa', '#FFCAAA', '#ffa46d', '#FFA46D', '#ff8027', '#FF8027', '#b95319', '#B95319', '#732706', '#561701', '#fff9de', '#FFF9DE', '#fff4bc', '#FFF4BC', '#ffe97a', '#FFE97A', '#fed60e', '#FED60E', '#bc9f0a', '#BC9f0A', '#635305', '#4b3f04', '#4B3F04', '#e3f2fd', '#E3F2FD', '#aad8f9', '#AAD8F9', '#4aabf2', '#4AABF2', '#0089ec', '#0089EC', '#006fc1', '#006FC1', '#003e6c', '#003E6C', '#002c4d', '#002C4D', '#dcf5e6', '#DCF5E6', '#a4e6bd', '#A4E6BD', '#63d48e', '#63D48E', '#00b845', '#00B845', '#008331', '#004b1c', '#004B1C', '#003514', '#febfe8', '#FEBFE8', '#fc89d5', '#FC89D5', '#fb42bc', '#FB42BC', '#b9318b', '#B9318B', '#671b4e', '#671B4E', '#edb9fb', '#EDB9FB', '#e084f9', '#E084F9', '#ce3df5', '#CE3DF5', '#84279e', '#84279E', '#461553', '#FBE4D7', '#fbe4d7', '#ED7000', '#ed7000', '#C44904', '#c44904', '#4A1C02', '#4a1c02', '#FFF4E0', '#fff4e0', '#FFBC3D', '#ffbc3d', '#523C14', '#523c14', '#D6EEFB', '#d6eefb', '#0096E4', '#0096e4', '#0077B4', '#0077b4', '#002B42', '#002b42', '#D6F2E0', '#d6f2e0', '#00AC3E', '#00ac3e', '#008330', '#003614', '#747676' // EOL BRand1.0 Coolgray6
  ]),

  /**
   * The tabIndex of the text.
   */
  tabIndex: PropTypes.number,
  /**
   * Assigns an Id to the component
   */
  id: PropTypes.string,
  /**
   * Adds an aria-labelledby attribute to the component
   */
  ariaLabelledBy: PropTypes.string,
  /**
   * If enabled, Micro will be rendered in bold
   */
  bold: PropTypes.bool,
  /**
   * Assigns an testid to the component
   */
  'data-testid': PropTypes.string
};
const defaultProps = {
  primitive: 'p',
  viewport: 'desktop',
  children: null,
  color: ColorTokens.palette.black.value,
  bold: false
};
const StyledMicro = styled(Typography).withConfig({
  displayName: "StyledMicro",
  componentId: "VDS__sc-13h2lsp-0"
})`
  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
`;
const Micro = props => {
  const {
    children,
    primitive,
    viewport,
    color,
    tabIndex,
    id,
    ariaLabelledBy,
    bold,
    'data-testid': testId
  } = props;
  const viewportToUse = viewport === 'mobile' ? 'mobile' : 'desktop';
  const getFontSize = () => {
    switch (viewportToUse) {
      case 'mobile':
      case 'desktop':
      default:
        return 11;
    }
  };
  const getLineHeight = () => {
    switch (viewportToUse) {
      case 'mobile':
      case 'desktop':
      default:
        return 16;
    }
  };
  const fontWeight = bold ? TypographyTokens.fontweight.bold.value : TypographyTokens.fontweight.regular.value;
  return React.createElement(StyledMicro, {
    fontSize: TypographyTokens.fontsize.micro[getFontSize()].value,
    fontWeight: fontWeight,
    fontFamily: Fonts.VerizonNHGeTX,
    lineheight: TypographyTokens.lineheight.micro[getLineHeight()].value,
    primitive: primitive,
    color: color,
    tabIndex: tabIndex,
    "aria-hidden": tabIndex < 0,
    id: id,
    "aria-labelledby": ariaLabelledBy,
    "data-testid": testId
  }, children);
};
Micro.propTypes = propTypes;
Micro.defaultProps = defaultProps;
var Micro$1 = withVDSManager(Micro);

export { Micro$1 as default };
