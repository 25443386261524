const containerStyleDefault = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%'
};
// Overrides containerStyleDefault properties
const containerStyleAutoHeight = {
  height: 'auto'
};
const viewStyleDefault = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: 'scroll',
  WebkitOverflowScrolling: 'touch'
};
// Overrides viewStyleDefault properties
const viewStyleAutoHeight = {
  position: 'relative',
  top: undefined,
  left: undefined,
  right: undefined,
  bottom: undefined
};
const viewStyleUniversalInitial = {
  overflow: 'hidden',
  marginRight: 0,
  marginBottom: 0
};
const trackHorizontalStyleDefault = {
  position: 'absolute',
  height: 6
};
const trackVerticalStyleDefault = {
  position: 'absolute',
  width: 6
};
const thumbHorizontalStyleDefault = {
  position: 'relative',
  display: 'block',
  height: '100%'
};
const thumbVerticalStyleDefault = {
  position: 'relative',
  display: 'block',
  width: '100%'
};
const disableSelectStyle = {
  userSelect: 'none'
};
const disableSelectStyleReset = {
  userSelect: ''
};

export { containerStyleAutoHeight, containerStyleDefault, disableSelectStyle, disableSelectStyleReset, thumbHorizontalStyleDefault, thumbVerticalStyleDefault, trackHorizontalStyleDefault, trackVerticalStyleDefault, viewStyleAutoHeight, viewStyleDefault, viewStyleUniversalInitial };
