import React, { Component } from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import TooltipPortal from './TooltipPortal.js';
import TooltipDialogOverlay from './TooltipDialogOverlay.js';
import { calculateRem, withVDSManager } from '@vds-core/utilities';
import { Fonts, Body } from '@vds-core/typography';
import { ColorTokens } from '@vds-tokens/color';
import { TypographyTokens } from '@vds-tokens/typography';
import screenReader from './util/screen-reader.js';

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  closeButtonText: PropTypes.string,
  onClick: PropTypes.func,
  dialogOpened: PropTypes.bool,
  unmounted: PropTypes.bool
};
const defaultProps = {
  onClick: undefined,
  closeButtonText: 'Close'
};
const Wrapper = styled.div.withConfig({
  displayName: "Wrapper",
  componentId: "VDS__sc-1j52l2j-0"
})`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
`;
const dialogFadeIn = () => keyframes`
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const dialogFadeOut = () => keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
`;
function _calcAnimation(animation) {
  if (animation === 'in') {
    return dialogFadeIn();
  } else {
    return dialogFadeOut();
  }
}
/**
 * @ignore
 */
const Dialog = styled.div.withConfig({
  displayName: "Dialog",
  componentId: "VDS__sc-1j52l2j-1"
})`
  padding-top: 16px;
  background-color: ${({
  surface
}) => surface === 'dark' ? ColorTokens.background.primary.dark.value : ColorTokens.background.primary.light.value};
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  flex-flow: column-reverse nowrap;
  justify-content: space-between;
  min-height: ${calculateRem(96)};
  max-height: ${calculateRem(312)};
  position: relative;
  width: ${calculateRem(296)};
  outline: none;
  z-index: 1000;
  border-radius: 8px;
  ${({
  dialogOpened,
  disableAnimation
}) => dialogOpened && !disableAnimation && css`
      animation: 0.2s cubic-bezier(0.37, 0, 0.63, 1) both 0s
        ${_calcAnimation('in')};
    `};

  ${({
  unmounted,
  disableAnimation
}) => unmounted && !disableAnimation && css`
      animation: 0.1s cubic-bezier(0.37, 0, 0.63, 1) both 0s
        ${_calcAnimation('out')};
    `};
`;
const StyledHeading = styled.span.withConfig({
  displayName: "StyledHeading",
  componentId: "VDS__sc-1j52l2j-2"
})`
  display: flex;
  max-width: 100%;
  color: ${({
  surface
}) => surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value};
  font-family: ${Fonts.VerizonNHGeDS};
  font-size: ${TypographyTokens.fontsize.title[20].value};
  line-height: ${TypographyTokens.lineheight.title[24].value};
  font-weight: ${TypographyTokens.fontweight.bold.value};
  padding-bottom: 8px;
  margin: 0;
`;
const StyledBody = styled.span.withConfig({
  displayName: "StyledBody",
  componentId: "VDS__sc-1j52l2j-3"
})`
  display: flex;
  width: 100%;
  color: ${({
  surface
}) => surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value};
  font-family: ${Fonts.VerizonNHGeDS};
  font-size: ${TypographyTokens.fontsize.title[16].value};
  line-height: ${TypographyTokens.lineheight.title[20].value};
  letter-spacing: ${TypographyTokens.letterspacing.wide.value};
  padding-bottom: 16px;
`;
const DialogWrapper = styled.div.withConfig({
  displayName: "DialogWrapper",
  componentId: "VDS__sc-1j52l2j-4"
})`
  display: flex;
  overflow-y: scroll;
  padding: 0px 16px 0px 16px;
  border-bottom: ${calculateRem(1)} solid
    ${({
  surface
}) => surface === 'dark' ? ColorTokens.elements.lowcontrast.ondark.value : ColorTokens.elements.lowcontrast.onlight.value};
`;
const ContentContainer = styled.div.withConfig({
  displayName: "ContentContainer",
  componentId: "VDS__sc-1j52l2j-5"
})`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: calc(312px - 44px - 16px - 1px);
`;
const TooltipButton = styled.button.withConfig({
  displayName: "TooltipButton",
  componentId: "VDS__sc-1j52l2j-6"
})`
  display: flex;
  width: 100%;
  height: ${calculateRem(44)};
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  position: relative;
  outline: none;
`;
const ButtonTextContainer = styled.span.withConfig({
  displayName: "ButtonTextContainer",
  componentId: "VDS__sc-1j52l2j-7"
})`
  position: relative;
`;
/**
 * @ignore
 */
class TooltipDialog extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      expandedStateText: 'expanded! ' //added this state to announce expanded state once the modal opened.
    };
  }

  componentDidMount() {
    this.props.isAndroidDevice && screenReader.OpenDialog();
    setTimeout(() => {
      this.setState({
        expandedStateText: ''
      });
    }, 500);
  }
  componentWillUnmount() {
    this.props.isAndroidDevice && screenReader.CloseDialog();
  }
  render() {
    const {
      title,
      body,
      onClick,
      surface,
      closeButtonText,
      dialogOpened,
      unmounted,
      dialogId
    } = this.props;
    return React.createElement(TooltipPortal, null, React.createElement(Wrapper, null, React.createElement(FocusLock, {
      disabled: false,
      returnFocus: true
    }, React.createElement(TooltipDialogOverlay, {
      dialogOpened: dialogOpened,
      opened: dialogOpened,
      surface: surface,
      viewport: "desktop",
      unmounted: unmounted
    }, React.createElement(Dialog, {
      surface: surface,
      dialogOpened: dialogOpened,
      unmounted: unmounted,
      id: dialogId,
      role: "dialog",
      "aria-modal": true
    }, React.createElement(TooltipButton, {
      surface: surface,
      onClick: onClick,
      "aria-label": `${this.state.expandedStateText}${closeButtonText}`
    }, React.createElement(ButtonTextContainer, null, React.createElement(Body, {
      size: "large",
      viewport: "mobile",
      color: surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value
    }, closeButtonText))), React.createElement(DialogWrapper, {
      surface: surface
    }, React.createElement(ContentContainer, null, !!title && React.createElement(StyledHeading, {
      surface: surface
    }, title), React.createElement(StyledBody, {
      surface: surface
    }, body))))))));
  }
}
TooltipDialog.propTypes = propTypes;
TooltipDialog.defaultProps = defaultProps;
var TooltipDialog$1 = withVDSManager(TooltipDialog);

export { TooltipDialog$1 as default };
