import { BadgeProps, Badge as VdsBadge } from "@vds/badges";

// interface BadgeProps {
//   children: string;
// }

// interface VdsBadgeProps {
//   fillColor?: string;
// }

const Badge = ({ children, ...rest }: BadgeProps) => {
  return <VdsBadge {...rest}>{children}</VdsBadge>;
};

export default Badge;
