import IconButton from "components/buttons/IconButton/IconButton";
import styled from "styled-components";

export const IconWrapper = styled.button`
  background: transparent;
  border: none;
  margin-left: 0.7ch;

  p {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
    p {
      text-decoration-thickness: 2px;
    }
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
`;
