import styled from "styled-components";
import { zIndex } from "theme/variables";

export const StyledWrapper = styled.div`
  position: relative;
  border-radius: 0.5rem;
`;

export const StyledTile = styled.button`
  z-index: ${zIndex.notBottom};
  position: relative;

  display: grid;

  width: 100%;
  min-width: 225px;
  height: 11.25rem;

  border: transparent 1px solid;
  border-radius: 0.5rem;
  overflow: hidden;

  background-color: #F3EDE0;
  color: #E13331;

  > a {
    text-decoration: none;
    color: inherit;

    width: 100%;
    height: 100%;
    color: #E13331;

    display: grid;
  }

  svg {
    align-self: end;
    margin: 0.5rem;
  }

  p {
    text-align: left;
    margin: 0.5rem;
    color: #E13331;
    //color: white;
    //transition: color 0.25s;
    font-size: 0.875rem;
  }

  &:not(.expanded):hover {
    cursor: pointer;
    border: black 1px solid;
    background-color: white;
  }
  &.expanded {
    position: absolute;
    height: 0.25rem;
    border: none;
    padding: 0;
    > svg {
      opacity: 0;
      transition: opacity 0.25s;
    }
  }

  transition: color 0.25s, background-color 0.25s, height 0.25s;
`;
