import {useEffect} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import paths from "routing/paths";
import {
  FlexBox,
  GenericError,
  Loader,
  NavBar,
  Notification,
} from "components";
import {ProtectedWrapper} from "./ProtectedPage.module";
import {
  useGetGlobalQuery,
} from "services/api/api.slice";
import Footer from "../Footer/Footer";
import SiteAlertBanner from "../../SiteAlertBanner/SiteAlertBanner";
import {ParsedUserJWT} from "interfaces/Cognito";
import {useDispatch, useSelector} from "hooks/redux";
import {
  currentUserJwt,
  selectCurrentUserDetails,
} from "services/auth/auth.slice";
import PageNotFound from "views/PageNotFound/PageNotFound";
import {Backdrop} from "@mui/material";
import {Loader as VdsLoader} from "@vds/loaders";
import {LoaderNavBar} from "../NavBar/NavBar.module";
import USER_ROLES from "constants/userRoles";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDateFns} from "@mui/x-date-pickers-pro/AdapterDateFns";
import {handleError} from "utils";
import {compose} from "@reduxjs/toolkit";
import {AuthProps} from "HOCs";
import {withAuth} from "HOCs/withAuth";

export const fallback = (showNav?: boolean) =>
  showNav ? (
    <FlexBox height="100vh">
      <LoaderNavBar id="loader-nav-bar"/>
      <Backdrop open sx={{position: "relative", flexGrow: 1}}>
        <VdsLoader active/>
      </Backdrop>
    </FlexBox>
  ) : (
    <Loader active/>
  );

const DataWrapper = ({guardedRoute}: { guardedRoute: boolean }) => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  // Need the slash on the end because guidelines routing appends the slash.
  // NOTE: If you ever need to check any of the other paths with the BC UX application, they will not have the slash appended.
  const guidelinesPath = `${paths.GUIDELINES}/`;

  const {
    data: global,
    isFetching: fetchingGlobal,
    isError: globalError,
  } = useGetGlobalQuery();

  const handleUserDetails = () => {
    dispatch(currentUserJwt());
  };

  useEffect(() => {
    handleUserDetails();
  }, []);

  const isError = globalError;

  return (
    <ProtectedWrapper guidelinesPage={pathname.startsWith(guidelinesPath)}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {global?.siteAlertBanner && (
          <SiteAlertBanner content={global?.siteAlertBanner?.message}/>
        )}
        <NavBar/>

        {guardedRoute ? (
          <PageNotFound/>
        ) : fetchingGlobal ? (
          fallback()
        ) : isError ? (
          <GenericError/>
        ) : (
          !pathname.startsWith(guidelinesPath) && <Outlet/>
        )}
        {!pathname.startsWith(guidelinesPath) &&
          <>
            <Notification/>
            <Footer/>
          </>
        }
      </LocalizationProvider>
    </ProtectedWrapper>
  );
};

const RouteGuard = ({path}: { path: string }) => {
  const dispatch = useDispatch();
  const handleUserDetails = () => {
    try {
      dispatch(currentUserJwt());
    } catch (err: any) {
      handleError(err);
    }
  };

  const userDetails: ParsedUserJWT | null | undefined = useSelector(
    selectCurrentUserDetails
  );
  useEffect(() => {
    handleUserDetails();
  }, []);

  const guardedRoute =
    path.startsWith(paths.ADMIN) && userDetails?.role !== USER_ROLES.ADMIN;

  return <DataWrapper guardedRoute={guardedRoute}/>;
};

const ProtectedPage = ({auth}: AuthProps) => {
  const location = useLocation();
  return auth === null ? (
    fallback(true)
  ) : auth ? (
    <RouteGuard path={location.pathname}/>
  ) : (
    <Navigate to={paths.SIGNIN} state={{from: location}}/>
  );
};

export default compose(withAuth())(ProtectedPage);
