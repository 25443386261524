import React from 'react';
import PropTypes from 'prop-types';
import CardData from './data.json.js';

/* istanbul ignore file */
const propTypes = {
  data: PropTypes.object
};
const defaultProps = {
  data: CardData
};
function mapChildren(children) {
  return children.length > 0 && children.map((child, index) => {
    let attr = child.attributes;
    if (child.name === 'g') {
      return React.createElement("g", {
        fill: attr.fill,
        key: `${child.name}${index}`
      }, child.children.length > 0 && mapChildren(child.children));
    }
    if (child.name === 'path') {
      return React.createElement("path", {
        stroke: attr.stroke,
        fill: attr.fill,
        key: `${child.name}${index}`,
        d: attr.d
      }, child.children.length > 0 && mapChildren(child.children));
    }
    if (child.name === 'circle') {
      return React.createElement("circle", {
        fill: attr.fill,
        id: attr.id,
        key: `${child.name}${index}`,
        cx: attr.cx,
        cy: attr.cy,
        r: attr.r,
        opacity: attr.opacity
      }, child.children.length > 0 && mapChildren(child.children));
    }
    if (child.name === 'rect') {
      return React.createElement("rect", {
        fill: attr.fill,
        key: `${child.name}${index}`,
        x: attr.x,
        y: attr.y,
        ry: attr.ry,
        rx: attr.rx,
        transform: attr.transform,
        width: attr.width,
        height: attr.height
      }, child.children.length > 0 && mapChildren(child.children));
    }
    if (child.name === 'polyline') {
      return React.createElement("polyline", {
        fill: attr.fill,
        key: `${child.name}${index}`,
        points: attr.points
      }, child.children.length > 0 && mapChildren(child.children));
    }
    if (child.name === 'line') {
      return React.createElement("line", {
        fill: attr.fill,
        key: `${child.name}${index}`,
        x1: attr.x1,
        x2: attr.x2,
        y1: attr.y1,
        y2: attr.y2
      }, child.children.length > 0 && mapChildren(child.children));
    }
    if (child.name === 'polygon') {
      return React.createElement("polygon", {
        fill: attr.fill,
        key: `${child.name}${index}`,
        points: attr.points
      }, child.children.length > 0 && mapChildren(child.children));
    }
    if (child.name === 'linearGradient') {
      return React.createElement("linearGradient", {
        key: `${child.name}${index}`,
        id: attr.id,
        gradientUnits: attr.gradientUnits,
        x1: attr.x1,
        y1: attr.y1,
        x2: attr.x2,
        y2: attr.y2,
        gradientTransform: attr.gradientTransform
      }, child.children.length > 0 && mapChildren(child.children));
    }
    if (child.name === 'stop') {
      return React.createElement("stop", {
        offset: attr.offset,
        stopColor: attr['stop-color'],
        key: `${child.name}${index}`
      }, child.children.length > 0 && mapChildren(child.children));
    }
    if (child.name === 'defs') {
      return React.createElement("defs", {
        key: `${child.name}${index}`
      }, child.children.length > 0 && mapChildren(child.children));
    }
  });
}
const CreditCardIcon = props => {
  const {
    data,
    name,
    surface
  } = props;
  let icon;
  const invertedCards = ['dinersClub', 'generic', 'placeholder', 'visa', 'securityCode', 'securityCodeAmex'];
  if (surface === 'dark' && invertedCards.includes(name)) icon = data[`${name}-inverted`];else icon = data[name];
  return React.createElement("svg", {
    data: data,
    viewBox: icon.attributes.viewBox,
    width: "100%",
    style: {
      backgroundColor: name === 'discover' && surface === 'dark' && '#FFFFFF'
    }
  }, mapChildren(icon.children));
};
CreditCardIcon.propTypes = propTypes;
CreditCardIcon.defaultProps = defaultProps;

export { CreditCardIcon as default };
