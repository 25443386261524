import Image from "components/layouts/Image/Image";
import Title from "components/typography/Title/Title";
import { verizonLogo } from "assets";
import { HeaderWrapper } from "./UnprotectedHeader.module";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {supportsStorage} from "../../../utils";

const UnprotectedHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (supportsStorage("localStorage") && !localStorage.getItem("pageRefreshed")) {
      if (localStorage.getItem("prevRoute")?.startsWith('/guidelines/') && !location.pathname.startsWith('/guidelines/')) {
        localStorage.setItem("pageRefreshed", "true");
        navigate(0);
      }
    }
  }, [location.pathname]);
  
  return (
    <HeaderWrapper>
      <Image src={verizonLogo} width="13.5rem" />
      <Title size="XLarge" bold pd="3rem 0">
        Brand Central
      </Title>
    </HeaderWrapper>
  );
};

export default UnprotectedHeader;
