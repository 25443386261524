import styled from "styled-components";
import { Input } from "@vds/inputs";
import { InputProps } from "interfaces";
import { InputProps as VdsInputProps } from "@vds/inputs";
// interface InputProps {
//   value: string;
//   onFocus?: Function;
//   onBlur?: Function;
//   pd?: string;
//   maxWidth?: string;
// }
export const StyledInput = styled(Input)<InputProps & VdsInputProps>`
  span[type="password"] {
    div:nth-of-type(2) {
      padding: 0px 0.6875rem 0px 0px;
    }
  }
  padding: ${(props: InputProps) => props.pd || 0};
  max-width: ${(props: InputProps) => props.maxWidth || "none"};

  &.disabled {
    label > p {
      color: #000000;
    }
  }
`;
