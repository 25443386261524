import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  handleMfaFlow,
  handleSignInFlow,
  handleSignUpFlow,
  handleForgotPasswordFlow,
} from "services";
import { AsyncThunkProps, RootStateProps } from "interfaces";
import { CognitoUserInterface } from "interfaces/Cognito";
import ASYNC_STATUS from "constants/asyncStatus";
import AUTH_STATE from "constants/authState";
import MFA_CHOICE from "constants/mfaChoice";
import {
  handleConfigureSMSFlow,
  handleSkipSMSFlow,
  getCurrentUserDetails,
  handleChangePasswordFlow,
} from "./auth.api";
import {
  CognitoSignInProps,
  ParsedUserJWT,
  CognitoChangePasswordProps,
} from "interfaces/Cognito";
import mfaChoice from "constants/mfaChoice";
import { NotificationProps } from "services/ui/ui.slice";

export interface AuthState {
  status: string;
  authState: string;
  user: CognitoUserInterface | null;
  mfaChoice: string | null;
  notification: NotificationProps;
  currentUserDetails?: ParsedUserJWT | null;
}

const initialNotificationState = { type: undefined, message: "" },
  initialState: AuthState = {
    status: ASYNC_STATUS.IDLE,
    authState: AUTH_STATE.NA,
    mfaChoice: mfaChoice.EMAIL,
    user: null,
    notification: initialNotificationState,
    currentUserDetails: null,
  };

export const signin = createAsyncThunk(
  "auth/signin",
  async (props: CognitoSignInProps, { rejectWithValue }) => {
    try {
      const response = await handleSignInFlow(props);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const mfa = createAsyncThunk(
  "auth/mfa",
  async (props: any, { getState, rejectWithValue }: AsyncThunkProps) => {
    const state = getState();
    try {
      const response = await handleMfaFlow({
        ...props,
        currentState: state.auth.authState,
        user: state.auth.user,
        choice: props.choice ? props.choice : state.auth.mfaChoice,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (props: any, { getState, rejectWithValue }: AsyncThunkProps) => {
    const state = getState();
    try {
      const response = await handleForgotPasswordFlow({
        ...props,
        currentState: props.resend ? AUTH_STATE.NA : state.auth.authState,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (props: any, { getState, rejectWithValue }: AsyncThunkProps) => {
    const state = getState();
    try {
      const response = await handleSignUpFlow({
        ...props,
        code: props.code,
        user: state.auth.user,
        currentState: props.resend
          ? AUTH_STATE.SIGNUP_RESEND
          : state.auth.authState,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const mfaSms = createAsyncThunk(
  "auth/mfaSms",
  async (
    props: { phoneNumber: string },
    { getState, rejectWithValue }: AsyncThunkProps
  ) => {
    const state = getState();
    try {
      const response = await handleConfigureSMSFlow({
        ...props,
        user: state.auth.user,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const skipSms = createAsyncThunk(
  "auth/skipSms",
  async (_, { rejectWithValue, getState }: AsyncThunkProps) => {
    const state = getState();
    try {
      const response = await handleSkipSMSFlow(state.auth.user);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const currentUserJwt = createAsyncThunk(
  "auth/currentUserJwt",
  async (_, { rejectWithValue }: AsyncThunkProps) => {
    try {
      const response = await getCurrentUserDetails();
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (
    props: CognitoChangePasswordProps,
    { rejectWithValue }: AsyncThunkProps
  ) => {
    try {
      const response = await handleChangePasswordFlow(props);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {},
    skipSmsFinal: (state) => {
      state.authState = AUTH_STATE.AUTHENTICATED;
      state.status = ASYNC_STATUS.IDLE;
    },
    resetAuthNotification: (state) => {
      state.status = ASYNC_STATUS.IDLE;
      state.notification = initialNotificationState;
    },
    resetAuthState: (state) => {
      state.status = ASYNC_STATUS.IDLE;
      state.authState = AUTH_STATE.NA;
      state.mfaChoice = mfaChoice.EMAIL;
      state.user = null;
      state.notification = initialNotificationState;
      state.currentUserDetails = null;
    },
    resetAuthStateValue: (state) => {
      return { ...state, authState: AUTH_STATE.NA };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: ASYNC_STATUS.IDLE,
        };
      })
      .addCase(mfa.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: ASYNC_STATUS.IDLE,
          mfaChoice: action.meta.arg.choice || state.mfaChoice,
        };
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: ASYNC_STATUS.IDLE,
        };
      })
      .addCase(signUp.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: ASYNC_STATUS.IDLE,
        };
      })
      .addCase(mfaSms.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          mfaChoice: MFA_CHOICE.SMS,
          status: ASYNC_STATUS.IDLE,
        };
      })
      .addCase(skipSms.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: ASYNC_STATUS.IDLE,
        };
      })
      .addCase(currentUserJwt.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: ASYNC_STATUS.IDLE,
        };
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: ASYNC_STATUS.IDLE,
        };
      })
      .addMatcher(
        isAnyOf(
          signin.pending,
          mfa.pending,
          forgotPassword.pending,
          signUp.pending,
          mfaSms.pending,
          skipSms.pending,
          currentUserJwt.pending,
          changePassword.pending
        ),
        (state) => {
          state.status = ASYNC_STATUS.LOADING;
        }
      )
      .addMatcher(
        isAnyOf(
          signin.rejected,
          mfa.rejected,
          forgotPassword.rejected,
          signUp.rejected,
          mfaSms.rejected,
          skipSms.rejected,
          currentUserJwt.rejected
        ),
        (state, action: { payload: any }) => {
          state.status = ASYNC_STATUS.FAILED;
          state.notification = {
            type: "error",
            message: action.payload,
          };
        }
      );
  },
});

export const {
  logout,
  skipSmsFinal,
  resetAuthState,
  resetAuthStateValue,
  resetAuthNotification,
} = authSlice.actions;

export const selectStatus = (state: RootStateProps) => state.auth.status;
export const selectAuthState = (state: RootStateProps) => state.auth.authState;
export const selectUser = (state: RootStateProps) => state.auth.user;
export const selectMfaChoice = (state: RootStateProps) => state.auth.mfaChoice;
export const selectAuthNotification = (state: RootStateProps) =>
  state.auth.notification;
export const selectCurrentUserDetails = (state: RootStateProps) =>
  state.auth.currentUserDetails;

export const authReducer = authSlice.reducer;
