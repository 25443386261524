import { useDispatch } from "hooks/redux";

export const withAction =
  (action: any, name?: string) => (WrappedComponent: any) => {
    const Component = (props: any) => {
      const dispatch = useDispatch(),
        dispatchAction = (props: any) => dispatch(action(props));
      return (
        <WrappedComponent
          {...{ [name || "action"]: dispatchAction }}
          {...props}
        />
      );
    };

    return Component;
  };

// export default withAction;
