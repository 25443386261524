export interface FormValues {
  isPhotographyAsset: boolean | null;
  audience: string;
  channelId: string;
  otherChannel: string;
  requestedUseId: string;
  otherRequestedUse: string;
  projectName: string;
  startDate: Date | null;
  endDate: Date | null;
}
export default function mapPropsToValues() {
  return {
    isPhotographyAsset: null,
    audience: "",
    channelId: "",
    otherChannel: "",
    requestedUseId: "",
    otherRequestedUse: "",
    projectName: "",
    startDate: null,
    endDate: null,
  };
}
