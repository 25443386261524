import React from 'react';
import PropTypes from 'prop-types';
import RadioBox from './RadioBox.js';
import { RadioBoxGroup as RadioBoxGroup$1 } from '@vds-core/radio-boxes';

const propTypes = {
  /**
   * If provided, the RadioBoxGroup will be rendered with transparent background.
   */
  transparentBackground: PropTypes.bool,
  /**
   * If provided, will set the height for all radio boxes in the group when orientation is vertical
   */
  childHeight: PropTypes.string
};
const defaultProps = {
  transparentBackground: false
};
const RadioBoxGroup = function (props) {
  return React.createElement(RadioBoxGroup$1, Object.assign({}, props, {
    RadioBox: RadioBox
  }));
};
RadioBoxGroup.propTypes = propTypes;
RadioBoxGroup.defaultProps = defaultProps;

export { RadioBoxGroup as default };
