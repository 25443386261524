import styled from "styled-components";
import { SectionProps } from "./Section";
import { minTablet } from "theme/variables";

export const StyledSection = styled.section<SectionProps>`
  display: block;

  overflow: ${(props) => props.overflow || ""};
  min-height: ${(props) => props.minHeight || ""};

  margin: ${(props) => props.mg || ""};
  /* padding: ${(props) => props.pd || "1.25rem 1.25rem 5rem"}; */
  @media screen and (max-width: ${minTablet - 1}px) {
    padding: ${(props) => props.pd || "1.25rem 1rem 5rem"};
  }

  @media screen and (min-width: ${minTablet}px) {
    padding: ${(props) => props.pd || "1.25rem 1.25rem 5rem"};
  }

  color: ${(props) => props.color || ""};
  background: ${(props) => props.background || ""};
`;

export const StyledLayoutContainer = styled.div<SectionProps>`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;
