import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { routeList } from "./routeList";
import { RouteProps } from "interfaces";
import { ProtectedPage, UnprotectedPage } from "components";
import ErrorBoundary from "views/ErrorBoundary/ErrorBoundary";
import Error404 from "views/Error404/Error404";

const unprotectedRoutes: Array<RouteObject> = routeList.unprotected.map(
    (route: RouteProps) => ({ ...route })
  ),
  protectedRoutes: Array<RouteObject> = routeList.protected.map(
    (route: RouteProps) => {
      return { ...route };
    }
  );

export const Router = () => {
  const router = createBrowserRouter([
    {
      element: <ProtectedPage />,
      children: protectedRoutes,
      errorElement: <ErrorBoundary />,
    },
    {
      element: <UnprotectedPage />,
      children: unprotectedRoutes,
      errorElement: <ErrorBoundary />,
    },
    {
      element: <ProtectedPage />,
      path: "/guidelines/*",
    },
    {
      element: <ProtectedPage />,
      path: "/vzso-bc-access-requests-test-0/*",
    },
    {
      element: <Error404 />,
      path: "*",
    },
  ]);

  return <RouterProvider router={router} />;
};
