import { useNavigate } from "react-router-dom";
import { Button, ButtonProps } from "@vds/buttons";

export interface LinkButtonProps {
  surface?: "light" | "dark";
  label: string;
  use?: "primary" | "secondary";
}

export interface StyledLinkButtonProps {
  to?: string | any;
  onClick?: Function;
  size?: ButtonProps["size"];
  className?: string;
  external?: boolean;
}

const LinkButton = ({
  label,
  surface = "dark",
  size = "small",
  to,
  use = "secondary",
  onClick,
  external,
}: LinkButtonProps & StyledLinkButtonProps) => {
  const navigate = useNavigate(),
    handleNavigation = () => {
      if (external) {
        window.open(`${to}`, "_blank");
      } else {
        to ? navigate(to) : onClick && onClick();
      }
    };
  return (
    <Button
      onClick={handleNavigation}
      size={size}
      surface={surface}
      use={use}
      width="fit-content"
    >
      {label}
    </Button>
  );
};

export default LinkButton;
