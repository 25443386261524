import { useState, useEffect } from "react";
import {Link, useLocation} from "react-router-dom";
import { IconButton, Image, FlexBox } from "components";
import { StyledAssetCard } from "./AssetCardSmall.module";
import {AssetFile, AssetFileGroup, UnifiedSearchResultProps} from "interfaces";
import {
  FavoriteModal,
  FullscreenImgCarouselModal,
  ShareModal,
} from "features";
import { getPrimaryFile } from "utils/assetThumbnails";
import {
  useLazyGetAssetDownloadUrlQuery,
  useTrackingMutation,
} from "services/api/api.slice";
import { openFileDownload } from "utils/assetFiles";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import UsageRightsModal from "../usageRightsModal/UsageRightsModal";
import {buildFinalTrackingObject, externalRoute, getThumbnails} from "utils";
import DownloadOptionsModal from "../downloadOptionsModal/DownloadOptionsModal";
import paths from "../../routing/paths";

export interface AssetCardProps {
  item: UnifiedSearchResultProps;
  assetId: string;
  title: string;
  isFavorite: boolean;
  trackingData?: Record<string, unknown>;
}

const AssetCardSmall = ({
  item,
  assetId,
  title,
  trackingData,
  isFavorite,
}: AssetCardProps) => {
  const [trackAction] = useTrackingMutation();

  const location = useLocation();

  const trackEvent = (action: string): void => {
    const payloadData = {
      version: "1.0.0",
      context: trackingData,
      type: "asset-card",
      action,
      entityType: "asset",
      id: item.id,
    };
    const trackingObject = buildFinalTrackingObject(
      payloadData,
      location.pathname
    );
    try {
      trackAction(trackingObject);
    } catch (error: any) {
      console.error(error);
    }
  };

  const [favoriteOpen, _openFavorite] = useState(false),
    [isFavoriteAsset, _isFavoriteAsset] = useState(isFavorite),
    openFavorite = () => {
      _openFavorite(true);
    },
    closeFavorite = (isFavoriteAsset: boolean) => {
      _isFavoriteAsset(isFavoriteAsset);
      _openFavorite(false);
    },
    completeFavorite = (isFavoriteAsset: boolean) => {
      trackEvent("favorite");
      closeFavorite(isFavoriteAsset);
    };

  const [fullWrapFocused, setFullWrapFocused] = useState<boolean>(false),
    focusFullWrap = () => {
      setFullWrapFocused(true);
    },
    blurFullWrap = () => {
      setFullWrapFocused(false);
    };

  const [activeThumbnail, setActiveThumbnail] = useState<number>(0),
    [thumbnails, _thumbnails] = useState<string[]>([]),
    updateThumbnail = (index: number) => {
      setActiveThumbnail(index);
    };

  const [fullscreenOpen, setFullscreenOpen] = useState(false),
    openFullscreen = () => {
      setFullscreenOpen(true);
    },
    closeFullscreen = (index: number) => {
      updateThumbnail(index);
      setFullscreenOpen(false);
    };

  const [groups, setGroups] = useState<AssetFileGroup[] | null>(null);
  const getFileGroups = (item: UnifiedSearchResultProps): void => {
    if (!item.files) {
      return;
    }

    const groupNames: string[] = [];

    for (const file of item.files) {
      if (file.groupName && file.groupName.trim().length > 0) {
        if (!groupNames.includes(file.groupName)) {
          groupNames.push(file.groupName);
        }
      }
    }

    const fileGroups: AssetFileGroup[] = [];

    if (groupNames.length > 0) {
      for (const groupName of groupNames) {
        const groupFiles = item.files.filter(
          (file) => file.groupName === groupName && !!file.currentVersionId
        );

        if (groupFiles.length) {
          fileGroups.push({
            name: groupName,
            files: groupFiles,
            default: getPrimaryFile(groupFiles),
          });
        }
      }
    }

    setGroups(fileGroups);
  };

  const [carouselFiles, setCarouselFiles] = useState<AssetFile[] | null>(null);

  const getCarouselFiles = () => {
    let tempFiles: AssetFile[] = [];
    if (item.files) {
      if (groups && groups.length > 0) {
        const allFiles = item.files.map((file: any) => file);
        const groupFiles = groups.map((group) => group.default);
        if (allFiles.length) {
          allFiles.forEach((assetFile: any) => {
            if (
              !groupFiles.find((file) => file.groupName === assetFile.groupName)
            ) {
              tempFiles.push(assetFile);
            }
          });
        }

        groupFiles.forEach((file) => {
          tempFiles.push(file);
        });

        const tempGroupFiles = tempFiles.filter(
          (file) => file.thumbnails.isImage
        );
        if (tempGroupFiles.length !== 0) {
          tempFiles = [...tempGroupFiles];
        }

        setCarouselFiles(tempFiles);
      } else {
        // BUG: if you've just created an asset, the file won't have a currentVersionId and nothing will be displayed
        item.files.forEach((file) => {
          tempFiles.push(file);
        });
        tempFiles = tempFiles.filter((file) => file.thumbnails.isImage);
        if (tempFiles.length === 0) {
          tempFiles = [];
          item.files.forEach((file) => {
            tempFiles.push(file);
          });
        }

        setCarouselFiles(tempFiles);
      }
    }
  };

  const [singleFiles, _singleFiles] = useState<AssetFile[] | null>(null);

  const getSingleFiles = () => {
    if (carouselFiles !== null && carouselFiles?.length > 0) {
      let tempSingleFiles: AssetFile[] = [];
      carouselFiles.forEach((file) => {
        if (file.groupName === null && !!file.currentVersionId) {
          tempSingleFiles.push(file);
        }
      });
      if (tempSingleFiles.length > 0) {
        _singleFiles(tempSingleFiles);
      }
    }
  };

  useEffect(() => {
    if (
      groups !== null &&
      groups?.length > 0 &&
      carouselFiles &&
      carouselFiles.length > 0
    ) {
      getSingleFiles();
    }
  }, [carouselFiles]);

  const getAssetThumbnails = () => {
    if (carouselFiles && carouselFiles.length > 0) {
      _thumbnails(getThumbnails(carouselFiles, "medium"));
    }
  };

  useEffect(() => {
    if (item) {
      getFileGroups(item);
    }
  }, [item]);

  useEffect(() => {
    if (item.files && groups) {
      getCarouselFiles();
    }
  }, [groups]);

  useEffect(() => {
    if (carouselFiles && carouselFiles.length > 0) {
      getAssetThumbnails();
    }
  }, [carouselFiles]);

  const [shareOpen, _shareOpen] = useState(false),
    openShare = () => {
      _shareOpen(true);
    },
    closeShare = () => {
      _shareOpen(false);
    },
    completeShare = () => {
      trackEvent("share");
      closeShare();
    };
  
  const getLinkUrl = (): string => {
    let itemUrl = item.url;
    if (item.searchType === 'template') {
      itemUrl = `/templates/details/${item.assetId}`;
    } else if (item.searchType === 'retail') {
      itemUrl = `/retail/branded-environments/design-advisories/details/${item.assetId}`;
    } else if (item.searchType === 'asset') {
      itemUrl = `/assets/details/${item.assetId}`;
    } else if (item.searchType === 'training') {
      itemUrl = `${paths.TRAINING_VIDEOS}/${item.playlistSlug}/${item.videoSlug}`;
    }
    return itemUrl;
  };

  const onAssetLinkClick = (): void => {
    trackEvent("view");
  };

  const onFullscreenOpenClick = () => {
    openFullscreen();
  };

  const [
    getAssetUrl,
    {
      data: assetResult,
      isFetching: assetFetching,
      isError: assetError,
      error: assetErrorMsg,
      isSuccess: assetSuccess,
    },
  ] = useLazyGetAssetDownloadUrlQuery();

  const callGetAssetUrl = (assetId: string) => {
    getAssetUrl(assetId);
  };

  const [rightsAccepted, setRightsAccepted] = useState<boolean>(
      item.usageRightsCopy &&
        item.userActions?.hasAcceptedUsageRights !== null
        ? item.userActions?.hasAcceptedUsageRights!
        : true
    ),
    updateUsageRights = (value: boolean) => {
      setRightsAccepted(value);
    };

  const [usageRightsModalOpen, setUsageRightsModalOpen] =
      useState<boolean>(false),
    openUsageRightsModal = () => {
      setUsageRightsModalOpen(true);
    },
    completeUsageRightsModal = () => {
      updateUsageRights(true);
      setUsageRightsModalOpen(false);
      callGetAssetUrl(assetId);
    },
    closeUsageRightsModal = () => {
      setUsageRightsModalOpen(false);
    };

  const [showDownloadOptionsModal, setShowDownloadOptionsModal] =
      useState<boolean>(false),
    openDownloadOptionsModal = () => {
      setShowDownloadOptionsModal(true);
    },
    closeDownloadOptionsModal = () => {
      setShowDownloadOptionsModal(false);
    },
    completeDownloadOptionsModal = () => {
      closeDownloadOptionsModal();
      updateUsageRights(true);
    };

  const onDownloadAssetClick = () => {
    if (
      item.usageRightsCopy &&
      item.usageRightsCopy.length > 0 &&
      !rightsAccepted
    ) {
      openUsageRightsModal();
    } else {
      callGetAssetUrl(assetId);
    }
    trackEvent("download");
  };

  const onOpenDownloadOptionsClick = () => {
    trackEvent("open-download-options-multiple-files");
    openDownloadOptionsModal();
  };

  // DOWNLOADING AN ENTIRE ASSET
  useEffect(() => {
    assetResult && openFileDownload(assetResult);
  }, [assetResult]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (assetError && assetErrorMsg) {
      dispatch(
        setNotification({
          type: "error",
          message: "Error downloading asset.",
        })
      );
      console.error(assetErrorMsg);
    }
  }, [assetError, assetErrorMsg]);

  return (
    <StyledAssetCard assetId={assetId} data-testid="asset-card">
      <div
        className={`full-wrap ${fullWrapFocused ? "focused" : ""}`}
        onMouseEnter={focusFullWrap}
        onMouseLeave={blurFullWrap}
        onBlur={blurFullWrap}
        onFocus={focusFullWrap}
        data-testid="asset-card-focusable"
      >
        <div className={`assetcard__wrap`} role="group" aria-label="asset">
          <div className="media">
            {thumbnails && thumbnails.length > 0 && (
              <Image
                className="media__image"
                alt={`Asset Image for: ${title}`}
                src={thumbnails[activeThumbnail ? activeThumbnail : 0]}
                testId="main-thumb"
              />
            )}
            {item.thumbnailAssetUrl && (
              <Image
                className="media__image"
                alt={`Image for: ${title}`}
                src={item.thumbnailAssetUrl}
                testId="training-thumb"
              />
            )}
            {(item.searchType !== "guideline") && (
              <Link
                to={getLinkUrl()}
                onClick={onAssetLinkClick}
                className="media__link"
                title={`Open new tab to view image: ${item.title}`}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="asset-details-link"
              >
                <span>{item.title}</span>
              </Link>
            )}
            {(item.searchType === "guideline") && (
              <a href={externalRoute("url" in item ? item.url : "")}
                rel={"external noopener noreferrer"}
                target="_blank"
                data-testid="asset-details-link"
                className="media__link"
              >
                <span>{"title" in item ? item.title : ""}</span>
              </a>
            )}
            {thumbnails && thumbnails[0] && (
              <div
                className={`asset-card-expand ${
                  fullWrapFocused ? "focused" : ""
                }`}
              >
                <IconButton
                  id={`asset-${assetId}-expand`}
                  name="fullscreen"
                  className="thumbnail-utility-button-open"
                  ariaLabel="View fullscreen image"
                  onClick={onFullscreenOpenClick}
                  noHover
                  testId="fullscreen-button"
                />
              </div>
            )}
          </div>

          <FlexBox
            className={`assetcard__footer ${fullWrapFocused ? "focused" : ""}`}
            data-testid="asset-card-small-footer"
          >
            <ul>
              <li className="assetcard__listitem">
                <IconButton
                  name={
                    isFavoriteAsset ? "added-to-favorite" : "add-to-favorite"
                  }
                  size="large"
                  ariaLabel="Add to favorites"
                  onClick={openFavorite}
                  noHover
                  testId="favorite-button"
                />
              </li>
              <li className="assetcard__listitem">
                <IconButton
                  name="share"
                  ariaLabel="Share asset"
                  noHover
                  onClick={openShare}
                  testId="share-button"
                />
              </li>
              {item.userActions && item.userActions.canDownload && (
                <li className="assetcard__listitem">
                  <IconButton
                    name="download"
                    ariaLabel="Download asset"
                    noHover
                    onClick={
                      item.files &&
                      groups !== null &&
                      groups.length >= 2 &&
                      item.files.length > 0
                        ? onOpenDownloadOptionsClick
                        : onDownloadAssetClick
                    }
                    testId="download-button"
                  />
                </li>
              )}
            </ul>
          </FlexBox>
        </div>
      </div>

      {favoriteOpen && (
        <FavoriteModal
          items={[item]}
          onComplete={(isFavorite: boolean) => completeFavorite(isFavorite)}
          onClose={(isFavorite: boolean) => closeFavorite(isFavorite)}
        />
      )}

      {shareOpen && (
        <ShareModal
          onClose={closeShare}
          items={[item]}
          onComplete={completeShare}
        />
      )}

      {showDownloadOptionsModal && singleFiles && singleFiles.length > 0 && (
        <DownloadOptionsModal
          files={singleFiles}
          assetId={assetId}
          userCanDownload={
            item.userActions?.canDownload
              ? item.userActions?.canDownload!
              : false
          }
          onClose={closeDownloadOptionsModal}
          groups={groups}
          usageRightsCopy={
            item.usageRightsCopy ? item.usageRightsCopy! : undefined
          }
          onComplete={completeDownloadOptionsModal}
          usageRightsAccepted={rightsAccepted}
        />
      )}

      {usageRightsModalOpen && (
        <UsageRightsModal
          assetId={assetId}
          usageRightsCopy={
            item.usageRightsCopy ? item.usageRightsCopy : undefined
          }
          onComplete={completeUsageRightsModal}
          onClose={closeUsageRightsModal}
        />
      )}

      {carouselFiles && carouselFiles.length > 0 && fullscreenOpen && (
        <FullscreenImgCarouselModal
          assetId={assetId}
          onClose={(index: number) => closeFullscreen(index)}
          activeThumbnail={activeThumbnail}
          files={carouselFiles}
          onNavClick={(index: number) => updateThumbnail(index)}
        />
      )}
    </StyledAssetCard>
  );
};

export default AssetCardSmall;
