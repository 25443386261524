import { useState } from "react";
import { DeleteModalProps, ManagedUser } from "interfaces";
import { TextButton, Button, FlexBox, Modal, Title, Body } from "components";
import {
  useDeleteUserMutation,
  useAdminUpdateGroupMutation,
} from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";

export enum ManagedStatus {
  notSet = "Not Set",
  active = "Active",
  inactive = "Inactive",
  deleted = "Deleted",
  requested = "Requested",
}

const DeleteModal = ({
  user,
  group,
  open,
  onClose,
  onComplete,
}: DeleteModalProps) => {
  const [deleteUser, { isLoading: userLoading }] = useDeleteUserMutation(),
    [deleteGroup, { isLoading: groupLoading }] = useAdminUpdateGroupMutation();

  const isLoading = userLoading || groupLoading;

  const dispatch = useDispatch();

  const [groupId, _groupId] = useState<string>(user.Id ? user.Id : "");

  const callDeleteUser = () => {
    if (group) {
      const newGroupInfo: ManagedUser = {
        ...user,
        Status: ManagedStatus.deleted,
      };
      delete newGroupInfo.Id;
      deleteGroup({
        id: groupId,
        userData: newGroupInfo,
      })
        .unwrap()
        .then(() => {
          dispatch(
            setNotification({
              type: "success",
              message: "Group successfully deleted.",
            })
          );
          onComplete();
        })
        .catch((error: any) => {
          dispatch(
            setNotification({
              type: "error",
              message: "Error deleting group.",
            })
          );
          console.error(error);
        });
    } else {
      deleteUser({
        id: user.Id ? user.Id : "",
        userData: user,
      })
        .unwrap()
        .then(() => {
          dispatch(
            setNotification({
              type: "success",
              message: "User successfully deleted.",
            })
          );
          onComplete();
        })
        .catch((error: unknown) => {
          dispatch(
            setNotification({
              type: "error",
              message: "Error deleting user.",
            })
          );
          console.error(error);
        });
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal testId="delete-modal" open={open} onClose={handleClose} disabled={isLoading}>
      <FlexBox>
        <Title size="large" bold pd="0 0 2rem">
          {`Are you sure you want to delete this ${group ? "group" : "user"}?`}
        </Title>
        <Body size="large" pd="0 0 2rem">
          {`Deleted ${
            group ? "groups" : "users"
          } are permanently removed from Brand Central and will no longer be accessible.`}
        </Body>
        <FlexBox row gap="1.5rem" align="center" justify="flex-start">
          <Button data-testid="submit-button" use="primary" onClick={callDeleteUser} disabled={isLoading}>
            {`Delete ${group ? "group" : "user"}`}
          </Button>
          <TextButton data-testid="cancel-button" onClick={handleClose} disabled={isLoading}>
            Cancel
          </TextButton>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default DeleteModal;
