import React from 'react';
import PropTypes from 'prop-types';
import { Title as Title$1 } from '@vds-core/typography';

const propTypes = {
  /**
   * If enabled, Title will be rendered in bold
   */
  bold: PropTypes.bool
};
const Title = function (props) {
  let size;
  let fontWeight;
  if (props.size === 'XSmall') size = 'small';else size = props.size;
  if (props.size === 'large' || props.size === 'XLarge' || props.size === '2XLarge') fontWeight = 'light';else fontWeight = 'regular';
  return React.createElement(Title$1, Object.assign({}, props, {
    size: size,
    fontSize: null,
    lineHeight: null,
    letterSpacing: null,
    regularWeight: fontWeight
  }));
};
Title.propTypes = propTypes;

export { Title as default };
