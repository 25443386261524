import React from 'react';
import PropTypes from 'prop-types';
import { Loader as Loader$1 } from '@vds-core/loaders';

const propTypes = {
  /**
  * Option to render on dark or light surface
  */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const Loader = function (props) {
  return React.createElement(Loader$1, Object.assign({}, props));
};
Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export { Loader as default };
