import React from "react";
import { StyledContainer } from "./PlaylistContainer.module";

export type PlaylistContainerProps = {
  className?: string;
  children: React.ReactNode;
  videosPg?: boolean;
  expanded?: boolean;

};

const PlaylistContainer = ({ className, children, expanded,...rest }: PlaylistContainerProps) => {
  return (
    <StyledContainer className={className} {...rest}>
      {children}
    </StyledContainer>
  );
};

export default PlaylistContainer;
