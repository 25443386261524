import { Grid } from "@mui/material";
import { Body } from "components";
import {UnifiedSearchResultProps} from "interfaces";
import { AssetCardSmall } from "features";
import { StyledAssetCardList } from "./AssetCardList.module";

export interface AssetCardListProps {
  items: UnifiedSearchResultProps[];
  title?: string;
  scrollOnMobile?: boolean;
  page?: number;
  id?: string;
}

const AssetCardList = ({
  items,
  scrollOnMobile,
  title,
  page,
  id,
}: AssetCardListProps) => {
  return (
    <StyledAssetCardList data-testid="asset-card-list">
      {title && (
        <Body size="small" pd="0 0 0.5rem" bold data-testid="title">
          {title}
        </Body>
      )}
      <div className="overflow-container">
        <Grid data-testid="scroll-grid" container className={scrollOnMobile ? "scroll-grid" : ""}>
          {items?.map(
            (item: UnifiedSearchResultProps, index) =>
              item.files && (
                <Grid key={item.id} item xs={12 / 5}>
                  <AssetCardSmall
                    isFavorite={item.isFavorite || false}
                    item={item}
                    assetId={item.id}
                    title={item.title}
                    tracking-data={{
                      parentComponent: "asset-card-list",
                      parentId: id,
                      cardIndex: index,
                      totalCards: items.length,
                      cardPage: page ? page : 1,
                    }}
                  />
                </Grid>
              )
          )}
        </Grid>
      </div>
    </StyledAssetCardList>
  );
};

export default AssetCardList;
