import styled from "styled-components";
import {MdToHtmlProps} from "./MdToHtml";

interface StyledMdToHtmlProps {
  pSize?: string;
  linkSize?: string;
  color?: string;
  pMargin?: string;
  linkMargin?: string;
  pd?: string;
  linkTextDecoration?: boolean;
};

export const StyledMdToHtml = styled("div")<StyledMdToHtmlProps>`
  font-size: 1rem;
  font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
  padding: ${(props) => `${props.pd ? props.pd : '0'}`};
  p {
    color: ${(props) => `${props.color ? props.color : '#000000'}`};
    font-size: ${(props) => `${props.pSize === "large" ? '1rem' : '0.75rem'}`};
    line-height: 1.5;
    font-family: ${(props) => `${props.pSize === "large" ? 'Verizon-NHG-eDS' : 'Verizon-NHG-eTX'}`}, Helvetica, Arial, sans-serif;
    margin: ${(props) => `${props.pMargin ? props.pMargin : '0.75rem 0'}`};
    letter-spacing:${(props) => `${props.pSize === "large" ? '0.5px' : '0'}`};
  }
  a {
    font-size: ${(props) => `${props.linkSize === "large" ? '1rem' : '0.75rem'}`};
    line-height: 1.5;
    margin: ${(props) => `${props.linkMargin ? props.linkMargin : '0'}`};
    display: ${(props) => `${props.linkMargin ? 'block' : 'inline-block'}`};
    font-family: ${(props) => `${props.linkSize === "large" ? 'Verizon-NHG-eDS' : 'Verizon-NHG-eTX'}`}, Helvetica, Arial, sans-serif;
    text-decoration: ${(props) => `${props.linkTextDecoration ? 'underline' : 'none'}`};
    color: ${(props) => `${props.color ? props.color : '#000000'}`};
    letter-spacing:${(props) => `${props.pSize === "large" ? '0.5px' : '0'}`};
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
    &:visited {
      color: ${(props) => `${props.color ? props.color : '#000000'}`};
      text-decoration: ${(props) => `${props.linkTextDecoration ? 'underline' : 'none'}`};
    }
  }
  ul {
    padding-right: 1rem;
    padding-inline-start: 1rem;
  }
`;