export const FormControlsTokens = {
  "border": {
    "radius": {
      "value": "4px",
      "attributes": {
        "category": "size",
        "type": "radius"
      },
      "filePath": "packages/FormControls/src/index.js",
      "isSource": true,
      "original": {
        "value": "4px",
        "attributes": {
          "category": "size"
        }
      },
      "name": "BorderRadius",
      "path": [
        "border",
        "radius"
      ]
    },
    "width": {
      "value": "1px",
      "attributes": {
        "category": "size",
        "type": "width"
      },
      "filePath": "packages/FormControls/src/index.js",
      "isSource": true,
      "original": {
        "value": "1px",
        "attributes": {
          "category": "size"
        }
      },
      "name": "BorderWidth",
      "path": [
        "border",
        "width"
      ]
    }
  },
  "color": {
    "background": {
      "onlight": {
        "value": "#ffffff",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "onlight"
        },
        "filePath": "packages/FormControls/src/index.js",
        "isSource": true,
        "original": {
          "value": "#ffffff",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ColorBackgroundOnlight",
        "path": [
          "color",
          "background",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#000000",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "ondark"
        },
        "filePath": "packages/FormControls/src/index.js",
        "isSource": true,
        "original": {
          "value": "#000000",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ColorBackgroundOndark",
        "path": [
          "color",
          "background",
          "ondark"
        ]
      }
    },
    "border": {
      "onlight": {
        "value": "#6f7171",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "onlight"
        },
        "filePath": "packages/FormControls/src/index.js",
        "isSource": true,
        "original": {
          "value": "#6f7171",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ColorBorderOnlight",
        "path": [
          "color",
          "border",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#a7a7a7",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "ondark"
        },
        "filePath": "packages/FormControls/src/index.js",
        "isSource": true,
        "original": {
          "value": "#a7a7a7",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ColorBorderOndark",
        "path": [
          "color",
          "border",
          "ondark"
        ]
      },
      "hover": {
        "onlight": {
          "value": "#000000",
          "attributes": {
            "category": "color",
            "type": "border",
            "item": "hover",
            "subitem": "onlight"
          },
          "filePath": "packages/FormControls/src/index.js",
          "isSource": true,
          "original": {
            "value": "#000000",
            "attributes": {
              "category": "color"
            }
          },
          "name": "ColorBorderHoverOnlight",
          "path": [
            "color",
            "border",
            "hover",
            "onlight"
          ]
        },
        "ondark": {
          "value": "#ffffff",
          "attributes": {
            "category": "color",
            "type": "border",
            "item": "hover",
            "subitem": "ondark"
          },
          "filePath": "packages/FormControls/src/index.js",
          "isSource": true,
          "original": {
            "value": "#ffffff",
            "attributes": {
              "category": "color"
            }
          },
          "name": "ColorBorderHoverOndark",
          "path": [
            "color",
            "border",
            "hover",
            "ondark"
          ]
        }
      },
      "readonly": {
        "onlight": {
          "value": "#d8dada",
          "attributes": {
            "category": "color",
            "type": "border",
            "item": "readonly",
            "subitem": "onlight"
          },
          "filePath": "packages/FormControls/src/index.js",
          "isSource": true,
          "original": {
            "value": "#d8dada",
            "attributes": {
              "category": "color"
            }
          },
          "name": "ColorBorderReadonlyOnlight",
          "path": [
            "color",
            "border",
            "readonly",
            "onlight"
          ]
        },
        "ondark": {
          "value": "#333333",
          "attributes": {
            "category": "color",
            "type": "border",
            "item": "readonly",
            "subitem": "ondark"
          },
          "filePath": "packages/FormControls/src/index.js",
          "isSource": true,
          "original": {
            "value": "#333333",
            "attributes": {
              "category": "color"
            }
          },
          "name": "ColorBorderReadonlyOndark",
          "path": [
            "color",
            "border",
            "readonly",
            "ondark"
          ]
        }
      }
    }
  },
  "space": {
    "inset": {
      "value": "12px",
      "attributes": {
        "category": "size",
        "type": "inset"
      },
      "filePath": "packages/FormControls/src/index.js",
      "isSource": true,
      "original": {
        "value": "12",
        "attributes": {
          "category": "size"
        }
      },
      "name": "SpaceInset",
      "path": [
        "space",
        "inset"
      ]
    }
  }
}