import { ExpireModalProps } from "interfaces";
import { TextButton, Button, FlexBox, Modal, Title, Body } from "components";
import { useExpireAssetMutation } from "services/api/api.slice";
import { setDateAsExpired } from "utils/dateFunctions";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";

const ExpireModal = ({
  id,
  onClose,
  buttonText,
  title,
  itemName,
  onComplete,
}: ExpireModalProps) => {
  const [expireAsset, { isLoading: expireLoading }] = useExpireAssetMutation();

  const dispatch = useDispatch();

  const callExpireAsset = () => {
    const newExpireDate = setDateAsExpired();
    expireAsset({
      assetId: id,
      expirationDate: newExpireDate,
    })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: "Asset has been expired.",
          })
        );
        onComplete(newExpireDate);
      })
      .catch((error: unknown) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Could not expire asset.",
          })
        );
        console.error(error);
      });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal testId="expire-modal" open onClose={handleClose} disabled={expireLoading}>
      <FlexBox>
        <Title size="large" bold pd="0 0 2rem">
          {title}
        </Title>
        <Body size="large" pd="0 0 2rem">
          Expiring this asset would set the expiration date for this asset to
          yesterday. Are you sure you want to expire{" "}
          <span style={{ fontWeight: "700" }}>{itemName}?</span>
        </Body>
        <FlexBox row gap="1.5rem" align="center" justify="flex-start">
          <Button
            use="primary"
            disabled={expireLoading}
            onClick={callExpireAsset}
            data-testid="submit-button"
          >
            {buttonText}
          </Button>
          <TextButton data-testid="cancel-button" onClick={handleClose} disabled={expireLoading}>
            Cancel
          </TextButton>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default ExpireModal;
