import {
  Body,
  Button,
  ButtonGroup,
  FlexBox,
  Form,
  Input,
  Notification,
  Select,
  Title,
  TwoUp,
} from "components";
import ReCAPTCHA from "react-google-recaptcha";
import {
  EmploymentTitleProps,
  EmploymentTitlesProps,
  FormikProps,
  withVDSManagerProps,
} from "interfaces";
import { useSelector } from "hooks/redux";
import {
  resetAuthNotification,
  selectAuthNotification,
  selectStatus,
  useGetCountriesQuery,
} from "services";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "routing/paths";
import { halfWidth } from "theme/variables";
import PasswordReqs from "components/layouts/PasswordReqs/PasswordReqs";
import { RECAPTCHA_API_KEY } from "utils/environmentVariables";
import { withVDSManager } from "@vds/utilities";

const SignUpForm = ({
  employmentTitles = [],
  signUp,
  values,
  values: {
    firstName,
    lastName,
    email,
    verizonContact,
    phone,
    country,
    company,
    employmentTitle,
    customEmploymentTitle,
    password,
    passwordConfirm,
  },
  handleBlur,
  errors,
  touched,
  handleChange,
  setFieldValue,
  setFieldTouched,
  dirty,
  isValid,
  viewport,
}: FormikProps &
  withVDSManagerProps & {
    signUp: Function;
    employmentTitles: EmploymentTitlesProps;
  }) => {
  const isMobile: boolean = ["mobile", "mobileLarge", "tablet"].includes(
    viewport as string
  );

  const navigate = useNavigate(),
    isLoading = useSelector(selectStatus) === "LOADING";

  const { data: countries = [] } = useGetCountriesQuery();

  const [validPassword, _setValidPassword] = useState(false);

  const handleCancel = () => {
    navigate(paths.SIGNIN);
  };

  const handleReCaptcha = useCallback(async (token: string | null) => {
      setFieldValue("recaptchaToken", token);
    }, []),
    handleSubmit = async () => {
      signUp({ values });
    };

  const otherId = employmentTitles.find(
    (item: EmploymentTitleProps) => item.name === "Other"
  )?.id;
  useEffect(() => {
    if (otherId !== employmentTitle) {
      setFieldValue("customEmploymentTitle", "");
      setFieldTouched("customEmploymentTitle", false);
    }
  }, [employmentTitle]);

  return (
    <FlexBox maxWidth={halfWidth}>
      <Title size="large" bold pd="0 0 0.5rem 0">
        Request system access
      </Title>
      <Body size="large" pd="0 0 1.5rem 0">
        All fields are required.
      </Body>

      <Form onSubmit={handleSubmit}>
        <TwoUp>
          <Input
            type="text"
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched["firstName"] && !!errors["firstName"]}
            errorText={errors["firstName"]}
            disabled={isLoading}
            data-testid="first-name"
          />
          <Input
            type="text"
            label="Last Name"
            name="lastName"
            value={lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched["lastName"] && !!errors["lastName"]}
            errorText={errors["lastName"]}
            disabled={isLoading}
            data-testid="last-name"
          />
          <Input
            type="text"
            name="email"
            value={email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched["email"] && !!errors["email"]}
            errorText={errors["email"]}
            disabled={isLoading}
            data-testid="email"
          />
          <Input
            type="text"
            label="Verizon Contact Email"
            name="verizonContact"
            value={verizonContact}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched["verizonContact"] && !!errors["verizonContact"]}
            errorText={errors["verizonContact"]}
            disabled={isLoading}
            data-testid="verizon-contact"
          />
          <Input
            type="text"
            name="phone"
            value={phone}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched["phone"] && !!errors["phone"]}
            errorText={errors["phone"]}
            disabled={isLoading}
            data-testid="phone"
          />
          <Select
            items={countries}
            label="Country"
            name="country"
            value={country}
            onChange={handleChange}
            disabled={isLoading}
            data-testid="country"
            includeEmpty
          />
          <Input
            type="text"
            name="company"
            value={company}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched["company"] && !!errors["company"]}
            errorText={errors["company"]}
            disabled={isLoading}
            data-testid="company"
          />
          <Select
            items={employmentTitles}
            label="User Role"
            name="employmentTitle"
            value={employmentTitle}
            onChange={handleChange}
            disabled={isLoading}
            data-testid="employment-title"
            includeEmpty
          />
          {employmentTitles.find(
            (item: EmploymentTitleProps) => item.name === "Other"
          )?.id === employmentTitle && (
            <Input
              type="text"
              label="Custom Employment Title"
              name="customEmploymentTitle"
              value={customEmploymentTitle}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched["customEmploymentTitle"] &&
                !!errors["customEmploymentTitle"]
              }
              errorText={errors["customEmploymentTitle"]}
              disabled={isLoading}
              data-testid="custom-employment-title"
            />
          )}
        </TwoUp>

        <Title size="small" bold pd="2rem 0 1rem 0">
          Create a Password
        </Title>
        <TwoUp pd={isMobile ? "0 0 1.5rem" : "0 0 0.5rem"}>
          <FlexBox>
            <Input
              type="password"
              name="password"
              value={password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched["password"] && !!errors["password"]}
              errorText={errors["password"]}
              disabled={isLoading}
              data-testid="password"
              pd="0 0 1.5rem 0"
            />

            <Input
              type="password"
              label="Retype Password"
              name="passwordConfirm"
              value={passwordConfirm}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched["password"] &&
                touched["passwordConfirm"] &&
                !!errors["passwordConfirm"]
              }
              errorText={errors["passwordConfirm"]}
              disabled={isLoading}
              data-testid="confirm-password"
            />
          </FlexBox>
          <div>
            <PasswordReqs password={password} setPassed={_setValidPassword} />
          </div>
        </TwoUp>
        <Notification
          selector={selectAuthNotification}
          reset={resetAuthNotification}
          inline
        />
        <ReCAPTCHA sitekey={RECAPTCHA_API_KEY} onChange={handleReCaptcha} />
        <ButtonGroup pd="1.5rem 0 0 0">
          <Button
            type="submit"
            use="primary"
            disabled={isLoading || !(dirty && isValid) || !validPassword}
            data-testid="submit"
          >
            Make request
          </Button>
          <Button
            type="button"
            onClick={handleCancel}
            disabled={isLoading}
            data-testid="cancel"
            use="secondary"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Form>
    </FlexBox>
  );
};

export default withVDSManager(SignUpForm);
