import styled from "styled-components";
import { minTablet } from "theme/variables";
import { zIndex } from "theme/variables";

export const StyledDownloadListContainer = styled("div")`
  .listbox-group {
    .close-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: transparent;
      z-index: 5;
    }
  }

  .listbox-group.not-active {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ${minTablet - 1}px) {
      display: none;
    }
  }

  .listbox-group.is-active {
    @media screen and (max-width: ${minTablet - 1}px) {
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      width: 100vw;
      height: 100vh;
      background: #ffffff;
      z-index: 10001;
    }
  }

  .listbox {
    padding: 1rem 1rem 0;
    margin-bottom: 1.5rem;
    min-height: 176px;
    //height: 90vh;
    font-size: inherit;
    line-height: 1.333;
    height: auto;
    border: #d8dada 1px solid;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    & > .header > p {
      position: relative;
      margin: 0;
      padding-bottom: 1rem;
      padding-right: 10%;
      font-size: 1rem;
      font-weight: 700;
      font-family: Verizon-NHG-eDS, "Helvetica Neue", helvetica, arial,
        sans-serif;

      /* horizontal rule styling */
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100vw;
        border-bottom: 1px solid #d8dada;
        left: calc(-1 * calc(100vw - 100%) / 2);
      }

      @media screen and (min-width: ${minTablet}px) {
        padding-bottom: 0.5rem;
        font-size: 0.75rem;
        font-family: Verizon-NHG-eTX, "Helvetica Neue", helvetica, arial,
          sans-serif;
        font-weight: normal;
        &:after {
          width: 100%;
          left: 0;
          border-bottom-color: #000000;
        }
      }
    }

    .scrollbar {
      overflow-y: auto;
      @media screen and (max-width: ${minTablet - 1}px) {
        height: calc(100vh - 153px);
      }
    }

    ul {
      padding: 0.5rem 0.5rem 1rem 0;
      margin: 0;
      height: 8.25rem;
      list-style: none;
      @media screen and (max-width: ${minTablet - 1}px) {
        height: calc(100vh - 153px);
      }
    }

    li {
      margin-top: 0.5rem;
      & + & {
        margin-top: 1rem;
      }

      span {
        word-break: break-all;
      }
    }

    .info + .link-dark {
      margin-left: 1rem;
    }
  }

  .info p {
    color: #747676;
  }

  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    @media screen and (max-width: ${minTablet - 1}px) {
      /* margin-bottom: 2.5rem;*/
      display: flex;
      flex-direction: row;
      align-items: center;
      position: fixed;
      bottom: 0;
      padding: 0 1rem;
      width: 100%;
      height: 82px;
      background: #ffffff;
      border-top: #d8dada 1px solid;
      box-sizing: border-box;
    }
  }

  /* .download-options-mobile {
  @media screen and (min-width: ${minTablet}px) {
    display: none;
  }
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  .download-options-desktop {
    @media screen and (max-width: ${minTablet - 1}px) {
      display: none;
    }
  } */

  .download-link {
    button {
      border-bottom: none;
      margin-bottom: 0;

      &:hover {
        border-bottom: none;
        margin-bottom: 0;
        /* span {
          font-weight: 700;
        } */
      }
    }
    span {
      white-space: normal;
      text-align: left;
    }
  }

  .info + button {
    margin-left: 0.75rem;
  }
`;
