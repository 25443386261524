import { Body, FlexBox, TextLink } from "components";
import paths from "routing/paths";
import { StyledKeywordsContainer } from "./Keywords.module";

export interface KeywordsProps {
  keywords: string[];
}

const Keywords = ({ keywords }: KeywordsProps) => {
  return (
    <StyledKeywordsContainer>
      <Body size="small" bold>
        Keywords
      </Body>
      <FlexBox pd="0.25rem 0 0" className="keyword-list" wrap row gap="1.25rem">
        {keywords.slice(0, 5).map((keyword: string) => (
          <TextLink
            key={keyword}
            bold
            className="keyword"
            to={paths.ASSET_SEARCH}
            state={{ saved: { search: keyword } }}
            testId="keywords"
          >
            {keyword}
          </TextLink>
        ))}
      </FlexBox>
    </StyledKeywordsContainer>
  );
};

export default Keywords;
