import { Loader as VdsLoader } from "@vds/loaders";
import { StyledLoaderContainer } from "./Loader.module";
import { Backdrop } from "@mui/material";
import { useEffect, useState } from "react";

export interface VdsLoaderProps {
  active?: boolean;
}

export interface LoaderProps {
  containerHeight?: string;
  pd?: string;
}

const Loader = ({
  containerHeight,
  pd,
  ...rest
}: VdsLoaderProps & LoaderProps) => {
  const [navBottom, _setNavBottom] = useState<number>();

  useEffect(() => {
    _setNavBottom(
      document.getElementById("brand-central-nav-bar")?.getBoundingClientRect()
        ?.bottom
    );
  }, [
    document.getElementById("brand-central-nav-bar")?.getBoundingClientRect()
      ?.bottom,
  ]);

  useEffect(() => {
    !containerHeight && document.body.classList.add("no-scroll");
    return () => document.body.classList.remove("no-scroll");
  }, []);

  return containerHeight ? (
    <StyledLoaderContainer
      {...rest}
      pd={pd}
      containerHeight={containerHeight}
      data-testid="loader"
    >
      <VdsLoader fullscreen={false} {...rest} />
    </StyledLoaderContainer>
  ) : (
    <>
      <Backdrop open sx={{ top: navBottom + "px" }} data-testid="full-loader">
        <VdsLoader {...rest} />
      </Backdrop>
    </>
  );
};

export default Loader;
