import { Tooltip } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import { CloseButton, IconWrapper } from "./Tooltip.module";

interface CustomTooltipProps {
  children: ReactElement;
  icon: ReactElement;
  ariaLabel?: string;
  testId?: string;
}

const CustomTooltip = ({ icon, ariaLabel, children, testId }: CustomTooltipProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [open, _setOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (!tooltipRef.current?.contains(e.target)) {
        _setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [tooltipRef]);

  return (
    <>
      <Tooltip
        placement="top"
        onClose={() => _setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <div data-testid={testId} ref={tooltipRef}>
            <CloseButton
              className="absolute"
              name="close"
              noHover
              onClick={() => _setOpen(false)}
              testId="close-tooltip"
            />
            {children}
          </div>
        }
        PopperProps={{
          sx: {
            "& .MuiTooltip-tooltip": {
              color: "#000000",
              background: "#FFFFFF",
              border: "1px solid #000000",
              borderRadius: "0",
              maxHeight: "18.125rem",
              width: "22.8125rem",
            },
          },
        }}
        arrow
      >
        <IconWrapper aria-label={ariaLabel} onClick={() => _setOpen(true)}>
          {icon}
        </IconWrapper>
      </Tooltip>
    </>
  );
};

export default CustomTooltip;
