import styled from "styled-components";

export const UnprotectedWrapper = styled.main`
  max-width: 1440px;
  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;

  padding: 5vh;

  box-sizing: border-box;
`;
