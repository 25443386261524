import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import { withAction } from "HOCs/withAction";
import { mfa } from "services";
import mapPropsToValues from "./mapPropsToValues";
import handleSubmit from "./handleSubmit";
import MfaChoice from "./MfaChoice";

export const MfaChoiceContainer = compose(
  withAction((props: any) => mfa(props)),
  withFormik({
    mapPropsToValues,
    handleSubmit,
  })
)(MfaChoice);

export default MfaChoiceContainer;
