import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AccordionHeader as AccordionHeader$1 } from '@vds-core/accordions';

const propTypes = {
  /**
   * @ignore
  * Option to render on dark or light surface
  */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
class AccordionHeader extends Component {
  render() {
    return React.createElement(AccordionHeader$1, Object.assign({}, this.props));
  }
}
AccordionHeader.componentType = 'AccordionHeader';
AccordionHeader.propTypes = propTypes;
AccordionHeader.defaultProps = defaultProps;

export { AccordionHeader as default };
