import { Title } from "components";
import { StyledTableCell, StyledTableRow } from "./MuiTable.module";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ColumnProps, MuiTableProps } from "interfaces";

const MuiTable = ({ headers, items, columnWidths }: MuiTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {headers.map((header: ColumnProps, index: number) => (
              <StyledTableCell
                key={header.name}
                width={columnWidths ? columnWidths[index] : "fit-content"}
              >
                <Title bold>{header.name}</Title>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: any, index: number) => (
            <StyledTableRow key={index}>
              {headers.map((column: ColumnProps, index: number) => (
                <StyledTableCell key={index} className={column.className}>
                  {item[column.name]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MuiTable;
