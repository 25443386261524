import {
  Button,
  ButtonGroup,
  Form,
  Input,
  Title,
  Body,
  ThreeUp,
  Notification,
} from "components";
import { FormikProps } from "interfaces";
import { useDispatch, useSelector } from "hooks/redux";
import {
  forgotPassword,
  resetAuthNotification,
  resetAuthState,
  selectAuthNotification,
  selectStatus,
} from "services";
import { useNavigate } from "react-router-dom";
import paths from "routing/paths";
import PasswordReqs from "components/layouts/PasswordReqs/PasswordReqs";
import TextButton from "components/buttons/TextButton/TextButton";
import FlexBox from "components/layouts/FlexBox/FlexBox";
import Line from "components/Line/Line";
import { useState } from "react";

export interface props {
  username: string;
}

const ResetPasswordForm = ({
  values,

  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  errors,
  dirty,
  isValid,
  username,
}: props & FormikProps) => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    isLoading = useSelector(selectStatus) === "LOADING";

  const resendCode = () => {
      dispatch(forgotPassword({ resend: true, username }));
    },
    [validPassword, _setValidPassword] = useState(false),
    handleOnSubmit = (e: any) => {
      handleSubmit(e);
    },
    handleCancel = () => {
      dispatch(resetAuthState());
      navigate(paths.SIGNIN);
    };

  return (
    <Form onSubmit={handleOnSubmit}>
      <ThreeUp>
        <FlexBox>
          <Title bold size="large" pd="0 0 0.5rem 0">
            Reset Password
          </Title>
          <Body size="small" pd="0 0 1.5rem 0">
            A verification code has been sent to your email. Please enter it
            below, then choose a new password.
          </Body>
          <Input
            name="code"
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isLoading}
            error={touched.code && errors.code}
            errorText={errors.code}
            data-testid="code"
            maxWidth="325px"
          />
          <Notification
            selector={selectAuthNotification}
            reset={resetAuthNotification}
            inline
          />
          <FlexBox pd="1.5rem 0 0 0">
            <TextButton size="small" data-testid="resend-code" onClick={resendCode} disabled={isLoading}>
              Send a new code
            </TextButton>
          </FlexBox>

          <FlexBox pd="2rem 0">
            <Line />
          </FlexBox>
        </FlexBox>
      </ThreeUp>
      <ThreeUp>
        <FlexBox gap="1.5rem">
          <Input
            type="password"
            label="New Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isLoading}
            error={touched.password && errors.password}
            errorText={errors.password}
            data-testid="password"
          />
          <Input
            type="password"
            label="Confirm Password"
            name="passwordConfirm"
            value={values.passwordConfirm}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isLoading}
            error={touched.passwordConfirm && errors.passwordConfirm}
            errorText={errors.passwordConfirm}
            data-testid="confirm-password"
          />
        </FlexBox>
        <PasswordReqs
          password={values.password}
          setPassed={_setValidPassword}
        />
      </ThreeUp>

      <FlexBox maxWidth="32%">
        <ButtonGroup fullWidth pd="1.5rem 0 0 0" bp={"768px"}>
          <Button
            type="submit"
            disabled={isLoading || !(dirty && isValid) || !validPassword}
            data-testid="submit"
          >
            Confirm
          </Button>
          <Button
            type="button"
            disabled={isLoading}
            onClick={handleCancel}
            use="secondary"
            data-testid="cancel"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </FlexBox>
    </Form>
  );
};

export default ResetPasswordForm;
