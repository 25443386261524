export default function mapPropsToValues({
  search,
}: {
  search: string | null;
  suggestedSearch: Array<string>;
  updateSearch: Function;
}) {
  return {
    assetName: search || "",
    purpose: "",
    fileTypes: "",
  };
}
