import { Auth } from "@aws-amplify/auth";
import { CognitoUserInterface } from "interfaces/Cognito";
import AUTH_STATE from "constants/authState";
import MFA_CHOICE from "constants/mfaChoice";
import {
  CognitoConfirmSignUpProps,
  CognitoFlowResponseProps,
  CognitoUsernameProps,
  CognitoForgotPasswordSubmitProps,
  CognitoMfaChoiceProps,
  CognitoMfaConfirmProps,
  CognitoForgotPasswordProps,
  CognitoResultProps,
  CognitoSignInProps,
  CognitoSignUpProps,
  CognitoSignUpValuesProps,
  CognitoUpdateUserAttributesProps,
  CognitoVerifyCurrentUserAttributeProps,
  MfaFlowProps,
  SmsFlowProps,
  CognitoAttributeObjectProps,
  ParsedUserJWT,
  CognitoCurrentUserDetailsProps,
  CognitoChangePasswordProps,
} from "interfaces/Cognito";
import handleError from "utils/handleError";
import {
  CognitoIdToken,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import jwt_decode from "jwt-decode";
const skip_attribute = "custom:sms_config_skips";

async function signin({
  email,
  password,
}: CognitoSignInProps): Promise<CognitoResultProps> {
  const response = await Auth.signIn(email, password);
  return { data: response } as CognitoResultProps;
}

async function mfaChoice({
  user,
  mfaChoice,
  clientMetadata,
}: CognitoMfaChoiceProps): Promise<CognitoResultProps> {
  const response = await Auth.sendCustomChallengeAnswer(
    user,
    mfaChoice,
    clientMetadata
  );
  return { data: response } as CognitoResultProps;
}

async function mfaConfirm({
  user,
  authCode,
  verify,
}: CognitoMfaConfirmProps): Promise<CognitoResultProps> {
  try {
    const response = verify
      ? await Auth.verifyCurrentUserAttributeSubmit("phone_number", authCode)
      : await Auth.sendCustomChallengeAnswer(user, authCode);
    return { data: response } as CognitoResultProps;
  } catch (err: any) {
    throw Error(err.message ? err.message : "Expired code. Please refresh.");
  }
}

async function forgotPassword({
  username,
}: CognitoUsernameProps): Promise<null> {
  await Auth.forgotPassword(username);
  return null;
}

async function forgotPasswordSubmit({
  username,
  code,
  password,
}: CognitoForgotPasswordSubmitProps): Promise<null> {
  await Auth.forgotPasswordSubmit(username, code, password);
  return null;
}

export async function handleChangePasswordFlow(
  data: CognitoChangePasswordProps
): Promise<CognitoResultProps> {
  try {
    const { oldPassword, newPassword } = data;
    const user: CognitoUser | any = await getCurrentAuthenticatedUser();
    const response = await Auth.changePassword(
      user.data,
      oldPassword,
      newPassword
    );
    return { data: response } as CognitoResultProps;
  } catch (error) {
    throw handleError(error);
  }
}

async function signUp({
  email,
  password,
  firstName,
  lastName,
  phone,
  company,
  employmentTitle,
  verizonContact,
  country,
  recaptchaToken,
}: CognitoSignUpValuesProps): Promise<CognitoResultProps> {
  const response = await Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
      given_name: firstName,
      family_name: lastName,
      "custom:alt_phone": phone,
      "custom:company": company,
      "custom:org_role": employmentTitle,
      "custom:vz_contact_email": verizonContact,
      "custom:country": country,
      "custom:org_role_other": "",
    },
    validationData: {
      token: recaptchaToken,
    },
  });
  return { data: response } as CognitoResultProps;
}

async function resendSignUp({ username }: CognitoUsernameProps): Promise<null> {
  await Auth.resendSignUp(username);
  return null;
}

async function confirmSignUp({
  username,
  code,
}: CognitoConfirmSignUpProps): Promise<null> {
  await Auth.confirmSignUp(username, code);
  return null;
}

async function updateUserAttributes({
  user,
  attributes,
}: CognitoUpdateUserAttributesProps): Promise<CognitoResultProps> {
  const response = await Auth.updateUserAttributes(user, attributes);
  return { data: response } as CognitoResultProps;
}

async function verifyCurrentUserAttribute({
  attribute,
}: CognitoVerifyCurrentUserAttributeProps): Promise<CognitoResultProps> {
  const response = await Auth.verifyCurrentUserAttribute(attribute);
  return { data: response } as CognitoResultProps;
}

export async function handleSignInFlow({
  email,
  password,
}: CognitoSignInProps): Promise<CognitoFlowResponseProps> {
  try {
    const signinResult = await signin({ email, password }),
      user = signinResult.data as CognitoUserInterface,
      authState = AUTH_STATE.MFA_CHOICE;
    return { authState, user };
  } catch (err: any) {
    throw handleError(err);
  }
}

export async function handleMfaFlow({
  currentState,
  choice,
  code,
  user,
  verify, // verify phone number
  resend,
}: MfaFlowProps): Promise<CognitoFlowResponseProps> {
  let result: CognitoResultProps;
  let currentUser: CognitoUserInterface | null = user;
  // If resending code, swap authState back - this flow is not called for SMS verify resend code (mfaSMS is)
  let authState = resend ? AUTH_STATE.MFA_CHOICE : currentState;

  try {
    // Need to signin again if resending new code - not to verify phone number though
    if (resend && !verify) {
      const signinResult: CognitoResultProps = await signin({
        email: resend.email,
        password: resend.password,
      });
      currentUser = signinResult.data as CognitoUserInterface;
    }

    let numSkips = 0;
    switch (authState) {
      case AUTH_STATE.MFA_CODE:
      case AUTH_STATE.MFA_SMS_CODE:
        result = await mfaConfirm({
          user: currentUser,
          authCode: code,
          verify,
        });

        if (result.data.challengeParam?.authStep === authState) {
          throw handleError(new Error("Incorrect code. Try again."));
        }
        if (!verify) {
          currentUser = result.data as CognitoUserInterface;

          const { data } = await getUserAttributes({
            user: currentUser,
          } as unknown as CognitoUserInterface);
          numSkips =
            data.find((attr: { Name: string }) => attr.Name === skip_attribute)
              ?.Value || 0;
        }
        break;
      case AUTH_STATE.MFA_CHOICE:
      default:
        result = await mfaChoice({
          user: currentUser,
          mfaChoice: choice || MFA_CHOICE.EMAIL,
          clientMetadata: { mfa_choice: choice || MFA_CHOICE.EMAIL },
        });
        break;
    }

    if (verify) {
      // If verify successful, nav to home
      authState = AUTH_STATE.AUTHENTICATED;
      currentUser = null;
    } else {
      if (user?.challengeParam) {
        // Auto next step
        authState = user.challengeParam.authStep;
      } else if (numSkips === 3) {
        // Skip SMS automatically & update attribute so don't enter this flow next time
        await updateUserAttributes({
          user: currentUser,
          attributes: {
            [skip_attribute]: "4",
          } as CognitoAttributeObjectProps,
        });
        authState = AUTH_STATE.MFA_SMS_SKIPPED;
      } else if (
        numSkips < 3 &&
        !result.data.attributes?.phone_number_verified
      ) {
        // Nav to SMS if no verified phone
        authState = AUTH_STATE.MFA_SMS;
      } else {
        authState = AUTH_STATE.AUTHENTICATED;
      }
    }
    return { authState, user: currentUser };
  } catch (err: any) {
    throw handleError(err);
  }
}

async function getUserAttributes(
  data: CognitoUserInterface
): Promise<CognitoResultProps> {
  const { user } = data;

  try {
    const response = await Auth.userAttributes(user);

    return { data: response } as CognitoResultProps;
  } catch (error) {
    throw handleError(error);
  }
}

export async function getCurrentUserDetails(): Promise<CognitoFlowResponseProps> {
  try {
    const accessToken = (await Auth.currentSession())
      .getIdToken()
      .getJwtToken();

    const userDetails = jwt_decode(accessToken) as ParsedUserJWT;

    const userJwt: ParsedUserJWT = {
      email: userDetails.email,
      exp: userDetails.exp,
      iat: userDetails.iat,
      jti: userDetails.jti,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      nbf: userDetails.nbf,
      permissions: userDetails.permissions,
      role: userDetails.role,
      primaryUserRole: userDetails.primaryUserRole,
      uid: userDetails.uid,
      isSSO: userDetails.isSSO,
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid":
        userDetails[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"
        ],
    };
    
    return { currentUserDetails: userJwt };
  } catch (error) {
    throw handleError(error);
  }
}

export async function handleSkipSMSFlow(
  user: CognitoUserInterface
): Promise<CognitoFlowResponseProps> {
  try {
    const { data } = await getUserAttributes({
      user,
    } as unknown as CognitoUserInterface);
    let numSkips =
      parseInt(
        data.find((attr: { Name: string }) => attr.Name === skip_attribute)
          ?.Value
      ) || 0;
    numSkips = numSkips + 1;
    await updateUserAttributes({
      user,
      attributes: {
        [skip_attribute]: numSkips.toString(),
      } as CognitoAttributeObjectProps,
    });
    return { authState: AUTH_STATE.MFA_SMS_SKIPPED };
  } catch (err: any) {
    throw handleError(err);
  }
}

export async function handleForgotPasswordFlow({
  username,
  code,
  password,
  currentState,
}: CognitoForgotPasswordProps): Promise<CognitoFlowResponseProps> {
  let authState: string | null = null;
  try {
    switch (currentState) {
      case AUTH_STATE.RESET_CODE:
        await forgotPasswordSubmit({ username, code, password });
        authState = AUTH_STATE.NA;
        break;
      default:
        await forgotPassword({ username });
        authState = AUTH_STATE.RESET_CODE;
    }
    return { authState };
  } catch (err: any) {
    throw handleError(err);
  }
}

export async function handleSignUpFlow({
  user,
  currentState,
  values,
  code,
}: CognitoSignUpProps): Promise<CognitoFlowResponseProps> {
  let authState: string;
  let currentUser: CognitoUserInterface = user;
  try {
    switch (currentState) {
      case AUTH_STATE.SIGNUP_CODE:
        await confirmSignUp({
          username: currentUser.username!,
          code: code,
        });
        authState = AUTH_STATE.SIGNUP_PENDING;
        break;
      case AUTH_STATE.SIGNUP_RESEND:
        await resendSignUp({ username: currentUser.username! });
        authState = AUTH_STATE.SIGNUP_CODE;
        break;
      default:
        const result = await signUp(values);
        authState = AUTH_STATE.SIGNUP_CODE;
        currentUser = result.data.user;
    }
    return { authState, user: currentUser };
  } catch (err: any) {
    throw handleError(err);
  }
}

export async function handleConfigureSMSFlow({
  phoneNumber,
  user,
}: SmsFlowProps): Promise<CognitoFlowResponseProps> {
  const phoneAtt = "phone_number";
  try {
    // DISCUSS: do I need to get the user here?
    const userResponse: CognitoUserInterface = !user
      ? (await getCurrentAuthenticatedUser()).data
      : user;
    await updateUserAttributes({
      user: userResponse,
      attributes: { [phoneAtt]: phoneNumber },
    });

    await verifyCurrentUserAttribute({ attribute: phoneAtt });
    return { authState: AUTH_STATE.MFA_SMS_CODE };
  } catch (err: any) {
    throw handleError(err);
  }
}

export async function getCurrentAuthenticatedUser(): Promise<CognitoResultProps> {
  try {
    const response = await Auth.currentAuthenticatedUser();

    return { data: response } as CognitoResultProps;
  } catch (err: any) {
    throw handleError(err);
  }
}
