import styled from "styled-components";
import { zIndex } from "theme/variables";

export const StyledSiteAlertBanner = styled("div")`
  display: block;
  padding: 0.5rem;
  background-color: #0088ce;
  text-align: center;
  position: relative;
  z-index: ${zIndex.banner};
  padding: 1.5rem;
  min-height: 4.25rem;
  p {
    color: #ffffff;
    font-size: 1rem;
    font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
    font-weight: bold;
  }
  a {
    color: #ffffff;
  }
`;
