import React from 'react';
import styled from 'styled-components';
import { calculateRem } from '@vds-core/utilities';
import { Body } from '@vds-core/typography';

const InputTextWrapper = styled.div.withConfig({
  displayName: "InputTextWrapper",
  componentId: "VDS__sc-r9e0ad-0"
})`
  display: block;
  margin-top: ${calculateRem(8)};
`;
/**
 * @ignore
 */
const InputText = ({
  children,
  fontFamily,
  color
}) => {
  return React.createElement("div", {
    role: "alert"
  }, React.createElement(InputTextWrapper, null, React.createElement(Body, {
    size: "small",
    fontFamily: fontFamily && fontFamily,
    color: color
  }, children)));
};

export { InputText as default };
