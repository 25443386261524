import { useState, useEffect } from "react";
import { IconButton, DatePicker } from "components";
import { addDaysToCurrentDate, getLocalDate } from "utils/dateFunctions";
import { FileCourierSentPackageProps } from "interfaces";
import { StyledDatePicker } from "./DatePickerToggle.module";

export interface DatePickerToggleProps {
  item: FileCourierSentPackageProps;
  selectedPackageId?: string;
  setSelectedPackage: (value: FileCourierSentPackageProps) => void;
  onUpdate: (value: Date) => void;
  disabled?: boolean;
}

const DatePickerToggle = ({
  item,
  selectedPackageId,
  setSelectedPackage,
  onUpdate,
  disabled,
}: DatePickerToggleProps) => {
  const [extendOpen, setExtendOpen] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<Date>(
    getLocalDate(item.expirationDate)
  );

  useEffect(() => {
    setExpirationDate(getLocalDate(item.expirationDate));
  }, [item.expirationDate]);

  const handleOpenExtend = (item: FileCourierSentPackageProps) => () => {
    setSelectedPackage(item);
    setExtendOpen(true);
  };

  const handleSelectExpiration = (value: Date, submit?: boolean) => {
    setExpirationDate(value);
    if (submit) {
      onUpdate(value);
      setExtendOpen(false);
    }
  };

  const handleClearExpiration = () => {
    setExpirationDate(getLocalDate(item.expirationDate));
    setExtendOpen(false);
  };

  return (
    <>
      {extendOpen && item.expirationDate && selectedPackageId === item.id ? (
        <StyledDatePicker>
          <DatePicker
            value={expirationDate}
            onChange={handleSelectExpiration}
            onClear={handleClearExpiration}
            testId="expiration-date"
            minDate={addDaysToCurrentDate(1)}
            maxDate={addDaysToCurrentDate(90)}
            disabled={disabled}
          />
        </StyledDatePicker>
      ) : (
        <IconButton
          key={`calendar-${item.id}`}
          name="calendar"
          size="large"
          ariaLabel="extend package"
          onClick={handleOpenExtend(item)}
          addTooltip
          toolTipText="extend"
          disabled={disabled}
          testId="open-calendar-button"
        />
      )}
    </>
  );
};

export default DatePickerToggle;
