import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import { calculateRem, withVDSManager } from '@vds-core/utilities';
import { ColorTokens } from '@vds-tokens/color';
import LoaderPortal from './LoaderPortal.js';
import { FadeInAnimation, FadeOutAnimation, LoadingAnimation } from './LoaderAnimations.js';

const propTypes = {
  /**
   * Loader will be active if 'active' prop is passed.
   */
  active: PropTypes.bool,
  /**
   * Loader will be contained in its container by default.
   */
  fullscreen: PropTypes.bool,
  /**
   * Allows an id to be passed to the outermost wrapper of the component.
   */
  id: PropTypes.string,
  /**
   * @ignore
   */
  disableFocusLock: PropTypes.bool,
  /**
   * @ignore
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  active: false,
  fullscreen: false,
  disableFocusLock: false,
  surface: 'light'
};
const StyledOverlay = styled.div.withConfig({
  displayName: "StyledOverlay",
  componentId: "VDS__sc-27g4au-0"
})`
  background-color: ${({
  surface
}) => surface === 'dark' ? ColorTokens.background.primary.dark.value : ColorTokens.background.primary.light.value};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: ${props => props.fullscreen ? 'fixed' : 'absolute'};
  display: ${props => props.active ? 'block' : 'none'};
  animation: ${props => props.active ? FadeInAnimation : FadeOutAnimation}
    0.15s linear;
  opacity: 0.8;
`;
const StyledLoader = styled.div.withConfig({
  displayName: "StyledLoader",
  componentId: "VDS__sc-27g4au-1"
})`
  outline: none;
  display: ${props => props.active ? 'block' : 'none'};
  animation: ${props => props.active ? FadeInAnimation : FadeOutAnimation}
    0.15s linear;
  content: '';
  position: ${props => props.fullscreen ? 'fixed' : 'absolute'};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: ${calculateRem(40)};
  height: ${calculateRem(40)};
  display: block;
  z-index: 3;
  border: ${calculateRem(4)} solid
    ${({
  surface
}) => surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value};
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${LoadingAnimation} 0.5s infinite linear;
`;
const Loader = ({
  active,
  fullscreen,
  id,
  disableFocusLock,
  surface
}) => {
  if (active) {
    if (fullscreen) {
      return React.createElement(LoaderPortal, {
        "aria-label": "loader container",
        id: id
      }, React.createElement(FocusTrap, {
        focusTrapOptions: {
          escapeDeactivates: false
        },
        active: !disableFocusLock
      }, React.createElement(StyledOverlay, {
        active: active,
        "aria-label": "loader overlay",
        fullscreen: fullscreen,
        surface: surface
      }, React.createElement(StyledLoader, {
        tabIndex: 0,
        active: active,
        "aria-label": "loader animation",
        surface: surface
      }))));
    }
    return React.createElement(StyledOverlay, {
      active: active,
      "aria-label": "loader overlay",
      surface: surface
    }, React.createElement(StyledLoader, {
      tabIndex: 0,
      active: active,
      "aria-label": "loader animation",
      surface: surface
    }));
  }
  return null;
};
Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;
var Loader$1 = withVDSManager(Loader);

export { Loader$1 as default, defaultProps, propTypes };
