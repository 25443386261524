import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Icon } from "@vds/icons";
import {
  Body,
  Checkbox,
  DatePicker,
  FlexBox,
  IconButton,
  Micro, RadioGroup,
  TextButton, Title,
} from "components";
import { useEffect, useRef, useState } from "react";
import {
  SelectedTag,
  StyledFiltersContainer,
} from "./Search.module";
import {useGetFiltersQuery, useLazyGetSavedSearchQuery, useLazySearchAssetsQuery} from "services/api/api.slice";
import {Category, CategoryArray, SavedSearchResponse, SubCategory, Tag} from "interfaces";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import {getLocalDate, supportsStorage} from "utils";
import {useLocation, useSearchParams} from "react-router-dom";

interface SearchFilterProps {
  setResults: Function;
  setLoading: Function;
  search: string | null;
  pageNumber: number;
  setPageNumber: Function;
  pageSize: number;
  sortBy: number;
  setCategory: Function;
  setSearchTypes: Function;
  setIsError: Function;
  setIsFetching: Function;
  setSearch: Function;
}

interface FiltersProps {
  restricted: boolean;
  expired: boolean;
  expiresAfter: boolean;
  expiresOn: Date | null;
  selectedTags: Array<{ id: string; value: string }>;
  submit: boolean;
}

interface AvailabilityProps {
  restricted: boolean;
  expired: boolean;
  expiresAfter: boolean;
}

export type CategoryOption = {
  label: string;
  value: number;
}

export type SelectedTagProps = {
  id: string;
  value: string;
}

const SearchFilter = ({
  setResults,
  setLoading,
  search,
  pageNumber,
  setPageNumber,
  pageSize,
  sortBy,
  setCategory,
  setSearchTypes,
  setIsError,
  setIsFetching,
  setSearch,
}: SearchFilterProps) => {
  
  const {
    data: initialCategories = { assets: [], templates: [], designAdvisories: [] },
    isFetching,
    isError,
  } = useGetFiltersQuery();
  
  const { state } = useLocation();
  
  useEffect(() => {
    if (isError) {
      setIsError(true);
    } else setIsError(false);
  }, [isError]);
  
  useEffect(() => {
    if (isFetching) {
      setIsFetching(true);
    } else setIsFetching(false);
  }, [isFetching]);
  
  const [subCategories, _setSubCategories] = useState<Array<SubCategory>>([]);
  const [categories, _categories] = useState<Array<Category>>([]);
  const [selectedSearchTypes, _selectedSearchTypes] = useState<Array<string>>(["All"]);
  const [selectedCategories, _selectedCategories] = useState<Array<string>>([]);
  const [selectedSearchType, _selectedSearchType] = useState<number>(0);
  const [selectedCategory, _selectedCategory] = useState<number>( 0);
  const [selectedCategoryId, _selectedCategoryId] = useState<string | null>( null);
  const [categoryOptions, _categoryOptions] = useState<Array<CategoryOption>>([]);
  const savedSearchApplied = useRef(false);
  const updateSearchHistory = useRef(false);
  const emptyAvailability = {
    restricted: true,
    expired: false,
    expiresAfter: false
  }
  const [availabilitySelected, _availabilitySelected] = useState<AvailabilityProps>(emptyAvailability);
  const [savedSearchResponse, _savedSearchResponse] = useState<SavedSearchResponse | null>(null);
  const [allCategories, _setAllCategories] = useState<CategoryArray>([]);

  const searchTypeOptions = [
    { value: 0, label: "All" },
    { value: 1, label: "Assets" },
    { value: 2, label: "Guidelines" },
    { value: 3, label: "Templates" },
    { value: 4, label: "Compliance" },
    { value: 5, label: "Retail" },
    { value: 6, label: "Training" },
  ];

  const emptyFilter = {
      restricted: true,
      expired: false,
      expiresAfter: false,
      expiresOn: null,
      selectedTags: [],
      submit: false,
    };
  const [filters, _filters] = useState<FiltersProps>(emptyFilter);
  
  const [searchTypeExpanded, _searchTypeExpanded] = useState<boolean>(true);
  const [categoryExpanded, _categoryExpanded] = useState<boolean>(false);
  const [availabilityExpanded, _availabilityExpanded] = useState<boolean>(false);
  
  
  const setInitialCategories = () => {
    _setAllCategories([
      ...initialCategories.assets,
      ...initialCategories.templates,
      ...initialCategories.designAdvisories,
    ]);
  };
  
  useEffect(() => {
    if (!isFetching) {
      setInitialCategories();
    }
  }, [isFetching]);
  
  useEffect(() => {
    if (allCategories.length > 0 && selectedSearchTypes.length > 0) {
      let tempCategories: Category[] = [];
      if (selectedSearchTypes.includes("asset") && selectedSearchTypes.length === 1) {
        tempCategories = initialCategories.assets.map((category) => category);
      } else if (selectedSearchTypes.includes("template") && selectedSearchTypes.length === 1) {
        tempCategories = initialCategories.templates.map((category) => category);
      } else if (selectedSearchTypes.includes("retail") && selectedSearchTypes.length === 1) {
        tempCategories = initialCategories.designAdvisories.map((category) => category);
      }
      _categories(tempCategories);
    }
  }, [allCategories, selectedSearchTypes]);
  
  const handleRestricted = () => {
    _availabilitySelected((prevState) => {
      return {...prevState, restricted: !prevState.restricted};
    });
    // console.log('filters reset in handleRestricted');
      _filters((existing: FiltersProps) => {
        return { ...existing, submit: true, restricted: !existing.restricted };
      });
    };
  const handleExpired = () => {
      _availabilitySelected((prevState) => {
        return {...prevState, expired: !prevState.expired};
      });
    // console.log('filters reset in handleExpired');
      _filters((existing: FiltersProps) => {
        return { ...existing, submit: true, expired: !existing.expired };
      });
    };
  const handleExpiresAfter = () => {
      _availabilitySelected((prevState) => {
        return {...prevState, expiresAfter: !prevState.expiresAfter};
      });
    // console.log('filters reset in handleExpiresAfter');
      _filters((existing: FiltersProps) => {
        return {
          ...existing,
          submit: filters.expiresAfter && !!filters.expiresOn,
          expiresAfter: !filters.expiresAfter,
          expiresOn: null,
        };
      });
    };
  const selectExpiration = (value: Date, submit: boolean) => {
    // console.log('filters reset in selectExpiration');
      _filters((existing: FiltersProps) => {
        return { ...existing, expiresOn: value, submit: submit };
      });
    };
  const handleClearExpiration = () => {
    // console.log('filters reset in handleClearExpiration');
      _filters((existing: FiltersProps) => {
        return { ...existing, submit: true, expiresOn: null };
      });
    };
  
  const [searchParams] = useSearchParams();
  const [getSavedSearch] = useLazyGetSavedSearchQuery();
  
  // this gets the searchtype and saves the saved search response in a state to use later as search settings are systematically handled
  const initiateSavedSearch = (response: SavedSearchResponse) => {
    const searchTypeOptionIndex = searchTypeOptions.findIndex((option) => option.label.toLowerCase() === response.searchType || (option.label.toLowerCase() === "assets" && response.searchType === "asset") || (option.label.toLowerCase() === "templates" && response.searchType === "template") || (option.label.toLowerCase() === "guidelines" && response.searchType === "guideline") || (option.label.toLowerCase() === "all" && response.searchType === "all"));
    // store saved search response for future loading steps
    _savedSearchResponse(response);
    // need to set the search type first so unified search component can get the categories based on which search type is selected
    handleManualSearchTypeChange(searchTypeOptionIndex);
  };
  
  // if there's a saved search and it hasn't been applied yet, get the saved search based on the params and set the search type
  useEffect(() => {
      if (searchParams.get("saved") && !savedSearchApplied.current) {
        getSavedSearch(searchParams.get("saved") || "")
        .unwrap()
        .then((response: SavedSearchResponse) => {
          window.history.replaceState({}, document.title);
          initiateSavedSearch(response);
        })
        .catch(() => {
          handleManualSearchTypeChange(0);
          window.history.replaceState({}, document.title);
          dispatch(
            setNotification({
              type: "error",
              message:
                "An error has occurred, unable to load saved search. Please try again.",
            })
          );
        });
      }
  }, [searchParams]);
  
  async function setAssetRetailTemplateSavedSearch(categoryOptionIndex?: number, subCategoryId?: string, tempFilters?: FiltersProps) {
    // make sure there are category options and that the selected category index is not larger than 1 more than the total number of options. the plus one is for the added all for asset categories. templates and retail do not have an all option.
    if (categoryOptionIndex !== undefined && (categoryOptionIndex >= 0 && categoryOptions.length > 0 && categoryOptionIndex <= (categoryOptions.length + 1))) {
      // set the category
      handleManualCategoryChange(categoryOptionIndex);
      // if there is a saved search subcategory and that subcategory is not all, set the subcategory, the filters, and set the state that tracks the availability filters
      if (subCategoryId && subCategoryId.length && subCategoryId !== "all") {
        if (tempFilters) {
          // console.log('there are category options, subcategory id exists, about to set filters');
          _filters(tempFilters);
          _availabilitySelected({expiresAfter: tempFilters.expiresAfter, expired: tempFilters.expired, restricted: tempFilters.restricted});
        }
        
      } else {
        if (tempFilters) {
          // console.log('there are category options, subcategory id does not exist, about to set filters');
          // otherwise just set the filters and the state that tracks the availability filters
          _filters(tempFilters);
          _availabilitySelected({expiresAfter: tempFilters.expiresAfter, expired: tempFilters.expired, restricted: tempFilters.restricted});
        }
      }
    } else {
      // if there are no category options like for training, guidelines, compliance, or all, just set the filters and state that tracks the availability filters
      if (tempFilters && (selectedSearchTypes.includes("compliance") || selectedSearchTypes.includes("training") || selectedSearchTypes.includes("guideline") || selectedSearchTypes.includes("all"))) {
        // console.log('there are not category options, selected searchtypes includes compliance, training, guideline, or all, about to set filters');
        _filters(tempFilters);
        _availabilitySelected({expiresAfter: tempFilters.expiresAfter, expired: tempFilters.expired, restricted: tempFilters.restricted});
      }
    }
    
  };
  
  // once the saved search response and search type have been set, start working through all the possible saved search combinations
  useEffect(() => {
    // there must be a selected search type. this is the only setting that is mandatory
    if (savedSearchResponse && !savedSearchApplied.current && selectedSearchTypes.length > 0) {
      const query = savedSearchResponse.searchObject
        ? JSON.parse(savedSearchResponse.searchObject)
        : null;
      
      if (query?.Query && query?.Query.length > 0) {
        setSearch(query?.Query);
      }
      
      // the following logic is only for assets, templates, and retail because they can have categories, subcategories and tags, but guidelines, compliance, training and all do not have them
      if (selectedSearchTypes.includes("asset") || selectedSearchTypes.includes("template") || selectedSearchTypes.includes("retail")) {
        // look for category id
        const category = query?.Filters.find(
          (filter: any) => filter.Type === "category"
        )?.Value;
        let categoryOptionIndex = 0;
        
        // look for subcategory id
        const subCategoryId = query?.Filters.find(
          (filter: any) => filter.Type === "subcategory"
        )?.Value;
        
        // look for tags. this is a list of ids as strings that will later need to be remapped to a list of objects
        const tagIds = query?.Filters.filter(
          (filter: any) => filter.Type === "tag"
        ).reduce((prev: any, curr: any) => [...prev, curr.Value], []);
        
        // before trying to set the selected category, must get categories from the selected search type and assign them to the category options
        if (categoryOptions.length > 0 && categories && categories.length) {
          
          let tags: SelectedTagProps[] = [];
          
          // if there is a category id in the saved search, look through the possible categories to match on the id
          if (category !== undefined) {
            const categoryName = categories?.find((fullCategory) => fullCategory.id === category);
            // if the category is found, search through the category options to match on the name and store that index
            if (categoryName) {
              categoryOptionIndex = categoryOptions.findIndex((option) => option.label === categoryName.value);
              
              // if there are tag ids, need to look at each subcategory under that category and its tags and match on the id, then push to a list the full tag object which is the required format for the search query
              if (tagIds.length > 0) {
                categoryName.subCategories.forEach((subCategory) => {
                  tagIds.forEach((tagId: string) => {
                    subCategory.tags.find((tag) => {
                      if (tag.id === tagId) {
                        tags.push(tag);
                      }
                    });
                  });
                });
              }
            } else {
              // if there is no category found, set it to the default which is all for assets and the first and possibly only option for templates and retail
              categoryOptionIndex = 0;
              // add the availability filters and tags
              let tempFilters: FiltersProps = {...emptyFilter};
              tempFilters.submit = true;
              tempFilters.selectedTags = tags;
              
              tempFilters.restricted =
                query?.IsRestricted === undefined
                  ? true
                  : query?.IsRestricted;
              
              tempFilters.expired =
                query?.IsExpired === undefined
                  ? false
                  : query?.IsExpired;
              
              if (!!query?.ExcludeWithinExpiredDate) {
                tempFilters.expiresAfter = true;
                tempFilters.expiresOn = getLocalDate(query?.ExcludeWithinExpiredDate);
              }
              setAssetRetailTemplateSavedSearch(categoryOptionIndex, subCategoryId, tempFilters);
            }
            
          } else if (!category && (subCategoryId || tags.length > 0)) {
            
            // if there are subcats and tags but no category, find matching category in the search type's category list, based on finding the category which has a subcategory that matches on the saved search subcategory's id
            if (subCategoryId) {
              const inferredCategory = categories?.find((category) => {
                if (category.subCategories.find((tempSubCategory) => tempSubCategory.id === subCategoryId)) {
                  return category;
                }
              });
              
              if (inferredCategory) {
                // if there are is an inferred category, find matching category index in category options
                categoryOptionIndex = categoryOptions.findIndex((option) => option.label === inferredCategory.value);
                if (tagIds.length > 0) {
                  // if there are tag ids, need to look at each subcategory under that category and its tags and match on the id, then push to a list the full tag object which is the required format for the search query
                  inferredCategory.subCategories.forEach((subCategory) => {
                    tagIds.forEach((tagId: string) => {
                      subCategory.tags.find((tag) => {
                        if (tag.id === tagId) {
                          tags.push(tag);
                        }
                      });
                    });
                  });
                }
              } else {
                // for categories previously saved as subcategories, if the subcategory id cannot be found within any of the categories' subcategories check for the id within categories
                const categoryName = categories?.find((fullCategory) => fullCategory.id === subCategoryId);
                // if the category is found, search through the category options to match on the name and store that index
                if (categoryName) {
                  categoryOptionIndex = categoryOptions.findIndex((option) => option.label === categoryName.value);
                  // if there are tag ids, need to look at each subcategory under that category and its tags and match on the id, then push to a list the full tag object which is the required format for the search query
                  if (tagIds.length > 0) {
                    categoryName.subCategories.forEach((subCategory) => {
                      tagIds.forEach((tagId: string) => {
                        subCategory.tags.find((tag) => {
                          if (tag.id === tagId) {
                            tags.push(tag);
                          }
                        });
                      });
                    });
                  }
                }
              }
            }
            // else if (!subCategoryId && !category || (!category && subCategoryId === "all")) {
            //   // if there are tags but no subcategory and no category, or if there is not category and the subcategory is all, set the category to all
            //   _selectedCategories(["All"]);
            // }
            
            
          } else if ((!category && !subCategoryId && tags.length === 0) || (!category && subCategoryId === "all" && tags.length === 0)) {
            // if there is no category, subcategory, or tags, or if there is no category, no tags, and the subcategory is all, set the category to all
            _selectedCategories(["All"]);
          }
          
          // add the availability filters and tags
          let tempFilters: FiltersProps = {...emptyFilter};
          tempFilters.submit = true;
          tempFilters.selectedTags = tags;
          
          tempFilters.restricted =
            query?.IsRestricted === undefined
              ? true
              : query?.IsRestricted;
          
          tempFilters.expired =
            query?.IsExpired === undefined
              ? false
              : query?.IsExpired;
          
          if (!!query?.ExcludeWithinExpiredDate) {
            tempFilters.expiresAfter = true;
            tempFilters.expiresOn = getLocalDate(query?.ExcludeWithinExpiredDate);
          }
        
          // call the function where the category, subcategory, and filters states get set
          setAssetRetailTemplateSavedSearch(categoryOptionIndex, subCategoryId, tempFilters);
          
        } else if (!category && !subCategoryId && tagIds.length === 0) {
          
          // if there are no category options, no category, no subcategory, and no tags, add the availability filters only
          let tempFilters: FiltersProps = {...emptyFilter};
          tempFilters.submit = true;
          
          tempFilters.restricted =
            query?.IsRestricted === undefined
              ? true
              : query?.IsRestricted;
          
          tempFilters.expired =
            query?.IsExpired === undefined
              ? false
              : query?.IsExpired;
          
          if (!!query?.ExcludeWithinExpiredDate) {
            tempFilters.expiresAfter = true;
            tempFilters.expiresOn = getLocalDate(query?.ExcludeWithinExpiredDate);
          }
          setAssetRetailTemplateSavedSearch(categoryOptionIndex, subCategoryId, tempFilters);
        }
        
      } else if (selectedSearchTypes.includes("all") || selectedSearchTypes.includes("guideline") || selectedSearchTypes.includes("compliance") || selectedSearchTypes.includes("training")) {
        
        // if the search type is all, compliance, training, or guideline, just set the availability filters
        let tempFilters: FiltersProps = {...emptyFilter};
        tempFilters.submit = true;
        
        tempFilters.restricted =
          query?.IsRestricted === undefined
            ? true
            : query?.IsRestricted;
        
        tempFilters.expired =
          query?.IsExpired === undefined
            ? false
            : query?.IsExpired;
        
        if (!!query?.ExcludeWithinExpiredDate) {
          tempFilters.expiresAfter = true;
          tempFilters.expiresOn = getLocalDate(query?.ExcludeWithinExpiredDate);
        }
        setAssetRetailTemplateSavedSearch(undefined, undefined, tempFilters);
      }
      
    }
    
  }, [savedSearchResponse, categoryOptions, selectedSearchTypes]);
  
  // if selected tag is deselected and no other tags are selected, reset the subcategories back to the full list
  const resetSubCategories = () => {
    if (categories && categories.length > 0) {
      // if the searching within assets, and the selected category is not all get the selected category's full list of subcategories, and reset the displayed subcategories
      if (selectedSearchTypes.includes("asset") && selectedCategory !== 0) {
        if (categories[selectedCategory - 1] && categories[selectedCategory - 1].subCategories && categories[selectedCategory - 1].subCategories.length > 0) {
          const newSubCategories = categories[selectedCategory - 1].subCategories.map((subcat) => subcat);
          _setSubCategories(newSubCategories);
        }
      } else if (selectedSearchTypes.includes("asset") && selectedCategory === 0) {
        _setSubCategories([]);
      } else {
        // if the searching within retail or templates, get the selected category's full list of subcategories, and reset the displayed subcategories
        if (categories[selectedCategory] && categories[selectedCategory].subCategories && categories[selectedCategory].subCategories.length > 0) {
          const newSubCategories = categories[selectedCategory].subCategories.map((subcat) => subcat);
          _setSubCategories(newSubCategories);
        }
      }
      
    }
  };
  
  const handleSelectFilter = ({ id, value }: { id: string; value: string }) => {
    // console.log('handleSelectFilter called');
    // if selected tags includes the tag id, filter out all the tags that don't match in order to remove that selected tag
      if (filters.selectedTags.some((tag) => tag.id === id)) {
        _filters((existing: FiltersProps) => {
          return {
            ...existing,
            submit: true,
            selectedTags: filters.selectedTags.filter((tag) => tag.id !== id),
          };
        });
      } else {
        // add the tag to the list of selected tags
        !!filters.selectedTags.length
          ? _filters((existing: FiltersProps) => {
              return {
                ...existing,
                submit: true,
                selectedTags: [...filters.selectedTags, { id, value }],
              };
            })
          : _filters((existing: FiltersProps) => {
              return {
                ...existing,
                submit: true,
                selectedTags: [{ id, value }],
              };
            });
      }
    };
  
  // reset selected tags when changing categories
  useEffect(() => {
    // only proceed if there is not a saved search or if there is a saved search and it has been applied
      if (!(searchParams.get("saved")) || ((searchParams.get("saved")) && savedSearchApplied.current === true)) {
        // if there is a selected category
        // console.log('selectedCategoryId: ', selectedCategoryId);
        if (selectedCategoryId) {
          // if there are subcategories, keep the date filters but reset tags
          // console.log('filters set because category id changed');
          _filters((existing: FiltersProps) => {
            return { ...existing, submit: true, selectedTags: [] };
          });
        }
        
      }
  }, [selectedCategoryId]);
  
  const [searchAssets] = useLazySearchAssetsQuery();
  
  const handleSubmit = () => {
    setLoading(true);
    searchAssets({
      search,
      searchTypes: selectedSearchType === 0 ? ["all"] : selectedSearchType === 1 ? ["asset"] : selectedSearchType === 2 ? ["guideline"] : selectedSearchType === 3 ? ["template"] : selectedSearchType === 4 ? ["compliance"] : selectedSearchType === 5 ? ["retail"] : ["training"],
      pageNumber,
      pageSize,
      sortBy: sortBy === 1 ? "popularity" : sortBy === 2 ? "last-modified" : sortBy === 3 ? "old-to-new" : sortBy === 4 ? "new-to-old" : sortBy === 5 ? "a-to-z" : sortBy === 6 ? "z-to-a" : "relevance",
      updateSearchHistory: updateSearchHistory.current,
      correlationId,
      includeRestricted: filters.restricted,
      includeExpired: filters.expired,
      expiresOn: filters.expiresOn
        ? filters.expiresOn.toISOString()
        : undefined,
      categoryId: selectedCategoryId === "All" || selectedCategoryId === null ? undefined : selectedCategoryId,
      selectedTags: filters.selectedTags,
    })
    .unwrap()
    .then((response) => {
      _setCorrelationId(response.correlationId);
      setResults({
        search,
        searchTypes: selectedSearchType === 0 ? ["all"] : selectedSearchType === 1 ? ["asset"] : selectedSearchType === 2 ? ["guideline"] : selectedSearchType === 3 ? ["template"] : selectedSearchType === 4 ? ["compliance"] : selectedSearchType === 5 ? ["retail"] : ["training"],
        pageNumber,
        pageSize,
        sortBy: sortBy === 1 ? "popularity" : sortBy === 2 ? "last-modified" : sortBy === 3 ? "old-to-new" : sortBy === 4 ? "new-to-old" : sortBy === 5 ? "a-to-z" : sortBy === 6 ? "z-to-a" : "relevance",
        updateSearchHistory: updateSearchHistory.current,
        correlationId: response.correlationId,
        includeRestricted: filters.restricted,
        includeExpired: filters.expired,
        expiresOn: filters.expiresOn
          ? filters.expiresOn.toISOString()
          : undefined,
        categoryId: selectedCategoryId,
        selectedTags: filters.selectedTags,
        results: response.results,
        total: response.total,
        totalPages: response.totalPages,
      });
      
      if (categories && categories.length > 0) {
        // start with all subcategories under the selected category
        // do the minus 1 if it is asset, just use selected category if retail or template
        if (selectedSearchTypes.includes("asset")) {
          if (categories[selectedCategory - 1]) {
            const newSubCategories = categories[selectedCategory - 1].subCategories.map((subcat) => subcat);
            
            // filter out any subcategories that don't contain the category ids from the response
            let filteredCategories = JSON.parse(JSON.stringify(newSubCategories));
            filteredCategories = filteredCategories.filter(
              (subCategory: SubCategory) => {
                return Object.keys(response.filters.categories).includes(
                  subCategory.categoryId
                );
              }
            );
            
            // filter out any subcategories whose id doesn't match the response list of subcategories
            filteredCategories = filteredCategories.filter(
              (subCategory: SubCategory) =>
                Object.keys(response.filters.subCategories).includes(
                  subCategory.id
                )
            );
            
            // filter out any tags that are not part of the response
            filteredCategories = filteredCategories.map(
              (subCategory: SubCategory) => {
                return {
                  ...subCategory,
                  tags: subCategory.tags.filter((tag: Tag) =>
                    Object.keys(response.filters.tags).includes(tag.id)
                  ),
                };
              }
            );
            
            
            // set filtered subcategories to get rid of any that do not have results; usually narrows it down to one subcategory but not always
            _setSubCategories(filteredCategories);
          }
        } else if (categories[selectedCategory] && (selectedSearchTypes.includes("template") || selectedSearchTypes.includes("retail"))) {
          const newSubCategories = categories[selectedCategory].subCategories.map((subcat) => subcat);
          
          if (response.filters.categories) {
            // filter out any subcategories that don't contain the category ids from the response
            let filteredCategories = JSON.parse(JSON.stringify(newSubCategories));
            filteredCategories = filteredCategories.filter(
              (subCategory: SubCategory) => {
                return Object.keys(response.filters.categories).includes(
                  subCategory.categoryId
                );
              }
            );
            
            if (response.filters.subCategories) {
              // filter out any subcategories whose id doesn't match the response list of subcategories
              filteredCategories = filteredCategories.filter(
                (subCategory: SubCategory) =>
                  Object.keys(response.filters.subCategories).includes(
                    subCategory.id
                  )
              );
              
              // filter out any tags that are not part of the response
              filteredCategories = filteredCategories.map(
                (subCategory: SubCategory) => {
                  return {
                    ...subCategory,
                    tags: subCategory.tags.filter((tag: Tag) =>
                      Object.keys(response.filters.tags).includes(tag.id)
                    ),
                  };
                }
              );
              
              // set filtered subcategories to get rid of any that do not have results; usually narrows it down to one subcategory but not always
              _setSubCategories(filteredCategories);
            }
          }
          
        } else {
          _setSubCategories([]);
        }
      }
      setLoading(false);
      updateSearchHistory.current = false;
    })
    .catch((error: any) => {
      console.error(error);
      handleOnError();
    })
    .finally(() => {
      if (localStorage.getItem("searchPathFromGuidelines") !== null) {
        localStorage.removeItem("searchPathFromGuidelines");
      }
    });
  };
  
  // if selected tag is deselected and no other tags are selected, reset the subcategories back to the full list
  useEffect(() => {
    // only proceed if there is not a saved search or if there is a saved search and it has been applied and filters have changed
    if ((!(searchParams.get("saved")) || ((searchParams.get("saved")) && savedSearchApplied.current === true)) && (!state?.saved?.subCategoryId || (state?.saved?.subCategoryId && savedSearchApplied.current === true))) {
      if (filters.selectedTags.length === 0) {
        resetSubCategories();
      }
    } else if (searchParams.get("saved") && !savedSearchApplied.current) {
      // if there is a saved search and it has not been applied yet, the saved search response has been stored, and the filters have changed
      if (savedSearchResponse) {
        const query = savedSearchResponse.searchObject
          ? JSON.parse(savedSearchResponse.searchObject)
          : null;
        
        const category = query?.Filters.find(
          (filter: any) => filter.Type === "category"
        )?.Value;
        
        const subCategoryId = query?.Filters.find(
          (filter: any) => filter.Type === "subcategory"
        )?.Value;
        const tagIds = query?.Filters.filter(
          (filter: any) => filter.Type === "tag"
        ).reduce((prev: any, curr: any) => [...prev, curr.Value], []);

        const categoryName = categories?.find((fullCategory) => fullCategory.id === category);
        
        // only submit the filters if the filters have been set to submit and there is no category in the saved search, or the category in the saved search is no longer active or cannot be found and there is no subcategory or the subcategory is all, and there are no tags. This is a safeguard for inactive categories and to prevent searches with categories, subcategories, and tags from calling the search api if the filters got set first
        if (!categoryName || (!category && (!subCategoryId || subCategoryId === "all") && tagIds.length === 0)) {
          // only submit the search if the filters have not been cleared or reset
          if (filters.submit) {
            savedSearchApplied.current = true;
          }
        }
      }
    }
  }, [filters]);
  
  // prevent looping on initial load once all states have been set
  const initialSearch = useRef(true);
  useEffect(() => {
    if (!localStorage.getItem("prevRoute")?.startsWith('/guidelines/') && !localStorage.getItem("searchPathFromGuidelines")) {
      if (initialSearch.current) {
        initialSearch.current = false;
        return;
      }
      // only proceed if there is not a saved search or if there is a saved search and it has been applied and user has interacted with sort, page size, or pagination
      if ((!(searchParams.get("saved")) || ((searchParams.get("saved")) && savedSearchApplied.current === true)) && (!state?.saved?.subCategoryId || (state?.saved?.subCategoryId && savedSearchApplied.current === true))) {
        // console.log('pageNumber, pageSize, or sortBy changed');
        handleSubmit();
      }
    }
  }, [pageNumber, pageSize, sortBy]);

  // for submitting search
  const firstUpdateSearch = useRef(true);
  
  useEffect(() => {
    if (firstUpdateSearch.current) {
      updateSearchHistory.current = true;
      firstUpdateSearch.current = false;
      return;
    }
    if (pageNumber !== 1) {
      updateSearchHistory.current = true;
      setPageNumber(1);
    } else {
      // only proceed if there is not a saved search or if there is a saved search and it has been applied and the search term has changed
      if ((!(searchParams.get("saved")) || ((searchParams.get("saved")) && savedSearchApplied.current === true)) && (!state?.saved?.subCategoryId || (state?.saved?.subCategoryId && savedSearchApplied.current === true))) {
        // console.log('search term changed');
        updateSearchHistory.current = true;
        handleSubmit();
      }
    }
  }, [search]);
  
  useEffect(() => {
    if (state?.saved?.subCategoryId && !savedSearchApplied.current) {
      // console.log('subCategoryId: ', state?.saved?.subCategoryId);
      if (!selectedSearchTypes.includes("asset")) {
        
        // need to set the search type first so component can get the categories based on which search type is selected
        handleManualSearchTypeChange(1);
      } else {
        if (categoryOptions.length > 0 && categories && categories.length > 0) {
          // console.log('categoryOptions: ', categoryOptions);
          
          // if there is a category id in the saved search, look through the possible categories to match on the id
          const categoryName = categories?.find((fullCategory) => fullCategory.id === state?.saved?.subCategoryId);
          // if the category is found, search through the category options to match on the name and store that index
          if (categoryName) {
            const categoryOptionIndex = categoryOptions.findIndex((option) => option.label === categoryName.value);
            handleManualCategoryChange(categoryOptionIndex);
          }
        }
      }
    }
  }, [state, categories, categoryOptions]);

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      if (!localStorage.getItem("prevRoute")?.startsWith('/guidelines/') && !localStorage.getItem("searchPathFromGuidelines")) {
        // if there's not a saved search or if the initial saved search was applied and the filters have changed, submit the search. this is where the saved search will ultimately get called once savedSearchApplied has been set to true if the saved search does not have a selected category
        if (!(searchParams.get("saved")) || ((searchParams.get("saved")) && savedSearchApplied.current === true) && (!state?.saved?.subCategoryId || (state?.saved?.subCategoryId && savedSearchApplied.current === true))) {
          if (filters.submit) {
            // console.log('filters changed. Filters: ', filters);
            handleSubmit();
          }
        }
      }
      
    }
  }, [filters]);
  
  
  useEffect(() => {
    if (searchParams.get("saved") && !savedSearchApplied.current && selectedCategoryId !== null) {
      // if there is a saved search but it hasn't been applied yet, there is a selected category, and the filters have not been cleared or reset, submit search
      savedSearchApplied.current = true;
      if (filters.submit) {
        // console.log('there is a saved search and the filters changed');
        handleSubmit();
      }
    } else if (!savedSearchApplied.current && state?.saved?.subCategoryId && (selectedCategoryId !== null || selectedCategoryId !== "All")) {
      if (filters.submit) {
        savedSearchApplied.current = true;
        state?.saved && window.history.replaceState({}, document.title);
        // console.log('user navigated from a category link and the filters changed');
        handleSubmit();
      }
    }
  }, [filters, selectedCategoryId]);
  
  useEffect(() => {
    // console.log('selectedSearchTypes: ', selectedSearchTypes);
    if (!localStorage.getItem("prevRoute")?.startsWith('/guidelines/') && !localStorage.getItem("searchPathFromGuidelines")) {
      if (pageNumber !== 1) {
        setPageNumber(1);
      } else {
        // only proceed if there is not a saved search or if there is a saved search and it has been applied
        if (((!(searchParams.get("saved")) || ((searchParams.get("saved")) && savedSearchApplied.current === true))) && (!state?.saved?.subCategoryId || (state?.saved?.subCategoryId && savedSearchApplied.current === true))) {
          
          if ((selectedSearchTypes.includes("asset") || selectedSearchTypes.includes("template") || selectedSearchTypes.includes("retail"))) {
            if (categoryOptions && categoryOptions.length > 0 && (!state?.saved?.subCategoryId || (state?.saved?.subCategoryId && savedSearchApplied.current === true))) {
              // only change the category for asset, template, and retail once there are category options. the default selection is the first option, which is all for assets and possibly the only category for template or retail
              handleManualCategoryChange(0);
            }
          }
          else {
            // if guidelines, compliance, training, or all, go ahead and submit because they don't have categories
            //   console.log('selectedSearchTypes changed');
            handleSubmit();
          }
        }
      }
    }
  }, [selectedSearchTypes, categoryOptions]);
  

  const dispatch = useDispatch(),
    handleOnError = () => {
      dispatch(
        setNotification({
          type: "error",
          message: "An error has occurred. Please try again.",
        })
      );
      setLoading(false);
    };

  const [correlationId, _setCorrelationId] = useState<string>();
  
  const handleManualSearchTypeChange = (selected: number) => {
    // when changing search types, clear category options, selected category, subcategories, and clear selected tags on filters but not the availability settings
    _categoryOptions([]);
    _selectedCategoryId(null);
    _setSubCategories([]);
    if ((!(searchParams.get("saved")) || ((searchParams.get("saved")) && savedSearchApplied.current === true)) && (!state?.saved?.subCategoryId || (state?.saved?.subCategoryId && savedSearchApplied.current === true))) {
      if (filters.submit === false || filters.selectedTags.length > 0) {
        // console.log("changing the search type, filter tags reset and submit set to true");
        _filters((existing: FiltersProps) => {
          return { ...existing, submit: true, selectedTags: [] };
        });
      }
    }
    if (selected === 0) {
      setSearchTypes(["all"]);
      _selectedSearchTypes(["all"]);
    } else if (selected === 1) {
      setSearchTypes(["asset"]);
      _selectedSearchTypes(["asset"]);
    } else if (selected === 2) {
      setSearchTypes(["guideline"]);
      _selectedSearchTypes(["guideline"]);
    } else if (selected === 3) {
      setSearchTypes(["template"]);
      _selectedSearchTypes(["template"]);
    } else if (selected === 4) {
      setSearchTypes(["compliance"]);
      _selectedSearchTypes(["compliance"]);
    } else if (selected === 5) {
      setSearchTypes(["retail"]);
      _selectedSearchTypes(["retail"]);
    } else if (selected === 6) {
      setSearchTypes(["training"]);
      _selectedSearchTypes(["training"]);
    }
    _selectedSearchType(selected);
  };
  
  // need this for radio buttons so it doesn't loop on load
  const handleSearchTypeChange = (selected: number) => () => {
    handleManualSearchTypeChange(selected);
  };
  
  const handleExpandSearchType = () => {
    _searchTypeExpanded((prevState) => !prevState);
  };
  
  
  const handleManualCategoryChange = (selected: number) => {
    if (categories && categories.length > 0) {
      // if asset search type, must account for the all as the first option, so move the index back one, but only if the selected options is not all, whose index is 0
      if (selectedSearchTypes.includes("asset") && selected > 0) {
        setCategory(categories[selected - 1].id);
        _selectedCategories([categories[selected - 1].id])
      } else if (selectedSearchTypes.includes("asset") && selected === 0) {
        // if asset search type and user selects all, set selected category id to null and do not call the setCategory function that is passed in
        _selectedCategories(["All"]);
        _selectedCategoryId("All");
      } else {
        // for everything else, set the selected option as normal
        setCategory(categories[selected].id);
        _selectedCategories([categories[selected].id])
      }
      // this updates the value of the field
      _selectedCategory(selected);
      
      // if asset search type and all categories is not selected, set the category id and then set the subcategories based on that category
      if (categoryOptions[selected].label !== "All" && selectedSearchTypes.includes("asset")) {
        _selectedCategoryId(categories[selected - 1].id);
        // if the category has subcategories, set the state for subcategories, otherwise reset subcategories to an empty array
        if (categories[selected - 1].subCategories.length > 0) {
          const newSubCategories = categories[selected - 1].subCategories.map((subcat) => subcat);
          _setSubCategories(newSubCategories);
        } else _setSubCategories([]);
        
      } else if (!selectedSearchTypes.includes("asset")) {
        // if search type is anything other than asset, set the category id and subcategories state based on that category's subcategories. if it does not have any, reset it to an empty array
        _selectedCategoryId(categories[selected].id);
        if (categories[selected].subCategories.length > 0) {
          const newSubCategories = categories[selected].subCategories.map((subcat) => subcat);
          _setSubCategories(newSubCategories);
        } else _setSubCategories([]);
        
      } else {
        // this is for if the search type is asset, category is all, so there is no selected category id, subcategories, or tags. when resetting filters, leave the availability filters the same, just remove the tags
        _selectedCategoryId("All");
        _setSubCategories([]);
        // if ((!(searchParams.get("saved")) || ((searchParams.get("saved")) && savedSearchApplied.current === true)) && (!state?.saved?.subCategoryId || (state?.saved?.subCategoryId && savedSearchApplied.current === true))) {
        //   if (filters.submit === false || filters.selectedTags.length > 0) {
        //     console.log('changing the category, filter tags reset and submit set to true');
        //     _filters((existing: FiltersProps) => {
        //       return {...existing, submit: true, selectedTags: []};
        //     });
        //   }
        // }
      }
    }
  };
  
  // need this for radio buttons so it doesn't loop on load
  const handleCategoryChange = (selected: number) => () => {
    handleManualCategoryChange(selected);
  };
  
  const getCategoryOptions = () => {
    if (categories && categories.length > 0) {
      const tempCategories: CategoryOption[] = categories.map((category, index) => {
        let option = {
          label: category.value,
          value: index
        };
        // add 1 to the value because for assets, first choice has to be all
        if (selectedSearchTypes.includes("asset")) {
          option = {
            label: category.value,
            value: index + 1
          };
        }
        return option;
      });
      // add All as the first option
      if (selectedSearchTypes.includes("asset")) {
        // unshift adds the all option at the beginning of the array
        tempCategories.unshift({label: "All", value: 0});
      }
      _categoryOptions(tempCategories);
    }
  };
  
  useEffect(() => {
    if (categories && categories.length > 0) {
      getCategoryOptions();
    }
  }, [categories]);
  
  const handleExpandCategories = () => {
    _categoryExpanded((prevState) => !prevState);
  };
  
  const handleExpandAvailability = () => {
    _availabilityExpanded((prevState) => !prevState);
  };
  
  // console.log('selected tags: ', filters.selectedTags);
  // console.log('selected search types: ', selectedSearchTypes);
  // console.log('selected categories: ', selectedCategories);
  
  
  return (
    <StyledFiltersContainer data-testid="search-filter">
      <FlexBox justify="space-between" row>
        <Title size="medium" pd="1rem 1.5rem">
          Filters
        </Title>
        {filters.selectedTags.length > 0 && (
          <TextButton
            pd="1.25rem 0 0"
            onClick={() =>
              _filters((existing: FiltersProps) => {
                return { ...existing, selectedTags: [] };
              })
            }
            testId="clear-all-button"
          >
            <Body>Clear all</Body>
          </TextButton>
        )}
      </FlexBox>
      {filters.selectedTags.length > 0 && (
        <FlexBox row gap="0.5rem" pd="0 1.5rem 1rem" data-testid="selected-tags" wrap>
          {filters.selectedTags.map(
            (tag: { id: string; value: string }) => (
              <SelectedTag key={tag.id}>
                <Body bold>
                  {tag.value}
                </Body>
                <IconButton
                  name="close"
                  color="#000000"
                  noHover
                  onClick={() => handleSelectFilter(tag)}
                  testId="remove-selected-button"
                />
              </SelectedTag>
            )
          )}
        </FlexBox>
      )}
      
      {/*Search type accordion*/}
      <Accordion
        onChange={handleExpandSearchType}
        expanded={searchTypeExpanded}
        disableGutters
        square
        data-testid="search-types"
        className="first"
      >
        <AccordionSummary
          expandIcon={<Icon name="down-caret" />}
        >
          <Body size="large">
            {`Search Within ${selectedSearchTypes.length > 0 ? `(${selectedSearchTypes.length})` : ''}`}
          </Body>
        </AccordionSummary>
        <AccordionDetails>
          <FlexBox>
            <FlexBox row gap="0.5rem" wrap>
              <RadioGroup
                name="searchType"
                options={searchTypeOptions}
                value={selectedSearchType}
                onChange={(value: number) => handleSearchTypeChange(value)}
              />
            </FlexBox>
          </FlexBox>
        </AccordionDetails>
      </Accordion>
      
      {/*Category accordion*/}
      {categoryOptions && categoryOptions.length > 0 && (selectedSearchTypes.includes("asset") || selectedSearchTypes.includes("template") || selectedSearchTypes.includes("retail")) && (
        <Accordion
          onChange={handleExpandCategories}
          expanded={categoryExpanded}
          disableGutters
          square
          data-testid="categories"
        >
          <AccordionSummary
            expandIcon={<Icon name="down-caret" />}
          >
            <Body size="large">
              {`Category ${selectedCategories.length > 0 ? `(${selectedCategories.length})` : ''}`}
            </Body>
          </AccordionSummary>
          <AccordionDetails>
            <FlexBox>
              <FlexBox row gap="0.5rem" wrap>
                <RadioGroup
                  name="category"
                  options={categoryOptions}
                  value={selectedCategory}
                  onChange={(value: number) => handleCategoryChange(value)}
                />
              </FlexBox>
            </FlexBox>
          </AccordionDetails>
        </Accordion>
      )}
      
      {/*Availability accordion*/}
      {(selectedSearchType === 0 || selectedSearchType === 1 || selectedSearchType === 3 || selectedSearchType === 5) && (
        <Accordion
          onChange={handleExpandAvailability}
          expanded={availabilityExpanded}
          disableGutters
          square
          data-testid="availability"
        >
          <AccordionSummary
            expandIcon={<Icon name="down-caret" />}
          >
            <Body size="large">
              {`Asset Availability ${Object.values(availabilitySelected).filter((value) => value === true).length > 0 ? `(${Object.values(availabilitySelected).filter((value) => value === true).length})` : ''}`}
            </Body>
          </AccordionSummary>
          <AccordionDetails>
            <FlexBox gap="1rem">
              <Checkbox
                selected={filters.restricted}
                onChange={handleRestricted}
                required={false}
              >
                <Body bold>Include restricted assets</Body>
              </Checkbox>
              <Checkbox
                selected={filters.expired}
                onChange={handleExpired}
                required={false}
              >
                <Body bold>Include expired assets</Body>
              </Checkbox>
              <Checkbox
                selected={filters.expiresAfter}
                onChange={handleExpiresAfter}
                required={false}
              >
                <Body bold>Expires after</Body>
              </Checkbox>
              {filters.expiresAfter && (
                <DatePicker
                  value={filters.expiresOn}
                  label="Date"
                  onChange={selectExpiration}
                  data-testid="expires-after-date"
                  onClear={handleClearExpiration}
                />
              )}
            </FlexBox>
          </AccordionDetails>
        </Accordion>
      )}

      {subCategories.map((subCategory: SubCategory) => (
        subCategory.tags.length > 0 ? (
          <Accordion
            key={subCategory.id}
            disableGutters
            square
            data-testid="filters-accordion"
          >
            <AccordionSummary
              expandIcon={<Icon name="down-caret" />}
            >
              <Body size="large">
                {`${subCategory.value} ${filters.selectedTags.filter((selectedTag) => {
                  if (subCategory.tags.find((tag) => tag.id === selectedTag.id)) {
                    return selectedTag;
                  }
                  
                }).length > 0 ? `(${filters.selectedTags.filter((selectedTag) => {
                  if (subCategory.tags.find((tag) => tag.id === selectedTag.id)) {
                    return selectedTag;
                  }
                }).length})` : ''}`}
              </Body>
            </AccordionSummary>
            <AccordionDetails>
              <FlexBox gap="1rem">
                {subCategory.tags.map((tag: Tag) => (
                  <Checkbox
                    key={tag.id}
                    onChange={() =>
                      handleSelectFilter({ id: tag.id, value: tag.value })
                    }
                    selected={
                      !!filters.selectedTags.some(
                        (selected) => selected.id === tag.id
                      )
                    }
                    required={false}
                  >
                    <Body bold>{tag.value}</Body>
                  </Checkbox>
                ))}
              </FlexBox>
            </AccordionDetails>
          </Accordion>
          ) : <></>
      ))}
      <FlexBox pd="1rem 1.5rem">
        <Micro color="#747676">
          Not finding what you’re looking for? Try another search term or search within a different section.
        </Micro>
      </FlexBox>
      
    </StyledFiltersContainer>
  );
};

export default SearchFilter;
