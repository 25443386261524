import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import { withAction } from "HOCs/withAction";
import { forgotPassword, setNotification } from "services";
import mapPropsToValues from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import handleSubmit from "./handleSubmit";
import ResetPasswordForm from "./ResetPasswordForm";

export const ResetPasswordFormContainer = compose(
  withAction((props: any) => forgotPassword(props)),
  withAction((props: any) => setNotification(props), "setNotification"),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
  })
)(ResetPasswordForm);

export default ResetPasswordFormContainer;
