import { useState } from "react";
import { DeleteSavedSearchModalProps } from "interfaces";
import { TextButton, Button, FlexBox, Modal, Title, Body } from "components";
import { useDeleteSavedSearchMutation } from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";

export enum ManagedStatus {
  notSet = "Not Set",
  active = "Active",
  inactive = "Inactive",
  deleted = "Deleted",
  requested = "Requested",
}

const DeleteSavedSearchModal = ({
  savedSearch,
  onClose,
  onComplete,
}: DeleteSavedSearchModalProps) => {
  const [deleteSearch, { isLoading: deleteLoading }] =
    useDeleteSavedSearchMutation();

  const dispatch = useDispatch();

  const callDeleteSearch = () => {
    deleteSearch(savedSearch.id)
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: "Search successfully deleted.",
          })
        );
        onComplete();
      })
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Could not delete search.",
          })
        );
        console.error(error);
      });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal testId="delete-saved-search-modal" open onClose={handleClose} disabled={deleteLoading}>
      <FlexBox>
        <Title size="large" bold pd="0 0 2rem">
          Are you sure you want to delete this search?
        </Title>
        <Body size="large" pd="0 0 2rem">
          Deleted searches are permanently removed from Brand Central and will
          no longer be accessible.
        </Body>
        <FlexBox row gap="1.5rem" align="center" justify="flex-start">
          <Button
            use="primary"
            onClick={callDeleteSearch}
            disabled={deleteLoading}
            data-testid="submit-button"
          >
            Delete search
          </Button>
          <TextButton data-testid="cancel-button" onClick={handleClose} disabled={deleteLoading}>
            Cancel
          </TextButton>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default DeleteSavedSearchModal;
