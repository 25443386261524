import { withFormik } from "formik";
import mapPropsToValues, { FormValues } from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import SaveSearchModal from "./SaveSearchModal";

export const SaveSearchModalContainer = withFormik<
  { searchResponse: any; onClose: Function },
  FormValues
>({
  mapPropsToValues,
  validationSchema,
  handleSubmit: () => null,
  enableReinitialize: true,
  validateOnMount: true,
})(SaveSearchModal);

export default SaveSearchModalContainer;
