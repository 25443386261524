import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as Tabs$1 } from '@vds-core/tabs';
import { TextLinkCaret } from '@vds/buttons';
import { ColorTokens } from '@vds-tokens/color';

const _calculateLineColor = function (surface) {
  return surface === 'dark' ? ColorTokens.palette.gray20.value : ColorTokens.palette.gray85.value;
};
const propTypes = {
  /**
   * Size for tabs
   */
  size: PropTypes.oneOf(['medium', 'large']),
  /**
   * Determines the layout of the Tabs, defaults to horizontal
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * Width of all Tabs when orientation is vertical, defaults to 25%.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If fillContainer prop is passed, it will fill the Tabs to the width of the viewport and all Tabs will be in equal width when orientation is horizontal. This is recommended when there are no more than 2-3 tabs.
   */
  fillContainer: PropTypes.bool,
  /**
   * Tabs will be displayed in surface colors.
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * Minimum Width for All Tabs, when orientation is horizontal.
   */
  minWidth: PropTypes.string,
  /**
   * Enable scrolling animation for tab content.
   */
  animated: PropTypes.bool
};
const defaultProps = {
  orientation: 'horizontal',
  width: '25%',
  minWidth: '44px',
  surface: 'light',
  animated: false
};
const Tabs = function (props) {
  const {
    orientation
  } = props;
  return React.createElement(Tabs$1, Object.assign({}, props, {
    TextLinkCaret: TextLinkCaret,
    orientation: orientation,
    calculateLineColor: _calculateLineColor,
    linePosition: undefined
  }));
};
Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export { Tabs as default };
