import { Grid } from "@mui/material";
import { GridProps } from "interfaces";

const ThreeUp = ({ children, pd, spacing }: GridProps) => {
  return (
    <Grid container spacing={spacing ? spacing : 2} p={pd}>
      {children?.length ? (
        children.map((child: any, i: number) => {
          return (
            <Grid key={i} item xs={12} sm={6} md={4} width="100%">
              {child}
            </Grid>
          );
        })
      ) : (
        <Grid item xs={12} sm={6} md={4} width="100%">
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default ThreeUp;
