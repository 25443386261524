import { TrainingSessionProps } from "../../../interfaces/Training";
import { Body, FlexBox, Section, Title, LinkButton, ThreeUp } from "components";
import MdToHtml from "../../MdToHtml/MdToHtml";
import paths from "../../../routing/paths";
import { formatRecordedTrainingSession } from "../../../utils/formatRecordedTrainingSession";
import { StyledWrapper } from "./NextSession.module";

export type NextSessionProps = {
  session?: TrainingSessionProps;
};

const NextSessionContainer = ({ session }: NextSessionProps) => {
  const getDateDetails = () => {
    if (session) {
      if (session.primaryDate && session.secondaryDate) {
        return (
          formatRecordedTrainingSession(session.primaryDate) +
          " or " +
          formatRecordedTrainingSession(session.secondaryDate)
        );
      } else {
        return formatRecordedTrainingSession(session.primaryDate);
      }
    }
  };

  return session ? (
    <StyledWrapper>
      <Body pd="0 0 2rem 0" color="#ffffff" bold size="large">
        Next session
      </Body>
      <ThreeUp>
        <FlexBox pd="0 0 1rem">
          {session.primaryDate && (
            <Body pd="0 0 0.5rem 0" color="#ffffff" bold size="large">
              {getDateDetails()}
            </Body>
          )}
          <Title pd="0 0 1.5rem 0" color="#ffffff" size="large" bold>
            {session.title}
          </Title>
          <MdToHtml
            content={session.description}
            pSize="large"
            linkSize="large"
            color="#ffffff"
            linkTextDecoration
            pMargin="0"
          />
        </FlexBox>
        <FlexBox>
          {session.topics && (
            <FlexBox pd="0 0 1rem">
              <Title color="#ffffff" size="medium" bold pd="0 0 0.25rem">
                Topics
              </Title>
              <MdToHtml
                content={session.topics}
                pSize="large"
                linkSize="large"
                color="#ffffff"
                linkTextDecoration
                pMargin="0"
              />
            </FlexBox>
          )}
          {session.attendees && (
            <FlexBox pd="0 0 1rem">
              <Title color="#ffffff" size="medium" bold pd="0 0 0.25rem">
                Who should attend
              </Title>
              <MdToHtml
                content={session.attendees}
                pSize="large"
                linkSize="large"
                color="#ffffff"
                linkTextDecoration
                pMargin="0"
              />
            </FlexBox>
          )}
          {session.customDateDetails && (
            <FlexBox pd="0 0 1rem">
              <Title color="#ffffff" size="medium" bold pd="0 0 0.25rem">
                When
              </Title>
              <MdToHtml
                content={session.customDateDetails}
                pSize="large"
                linkSize="large"
                color="#ffffff"
                linkTextDecoration
                pMargin="0"
              />
            </FlexBox>
          )}
        </FlexBox>
        <FlexBox>
          {session.primaryRegistrationHeading && (
            <FlexBox pd="0 0 1rem">
              <Title color="#ffffff" size="medium" bold pd="0 0 0.25rem">
                {session.primaryRegistrationHeading}
              </Title>
              <MdToHtml
                content={session.primaryRegistrationDetails}
                pSize="large"
                linkSize="large"
                color="#ffffff"
                linkTextDecoration
                pMargin="0 0 1.25rem"
              />
              {session.primaryRegistrationLinkText && (
                <LinkButton
                  label={
                    session.primaryRegistrationLinkText
                      ? session.primaryRegistrationLinkText
                      : ""
                  }
                  to={
                    session.primaryRegistrationUrl
                      ? session.primaryRegistrationUrl
                      : paths.SIGNIN
                  }
                />
              )}
            </FlexBox>
          )}
          {session.secondaryRegistrationHeading && (
            <FlexBox>
              <Title color="#ffffff" size="medium" bold>
                {session.secondaryRegistrationHeading}
              </Title>
              <MdToHtml
                content={session.secondaryRegistrationDetails}
                pSize="large"
                linkSize="large"
                color="#ffffff"
                linkTextDecoration
              />
              {session.secondaryRegistrationLinkText && (
                <LinkButton
                  label={
                    session.secondaryRegistrationLinkText
                      ? session.secondaryRegistrationLinkText
                      : ""
                  }
                  to={
                    session.secondaryRegistrationUrl
                      ? session.secondaryRegistrationUrl
                      : paths.SIGNIN
                  }
                />
              )}
            </FlexBox>
          )}
        </FlexBox>
      </ThreeUp>
    </StyledWrapper>
  ) : (
    <></>
  );
};

export default NextSessionContainer;
