import { InputProps } from "interfaces";
import { InputProps as VdsInputProps } from "@vds/inputs";
import { StyledInput } from "./Input.module";

const Input = ({
  type = "text",
  label,
  name,
  value,
  onChange,
  onBlur,
  disabled,
  maxWidth,
  error,
  noError = false,
  errorText = " ",
  helperText,
  helperTextPlacement,
  ...rest
}: VdsInputProps & InputProps) => {
  return (
    <StyledInput
      type={type}
      name={name}
      label={label ? label : name.charAt(0).toUpperCase() + name.slice(1)}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      error={noError ? false : error}
      errorText={errorText}
      maxWidth={maxWidth}
      helperText={helperText}
      helperTextPlacement={helperTextPlacement ? helperTextPlacement : "bottom"}
      {...rest}
    />
  );
};

export default Input;
