import { HandleSubmitProps } from "interfaces";

interface formValues {
  username: string;
}

export default function (
  values: formValues,
  { props: { action } }: HandleSubmitProps
) {
  return action({ ...values });
}
