import { Navigate } from "react-router-dom";
import paths from "routing/paths";
import { useEffect, useState } from "react";
import { supportsStorage } from "utils";
import Loader from "components/Loader/Loader";
import { BASE_URL } from "utils/environmentVariables";

const AuthRedirect = () => {
  const [to, _to] = useState<
    { url: string; external: boolean } | null | undefined
  >(undefined);

  useEffect(() => {
    if (supportsStorage("localStorage")) {
      const local = localStorage.getItem("location") as string,
        persistedLocation = !!local ? JSON.parse(local) : null;
      const to = !!persistedLocation
        ? persistedLocation.search
          ? persistedLocation.pathname + persistedLocation.search
          : persistedLocation.hash
          ? persistedLocation.pathname + persistedLocation.hash
          : persistedLocation.pathname
        : null;

      _to({ url: to, external: persistedLocation?.external || false });
    } else {
      _to(null);
    }
  }, []);

  useEffect(() => {
    if (to !== undefined && supportsStorage("localStorage")) {
      localStorage.removeItem("location");
    }

    if (to?.external) {
      window.location.href = BASE_URL + to.url;
    }
  }, [to]);

  return to === undefined || to?.external ? (
    <Loader active />
  ) : !!to?.url ? (
    <Navigate to={to.url} replace />
  ) : (
    <Navigate to={paths.HOME} replace />
  );
};

export default AuthRedirect;
