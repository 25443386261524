import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authReducer, apiSlice, uiReducer } from "services";
import type { AnyAction, PreloadedState, Store } from "@reduxjs/toolkit";
// import { RootStateProps } from "interfaces";

const combinedReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }).concat(apiSlice.middleware),
// });

// export type RootStateProps = ReturnType<any>;
// export type RootStateProps = typeof store.dispatch;

export const rootReducer: RootStateProps = (state: any, action: AnyAction) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
}) as Store;

// use setupStore for testing/storybooks when need to preload a state
export const setupStore = (preloadedState?: PreloadedState<RootStateProps>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(apiSlice.middleware),
  }) as Store;
};

export type RootStateProps = ReturnType<typeof store.getState>;

export type AppStore = ReturnType<typeof store.dispatch>;
