import { useState } from "react";
import { StyledSecondaryNavLink } from "./SecondaryNav.module"
import TextLink from "../../buttons/TextLink/TextLink";

export interface SecondaryNavLinkProps {
  to: string;
  label: any;
  onClick?: () => void;
  active: boolean;
  white?: boolean;
}

const SecondaryNavLink = ({to, label, onClick, active, white}: SecondaryNavLinkProps) => {
  const [hovered, _hovered] = useState<boolean>(false);
  const onLinkHover = () => {
    _hovered(true);
  };
  const onLinkHoverOut = () => {
    _hovered(false);
  };
  
  return (
    <StyledSecondaryNavLink onMouseEnter={onLinkHover} onMouseLeave={onLinkHoverOut} className={`${active ? "active" : ""} ${white ? "white" : ""} ${hovered ? "hover" : ""}`}>
      <TextLink to={to} size="large" bold color={white && !hovered ? "#ffffff" : ((hovered && !white) || (active && !white) ? "#000000" : "#747676")}>{label}</TextLink>
    </StyledSecondaryNavLink>
  
  );
};

export default SecondaryNavLink;