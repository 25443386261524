import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import { withAction } from "HOCs/withAction";
import { mfa } from "services";
import mapPropsToValues from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import handleSubmit from "./handleSubmit";
import MfaConfirm from "./MfaConfirm";

export const MfaConfirmContainer = compose(
  withAction((props: any) => mfa(props)),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
  })
)(MfaConfirm);

export default MfaConfirmContainer;
