import { Body, FlexBox } from "components";
import { RadioButton } from "@vds/radio-buttons";
import { RadioGroupProps } from "interfaces";
import { RadioWrapper, StyledSubtitle } from "./RadioGroup.module";

const RadioGroup = ({
  title,
  name,
  options,
  value,
  onChange,
  disabled,
  ...rest
}: RadioGroupProps) => {
  return (
    <RadioWrapper {...rest}>
      {title}

      <FlexBox gap="1.5rem" mg="0 0 0 32px" maxWidth="fit-content">
        {options.map(
          (
            item: { value: string | number; label: string; subLabel?: string },
            i: number
          ) => (
            <div key={i}>
              <RadioButton
                label={item.label}
                name={name}
                value={item.value}
                selected={item.value === value}
                onChange={onChange(i)}
                disabled={disabled}
              />
              {item.subLabel && (
                <StyledSubtitle>
                  <Body color="#747676">{item.subLabel}</Body>
                </StyledSubtitle>
              )}
            </div>
          )
        )}
      </FlexBox>
    </RadioWrapper>
  );
};

export default RadioGroup;
