import styled from "styled-components";
import {mobile, minTablet, mobileLarge, minDesktop, tabletLarge, tablet} from "theme/variables";

interface AssetCardProps {
  assetId: string;
}

export const StyledAssetCard = styled("div")<AssetCardProps>`
  position: relative;
  height: fit-content;

  @media ${mobile} {
    min-height: 8rem;
  }

  @media ${mobileLarge} {
    min-height: 8rem;
  }

  @media ${tablet} {
    min-height: 6rem;
  }

  @media ${tabletLarge} {
    min-height: 6.5rem;
  }

  @media screen and (min-width: ${minDesktop}px) {
    min-height: 8rem;
  }
  
  

  /* min-height: 175px; */
  /* @media screen and (min-width: ${minTablet}px) {
    &:hover,
    &:focus-within {
      margin-bottom: -2.6rem;
    }
  } */
  .full-wrap {
    display: block;
    @media screen and (max-width: ${minTablet - 1}px) {
      position: relative;
    }
    @media screen and (min-width: ${minTablet}px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    min-height: fit-content;

    border: 0.0625rem solid transparent;
    background-color: #ffffff;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-sizing: border-box;
    border-radius: 4px;
    .media {
      border-radius: 4px;
    }
    #carousel-slides > div {
      border-radius: 4px;
    }

    * {
      box-sizing: border-box;
    }
    @media screen and (max-width: ${minTablet - 1}px) {
      overflow: visible;
    }

    @media screen and (min-width: ${minTablet}px) {
      overflow: hidden;
      &.focused {
        border: 0.0625rem solid #747676;
        box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.1);
        z-index: 100;
        transition: border-color ease-in 550ms, box-shadow ease-out 250ms,
          padding ease-in 50ms;
        padding: 4px;
      }
    }
  }

  .asset-card-utilities {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: calc(100% - (1rem * 2));
    z-index: 5;
    padding: 0.5rem;
    box-sizing: border-box;
    transition: border-color 250ms;
  }

  .assetcard__wrap {
    position: relative;
    box-sizing: border-box;

    > a {
      padding: 0;
    }
    padding: 4px;
    border-radius: 0.25rem;
    .asset-card-utilities {
      width: calc(100% - (0.5rem * 2));
    }
    overflow: hidden;
    
    .asset-card-footer {
      @media screen and (max-width: ${minTablet - 1}px) {
        opacity: 1;
      }
      @media screen and (min-width: ${minTablet}px) {
        opacity: 0;
      }
    }

    @media ${mobile} {
      .asset-card-expand {
        display: none;
      }
    }
  }

  .asset-card-expand {
    @media (min-width: ${minTablet}px) {
      opacity: 0;
    }

    &.focused {
      @media (min-width: ${minTablet}px) {
        opacity: 1;
      }
    }
  }

  .media {
    outline: #f6f6f6 0.0625rem solid;
    background-color: #d8dada;
    position: relative;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media ${mobile} {
      height: 8rem;
    }

    @media ${mobileLarge} {
      height: 8rem;
    }

    @media ${tablet} {
      height: 6rem;
    }

    @media ${tabletLarge} {
      height: 6.5rem;
    }

    @media screen and (min-width: ${minDesktop}px) {
      height: 8rem;
    }

    .media__image {
      width: auto;
      /* width: 100%; */
      max-height: 100%;
      /* position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */
      /* vertical-align: middle; */
      object-fit: cover;
    }

    .media__link {
      text-decoration: none;
      color: #000000;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: transparent;

      span {
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        overflow: hidden;
        position: absolute;
      }
    }
  }

  .assetcard__footer {
    ul {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;
      @media screen and (max-width: ${minTablet - 1}px) {
        padding: 0.5rem 0 0;
        li.assetcard__listitem {
          button {
            margin-right: 0.5rem;
            padding: 0.25rem;
            svg {
              width: 1rem;
              height: 1rem;
              min-width: 1rem;
              min-height: 1rem;
            }
          }
        }
      }
      @media screen and (min-width: ${minTablet}px) {
        padding: 0;
        li.assetcard__listitem {
          button {
            padding: 0.25rem;
            svg {
              width: 1.25rem;
              height: 1.25rem;
              min-width: 1.25rem;
              min-height: 1.25rem;
            }
          }
        }
      }
      
      li {
        &.mobile-select {
          display: none;
          @media ${mobile} {
            display: block;
          }
          width: 20px;

          label {
            padding: 0;
          }

          label span p {
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            margin: -1px;
            padding: 0;
            border: 0;
            overflow: hidden;
            position: absolute;
          }
        }

        @media (min-width: ${minTablet}px) {
          + li {
            margin-left: 0.25rem;
          }

          &.mobile-select + li {
            margin-left: 0;
          }
        }
      }
    }

    @media screen and (min-width: ${minTablet}px) {
      height: 0;
      margin-top: 0;
      opacity: 0;
      transform: translateY(0);
      &.focused {
        margin-top: 1.375rem;

        height: auto;
        opacity: 1;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
      }

      ul {
        li.assetcard__listitem {
          button {
            padding: 0 0.25rem;
            svg {
              width: 0.825rem;
              height: 0.825rem;
              min-width: 0.825rem;
              min-height: 0.825rem;
            }
          }
          & + li {
            margin-left: 0rem;
          }
        }
      }
    }
  }

  /* fullscreen */
  .uimodal-title {
    font-size: 0;
    line-height: 0;
    color: transparent;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
  }

  /* fullscreen utility buttons */
  [class|="thumbnail-utility-button"] {
    position: absolute;
    top: 1rem;
    padding: 0.313rem 0.453rem;
    background-color: #ffffff;
    z-index: 20;
  }

  .thumbnail-utility-button-open {
    padding: 0.313rem 0.453rem;
    background-color: #ffffff;
    z-index: 20;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    top: auto;
    border-radius: 2px;
  }

  .thumbnail-utility-button-close {
    padding: 0.313rem 0.453rem;
    background-color: #ffffff;
    z-index: 20;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    box-shadow: 0 0 3px rgb(0 0 0 / 11%);
  }

  .thumbnail-expired-indicator {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 20;
    padding: 0 0.75rem;
    background-color: #ffffff;
  }

  .thumbnail-list-wrap.fullscreen & {
    .thumbnail-expired-indicator {
      display: none;
    }
  }
`;
