import {useState} from "react";

import {
  Cell,
  Table as VdsTable,
  TableProps as VdsTableProps,
  TableBody,
  TableHead,
  TableHeader,
  TableRow
} from "@vds/tables";
import {
  FlexBox, Select
} from "components";
import { StyledTable, StyledCellContent } from "./Table.module";


export interface TableProps {
  headers: Array<string>;
  id?: string;
  items: Array<any>;
  helpers?: boolean;
  filter?: boolean;
  // topLine?: boolean;
  // bottomLine?: boolean;
  // viewport?: "desktop" | "tablet" | "mobile";
  // surface?: "light" | "dark";
}
const Table = ({ headers, items, ...rest }: TableProps & VdsTableProps) => {
  
  return (
    <StyledTable>
      <div>
        <VdsTable {...rest}>
      
          <TableHead>
            {headers.map((header) => (
              <TableHeader key={header}>
                {header}
              </TableHeader>
            ))}
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                {headers.map((header: string, index: number) => (
                  <Cell key={index}>
                    <StyledCellContent className={header === "Email" ? "break" : ""}>
                      {item[header.replace(/ /g, "")]}
                    </StyledCellContent>
                    
                  </Cell>
                ))}
              </TableRow>
            ))}
          </TableBody>
    
        </VdsTable>
      </div>
    </StyledTable>
  );
};

export default Table;