import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup as CheckboxGroup$1 } from '@vds-core/checkboxes';

const propTypes = {
  /**
  * Option to render on dark or light surface
  */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const CheckboxGroup = function (props) {
  return React.createElement(CheckboxGroup$1, Object.assign({}, props));
};
CheckboxGroup.propTypes = propTypes;
CheckboxGroup.defaultProps = defaultProps;

export { CheckboxGroup as default };
