import { createGlobalStyle } from "styled-components";
import { minTablet } from "./variables";
import { zIndex } from "theme/variables";

const GlobalStyles = createGlobalStyle`
html, body {
    /* min-height:100vh; */
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    line-height: 1.5;
  }

  body {
    &.no-scroll {
      overflow: hidden;
    }
  /* overflow-y: scroll; */
  }

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

/* #root {
  min-height: 100vh;
} */
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .hide-on-mobile {
    @media (max-width: ${minTablet - 1}px) {
      display: none;
    }
  }
  .text-align-right {
    &> p, span, h1, h2, h3, h4, h5, h6 {
      text-align: right;
    }
  }

  .grecaptcha-badge {
    z-index: ${zIndex.recaptcha};
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
    overflow: hidden;
    position: absolute;
  
    &.focusable:active,
    &.focusable:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }

`;

export default GlobalStyles;
