import {ShareModalProps, SuggestedUser} from "interfaces";
import {
  useShareAssetsMutation,
  useLazyGetSuggestedUsersQuery,
} from "services/api/api.slice";
import { setNotification } from "services";
import {
  Modal,
  Title,
  TextButton,
  Button,
  TextArea,
  Form,
  ButtonGroup,
  MultiSelectSearch,
} from "components";
import { ModalThumbnailGrid } from "features";
import { FormikProps } from "interfaces";
import { useDispatch } from "hooks/redux";
import { Grid } from "@mui/material";

const ShareModal = ({
  items,
  onClose,
  values: { shareRecipients, shareMessage },
  handleChange,
  setFieldValue,
  resetForm,
  onComplete,
  hideBackdrop,
}: ShareModalProps & FormikProps) => {
  const [callGetUsers] = useLazyGetSuggestedUsersQuery(),
    [shareAssets, { isLoading: shareLoading }] = useShareAssetsMutation();
  
  const dispatch = useDispatch();
  const handleOnSubmit = () => {
    shareAssets({
      recipientIds: shareRecipients.map(
        (recipient: SuggestedUser) => recipient.id
      ),
      shareItems: items.map((item) => {
        if (item.entityId) {
          return {
            entityId: item.entityId,
            searchType: item.searchType ? item.searchType : ""
          }
        } else {
          return {
            entityId: item.id,
            searchType: item.searchType ? item.searchType : ""
          }
        }
      }),
      message: shareMessage,
    })
      .unwrap()
      .then(() => {
        if (onComplete) {
          onComplete();
        } else {
          handleClose();
        }
        dispatch(
          setNotification({
            type: "success",
            message: `Item${
              items.length > 1 ? "s" : ""
            } shared successfully.`,
          })
        );
      })
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: `Could not share item${items.length > 1 ? "s" : ""}.`,
          })
        );
        console.error(error);
      });
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const removeSelected = (selected: any) => () => {
    setFieldValue(
      "shareRecipients",
      shareRecipients.filter((item: any) => item.id !== selected.id)
    );
  };

  return (
    <Modal
      open
      onClose={handleClose}
      hideBackdrop={hideBackdrop}
      disabled={shareLoading}
      testId="share-modal"
    >
      <Title size="large" bold>
        {items.length > 1 ? `Share ${items.length} items` : "Share item"}
      </Title>
      <ModalThumbnailGrid
        items={items}
      />

      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={6} width="100%">
          <Form onSubmit={handleOnSubmit} width="100%" gap="1rem">
            <MultiSelectSearch
              label="Recipients"
              name="shareRecipients"
              value={shareRecipients}
              callSearch={callGetUsers}
              optionLabels={["firstName", "lastName"]}
              setFieldValue={setFieldValue}
              selectedLabel="Selected recipients"
              removeSelected={(selected: any) => removeSelected(selected)}
              disabled={shareLoading}
              includeEmail
              // TODO: add error handling
            />

            <TextArea
              value={shareMessage}
              required={false}
              onChange={handleChange}
              name="shareMessage"
              label="Message"
              disabled={shareLoading}
              testId="share-message"
              maxLength={1000}
            />
            <ButtonGroup>
              <Button
                use="primary"
                type="submit"
                disabled={shareLoading || shareRecipients.length === 0}
                data-testid="send-button"
              >
                Send
              </Button>
              <TextButton
                onClick={handleClose}
                disabled={shareLoading}
                data-testid="cancel-button"
              >
                Cancel
              </TextButton>
            </ButtonGroup>
          </Form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ShareModal;
