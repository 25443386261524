import styled from "styled-components";
import { minTablet } from "../../theme/variables";
import { zIndex } from "theme/variables";

export const StyledFeedbackContainer = styled("div")`
  
  &.feedback-container {
    position: fixed;
    bottom: -20rem;
    left: 0;
    opacity: 0;
    width: 100vw;
    z-index: ${zIndex.modal};
    transition: all 0.5s ease;
    pointer-events: none;
    visibility: hidden;

    &.active {
      bottom: 0;
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
    }

    .x-button {
      background: transparent;
      border: none;
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    .content {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      background: #ffffff;
      padding: 3rem;
      text-align: center;
    }

    .bg-gradient {
      bottom: 0;
      left: 0;
      content: '';
      display: block;
      position: absolute;
      width: 100vw;
      height: 100vh;
      background: linear-gradient( to top, #000000, rgba(0, 0, 0, 0) );

      @media screen and (min-width: ${minTablet}px) {
        height: 75vh;
      }
    }
    
    &.on-step-0 {
      .bg-gradient {
        height: 50vh;
      }
    }

    .feedback-step {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease;
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      visibility: hidden;
      
      &.step-0 h3 {
        @media screen and (max-width: ${minTablet - 1}px) {
          text-align: center;
        }
      }

      .feedback-button {
        border: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-clip: border-box;
        margin-left: 1rem;
        text-align: center;
        cursor: pointer;
        outline: none;

        &.thumbs-down {
          svg {
            position: relative;
            top: 0.1rem;
            right: 0.02rem;
          }
        }
        &.thumbs-up {
          margin-left: 1.5rem;
          svg {
            position: relative;
            left: 0.02rem;
            bottom: 0.05rem;
          }
        }

        @media screen and (max-width: ${minTablet - 1}px) {
          margin-top: 1rem;
          &:first-child {
            margin-left: 0;
          }
        }
      }

      h3 {
        text-align: left;
      }
      &.step-2 h3 {
        text-align: center;
      }
      .feedback-instruction {
        font-weight: bold;
        font-size: 14px;
      }
      
    }

    &.on-step-0 .step-0, &.on-step-1 .step-1, &.on-step-2 .step-2 {
      opacity: 1;
      max-height: 100vh;
      visibility: visible;

      @media screen and (min-width: ${minTablet}px) {
        max-height: 30rem;
      }
    }
    

      .small {
        font-size: 16px;
        margin-top: 0;
      }

      .copy {
        p {
          margin: 0;
        }
      }

      //.checkboxes {
      //  margin: 2rem 1rem;
      //}
    }
  }

  .step-1 > button {
    margin-bottom: 1rem;
    margin-left: 1rem;
    max-width: fit-content;
  }

  .visuallyhidden {
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
    overflow: hidden;
    position: absolute;

    &.focusable:active,
    &.focusable:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
  
`;
