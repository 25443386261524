import styled from "styled-components";
import { ButtonProps } from "interfaces";

export const ButtonWrapper = styled.div<ButtonProps>`
  padding: ${(props: ButtonProps) => props.pd || 0};
  button {
    background-color: ${(props: ButtonProps) => props.searchButton ? "#333333" : ""};
    color: ${(props: ButtonProps) => props.searchButton ? "#ffffff" : ""};
  }
`;
