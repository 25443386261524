import { useEffect, useState } from "react";
import { Body, FlexBox, TextButton, IconButton, Button } from "components";
import { AssetFile, AssetFileGroup, AssetUserActions } from "interfaces";
import {
  formatAllFileSize,
  formatFileSize,
  getFileCurrentVersion,
  getFileName,
  openFileDownload,
} from "../../utils/assetFiles";
import { StyledDownloadListContainer } from "./DownloadListBox.module";
import LookingForFeedback from "../lookingForFeedback/LookingForFeedback";
import {
  useGetFeedbackOptionsQuery,
  useLazyGetAssetDownloadUrlQuery,
  useLazyGetFileDownloadUrlQuery,
  useTrackingMutation,
} from "../../services/api/api.slice";
import SatisfiedFeedback from "../satisfiedFeedback/SatisfiedFeedback";
import DownloadOptionsModal from "../downloadOptionsModal/DownloadOptionsModal";
import UsageRightsModal from "../usageRightsModal/UsageRightsModal";
import MobileDownloadOptionsModal from "../mobileDownloadOptionsModal/MobileDownloadOptionsModal";
import { useDispatch } from "react-redux";
import { setNotification } from "services";
import { useLocation } from "react-router-dom";
import { buildFinalTrackingObject } from "../../utils";

export interface DownloadListBoxProps {
  canDownload: boolean;
  files: AssetFile[];
  singleFiles: AssetFile[] | null;
  fileGroups: AssetFileGroup[] | null;
  userActions: AssetUserActions;
  usageRightsCopy?: string;
  isExpired: boolean;
  assetId: string;
  isMobile: boolean;
  correlationId?: string;
  trackingData?: Record<string, unknown>;
}

const DownloadListBox = ({
  canDownload,
  isExpired,
  assetId,
  files,
  singleFiles,
  userActions,
  fileGroups,
  usageRightsCopy,
  isMobile,
  correlationId,
  trackingData,
}: DownloadListBoxProps) => {
  const [fileVersionId, setFileVersionId] = useState<string>("");

  const dispatch = useDispatch();

  const [
    getAssetUrl,
    {
      data: assetResult,
      isFetching: assetFetching,
      isError: assetError,
      error: assetErrorMsg,
      isSuccess: assetSuccess,
    },
  ] = useLazyGetAssetDownloadUrlQuery();
  const [
    getFileUrl,
    {
      data: fileResult,
      isFetching: fileFetching,
      isError: fileError,
      error: fileErrorMsg,
      isSuccess: fileSuccess,
    },
  ] = useLazyGetFileDownloadUrlQuery();
  const {
    data: feedbackOptions = [],
    isError: feedbackOptionsError,
    error: feedbackOptionsErrorMsg,
  } = useGetFeedbackOptionsQuery();

  const isFetching = assetFetching || fileFetching;

  const [trackAction, isLoading] = useTrackingMutation();

  const location = useLocation();

  const trackEvent = (action: string, entityType: string, id: string): void => {
    const payloadData = {
      version: "1.0.0",
      context: trackingData,
      type: "download-listbox",
      action,
      entityType,
      id,
    };
    const trackingObject = buildFinalTrackingObject(
      payloadData,
      location.pathname,
      correlationId
    );
    trackAction(trackingObject)
      .unwrap()
      .catch((error: any) => {
        console.error(error);
      });
  };

  const callGetFileUrl = (fileVersionId: string) => {
    trackEvent("download-single-file", "asset-file", fileVersionId);
    getFileUrl({ assetId, fileVersionId })
      .unwrap()
      .catch((error: unknown) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Could not download file.",
          })
        );
        console.error(error);
      });
  };

  const callGetAssetUrl = (assetId: string) => {
    trackEvent("download-asset", "asset", assetId);
    getAssetUrl(assetId)
      .unwrap()
      .catch((error: unknown) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Could not download asset.",
          })
        );
        console.error(error);
      });
  };

  const [showMobileOptions, setShowMobileOptions] = useState<boolean>(false),
    toggleMobileOptions = () => {
      if (!showMobileOptions) {
        trackEvent("open-download-options-mobile", "asset", assetId);
      }
      setShowMobileOptions(!showMobileOptions);
    };

  const [showSatisfiedFeedback, setShowSatisfiedFeedback] =
      useState<boolean>(false),
    openSatisfiedFeedback = () => {
      if (!satisfiedFeedbackSeen) {
        setShowSatisfiedFeedback(true);
      }
    },
    closeSatisfiedFeedback = () => {
      setShowSatisfiedFeedback(false);
      _satisfiedFeedbackSeen(true);
    };

  const [satisfiedFeedbackSeen, _satisfiedFeedbackSeen] =
    useState<boolean>(false);

  const [rightsAccepted, setRightsAccepted] = useState<boolean>(
      usageRightsCopy &&
        userActions &&
        userActions.hasAcceptedUsageRights !== null
        ? userActions.hasAcceptedUsageRights
        : true
    ),
    updateUsageRights = (value: boolean) => {
      setRightsAccepted(value);
    };

  const [showDownloadOptionsModal, setShowDownloadOptionsModal] =
      useState<boolean>(false),
    openDownloadOptionsModal = () => {
      trackEvent("open-download-options-multiple-files", "asset", assetId);
      setShowDownloadOptionsModal(true);
    },
    closeDownloadOptionsModal = () => {
      setShowDownloadOptionsModal(false);
    },
    completeDownloadOptionsModal = () => {
      closeDownloadOptionsModal();
      openSatisfiedFeedback();
      updateUsageRights(true);
    };

  const onDownloadFileClick = (file: AssetFile) => () => {
    // if (!file.currentVersionId) {
    //   dispatch(
    //     setNotification({
    //       type: "error",
    //       message: "Could not download file.",
    //     })
    //   );
    //   return;
    // }
    if (usageRightsCopy && !rightsAccepted) {
      file.currentVersionId && setFileVersionId(file.currentVersionId);
      openUsageRightsModal();
    } else {
      file.currentVersionId && callGetFileUrl(file.currentVersionId);
    }
  };

  const onDownloadAssetClick = () => {
    if (usageRightsCopy && usageRightsCopy.length > 0 && !rightsAccepted) {
      openUsageRightsModal();
    } else {
      callGetAssetUrl(assetId);
    }
  };

  // const [feedbackOptions, setFeedbackOptions] = useState<string[]>([]);

  const [usageRightsModalOpen, setUsageRightsModalOpen] =
      useState<boolean>(false),
    openUsageRightsModal = () => {
      setUsageRightsModalOpen(true);
    },
    completeUsageRightsModal = () => {
      updateUsageRights(true);
      setUsageRightsModalOpen(false);
      callGetFileUrl(fileVersionId);
    },
    closeUsageRightsModal = () => {
      setUsageRightsModalOpen(false);
    };

  const onOpenDownloadOptionsClick = () => {
    openDownloadOptionsModal();
  };

  useEffect(() => {
    if (feedbackOptionsError && feedbackOptionsErrorMsg) {
      dispatch(
        setNotification({
          type: "error",
          message: "Error retrieving feedback options.",
        })
      );
      console.error(feedbackOptionsErrorMsg);
    }
  }, [feedbackOptionsError, feedbackOptionsErrorMsg]);

  // DOWNLOADING A SINGLE FILE
  useEffect(() => {
    fileResult && openFileDownload(fileResult);
    if (fileResult && !userActions.isAdmin) {
      openSatisfiedFeedback();
    }
  }, [fileResult]);

  // DOWNLOADING AN ENTIRE ASSET
  useEffect(() => {
    assetResult && openFileDownload(assetResult);
    if (assetResult && !userActions.isAdmin) {
      openSatisfiedFeedback();
    }
  }, [assetResult]);

  return (
    <StyledDownloadListContainer data-testid="download-listbox">
      {fileGroups !== null &&
        fileGroups.length < 2 &&
        (!isMobile ? (
          <div
            className={`listbox-group ${
              showMobileOptions ? "is-active" : "not-active"
            }`}
          >
            <FlexBox className="listbox">
              {userActions?.canDownload ? (
                <Body className="header">
                  {files?.length > 1
                    ? "Click individual files to download or download all below"
                    : "Click the file or download button below to download"}
                </Body>
              ) : (
                <Body className="header">
                  {files?.length > 1
                    ? "Files included in asset"
                    : "File included in asset"}
                </Body>
              )}

              <ul className="scrollbar">
                {files.map((file: AssetFile) => {
                  return (
                    !!file.currentVersionId && (
                      <li key={file.id}>
                        <FlexBox className="layout" row justify="space-between">
                          <div
                            style={{ maxWidth: "70%", overflow: "hidden" }}
                            className="download-link"
                          >
                            {userActions?.canDownload && !isExpired ? (
                              <TextButton
                                size="small"
                                onClick={onDownloadFileClick(file)}
                                disabled={isFetching}
                                data-testid="download-file-text-button"
                              >
                                {getFileName(file)}
                              </TextButton>
                            ) : (
                              <Body>{getFileName(file)}</Body>
                            )}
                          </div>

                          <FlexBox
                            row
                            maxWidth="30%"
                            justify="flex-end"
                            align="center"
                          >
                            <Body className="info">
                              {formatFileSize(
                                getFileCurrentVersion(file)?.sizeInBytes
                              )}
                            </Body>
                            {userActions?.canDownload && !isExpired && (
                              <IconButton
                                mg="0 0 0 0.5rem"
                                id="download-file-button"
                                name="download"
                                size="medium"
                                onClick={onDownloadFileClick(file)}
                                disabled={isFetching}
                                noHover
                                testId="download-file-icon-button"
                              />
                            )}
                          </FlexBox>
                        </FlexBox>
                      </li>
                    )
                  );
                })}
              </ul>
            </FlexBox>

            {userActions?.canDownload && !isExpired && (
              <FlexBox
                row
                pd="0 1rem 2.5rem 0"
                justify="space-between"
                className="controls"
              >
                <Button
                  use="secondary"
                  disabled={
                    !userActions?.canDownload || isExpired || isFetching
                  }
                  onClick={onDownloadAssetClick}
                  data-testid="download-asset-button"
                >
                  Download {files.length > 1 ? "all" : ""}
                </Button>
                <Body className="info">Total {formatAllFileSize(files)}</Body>
              </FlexBox>
            )}
          </div>
        ) : showMobileOptions ? (
          <MobileDownloadOptionsModal
            files={files}
            onClose={toggleMobileOptions}
            isExpired={isExpired}
            userActions={userActions}
            assetId={assetId}
            correlationId={correlationId}
            trackingData={trackingData}
            usageRightsCopy={usageRightsCopy}
          />
        ) : (
          <></>
        ))}

      {fileGroups !== null && fileGroups.length >= 2 && files.length > 0 && (
        <FlexBox
          pd="2rem 0 4rem 0"
          maxWidth="fit-content"
          className="download-options-desktop"
        >
          <Button
            use="secondary"
            onClick={onOpenDownloadOptionsClick}
            disabled={isFetching}
            data-testid="file-groups-button"
          >
            {userActions.canDownload && !isExpired
              ? "Download options"
              : "View files in asset"}
          </Button>
        </FlexBox>
      )}

      {isMobile && (fileGroups === null || fileGroups.length < 2) && files.length > 0 && (
        <FlexBox pd="2rem 0 4rem 0" maxWidth="fit-content">
          <Button
            // className="download-options-mobile"
            use="secondary"
            onClick={toggleMobileOptions}
            disabled={isFetching}
            data-testid="mobile-toggle-button"
          >
            {userActions.canDownload && !isExpired
              ? "Download options"
              : "View files in asset"}
          </Button>
        </FlexBox>
      )}

      <LookingForFeedback assetId={assetId} correlationId={correlationId} />

      <SatisfiedFeedback
        feedbackOptionList={feedbackOptions}
        isActive={showSatisfiedFeedback}
        assetId={assetId}
        correlationId={correlationId}
        onClose={closeSatisfiedFeedback}
      />

      {showDownloadOptionsModal && (
        <DownloadOptionsModal
          assetId={assetId}
          userCanDownload={canDownload}
          onClose={closeDownloadOptionsModal}
          groups={fileGroups}
          files={singleFiles}
          usageRightsCopy={usageRightsCopy}
          onComplete={completeDownloadOptionsModal}
          usageRightsAccepted={userActions.hasAcceptedUsageRights}
        />
      )}

      {usageRightsModalOpen && (
        <UsageRightsModal
          assetId={assetId}
          usageRightsCopy={usageRightsCopy}
          onComplete={completeUsageRightsModal}
          onClose={closeUsageRightsModal}
        />
      )}
    </StyledDownloadListContainer>
  );
};

export default DownloadListBox;
