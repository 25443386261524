import CaretLink from "../../buttons/CaretLink/CaretLink";
import Title from "../../typography/Title/Title";
import Body from "../../typography/Body/Body";
import { StyledUnprotectedFooter } from "./UnprotectedFooter.module";

export type UnprotectedFooterProps = {
  position?: string;
};

const UnprotectedFooter = ({ position }: UnprotectedFooterProps) => {
  return (
    <StyledUnprotectedFooter position={position}>
      <Title size="small" bold pd="0 0 0.5rem 0">
        Need Help?
      </Title>
      <Body size="large">For any questions or concerns, drop us a line.</Body>

      <CaretLink
        onClick={(e: any) => {
          window.open("mailto:support@brandcentral.verizon.com", "_blank");
          e.preventDefault();
        }}
        caretPosition="right"
        to="#"
        fontSize="1"
        pd="2rem 0 0 0"
      >
        Contact us
      </CaretLink>
    </StyledUnprotectedFooter>
  );
};

export default UnprotectedFooter;
