import React from 'react';
import PropTypes from 'prop-types';
import { TextArea as TextArea$1 } from '@vds-core/inputs';
import { Tooltip } from '@vds/tooltips';

const propTypes = {
  /**
   * If provided, the TextArea will be rendered with transparent background.
   */
  transparentBackground: PropTypes.bool,
  /**
   * Render the component in surface background dark or light
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * @deprecated String, array or node containing the title of the Tooltip for Input label
   */
  tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  /**
   * @deprecated String, array or node containing the body of the Tooltip for Input label
   */
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  /**
   * @deprecated If provided, will render the custom text for Close button for Tooltip when on mobile/touch devices
   */
  tooltipCloseButtonText: PropTypes.string,
  /**
   * Config object for tooltip option
   */
  tooltip: PropTypes.shape({
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    closeButtonText: PropTypes.string,
    'data-track': PropTypes.string,
    'data-track-ignore': PropTypes.string,
    'data-analyticstrack': PropTypes.string,
    'data-clickstream': PropTypes.string
  })
};
const defaultProps = {
  transparentBackground: false,
  surface: 'light'
};
const TextArea = function (props) {
  return React.createElement(TextArea$1, Object.assign({}, props, {
    Tooltip: Tooltip,
    focusRing: true,
    heavyErrorBorder: false,
    borderRadius: "4px",
    highlightZIndex: 1
  }));
};
TextArea.propTypes = propTypes;
TextArea.defaultProps = defaultProps;

export { TextArea as default };
