import { useState } from "react";
import { SSO_HOST_UX_URL_REDIRECT } from "utils/environmentVariables";
import { StyledTile, StyledWrapper } from "./AuthenticationTile.module";
import { Icon } from "@vds/icons";
import Body from "components/typography/Body/Body";
import { SignInForm } from "features";

export interface AuthenticationTileInterface {
  sso?: boolean;
}

const AuthenticationTile = ({ sso }: AuthenticationTileInterface) => {
  const [expanded, _setExpanded] = useState(false),
    setExpanded = () => {
      _setExpanded(!expanded);
    };

  return (
    <>
      {sso ? (
        <StyledTile>
          <a href={SSO_HOST_UX_URL_REDIRECT}>
            <Body bold>
              <>
                Verizon <br /> Employees
              </>
            </Body>
            <Icon name="right-arrow" size={45} color="#000000" />
          </a>
        </StyledTile>
      ) : (
        <StyledWrapper>
          <StyledTile
            className={expanded ? `expanded` : ``}
            onClick={setExpanded}
          >
            <Body bold>
              Approved <br />
              agencies & vendors
            </Body>
            <Icon name="right-arrow" size={45} color="#000000" />
          </StyledTile>

          <SignInForm expanded={expanded} setExpanded={setExpanded} />
        </StyledWrapper>
      )}
    </>
  );
};

export default AuthenticationTile;
