import styled from "styled-components";
import { TitleProps } from "./Title";

export const StyledWrapper = styled("div")<TitleProps>`
  height: fit-content;
  padding: ${(props: TitleProps) => props.pd || 0};

  max-width: ${(props: TitleProps) => props.maxWidth || ""};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: ${(props: TitleProps) => props.alignText || "left"};
  }
`;
