export const ColorTokens = {
  "palette": {
    "black": {
      "value": "#000000",
      "attributes": {
        "category": "color",
        "type": "black"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#000000",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteBlack",
      "path": [
        "palette",
        "black"
      ]
    },
    "white": {
      "value": "#ffffff",
      "attributes": {
        "category": "color",
        "type": "white"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#ffffff",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteWhite",
      "path": [
        "palette",
        "white"
      ]
    },
    "red": {
      "value": "#ee0000",
      "attributes": {
        "category": "color",
        "type": "red"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#ee0000",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteRed",
      "path": [
        "palette",
        "red"
      ]
    },
    "gray95": {
      "value": "#f6f6f6",
      "attributes": {
        "category": "color",
        "type": "gray95"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#f6f6f6",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGray95",
      "path": [
        "palette",
        "gray95"
      ]
    },
    "gray85": {
      "value": "#d8dada",
      "attributes": {
        "category": "color",
        "type": "gray85"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#d8dada",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGray85",
      "path": [
        "palette",
        "gray85"
      ]
    },
    "gray65": {
      "value": "#a7a7a7",
      "attributes": {
        "category": "color",
        "type": "gray65"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#a7a7a7",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGray65",
      "path": [
        "palette",
        "gray65"
      ]
    },
    "gray44": {
      "value": "#6f7171",
      "attributes": {
        "category": "color",
        "type": "gray44"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#6f7171",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGray44",
      "path": [
        "palette",
        "gray44"
      ]
    },
    "gray20": {
      "value": "#333333",
      "attributes": {
        "category": "color",
        "type": "gray20"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#333333",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGray20",
      "path": [
        "palette",
        "gray20"
      ]
    },
    "gray11": {
      "value": "#1b1d1f",
      "attributes": {
        "category": "color",
        "type": "gray11"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#1b1d1f",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGray11",
      "path": [
        "palette",
        "gray11"
      ]
    },
    "orange94": {
      "value": "#ffece0",
      "attributes": {
        "category": "color",
        "type": "orange94"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#ffece0",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteOrange94",
      "path": [
        "palette",
        "orange94"
      ]
    },
    "orange83": {
      "value": "#ffcaaa",
      "attributes": {
        "category": "color",
        "type": "orange83"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#ffcaaa",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteOrange83",
      "path": [
        "palette",
        "orange83"
      ]
    },
    "orange71": {
      "value": "#ffa46d",
      "attributes": {
        "category": "color",
        "type": "orange71"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#ffa46d",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteOrange71",
      "path": [
        "palette",
        "orange71"
      ]
    },
    "orange58": {
      "value": "#ff8027",
      "attributes": {
        "category": "color",
        "type": "orange58"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#ff8027",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteOrange58",
      "path": [
        "palette",
        "orange58"
      ]
    },
    "orange41": {
      "value": "#b95319",
      "attributes": {
        "category": "color",
        "type": "orange41"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#b95319",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteOrange41",
      "path": [
        "palette",
        "orange41"
      ]
    },
    "orange24": {
      "value": "#732706",
      "attributes": {
        "category": "color",
        "type": "orange24"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#732706",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteOrange24",
      "path": [
        "palette",
        "orange24"
      ]
    },
    "orange17": {
      "value": "#561701",
      "attributes": {
        "category": "color",
        "type": "orange17"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#561701",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteOrange17",
      "path": [
        "palette",
        "orange17"
      ]
    },
    "yellow94": {
      "value": "#fff9de",
      "attributes": {
        "category": "color",
        "type": "yellow94"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#fff9de",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteYellow94",
      "path": [
        "palette",
        "yellow94"
      ]
    },
    "yellow87": {
      "value": "#fff4bc",
      "attributes": {
        "category": "color",
        "type": "yellow87"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#fff4bc",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteYellow87",
      "path": [
        "palette",
        "yellow87"
      ]
    },
    "yellow74": {
      "value": "#ffe97a",
      "attributes": {
        "category": "color",
        "type": "yellow74"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#ffe97a",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteYellow74",
      "path": [
        "palette",
        "yellow74"
      ]
    },
    "yellow53": {
      "value": "#fed60e",
      "attributes": {
        "category": "color",
        "type": "yellow53"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#fed60e",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteYellow53",
      "path": [
        "palette",
        "yellow53"
      ]
    },
    "yellow39": {
      "value": "#bc9f0a",
      "attributes": {
        "category": "color",
        "type": "yellow39"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#bc9f0a",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteYellow39",
      "path": [
        "palette",
        "yellow39"
      ]
    },
    "yellow20": {
      "value": "#635305",
      "attributes": {
        "category": "color",
        "type": "yellow20"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#635305",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteYellow20",
      "path": [
        "palette",
        "yellow20"
      ]
    },
    "yellow15": {
      "value": "#4b3f04",
      "attributes": {
        "category": "color",
        "type": "yellow15"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#4b3f04",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteYellow15",
      "path": [
        "palette",
        "yellow15"
      ]
    },
    "blue94": {
      "value": "#e3f2fd",
      "attributes": {
        "category": "color",
        "type": "blue94"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#e3f2fd",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteBlue94",
      "path": [
        "palette",
        "blue94"
      ]
    },
    "blue82": {
      "value": "#aad8f9",
      "attributes": {
        "category": "color",
        "type": "blue82"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#aad8f9",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteBlue82",
      "path": [
        "palette",
        "blue82"
      ]
    },
    "blue62": {
      "value": "#4aabf2",
      "attributes": {
        "category": "color",
        "type": "blue62"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#4aabf2",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteBlue62",
      "path": [
        "palette",
        "blue62"
      ]
    },
    "blue46": {
      "value": "#0089ec",
      "attributes": {
        "category": "color",
        "type": "blue46"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#0089ec",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteBlue46",
      "path": [
        "palette",
        "blue46"
      ]
    },
    "blue38": {
      "value": "#006fc1",
      "attributes": {
        "category": "color",
        "type": "blue38"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#006fc1",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteBlue38",
      "path": [
        "palette",
        "blue38"
      ]
    },
    "blue21": {
      "value": "#003e6c",
      "attributes": {
        "category": "color",
        "type": "blue21"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#003e6c",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteBlue21",
      "path": [
        "palette",
        "blue21"
      ]
    },
    "blue15": {
      "value": "#002c4d",
      "attributes": {
        "category": "color",
        "type": "blue15"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#002c4d",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteBlue15",
      "path": [
        "palette",
        "blue15"
      ]
    },
    "green91": {
      "value": "#dcf5e6",
      "attributes": {
        "category": "color",
        "type": "green91"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#dcf5e6",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGreen91",
      "path": [
        "palette",
        "green91"
      ]
    },
    "green77": {
      "value": "#a4e6bd",
      "attributes": {
        "category": "color",
        "type": "green77"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#a4e6bd",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGreen77",
      "path": [
        "palette",
        "green77"
      ]
    },
    "green61": {
      "value": "#63d48e",
      "attributes": {
        "category": "color",
        "type": "green61"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#63d48e",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGreen61",
      "path": [
        "palette",
        "green61"
      ]
    },
    "green36": {
      "value": "#00b845",
      "attributes": {
        "category": "color",
        "type": "green36"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#00b845",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGreen36",
      "path": [
        "palette",
        "green36"
      ]
    },
    "green26": {
      "value": "#008331",
      "attributes": {
        "category": "color",
        "type": "green26"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#008331",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGreen26",
      "path": [
        "palette",
        "green26"
      ]
    },
    "green15": {
      "value": "#004b1c",
      "attributes": {
        "category": "color",
        "type": "green15"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#004b1c",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGreen15",
      "path": [
        "palette",
        "green15"
      ]
    },
    "green10": {
      "value": "#003514",
      "attributes": {
        "category": "color",
        "type": "green10"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#003514",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PaletteGreen10",
      "path": [
        "palette",
        "green10"
      ]
    },
    "pink87": {
      "value": "#febfe8",
      "attributes": {
        "category": "color",
        "type": "pink87"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#febfe8",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PalettePink87",
      "path": [
        "palette",
        "pink87"
      ]
    },
    "pink76": {
      "value": "#fc89d5",
      "attributes": {
        "category": "color",
        "type": "pink76"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#fc89d5",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PalettePink76",
      "path": [
        "palette",
        "pink76"
      ]
    },
    "pink62": {
      "value": "#fb42bc",
      "attributes": {
        "category": "color",
        "type": "pink62"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#fb42bc",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PalettePink62",
      "path": [
        "palette",
        "pink62"
      ]
    },
    "pink46": {
      "value": "#b9318b",
      "attributes": {
        "category": "color",
        "type": "pink46"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#b9318b",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PalettePink46",
      "path": [
        "palette",
        "pink46"
      ]
    },
    "pink25": {
      "value": "#671b4e",
      "attributes": {
        "category": "color",
        "type": "pink25"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#671b4e",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PalettePink25",
      "path": [
        "palette",
        "pink25"
      ]
    },
    "purple85": {
      "value": "#edb9fb",
      "attributes": {
        "category": "color",
        "type": "purple85"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#edb9fb",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PalettePurple85",
      "path": [
        "palette",
        "purple85"
      ]
    },
    "purple75": {
      "value": "#e084f9",
      "attributes": {
        "category": "color",
        "type": "purple75"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#e084f9",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PalettePurple75",
      "path": [
        "palette",
        "purple75"
      ]
    },
    "purple60": {
      "value": "#ce3df5",
      "attributes": {
        "category": "color",
        "type": "purple60"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#ce3df5",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PalettePurple60",
      "path": [
        "palette",
        "purple60"
      ]
    },
    "purple39": {
      "value": "#84279e",
      "attributes": {
        "category": "color",
        "type": "purple39"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#84279e",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PalettePurple39",
      "path": [
        "palette",
        "purple39"
      ]
    },
    "purple20": {
      "value": "#461553",
      "attributes": {
        "category": "color",
        "type": "purple20"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#461553",
        "attributes": {
          "category": "color"
        }
      },
      "name": "PalettePurple20",
      "path": [
        "palette",
        "purple20"
      ]
    }
  },
  "background": {
    "primary": {
      "light": {
        "value": "#ffffff",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "light"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#ffffff",
          "attributes": {
            "category": "color"
          }
        },
        "name": "BackgroundPrimaryLight",
        "path": [
          "background",
          "primary",
          "light"
        ]
      },
      "dark": {
        "value": "#000000",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "dark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#000000",
          "attributes": {
            "category": "color"
          }
        },
        "name": "BackgroundPrimaryDark",
        "path": [
          "background",
          "primary",
          "dark"
        ]
      }
    },
    "secondary": {
      "light": {
        "value": "#f6f6f6",
        "attributes": {
          "category": "color",
          "type": "secondary",
          "item": "light"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#f6f6f6",
          "attributes": {
            "category": "color"
          }
        },
        "name": "BackgroundSecondaryLight",
        "path": [
          "background",
          "secondary",
          "light"
        ]
      },
      "dark": {
        "value": "#1b1d1f",
        "attributes": {
          "category": "color",
          "type": "secondary",
          "item": "dark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#1b1d1f",
          "attributes": {
            "category": "color"
          }
        },
        "name": "BackgroundSecondaryDark",
        "path": [
          "background",
          "secondary",
          "dark"
        ]
      }
    },
    "brandhighlight": {
      "value": "#ee0000",
      "attributes": {
        "category": "color",
        "type": "brandhighlight"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#ee0000",
        "attributes": {
          "category": "color"
        }
      },
      "name": "BackgroundBrandhighlight",
      "path": [
        "background",
        "brandhighlight"
      ]
    }
  },
  "feedback": {
    "error": {
      "onlight": {
        "value": "#b95319",
        "attributes": {
          "category": "color",
          "type": "error",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#b95319",
          "attributes": {
            "category": "color"
          }
        },
        "name": "FeedbackErrorOnlight",
        "path": [
          "feedback",
          "error",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#ff8027",
        "attributes": {
          "category": "color",
          "type": "error",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#ff8027",
          "attributes": {
            "category": "color"
          }
        },
        "name": "FeedbackErrorOndark",
        "path": [
          "feedback",
          "error",
          "ondark"
        ]
      },
      "background": {
        "onlight": {
          "value": "#ffece0",
          "attributes": {
            "category": "color",
            "type": "error",
            "item": "background",
            "subitem": "onlight"
          },
          "filePath": "packages/Color/src/index.js",
          "isSource": true,
          "original": {
            "value": "#ffece0",
            "attributes": {
              "category": "color"
            }
          },
          "name": "FeedbackErrorBackgroundOnlight",
          "path": [
            "feedback",
            "error",
            "background",
            "onlight"
          ]
        },
        "ondark": {
          "value": "#561701",
          "attributes": {
            "category": "color",
            "type": "error",
            "item": "background",
            "subitem": "ondark"
          },
          "filePath": "packages/Color/src/index.js",
          "isSource": true,
          "original": {
            "value": "#561701",
            "attributes": {
              "category": "color"
            }
          },
          "name": "FeedbackErrorBackgroundOndark",
          "path": [
            "feedback",
            "error",
            "background",
            "ondark"
          ]
        }
      }
    },
    "warning": {
      "onlight": {
        "value": "#fed60e",
        "attributes": {
          "category": "color",
          "type": "warning",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#fed60e",
          "attributes": {
            "category": "color"
          }
        },
        "name": "FeedbackWarningOnlight",
        "path": [
          "feedback",
          "warning",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#fed60e",
        "attributes": {
          "category": "color",
          "type": "warning",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#fed60e",
          "attributes": {
            "category": "color"
          }
        },
        "name": "FeedbackWarningOndark",
        "path": [
          "feedback",
          "warning",
          "ondark"
        ]
      },
      "background": {
        "onlight": {
          "value": "#fff9de",
          "attributes": {
            "category": "color",
            "type": "warning",
            "item": "background",
            "subitem": "onlight"
          },
          "filePath": "packages/Color/src/index.js",
          "isSource": true,
          "original": {
            "value": "#fff9de",
            "attributes": {
              "category": "color"
            }
          },
          "name": "FeedbackWarningBackgroundOnlight",
          "path": [
            "feedback",
            "warning",
            "background",
            "onlight"
          ]
        },
        "ondark": {
          "value": "#4b3f04",
          "attributes": {
            "category": "color",
            "type": "warning",
            "item": "background",
            "subitem": "ondark"
          },
          "filePath": "packages/Color/src/index.js",
          "isSource": true,
          "original": {
            "value": "#4b3f04",
            "attributes": {
              "category": "color"
            }
          },
          "name": "FeedbackWarningBackgroundOndark",
          "path": [
            "feedback",
            "warning",
            "background",
            "ondark"
          ]
        }
      }
    },
    "information": {
      "onlight": {
        "value": "#006fc1",
        "attributes": {
          "category": "color",
          "type": "information",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#006fc1",
          "attributes": {
            "category": "color"
          }
        },
        "name": "FeedbackInformationOnlight",
        "path": [
          "feedback",
          "information",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#0089ec",
        "attributes": {
          "category": "color",
          "type": "information",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#0089ec",
          "attributes": {
            "category": "color"
          }
        },
        "name": "FeedbackInformationOndark",
        "path": [
          "feedback",
          "information",
          "ondark"
        ]
      },
      "background": {
        "onlight": {
          "value": "#e3f2fd",
          "attributes": {
            "category": "color",
            "type": "information",
            "item": "background",
            "subitem": "onlight"
          },
          "filePath": "packages/Color/src/index.js",
          "isSource": true,
          "original": {
            "value": "#e3f2fd",
            "attributes": {
              "category": "color"
            }
          },
          "name": "FeedbackInformationBackgroundOnlight",
          "path": [
            "feedback",
            "information",
            "background",
            "onlight"
          ]
        },
        "ondark": {
          "value": "#002c4d",
          "attributes": {
            "category": "color",
            "type": "information",
            "item": "background",
            "subitem": "ondark"
          },
          "filePath": "packages/Color/src/index.js",
          "isSource": true,
          "original": {
            "value": "#002c4d",
            "attributes": {
              "category": "color"
            }
          },
          "name": "FeedbackInformationBackgroundOndark",
          "path": [
            "feedback",
            "information",
            "background",
            "ondark"
          ]
        }
      }
    },
    "success": {
      "onlight": {
        "value": "#008331",
        "attributes": {
          "category": "color",
          "type": "success",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#008331",
          "attributes": {
            "category": "color"
          }
        },
        "name": "FeedbackSuccessOnlight",
        "path": [
          "feedback",
          "success",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#00b845",
        "attributes": {
          "category": "color",
          "type": "success",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#00b845",
          "attributes": {
            "category": "color"
          }
        },
        "name": "FeedbackSuccessOndark",
        "path": [
          "feedback",
          "success",
          "ondark"
        ]
      },
      "background": {
        "onlight": {
          "value": "#dcf5e6",
          "attributes": {
            "category": "color",
            "type": "success",
            "item": "background",
            "subitem": "onlight"
          },
          "filePath": "packages/Color/src/index.js",
          "isSource": true,
          "original": {
            "value": "#dcf5e6",
            "attributes": {
              "category": "color"
            }
          },
          "name": "FeedbackSuccessBackgroundOnlight",
          "path": [
            "feedback",
            "success",
            "background",
            "onlight"
          ]
        },
        "ondark": {
          "value": "#003514",
          "attributes": {
            "category": "color",
            "type": "success",
            "item": "background",
            "subitem": "ondark"
          },
          "filePath": "packages/Color/src/index.js",
          "isSource": true,
          "original": {
            "value": "#003514",
            "attributes": {
              "category": "color"
            }
          },
          "name": "FeedbackSuccessBackgroundOndark",
          "path": [
            "feedback",
            "success",
            "background",
            "ondark"
          ]
        }
      }
    }
  },
  "interactive": {
    "active": {
      "onlight": {
        "value": "#6f7171",
        "attributes": {
          "category": "color",
          "type": "active",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#6f7171",
          "attributes": {
            "category": "color"
          }
        },
        "name": "InteractiveActiveOnlight",
        "path": [
          "interactive",
          "active",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#a7a7a7",
        "attributes": {
          "category": "color",
          "type": "active",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#a7a7a7",
          "attributes": {
            "category": "color"
          }
        },
        "name": "InteractiveActiveOndark",
        "path": [
          "interactive",
          "active",
          "ondark"
        ]
      }
    },
    "disabled": {
      "onlight": {
        "value": "#d8dada",
        "attributes": {
          "category": "color",
          "type": "disabled",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#d8dada",
          "attributes": {
            "category": "color"
          }
        },
        "name": "InteractiveDisabledOnlight",
        "path": [
          "interactive",
          "disabled",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#333333",
        "attributes": {
          "category": "color",
          "type": "disabled",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#333333",
          "attributes": {
            "category": "color"
          }
        },
        "name": "InteractiveDisabledOndark",
        "path": [
          "interactive",
          "disabled",
          "ondark"
        ]
      }
    },
    "scrollthumb": {
      "onlight": {
        "value": "#6f7171",
        "attributes": {
          "category": "color",
          "type": "scrollthumb",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#6f7171",
          "attributes": {
            "category": "color"
          }
        },
        "name": "InteractiveScrollthumbOnlight",
        "path": [
          "interactive",
          "scrollthumb",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#a7a7a7",
        "attributes": {
          "category": "color",
          "type": "scrollthumb",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#a7a7a7",
          "attributes": {
            "category": "color"
          }
        },
        "name": "InteractiveScrollthumbOndark",
        "path": [
          "interactive",
          "scrollthumb",
          "ondark"
        ]
      },
      "hover": {
        "onlight": {
          "value": "#333333",
          "attributes": {
            "category": "color",
            "type": "scrollthumb",
            "item": "hover",
            "subitem": "onlight"
          },
          "filePath": "packages/Color/src/index.js",
          "isSource": true,
          "original": {
            "value": "#333333",
            "attributes": {
              "category": "color"
            }
          },
          "name": "InteractiveScrollthumbHoverOnlight",
          "path": [
            "interactive",
            "scrollthumb",
            "hover",
            "onlight"
          ]
        },
        "ondark": {
          "value": "#d8dada",
          "attributes": {
            "category": "color",
            "type": "scrollthumb",
            "item": "hover",
            "subitem": "ondark"
          },
          "filePath": "packages/Color/src/index.js",
          "isSource": true,
          "original": {
            "value": "#d8dada",
            "attributes": {
              "category": "color"
            }
          },
          "name": "InteractiveScrollthumbHoverOndark",
          "path": [
            "interactive",
            "scrollthumb",
            "hover",
            "ondark"
          ]
        }
      }
    },
    "scrolltrack": {
      "onlight": {
        "value": "#d8dada",
        "attributes": {
          "category": "color",
          "type": "scrolltrack",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#d8dada",
          "attributes": {
            "category": "color"
          }
        },
        "name": "InteractiveScrolltrackOnlight",
        "path": [
          "interactive",
          "scrolltrack",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#333333",
        "attributes": {
          "category": "color",
          "type": "scrolltrack",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#333333",
          "attributes": {
            "category": "color"
          }
        },
        "name": "InteractiveScrolltrackOndark",
        "path": [
          "interactive",
          "scrolltrack",
          "ondark"
        ]
      }
    }
  },
  "elements": {
    "primary": {
      "onlight": {
        "value": "#000000",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#000000",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ElementsPrimaryOnlight",
        "path": [
          "elements",
          "primary",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#ffffff",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#ffffff",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ElementsPrimaryOndark",
        "path": [
          "elements",
          "primary",
          "ondark"
        ]
      }
    },
    "secondary": {
      "onlight": {
        "value": "#6f7171",
        "attributes": {
          "category": "color",
          "type": "secondary",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#6f7171",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ElementsSecondaryOnlight",
        "path": [
          "elements",
          "secondary",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#a7a7a7",
        "attributes": {
          "category": "color",
          "type": "secondary",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#a7a7a7",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ElementsSecondaryOndark",
        "path": [
          "elements",
          "secondary",
          "ondark"
        ]
      }
    },
    "brandhighlight": {
      "value": "#ee0000",
      "attributes": {
        "category": "color",
        "type": "brandhighlight"
      },
      "filePath": "packages/Color/src/index.js",
      "isSource": true,
      "original": {
        "value": "#ee0000",
        "attributes": {
          "category": "color"
        }
      },
      "name": "ElementsBrandhighlight",
      "path": [
        "elements",
        "brandhighlight"
      ]
    },
    "lowcontrast": {
      "onlight": {
        "value": "#d8dada",
        "attributes": {
          "category": "color",
          "type": "lowcontrast",
          "item": "onlight"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#d8dada",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ElementsLowcontrastOnlight",
        "path": [
          "elements",
          "lowcontrast",
          "onlight"
        ]
      },
      "ondark": {
        "value": "#333333",
        "attributes": {
          "category": "color",
          "type": "lowcontrast",
          "item": "ondark"
        },
        "filePath": "packages/Color/src/index.js",
        "isSource": true,
        "original": {
          "value": "#333333",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ElementsLowcontrastOndark",
        "path": [
          "elements",
          "lowcontrast",
          "ondark"
        ]
      }
    }
  }
}