interface DropdownOption {
  value: string;
}

const SORT_BY_OPTIONS: DropdownOption[] = [
  {
    value: "Sent date (newest - oldest)",
  },
  {
    value: "Sent date (oldest - newest)",
  },
];

export default SORT_BY_OPTIONS;