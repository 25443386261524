import {OwnerActionsInfoModalProps} from "interfaces";
import { TextButton,
         FlexBox,
         Modal,
         Title,
         Body } from "components";
// @ts-ignore
import { Icon } from "@vds/icons";

const OwnerActionsInfoModal = ({
  open,
  onClose,
}: OwnerActionsInfoModalProps) => {
  
  const handleClose = () => {
    onClose();
  };

  return ( open ? (
      <Modal testId="owner-actions-info-modal" open={true} onClose={handleClose}>
        <FlexBox>
          <Title size="large" bold pd="0 0 2rem">Owner information</Title>
          <Title size="small" bold>Expiring an asset:</Title>
          <Body size="large" pd="0.5rem 0 2rem">This allows for the asset to still be searchable in Resource Library but it is no longer available for download. All
            users who have downloaded the asset within the last 6 months are notified of it’s expiration.</Body>
        
          <Title size="small" bold>Deactivating an asset:</Title>
          <Body size="large" pd="0.5rem 0 4rem">This removes the asset from the Resource Library. The asset can no longer be found in search results and cannot be
            downloaded.</Body>
          <FlexBox row align="center" justify="flex-start">
            <TextButton data-testid="cancel-button" onClick={handleClose}>Cancel</TextButton>
          </FlexBox>
        </FlexBox>
      </Modal>
    ) : <></>
  );
};

export default OwnerActionsInfoModal;
