import {
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import {
  StyledVideoPlayer,
  StyledDetailsContainer,
} from "./VideoPlayer.module";
import { FlexBox, Title, Body, CaretLink } from "components";
import { TrainingVideoListProps } from "../../interfaces";

export type VideoPlayerProps = {
  className?: string;
  src?: string;
  transcript?: string;
  thumbnail?: string;
  videos?: TrainingVideoListProps;
  dataIndex?: number;
  setDataIndex?: Function | null;
  isVideoPlaylist?: boolean;
  autoplay?: boolean;
  disabled?: boolean;
  fullscreen?: boolean;
  selectedVideo?: boolean;
};

const VideoPlayer = ({
  className,
  src,
  transcript,
  thumbnail,
  videos = [],
  dataIndex,
  setDataIndex,
  isVideoPlaylist,
  autoplay = false,
  disabled,
  fullscreen,
  selectedVideo,
}: VideoPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  // const [progress, setProgress] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  // if the video player is used in conjunction with the video playlist component
  // @ts-ignore
  const [vid, _vid] = useState<string>();
  useEffect(() => {
    // @ts-ignore
    isVideoPlaylist ? _vid(videos[dataIndex].videoAssetUrl) : _vid(src);
  }, []);
  // let vid = isVideoPlaylist ? videos[dataIndex].videoAssetUrl : src;
  let poster = isVideoPlaylist
    ? // @ts-ignore
      videos[dataIndex].thumbnailAssetUrl
    : thumbnail;
  let script = isVideoPlaylist
    ? // @ts-ignore
      videos[dataIndex].transcriptAssetUrl
    : transcript;

  useEffect(() => {
    if (videos.length > 0) {
      setIsPlaying(false);
      _vid(videos[0]?.videoAssetUrl);
    }
    videoRef.current?.load();
  }, [videos]);

  useEffect(() => {
    if (isVideoPlaylist) {
      // @ts-ignore
      _vid(videos[dataIndex].videoAssetUrl);
      videoRef.current?.load();

      !autoplay && setIsPlaying(false);
    }
  }, [dataIndex]);

  const togglePlay = () => {
    const vidEl = videoRef.current;
    if (vidEl !== null && !disabled) {
      if (isPlaying) {
        vidEl.pause();
      } else {
        fullscreen && vidEl.requestFullscreen();
        vidEl.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleNext = () => {
    if (autoplay && setDataIndex) {
      setDataIndex(
        dataIndex !== undefined
          ? dataIndex >= videos?.length - 1
          : true
      );
    }
  };

  return (
    <FlexBox>
      <StyledVideoPlayer className={className}>
        <video
          ref={videoRef}
          src={vid}
          width="100%"
          height="100%"
          controls
          poster={poster}
          onEnded={toggleNext}
          autoPlay={isPlaying ? autoplay : false}
          muted
          crossOrigin="anonymous"
        >
          {script && <track src={script} srcLang="en" />}

          <source src={vid} type="video/mp4" />
        </video>
        {!isPlaying && (
          <FlexBox className="play-button-container">
            <button
              className="play-button"
              aria-label="play video"
              onClick={togglePlay}
            >
              <svg
                width="80"
                height="79"
                viewBox="0 0 80 79"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40.2178 0.0751953C18.3599 0.0751953 0.873535 17.4856 0.873535 39.2487C0.873535 61.0117 18.3599
            78.4222 40.2178 78.4222C62.0757 78.4222 79.5621 61.0117 79.5621 39.2487C79.5621 17.4856 61.6386 0.0751953
            40.2178 0.0751953ZM40.2178 73.199C21.42 73.199 5.68228 57.9649 5.68228 38.8134C5.68228 19.6619 20.9828
            4.42781 40.2178 4.42781C59.4528 4.42781 74.3162 20.0972 74.3162 39.2487C74.3162 58.4002 59.0156 73.199
            40.2178 73.199Z"
                  fill="white"
                />
                <path
                  d="M27.5381 17.9219V60.1422L64.2594 38.8144L27.5381 17.9219ZM32.784 26.1918L55.0791 38.8144L32.784
            51.437V26.1918Z"
                  fill="white"
                />
              </svg>
            </button>
          </FlexBox>
        )}
      </StyledVideoPlayer>

      {videos && dataIndex !== undefined && selectedVideo ? (
        <StyledDetailsContainer pd="1.5rem 0 0">
          <Title size="medium" bold>
            {videos[dataIndex].title}
          </Title>
          <Body pd="0.25rem 0 1.5rem" size="large">
            {videos[dataIndex].description}
          </Body>
          <FlexBox gap="1rem">
            {videos[dataIndex].links.map((link, index) =>
              link.linkUrl ? (
                <CaretLink
                  key={link.linkUrl}
                  caretPosition="right"
                  fontSize="0.825"
                  external={link.linkExternal}
                  to={link.linkUrl}
                >
                  {link.linkText}
                </CaretLink>
              ) : (
                <Fragment key={index}></Fragment>
              )
            )}
          </FlexBox>
        </StyledDetailsContainer>
      ) : (
        <></>
      )}
    </FlexBox>
  );
};

export default VideoPlayer;
