import styled from "styled-components";
import React from "react";

export default interface RadioProps {
  bold?: boolean;
}

export const RadioWrapper = styled("div")<RadioProps>`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1.5rem;
  label > div > span {
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
    font-weight: normal;
    font-size: 0.75rem;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, Sans-serif;
  }
`;
