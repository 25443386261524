import { ButtonWrapper, StyledIconButton } from "./IconButton.module";
import { Icon, IconProps } from "@vds/icons";
import { ButtonIcon, ButtonIconProps } from "@vds/button-icons";
import { Tooltip } from "@mui/material";

export interface IconButtonProps extends StyledIconProps {
  kind?: ButtonIconProps["kind"];
  surfaceType?: ButtonIconProps["surfaceType"];
  className?: string;
  name: string;
  size?: string;
  color?: string;
  onKeyDown?: Function;
  noHover?: boolean;
  children?: any;
  fitToIcon?: boolean;
  addTooltip?: boolean;
  toolTipText?: string;
  testId?: string;
}

export interface StyledIconProps {
  onClick?: any;
  onKeyDown?: any;
  disabled?: boolean;
  ariaHidden?: boolean;
  ariaLabel?: string;
  mg?: string;
  bold?: boolean;
  tabIndex?: number;
}

const IconButton = ({
  kind = "ghost",
  surfaceType,
  className = "",
  name,
  size = "small",
  color = "#000000",
  surface,
  ariaHidden = false,
  tabIndex = 0,
  onClick,
  onKeyDown,
  disabled,
  noHover,
  children,
  ariaLabel,
  mg,
  bold,
  fitToIcon = true,
  addTooltip = false,
  toolTipText = "",
  testId,
}: IconButtonProps & IconProps) => {
  return noHover ? (
    <StyledIconButton
      type="button"
      className={className}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled}
      aria-hidden={ariaHidden}
      mg={mg}
      bold={bold}
      role="button"
      tabIndex={tabIndex}
      data-testid={testId ? testId : "icon-button"}
    >
      <Icon
        name={name}
        size={size}
        color={disabled ? "#D8DADA" : color}
        ariaLabel={ariaLabel}
      />
      {children && children}
    </StyledIconButton>
  ) : (
    <ButtonWrapper className={className} mg={mg}>
      {addTooltip ? (
        <Tooltip title={toolTipText}>
          <div>
            <ButtonIcon
              kind={kind}
              surfaceType={surfaceType}
              onClick={onClick}
              onKeyDown={onKeyDown}
              renderIcon={() => (
                <Icon
                  name={name}
                  size={size}
                  color={disabled ? "#D8DADA" : color}
                />
              )}
              ariaLabel={ariaLabel}
              ariaHidden={ariaHidden}
              surface={surface}
              fitToIcon={fitToIcon}
              disabled={disabled}
              data-testid={testId ? testId : "icon-button"}
            />
          </div>
        </Tooltip>
      ) : (
        <ButtonIcon
          kind={kind}
          surfaceType={surfaceType}
          onClick={onClick}
          onKeyDown={onKeyDown}
          renderIcon={() => (
            <Icon
              name={name}
              size={size}
              color={disabled ? "#D8DADA" : color}
            />
          )}
          ariaLabel={ariaLabel}
          ariaHidden={ariaHidden}
          surface={surface}
          fitToIcon={fitToIcon}
          disabled={disabled}
          data-testid={testId ? testId : "icon-button"}
        />
      )}
      {children && children}
    </ButtonWrapper>
  );
};

export default IconButton;
