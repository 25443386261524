import { withFormik } from "formik";
import { compose } from "@reduxjs/toolkit";
import { withAction } from "HOCs/withAction";
import { signUp } from "services";
import mapPropsToValues from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import SignUpForm from "./SignUpForm";

export const SignUpFormContainer = compose(
  withAction((props: any) => signUp(props), "signUp"),
  withFormik({
    mapPropsToValues,
    validationSchema: (props: any) => validationSchema(props),
    handleSubmit: () => {},
    enableReinitialize: true,
  })
)(SignUpForm);

export default SignUpFormContainer;
