import { BASE_URL } from "utils/environmentVariables";

export function externalRoute(path: string): string {
  return path
    ? path.startsWith("http")
      ? path
      : path.startsWith("/")
      ? `${BASE_URL}${path}`
      : `${BASE_URL}/${path}`
    : "";
}
