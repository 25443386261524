import { HandleSubmitProps, CountryProps } from "interfaces";

interface formValues {
  country: string;
  phone: string;
}
interface props {
  props: { countries: Array<CountryProps> };
}

export default function (
  values: formValues,
  { props: { action, countries } }: HandleSubmitProps & props
) {
  const plusCode =
      countries.find((val: any) => val.id === values.country)?.plusPhoneCode ||
      null,
    phoneNumber = plusCode + values.phone;
  return action({ phoneNumber });
}
