import styled from "styled-components";
import { zIndex } from "theme/variables";

export const StyledBanner = styled("div")`
  display: block;
  padding: 0.75rem 12rem;
  background-color: #0088ce;
  text-align: center;
  position: relative;
  z-index: ${zIndex.banner};
  p {
    color: #ffffff;
    font-size: 1rem;
    font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
    font-weight: bold;
  }
  a {
    color: #ffffff;
  }
`;

export const StyledTextLinkWrapper = styled("div")`
  a > div > p {
    border-bottom: 1px solid #ffffff;
    font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
    font-weight: bold;
  }
`;

export const StyledCountWrapper = styled("div")`
  div > p {
    background: #ffffff;
    color: #0088ce;
    border-radius: 50%;
    padding: 0.25rem;
    height: 1.5rem;
    width: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;
