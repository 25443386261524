import { CheckboxProps as VdsCheckboxProps } from "@vds/checkboxes";
import { StyledCheckbox } from "./Checkbox.module";

export interface CheckboxProps {
  label?: string;
  inputId?: string;
}

const Checkbox = ({
  children,
  selected,
  onChange,
  inputId,
  ...rest
}: CheckboxProps & VdsCheckboxProps) => {
  return (
    <StyledCheckbox
      inputId={inputId}
      onChange={onChange}
      selected={selected}
      {...rest}
    >
      {children}
    </StyledCheckbox>
  );
};

export default Checkbox;
