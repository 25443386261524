import { FormikProps } from "interfaces";
import { resetAuthNotification, selectAuthNotification } from "services";
import Button from "components/buttons/Button/Button";
import ButtonGroup from "components/buttons/ButtonGroup/ButtonGroup";
import Input from "components/forms/Input/Input";
import { Form, Notification, TextArea } from "components";
import { Dispatch, SetStateAction } from "react";
import { setNotification } from "../../services";
import { useDispatch } from "hooks/redux";

import { useSendContactMutation } from "services/api/api.slice";

interface ContactProps {
  setSuccess: Dispatch<SetStateAction<boolean>>;
  distributionId: string;
}

const ContactForm = ({
  values: { name, comments },
  handleChange,
  handleBlur,
  touched,
  errors,
  dirty,
  isValid,
  setSuccess,
  distributionId,
}: FormikProps & ContactProps) => {
  const [sendContact, { isLoading }] = useSendContactMutation();

  const dispatch = useDispatch();

  const handleOnSubmit = () => {
    sendContact({ name, comments, distributionId })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: `Successfully submitted Contact form.`,
          })
        );
        setSuccess((success) => !success);
      })
      .catch(() => {
        dispatch(
          setNotification({
            type: "error",
            message: `Could not submit Contact form.`,
          })
        );
      });
  };

  return (
    <Form data-testid="contact-form" onSubmit={handleOnSubmit} gap="1rem">
      <Input
        value={name}
        onChange={handleChange}
        onBlur={handleBlur}
        name="name"
        data-testid="name"
        error={touched.name && !!errors.name}
        errorText={errors.name}
        disabled={isLoading}
      />
      <TextArea
        value={comments}
        onChange={handleChange}
        onBlur={handleBlur}
        name="comments"
        error={touched.comments && !!errors.comments}
        errorText={errors.comments}
        disabled={isLoading}
        required
        data-testid="comments"
        maxLength={1000}
      />
      {/*<Notification*/}
      {/*  selector={selectAuthNotification}*/}
      {/*  reset={resetAuthNotification}*/}
      {/*  inline*/}
      {/*/>*/}
      <ButtonGroup align="flex-start" pd="1.5rem 0">
        <Button
          type="submit"
          use="primary"
          disabled={isLoading || !(dirty && isValid)}
          data-testid="submit"
        >
          Submit
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default ContactForm;
