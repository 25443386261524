import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ColorTokens } from '@vds-tokens/color';
import { calculateRem, withVDSManager } from '@vds-core/utilities';

function _calculateLineColor(type, surface) {
  let lineColor = ColorTokens.elements.primary.onlight.value;
  if (type === 'accent') lineColor = '#d52b1e';else if (surface !== 'dark' && (type === 'xLight' || type === 'secondary')) lineColor = ColorTokens.elements.lowcontrast.onlight.value;else if (surface === 'dark' && (type === 'xLight' || type === 'secondary')) lineColor = ColorTokens.elements.lowcontrast.ondark.value;else if (surface === 'dark') lineColor = ColorTokens.elements.primary.ondark.value;
  return lineColor;
}
const propTypes = {
  /**
   * @ignore Type of the line that will be displayed.
   */
  type: PropTypes.oneOf(['heavy', 'light', 'xLight', 'accent', 'primary', 'secondary']),
  /**
   * @ignore
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * Allows a unique ID to be passed to the component.
   */
  id: PropTypes.string
};
const defaultProps = {
  type: 'primary',
  surface: 'light'
};
const StyledLine = styled.div.withConfig({
  displayName: "StyledLine",
  componentId: "VDS__sc-7j8mur-0"
})`
  height: ${({
  type
}) => calculateRem(type === 'heavy' || type === 'accent' ? 4 : 1)};
  width: 100%;
  background-color: ${({
  type,
  surface
}) => _calculateLineColor(type, surface)};
`;
const Line = props => {
  const {
    type,
    surface,
    id
  } = props;
  return React.createElement(StyledLine, Object.assign({}, props, {
    id: id,
    type: type,
    surface: surface
  }));
};
Line.propTypes = propTypes;
Line.defaultProps = defaultProps;
var Line$1 = withVDSManager(Line);

export { Line$1 as default };
