/* eslint-disable */
import { useEffect, useState, useRef } from "react";
import { marked } from "marked";
import { CSSProperties } from "styled-components";
import { StyledMdToHtml } from "./MdToHtml.module";

export interface MdToHtmlProps {
  content: any;
  customStyles?: CSSProperties;
  pSize?: string;
  linkSize?: string;
  color?: string;
  pMargin?: string;
  linkMargin?: string;
  linksInNewTab?: boolean;
  pd?: string;
  linkTextDecoration?: boolean;
}

const MdToHtml = ({
  content,
  color,
  pSize,
  linkSize,
  customStyles,
  linkMargin,
  pMargin,
  linksInNewTab,
  pd,
  linkTextDecoration,
}: MdToHtmlProps) => {
  const [rootContent, setRootContent] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const mdToHTMLRef = useRef<HTMLDivElement | null>(null);

  const setContentMarkDownLinks = (): void => {
    const mdlinks = mdToHTMLRef?.current?.getElementsByTagName("a");
    const links = Array.prototype.slice.call(mdlinks);
    for (let x = 0, len = links.length; x < len; x++) {
      links[x].target = "_blank";
    }
  };

  useEffect(() => {
    if (content) {
      setLoading(false);
    }
  }, [content]);

  useEffect(() => {
    if (!loading) {
      const parsedContent = marked.parse(content, {
        gfm: true,
        breaks: true,
      });
      setRootContent(parsedContent);
    }
  }, [loading]);

  const getContent = (): string => {
    const parsedContent = marked.parse(content, {
      gfm: true,
      breaks: true,
    });
    return parsedContent;
  };

  useEffect(() => {
    if (linksInNewTab && rootContent) {
      setContentMarkDownLinks();
    }
  }, [rootContent]);

  return loading ? (
    <></>
  ) : (
    <StyledMdToHtml
      pd={pd}
      color={color}
      linkMargin={linkMargin}
      pMargin={pMargin}
      linkSize={linkSize}
      pSize={pSize}
      linkTextDecoration={linkTextDecoration}
      data-testid="md-to-html"
    >
      <div
        ref={mdToHTMLRef}
        className="md-to-html"
        style={customStyles}
        dangerouslySetInnerHTML={{
          __html: linksInNewTab ? rootContent : getContent(),
        }}
      ></div>
    </StyledMdToHtml>
  );
};

export default MdToHtml;
