import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  IconButton,
  Body,
  Image,
  FlexBox,
  Checkbox,
  BasicCarousel,
  Micro, Title,
} from "components";
import { ImageWrapper, StyledAssetCard } from "./AssetCardLarge.module";
import {UnifiedSearchResultProps, AssetFile, AssetFileGroup} from "interfaces";
import {
  formatDate,
  expiresWithin90Days,
  isExpired,
} from "utils/dateFunctions";
import {
  FavoriteModal,
  FullscreenImgCarouselModal,
  ShareModal,
  UsageRightsModal,
} from "features";
// @ts-ignore
import { Icon } from "@vds/icons";
import { getPrimaryFile, getThumbnails } from "utils/assetThumbnails";
import {
  useLazyGetAssetDownloadUrlQuery,
  useTrackingMutation,
} from "services/api/api.slice";
import { openFileDownload } from "utils/assetFiles";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import {buildFinalTrackingObject, externalRoute} from "utils";
import DownloadOptionsModal from "features/downloadOptionsModal/DownloadOptionsModal";
import paths from "../../routing/paths";

interface AssetCardProps {
  item: UnifiedSearchResultProps;
  fileType?: string;
  selected?: boolean;
  disableSelection?: boolean;
  onDownload?: () => void;
  onShare?: () => void;
  isExpanded?: (expanded: boolean) => void;
  onSelectChange?: (id: string) => void;
  assetBucket?: string;
  trackingData?: Record<string, unknown>;
  searchResultNum?: number;
  maxWidth?: string;
  correlationId?: string;
  isMobile?: boolean;
  onFavoriteChange?: () => void;
}

const AssetCardLarge = ({
  item,
  selected,
  onSelectChange,
  trackingData,
  maxWidth,
  correlationId,
  isMobile,
  onFavoriteChange,
}: AssetCardProps) => {
  const [trackAction] = useTrackingMutation();

  const location = useLocation();
  
  const trackEvent = (action: string): void => {
    const payloadData = {
      version: "1.0.0",
      context: trackingData,
      type: "asset-card",
      action,
      entityType: "asset",
      id: item.id,
    };
    const trackingObject = buildFinalTrackingObject(
      payloadData,
      location.pathname,
      correlationId
    );
    try {
      trackAction(trackingObject);
    } catch (error: any) {
      console.error(error);
    }
  };

  const [favoriteOpen, _openFavorite] = useState(false),
    [isFavoriteAsset, _isFavoriteAsset] = useState(item.isFavorite),
    openFavorite = () => {
      _openFavorite(true);
    },
    closeFavorite = (isFavoriteAsset: boolean) => {
      _isFavoriteAsset(isFavoriteAsset);
      _openFavorite(false);
    },
    completeFavorite = (isFavoriteAsset: boolean) => {
      trackEvent("favorite");
      onFavoriteChange && onFavoriteChange();
      closeFavorite(isFavoriteAsset);
    };

  const [expiredDate, setExpiredDate] = useState<string>("");
  const [assetIsExpired, setAssetIsExpired] = useState<boolean>(false);
  const [expiresWithin90, setExpiresWithin90] = useState<boolean>(false);

  const [fullWrapFocused, setFullWrapFocused] = useState<boolean>(false),
    focusFullWrap = () => {
      setFullWrapFocused(true);
    },
    blurFullWrap = () => {
      setFullWrapFocused(false);
    };

  const [activeThumbnail, setActiveThumbnail] = useState<number>(0),
    // [thumbnails, _thumbnails] = useState<string[]>([]),
    updateThumbnail = (index: number) => {
      setActiveThumbnail(index);
    };

  const [fullscreenOpen, setFullscreenOpen] = useState(false),
    openFullscreen = () => {
      setFullscreenOpen(true);
    },
    closeFullscreen = (index: number) => {
      updateThumbnail(index);
      setFullscreenOpen(false);
    };

  const [groups, setGroups] = useState<AssetFileGroup[] | null>(null);

  const getFileGroups = (asset: UnifiedSearchResultProps): void => {
    if (!asset.files) {
      return;
    }

    const groupNames: string[] = [];

    for (const file of asset.files) {
      if (file.groupName && file.groupName.trim().length > 0) {
        if (!groupNames.includes(file.groupName)) {
          groupNames.push(file.groupName);
        }
      }
    }
    const fileGroups: AssetFileGroup[] = [];

    if (groupNames.length > 0) {
      for (const groupName of groupNames) {
        const groupFiles = asset.files.filter(
          (file) => file.groupName === groupName && !!file.currentVersionId
        );

        if (groupFiles.length) {
          fileGroups.push({
            name: groupName,
            files: groupFiles,
            default: getPrimaryFile(groupFiles),
          });
        }
      }
    }

    setGroups(fileGroups);
  };

  const [carouselFiles, setCarouselFiles] = useState<AssetFile[] | null>(null);

  const [thumbs, _thumbs] = useState<string[]>([]);
  const getCarouselFiles = () => {
    let tempFiles: AssetFile[] = [];
    if (item.files) {
      if (groups && groups.length > 0) {
        const allFiles = item.files.map((file: any) => file);
        const groupFiles = groups.map((group) => group.default);

        if (allFiles.length) {
          allFiles.forEach((assetFile: any) => {
            if (
              !groupFiles.find((file) => file.groupName === assetFile.groupName)
            ) {
              tempFiles.push(assetFile);
            }
          });
        }

        groupFiles.forEach((file) => {
          tempFiles.push(file);
        });

        const tempGroupFiles = tempFiles.filter(
          (file) => file.thumbnails.isImage
        );
        if (tempGroupFiles.length !== 0) {
          tempFiles = [...tempGroupFiles];
        }

        _thumbs(getThumbnails(tempFiles, "medium"));

        setCarouselFiles(tempFiles);
      } else {
        // BUG: if you've just created an asset, the file won't have a currentVersionId and nothing will be displayed
        item.files.forEach((file) => {
          tempFiles.push(file);
        });
        tempFiles = tempFiles.filter((file) => file.thumbnails.isImage);
        if (tempFiles.length === 0) {
          tempFiles = [];
          item.files.forEach((file) => {
            tempFiles.push(file);
          });
        }

        _thumbs(getThumbnails(tempFiles, "medium"));

        setCarouselFiles(tempFiles);
      }
    }
  };

  const [singleFiles, _singleFiles] = useState<AssetFile[] | null>(null);

  const getSingleFiles = () => {
    if (carouselFiles !== null && carouselFiles?.length > 0) {
      let tempSingleFiles: AssetFile[] = [];
      carouselFiles.forEach((file) => {
        if (file.groupName === null) {
          tempSingleFiles.push(file);
        }
      });
      if (tempSingleFiles.length > 0) {
        _singleFiles(tempSingleFiles);
      }
    }
  };

  useEffect(() => {
    if (item) {
      getFileGroups(item);
    }
  }, [item]);

  useEffect(() => {
    if (item.files && groups) {
      getCarouselFiles();
    }
  }, [groups]);

  useEffect(() => {
    if (
      groups !== null &&
      groups?.length > 0 &&
      carouselFiles &&
      carouselFiles.length > 0
    ) {
      getSingleFiles();
    }
  }, [carouselFiles]);
  

  const [shareOpen, _shareOpen] = useState(false),
    openShare = () => {
      _shareOpen(true);
    },
    closeShare = () => {
      _shareOpen(false);
    },
    completeShare = () => {
      trackEvent("share");
      closeShare();
    };

  const onAssetLinkClick = (): void => {
    trackEvent("view");
  };

  const handleSelectChange = (): void => {
    if (onSelectChange) {
      onSelectChange(item.id);
    }
    trackEvent("select");
  };

  const maxLengthString = (value: string): string => {
    if (!value) {
      return value;
    }
    if (value.length <= 64) {
      return value;
    }
    return `${value.slice(0, 64)}...`;
  };
  
  const getLinkUrl = (correlationId?: string): string => {
    let itemUrl = item.url;
    if (item.searchType === 'template') {
      itemUrl = `/templates/details/${item.assetId}`;
    } else if (item.searchType === 'retail') {
      itemUrl = `/retail/branded-environments/design-advisories/details/${item.assetId}`;
    } else if (item.searchType === 'asset' && correlationId) {
      itemUrl = `/assets/details/${item.assetId}?correlation=${correlationId}`
    } else if (item.searchType === 'asset' && !correlationId) {
      itemUrl = `/assets/details/${item.assetId}`;
    } else if (item.searchType === 'training') {
      itemUrl = `${paths.TRAINING_VIDEOS}/${item.playlistSlug}/${item.videoSlug}`;
    }
    return itemUrl;
  };

  const checkExpirationDate = (date: string) => {
    setExpiresWithin90(expiresWithin90Days(date));
  };

  const getExpirationDate = (date: string) => {
    if (expiresWithin90 && !assetIsExpired) {
      const formattedDate: string = formatDate(date);
      setExpiredDate(formattedDate);
    }
  };

  const onFullscreenOpenClick = () => {
    openFullscreen();
  };

  const [
    getAssetUrl,
    { data: assetResult, isError: assetError, error: assetErrorMsg },
  ] = useLazyGetAssetDownloadUrlQuery();
  const callGetAssetUrl = (itemId: string) => {
    getAssetUrl(itemId);
  };

  const [rightsAccepted, setRightsAccepted] = useState<boolean>(
      item.usageRightsCopy &&
        item.userActions?.hasAcceptedUsageRights !== null
        ? item.userActions?.hasAcceptedUsageRights!
        : true
    ),
    updateUsageRights = (value: boolean) => {
      setRightsAccepted(value);
    };

  const [usageRightsModalOpen, setUsageRightsModalOpen] =
      useState<boolean>(false),
    openUsageRightsModal = () => {
      setUsageRightsModalOpen(true);
    },
    completeUsageRightsModal = () => {
      updateUsageRights(true);
      setUsageRightsModalOpen(false);
      callGetAssetUrl(item.id);
    },
    closeUsageRightsModal = () => {
      setUsageRightsModalOpen(false);
    };

  const [showDownloadOptionsModal, setShowDownloadOptionsModal] =
      useState<boolean>(false),
    openDownloadOptionsModal = () => {
      setShowDownloadOptionsModal(true);
    },
    closeDownloadOptionsModal = () => {
      setShowDownloadOptionsModal(false);
    },
    completeDownloadOptionsModal = () => {
      closeDownloadOptionsModal();
      updateUsageRights(true);
    };

  const onDownloadAssetClick = () => {
    if (
      item.usageRightsCopy &&
      item.usageRightsCopy.length > 0 &&
      !rightsAccepted
    ) {
      openUsageRightsModal();
    } else {
      callGetAssetUrl(item.id);
    }
    trackEvent("download");
  };

  const onOpenDownloadOptionsClick = () => {
    trackEvent("open-download-options-multiple-files");
    openDownloadOptionsModal();
  };

  useEffect(() => {
    if (item.expiresOn) {
      setAssetIsExpired(isExpired(item.expiresOn));
    }
  }, [item.expiresOn]);

  useEffect(() => {
    if (item.expiresOn) {
      checkExpirationDate(item.expiresOn);
    }
  }, [assetIsExpired]);

  useEffect(() => {
    if (item.expiresOn && expiresWithin90) {
      getExpirationDate(item.expiresOn);
    }
  }, [expiresWithin90]);

  // DOWNLOADING AN ENTIRE ASSET
  useEffect(() => {
    assetResult && openFileDownload(assetResult);
  }, [assetResult]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (assetError) {
      dispatch(
        setNotification({
          type: "error",
          message: "Error downloading asset.",
        })
      );
      console.error(assetErrorMsg);
    }
  }, [assetError]);
  
  const getCategoryOrSearchType = (): string => {
    let label = "";
    if (item.searchType === "guideline") {
      label = "Guideline";
    } else if (item.searchType === "template") {
      label = "Template";
    } else if (item.searchType === "retail") {
      label = "Retail";
    } else if (item.searchType === "training") {
      label = "Training";
    } else if (item.searchType === "asset" && item.category) {
      label = item.category;
    } else if (item.searchType === "compliance" && item.complianceType === "complianceFaqsItem") {
      label = "Compliance FAQ";
    } else if (item.searchType === "compliance" && item.complianceType !== "complianceFaqsItem") {
      label = "Compliance Center";
    }
    return label;
  };

  return (
    <StyledAssetCard
      className={`${isMobile ? "is-mobile" : ""}`}
      data-testid="asset-card"
      searchType={item.searchType}
    >
      <div
        className={`full-wrap ${fullWrapFocused ? "focused" : ""} ${
          isMobile ? "is-mobile" : ""
        }`}
        onMouseEnter={focusFullWrap}
        onMouseLeave={blurFullWrap}
        onBlur={blurFullWrap}
        onFocus={focusFullWrap}
        data-testid="asset-card-focusable"
      >
        <div
          className={`assetcard__wrap ${isMobile ? "is-mobile" : ""}`}
          role="group"
          aria-label="asset"
        >
          {(((item.searchType === "asset" || item.searchType  === "template" || item.searchType  === "retail") && (item.files && (item.files.length > 0) && item.files.some((file: AssetFile) => file.approvalStatus === "Approved")) && (item.userActions?.isAdmin || item.userActions?.canDownload)) || !(item.searchType === "asset" || item.searchType  === "template" || item.searchType  === "retail")) && (
            <div
              className={`asset-card-select ${
                fullWrapFocused || selected ? "focused" : ""
              } ${selected ? "selected" : ""} ${isMobile ? "is-mobile" : ""}`}
              id={`asset-${item.id}-selected`}
            >
              <Checkbox
                selected={selected ? selected : false}
                onChange={handleSelectChange}
                ariaLabel="Select asset"
                // on-change={handleSelectChange}
                // on-key-up={onKeyUp}
              />
            </div>
          )}
          
          <div className="asset-card-indicators">
            {item.isRestricted && (
              <div
                className="asset-card-indicator"
                data-testid="is-restricted-icon"
              >
                <Icon
                  name="lock-closed"
                  ariaLabel="Restricted asset: Open the asset to request access"
                  size="small"
                />
                <span>Restricted asset: Open the asset to request access</span>
              </div>
            )}

            {assetIsExpired && (
              <Body className="asset-card-indicator asset-card-indicator-text">
                Expired
              </Body>
            )}
          </div>

          <div className="media">
            {thumbs && thumbs.length > 0 && (
              <Image
                className="media__image"
                alt={`Image for: ${item.title}`}
                src={thumbs[activeThumbnail ? activeThumbnail : 0]}
                testId="main-thumb"
              />
            )}
            {item.thumbnailAssetUrl && (
              <Image
                className="media__image"
                alt={`Image for: ${item.title}`}
                src={item.thumbnailAssetUrl}
                testId="training-video-thumb"
              />
            )}
            {(item.searchType !== "guideline") && (
              <Link
                to={getLinkUrl(correlationId)}
                onClick={onAssetLinkClick}
                className="media__link"
                title={`Open new tab to view image: ${item.title}`}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="asset-details-link"
              >
                {item.searchType !== "compliance" && (
                  <span>{item.title}</span>
                )}
                {item.searchType === "compliance" && (
                  <Title bold size="small" className="compliance__title">{item.title}</Title>
                )}
              </Link>
            )}
            {(item.searchType === "guideline") && (
              <a href={externalRoute("url" in item ? item.url : "")}
                rel={"external noopener noreferrer"}
                target="_blank"
                data-testid="asset-details-link"
                className="media__link"
              >
                <Title bold size="small" className="guideline__title">{item.title}</Title>
              </a>
            )}
            {(thumbs && thumbs[0] || item.thumbnailAssetUrl) && (
              <div
                className={`asset-card-expand ${
                  fullWrapFocused ? "focused" : ""
                }`}
              >
                <IconButton
                  id={`asset-${item.entityId ? item.entityId : item.id}-expand`}
                  name="fullscreen"
                  className="thumbnail-utility-button-open"
                  ariaLabel="View fullscreen image"
                  onClick={onFullscreenOpenClick}
                  noHover
                  testId="fullscreen-button"
                />
              </div>
            )}
          </div>

          {thumbs && thumbs.length > 1 && (
            <BasicCarousel
              pd="0.5rem 0 0"
              carouselId={item.id}
              assetCardThumbnails
              className={`thumbnails-carousel ${
                fullWrapFocused ? "focused" : ""
              }`}
              navSize="1rem"
              noControls={thumbs.length <= 3}
              slides={thumbs.map(
                (file, index) =>
                  file && (
                    <FlexBox
                      className={`${
                        activeThumbnail === index ? "highlighted" : ""
                      } asset-detail-nav`}
                      key={file}
                      align="center"
                      justify="center"
                      data-testid="basic-carousel-slide"
                    >
                      <button
                        className="tile-button"
                        data-testid="basic-carousel-slide-button"
                        onClick={() => {
                          setActiveThumbnail(index);
                        }}
                      >
                        <ImageWrapper>
                          <Image src={file} />
                        </ImageWrapper>
                      </button>
                    </FlexBox>
                  )
              )}
              slidesPerView={3}
              gap="0.5rem"
              noHover
            />
          )}

          <div className="assetcard__body">
              <Micro bold>{getCategoryOrSearchType()}</Micro>
              <Body
                size="large"
                className={`assetcard__title ${
                  fullWrapFocused ? "focused" : ""
                }`}
                pd="0.25rem 0 0"
              >{item.searchType !== "guideline" && (
                <Link
                  to={getLinkUrl(correlationId)}
                  onClick={onAssetLinkClick}
                  className="link--silent"
                  title={item.title}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="item-title-link"
                >
                  {maxLengthString(item.title)}
                </Link>
              )
              }
                {item.searchType === "guideline" && (
                  <a href={externalRoute(item.url)}
                    rel={"external noopener noreferrer"}
                    target="_blank"
                    data-testid="item-title-link"
                  >
                    {maxLengthString(item.title)}
                  </a>
                )}
                
              </Body>
          </div>

          <FlexBox
            className={`assetcard__footer ${fullWrapFocused ? "focused" : ""} ${
              isMobile ? "is-mobile" : ""
            }`}
            data-testid="asset-card-large-footer"
          >
            <ul>
              <li className="assetcard__listitem">
                <IconButton
                  name={
                    isFavoriteAsset ? "added-to-favorite" : "add-to-favorite"
                  }
                  ariaLabel={
                    isFavoriteAsset ? "Remove from favorite" : "Add to favorite"
                  }
                  size="large"
                  onClick={openFavorite}
                  noHover
                  testId="favorite-button"
                />
              </li>
              <li className="assetcard__listitem">
                <IconButton
                  name="share"
                  ariaLabel="Share asset"
                  noHover
                  onClick={openShare}
                  testId="share-button"
                />
              </li>
              {((item.userActions?.isAdmin ||
                item.userActions?.canDownload) && ((item.searchType === "asset" || item.searchType  === "template" || item.searchType  === "retail") && (item.files && (item.files.length > 0) && item.files.some((file: AssetFile) => file.approvalStatus === "Approved")))) && (
                <li className="assetcard__listitem">
                  <IconButton
                    name="download"
                    ariaLabel="Download asset"
                    noHover
                    onClick={
                      item.files &&
                      groups !== null &&
                      groups.length >= 2 &&
                      item.files.length > 0
                        ? onOpenDownloadOptionsClick
                        : onDownloadAssetClick
                    }
                    testId="download-button"
                  />
                </li>
              )}
            </ul>
            {expiresWithin90 && !assetIsExpired && (
              <span className="expiration-date" data-testid="expires-date">
                <Micro color="#747676">Expiring soon: {expiredDate}</Micro>
              </span>
            )}
          </FlexBox>
        </div>
      </div>

      {favoriteOpen && (
        <FavoriteModal
          items={[item]}
          onComplete={(isFavorite: boolean) => completeFavorite(isFavorite)}
          onClose={(isFavorite: boolean) => closeFavorite(isFavorite)}
        />
      )}

      {shareOpen && (
        <ShareModal
          onClose={closeShare}
          items={[item]}
          onComplete={completeShare}
        />
      )}

      {showDownloadOptionsModal && (
        <DownloadOptionsModal
          files={singleFiles}
          assetId={item.id}
          userCanDownload={
            item.userActions?.canDownload
              ? item.userActions?.canDownload!
              : false
          }
          onClose={closeDownloadOptionsModal}
          groups={groups}
          usageRightsCopy={
            item.usageRightsCopy ? item.usageRightsCopy! : undefined
          }
          onComplete={completeDownloadOptionsModal}
          usageRightsAccepted={rightsAccepted}
        />
      )}

      {usageRightsModalOpen && (
        <UsageRightsModal
          assetId={item.id}
          usageRightsCopy={
            item.usageRightsCopy ? item.usageRightsCopy : undefined
          }
          onComplete={completeUsageRightsModal}
          onClose={closeUsageRightsModal}
        />
      )}

      {fullscreenOpen && (
        <FullscreenImgCarouselModal
          assetId={item.id}
          onClose={(index: number) => closeFullscreen(index)}
          activeThumbnail={activeThumbnail}
          files={item.files && item.files.length > 0 ? item.files : []}
          trainingVideoThumb={item.thumbnailAssetUrl ? [item.thumbnailAssetUrl] : []}
          onNavClick={(index: number) => updateThumbnail(index)}
        />
      )}
    </StyledAssetCard>
  );
};

export default AssetCardLarge;
