import { Body, Button, FlexBox } from "../../components";
import { Icon } from "@vds/icons";
import { useNavigate } from "react-router-dom";
import paths from "../../routing/paths";

const MfaSkip = () => {
  const navigate = useNavigate();

  const handleOk = () => {
    navigate(paths.HOME);
  };
  return (
    <FlexBox maxWidth="380px">
      <FlexBox row mg="5rem 0 1.25rem">
        <Icon name="my-account" />
        <Body size="large" pd="0 0 0 0.5rem">
          If you decide that you wish to set up SMS two-factor authentication,
          you may do so in your account settings.
        </Body>
      </FlexBox>
      <Button data-testid="ok-button" type="button" onClick={handleOk}>
        Ok
      </Button>
    </FlexBox>
  );
};

export default MfaSkip;
