import styled from "styled-components";
import FlexBox from "../FlexBox/FlexBox";
import { minTablet } from "theme/variables";

export const StyledKeywordsContainer = styled(FlexBox)`
  @media screen and (max-width: ${minTablet - 1}px) {
    padding: 3rem 0;
  }

  @media screen and (min-width: ${minTablet}px) {
    padding: 4rem 0 0;
  }
`;