import { SubmitFileCourierPackageModalProps } from "interfaces";
import {
  Button,
  FlexBox,
  Modal,
  Title,
  TwoUp,
  Body,
} from "components";
import {Icon} from "@vds/icons";
import {
  StyledEmailList,
  StyledFileList,
  StyledHorizontalRule,
} from "./SubmitFileCourierPackageModal.module";

const SubmitFileCourierPackageModal = ({
  data,
  onClose,
}: SubmitFileCourierPackageModalProps) => {
  const { sendToUsers, files } = data;

  return (
    <Modal testId="submit-fcpackage-modal" open={true} onClose={onClose}>
      <FlexBox row gap="1rem" pd={"1.5rem 0 0 0"}>
        <Icon name="checkmark-alt" size="XLarge"/>
        <Title bold>
          Files sent successfully
        </Title>
      </FlexBox>
      <TwoUp pd={"1.5rem 0 0 0"}>
        <FlexBox gap="1rem" maxWidth={"32rem"}>
          <FlexBox gap=".25rem">
            <Body size="large" pd={".25rem 0 0 0"} bold>
              Recipients
            </Body>
            <StyledHorizontalRule />
            <StyledEmailList>
              {sendToUsers.map(({email}) => {
                return (
                  <li key={email}>
                    <Body>
                      {email}
                    </Body>
                  </li>
                )
              })}
            </StyledEmailList>
          </FlexBox>

          <Button
            type="submit"
            use="primary"
            data-testid="cancel-button"
            onClick={onClose}
          >
            Return to File Courier
          </Button>
        </FlexBox>
        <FlexBox gap="1rem" maxWidth={"32rem"}>
          <FlexBox gap=".25rem">
            <Body size="large" pd={".25rem 0 0 0"} bold>
              Files Sent
            </Body>
            <StyledHorizontalRule />
            <StyledFileList>
              {files.map(({ name }) => (
                  <li key={name}>
                    <Body>
                      {name}
                    </Body>
                  </li>
                )
              )}
            </StyledFileList>
          </FlexBox>
        </FlexBox>
      </TwoUp>
    </Modal>
  );
};

export default SubmitFileCourierPackageModal;