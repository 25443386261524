import React from 'react';
import PropTypes from 'prop-types';
import { calculateRem } from '@vds/utilities';
import { TextLink as TextLink$1 } from '@vds-core/buttons';
import { ColorTokens } from '@vds-tokens/color';
import { TypographyTokens } from '@vds-tokens/typography';

const propTypes = {
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const _calculateColors = function (surface) {
  return {
    default: surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value,
    hover: surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value,
    active: surface === 'dark' ? ColorTokens.interactive.active.ondark.value : ColorTokens.interactive.active.onlight.value,
    disabled: surface === 'dark' ? ColorTokens.interactive.disabled.ondark.value : ColorTokens.interactive.disabled.onlight.value
  };
};
const _calculateUnderLine = function (calculateColors, surface) {
  const color = calculateColors(surface);
  return {
    default: `${calculateRem(1)} solid ${color.default}`,
    hover: `${calculateRem(2)} solid ${color.hover}`,
    active: `${calculateRem(2)} solid ${color.active}`,
    disabled: `${calculateRem(1)} solid ${color.disabled}`
  };
};
const _calculateLetterSpacing = function (size, viewport, bold) {
  let letterSpacing = '0px';
  if (size === 'large' && viewport === 'desktop') {
    letterSpacing = TypographyTokens.letterspacing.wide.value;
  }
  return letterSpacing;
};
const TextLink = function (props) {
  return React.createElement(TextLink$1, Object.assign({}, props, {
    calculateColors: _calculateColors,
    calculateUnderLine: _calculateUnderLine,
    calculateLetterSpacing: _calculateLetterSpacing,
    focusRingBorderRadius: "2px",
    bold: false
  }));
};
TextLink.propTypes = propTypes;
TextLink.defaultProps = defaultProps;

export { TextLink as default };
