import { CreateGroupModalProps } from "interfaces";
import {
  TextButton,
  Button,
  FlexBox,
  Modal,
  Title,
  ButtonGroup,
  Form,
  Input,
} from "components";
import { useAdminCreateGroupMutation } from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import { FormikProps } from "interfaces";
import { StyledForm } from "./CreateGroupModal.module";

export enum ManagedStatus {
  notSet = "Not Set",
  active = "Active",
  inactive = "Inactive",
  deleted = "Deleted",
  requested = "Requested",
}

const CreateGroupModal = ({
  onClose,
  onComplete,
  values,
  handleChange,
  touched,
  errors,
  dirty,
  isValid,
  resetForm,
}: CreateGroupModalProps & FormikProps) => {
  const [createGroup, { isLoading }] = useAdminCreateGroupMutation();

  const dispatch = useDispatch();

  const handleOnSubmit = () => {
    createGroup({
      Name: values.groupName,
      Company: values.companyName,
      Status: ManagedStatus.active,
    })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: "Group successfully added.",
          })
        );
        resetForm();
        onComplete();
      })
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Error creating group.",
          })
        );
        console.error(error);
      });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal testId="create-group-modal" open onClose={handleClose} disabled={isLoading}>
      <FlexBox minWidth="100%">
        <Title size="large" bold pd="0 0 2rem">
          Add a new group
        </Title>
        <StyledForm maxWidth="424px">
          <Form onSubmit={handleOnSubmit} width="100%" gap="1rem">
            <Input
              value={values.groupName}
              required={true}
              onChange={handleChange}
              name="groupName"
              label="Group name"
              error={touched.groupName && !!errors.groupName}
              errorText={errors.groupName}
              disabled={isLoading}
              data-testid="group-name"
            />
            <Input
              value={values.companyName}
              required={true}
              onChange={handleChange}
              name="companyName"
              label="Company Name"
              error={touched.companyName && !!errors.companyName}
              errorText={errors.companyName}
              disabled={isLoading}
              data-testid="company-name"
            />
            <ButtonGroup>
              <Button
                use="primary"
                type="submit"
                disabled={!(dirty && isValid) || isLoading}
                data-testid="submit-button"
              >
                Add
              </Button>
              <TextButton
                onClick={handleClose}
                disabled={isLoading}
                data-testid="cancel-button"
              >
                Cancel
              </TextButton>
            </ButtonGroup>
          </Form>
        </StyledForm>
      </FlexBox>
    </Modal>
  );
};

export default CreateGroupModal;
