import { Pagination as VdsPagination } from "@vds/pagination";

export interface PaginationProps {
  total: number;
  selectPage?: (newPage: number) => void;
  selectedPage?: number;
  className?: string;
  ariaLabel?: string;
}

const Pagination = ({ ...rest }: PaginationProps) => {
  return rest.total > 1 ? <VdsPagination {...rest} /> : <></>;
};

export default Pagination;
