import 'react';
import PropTypes from 'prop-types';

var sharedPropTypes = {
  baseUrl: PropTypes.func,
  current: PropTypes.number,
  selectedPage: PropTypes.number,
  selectPage: PropTypes.func,
  total: PropTypes.number.isRequired,
  surface: PropTypes.oneOf(['light', 'dark']),
  focusBorderRadius: PropTypes.string
};

export { sharedPropTypes as default };
