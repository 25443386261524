import { Icon } from "@vds/icons";
import FlexBox from "../FlexBox/FlexBox";
import Body from "../../typography/Body/Body";
import { useEffect } from "react";

export interface PasswordReqsProps {
  password: string;
  className?: string;
  setPassed: Function;
}

const PasswordReqs = ({
  password,
  className,
  setPassed,
}: PasswordReqsProps) => {
  const conditionOne = password.length >= 8,
    conditionTwo =
      new RegExp(/[A-Z]/).test(password) && new RegExp(/[a-z]/).test(password),
    conditionThree = new RegExp(/\d/).test(password),
    conditionFour = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/).test(
      password
    ),
    passed = conditionOne && conditionTwo && conditionThree && conditionFour,
    checked = <Icon name="checkmark" color="#00ac3e" />,
    unchecked = <Icon name="close" />;

  useEffect(() => {
    setPassed(passed);
  }, [passed]);

  return (
    <FlexBox maxWidth="25ch" className={className}>
      <Body size="large" bold pd="0 0 0.5rem 0">
        Password requirements
      </Body>
      <FlexBox gap="0.5rem">
        <FlexBox align="center" row mg="0 0 0.125rem 0">
          {conditionOne ? checked : unchecked}
          <Body pd="0 0 0 0.5rem">At least 8 characters long</Body>
        </FlexBox>
        <FlexBox align="center" row mg="0 0 0.125rem 0">
          {conditionTwo ? checked : unchecked}
          <Body pd="0 0 0 0.5rem">
            Includes uppercase and lowercase characters
          </Body>
        </FlexBox>
        <FlexBox align="center" row mg="0 0 0.125rem 0">
          {conditionThree ? checked : unchecked}
          <Body pd="0 0 0 0.5rem">At least 1 number</Body>
        </FlexBox>
        <FlexBox align="center" row>
          {conditionFour ? checked : unchecked}
          <Body pd="0 0 0 0.5rem">
            Must contain at least one symbol (i.e. !@#$%^&*)
          </Body>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default PasswordReqs;
