import styled from "styled-components";
import { StyledIconProps } from "./IconButton";

export const ButtonWrapper = styled.div<StyledIconProps>`
  margin: ${(props) => props.mg || ``};

  &.fullscreen-button {
    position: absolute;
    top: 1rem;
    left: 50%;
    border-radius: 50%;
    background-color: #ffffff;
  }
`;

export const StyledIconButton = styled.button<StyledIconProps>`
  display: flex;
  background: none;
  border: none;

  margin: ${(props) => props.mg || ``};

  &:not([disabled]):hover {
    cursor: pointer;
  }

  &.disabled:hover {
    cursor: default;
  }

  > svg > polygon {
    ${(props) => props.bold && `stroke: black;`};
  }
  > svg > path {
    ${(props) => props.bold && `stroke: black;`};
  }
`;
