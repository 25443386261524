import { withFormik } from "formik";
import mapPropsToValues, { FormValues } from "./mapPropsToValues";
import ShareSavedSearchesModal from "./ShareSavedSearchesModal";
import { ShareSavedSearchesModalProps } from "interfaces/Modal";

export const ShareSavedSearchesModalContainer = withFormik<
  ShareSavedSearchesModalProps,
  FormValues
>({
  mapPropsToValues,
  handleSubmit: () => null,
  enableReinitialize: true,
  validateOnMount: true,
})(ShareSavedSearchesModal);

export default ShareSavedSearchesModalContainer;
