import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as Tooltip$1 } from '@vds-core/tooltips';
import '@vds/utilities';
import { ColorTokens } from '@vds-tokens/color';

const _calculateTrackColor = function (surface) {
  let trackColor;
  if (surface === 'dark') {
    trackColor = ColorTokens.interactive.scrolltrack.ondark.value;
  } else {
    trackColor = ColorTokens.interactive.scrolltrack.onlight.value;
  }
  return trackColor;
};
const _calculateThumbColor = function (surface, hover) {
  let thumbColor;
  if (surface === 'dark' && !hover) {
    thumbColor = ColorTokens.interactive.scrollthumb.ondark.value;
  } else if (hover && surface !== 'dark') {
    thumbColor = ColorTokens.interactive.scrollthumb.hover.onlight.value;
  } else if (hover && surface === 'dark') {
    thumbColor = ColorTokens.interactive.scrollthumb.hover.ondark.value;
  } else {
    thumbColor = ColorTokens.interactive.scrollthumb.onlight.value;
  }
  return thumbColor;
};
const _calculateActiveColor = function (surface, iconFillColor) {
  switch (iconFillColor) {
    case 'secondary':
      return surface === 'dark' ? ColorTokens.palette.gray44.value : ColorTokens.palette.gray65.value;
    case 'primary':
    case 'brandHighlight':
    default:
      return ColorTokens.interactive.active[`on${surface}`].value;
  }
};
const _getIconColor = function (iconFillColor, active, disabled, surface) {
  const isBrandColor = iconFillColor === 'primary' || iconFillColor === 'secondary' || iconFillColor === 'brandHighlight';
  if (!active && !disabled && isBrandColor) {
    return iconFillColor === 'brandHighlight' ? ColorTokens.elements.brandhighlight.value : ColorTokens.elements[iconFillColor][`on${surface}`].value;
  } else if (!active && !disabled && !isBrandColor) {
    return iconFillColor;
  } else if (active) {
    return _calculateActiveColor(surface, iconFillColor);
  } else if (disabled) {
    return ColorTokens.interactive.disabled[`on${surface}`].value;
  }
};
const propTypes = {
  /**
   * Option to render icon element in brand colors
   * @note Providing a custom HEX value is also possible. brandHightlight is recommended to use on light surface only
   */
  iconFillColor: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'brandHighlight']), PropTypes.string]),
  /**
   * Render the component in surface background dark or light
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * @deprecated Use closeButtonText
   */
  buttonText: PropTypes.string,
  /**
   * If provided, will render the text for Close button for tooltip dialog when on mobile devices
   */
  closeButtonText: PropTypes.string
};
const defaultProps = {
  iconFillColor: 'primary',
  surface: 'light',
  closeButtonText: 'Close'
};
const Tooltip = function (props) {
  return React.createElement(Tooltip$1, Object.assign({}, props, {
    closeButtonText: props.buttonText !== undefined ? props.buttonText : props.closeButtonText,
    renderTooltipDialog: true,
    calculateTrackColor: _calculateTrackColor,
    calculateThumbColor: _calculateThumbColor,
    scrollbarBorderRadius: "2px",
    getIconColor: _getIconColor
  }));
};
Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export { Tooltip as default };
