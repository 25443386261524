import styled from "styled-components";
import { SecondaryNavProps } from "./SecondaryNav";

export const StyledSecondaryNavLink = styled("div")`
  //width: fit-content;
  //height: fit-content;
  //
  //border: none;
  //border-radius: unset;
  //background-color: transparent;
  //width: fit-content;
  //
  //text-transform: none;
  //min-width: unset;
  
  a {
    text-decoration: none;
    border: none;
    margin: 0 0.5rem 0;
    
    &:first-child {
      margin: 0 0.5rem 0 0;
    }

    div {
      padding: 0 0.5rem;
      p {
        border-bottom: none;
        padding: 0.5rem 0;
        margin-bottom: 4px;
        height: fit-content;
        white-space: nowrap;
      }
    }
  }
  a:hover div p,
  a:active div p,
  a:hover:not(:active) div p,
  a:hover:not(:disabled) div p {
    border-bottom: none;
    border-bottom-width: 0;
    margin-bottom: 4px;
  }
  
  &.active {
    a {
      div p {
        border-bottom: 4px solid #ee0000;
        margin-bottom: 0;
      }
    }
    a:hover div p,
    a:active div p,
    a:hover:not(:active) div p,
    a:hover:not(:disabled) div p {
      border-bottom: 4px solid #ee0000;
      margin-bottom: 0;
    }
  }

  a:hover,
  a:hover:not(:active),
  a:active,
  a:hover:not(.disabled) {
    background: none;
    box-shadow: none;
  }

  &.disabled {
    pointer-events: none;
    p {
      color: grey;
    }
  }
`;

export const StyledSecondaryNav = styled("div")`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
`;
