import {
  FileCourierDownloadFileProps,
  FileCourierDownloadPackageProps,
} from "interfaces";
import { API_URL } from "utils/environmentVariables";

export const getOrderDirection = (sortBy: string): string => {
  return sortBy.includes("(newest - oldest)") ? "desc" : "asc";
};

export async function downloadFile({
  fileId,
}: FileCourierDownloadFileProps): Promise<void> {
  const link = document.createElement("a");
  link.href = `${API_URL}/file-courier/api/download/getfile/${fileId}`;
  link.download = "download";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function downloadPackage({
  packageId,
}: FileCourierDownloadPackageProps): Promise<void> {
  const link = document.createElement("a");
  link.href = `${API_URL}/file-courier/api/download/getzip/${packageId}`;
  link.download = "download";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
