import styled from "styled-components";
import { zIndex, minTablet } from "theme/variables";
import FlexBox from "../layouts/FlexBox/FlexBox";

export const StyledVideoPlayer = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;

  .play-button-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${zIndex.notBottom};
    background: rgba(0, 0, 0, 0.5);
    .play-button {
      border: none;
      background: none;
      &:hover {
        cursor: pointer;
        scale: 1.05;
      }
      transition: scale 200ms;
    }
  }
`;

export const StyledDetailsContainer = styled(FlexBox)`
  flex-direction: column;

  @media screen and (max-width: ${minTablet - 1}px) {
    width: 100%;
  }

  @media screen and (min-width: ${minTablet}px) {
    width: 80%;
    max-width: 90ch;
  }
`;
