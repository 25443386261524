import React from 'react';
import PropTypes from 'prop-types';
import { ColorTokens } from '@vds-tokens/color';
import { Toggle as Toggle$1 } from '@vds-core/toggles';

const propTypes = {
  /**
   * The surface behind the toggle and determines the background color, text color, and button color of the toggle.
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * If provided, displays text either to the right or left of the toggle.
   */
  showText: PropTypes.bool,
  /**
   * Returns the status of the toggle, typically "on" or "off". The parameter contains the status and the component expects a string in return.
   */
  statusText: PropTypes.func,
  /**
   * Positions status text to either the right or left of toggle
   */
  textPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * changes the font weight of the status text. Takes 'regular' or 'bold'
   */
  textWeight: PropTypes.oneOf(['regular', 'bold']),
  /**
   * changes the font size of the status text. Takes 'small' or 'large'
   */
  textSize: PropTypes.oneOf(['small', 'large'])
};
const Toggle = function (props) {
  const {
    textSize,
    textWeight,
    showText,
    textPosition,
    statusText
  } = props;
  function _calculateBackgroundColor(disabled, on, surface) {
    if (disabled && surface === 'dark') {
      return ColorTokens.interactive.disabled.ondark.value;
    } else if (disabled && surface === 'light') {
      return ColorTokens.interactive.disabled.onlight.value;
    } else if (!disabled && surface === 'dark') {
      return on ? ColorTokens.palette.green36.value : ColorTokens.palette.gray44.value;
    } else {
      return on ? ColorTokens.palette.green26.value : ColorTokens.palette.gray44.value;
    }
  }
  function _calculateTextColor(disabled, surface) {
    if (disabled && surface === 'dark') {
      return ColorTokens.elements.secondary.ondark.value;
    } else if (disabled && surface === 'light') {
      return ColorTokens.elements.secondary.onlight.value;
    } else if (!disabled && surface === 'dark') {
      return ColorTokens.elements.primary.ondark.value;
    } else {
      return ColorTokens.elements.primary.onlight.value;
    }
  }
  function _calculateButtonColor(disabled, surface) {
    if (disabled && surface === 'light') {
      return ColorTokens.palette.gray95.value;
    } else if (disabled && surface === 'dark') {
      return ColorTokens.palette.gray44.value;
    } else {
      return ColorTokens.elements.primary.ondark.value;
    }
  }
  return React.createElement(Toggle$1, Object.assign({}, props, {
    textSize: textSize,
    textWeight: textWeight,
    statusText: statusText,
    showText: showText,
    textPosition: textPosition,
    calculateTextColor: _calculateTextColor,
    calculateButtonColor: _calculateButtonColor,
    calculateBackgroundColor: _calculateBackgroundColor
  }));
};
Toggle.propTypes = propTypes;

export { Toggle as default };
