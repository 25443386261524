import { DeleteFileCourierPackageModalProps } from "interfaces";
import {
  TextButton,
  Button,
  FlexBox,
  Modal,
  Title,
} from "components";
import {
  useLazyDeleteFileCourierPackageQuery,
  setNotification,
} from "services";
import {useDispatch} from "react-redux";

const DeleteFileCourierPackageModal = ({
  packageId,
  onClose,
  onRefresh,
}: DeleteFileCourierPackageModalProps) => {
  const [deletePackage, { isLoading: packageDeleting }] = useLazyDeleteFileCourierPackageQuery();
  
  const dispatch = useDispatch();

  const handleDeletePackage = () => {
    deletePackage({ packageId })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: `Successfully deleted File Courier package.`,
          })
        );
        onRefresh();
        handleClose()
      })
      .catch(() => {
        dispatch(
          setNotification({
            type: "error",
            message: `Could not delete File Courier package.`,
          })
        );
      });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal testId="delete-fcpackage-modal" open={true} onClose={handleClose}>
      <FlexBox>
        <Title size="large" bold pd="0 0 2rem">
          Are you sure you want to delete this package?
        </Title>
        <FlexBox row gap="1.5rem" align="center" justify="flex-start">
          <Button
            use="primary"
            onClick={handleDeletePackage}
            disabled={false}
            data-testid="delete-button"
          >
            Delete package
          </Button>
          <TextButton data-testid="cancel-button" onClick={handleClose}>Cancel</TextButton>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default DeleteFileCourierPackageModal;