import { Grid } from "@mui/material";
import { GridProps } from "interfaces";

const TwoUp = ({ spacing, pd, children, className="", force, forceAlt }: GridProps) => {
  return (
    <Grid container spacing={spacing ? spacing : 2} p={pd} className={className}>
      {children.length ? (
        children.map((child: any, i: number) => {
          return (
            force ? (
                <Grid key={i} item xs={12} lg={6} width="100%">
                  {child}
                </Grid>
              ) : forceAlt ? (
                <Grid key={i} item xs={12} md={6} width="100%">
                  {child}
                </Grid>
              ) :
            <Grid key={i} item xs={12} sm={6} width="100%">
              {child}
            </Grid>
          );
        })
      ) : (
        force ? (
            <Grid item xs={12} md={6} width="100%" p={pd}>
              {children}
            </Grid>
          ) :
        <Grid item xs={12} sm={6} width="100%" p={pd}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default TwoUp;
