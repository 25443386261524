import { RadioButton as VdsRadio } from "@vds/radio-buttons";
import { RadioProps } from "interfaces";
import { RadioWrapper } from "./Radio.module";

const Radio = ({
  label,
  name,
  value,
  selected = false,
  onChange,
  disabled,
  error,
  children,
  ...rest
}: RadioProps) => {
  return (
    <RadioWrapper {...rest}>
      <VdsRadio
        name={name}
        label={label ? label : name.charAt(0).toUpperCase() + name.slice(1)}
        selected={selected}
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
      >
        {children}
      </VdsRadio>
    </RadioWrapper>
  );
};

export default Radio;
