import { StyledCaretTextContainer } from "./CaretText.module";
// import { Body } from "components";
import Body from "components/typography/Body/Body";

export interface CaretTextProps {
  align?: "flex-start" | "center" | "flex-end";
  disabled?: boolean;
  children: any;
  caretPosition?: "left" | "right" | "";
  fontSize?: string;
  pd?: string;
  color?: string;
  hover?: boolean;
  size?: "medium" | "small" | "large";
}
const CaretText = ({
  disabled,
  caretPosition = "",
  color,
  children,
  hover,
  size = "medium",
  ...rest
}: CaretTextProps) => {
  
  return (
    <StyledCaretTextContainer
      className={`${caretPosition} ${disabled ? "disabled" : ""} ${
        hover ? "hover" : ""
      }`}
      color={color}
      {...rest}
    >
      <Body size={size} bold>
        {children}
      </Body>
      <div className={`caret ${caretPosition}`}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 21.6 21.6"
        >
          <path
            fill={`${disabled ? "gray" : color ? color : "black"}`}
            d="M7.6,20.7L5,18.1l7.3-7.3L5,3.5l2.5-2.5l9.9,9.9L7.6,20.7z"
          ></path>
        </svg>
      </div>
    </StyledCaretTextContainer>
  );
};

export default CaretText;
