import { useState } from "react";
import { StyledImage } from "../Image/Image.module";
import { ImageProps } from "../Image/Image";

const ImageRetry = ({ src, ...rest }: ImageProps) => {
  const [retries, _setRetries] = useState<number>(5),
    handleError = (e: any) => {
      if (retries > 0) {
        setTimeout(() => {
          (e.target as HTMLImageElement).src = src;
          _setRetries(retries - 1);
        }, 1000);
      } else {
        _setRetries(5);
      }
    };
  return <StyledImage src={src} {...rest} onError={handleError} />;
};

export default ImageRetry;
