import { object, string } from "yup";

const validationSchema = object().shape({
  code: string()
    .test("len", "The code must be 6 characters long", (val) =>
      val ? val?.toString().length === 6 : false
    )
    .required("You must enter a Code"),
});

export default () => validationSchema;
