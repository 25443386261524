import styled from 'styled-components';
import 'react';
import PropTypes from 'prop-types';
import { calculateRem } from '@vds-core/utilities';
import { BodyConfig, TypographyConfig } from '@vds-core/typography';

({
  /**
   * @ignore config for passing though typography
   */
  bodyConfig: PropTypes.object,
  /**
   * @ignore
   */
  margin: PropTypes.string,
  /**
   * @ignore
   */
  typescale: PropTypes.string
});
({
  bodyConfig: BodyConfig,
  margin: calculateRem(4),
  typescale: TypographyConfig.getTypescale()
});
styled.div.withConfig({
  displayName: "InputTextWrapper",
  componentId: "VDS__sc-1wiyhm4-0"
})`
  display: block;
  margin-top: ${({
  margin,
  typescale
}) => typescale === 'VDS' ? calculateRem(8) : margin};
`;
