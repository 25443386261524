import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton as RadioButton$1 } from '@vds-core/radio-buttons';
import { calculateRem } from '@vds/utilities';
import { ColorTokens } from '@vds-tokens/color';
import { TypographyTokens } from '@vds-tokens/typography';

const propTypes = {
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const _calculateLetterSpacing = function (fontweight, viewport) {
  return calculateRem(TypographyTokens.letterspacing.wide.value);
};
const _calculateErrorBackgroundColor = function (surface) {
  return surface === 'dark' ? ColorTokens.feedback.error.background.ondark.value : ColorTokens.feedback.error.background.onlight.value;
};
const _calculateErrorForegroundColor = function (surface) {
  return surface === 'dark' ? ColorTokens.feedback.error.ondark.value : ColorTokens.feedback.error.onlight.value;
};
const _calculateHoverBorderColor = function (_calculateErrorForegroundColor, selected, error, surface) {
  if (error) {
    return _calculateErrorForegroundColor(surface);
  }
  if (selected) {
    return surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value;
  }
  return surface === 'dark' ? ColorTokens.elements.secondary.ondark.value : ColorTokens.elements.secondary.onlight.value;
};
const RadioButton = function (props) {
  return React.createElement(RadioButton$1, Object.assign({}, props, {
    calculateLetterSpacing: _calculateLetterSpacing,
    calculateErrorBackgroundColor: _calculateErrorBackgroundColor,
    calculateErrorForegroundColor: _calculateErrorForegroundColor,
    calculateHoverBorderColor: _calculateHoverBorderColor,
    heavyErrorBorder: false,
    selectedHoverBorderWidth: 0
  }));
};
RadioButton.propTypes = propTypes;
RadioButton.defaultProps = defaultProps;

export { RadioButton as default };
