import styled from "styled-components";
import FlexBox from "components/layouts/FlexBox/FlexBox";

export const StyledSelected = styled(FlexBox)`
  width: 100%;

  background-color: rgb(246, 246, 246);
  border-radius: 0.25rem;

  padding: 0.5rem;
`;

export const StyledSelectText = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
`;
