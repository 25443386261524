import { FlexBox, Image, Title } from "components";
import {
  StyledThumbnailGridWrapper,
  ImageWrapper,
} from "./ModalThumbnailGrid.module";
import {UnifiedSearchResultProps} from "interfaces";
import {Link, useLocation} from "react-router-dom";
import paths from "../../routing/paths";
import {buildFinalTrackingObject, externalRoute} from "../../utils";
import {useTrackingMutation} from "../../services";

export type ModalThumbnailGridProps = {
  items: Array<UnifiedSearchResultProps>;
  isFavorites?: boolean;
};

const ModalThumbnailGrid = ({
  items,
}:
ModalThumbnailGridProps) => {
  
  const getLinkUrl = (item: UnifiedSearchResultProps): string => {
    let itemUrl = item.url;
    if (item.searchType === 'template') {
      itemUrl = `/templates/details/${item.assetId}`;
    } else if (item.searchType === 'retail') {
      itemUrl = `/retail/branded-environments/design-advisories/details/${item.assetId}`;
    } else if (item.searchType === 'asset') {
      itemUrl = `/assets/details/${item.assetId}`;
    } else if (item.searchType === 'training') {
      itemUrl = `${paths.TRAINING_VIDEOS}/${item.playlistSlug}/${item.videoSlug}`;
    }
    return itemUrl;
  };
  
  const [trackAction] = useTrackingMutation();
  
  const location = useLocation();
  
  const trackEvent = (action: string, item: UnifiedSearchResultProps): void => {
    const trackingData={
      expiresOn: item.expiresOn,
      usageRights: item.usageRightsCopy,
      isActive: true,
    };
    const payloadData = {
      version: "1.0.0",
      context: trackingData,
      type: "asset-card",
      action,
      entityType: "asset",
      id: item.id,
    };
    const trackingObject = buildFinalTrackingObject(
      payloadData,
      location.pathname,
    );
    try {
      trackAction(trackingObject);
    } catch (error: any) {
      console.error(error);
    }
  };
  
  const onAssetLinkClick = (item: UnifiedSearchResultProps): void => {
    trackEvent("view", item);
  };
  
  const maxLengthString = (value: string): string => {
    if (!value) {
      return value;
    }
    if (value.length <= 54) {
      return value;
    }
    return `${value.slice(0, 54)}...`;
  };

  return (
    <StyledThumbnailGridWrapper>
      <ul>
        {items &&
          items.map((item) => {
            return (
                <li key={item.id} className="list-asset">
                  <ImageWrapper searchType={item.searchType}>
                    {item.files && item.files.length > 0 && (
                    <Image
                      src={
                        item.files[0]?.thumbnails?.medium
                          ? item.files[0].thumbnails.medium
                          : ""
                      }
                    />
                    )}
                    {item.thumbnailAssetUrl && (
                      <Image
                        alt={`Image for: ${item.title}`}
                        src={item.thumbnailAssetUrl}
                        testId="training-video-thumb"
                      />
                    )}
                    {(item.searchType !== "guideline") && (
                      <Link
                        to={getLinkUrl(item)}
                        onClick={() => onAssetLinkClick(item)}
                        className="media__link"
                        title={`Open new tab to view image: ${item.title}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid="asset-details-link"
                      >
                        {item.searchType !== "compliance" && (
                          <span>{item.title}</span>
                        )}
                        {item.searchType === "compliance" && (
                          <Title bold size="small" className="compliance__title">{item.title}</Title>
                        )}
                      </Link>
                    )}
                    {(item.searchType === "guideline") && (
                      <a href={externalRoute("url" in item ? item.url : "")}
                        rel={"external noopener noreferrer"}
                        target="_blank"
                        data-testid="asset-details-link"
                        className="media__link"
                      >
                        <Title bold size="small" className="guideline__title">{item.title}</Title>
                      </a>
                    )}
                  </ImageWrapper>
                  <FlexBox>
                    <FlexBox
                      pd="0.25rem 0 0"
                      row
                      align="center"
                      justify="flex-start"
                      className="asset-name-container"
                    >
                      <Title bold size="small">
                        {item.searchType !== "guideline" && (
                          <Link
                            to={getLinkUrl(item)}
                            onClick={() => onAssetLinkClick(item)}
                            className="link--silent"
                            title={item.title}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-testid="item-title-link"
                          >
                            {maxLengthString(item.title)}
                          </Link>
                        )
                        }
                        {item.searchType === "guideline" && (
                          <a href={externalRoute(item.url)}
                            rel={"external noopener noreferrer"}
                            target="_blank"
                            data-testid="item-title-link"
                          >
                            {maxLengthString(item.title)}
                          </a>
                        )}
                      </Title>
                    </FlexBox>
                  </FlexBox>
                </li>
            );
          })}
      </ul>
    </StyledThumbnailGridWrapper>
  );
};

export default ModalThumbnailGrid;
