export const maxWidth = "1440px",
  halfWidth = "720px",
  // defaultWidthsWithMargins = {
  //   xs: "320px",
  //   sm: "544px",
  //   md: "767px",
  //   lg: "1025px",
  //   xl: "1272px",
  // },
  minDesktop = 1024,
  minTabletLarge = 992,
  minTablet = 768,
  minMobileLarge = 480,
  desktop = `only screen and (min-width: ${minDesktop}px)`,
  tabletLarge = `only screen and (min-width: ${minTabletLarge}px) and (max-width: ${
    minDesktop - 1
  }px)`,
  tablet = `only screen and (min-width: ${minTablet}px) and (max-width: ${
    minTabletLarge - 1
  }px)`,
  mobileLarge = `only screen and (min-width: ${minMobileLarge}px) and (max-width: ${
    minTablet - 1
  }px)`,
  mobile = `only screen and (max-width: ${minMobileLarge - 1}px)`;

export const zIndex = {
  notBottom: 2,
  backdrop: 4,
  searchBar: 5,
  recaptcha: 1001,
  FAB: 1009,
  tooltip: 1300,
  globalBackdrop: 100000,
  pageLoader: 100001,
  banner: 100002,
  navBar: 100003,
  globalSearch: 100004,
  modal: 100005,
  notification: 100006,
  introVideo: 100007,
};
