import Body from "components/typography/Body/Body";
import styled from "styled-components";

export default interface RadioProps {
  bold?: boolean;
}

export const RadioWrapper = styled("div")<RadioProps>`
  display: flex;
  flex-direction: column;
  label > div > span {
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
    font-weight: normal;
    font-size: 0.75rem;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, Sans-serif;
  }
`;

export const StyledSubtitle = styled.div`
  padding: 0px 0px 0px 12px;
`;
