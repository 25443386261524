import { Grid } from "@mui/material";
import { GridProps } from "interfaces";

const FourUp = ({
  spacing = 2,
  rowSpacing,
  columnSpacing,
  force,
  forceAlt,
  children,
  wrap,
  id,
  pd,
  searchGrid,
}: GridProps) => {
  return (
    <Grid
      container
      rowSpacing={rowSpacing || spacing}
      columnSpacing={columnSpacing || spacing}
      wrap={wrap}
      id={id}
      p={pd}
    >
      {children.length ? (
        children.map((child: any, i: number) => {
          return (
            <Grid
              key={i}
              item
              xs={force ? 6 : 12}
              sm={force ? 3 : forceAlt ? 4 : 6}
              lg={searchGrid ? 4 : 3}
              xl={3}
              width="100%"
            >
              {child}
            </Grid>
          );
        })
      ) : (
        <Grid item xs={force ? 6 : 12} sm={force ? 3 : 6} lg={3} width="100%">
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default FourUp;
