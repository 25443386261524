import { useState } from "react";
import { StyledContainer, StyledButton } from "./SessionContainer.module";
import { TrainingSessionProps } from "../../../interfaces/Training";
import Line from "../../Line/Line";
import { Body, FlexBox, LinkButton, Title } from "../../index";
import MdToHtml from "../../MdToHtml/MdToHtml";
import { Icon } from "@vds/icons";
import paths from "../../../routing/paths";

export type SessionContainerProps = {
  index: number;
  session: TrainingSessionProps;
};

const SessionContainer = ({ session, index }: SessionContainerProps) => {
  const [isHidden, setIsHidden] = useState(true);

  const isHiddenHandler = () => {
    if (setIsHidden) {
      setIsHidden((hide) => !hide);
    }
  };

  const getCustomEyebrow = () => {
    if (session.customEyebrow) {
      return session.customEyebrow;
    }
    if (session.availableAnytime) {
      return "Available anytime";
    }
    if (session.recurring) {
      return "Recurring";
    }
    if (!session.primaryDate || !session.primaryTime) {
      return "Coming soon";
    }
  };

  const today = new Date().toISOString();
  // @ts-ignore
  const primarySessionDate = new Date(session.primaryDate).toISOString();

  // @ts-ignore
  const secondarySessionDate = new Date(session.secondaryDate).toDateString();

  return (
    <>
      {(primarySessionDate || secondarySessionDate) < today ? (
        <FlexBox>
          <Line />
          <FlexBox row justify="space-between" pd="1rem 0 0">
            <Body bold size="large">
              {getCustomEyebrow()}
            </Body>
            <StyledButton data-id={index} onClick={isHiddenHandler}>
              <Icon name={isHidden ? "plus" : "minus"} />
            </StyledButton>
          </FlexBox>
          <Title pd="0 0 0.5rem" size="large" bold>
            {session.title}
          </Title>
          <StyledContainer isHidden={isHidden} data-id={index}>
            <MdToHtml
              linkTextDecoration
              content={session.description}
              pSize="large"
              linkSize="large"
              pMargin="0"
            />
            {session.topics && (
              <FlexBox pd="1.5rem 0 0">
                <Title size="medium" bold pd="0 0 0.125rem 0">
                  Topics
                </Title>
                <MdToHtml
                  linkTextDecoration
                  content={session.topics}
                  pSize="large"
                  linkSize="large"
                  pMargin="0"
                />
              </FlexBox>
            )}
            {session.attendees && (
              <FlexBox pd="1rem 0 0">
                <Title size="medium" bold pd="0 0 0.125rem 0">
                  Who should attend
                </Title>
                <MdToHtml
                  linkTextDecoration
                  content={session.attendees}
                  pSize="large"
                  linkSize="large"
                  pMargin="0"
                />
              </FlexBox>
            )}
            {session.customDateDetails && (
              <FlexBox pd="1rem 0 0">
                <Title size="medium" bold pd="0 0 0.125rem 0">
                  When
                </Title>
                <MdToHtml
                  linkTextDecoration
                  content={session.customDateDetails}
                  pSize="large"
                  linkSize="large"
                  pMargin="0"
                />
              </FlexBox>
            )}
            {session.primaryRegistrationHeading && (
              <FlexBox pd="1rem 0 0">
                <Title size="medium" bold pd="0 0 0.125rem 0">
                  {session.primaryRegistrationHeading}
                </Title>
                <MdToHtml
                  linkTextDecoration
                  content={session.primaryRegistrationDetails}
                  pSize="large"
                  linkSize="large"
                  pMargin="0 0 1.25rem"
                />
                {session.primaryRegistrationLinkText && (
                  <LinkButton
                    surface="light"
                    label={
                      session.primaryRegistrationLinkText
                        ? session.primaryRegistrationLinkText
                        : ""
                    }
                    to={
                      session.primaryRegistrationUrl
                        ? session.primaryRegistrationUrl
                        : paths.SIGNIN
                    }
                  />
                )}
              </FlexBox>
            )}
            {session.secondaryRegistrationHeading && (
              <FlexBox pd="1rem 0 0">
                <Title size="medium" bold pd="0 0 0.125rem 0">
                  {session.secondaryRegistrationHeading}
                </Title>
                <MdToHtml
                  linkTextDecoration
                  content={session.secondaryRegistrationDetails}
                  pSize="large"
                  linkSize="large"
                  pMargin="0 0 1.25rem"
                />
                {session.secondaryRegistrationLinkText && (
                  <LinkButton
                    surface="light"
                    label={
                      session.secondaryRegistrationLinkText
                        ? session.secondaryRegistrationLinkText
                        : ""
                    }
                    to={
                      session.secondaryRegistrationUrl
                        ? session.secondaryRegistrationUrl
                        : paths.SIGNIN
                    }
                  />
                )}
              </FlexBox>
            )}
          </StyledContainer>
        </FlexBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default SessionContainer;
