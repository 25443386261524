import { __rest } from '../_virtual/_tslib.js';
import React from 'react';
import PropTypes from 'prop-types';
import { Table as Table$2 } from '@vds-core/tables';
import { withVDSManager } from '@vds/utilities';

const propTypes = {
  /**
   * Options to render the bottom line of the rows in the Table Body
   */
  bottomLine: PropTypes.oneOf(['primary', 'secondary', 'none']),
  /**
   * Options to render Table in dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * Options to render padding styles
   */
  padding: PropTypes.oneOf(['standard', 'compact']),
  /**
   * If provided, rows inside the Table component will have alternating stripes.
   */
  striped: PropTypes.bool
};
const defaultProps = {
  bottomLine: 'secondary',
  surface: 'light',
  padding: 'standard',
  striped: false
};
const Table = function (props) {
  const {
      striped,
      bottomLine
    } = props,
    rest = __rest(props, ["striped", "bottomLine"]);
  return React.createElement(Table$2, Object.assign({
    striped: striped,
    bottomLine: striped ? 'none' : bottomLine
  }, rest));
};
Table.propTypes = propTypes;
Table.defaultProps = defaultProps;
var Table$1 = withVDSManager(Table);

export { Table$1 as default };
