import { object, string } from "yup";

const validationSchema = object().shape({
  code: string().required("You must enter a Code"),
  password: string().required("You must enter a New Password"),
  passwordConfirm: string()
    .required("You must confirm your New Password")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

export default () => validationSchema;
