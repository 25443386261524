import { __rest } from '../_virtual/_tslib.js';
import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { calculateRem, getOS } from '@vds-core/utilities';
import { Title } from '@vds-core/typography';
import { LayoutTokens } from '@vds-tokens/layout';
import { ColorTokens } from '@vds-tokens/color';

const propTypes = {
  /**
   * Children for the AccordionHeader can be any type.
   */
  children: PropTypes.node.isRequired,
  /**
   * If enabled, title will be rendered in bold
   */
  bold: PropTypes.bool,
  /**
   * @Ignore
   * Pass in a title component. Defaults to Title for 3.0 and Body for 1.0
   */
  typographyComponent: PropTypes.func,
  /**
   * @Ignore
   * Pass in a title component size. Small and large
   */
  typeSize: PropTypes.oneOf(['small', 'large']),
  /**
   * Set the styling of the component to the desktop, tablet, or mobile size based on passed prop. Desktop and tablet are the same size.
   */
  viewport: PropTypes.oneOf(['desktop', 'mobile', 'tablet']),
  /**
   * @ignore
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light',
  bold: false,
  typographyComponent: Title,
  typeSize: 'small'
};
const TitleWrapper = styled.div.withConfig({
  displayName: "TitleWrapper",
  componentId: "VDS__sc-1fnnlnf-0"
})`
  padding-bottom: ${calculateRem(LayoutTokens.space['1X'].value)};
`;
const AccordionTitle = props => {
  const {
      typeSize,
      typographyComponent,
      surface,
      bold,
      children,
      viewport
    } = props,
    rest = __rest(props, ["typeSize", "typographyComponent", "surface", "bold", "children", "viewport"]);
  const AccordionHeaderStyled = typographyComponent;
  return React.createElement(TitleWrapper, null, React.createElement(AccordionHeaderStyled, Object.assign({}, rest, {
    color: surface === 'dark' ? ColorTokens.elements.primary.ondark.value : ColorTokens.elements.primary.onlight.value,
    size: typeSize,
    viewport: viewport,
    bold: bold,
    tabIndex: getOS() !== 'ios' ? -1 : undefined
  }), children));
};
AccordionTitle.propTypes = propTypes;
AccordionTitle.defaultProps = defaultProps;
AccordionTitle.displayName = 'AccordionTitle';

export { AccordionTitle as default };
