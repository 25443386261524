import React from 'react';
import PropTypes from 'prop-types';
import { Line as Line$1 } from '@vds-core/lines';

const propTypes = {
  /**
   * Type of the line that will be displayed.
   */
  type: PropTypes.oneOf(['primary', 'secondary']),
  /**
  * Option to render on dark or light surface
  */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  type: 'primary',
  surface: 'light'
};
const Line = function (props) {
  const {
    type
  } = props;
  return React.createElement(Line$1, Object.assign({}, props, {
    type: type === 'primary' || type === 'secondary' ? type : 'primary'
  }));
};
Line.propTypes = propTypes;
Line.defaultProps = defaultProps;

export { Line as default };
