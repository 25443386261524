export const formatRecordedTrainingSession = (date?: Date) => {
  if (date === null || date === undefined) {
    return;
  }

  let newDate = new Date(date);

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July',
    'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
  ];

  return `${months[newDate.getUTCMonth()]} ${newDate.getUTCDate()}, ${newDate.getFullYear()}`;
}