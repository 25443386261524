import React from 'react';
import PropTypes from 'prop-types';
import { TextLinkCaret as TextLinkCaret$1 } from '@vds-core/buttons';
import { calculateRem } from '@vds/utilities';
import { ColorTokens } from '@vds-tokens/color';
import { TypographyTokens } from '@vds-tokens/typography';

const propTypes = {
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const _calculateActiveColor = function (surface) {
  return surface === 'dark' ? ColorTokens.interactive.active.ondark.value : ColorTokens.interactive.active.onlight.value;
};
const _calculateLetterSpacing = function (viewport) {
  return calculateRem(TypographyTokens.letterspacing.wide.value);
};
const TextLinkCaret = function (props) {
  return React.createElement(TextLinkCaret$1, Object.assign({}, props, {
    calculateActiveColor: _calculateActiveColor,
    calculateLetterSpacing: _calculateLetterSpacing,
    focusRingBorderRadius: "2px"
  }));
};
TextLinkCaret.propTypes = propTypes;
TextLinkCaret.defaultProps = defaultProps;

export { TextLinkCaret as default };
