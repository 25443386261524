import styled from "styled-components";
import FlexBox from "components/layouts/FlexBox/FlexBox";
import { minTablet } from "theme/variables";

export const StyledFlexBox = styled(FlexBox)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;

  > * {
    opacity: 0.8;
  }
`;

export const StyledModalWrapper = styled(FlexBox)`
  max-width: 100%;
  width: 100%;
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .uimodal-controls {
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 0 2rem 0 1rem;
    margin: auto;
    width: 100%;
    height: 82px;
    border: 0;
    border-top: #D8DADA 1px solid;
    @media screen and (min-width: ${minTablet}px) {
      padding-right: 56px;
      padding-left: 40px;
      height: 80px;
      border-top-color: #000000;
    }
  }

  .uimodal-list {
    @media screen and (min-width: ${minTablet}px) {
      min-height: 392px;
    }
  }

  .uimodal-rule {
    display: block;
    position: relative;
    left: calc((100vw - 100%) / 2 * -1);
    padding: 0;
    width: 100vw;
    border: 0;
    border-bottom: 1px solid #D8DADA;
    @media screen and (min-width: ${minTablet}px) {
      display: none;
    }
  }

  .uimodal-scroll {
    padding: 0;
    margin: 0;
    height: calc(100vh - 191px);
    /* @include respond.min(500) {
      height: calc(100vh - 171px);
    }
    @include respond.min(700) {
      height: calc(100vh - 188px);
    }*/
    @media (max-width: ${minTablet - 1}px) {
      height: calc(100vh - 153px);
      padding-bottom: 82px;
    }
    @media screen and (min-width: ${minTablet}px) {
      max-height: 328px;
      height: 100%;
      margin-bottom: 56px;
    }
  }
  
  .scrollbar {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
  }

  .uimodal-item {
    margin-top: 0.5rem;
    & + & {
      margin-top: 1rem;
    }
  }

  .uimodal-item-group {
    &+ .uimodal-item-group {
      margin-top: 2.5rem;
    }
  }

  .uimodal-item-title {
    color: #000000;
    font-size: 1rem;
    margin-bottom: 0;
    display: inline-block;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 1.33;
    span {
      font-weight: 700;
      text-align: left;
    }
    @media screen and (min-width: ${minTablet}px) {
      font-size: 1.25rem;
    }
  }
  
  .uimodal-media-absolute {
    position: absolute;
  }

  .uimodal-item-info p,
  .info p {
    color: #747676;
  }

  .uimodal-media {
    width: 66px;
    height: 66px;
    img {
      border-radius: 4px;
    }
    
  }
  
  .uimodal-info {
    padding-bottom: 1rem;
    @media screen and (min-width: ${minTablet}px) {
      padding-bottom: 0;
    }
  }
  
  .download-link {
    button {
      border-bottom: none;
      margin-bottom: 0;

      &:hover {
        border-bottom: none;
        margin-bottom: 0;
        span {
          font-weight: 700;
        }
      }
    }
    span {
      white-space: normal;
      text-align: left;
    }
  }
  
`;


