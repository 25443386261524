import ASYNC_STATUS from "constants/asyncStatus";
import AUTH_STATE from "constants/authState";
import MFA_CHOICE from "constants/mfaChoice";

export const mockStore = {
  auth: {
    status: ASYNC_STATUS.IDLE,
    authState: AUTH_STATE.NA,
    mfaChoice: MFA_CHOICE.EMAIL,
    user: null,
    notification: { type: undefined, message: "" },
    currentUserDetails: null,
  },
  ui: { notification: { type: undefined, message: "" }, location: undefined },
};
