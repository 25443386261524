import Body from "../../components/typography/Body/Body";
import Title from "../../components/typography/Title/Title";
import CaretLink from "../../components/buttons/CaretLink/CaretLink";
import { Link } from "../../interfaces";
import TextLink from "../../components/buttons/TextLink/TextLink";
import FlexBox from "../../components/layouts/FlexBox/FlexBox";
import Line from "../../components/Line/Line";
import { externalRoute } from "utils";
import MdToHtml from "../../components/MdToHtml/MdToHtml";

interface props {
  label: string;
  title: string;
  titleUrl?: string;
  links?: Link[];
  date?: string;
  body?: string;
  noBottomPadding?: boolean;
}

const HeroAnnouncement = ({ label, title, titleUrl, links, date, body, noBottomPadding }: props) => {
  return (
    <FlexBox data-testid="hero-announcement" pd={noBottomPadding ? "0" : "0 0 2rem 0"}>
      <Line surface="light" />
      <Body pd="0.75rem 0 0.75rem 0" size="small" bold>
        {label}
      </Body>
      {titleUrl ? (
        <TextLink
          to={externalRoute(titleUrl)}
          color="#000000"
          size="XLarge"
          bold
          newTab
          underlineOnHover
          data-testid="title-link"
        >
          {title}
        </TextLink>
      ) : (
        <Title size="small" color="#000000" bold>
          {title}
        </Title>
      )}
      {date && (
        <Body pd="0.75rem 0 0 0" size="large">
          {date}
        </Body>
      )}
      {body && (
        <MdToHtml pMargin="0" linkTextDecoration content={body} pSize="large" color="#000000" pd="0.75rem 0 0 0" linkSize="large" />
      )}
      <FlexBox pd="1.25rem 0 0 0" gap="0.75rem">
        {links &&
          links.length > 0 &&
          links.map((link: Link) => (
            <FlexBox key={link.text}>
              <CaretLink
                to={link.url}
                external={link.external}
                newTab={link.openInNewTab}
                caretPosition="right"
                color="#000000"
                fontSize="1"
                data-testid="hero-announcement-link"
              >
                {link.text}
              </CaretLink>
            </FlexBox>
          ))}
      </FlexBox>
    </FlexBox>
  );
};

export default HeroAnnouncement;
