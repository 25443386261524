import { useDispatch, useSelector } from "hooks/redux";
import { ParsedUserJWT } from "interfaces/Cognito";
import { useEffect } from "react";
import {
  currentUserJwt,
  selectCurrentUserDetails,
} from "services/auth/auth.slice";

const withUserDetails = () => (WrappedComponent: any) => {
  const Component = (props: any) => {
    const dispatch = useDispatch();
    const handleUserDetails = () => {
      dispatch(currentUserJwt());
    };

    const userDetails: ParsedUserJWT | null | undefined =
      useSelector(selectCurrentUserDetails) || null;

    useEffect(() => {
      handleUserDetails();
    }, []);
    return <WrappedComponent userDetails={userDetails} {...props} />;
  };

  return Component;
};

export default withUserDetails;
