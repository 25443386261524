import { ButtonProps } from "interfaces";
import { StyledButton } from "./TextButton.module";
import { ButtonProps as VdsButtonProps } from "@vds/buttons";

const TextButton = ({
  children,
  type = "button",
  use = "secondary",
  className,
  size,
  bold,
  pd,
  color,
  testId,
  ...rest
}: VdsButtonProps & ButtonProps) => {
  return (
    <StyledButton
      bold={bold}
      size={size}
      pd={pd}
      className={className}
      type={type}
      use={use}
      color={color}
      data-testid={testId ? testId : "text-button"}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default TextButton;
