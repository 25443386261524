import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { calculateRem } from '@vds-core/utilities';
import { ColorTokens } from '@vds-tokens/color';
import { LayoutTokens } from '@vds-tokens/layout';
import { Fonts } from '@vds-core/typography';
import { TypographyTokens } from '@vds-tokens/typography';

const propTypes = {
  /**
   * Determines the type of badge indicator.
   */
  kind: PropTypes.oneOf(['simple', 'numbered']),
  /**
   * This prop is to provide the surface that the badge indicator will be placed on. This helps maintain proper color contrast ratio.
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * Option to set any HEX value to badge indicator border color for both on light and dark.
   */
  borderColor: PropTypes.shape({
    onlight: PropTypes.string,
    ondark: PropTypes.string
  }),
  /**
   * If set to true, the badge indicator will not have a border.
   */
  hideBorder: PropTypes.bool,
  /**
   * This prop takes a string or number that will render as the text inside of the badge indicator.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Sets the size of badge indicator.
   */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Sets the size of the dot inside the badge indicator, when the kind is 'simple'.
   */
  dotSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Sets the left and right paddings for badge indicator, when the kind is 'numbered'.
   */
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Allows an id to be passed to the outermost wrapper of the component
   */
  id: PropTypes.string,
  /**
   * Sets the maximum number of digits to be displayed in badge indicator, when the kind is 'numbered'.
   */
  maximumDigits: PropTypes.oneOf(['one', 'two', 'three', 'four', 'five', 'six'])
};
const defaultProps = {
  kind: 'simple',
  surface: 'light',
  size: '16px',
  dotSize: '3px',
  borderColor: {
    onlight: ColorTokens.palette.white.value,
    ondark: ColorTokens.palette.black.value
  },
  hideBorder: false,
  padding: LayoutTokens.space['1X'].value,
  maximumDigits: 'two'
};
const fillColors = {
  red: ColorTokens.background.brandhighlight.value
};
const BadgeIndicatorWrapper = styled.div.withConfig({
  displayName: "BadgeIndicatorWrapper",
  componentId: "VDS__sc-1kbnyqj-0"
})`
  display: flex;
`;
const StyledBadgeIndicator = styled.div.withConfig({
  displayName: "StyledBadgeIndicator",
  componentId: "VDS__sc-1kbnyqj-1"
})`
  display: flex;
  background-color: ${fillColors.red};
  height: ${({
  size
}) => calculateRem(size)};
  min-width: ${({
  size
}) => calculateRem(size)};
  border-radius: ${({
  size
}) => calculateRem(size)};
  justify-content: center;
  align-items: center;
  ${({
  borderColor,
  hideBorder
}) => !hideBorder ? `
      box-shadow: 0 0 0 ${calculateRem(1)} ${borderColor};
      ` : ``}
`;
const StyledDot = styled.div.withConfig({
  displayName: "StyledDot",
  componentId: "VDS__sc-1kbnyqj-2"
})`
  background-color: ${ColorTokens.elements.primary.ondark.value};
  height: ${({
  dotSize
}) => calculateRem(dotSize)};
  width: ${({
  dotSize
}) => calculateRem(dotSize)};
  border-radius: ${({
  dotSize
}) => calculateRem(dotSize)};
`;
const StyledNumber = styled.div.withConfig({
  displayName: "StyledNumber",
  componentId: "VDS__sc-1kbnyqj-3"
})`
  font-family: ${Fonts.VerizonNHGeTX};
  font-size: ${calculateRem(TypographyTokens.fontsize.body[12].value)};
  font-weight: ${TypographyTokens.fontweight.regular.value};
  line-height: ${({
  size
}) => calculateRem(size)};
  color: ${ColorTokens.elements.primary.ondark.value};
  padding: 0 ${({
  padding
}) => calculateRem(padding)};
`;
const BadgeIndicator = props => {
  const {
    kind,
    surface,
    borderColor: borderColorProp,
    hideBorder,
    children,
    size,
    dotSize,
    padding,
    maximumDigits,
    id
  } = props;
  const borderColor = surface === 'dark' ? borderColorProp.ondark : borderColorProp.onlight;
  const calculateNumber = () => {
    const maxNumberMap = {
      one: 9,
      two: 99,
      three: 999,
      four: 9999,
      five: 99999,
      six: 999999
    };
    const maxNumber = maxNumberMap[maximumDigits];
    const parsed = parseInt(children) || 0;
    return parsed <= maxNumber ? parsed : `${maxNumber}+`;
  };
  return React.createElement(BadgeIndicatorWrapper, {
    size: size
  }, React.createElement(StyledBadgeIndicator, {
    kind: kind,
    surface: surface,
    id: id,
    size: size,
    borderColor: borderColor,
    hideBorder: hideBorder
  }, kind === 'numbered' ? React.createElement(StyledNumber, {
    "data-testid": "numberedDiv",
    size: size,
    padding: padding
  }, calculateNumber()) : React.createElement(StyledDot, {
    dotSize: dotSize
  })));
};
BadgeIndicator.propTypes = propTypes;
BadgeIndicator.defaultProps = defaultProps;

export { BadgeIndicator as default };
