'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactSideEffect = require('react-side-effect');

var _reactSideEffect2 = _interopRequireDefault(_reactSideEffect);

var _focusLock = require('focus-lock');

var _focusLock2 = _interopRequireDefault(_focusLock);

var _util = require('./util');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var focusOnBody = function focusOnBody() {
  return document && document.activeElement === document.body;
};

var lastActiveTrap = 0;
var lastActiveFocus = null;

var lastPortaledElement = null;

var recordPortal = function recordPortal(observerNode, portaledElement) {
  lastPortaledElement = [portaledElement, observerNode];
};

var isPortaledPair = function isPortaledPair(element, observed) {
  return lastPortaledElement && lastPortaledElement[0] === element && (0, _focusLock.getAllAffectedNodes)(observed).reduce(function (result, node) {
    return result || node.contains(lastPortaledElement[1]);
  }, false);
};

var activateTrap = function activateTrap() {
  var result = false;
  if (lastActiveTrap) {
    var _lastActiveTrap = lastActiveTrap,
        observed = _lastActiveTrap.observed,
        onActivation = _lastActiveTrap.onActivation,
        persistentFocus = _lastActiveTrap.persistentFocus,
        autoFocus = _lastActiveTrap.autoFocus;


    if (persistentFocus || !focusOnBody() || !lastActiveFocus && autoFocus) {
      if (!isPortaledPair(document && document.activeElement, observed)) {
        if (observed && !(0, _focusLock.focusInside)(observed)) {
          onActivation();
          if (document && !lastActiveFocus && document.activeElement && !autoFocus) {
            document.activeElement.blur();
            document.body.focus();
          } else {
            result = (0, _focusLock2.default)(observed, lastActiveFocus);
          }
        }
        lastActiveFocus = document && document.activeElement;
      }
    }
  }
  return result;
};

var onTrap = function onTrap(event) {
  if (activateTrap() && event) {
    // prevent scroll jump
    event.stopPropagation();
    event.preventDefault();
  }
};

var onBlur = function onBlur() {
  return (0, _util.deferAction)(activateTrap);
};

var onFocus = function onFocus(event) {
  // detect portal
  var source = event.target;
  var currentNode = event.currentTarget;
  if (!currentNode.contains(source)) {
    recordPortal(currentNode, source);
  }
};

var FocusTrap = function FocusTrap(_ref) {
  var children = _ref.children;
  return _react2.default.createElement(
    'div',
    { onBlur: onBlur, onFocus: onFocus },
    children
  );
};

FocusTrap.propTypes = {
  children: _propTypes2.default.node.isRequired
};

var attachHandler = function attachHandler() {
  document.addEventListener('focusin', onTrap, true);
  document.addEventListener('focusout', onBlur);
};

var detachHandler = function detachHandler() {
  document.removeEventListener('focusin', onTrap, true);
  document.removeEventListener('focusout', onBlur);
};

function reducePropsToState(propsList) {
  return propsList.filter(function (_ref2) {
    var disabled = _ref2.disabled;
    return !disabled;
  }).slice(-1)[0];
}

function handleStateChangeOnClient(trap) {
  if (trap && !lastActiveTrap) {
    attachHandler();
  }

  lastActiveTrap = trap;
  if (trap) {
    lastActiveFocus = null;
    activateTrap(true);
    (0, _util.deferAction)(activateTrap);
  } else {
    detachHandler();
    lastActiveFocus = null;
  }
}

exports.default = (0, _reactSideEffect2.default)(reducePropsToState, handleStateChangeOnClient)(FocusTrap);