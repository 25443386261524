import styled from "styled-components";
import FlexBox from "components/layouts/FlexBox/FlexBox";
import { IconButton } from "components";
import { minTablet } from "theme/variables";

export const StyledWrapper = styled.div`
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;

  width: 100%;

  height: auto;
  max-width: 70vw;
  max-height: 98vh;
  padding: 4rem;

  border-radius: 0.5rem;

  @media screen and (max-width: ${minTablet - 1}px) {
    max-width: 100vw;
    border-radius: unset;
    padding: 1.5rem;
    max-height: 100vh;
  }
`;

export const StyledFlexBox = styled(FlexBox)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
`;

export const StyledClose = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
`;

export const StyledHorizontalRule = styled.hr`
  border-top: #000000 .063rem solid;
  min-width: 100%;
`;

export const StyledTable = styled(FlexBox)`
  :nth-child(even) {
    background-color: #f6f6f6;
  }
  
  div:nth-child(2) {
    p {
      overflow-wrap: anywhere;
    }
  }
  
  div:nth-child(3) {
    p {
      text-align: right;
    }
  }
`;





