import styled from "styled-components";
import { ComplianceOverlayBoxProps } from "./ComplianceOverlayBox";
import { minTablet } from "theme/variables";
import { FlexBox } from "components";

export const StyledComplianceOverlayBox = styled("div")<ComplianceOverlayBoxProps>`
  // display: block;
  //
  // max-height: ${(props) => props.maxHeight || ""};
  //
  // margin: ${(props) => props.mg || ""};
  // padding: ${(props) => props.pd || "2.5rem 0 5rem 0"};
  //
  // color: ${(props) => props.color || ""};
  // background: ${(props) => props.background || ""};

  :host {
    display: block;
    border-radius: 0.5rem;

    @media screen and (max-width: ${minTablet - 1}px) {
      padding: 2.5rem 0;
      background-color: #000000;
    }
  }

  .ComplianceOverlayBox-overlay-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    border-radius: 0.5rem;

    @media screen and (max-width: ${minTablet - 1}px) {
      flex-direction: column-reverse;
      justify-content: flex-start;
      background-color: #000000;
      padding: 1rem;
      border-radius: 0;
    }
  }

  .ComplianceOverlayBox-overlay-content {
    width: 45%;
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 3.5rem 4rem;
    background-color: #000000;
    color: #ffffff;
    border-radius: 0.5rem;
    > div {
      padding: 0;
    }

    @media (max-width: 1024px) {
      width: 40%;
      padding: 2.5rem 3rem;
    }

    @media screen and (max-width: ${minTablet - 1}px) {
      width: 100%;
      position: static;
      padding: 0;
      transform: none;
    }

    h2 {
      margin: 0 0 1.25rem;
      font-size: 3rem;
      color: #ffffff;

      @media (max-width: 1024px) {
        margin-bottom: 1rem;
        font-size: 2rem;
      }
    }

    > div > div:nth-of-type(2) > p {
      margin: 0 0 1.25rem;
      letter-spacing: 0.5px;
    }
  }

  .ComplianceOverlayBox-overlay-background {
    width: 80%;
    height: 40rem;
    background-image: ${(props) =>
  props.background ? `url(\"${props.background}\")` : ""};
    background-size: 150%;

    @media (max-width: 1280px) {
      background-size: 175%;
    }

    @media (max-width: 1024px) {
      background-size: 200%;
    }

    @media screen and (max-width: ${minTablet - 1}px) {
      width: 100%;
      min-height: 30vh;
      margin-bottom: 1.5rem;
      background-size: cover;
      border-radius: 0.5rem;
    }
    
    &.content {
      display: flex;
      flex-direction: column;
      align-items: end;
      padding: 6rem 10% 6rem 15%;
      justify-content: center;
      @media screen and (max-width: ${minTablet - 1}px) {
        align-items: flex-start;
        padding: 2.5rem 1rem;
      }
    }
  }
`;

export const StyledStepsWrapper = styled(FlexBox)`
  gap: 4rem;
  // @media screen and (min-width: ${minTablet}px) {
  //
  // }
  @media screen and (max-width: ${minTablet - 1}px) {
    gap: 1.5rem;
  }
  h3 {
    padding: 0 0 1.25rem;
    @media screen and (max-width: ${minTablet - 1}px) {
      padding: 0 0 0.5rem;
    }
  }
`;
