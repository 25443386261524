import { Notification as VdsNotification } from "@vds/notifications";
import { RootStateProps } from "interfaces";
import { useEffect } from "react";
import { useSelector, useDispatch } from "hooks/redux";
import { StyledWrapper } from "./Notification.module";
import { selectNotification } from "services";
import { NotificationProps, resetNotification } from "services/ui/ui.slice";
export interface NotificationModalProps {
  selector?: (state: RootStateProps) => NotificationProps;
  reset?: Function;
  inline?: boolean;
}

const Notification = ({
  selector = selectNotification,
  reset = resetNotification,
  inline = false,
}: NotificationModalProps) => {
  const notification = useSelector(selector) as NotificationProps;
  const dispatch = useDispatch(),
    onCloseButtonClick = () => {
      dispatch(reset());
    },
    clickAway = notification.type === "error" && inline,
    success = notification.type === "success";

  useEffect(() => {
    let timeout: any = null;
    if (notification.type) {
      document.addEventListener("click", onCloseButtonClick);
      timeout = setTimeout(() => onCloseButtonClick(), 5000);

      // if (inline) {
      //   document.addEventListener("click", onCloseButtonClick);
      // } else {
      //   timeout = setTimeout(() => onCloseButtonClick(), 5000);
      // }
      // success && setTimeout(() => onCloseButtonClick(), 5000);
      // clickAway && document.addEventListener("click", onCloseButtonClick);
    }

    return () => {
      document.removeEventListener("click", onCloseButtonClick);
      clearTimeout(timeout);
    };
  }, [notification.type]);

  return (
    <StyledWrapper
      open={!!notification.type}
      className={!inline ? "absolute" : ""}
      data-testid={"notification-" + notification.type}
    >
      <VdsNotification
        opened={!!notification.type}
        type={notification.type}
        // onCloseButtonClick={onCloseButtonClick}
        hideCloseButton={true}
        title={notification.message}
      />
    </StyledWrapper>
  );
};

export default Notification;
