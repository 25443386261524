import FlexBox from "../layouts/FlexBox/FlexBox";
import { VideoPlaylistProps } from "../../interfaces/Training";
import Body from "../typography/Body/Body";
import Image from "../layouts/Image/Image";
import { StyledContainer } from "./VideoPlaylist.module";
import { useEffect } from "react";

const scrollToView = (dataIndex: number) => {
  const list = document.getElementsByClassName("playlist")[0],
    slide = list.querySelector(
      '[data-id = "' + dataIndex + '"]'
    ) as HTMLElement;

  list.scrollTo({ behavior: "smooth", top: slide?.offsetTop });
};

const VideoPlaylist = ({
  index,
  playlist,
  setDataIndex,
  dataIndex,
}: VideoPlaylistProps) => {
  function onClickHandler(event: any): void {
    if (setDataIndex) {
      setDataIndex(
        parseInt(
          (event.currentTarget as HTMLElement)?.getAttribute("data-id") || "0"
        )
      );
    }
  }

  useEffect(() => {
    scrollToView(dataIndex);
  }, [dataIndex]);

  return (
    <StyledContainer data-id={index} onClick={onClickHandler}>
      <FlexBox row flex="3">
        <FlexBox minWidth="1rem" mg="0 1.75rem 0 0">
          <Body bold size="large">
            {index + 1}
          </Body>
        </FlexBox>
        <FlexBox
          mg="0 2.5rem 0 0"
          className={dataIndex === index ? "selected" : ""}
        >
          <Image
            border
            width="9rem"
            height="5rem"
            src={playlist.thumbnailAssetUrl}
          />
        </FlexBox>
        <FlexBox maxWidth="9rem">
          <Body bold size="large">
            {playlist.title}
          </Body>
          <Body color="#747676">{playlist.duration}</Body>
        </FlexBox>
      </FlexBox>
    </StyledContainer>
  );
};

export default VideoPlaylist;
