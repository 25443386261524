import { Title, Body } from "../../index";
import { Icon } from "@vds/icons";
import { StyledDiv, StyledFlexbox } from "./Success.module";

const Success = () => {
  return (
    <StyledFlexbox gap="1.5rem">
      <StyledDiv>
        <Icon name="checkmark" color="#00AC3E" />
      </StyledDiv>
      <Title size="XLarge" bold>Thanks for contacting the Retail Branding Center team.</Title>
      <Body size="medium">Our team will reply within 72 hours to your message.</Body>
    </StyledFlexbox>
  );
};

export default Success;