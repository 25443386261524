import { useState } from "react";
import { AssetFile, FullscreenImgCarouselModalProps } from "interfaces";
import { Modal, Image, BasicCarousel } from "components";
import { ImageWrapper } from "./FullscreenImgCarouselModal.module";

const FullscreenImgCarouselModal = ({
  assetId,
  onClose,
  activeThumbnail,
  files,
  trainingVideoThumb,
}: FullscreenImgCarouselModalProps) => {
  const [selectedImage, setSelectedImage] = useState<number>(activeThumbnail),
    updateThumbnail = (index: number) => {
      setSelectedImage(index);
    };

  const handleClose = () => {
    onClose(selectedImage);
  };

  return (
    <Modal testId="fullscreen-carousel-modal" open onClose={handleClose}>
      <BasicCarousel
        carouselId={`${assetId}-fullscreen`}
        navButtonTestId="fullscreen-carousel-nav-button"
        noScroll
        onNavClick={(index: number) => {
          updateThumbnail(index);
        }}
        fullScreen
        assetCardThumbnails
        className="thumbnails-carousel fullscreen-carousel focused"
        navSize="3rem"
        noControls={files && files?.length < 2}
        scrollToSlide={activeThumbnail}
        slides={files.length > 0 ? files.map(
          (file: AssetFile) =>
            file && (
              <ImageWrapper
                className="image-container"
                key={file.id}
                align="center"
                justify="center"
                data-testid="fullscreen-carousel-slide"
              >
                {file.thumbnails &&
                  (file.thumbnails.xLarge ||
                    file.thumbnails.large ||
                    file.thumbnails.medium ||
                    file.thumbnails.small) && (
                    <Image
                      width="auto"
                      maxWidth="100%"
                      maxHeight="500px"
                      height="auto"
                      src={
                        file.thumbnails.xLarge
                          ? file.thumbnails.xLarge
                          : file.thumbnails.large
                          ? file.thumbnails.large
                          : file.thumbnails.medium
                          ? file.thumbnails.medium
                          : file.thumbnails.small
                      }
                    />
                  )}
              </ImageWrapper>
            )
        ) : (
          trainingVideoThumb!.map(
            (url: string) =>
                <ImageWrapper
                  className="image-container"
                  key={assetId}
                  align="center"
                  justify="center"
                  data-testid="training-video-fullscreen-carousel-slide"
                >
                  <Image
                    width="auto"
                    maxWidth="100%"
                    maxHeight="500px"
                    height="auto"
                    src={url}
                  />
                </ImageWrapper>
          )
        )}
        slidesPerView={1}
      />
    </Modal>
  );
};

export default FullscreenImgCarouselModal;
