import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup as ButtonGroup$1 } from '@vds-core/buttons';
import Button from './Button.js';

const propTypes = {
  /**
   * If provided, Button components will be rendered based on this data.
   * @typeName buttonData
   */
  data: PropTypes.arrayOf(PropTypes.shape({
    size: PropTypes.oneOf(['small', 'large']),
    width: PropTypes.string,
    use: PropTypes.oneOf(['primary', 'secondary', 'textLink', 'textLinkCaret']),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    surface: PropTypes.oneOf(['light', 'dark']),
    href: PropTypes.string,
    ariaLabel: PropTypes.string
  })),
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * An object containing number of Button components per row, in each viewport
   */
  rowQuantity: PropTypes.shape({
    mobile: PropTypes.number,
    mobileLarge: PropTypes.number,
    tablet: PropTypes.number,
    tabletLarge: PropTypes.number,
    desktop: PropTypes.number
  }),
  /**
   * Viewport the Buttons will be rendered in
   */
  viewport: PropTypes.oneOf(['desktop', 'tabletLarge', 'tablet', 'mobileLarge', 'mobile'])
};
const defaultProps = {
  surface: 'light'
};
const ButtonGroup = function (props) {
  function _onClick(e, onClick) {
    if (e.detail !== 0) {
      onClick && onClick(e);
      e.target.blur();
    } else if (e.type === 'click') {
      onClick && onClick(e);
    }
  }
  function _renderButton(props) {
    const {
      use,
      children,
      size,
      width,
      onClick,
      ariaLabel
    } = props;
    if (use === 'primary') {
      return React.createElement(Button, Object.assign({}, props, {
        style: {
          maxWidth: '100%'
        },
        ariaLabel: ariaLabel,
        children: children,
        size: size,
        width: width,
        onClick: function (e) {
          return _onClick(e, onClick);
        }
      }));
    } else if (use === 'secondary') {
      return React.createElement(Button, Object.assign({}, props, {
        use: "secondary",
        style: {
          maxWidth: '100%'
        },
        ariaLabel: ariaLabel,
        children: children,
        size: size,
        width: width,
        onClick: function (e) {
          return _onClick(e, onClick);
        }
      }));
    }
  }
  return React.createElement(ButtonGroup$1, Object.assign({}, props, {
    renderButton: _renderButton
  }));
};
ButtonGroup.propTypes = propTypes;
ButtonGroup.defaultProps = defaultProps;

export { ButtonGroup as default };
