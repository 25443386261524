import { StyledHero, HeroContent } from "./Hero.module";

type HeroProps = {
  left: any;
  right?: any;
};

const Hero = ({ left, right }: HeroProps) => {
  return (
    <StyledHero>
      <HeroContent>
        {left}
        {right}
      </HeroContent>
    </StyledHero>
  );
};

export default Hero;
