import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { setupStore } from "services/store";
import { Router } from "routing/Router";
import GlobalStyles from "theme/globalStyles";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "@aws-amplify/core";
import awsAmplify from "amplifyConfiguration";
import muiTheme from "./theme/muiTheme";
import { ThemeProvider } from "@mui/material";

const container = document.getElementById("bc-root")!;
const root = createRoot(container);

Amplify.configure(awsAmplify);

root.render(
  // <React.StrictMode>
  <Provider store={setupStore()}>
    <ThemeProvider theme={muiTheme}>
      <GlobalStyles />
      <Router />
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
