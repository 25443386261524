import {StyledAvatarGroup, StyledBar, StyledLinkContainer, StyledSelectedTitleAndClearContainer} from "./MultipleSelectedBar.module";
import Body from "../../components/typography/Body/Body";
import Title from "../../components/typography/Title/Title";
import {Image} from "../../components";
import {AssetFile, UnifiedSearchResultProps} from "../../interfaces";
import {getThumbnails} from "../../utils";
import {StyledAvatar} from "../search/Search.module";
import {AvatarGroup} from "@mui/material";
import LinkButton from "../../components/buttons/LinkButton/LinkButton";
import TextButton from "../../components/buttons/TextButton/TextButton";
import {MultipleSelectedModal} from "../index";
import {useState} from "react";
import Button from "../../components/buttons/Button/Button";

export interface MultipleSelectedBarProps {
  selectedItems: Array<UnifiedSearchResultProps>;
  clearSelected: Function;
  removeAsset: Function;
  updateUsageRights: Function;
  clearBySearchType: Function;
}

const MultipleSelectedBar = ({
  selectedItems,
  clearSelected,
  removeAsset,
  updateUsageRights,
  clearBySearchType,
}: MultipleSelectedBarProps) => {
  
  const [multipleModalOpen, _multipleModalOpen] = useState(false);
  const closeMultipleModal = () => {
    _multipleModalOpen(false);
  };
  const handleClearSelected = () => {
    clearSelected();
  };
  
  const handleRemoveAsset = (id: string) => {
    removeAsset(id);
  };
  const handleClearBySearchType = (searchType: string) => {
    clearBySearchType(searchType);
  }
  const handleUpdateUsageRights = (id: string) => {
    updateUsageRights(id);
  };
  
  return (
    <>
      <StyledBar align="center" justify="space-between" data-testid="multiple-selected-bar">
        <StyledSelectedTitleAndClearContainer>
          <Title alignText="left" size="medium">Selected</Title>
          <TextButton testId="clear-all-selected-results" onClick={handleClearSelected}>Remove all selections</TextButton>
        </StyledSelectedTitleAndClearContainer>
        
        <StyledLinkContainer align="center" row gap="1rem">
          <StyledAvatarGroup variant="rounded" max={3}>
            {selectedItems.map((item) => {
              return (
                <StyledAvatar
                  key={item.id}
                  alt={item.altText}
                  src={(item.files && item.files.length > 0) ? getThumbnails(item.files, "medium")[0] : item.thumbnailAssetUrl ? item.thumbnailAssetUrl : ""}
                >
                  {item.altText?.charAt(0) || item.title.charAt(0)}
                </StyledAvatar>
              );
            })}
          </StyledAvatarGroup>
          <Button size="small" testId="open-multiple-modal" use="secondary" onClick={() => _multipleModalOpen(true)}>View Actions</Button>
        </StyledLinkContainer>
      </StyledBar>
      {multipleModalOpen && (
        <MultipleSelectedModal
          clearBySearchType={(searchType: string) => handleClearBySearchType(searchType)}
          selectedItems={selectedItems}
          onClose={closeMultipleModal}
          clearSelected={handleClearSelected}
          removeAsset={handleRemoveAsset}
          updateUsageRights={handleUpdateUsageRights}
        />
      )}
    </>
  );
  
};

export default MultipleSelectedBar;