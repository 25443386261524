import {
  Body,
  Button,
  FlexBox,
  Section,
  TextLink,
  ThreeUp,
  Title,
} from "components";
import { useNavigate } from "react-router-dom";
import paths from "routing/paths";

const PageNotFound = () => {
  document.title = "Page Not Found";
  const navigate = useNavigate();

  async function handleNav() {
    navigate(paths.HOME);
  }

  return (
    <Section>
      <ThreeUp pd="4rem 0 0">
        <FlexBox gap="2rem">
          <Title size="XLarge" bold>
            Page not found
          </Title>
          <Body size="large">
            The requested page could not be found. If this seems to be an error,
            feel free to{" "}
            <TextLink
              display="inline-block"
              newTab
              to={`mailto:support@brandcentral@verizon.com`}
              size="large"
            >
              contact us
            </TextLink>{" "}
            and we'll be sure to look into the issue.
          </Body>

          <Button onClick={handleNav}>Return home</Button>
        </FlexBox>
      </ThreeUp>
    </Section>
  );
};

export default PageNotFound;
