import { withFormik } from "formik";
import mapPropsToValues, { FormValues } from "./mapPropsToValues";
import validationSchema from "./validationSchema";
import AccessModal from "./AccessModal";
import { AccessModalProps } from "interfaces/Modal";

export const AccessModalContainer = withFormik<AccessModalProps, FormValues>({
  mapPropsToValues,
  validationSchema,
  handleSubmit: () => null,
  enableReinitialize: true,
  validateOnMount: true,
})(AccessModal);

export default AccessModalContainer;
