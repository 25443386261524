/**
 * Debounce.
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)
 *
 * @example
 * let myEfficientFn = debounceFunction(function() {
 *   // All the taxing stuff you do
 * }, 250);
 * window.addEventListener('resize', myEfficientFn);
 *
 * @param {function} func - Function to debounce
 * @param {number} waitMS - Time to wait before executing the function
 * @param {boolean} immediate - Trigger the function on the leading edge instead of the trailing edge.
 * @returns {function} executedFunction
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce(func: Function, waitMS: number, immediate: boolean = false): () => unknown {
  let timeout: number | null;
  
  return function executedFunction() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    
    const later = function(): void {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    
    const callNow = immediate && !timeout;
    
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.clearTimeout(timeout);
    
    timeout = window.setTimeout(later, waitMS);
    
    if (callNow) {
      func.apply(context, args);
    }
  };
}
